<template>
  <CChartLine
    v-if="chartData && chartData.datasets && chartData.datasets.length > 0"
    :datasets="chartData.datasets"
    :labels="chartData.labels"
    :options="mergedOptions"
  />
  <div v-else class="text-center p-4">
    <p>Keine Daten verfügbar</p>
  </div>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'LineChart',
  components: {
    CChartLine
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
