<template>
  <CInput
    :label="label"
    :type="type"
    :value="computedValue"
    @input="onInput"
    @focusin="onFocusIn"
    @focusout="onFocusOut"
  />
</template>

<script>
export default {
  name: "FocusableMoneyInput",
  props: {
    value: Number,
    type: {
      type: String,
      default: "",
    },
    label: String,
  },
  data() {
    return {
      dataValue: this.value,
      focused: false,
    };
  },
  computed: {
    computedValue() {
      return this.focused
        ? this.dataValue
        : isNaN(this.dataValue)
          ? "Fehler! Bitte korrigieren!"
          : this.$commons.formatMoney(this.dataValue);
    },
  },
  methods: {
    onInput(e) {
      this.dataValue = e;
      if (!isNaN(e)) this.$emit("input", Number(e));
    },
    onFocusIn(e) {
      this.focused = true;
    },
    onFocusOut(e) {
      this.focused = false;
    },
  },
};
</script>

<style scoped></style>
