<template>
  <CModal
    :title="modalTitle"
    :show.sync="isVisible"
    :size="isEdit ? 'xl' : 'lg'"
    :close-on-backdrop="false"
  >
    <div class="deal-form">
      <!-- Basic Deal Information Section -->
      <div class="deal-form__section">
        <!-- Contact Selection -->
        <CRow>
          <CCol sm="12" class="mb-4">
            <label class="form-label required">Kontakt</label>
            <div
              class="contact-multiselect"
              :class="{ 'is-invalid': errors.selectedPeople }"
            >
              <VueMultiselect
                v-model="form.selectedPeople"
                :options="peopleOptions"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Kontakt auswählen oder suchen..."
                label="text"
                track-by="value"
                @search-change="searchPeople"
                @input="handlePeopleSelect"
                :selectLabel="''"
                :selectedLabel="''"
                :deselectLabel="''"
              >
                <template #option="props">
                  <div class="contact-option">
                    <div class="contact-option__avatar">
                      {{ getInitials(props.option.text) }}
                    </div>
                    <div class="contact-option__info">
                      <span class="contact-option__name">{{
                        props.option.text
                      }}</span>
                      <div
                        v-if="props.option.emails?.length"
                        class="contact-option__detail"
                      >
                        <CIcon name="cil-envelope-closed" size="sm" />
                        {{ props.option.emails[0].email }}
                      </div>
                      <div
                        v-if="props.option.phones?.length"
                        class="contact-option__detail"
                      >
                        <CIcon name="cil-phone" size="sm" />
                        {{ props.option.phones[0].phone }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #noResult>
                  <div class="multiselect-empty">
                    <CIcon name="cil-user-unfollow" />
                    <span>Keine Kontakte gefunden</span>
                  </div>
                </template>
                <template #noOptions>
                  <div class="multiselect-empty">
                    <CIcon name="cil-user-unfollow" />
                    <span>Keine Kontakte vorhanden</span>
                  </div>
                </template>
              </VueMultiselect>
              <div v-show="errors.selectedPeople" class="invalid-feedback">
                {{ errors.selectedPeople }}
              </div>
            </div>
          </CCol>
        </CRow>

        <!-- Deal Details -->
        <CRow>
          <CCol sm="12" class="mb-4">
            <CInput
              v-model="form.name"
              label="Deal Name"
              label-class="required"
              placeholder="z.B. Website Projekt XYZ"
              :is-valid="isFieldValid('name')"
              :invalid-feedback="errors.name"
              @input="validateField('name')"
            >
              <template #prepend-content>
                <CIcon name="cil-briefcase" />
              </template>
            </CInput>
          </CCol>

          <CCol sm="6" class="mb-4">
            <CInput
              v-model.number="form.value"
              label="Deal Wert"
              type="number"
              placeholder="0"
              :is-valid="isFieldValid('value')"
              :invalid-feedback="errors.value"
              @input="validateField('value')"
            >
              <template #prepend-content>
                <CIcon name="cil-euro" />
              </template>
              <template #append-content>EUR</template>
            </CInput>
          </CCol>

          <CCol sm="6" class="mb-4">
            <CSelect
              v-model="form.pipelineStepId"
              label="Pipeline-Phase"
              label-class="required"
              :options="pipelineStepOptions"
              :is-valid="isFieldValid('pipelineStepId')"
              :invalid-feedback="errors.pipelineStepId"
              @change="validateField('pipelineStepId')"
            >
              <template #prepend-content>
                <CIcon name="cil-chevron-double-right" />
              </template>
            </CSelect>
          </CCol>
        </CRow>

        <!-- Status Badge (Edit Mode) -->
        <CRow v-if="isEdit">
          <CCol sm="12" class="mb-4">
            <div class="deal-status">
              <CIcon :name="getStatusIcon(form.status)" class="mr-2" />
              <span class="status-label">Status:</span>
              <CBadge :color="getStatusColor(form.status)" class="ml-2">
                {{ getStatusText(form.status) }}
              </CBadge>
            </div>
          </CCol>
        </CRow>
      </div>

      <!-- Activities and Notes Section -->
      <template v-if="isEdit">
        <div class="deal-form__section">
          <CTabs
            :active-tab="activeTab"
            @update:activeTab="(tab) => (activeTab = tab)"
          >
            <CTab>
              <template #title>
                <div class="tab-title">
                  <CIcon name="cil-list" />
                  <span>Aktivitäten</span>
                  <CBadge
                    v-if="activitiesCount > 0"
                    color="primary"
                    shape="pill"
                    class="ml-2"
                  >
                    {{ activitiesCount }}
                  </CBadge>
                </div>
              </template>
              <div class="tab-content-wrapper">
                <div class="tab-header">
                  <h3>Aktivitäten</h3>
                  <CButton
                    @click="showActivityModal"
                    color="primary"
                    variant="ghost"
                  >
                    <CIcon name="cil-plus" />
                    Neue Aktivität
                  </CButton>
                </div>
                <ActivityWidget
                  ref="activityWidget"
                  :applyUpdate="handleActivityUpdate"
                  :closeModal="closeActivityModal"
                  @activity-updated="handleActivityUpdate"
                  class="widget-container"
                />
              </div>
            </CTab>

            <CTab>
              <template #title>
                <div class="tab-title">
                  <CIcon name="cil-notes" />
                  <span>Notizen</span>
                  <CBadge
                    v-if="notesCount > 0"
                    color="primary"
                    shape="pill"
                    class="ml-2"
                  >
                    {{ notesCount }}
                  </CBadge>
                </div>
              </template>
              <div class="tab-content-wrapper">
                <div class="tab-header">
                  <h3>Notizen</h3>
                  <CButton
                    color="primary"
                    variant="ghost"
                    @click="showNotesModal"
                  >
                    <CIcon name="cil-plus" />
                    Neue Notiz
                  </CButton>
                </div>
                <NotesWidget
                  ref="notesWidget"
                  @notes-updated="handleNotesUpdate"
                  class="widget-container"
                />
              </div>
            </CTab>
          </CTabs>
        </div>
      </template>
    </div>

    <!-- Modal Footer -->
    <template #footer>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div v-if="isEdit" class="status-actions">
          <CButton
            v-if="form.status === 'PROCESSING'"
            color="success"
            variant="ghost"
            class="mr-2"
            @click="updateStatus('WON')"
          >
            <CIcon name="cil-check" />
            Gewonnen
          </CButton>
          <CButton
            v-if="form.status === 'PROCESSING'"
            color="danger"
            variant="ghost"
            @click="updateStatus('LOST')"
          >
            <CIcon name="cil-x" />
            Verloren
          </CButton>
          <CButton
            v-if="form.status !== 'PROCESSING'"
            color="primary"
            variant="ghost"
            @click="updateStatus('PROCESSING')"
          >
            <CIcon name="cil-reload" />
            Wiedereröffnen
          </CButton>
        </div>
        <div class="ml-auto">
          <CButton color="dark" variant="ghost" @click="handleClose">
            Abbrechen
          </CButton>
          <CButton
            color="primary"
            class="ml-2"
            :disabled="isSubmitting"
            @click="handleSubmit"
          >
            <CSpinner v-if="isSubmitting" size="sm" />
            <span>{{
              isSubmitting ? "Wird gespeichert..." : "Speichern"
            }}</span>
          </CButton>
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>
import ActivityWidget from "@/components/shared/widgets/ActivityWidget.vue";
import NotesWidget from "@/components/shared/widgets/NotesWidget.vue";
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import dealService from "@/services/dealService";

export default {
  name: "DealModal",

  components: {
    VueMultiselect,
    ActivityWidget,
    NotesWidget,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "add",
      validator: (value) => ["add", "edit"].includes(value),
    },
    deal: {
      type: Object,
      default: () => ({}),
    },
    pipelineSteps: {
      type: Array,
      default: () => [],
    },
    selectedStep: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSubmitting: false,
      fieldsTouched: {},
      activeTab: 0,
      activitiesCount: 0,
      notesCount: 0,
      form: {
        name: "",
        value: null,
        selectedPeople: null,
        selectedGroup: null,
        pipelineStepId: null,
        status: "PROCESSING",
      },
      peopleOptions: [],
      errors: {},
      searchTimeout: null,
    };
  },

  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
      },
    },

    isEdit() {
      return this.mode === "edit";
    },

    modalTitle() {
      return this.isEdit ? "Deal bearbeiten" : "Neuer Deal";
    },

    pipelineStepOptions() {
      return this.pipelineSteps.map((step) => ({
        value: step.id,
        label: step.name,
      }));
    },
  },

  watch: {
    visible(newVal) {
      if (newVal) {
        this.initializeForm();
        if (this.isEdit && this.deal.id) {
          this.loadDealDetails();
        }
      }
    },
    "form.selectedPeople"(newVal) {
      if (this.fieldsTouched.selectedPeople) {
        this.validateField("selectedPeople");
      }
      if (newVal?.text && !this.form.name) {
        this.form.name = `${newVal.text} - Deal`;
      }
    },
  },

  async mounted() {
    await this.loadInitialPeople();
    if (this.isEdit && this.deal.id) {
      this.populateForm(this.deal);
    }
  },

  methods: {
    getInitials(name) {
      return name
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase()
        .slice(0, 2);
    },

    getStatusIcon(status) {
      switch (status) {
        case "WON":
          return "cil-check-circle";
        case "LOST":
          return "cil-x-circle";
        case "PROCESSING":
          return "cil-loop-circular";
        default:
          return "cil-question-circle";
      }
    },

    async loadInitialPeople() {
      try {
        const response = await axios.get("/realm-people");
        this.peopleOptions = response.data.map((person) => ({
          value: person.id,
          text: person.name,
          emails: person.realmPeopleEmails,
          phones: person.realmPeoplePhones,
        }));
      } catch (error) {
        console.error("Fehler beim Laden der initialen Kontakte:", error);
      }
    },

    async searchPeople(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(async () => {
        try {
          const response = await axios.get(`/realm-people`);
          this.peopleOptions = response.data.map((person) => ({
            value: person.id,
            text: person.name,
            emails: person.realmPeopleEmails,
            phones: person.realmPeoplePhones,
          }));
        } catch (error) {
          console.error("Fehler beim Suchen von Kontakten:", error);
        }
      }, 300);
    },

    async loadDealDetails() {
      console.log('=== START loadDealDetails ===');
      console.log('Current deal:', this.deal);

      if (!this.deal?.id) {
        console.warn('No deal ID available');
        return;
      }

      try {
        console.log('Loading deal details for ID:', this.deal.id);
        const dealResponse = await dealService.loadDeal(this.deal.id);
        console.log('Deal response:', dealResponse);

        this.populateForm(dealResponse);

        if (this.$refs.activityWidget) {
          await this.$refs.activityWidget.initialize(dealResponse.id);
        } else {
          console.error('ActivityWidget reference not found');
        }
      } catch (error) {
        console.error('Error in loadDealDetails:', error);
      }
      console.log('=== END loadDealDetails ===');
    },

    updateActivityCount() {
      if (this.$refs.activityWidget) {
        this.activitiesCount =
          this.$refs.activityWidget.activities?.length || 0;
      }
    },

    updateNotesCount() {
      if (this.$refs.notesWidget) {
        this.notesCount = this.$refs.notesWidget.notes?.length || 0;
      }
    },

    initializeForm() {
      if (this.deal.id) {
        this.populateForm(this.deal);
      } else {
        this.resetForm();
        if (this.selectedStep) {
          this.form.pipelineStepId = this.selectedStep.id;
        }
      }
    },

    populateForm(dealData) {
      this.form = {
        name: dealData.name || "",
        value: dealData.value || null,
        selectedPeople: dealData.realmPeople
          ? {
              value: dealData.realmPeople.id,
              text: dealData.realmPeople.name,
              emails: dealData.realmPeople.realmPeopleEmails,
              phones: dealData.realmPeople.realmPeoplePhones,
            }
          : null,
        selectedGroup: dealData.realmGroup
          ? {
              value: dealData.realmGroup.id,
              text: dealData.realmGroup.title,
            }
          : null,
        pipelineStepId: dealData.pipelineStep?.id || null,
        status: dealData.status || "PROCESSING",
      };

      if (dealData.realmPeople) {
        const existingContact = {
          value: dealData.realmPeople.id,
          text: dealData.realmPeople.name,
          emails: dealData.realmPeople.realmPeopleEmails,
          phones: dealData.realmPeople.realmPeoplePhones,
        };

        const exists = this.peopleOptions.some(
          (p) => p.value === existingContact.value,
        );
        if (!exists) {
          this.peopleOptions.push(existingContact);
        }
      }
    },

    isFieldValid(fieldName) {
      if (!this.fieldsTouched[fieldName]) return null;
      return !this.errors[fieldName];
    },

    validateField(fieldName) {
      this.fieldsTouched[fieldName] = true;

      switch (fieldName) {
        case "name":
          if (!this.form.name?.trim()) {
            this.errors[fieldName] = "Name ist erforderlich";
          } else {
            delete this.errors[fieldName];
          }
          break;

        case "pipelineStepId":
          if (!this.form.pipelineStepId) {
            this.errors[fieldName] = "Pipeline-Phase ist erforderlich";
          } else {
            delete this.errors[fieldName];
          }
          break;

        case "selectedPeople":
          if (!this.form.selectedPeople?.value) {
            this.errors[fieldName] = "Kontakt ist erforderlich";
          } else {
            delete this.errors[fieldName];
          }
          break;
      }
    },

    validate() {
      this.fieldsTouched = {
        name: true,
        pipelineStepId: true,
        selectedPeople: true,
      };

      this.validateField("name");
      this.validateField("pipelineStepId");
      this.validateField("selectedPeople");

      return Object.keys(this.errors).length === 0;
    },

    async handleSubmit() {
      if (!this.validate()) return;

      try {
        this.isSubmitting = true;
        const dealData = {
          name: this.form.name,
          value: this.form.value,
          realmPeopleId: this.form.selectedPeople?.value,
          realmGroupId: this.form.selectedGroup?.value,
          pipelineStepId: this.form.pipelineStepId,
          status: this.form.status,
          realmPeople: this.form.selectedPeople
            ? {
                id: this.form.selectedPeople.value,
                name: this.form.selectedPeople.text,
                emails: this.form.selectedPeople.emails,
                phones: this.form.selectedPeople.phones,
              }
            : null,
          realmGroup: this.form.selectedGroup
            ? {
                id: this.form.selectedGroup.value,
                title: this.form.selectedGroup.text,
              }
            : null,
        };

        let savedDeal;
        if (this.isEdit && this.deal.id) {
          savedDeal = await dealService.updateDeal(this.deal.id, dealData);
          this.$emit("deal-updated", { ...savedDeal });
        } else {
          savedDeal = await dealService.createDeal(dealData);
          this.$emit("deal-added", savedDeal);
        }

        if (
          this.$refs.notesWidget &&
          savedDeal.id &&
          this.$refs.notesWidget.notes.trim()
        ) {
          await this.$refs.notesWidget.submit(savedDeal.id);
        }

        if (this.$refs.activityWidget && savedDeal.id) {
          const activityWidget = this.$refs.activityWidget;
          const hasActivityContent = !!(
            activityWidget.description?.trim() ||
            activityWidget.location?.trim() ||
            activityWidget.notes?.trim() ||
            activityWidget.startTime ||
            activityWidget.endTime
          );

          if (hasActivityContent) {
            const activityData = {
              title:
                activityWidget.title ||
                `Aktivität vom ${new Date().toLocaleDateString()}`,
              type: activityWidget.type,
              startTime: activityWidget
                .makeDateTime(
                  activityWidget.startDate,
                  activityWidget.startTime,
                )
                .toISOString(),
              endTime: activityWidget
                .makeDateTime(activityWidget.endDate, activityWidget.endTime)
                .toISOString(),
              description: activityWidget.description,
              location: activityWidget.location,
              notes: activityWidget.notes,
              freeType: activityWidget.freeType,
              done: activityWidget.done,
              deal: {
                id: savedDeal.id,
              },
            };

            await axios.post("/task", activityData);
          }
        }

        this.handleClose();
      } catch (error) {
        console.error("Fehler beim Speichern des Deals:", error);
      } finally {
        this.isSubmitting = false;
      }
    },

    handleClose() {
      this.resetForm();
      this.isVisible = false;
    },

    resetForm() {
      this.form = {
        name: "",
        value: null,
        selectedPeople: null,
        selectedGroup: null,
        pipelineStepId: null,
        status: "PROCESSING",
      };
      this.errors = {};
      this.fieldsTouched = {};
      this.activeTab = 0;
    },

    async showActivityModal() {
      console.log('=== START showActivityModal ===');
      console.log('Current deal:', this.deal);
      console.log('ActivityWidget ref:', this.$refs.activityWidget ? 'exists' : 'missing');

      if (this.$refs.activityWidget) {
        const dealId = this.deal?.id;
        console.log('DealId:', dealId);

        if (dealId) {
          console.log('Starting task load for dealId:', dealId);
          try {
            const response = await axios.get(`/task/deal/${dealId}`);
            console.log('Task API Response:', response.data);

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
              const task = response.data[0];
              console.log('Found task to initialize:', task);
              this.$refs.activityWidget.initializeWithTask(task);
            } else {
              console.log('No tasks found, initializing empty with dealId:', dealId);
              this.$refs.activityWidget.initializeEmpty(dealId);
            }
          } catch (error) {
            console.error('Task load error:', error);
            this.$refs.activityWidget.initializeEmpty(dealId);
          }
        } else {
          console.warn('No dealId available, initializing empty');
          this.$refs.activityWidget.initializeEmpty();
        }
      } else {
        console.error('ActivityWidget reference not found');
      }

      console.log('Opening modal');
      this.visibleActivityModal = true;
      console.log('=== END showActivityModal ===');
    },

    showNotesModal() {
      if (this.$refs.notesWidget) {
        this.$refs.notesWidget.initialize(this.deal.id);
      }
    },

    handleActivityUpdate(updatedActivity) {
      this.loadDealDetails();
    },

    closeActivityModal() {
      try {
        // 1. Initialisiere das ActivityWidget neu
        if (this.$refs.activityWidget) {
          // Wenn ein Deal ausgewählt ist, initialisiere mit der Deal-ID
          if (this.selectedDeal && this.selectedDeal.id) {
            this.$refs.activityWidget.initialize(this.selectedDeal.id);
          } else {
            // Ansonsten normale Initialisierung
            this.$refs.activityWidget.initialize();
          }
        }

        // 2. Aktualisiere die Deal-Details
        if (this.selectedDeal && this.selectedDeal.id) {
          this.loadDealDetails();
        }

        // 3. Schließe das Modal
        this.visibleActivityModal = false;

        // 4. Setze temporäre Zustände zurück
        this.selectedTaskId = null;

        console.log('Activity modal closed and reset');
      } catch (error) {
        console.error('Error in closeActivityModal:', error);
        // Trotz Fehler Modal schließen
        this.visibleActivityModal = false;
      }
    },

    handleNotesUpdate(updatedNote) {
      this.loadDealDetails();
    },

    getStatusColor(status) {
      switch (status) {
        case "WON":
          return "success";
        case "LOST":
          return "danger";
        case "PROCESSING":
          return "primary";
        default:
          return "secondary";
      }
    },

    getStatusText(status) {
      switch (status) {
        case "WON":
          return "Gewonnen";
        case "LOST":
          return "Verloren";
        case "PROCESSING":
          return "In Bearbeitung";
        default:
          return "Unbekannt";
      }
    },

    handlePeopleSelect(selected) {
      if (selected?.value) {
        this.fieldsTouched.selectedPeople = true;
        this.validateField("selectedPeople");
      }
    },

    updateStatus(newStatus) {
      this.form.status = newStatus;
      this.handleSubmit();
    },
  },
};
</script>

<style lang="scss">
.deal-form {
  &__section {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contact-multiselect {
  .multiselect__tags {
    border-radius: 4px;
    border-color: #d8dbe0;
    min-height: 38px;
    padding: 5px 40px 0 8px;
    transition: all 0.2s ease;

    &:hover {
      border-color: #321fdb;
    }
  }

  &.is-invalid .multiselect__tags {
    border-color: #e55353;
  }
}

.contact-option {
  display: flex;
  align-items: center;
  padding: 4px 0;

  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #321fdb;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 12px;
    flex-shrink: 0;
  }

  &__info {
    flex-grow: 1;
    min-width: 0;
  }

  &__name {
    font-weight: 600;
    display: block;
    margin-bottom: 2px;
  }

  &__detail {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #768192;
    margin-top: 2px;

    .c-icon {
      margin-right: 4px;
      width: 14px;
      height: 14px;
    }
  }
}

.multiselect-empty {
  display: flex;
  align-items: center;
  padding: 8px;
  color: #768192;

  .c-icon {
    margin-right: 8px;
  }
}

.tab-content-wrapper {
  padding: 1rem 0;
}

.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h3 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.tab-title {
  display: flex;
  align-items: center;

  .c-icon {
    margin-right: 8px;
  }
}

.widget-container {
  background: white;
  border-radius: 4px;
  padding: 1rem;
}

.deal-status {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 4px;

  .status-label {
    font-weight: 600;
    margin-right: 4px;
  }

  .c-icon {
    color: #768192;
  }
}

.status-actions {
  .btn {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.form-label {
  font-weight: 600;
  margin-bottom: 0.5rem;

  &.required:after {
    content: "*";
    color: #e55353;
    margin-left: 4px;
  }
}

// CoreUI Overrides
.c-tabs {
  border: none;
  margin-bottom: 1rem;

  .c-tab {
    padding: 0.75rem 1rem;
    border: none;
    margin-right: 0.5rem;
    border-radius: 4px;
    color: #768192;
    transition: all 0.2s ease;

    &:hover {
      background-color: #ebedef;
    }

    &--active {
      background-color: #321fdb;
      color: white;

      &:hover {
        background-color: #2b1abb;
      }

      .badge {
        background-color: white;
        color: #321fdb;
      }
    }
  }
}

.c-button {
  display: inline-flex;
  align-items: center;

  .c-icon {
    margin-right: 6px;
  }
}

.invalid-feedback {
  color: #e55353;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

// Responsive Adjustments
@media (max-width: 768px) {
  .deal-form__section {
    margin-bottom: 1.5rem;
  }

  .tab-header {
    flex-direction: column;
    align-items: flex-start;

    .c-button {
      margin-top: 1rem;
    }
  }

  .status-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .btn {
      flex: 1;
      min-width: 120px;
    }
  }
}
</style>
