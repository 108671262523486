<template>
  <CCard class="newsletter-subscriber-import">
    <CCardHeader>
      <slot name="header">
        <b>Newsletter-Abonnenten importieren</b>
      </slot>
    </CCardHeader>
    <CCardBody>
      <!-- Neue Buttons für die Importauswahl -->
      <CRow v-if="!showTable && !showAppUserImport">
        <CCol :md="isOrganizationAdmin ? '6' : '12'" class="text-center">
          <CButton color="primary" @click="showCsvImport = true">
            CSV-Datei importieren
          </CButton>
        </CCol>
        <!-- App-Benutzer Import Button nur für Organization Admin -->
        <CCol md="6" v-if="commons.isSystemAdmin() " class="text-center">
          <CButton color="primary" @click="importAppUsers">
            App-Benutzer importieren
          </CButton>
        </CCol>
      </CRow>

      <!-- Bestehender CSV-Import -->
      <CCol md="12" v-if="!showTable && showCsvImport" class="pl-0 pr-2">
        <vue-csv-import v-model="csv" :map-fields="mapFields">
          <template slot="hasHeaders" slot-scope="{ headers, toggle }">
            <label style="margin-left: -20px">
              <input
                  type="checkbox"
                  id="hasHeaders"
                  :value="headers"
                  @change="toggle"
              />
              Die Datei hat eine Kopfzeile
            </label>
          </template>

          <template slot="error"> Der Dateityp ist ungültig </template>

          <template slot="thead">
            <CAlert color="warning">
              Empfängergruppen müssen kommasepariert in der Datei hinterlegt sein.
              Beispiel: Wertermittlung, Ratgeber, Webinar
            </CAlert>
            <tr>
              <th>
                Spaltenbeschriftungen den Abonnenteninformationen zuordnen
              </th>
              <th>Spalten in Ihrer Importdatei</th>
            </tr>
          </template>

          <template slot="next" slot-scope="{ load }">
            <CButton color="success" class="mt-5" @click.prevent="load"
            >Datei hochladen</CButton
            >
          </template>
        </vue-csv-import>
      </CCol>

      <CCol md="12" v-if="showTable">
        <CDataTable
            :items="csv"
            :fields="getTableFields()"
            hover
            striped
            bordered
            fixed
            column-filter
            table-filter
            items-per-page-select
            sorter
            pagination
            :loading="loading"
        >
          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click.prevent="showEditModal(index, item)"
              >
                Editieren
              </CButton>
            </td>
          </template>
          <template #delete_item="{ item, index }">
            <td class="py-2">
              <CButton
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                  @click.prevent="deleteRecord(index)"
              >
                Löschen
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCol>

      <CCol
          md="12"
          v-if="visibleActionButton && !showTable"
          class="text-center"
      >
        <CButton color="primary" @click.prevent="showTable = true">
          Weiter
        </CButton>
      </CCol>

      <CCol md="12" v-if="visibleActionButton && showTable" class="text-center">
        <CButton color="primary" @click.prevent="back()"> Zurück </CButton>
        &nbsp;&nbsp;&nbsp;
        <CButton color="primary" @click.prevent="submit()">
          Import abschließen
        </CButton>
      </CCol>

      <CCol md="12">
        <CForm @submit.prevent="update">
          <CModal
              title="Abonnent hinzufügen"
              size="sm"
              :show.sync="visibleModal"
              :closeOnBackdrop="false"
          >
            <CRow>
              <CCol sm="12">
                <label>E-Mail Adresse</label>
                <CInput
                    type="email"
                    required
                    v-model="selectedItem && selectedItem['E-Mail']"
                />
              </CCol>

              <CCol sm="12">
                <label for="company">Firma</label>
                <CInput
                    id="company"
                    required="true"
                    v-model="selectedItem && selectedItem['Firma']"
                />
              </CCol>

              <CCol sm="12">
                <label for="salutation">Anrede</label>
                <CInput
                    id="salutation"
                    required="true"
                    v-model="selectedItem && selectedItem['Anrede']"
                />
              </CCol>

              <CCol sm="12">
                <label for="firstName">Vorname</label>
                <CInput
                    id="firstName"
                    required="true"
                    v-model="selectedItem && selectedItem['Vorname']"
                />
              </CCol>

              <CCol sm="12">
                <label for="lastName">Nachname</label>
                <CInput
                    id="lastName"
                    required="true"
                    v-model="selectedItem && selectedItem['Nachname']"
                />
              </CCol>

              <CCol sm="12">
                <label for="groups">Gruppen (Komma-seperiert)</label>
                <CInput
                    id="groups"
                    required="true"
                    v-model="selectedItem && selectedItem['Gruppen']"
                />
              </CCol>
            </CRow>

            <div slot="footer">
              <CButton
                  class="mr-3"
                  color="dark"
                  variant="outline"
                  @click="visibleModal = false"
              >
                Abbrechen
              </CButton>
              <CButton color="success" type="submit"> Speichern </CButton>
            </div>
          </CModal>
        </CForm>
      </CCol>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { VueCsvImport } from "vue-csv-import";
import commons from "@/commons";

export default {
  name: "NewsletterSubscriberImport",
  components: {
    VueCsvImport,
  },
  mounted() {
    if (this.isOrganizationAdmin) {
      this.loadKundenGruppe();
    }
  },
  data() {
    return {
      mapFields: ["E-Mail", "Firma", "Anrede", "Vorname", "Nachname", "Gruppen"],
      csv: null,
      tableData: [],
      showTable: false,
      showCsvImport: false,
      showAppUserImport: false,
      visibleActionButton: false,
      loading: false,
      visibleModal: false,
      selectedIndex: null,
      selectedItem: null,
      kundenGruppeId: null,
    };
  },
  computed: {
    commons() {
      return commons
    },
    isOrganizationAdmin() {
      return this.$commons.hasRole("ORGANIZATION_ADMIN");
    }
  },
  watch: {
    // Wenn CSV-Import angezeigt wird, dann customizeFileInput aufrufen
    showCsvImport(newVal) {
      if (newVal) {
        // Warten bis das DOM aktualisiert wurde
        this.$nextTick(() => {
          this.customizeFileInput();
        });
      }
    },
    csv(val) {
      if (val) {
        this.visibleActionButton = true;
      }
    },
  },
  methods: {
    customizeFileInput() {
      const csvImportFile = document.querySelector(
          ".newsletter-subscriber-import .csv-import-file"
      );

      // Prüfen ob das Element existiert
      if (!csvImportFile) {
        console.warn('CSV import file element not found');
        return;
      }

      csvImportFile.style.display = "none";

      const divWrapper = document.createElement("div");
      divWrapper.setAttribute("class", "row");

      const divOffset = document.createElement("div");
      divOffset.setAttribute("class", "col-sm-3 mb-3");
      divWrapper.appendChild(divOffset);

      const div = document.createElement("div");
      div.setAttribute("class", "form-group col-sm-9 mb-3");
      const label = document.createElement("label");
      label.setAttribute("for", "csv-import-file");
      label.setAttribute("class", "custom-file-label");
      label.style.display = "block";
      label.innerHTML = "Datei auswählen...";
      div.appendChild(label);

      divWrapper.appendChild(div);

      const csvInput = document.querySelector(
          ".newsletter-subscriber-import .form-control-file"
      );

      // Prüfen ob das Element existiert
      if (!csvInput) {
        console.warn('CSV input element not found');
        return;
      }

      csvInput.addEventListener("change", function (e) {
        label.innerHTML = e.target.files[0].name;
      });

      div.addEventListener("click", function () {
        csvInput.click();
      });

      const partOne = document.querySelector(
          ".newsletter-subscriber-import .vue-csv-uploader-part-one"
      );

      // Prüfen ob das Element existiert
      if (!partOne) {
        console.warn('CSV uploader part one element not found');
        return;
      }

      partOne.insertBefore(divWrapper, partOne.children[2]);
    },

    getTableFields() {
      return [
        {
          key: "E-Mail",
          label: "E-Mail",
        },

        {
          key: "Firma",
          label: "Firma",
        },

        {
          key: "Anrede",
          label: "Anrede",
        },
        {
          key: "Nachname",
          label: "Nachname",
        },
        {
          key: "Gruppen",
          label: "Gruppen",
        },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
        {
          key: "delete_item",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ];
    },
    back() {
      this.showTable = false;
      this.showCsvImport = true; // Setzen Sie showCsvImport auf true
    },
    async loadKundenGruppe() {
      try {
        const groupsReq = await axios.get("/newsletter/group");
        const kundenGruppe = groupsReq.data.find(
            (group) => group.name.toLowerCase() === "kunden"
        );
        if (kundenGruppe) {
          this.kundenGruppeId = kundenGruppe.id;
        }
      } catch (error) {
        console.error("Fehler beim Laden der Gruppen", error);
      }
    },
    async importAppUsers() {
      if (!this.isOrganizationAdmin) {
        this.$toaster.makeToast(
            "Fehler!",
            "Sie haben keine Berechtigung für diese Aktion."
        );
        return;
      }

      try {
        this.loading = true;

        const response = await axios.get("/user");
        const users = response.data;

        const subscribers = users.map(user => ({
          company: user.company,
          salutation: user.salutation,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          groups: [this.kundenGruppeId]
        }));

        await this.addSubscribers(subscribers);

        this.$toaster.makeToast(
            "Erfolgreich!",
            "App-Benutzer wurden als Newsletter-Abonnenten importiert"
        );
        await this.$router.push({ name: "NewsletterSubscribers" });
      } catch (error) {
        console.error("Fehler beim Import der App-Benutzer", error);
        this.$toaster.makeToast(
            "Fehler!",
            "Fehler beim Import der App-Benutzer. Bitte versuchen Sie es später erneut."
        );
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      this.loading = true;
      const subscribers = this.csv;
      if (subscribers && subscribers.length > 0) {
        try {
          const groupsReq = await axios.get("/newsletter/group");
          const groups = groupsReq.data.reduce(
              (acc, curr) => ((acc[curr.name.toLowerCase()] = curr), acc),
              {},
          );

          const formattedSubscribers = await this.formatSubscribersData(
              subscribers,
              groups,
          );

          await this.addSubscribers(formattedSubscribers);

          this.$toaster.makeToast("Erfolgreich!", "Import erfolgreich abgeschlossen");
          // Catch möglichen Navigation-Fehler
          try {
            await this.$router.push({ name: "NewsletterSubscribers" });
          } catch (navigationError) {
            // Navigation-Fehler ignorieren, da wir bereits auf der richtigen Seite sind
            console.log('Navigation nicht notwendig - bereits auf der Zielseite');
          }
        } catch (err) {
          this.$toaster.makeToast(
              "Fehler!",
              "Fehler beim Import der Abonnenten: " + (err.message || "Unbekannter Fehler")
          );
        } finally {
          this.loading = false;
        }
      } else if (!this.csv || (this.csv && this.csv.length === 0)) {
        this.$toaster.makeToast("Warnung", "Keine Daten zum Importieren vorhanden");
      }
    },
    async formatSubscribersData(subscribers, groupsAlreadyExists) {
      let formattedSubscribers = [];

      for (const subscriber of subscribers) {
        const entity = {
          email: subscriber["E-Mail"],
          company: subscriber["Firma"],
          salutation: subscriber["Anrede"],
          firstName: subscriber["Vorname"],
          lastName: subscriber["Nachname"],
          groups: []
        };

        if (subscriber["Gruppen"]) {
          const groupNames = subscriber["Gruppen"].split(",").map(g => g.trim());

          for (const groupName of groupNames) {
            let groupId;
            const groupNameLower = groupName.toLowerCase();

            // Prüfe ob Gruppe bereits existiert
            if (groupsAlreadyExists[groupNameLower]) {
              groupId = groupsAlreadyExists[groupNameLower].id;
            } else {
              // Wenn nicht, erstelle neue Gruppe
              try {
                const response = await axios.post("/newsletter/group", {
                  name: groupName,
                  description: `Automatisch erstellt beim Import`
                });
                groupId = response.data.id;
                // Füge neue Gruppe zum Cache hinzu
                groupsAlreadyExists[groupNameLower] = {
                  id: groupId,
                  name: groupName
                };
              } catch (error) {
                console.error(`Fehler beim Erstellen der Gruppe ${groupName}:`, error);
                continue;
              }
            }

            entity.groups.push(groupId);
          }
        }

        formattedSubscribers.push(entity);
      }

      return formattedSubscribers;
    },
    async addSubscribers(subscribers) {
      try {
        this.loading = true;
        await axios.post("/newsletter/subscriber/multiple", subscribers);
        this.$toaster.makeToast("Erfolgreich!", "Erfolgreich importiert");
        await this.$router.push({ name: "NewsletterSubscribers" });
      } catch (e) {
        this.$toaster.makeToast(
            "Fehler!",
            "Fehler beim Import. Versuchen Sie es zu einem späteren Zeitpunkt wieder.",
        );
      } finally {
        this.loading = false;
      }
    },
    showEditModal(index, item) {
      this.selectedIndex = index;
      this.selectedItem = item;
      this.visibleModal = true;
    },
    update() {
      if (this.selectedIndex !== null) {
        this.csv && this.csv.splice(this.selectedIndex, 1, this.selectedItem);
        this.visibleModal = false;
      }
    },
    deleteRecord(index) {
      this.csv && this.csv.splice(index, 1);
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
