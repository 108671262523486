const state = {
    locations: [],
    connectionStatus: {
        enabled: false,
        autoSyncEnabled: false,
        lastSync: null,
        syncIntervalMinutes: 60
    },
    loading: false,
    error: null
}

const getBaseUrl = () => {
    const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL || 'https://localhost:8080'
    return baseUrl.replace(/\/backend$/, '')
}

const mutations = {
    SET_LOCATIONS(state, locations) {
        state.locations = locations
    },
    SET_CONNECTION_STATUS(state, status) {
        state.connectionStatus = status
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_ERROR(state, error) {
        state.error = error
    }
}

const actions = {
    async fetchLocations({ commit }) {
        commit('SET_LOADING', true)
        try {
            const response = await axios.get(`${getBaseUrl()}/api/v1/google-my-business/locations`)
            commit('SET_LOCATIONS', response.data)
        } catch (error) {
            commit('SET_ERROR', error.response?.data?.error || 'Fehler beim Laden der Locations')
        } finally {
            commit('SET_LOADING', false)
        }
    },

    async fetchStatus({ commit }) {
        try {
            const response = await axios.get(`${getBaseUrl()}/api/v1/google-my-business/status`)
            commit('SET_CONNECTION_STATUS', response.data)
        } catch (error) {
            commit('SET_ERROR', error.response?.data?.error || 'Fehler beim Laden des Status')
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
