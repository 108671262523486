import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import commons from "../commons";

class WebSocketService {
  constructor() {
    this.stompClient = null;
    this.connected = false;
    this.connecting = false;  // Neuer Status
    this.subscriptions = new Map();
    this.connectionAttempts = 0;  // Zähler für Verbindungsversuche
    this.maxReconnectAttempts = 5;  // Maximum Reconnect-Versuche
    this.monitoringInterval = null;
  }

  async checkAndRefreshToken() {
    try {
      const currentToken = commons.getFromStorage('jwtToken', null);
      if (!currentToken) {
        console.warn('No token available for refresh');
        return false;
      }

      const cleanToken = currentToken.replace(/^["']|["']$/g, '');
      console.debug('Attempting to refresh token');

      const response = await fetch(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/auth/refresh`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${cleanToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.token) {
          // Wichtig: Zuerst den alten Token-Cache löschen
          commons.removeFromStorage('jwtTokenParsed');

          // Dann den neuen Token speichern
          commons.setToStorage('jwtToken', data.token);

          // Sofort den neuen Token parsen
          const parsedToken = commons.getJwt();
          if (!parsedToken) {
            console.error('Failed to parse new token');
            return false;
          }

          // Optional: Validiere die Rollen
          if (!parsedToken.auth || !Array.isArray(parsedToken.auth)) {
            console.error('New token has invalid role format');
            return false;
          }

          console.debug('Token refresh successful, new roles:',
              parsedToken.auth.map(r => r.authority));
          return true;
        }
      } else {
        console.warn('Token refresh failed with status:', response.status);
        if (response.status === 401) {
          await this.handleUnauthorized();
        }
      }
      return false;
    } catch (error) {
      console.error('Token refresh failed:', error);
      return false;
    }
  }

  async handleUnauthorized() {
    console.warn('Authentication required - performing logout');
    // Nutzen Sie die Commons-Funktion für Logout
    commons.logout();
  }

  startConnectionMonitoring() {
    if (this.monitoringInterval) {
      clearInterval(this.monitoringInterval);
    }

    this.monitoringInterval = setInterval(async () => {
      // Prüfen Sie, ob der Benutzer noch eingeloggt ist
      if (!commons.isLoggedIn()) {
        this.disconnect();
        return;
      }

      const currentToken = commons.getFromStorage('jwtToken', null);
      if (currentToken && this.lastUsedToken !== currentToken) {
        await this.checkAndRefreshToken();
      } else if (this.stompClient && !this.stompClient.connected && !this.connecting) {
        await this.handleReconnect();
      }
    }, 5000);
  }


  initializeStompClient() {
    // Nutzen Sie die Commons-Funktion für Token
    const jwtToken = commons.getFromStorage('jwtToken', null);
    if (!jwtToken) {
      console.error('Kein JWT Token verfügbar');
      return;
    }

    const cleanToken = jwtToken.replace(/^["']|["']$/g, '');
    const encodedToken = encodeURIComponent(cleanToken);

    const baseUrl = `${process.env.VUE_APP_BACKEND_BASE_URL}/ws`;
    const urlWithToken = `${baseUrl}?token=${encodedToken}`;

    this.stompClient = new Client({
      webSocketFactory: () => {
        const sockjs = new SockJS(urlWithToken, null, {
          transports: ['websocket'],
          timeout: 5000,
        });
        return sockjs;
      },
      connectHeaders: {
        'Authorization': `Bearer ${cleanToken}`
      },
      debug: function (str) {
        if (process.env.NODE_ENV !== 'production') {
          console.debug('STOMP:', str);
        }
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onStompError: (frame) => {
        console.error('STOMP Fehler:', frame.headers?.message || 'Unbekannter Fehler');
        if (frame.headers?.message?.includes('JWT expired')) {
          this.handleTokenExpired();
        }
      },
      onWebSocketError: async (event) => {
        console.error('WebSocket Fehler:', event?.message || 'Verbindungsfehler');
        this.connected = false;
        this.connecting = false;

        // Versuchen Sie einen Token-Refresh
        const refreshed = await this.checkAndRefreshToken();
        if (refreshed) {
          await this.reconnect();
        } else {
          await this.handleReconnect();
        }
      }
    });
  }

  async handleTokenExpired() {
    const refreshed = await this.checkAndRefreshToken();
    if (refreshed) {
      await this.reconnect();
    } else {
      // Wenn der Refresh fehlschlägt, logout
      commons.logout();
    }
  }


  reconnect() {
    if (this.stompClient) {
      this.stompClient.deactivate().then(() => {
        this.connected = false;
        this.initializeStompClient();
        this.stompClient.activate();
      }).catch(error => {
        console.error('Fehler beim Reconnect:', error);
      });
    } else {
      this.initializeStompClient();
      this.stompClient.activate();
    }
  }

  connect() {
    // Überprüfe ob ein JWT Token existiert
    if (!localStorage.getItem('jwtToken')) {
      console.warn('WebSocket Verbindung nicht möglich: Kein JWT Token vorhanden');
      return;
    }

    if (this.connecting) {
      return;
    }

    try {
      if (!this.stompClient) {
        this.initializeStompClient();
      }
      this.connecting = true;
      this.stompClient.activate();
      this.startConnectionMonitoring();
    } catch (error) {
      console.error('Fehler beim Aktivieren der WebSocket-Verbindung:', error);
      this.connecting = false;
      this.handleReconnect();
    }
  }

  checkAndUpdateToken() {
    const currentToken = localStorage.getItem('jwtToken');
    if (currentToken && this.lastUsedToken !== currentToken) {
      // Wichtig: Zuerst den lastUsedToken aktualisieren
      this.lastUsedToken = currentToken;
      // Dann neu verbinden
      this.reconnect();
    }
  }

  handleReconnect() {
    this.connectionAttempts++;
    if (this.connectionAttempts < this.maxReconnectAttempts) {
      setTimeout(() => this.connect(), 5000);
    } else {
      console.error('Maximale Anzahl an Verbindungsversuchen erreicht');
      // Hier könnte man einen Event auslösen, um die UI zu informieren
    }
  }

  subscribeToTopic(topic, callback) {
    const existingSubscription = this.subscriptions.get(topic);
    if (existingSubscription && existingSubscription.active) {
      return existingSubscription.subscription;
    }

    // Speichere die Subscription mit Status
    this.subscriptions.set(topic, {
      callback,
      active: false,
      subscription: null
    });

    if (this.stompClient && this.stompClient.connected) {
      return this.executeSubscription(topic, callback);
    } else {
      return null;
    }
  }

  executeSubscription(topic, callback) {
    try {
      const subscription = this.stompClient.subscribe(topic, message => {
        try {
          // Prüfen, ob message.body ein String ist und geparst werden muss
          let parsedMessage;
          if (message.body) {
            if (typeof message.body === 'string') {
              parsedMessage = JSON.parse(message.body);
            } else {
              parsedMessage = message.body;
            }
          } else if (message._binaryBody) {
            // Wenn binary body vorhanden ist, konvertiere zu String und parse
            const decoder = new TextDecoder();
            const textData = decoder.decode(message._binaryBody);
            parsedMessage = JSON.parse(textData);
          }

          if (!parsedMessage) {
            throw new Error('Keine validen Daten in der Nachricht gefunden');
          }

          callback(parsedMessage);
        } catch (error) {
          console.error(`Fehler beim Parsen der Nachricht auf ${topic}:`, error);
          // Optional: Sende Rohdaten im Fehlerfall
          if (message.body) {
            callback(message.body);
          }
        }
      });

      if (subscription) {
        this.subscriptions.set(topic, {
          callback,
          active: true,
          subscription
        });
        return subscription;
      } else {
        console.error(`Fehler beim Subscribe auf ${topic}`);
        return null;
      }
    } catch (error) {
      console.error(`Fehler beim Subscribe auf ${topic}:`, error);
      return null;
    }
  }

  handleMailOpened(leadId) {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.publish({
        destination: "/app/mailOpened",
        body: JSON.stringify({ leadId })
      });
    }
  }

  handleNewNotification(notificationData) {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.publish({
        destination: "/app/notification",
        body: JSON.stringify(notificationData)
      });
    }
  }

  disconnect() {
    if (this.stompClient) {
      this.stompClient.deactivate().then(() => {
        this.connected = false;
      }).catch(error => {
        console.error('Fehler beim Trennen der WebSocket-Verbindung:', error);
      });
    }
  }

  isConnected() {
    return this.connected && this.stompClient?.connected;
  }
}

// Singleton-Instanz erstellen und exportieren
const webSocketService = new WebSocketService();
export default webSocketService;
