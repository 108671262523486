<template>
  <div>
    <CCard>
      <CCardHeader class="bg-primary">
        <strong class="text-white">Default Realm Einstellungen</strong>
      </CCardHeader>
      <CCardBody class="settings-container">
        <!-- Tab Navigation -->
        <div class="nav nav-tabs nav-fill mb-4">
          <a
            v-for="group in groups"
            :key="group"
            class="nav-link"
            :class="{ active: activeGroup === group }"
            href="#"
            @click.prevent="onGroupClick(group)"
          >
            {{ formatGroupTitle(group) }}
          </a>
        </div>

        <!-- Content Area -->
        <div class="settings-wrapper" v-if="!isLoading">
          <div v-for="setting in groupSettings" :key="setting.key" class="setting-item">
            <div class="card setting-card">
              <div class="card-body">
                <div class="form-group">
                  <label :for="setting.key" class="form-label setting-label">
                    {{ formatSettingKey(setting.key) }}
                  </label>

                  <!-- HTML Editor -->
                  <WysiwygEditor
                    v-if="setting.type === 'HTML'"
                    v-model="setting.value"
                    :id="setting.key"
                    @input="onSettingChange(setting)"
                  />

                  <!-- JSON Editor -->
                  <textarea
                    v-else-if="setting.type === 'JSON'"
                    class="form-control code-editor"
                    :id="setting.key"
                    v-model="setting.value"
                    rows="5"
                    @input="onSettingChange(setting)"
                  ></textarea>

                  <!-- Boolean Toggle -->
                  <div v-else-if="setting.type === 'BOOLEAN'" class="boolean-toggle">
                    <CSwitch
                      v-model="setting.value"
                      :id="setting.key"
                      color="primary"
                      variant="3d"
                      @update:checked="onSettingChange(setting)"
                    />
                  </div>

                  <!-- Default Text Input -->
                  <CInput
                    v-else
                    :id="setting.key"
                    v-model="setting.value"
                    class="setting-input"
                    @input="onSettingChange(setting)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <COverlay :show="isLoading" color="light" centered>
      <CSpinner color="primary" grow />
    </COverlay>
  </div>
</template>

<script>
import toastPlugin from "@/toastPlugin";
import WysiwygEditor from '@/components/WysiwygEditor'
import axios from 'axios'
import { CSwitch } from '@coreui/vue'
import { debounce } from 'lodash'

const jwtToken = localStorage.getItem('jwtToken')?.replace(/"/g, '')
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL.split('/backend')[0],
  headers: { 'Authorization': `Bearer ${jwtToken}` }
})

export default {
  name: 'DefaultRealmSettings',

  components: {
    WysiwygEditor,
    toastPlugin,
    CSwitch,
  },

  data() {
    return {
      isLoading: false,
      groups: [],
      activeGroup: null,
      groupSettings: [],
      updateQueue: new Map()
    }
  },

  created() {
    this.debouncedUpdate = debounce(() => this.saveSettings(), 500)
    this.init()
  },

  methods: {
    async init() {
      try {
        this.isLoading = true
        const response = await axiosInstance.get('/api/v1/admin/default-realm-settings/groups')
        this.groups = response.data
        if (this.groups.length > 0) {
          await this.onGroupClick(this.groups[0])
        }
      } catch (error) {
        console.error('Fehler beim Laden der Gruppen:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Einstellungsgruppen')
      } finally {
        this.isLoading = false
      }
    },

    async onGroupClick(group) {
      if (!group || group === this.activeGroup) return

      this.isLoading = true
      this.activeGroup = group

      try {
        const response = await axiosInstance.get('/api/v1/admin/default-realm-settings', {
          params: { group }
        })
        this.groupSettings = Array.isArray(response.data) ? response.data : []
      } catch (error) {
        console.error('Fehler beim Laden der Einstellungen:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Einstellungen')
      } finally {
        this.isLoading = false
      }
    },

    formatSettingKey(key) {
      if (!key) return ''
      const parts = key.split('_')
      parts.shift() // Remove group prefix
      return parts.join(' ').replace(/([A-Z])/g, ' $1').trim()
    },

    formatGroupTitle(group) {
      const groupMappings = {
        'chatbot': 'Chatbot',
        'email': 'E-Mail',
        'iframe': 'iFrame',
        'openimmo': 'OpenImmo',
        'optin': 'Opt-In',
        'pdf': 'PDF'
      }
      return groupMappings[group] || group.charAt(0).toUpperCase() + group.slice(1).toLowerCase()
    },

    onSettingChange(setting) {
      if (!setting?.key) return
      this.updateQueue.set(setting.key, setting)
      this.debouncedUpdate()
    },

    async saveSettings() {
      const updates = Array.from(this.updateQueue.values())
      this.updateQueue.clear()

      if (updates.length === 0) return

      try {
        await axiosInstance.put('/api/v1/admin/default-realm-settings/bulk',
          updates.reduce((acc, setting) => {
            acc[setting.key] = setting.value
            return acc
          }, {})
        )
        toastPlugin.makeToast('Erfolg', 'Einstellungen wurden gespeichert')
      } catch (error) {
        console.error('Fehler beim Speichern:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Speichern der Einstellungen')
      }
    },

    showToast(header, content) {
      this.$root.$refs.toaster?.makeToast(header, content)
    }
  }
}
</script>

<style scoped>
.settings-container {
  padding: 1.5rem;
  background-color: #f8f9fa;
}

.nav-tabs {
  border-bottom: 2px solid #dee2e6;
  background-color: white;
  border-radius: 4px;
}

.nav-link {
  color: #495057;
  padding: 1rem 1.5rem;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  margin-bottom: -2px;
}

.nav-link:hover {
  color: #007bff;
  background-color: #f8f9fa;
}

.nav-link.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
  background-color: white;
}

.setting-item {
  margin-bottom: 1.5rem;
}

.setting-card {
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.setting-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.setting-label {
  font-size: 1rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.75rem;
}

.code-editor {
  font-family: monospace;
  font-size: 0.9rem;
  line-height: 1.4;
  padding: 1rem;
  background-color: #f7fafc;
}

.boolean-toggle {
  padding: 0.5rem 0;
}

@media (max-width: 768px) {
  .settings-container {
    padding: 1rem;
  }

  .nav-link {
    padding: 0.75rem 1rem;
  }
}
</style>
