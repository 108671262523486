<!-- src/components/google-my-business/SyncManager.vue -->
<template>
  <CCard>
    <CCardHeader>
      <h4>Synchronisation</h4>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12" md="6">
          <div class="sync-status">
            <p>
              <strong>Letzter Sync:</strong>
              {{ lastSyncFormatted }}
            </p>
            <p v-if="syncInProgress">
              <CSpinner size="sm" /> Synchronisation läuft...
            </p>
          </div>
        </CCol>
        <CCol sm="12" md="6" class="d-flex justify-content-end">
          <CButton
              color="primary"
              @click="startSync"
              :disabled="syncInProgress"
          >
            <CIcon name="cil-sync" :class="{ 'icon-spin': syncInProgress }" />
            Jetzt synchronisieren
          </CButton>
        </CCol>
      </CRow>

      <!-- Sync History -->
      <CRow class="mt-4">
        <CCol sm="12">
          <h5>Synchronisationsverlauf</h5>
          <CDataTable
              :items="syncHistory"
              :fields="historyFields"
              hover
              striped
              :items-per-page="5"
              pagination
          >
            <template #status="{item}">
              <CBadge :color="getSyncStatusColor(item.status)">
                {{ item.status }}
              </CBadge>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'SyncManager',

  data() {
    return {
      syncInProgress: false,
      syncHistory: [],
      historyFields: [
        { key: 'timestamp', label: 'Zeitpunkt' },
        { key: 'status', label: 'Status' },
        { key: 'details', label: 'Details' }
      ]
    }
  },

  computed: {
    ...mapState({
      connectionStatus: state => state.googleMyBusiness.connectionStatus
    }),

    lastSyncFormatted() {
      return this.connectionStatus.lastSync
          ? moment(this.connectionStatus.lastSync).format('DD.MM.YYYY HH:mm:ss')
          : 'Noch keine Synchronisation'
    }
  },

  methods: {
    async startSync() {
      this.syncInProgress = true
      try {
        await this.$axios.post('/api/v1/google-my-business/locations/sync')
        this.$toast.success('Synchronisation erfolgreich gestartet')
        await this.fetchSyncHistory()
      } catch (error) {
        this.$toast.error('Fehler beim Starten der Synchronisation')
      } finally {
        this.syncInProgress = false
      }
    },

    async fetchSyncHistory() {
      try {
        const response = await this.$axios.get('/api/v1/google-my-business/sync/history')
        this.syncHistory = response.data
      } catch (error) {
        this.$toast.error('Fehler beim Laden des Synchronisationsverlaufs')
      }
    },

    getSyncStatusColor(status) {
      const colors = {
        'SUCCESS': 'success',
        'FAILED': 'danger',
        'IN_PROGRESS': 'warning',
        'default': 'secondary'
      }
      return colors[status] || colors.default
    }
  },

  created() {
    this.fetchSyncHistory()
  }
}
</script>

<style scoped>
.icon-spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>
