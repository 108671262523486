<template>
  <div class="login-container">
    <div class="login-overlay">
      <div class="login-card">
        <div class="logo-container">
          <img src="@/assets/img/brand/logo.svg" alt="Logo" class="logo" />
        </div>

        <template v-if="!successfulReset">
          <form @submit.prevent="submit" class="login-form">
            <h1>Passwort vergessen?</h1>
            <p>
              Kein Problem! Geben Sie Ihre bei uns hinterlegte E-Mail-Adresse an,
              um Ihr Passwort zurückzusetzen.
            </p>

            <div class="form-group">
              <input
                  v-model="email"
                  type="email"
                  placeholder="E-Mail-Adresse"
                  :class="{ 'input-error': error }"
                  autocomplete="email"
              />
              <span v-if="error" class="error-text">{{ error }}</span>
            </div>

            <div class="button-group">
              <button
                  type="submit"
                  class="submit-button"
                  :disabled="isSubmitting"
              >
                <span v-if="!isSubmitting">Zurücksetzen</span>
                <span v-else class="loading-spinner"></span>
              </button>

              <router-link to="/pages/login" class="back-link">
                Zurück zum Login
              </router-link>
            </div>
          </form>
        </template>

        <div v-else class="success-container">
          <div class="success-icon">✓</div>
          <h2>E-Mail wurde versendet!</h2>
          <p>
            Wir haben Ihnen eine E-Mail an <strong>{{ email }}</strong> mit einem
            Link zum Zurücksetzen Ihres Passworts geschickt.
          </p>
          <p class="hint">
            Falls Sie keine E-Mail von uns finden, schauen Sie auch in Ihren
            Spam-Ordner.
          </p>
          <router-link to="/pages/login" class="login-return-button">
            Zurück zum Login
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: this.$route.params.email || "",
      error: "",
      isSubmitting: false,
      successfulReset: false,
    };
  },
  methods: {
    validateEmail() {
      if (!this.email) {
        this.error = "Bitte geben Sie Ihre E-Mail-Adresse ein";
        return false;
      }
      if (!this.email.includes("@")) {
        this.error = "Bitte geben Sie eine gültige E-Mail-Adresse ein";
        return false;
      }
      return true;
    },
    async submit() {
      if (!this.validateEmail()) return;

      this.isSubmitting = true;
      this.error = "";

      try {
        const response = await axios.get("/user/passwordForgot?email=" + this.email);
        this.successfulReset = true;
        this.$toaster.makeToast(
            "Unterwegs!",
            `Wir haben Ihnen eine E-Mail an "${response.data.email}" mit einem Link zum Zurücksetzen Ihres Passworts geschickt.`
        );
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              this.error = "Dieser Nutzer existiert nicht.";
              break;
            case 400:
              this.error = "Bitte warten Sie 5 Minuten bevor Sie es erneut versuchen.";
              break;
            default:
              this.error = err.response.data.message || "Ein unerwarteter Fehler ist aufgetreten.";
          }
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
}

.login-overlay {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 480px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.logo-container {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  width: 150px;
  filter: brightness(0) invert(1);
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-form h1 {
  color: white;
  font-size: 28px;
  margin: 0;
  text-align: center;
}

.login-form p {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin: 0;
  line-height: 1.5;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

input {
  width: 100%;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 16px;
  transition: all 0.3s ease;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.15);
}

.input-error {
  border-color: #ff4444;
}

.error-text {
  color: #ff4444;
  font-size: 14px;
  margin-top: 5px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.submit-button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background: white;
  color: #2a5298;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.back-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: white;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(42, 82, 152, 0.3);
  border-radius: 50%;
  border-top-color: #2a5298;
  animation: spin 1s linear infinite;
}

.success-container {
  text-align: center;
  color: white;
}

.success-icon {
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  font-size: 30px;
  color: #4CAF50;
}

.success-container h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.success-container p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 15px;
  line-height: 1.5;
}

.hint {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 20px;
}

.login-return-button {
  display: inline-block;
  padding: 12px 30px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  color: white;
  text-decoration: none;
  margin-top: 20px;
  transition: all 0.3s ease;
}

.login-return-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .login-card {
    padding: 20px;
  }

  .success-container h2 {
    font-size: 20px;
  }
}
</style>
