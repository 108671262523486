var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CInput',{attrs:{"label":_vm.label,"name":_vm.name,"value":_vm.computedValue,"required":_vm.required,"isValid":_vm.validateSubdomain,"invalidFeedback":_vm.invalidFeedback},on:{"input":_vm.inputEvent},scopedSlots:_vm._u([(!_vm.customDomain)?{key:"append",fn:function(){return [_c('CSelect',{staticClass:"prt-landingpage-domain-input-select",attrs:{"value":_vm.chosenPrtDomain,"required":"","options":[
          '.immobilienmakler-rheinbach.de',
          '.gardasee-wohnungen.de',
          '.gardaseewohnungen.de',
          '.gratis-immobewertung.de',
          '.haus-verkaufen-haan.de',
          '.ihr-immobilienmakler-koeln.de',
          '.immobilien-bad-kissingen.de',
          '.immobilien-badtoelz.de',
          '.immobilien-datteln.de',
          '.immobilien-geilenkirchen.de',
          '.immobilien-lead.de',
          '.immobilien-luftbilder.de',
          '.immobilien-makler-bonn.de',
          '.immobilien-makler-duesseldorf.de',
          '.immobilien-news.at',
          '.immobilien-wertberechnung.de',
          '.immobilien-wertheim.de',
          '.immobilien-winterberg.de',
          '.immobilienangebote-augsburg.de',
          '.immobilienangebote-berlin.de',
          '.immobilienangebote-buende.de',
          '.immobilienangebote-fuerth.de',
          '.immobilienangebote-gladbeck.de',
          '.immobilienangebote-muenchen.de',
          '.immobilienangebote-passau.de',
          '.immobilienauktion-bergisches-land.de',
          '.immobilienbewertung-arnsberg.de',
          '.immobilienbewertung-baden-baden.de',
          '.immobilienbewertung-bergisch-gladbach.de',
          '.immobilienbewertung-bergisches-land.de',
          '.immobilienbewertung-bogenhausen.de',
          '.immobilienbewertung-detmold.de',
          '.immobilienbewertung-dinslaken.de',
          '.immobilienbewertung-dormagen.de',
          '.immobilienbewertung-eckernfoerde.de',
          '.immobilienbewertung-eifel.de',
          '.immobilienbewertung-freising.de',
          '.immobilienbewertung-gladbeck.de',
          '.immobilienbewertung-grevenbroich.de',
          '.immobilienbewertung-gruenwald.de',
          '.immobilienbewertung-herne.de',
          '.immobilienbewertung-hilden.de',
          '.immobilienbewertung-iserlohn.de',
          '.immobilienbewertung-kerpen.de',
          '.immobilienbewertung-koeln-bonn.de',
          '.immobilienbewertung-koelnbonn.de',
          '.immobilienbewertung-leipzig.info',
          '.immobilienbewertung-leverkusen.de',
          '.immobilienbewertung-lippstadt.de',
          '.immobilienbewertung-minden.de',
          '.immobilienbewertung-passau.de',
          '.immobilienbewertung-ravensburg.de',
          '.immobilienbewertung-recklinghausen.de',
          '.immobilienbewertung-rheine.de',
          '.immobilienbewertung-salzgitter.de',
          '.immobilienbewertung-starnberg.de',
          '.immobilienbewertung-starnberg.info',
          '.immobilienbewertung-stralsund.de',
          '.immobilienbewertung-thueringen.de',
          '.immobilienbewertungen-online.de',
          '.immobilienmakler-ahaus.de',
          '.immobilienmakler-bad-salzuflen.de',
          '.immobilienmakler-bad-toelz.de',
          '.immobilienmakler-bad-vilbel.de',
          '.immobilienmakler-badtoelz.de',
          '.immobilienmakler-beckum.de',
          '.immobilienmakler-berchtesgaden.de',
          '.immobilienmakler-bergkamen.de',
          '.immobilienmakler-berlin-charlottenburg.de',
          '.immobilienmakler-berlin-mitte.de',
          '.immobilienmakler-berlin-wilmersdorf.de',
          '.immobilienmakler-bonn-immobilien.de',
          '.immobilienmakler-borken.de',
          '.immobilienmakler-daun.de',
          '.immobilienmakler-duesseldorf.immo',
          '.immobilienmakler-eifel.de',
          '.immobilienmakler-gauting.de',
          '.immobilienmakler-greven.de',
          '.immobilienmakler-gronau.de',
          '.immobilienmakler-haltern.de',
          '.immobilienmakler-heppenheim.de',
          '.immobilienmakler-italien.de',
          '.immobilienmakler-kempten.de',
          '.immobilienmakler-koeln-bonn.de',
          '.immobilienmakler-koeln.net',
          '.immobilienmakler-kreuzberg.de',
          '.immobilienmakler-lampertheim.de',
          '.immobilienmakler-leverkusen.immo',
          '.immobilienmakler-memmingen.de',
          '.immobilienmakler-nuertingen.de',
          '.immobilienmakler-raesfeld.de',
          '.immobilienmakler-refrath.de',
          '.immobilienmakler-reken.de',
          '.immobilienmakler-rhein-main.de',
          '.immobilienmakler-rheinbach.de',
          '.immobilienmakler-sachsen.de',
          '.immobilienmakler-schermbeck.de',
          '.immobilienmakler-taunusstein.de',
          '.immobilienmakler-thueringen.de',
          '.immobilienmakler-velbert.de',
          '.immobilienmakler-viernheim.de',
          '.immobilienmakler-worms.de',
          '.immobilienmakler.estate',
          '.immobilienmakler.haus',
          '.immobilienmaklerin-hamburg.de',
          '.immobilienmaklerin-muenchen.de',
          '.immobilienmaklernrw.de',
          '.immobilienmarkt-ruhrgebiet.de',
          '.immobilienverkauf-neuss.de',
          '.immobilienvermietung-frankfurt.de',
          '.immobilienwert-hannover.de',
          '.immobilienwert-koeln.de',
          '.immobilienwert.online',
          '.immobilienwertberechnung.de',
          '.leben-am-gardasee.de',
          '.lebenamgardasee.de',
          '.makler-dorsten.de',
          '.makler-dorsten.info',
          '.makler-meerbusch.de',
          '.makler-schermbeck.de',
          '.muenchens-immobilienmakler.de',
          '.online-immobilienbewertungen.de',
          '.online-leadgenerierung.de',
          '.stuttgart-immobilien-makler.de',
          '.top-immobilien-hannover.de',
          '.verkehrswert-bayern.de',
        ]},on:{"update:value":function($event){_vm.chosenPrtDomain = $event}}})]},proxy:true}:null,{key:"description",fn:function(){return [(_vm.customDomain)?[_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(" Bitte geben Sie Ihre Landingpage Domain ein. Diese Domain muss auf unseren Server ("),_c('b',[_vm._v("landingpage.makler-anfragen.immo")]),_vm._v(") zeigen. ")]),_c('CButton',{staticClass:"mt-1 prt-no-focus-box-shadow",attrs:{"variant":"outline","color":"info"},on:{"click":function($event){_vm.customDomain = !_vm.customDomain}}},[_vm._v(" Unsere Domains verwenden ")])]:[_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(" Bitte geben Sie eine Subdomain ein. ")]),_c('CButton',{staticClass:"mt-1 prt-no-focus-box-shadow",attrs:{"variant":"outline","color":"danger"},on:{"click":function($event){_vm.customDomain = !_vm.customDomain}}},[_vm._v(" Eigene Domain verwenden ")])]]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }