<template>
  <div class="modern-header">
    <div class="header-container">
      <!-- Left Section -->
      <div class="header-left">
        <div class="page-info">
          <CIcon name="cil-arrow-left" class="cursor-pointer" @click="$emit('back')" />
          <h2 class="page-title">{{ pageName }}</h2>
          <div :class="['save-status', saveStatus]">
            <CIcon :name="saveStatusIcon" />
            <span>{{ saveStatusText }}</span>
          </div>
        </div>
      </div>

      <!-- Right Section -->
      <div class="header-right">
        <!-- Undo/Redo Buttons -->
        <div class="control-group">
          <CButton
            color="info"
            variant="ghost"
            size="sm"
            :disabled="!canUndo"
            @click="$emit('undo')"
          >
            <CIcon name="cil-action-undo" />
          </CButton>
          <CButton
            color="info"
            variant="ghost"
            size="sm"
            :disabled="!canRedo"
            @click="$emit('redo')"
          >
            <CIcon name="cil-action-redo" />
          </CButton>
        </div>

        <!-- View Mode Toggle -->
        <div class="control-group">
          <CButton
            color="info"
            :variant="previewMode === 'desktop' ? 'ghost' : 'outline'"
            size="sm"
            :class="{ active: previewMode === 'desktop' }"
            @click="$emit('update:previewMode', 'desktop')"
          >
            <CIcon name="cil-screen-desktop" />
          </CButton>
          <CButton
            color="info"
            :variant="previewMode === 'mobile' ? 'ghost' : 'outline'"
            size="sm"
            :class="{ active: previewMode === 'mobile' }"
            @click="$emit('update:previewMode', 'mobile')"
          >
            <CIcon name="cil-mobile" />
          </CButton>
        </div>

        <!-- Action Buttons -->
        <div class="action-buttons">
          <CButton
            color="info"
            variant="ghost"
            size="sm"
            @click="$emit('add-module')"
          >
            <CIcon name="cil-plus" /> Modul
          </CButton>

          <CButton
            color="info"
            variant="outline"
            size="sm"
            @click="$emit('preview')"
          >
            <CIcon name="cil-monitor" /> Vorschau
          </CButton>

          <CButton
            color="info"
            variant="ghost"
            size="sm"
            @click="$emit('open-templates')"
          >
            <CIcon name="cil-folder-open" /> Vorlagen
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModernHeader',

  props: {
    pageName: {
      type: String,
      default: 'Seite 1'
    },
    saveStatus: {
      type: String,
      default: 'saved',
      validator: value => ['saved', 'saving', 'error'].includes(value)
    },
    canUndo: {
      type: Boolean,
      default: false
    },
    canRedo: {
      type: Boolean,
      default: false
    },
    previewMode: {
      type: String,
      default: 'desktop',
      validator: value => ['desktop', 'mobile'].includes(value)
    }
  },

  computed: {
    buttonVariant() {
      return this.previewMode === 'desktop' ? 'ghost' : 'outline';
    },

    saveStatusIcon() {
      const icons = {
        saved: 'cil-check-circle',
        saving: 'cil-loop-circular',
        error: 'cil-warning'
      };
      return icons[this.saveStatus];
    },

    saveStatusText() {
      const texts = {
        saved: 'Alle Änderungen gespeichert',
        saving: 'Speichert...',
        error: 'Fehler beim Speichern'
      };
      return texts[this.saveStatus];
    }
  }
};
</script>
export default {
  name: 'ModernHeader',

  props: {
    pageName: {
      type: String,
      default: 'Seite 1'
    },
    saveStatus: {
      type: String,
      default: 'saved',
      validator: value => ['saved', 'saving', 'error'].includes(value)
    },
    canUndo: {
      type: Boolean,
      default: false
    },
    canRedo: {
      type: Boolean,
      default: false
    },
    previewMode: {
      type: String,
      default: 'desktop',
      validator: value => ['desktop', 'mobile'].includes(value)
    }
  },

  computed: {
    buttonVariant() {
      return this.previewMode === 'desktop' ? 'solid' : 'outline';
    },

    saveStatusIcon() {
      const icons = {
        saved: 'cil-check-circle',
        saving: 'cil-loop-circular',
        error: 'cil-warning'
      };
      return icons[this.saveStatus];
    },

    saveStatusText() {
      const texts = {
        saved: 'Alle Änderungen gespeichert',
        saving: 'Speichert...',
        error: 'Fehler beim Speichern'
      };
      return texts[this.saveStatus];
    }
  }
};
</script>

<style lang="scss" scoped>
.modern-header {
  background: #fff;
  border-bottom: 1px solid #e5e7eb;

  .header-container {
    padding: 0.75rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .header-left {
    .page-info {
      display: flex;
      align-items: center;
      gap: 1rem;

      .page-title {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
        color: #1f2937;
      }
    }

    .save-status {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.25rem 0.75rem;
      border-radius: 9999px;
      font-size: 0.875rem;

      &.saved {
        background: rgba(16, 185, 129, 0.1);
        color: #10b981;
      }

      &.saving {
        background: rgba(59, 130, 246, 0.1);
        color: #3b82f6;
      }

      &.error {
        background: rgba(239, 68, 68, 0.1);
        color: #ef4444;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  .control-group {
    display: flex;
    gap: 0.25rem;

    .btn {
      padding: 0.375rem;
      border: 1px solid var(--info);
      color: var(--info);

      &.active {
        background: var(--info);
        color: white;
      }

      &:hover:not(:disabled) {
        background: var(--info);
        color: white;
      }

      &:disabled {
        opacity: 0.5;
        border-color: #cbd5e1;
        color: #94a3b8;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 0.5rem;

    .btn {
      display: flex;
      align-items: center;
      gap: 0.375rem;

      &[variant="outline"] {
        &:hover {
          background: var(--info);
          color: white;
        }
      }
    }
  }

  .c-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
