<template>
  <CModal :title="title" :show.sync="show" style="text-align: left">
    <h4>Sie sind dabei folgendes zu löschen:</h4>
    <ul>
      <li v-for="item in entities" :key="item">
        <span v-html="item" />
      </li>
    </ul>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="cancelEvent">
        Abbrechen
      </button>
      <button type="button" class="btn btn-danger" @click="confirmEvent">
        Löschen!
      </button>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "DeleteEntity",
  data() {
    return {
      show: false,
      title: null,
      entities: [],
      onCancel: null,
      onConfirm: null,
      options: {},
    };
  },
  mounted() {
    this.resetModal();
  },
  methods: {
    hideModal() {
      this.show = false;
    },
    resetModal() {
      this.title = "Sind Sie sicher?";
      this.entities = [];
      this.onCancel = () => {
        this.hideModal();
      };
      this.onConfirm = () => {
        this.hideModal();
      };
    },
    cancelEvent() {
      this.onCancel();
      this.hideModal();
    },
    confirmEvent() {
      this.onConfirm();
      this.hideModal();
    },
  },
  watch: {
    show(newVal) {
      if (newVal === false) {
        setTimeout(this.resetModal, 300);
      }
    },
  },
};
</script>

<style scoped></style>
