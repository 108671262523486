<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-grid" />
                  Benutzer
                </div>

                <div class="header-button">
                  <CButton
                    :to="{ name: 'New user' }"
                    name="backagain"
                    size="sm"
                    key="key"
                    color="dark"
                  >
                    <CIcon size="sm" name="cil-plus" />
                    <span> Neuen Benutzer hinzufügen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <p>
              Erstellen Sie neue Benutzer, passen Sie Benutzerberechtigungen an
              oder entfernen Sie Benutzer von Ihrem Account.
            </p>
            <CDataTable
              :items="tableData"
              :fields="getTableFields()"
              hover
              striped
              bordered
              fixed
              column-filter
              table-filter
              items-per-page-select
              sorter
              pagination
              :loading="tableShowLoading"
            >
              <template #role="{ item }">
                <td>
                  <CBadge :color="getTableBadgeForRole(item.role)">
                    {{
                      item.role
                        ? $commons.ROLES_MAP[item.role].title
                        : "Keine Rolle"
                    }}
                  </CBadge>
                </td>
              </template>
              <template #lastLogin="{ item }">
                <td>
                  {{ item.lastLogin || "Noch nie" }}
                </td>
              </template>
              <template #show_details="{ item }">
                <td class="py-2">
                  <CButton
                    :to="{ name: 'Edit user', params: { id: item.id } }"
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                  >
                    Edit
                  </CButton>
                </td>
              </template>
              <template #lastLogin="{ item }">
                <td>
                  {{
                    $moment(item.lastLogin).format($commons.DATE_TIME_FORMAT)
                  }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";

export default {
  name: "Users",
  components: {},
  props: {
    showIds: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableData: [],
      tableShowLoading: true,
    };
  },
  mounted() {
    axios
      .get("/user")
      .then((response) => {
        this.tableData = response.data;
        this.tableShowLoading = false;
      })
      .catch((err) => console.error("Fehler beim Abrufen der Benutzer", err));
  },
  methods: {
    getTableFields() {
      const fields = [
        { key: "email", label: "E-Mail" },
        { key: "firstName", label: "Vorname" },
        { key: "lastName", label: "Nachname" },
        { key: "role", label: "Rolle" },
        { key: "lastLogin", label: "Letzter Login" },
        { key: "created", label: "Erstellt" },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ];
      if (this.showIds)
        fields.unshift({ key: "id", label: "ID", _style: "width: 10%" });
      return fields;
    },
    getTableBadgeForRole(role) {
      switch (role) {
        case "ADMIN":
          return "danger";
        case "ORGANIZATION_ADMIN":
          return "warning";
        case "ORGANIZATION_USER":
          return "success";
        case "REALM_ADMIN":
          return "info";
        case "REALM_USER":
          return "secondary";
        default:
          return "primary";
      }
    },
  },
};
</script>
