<template>
  <div class="dashboard" :class="{ 'dark-mode': isDarkMode }">
    <!-- Header Section -->
    <header class="dashboard-header">
      <div class="header-content">
        <div class="brand-section">
          <h1 class="company-name">{{ $commons.getCurrentRealm().name }}</h1>
          <div class="period-selector">
            <CDropdown
              :show="isDropdownOpen"
              @update:show="isDropdownOpen = $event"
              class="period-dropdown"
            >
              <template #toggler>
                <CButton color="primary" variant="outline">
                  {{ dashboardMetricsPeriod }}
                  <CIcon name="cil-chevron-bottom" />
                </CButton>
              </template>
              <CDropdownItem
                v-for="period in quickPeriods"
                :key="period.value"
                href="#"
                @click="setDashboardMetricsPeriod(period.value)"
              >
                {{ period.label }}
              </CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem
                v-for="period in periods"
                :key="period"
                href="#"
                @click="handlePeriodSelect(period)"
              >
                {{ period }}
              </CDropdownItem>
            </CDropdown>
          </div>
        </div>
        <div class="header-actions">
          <CButton
            color="light"
            class="refresh-btn"
            @click="refreshAllData"
            :disabled="isUpdating"
          >
            <CIcon
              name="cil-reload"
              :class="{ 'spinning': isUpdating }"
            />
            Aktualisieren
          </CButton>
          <CButton
            color="light"
            class="theme-btn"
            @click="toggleTheme"
          >
            <CIcon :name="isDarkMode ? 'cil-sun' : 'cil-moon'" />
          </CButton>
        </div>
      </div>
    </header>

    <!-- Main Dashboard Content -->
    <div class="dashboard-content">
      <!-- KPI Section -->
      <section class="dashboard-section kpi-section">
        <div class="section-grid">
          <KpiCard
            v-for="(metric, index) in keyMetrics"
            :key="index"
            :title="metric.title"
            :value="metric.value"
            :icon="metric.icon"
            :trend="metric.trend"
            :color="metric.color"
            :description="metric.description"
          />
        </div>
      </section>

     <!-- Lead Performance Section -->
      <section class="dashboard-section performance-section">
        <div class="section-grid">
          <!-- Trend Chart -->
          <div class="dashboard-card trend-card">
            <div class="card-header">
              <h2>Lead-Generierung Trend</h2>
            </div>
            <div class="card-content">
              <div class="chart-wrapper">
                <CChartLine
                  v-if="!isUpdating && cardData.leadTrend?.length"
                  :datasets="leadTrendData.datasets"
                  :labels="leadTrendData.labels"
                  :options="chartOptions"
                />
                <div v-else-if="isUpdating" class="chart-loader">
                  <SkeletonChart />
                  <span>Daten werden geladen...</span>
                </div>
                <div v-else class="no-data-state">
                  <CIcon name="cil-chart-line" size="xl" />
                  <h3>Keine Trend-Daten verfügbar</h3>
                  <CButton color="primary" @click="fetchTrend">
                    Daten laden
                  </CButton>
                </div>
              </div>
            </div>
          </div>

          <!-- Deal Pipeline -->
          <div class="dashboard-card pipeline-card">

            <div class="dashboard-card pipeline-card">
              <div class="card-header">
                <h2>Deal Pipeline</h2>
                <CButton
                  color="primary"
                  variant="ghost"
                  to="/deals/"
                  size="sm"
                >
                  Alle Deals
                </CButton>
              </div>
              <div class="card-content">
                <DealPipeline
                    v-if="!isUpdating"
                    :deals="activeDeals"
                    :pipeline-steps="pipelineSteps"
                    @click="showDealModal"
                    @edit="showDealEditModal"
                    @delete="showDealDeleteModal"
                />
                <div v-else class="chart-loader">
                  <SkeletonChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Additional Metrics Section -->
      <section class="dashboard-section metrics-section">
        <div class="section-grid">
          <!-- Lead Sources -->
          <div class="dashboard-card sources-card">
            <div class="card-header">
              <h2>Lead-Quellen</h2>
            </div>
            <div class="card-content">
              <div v-if="!isUpdating && Object.keys(cardData.leadSources).length" class="chart-layout">
                <div class="chart-area">
                  <CChartPie
                    :datasets="leadSourceData.datasets"
                    :labels="leadSourceData.labels"
                    :options="pieChartOptions"
                  />
                </div>
                <div class="legend-area">
                  <div
                    v-for="(value, index) in leadSourceData.datasets[0].data"
                    :key="index"
                    class="legend-item"
                    :title="leadSourceData.labels[index]"
                  >
                    <div class="legend-main">
                      <span
                        class="legend-color"
                        :style="{ backgroundColor: leadSourceData.datasets[0].backgroundColor[index] }"
                      ></span>
                      <span class="legend-label">{{ leadSourceData.labels[index] }}</span>
                    </div>
                    <span class="legend-value">{{ formatNumber(value) }}</span>
                  </div>
                </div>
              </div>
              <div v-else-if="isUpdating" class="chart-loader">
                <SkeletonChart />
                <span>Daten werden geladen...</span>
              </div>
              <div v-else class="no-data-state">
                <CIcon name="cil-pie-chart" size="xl" />
                <h3>Keine Quellen-Daten verfügbar</h3>
              </div>
            </div>
          </div>

          <!-- Geographic Distribution -->
          <div class="dashboard-card map-card" :class="{ 'is-fullscreen': isMapFullscreen }">
            <div class="card-header">
              <h2>Geografische Verteilung</h2>
              <div class="header-actions">
                <button
                  v-if="isMapFullscreen"
                  class="close-btn"
                  @click="closeFullscreen"
                  aria-label="Schließen"
                >
                  <CIcon name="cil-x" />
                </button>
                <button
                  class="map-fullscreen-btn"
                  @click="toggleMapFullscreen"
                  :aria-label="isMapFullscreen ? 'Vollbildmodus beenden' : 'Vollbildmodus'"
                >
                  <CIcon
                    :name="isMapFullscreen ? 'cil-fullscreen-exit' : 'cil-fullscreen'"
                  />
                </button>
              </div>
            </div>
            <div class="card-content">
              <LeadMap
                v-if="leads.length"
                :markers="mapMarkers"
                :center="center"
                :dark-mode="isDarkMode"
                :is-fullscreen="isMapFullscreen"
              />
              <div v-else class="no-data-state">
                <CIcon name="cil-map" size="xl" />
                <h3>Keine Geo-Daten verfügbar</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Loading Overlay -->
    <LoadingOverlay
      v-if="isUpdating"
      :progress="loadingProgress"
    />
    <!-- Deal Modal -->
    <DealModal
      ref="dealModal"
      :visible.sync="dealModalConfig.visible"
      :mode="dealModalConfig.mode"
      :deal="dealModalConfig.deal"
      :pipeline-steps="pipelineSteps"
      :selected-step="dealModalConfig.selectedStep"
      @deal-added="handleDealAdded"
      @deal-updated="handleDealUpdated"
    />

    <!-- Activity Modal -->
    <CModal
      title="Aktivität planen"
      size="lg"
      :show.sync="visibleActivityModal"
      :closeOnBackdrop="false"
    >
      <ActivityWidget
        ref="activityWidget"
        :applyUpdate="applyActivityUpdate"
        :closeModal="closeActivityModal"
      />
      <template #footer>
        <CButton
          color="dark"
          variant="outline"
          @click="closeActivityModal"
          class="mr-3"
        >
          Abbrechen
        </CButton>
        <CButton
          color="success"
          @click="submitActivity"
        >
          Speichern
        </CButton>
      </template>
    </CModal>

    <!-- Notes Modal -->
    <CModal
      title="Notizen"
      :show.sync="visibleNotesModal"
      :closeOnBackdrop="false"
    >
      <NotesWidget
        ref="notesWidget"
        :applyUpdate="applyNotesUpdate"
        :closeModal="closeNotesModal"
      />
      <template #footer>
        <CButton
          color="dark"
          variant="outline"
          @click="closeNotesModal"
          class="mr-3"
        >
          Abbrechen
        </CButton>
        <CButton
          color="success"
          @click="submitNotes"
        >
          Speichern
        </CButton>
      </template>
    </CModal>
    <!-- Am Ende des Templates -->
    <DealModal
        :visible.sync="dealModalConfig.visible"
        :mode="dealModalConfig.mode"
        :deal="dealModalConfig.deal"
        :pipeline-steps="pipelineSteps"
        @deal-updated="handleDealUpdated"
    />
  </div>
</template>

<script>
import axios from 'axios';
import {
  CIcon,
  CDropdown,
  CDropdownItem,
  CDropdownDivider,
  CButton,
} from '@coreui/vue'
import moment from 'moment'
import KpiCard from '@/components/dashboard/KpiCard.vue'
import DealPipeline from '@/components/dashboard/DealPipeline.vue'
import DealModal from '@/components/deals/modals/DealModal.vue'
import LeadMap from '@/components/LeadMap.vue'
import SkeletonChart from '@/components/SkeletonChart.vue'
import LoadingOverlay from '@/components/dashboard/LoadingOverlay.vue'
import toastPlugin from "@/toastPlugin"
import ActivityWidget from '@/components/shared/widgets/ActivityWidget.vue'
import NotesWidget from '@/components/shared/widgets/NotesWidget.vue'

export default {
  name: 'Dashboard',

  components: {
    CIcon,
    CDropdown,
    CDropdownItem,
    CDropdownDivider,
    CButton,
    KpiCard,
    DealPipeline,
    DealModal,
    LeadMap,
    SkeletonChart,
    LoadingOverlay,
    ActivityWidget,
    NotesWidget,
  },

  data() {
    return {
      isDarkMode: true,
      isUpdating: false,
      loadingProgress: 0,
      isDropdownOpen: false,
      isMapFullscreen: false,
      leads: [],
      activeDeals: [],
      pipelineSteps: [],
      selectedPipeline: null,
      visibleActivityModal: false,
      visibleNotesModal: false,
      selectedTaskId: null,
      selectedNotesId: null,
      selectedDeal: null,
      center: { lat: 50.90055852891332, lng: 10.463925212353091 },
      cardData: {
        leadTrend: [],
        leadSources: {},
        totalLeads: 0,
        leads: 0,
        leadsWithPdf: 0,
        valueOfLeads: 0,
        openDeals: 0,
      },
      dealModalConfig: {
        visible: false,
        mode: 'add',
        deal: null,
        selectedStep: null
      },
      dashboardMetricsPeriod: this.$commons.getFromStorage(
        'dashboardMetricsPeriod',
        'Letzte 7 Tage'
      ),
      quickPeriods: [
        { label: '24h', value: 'Letzte 24 Stunden' },
        { label: '7T', value: 'Letzte 7 Tage' },
        { label: '30T', value: 'Letzte 30 Tage' },
      ],
      periods: [
        'Letzte 3 Monate',
        'Letzte 6 Monate',
        'Letzte 12 Monate',
        'Dieser Tag',
        'Diese Woche',
        'Dieser Monat',
        'Dieses Jahr',
        'Alle',
      ],
    }
  },

  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 10,
              fontColor: this.isDarkMode ? '#E2E8F0' : '#2D3748',
              fontSize: 12,
              fontStyle: 'bold',
              callback: function(value) {
                return Math.round(value)
              }
            },
            gridLines: {
              drawBorder: true,
              color: this.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              zeroLineColor: this.isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
              borderDash: [5, 5]
            }
          }],
          xAxes: [{
            ticks: {
              padding: 10,
              fontColor: this.isDarkMode ? '#E2E8F0' : '#2D3748',
              fontSize: 12,
              fontStyle: 'bold'
            },
            gridLines: {
              display: true,
              color: this.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              borderDash: [5, 5]
            }
          }]
        },
        elements: {
          line: {
            tension: 0.4,
            borderWidth: 3,
            borderColor: this.isDarkMode ? '#4299E1' : '#3182CE',
            backgroundColor: this.isDarkMode ? 'rgba(66, 153, 225, 0.1)' : 'rgba(49, 130, 206, 0.1)'
          },
          point: {
            radius: 6,
            hitRadius: 12,
            hoverRadius: 8,
            borderWidth: 3,
            borderColor: this.isDarkMode ? '#4299E1' : '#3182CE',
            backgroundColor: this.isDarkMode ? '#2D3748' : '#FFFFFF'
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          backgroundColor: this.isDarkMode ? 'rgba(45, 55, 72, 0.9)' : 'rgba(255, 255, 255, 0.9)',
          titleFontColor: this.isDarkMode ? '#E2E8F0' : '#2D3748',
          bodyFontColor: this.isDarkMode ? '#E2E8F0' : '#2D3748',
          borderColor: this.isDarkMode ? '#4299E1' : '#3182CE',
          borderWidth: 1,
          padding: 12,
          titleFontSize: 13,
          bodyFontSize: 13,
          displayColors: false,
          callbacks: {
            title: function(tooltipItems) {
              return 'Datum: ' + tooltipItems[0].xLabel;
            },
            label: function(tooltipItem) {
              return `Leads: ${Math.round(tooltipItem.value)}`;
            }
          }
        }
      };
    },

    pieChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        plugins: {
          legend: {
            display: false
          }
        },
        layout: {
          padding: 0
        },
        elements: {
          arc: {
            borderWidth: 1,
            borderColor: this.isDarkMode ? '#1F2937' : '#FFFFFF'
          }
        },
        tooltips: {
          enabled: true,
          mode: 'point',
          intersect: true,
          backgroundColor: this.isDarkMode ? 'rgba(17, 24, 39, 0.95)' : 'rgba(255, 255, 255, 0.95)',
          titleFontColor: this.isDarkMode ? '#F3F4F6' : '#1F2937',
          bodyFontColor: this.isDarkMode ? '#F3F4F6' : '#1F2937',
          borderColor: this.isDarkMode ? '#4299E1' : '#3182CE',
          borderWidth: 1,
          padding: 8,
          displayColors: true,
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const total = dataset.data.reduce((acc, current) => acc + current, 0);
              const currentValue = dataset.data[tooltipItem.index];
              const percentage = Math.round((currentValue / total) * 100);
              return ` ${data.labels[tooltipItem.index]}: ${currentValue} (${percentage}%)`;
            }
          }
        }
      };
    },

    keyMetrics() {
      return [
        {
          title: 'Gesamte Leads',
          value: this.formatNumber(this.cardData.totalLeads || 0),
          icon: 'cil-people',
          color: 'primary',
          description: 'Seit Beginn',
        },
        {
          title: 'Leads im Zeitraum',
          value: this.formatNumber(this.cardData.leads || 0),
          icon: 'cil-user-follow',
          color: 'success',
          description: this.dashboardMetricsPeriod,
        },
        {
          title: 'Leads mit PDF',
          value: this.formatNumber(this.cardData.leadsWithPdf || 0),
          icon: 'cil-file',
          color: 'info',
          description: 'Im gewählten Zeitraum',
        },
        {
          title: 'Potentieller Gewinn',
          value: this.formatMoney(this.cardData.valueOfLeads * 0.05),
          icon: 'cil-dollar',
          color: 'warning',
          description: 'Bei 5% Provision',
        },
      ]
    },

    mapMarkers() {
      return this.leads
        .map(lead => ({
          id: lead.id,
          position: {
            lat: lead.values?.address?.latitude || 0,
            lng: lead.values?.address?.longitude || 0,
          },
          name: lead.holder?.fullName,
          email: lead.holder?.email,
          phone: lead.holder?.phoneNumber,
          createdAt: lead.created,
          source: lead.values?.address?.source,
          address: lead.values?.address?.formattedAddress,
          hasDocument: !!lead.pdfFile,
          isNew: this.isNewLead(lead.created),
        }))
        .filter(marker => marker.position.lat !== 0 && marker.position.lng !== 0)
    },

    leadTrendData() {
      if (!this.cardData.leadTrend?.length) {
        return {
          labels: [],
          datasets: [
            {
              label: 'Leads',
              data: [],
              borderColor: this.isDarkMode ? '#4299E1' : '#3182CE',
              backgroundColor: this.isDarkMode ? 'rgba(66, 153, 225, 0.1)' : 'rgba(49, 130, 206, 0.1)',
              borderWidth: 2,
              tension: 0.4
            }
          ]
        }
      }

      const { from, to } = this.getPeriodDates()
      const filteredTrend = this.cardData.leadTrend
        .filter(item => {
          const itemDate = moment(item.date)
          return itemDate.isSameOrAfter(from) && itemDate.isSameOrBefore(to)
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date))

      return {
        labels: filteredTrend.map(item => this.formatTrendDate(item.date)),
        datasets: [
          {
            label: 'Leads',
            data: filteredTrend.map(item => item.count),
            borderColor: this.isDarkMode ? '#4299E1' : '#3182CE',
            backgroundColor: this.isDarkMode ? 'rgba(66, 153, 225, 0.1)' : 'rgba(49, 130, 206, 0.1)',
            borderWidth: 2,
            tension: 0.4
          }
        ]
      }
    },

    leadSourceData() {
      const sources = { ...this.cardData.leadSources };
      if (!Object.keys(sources).length) {
        return {
          labels: ['Keine Daten'],
          datasets: [{
            backgroundColor: [this.isDarkMode ? '#2D3748' : '#E2E8F0'],
            data: [1],
            borderWidth: 0
          }]
        };
      }

      const sortedSources = Object.entries(sources)
        .sort(([, a], [, b]) => b - a)
        .reduce((obj, [key, value]) => {
          obj[key === 'unknown' ? 'Diverse Quellen' : key] = value;
          return obj;
        }, {});

      // Basis-Farbpalette
      const baseColors = this.isDarkMode ? [
        '#4299E1', // Blau
        '#48BB78', // Grün
        '#ED8936', // Orange
        '#9F7AEA', // Lila
        '#38B2AC', // Türkis
        '#F56565'  // Rot
      ] : [
        '#3182CE', // Blau
        '#38A169', // Grün
        '#DD6B20', // Orange
        '#805AD5', // Lila
        '#319795', // Türkis
        '#E53E3E'  // Rot
      ];

      const colors = [...baseColors];
      while (colors.length < Object.keys(sortedSources).length) {
        colors.push(baseColors[colors.length % baseColors.length]);
      }

      return {
        labels: Object.keys(sortedSources),
        datasets: [{
          backgroundColor: colors,
          data: Object.values(sortedSources),
          borderWidth: 1,
          borderColor: this.isDarkMode ? '#2D3748' : '#FFFFFF'
        }]
      };
    }
  },

  methods: {
    submitActivity() {
      if (this.$refs.activityWidget) {
        this.$refs.activityWidget.submit(this.selectedTaskId);
      }
    },

    closeActivityModal() {
      if (this.$refs.activityWidget) {
        this.$refs.activityWidget.initialize();
      }
      this.visibleActivityModal = false;
    },

    submitNotes() {
      if (this.$refs.notesWidget) {
        this.$refs.notesWidget.submit(this.selectedNotesId);
      }
    },

    closeNotesModal() {
      if (this.$refs.notesWidget) {
        this.$refs.notesWidget.initialize();
      }
      this.visibleNotesModal = false;
    },

    applyActivityUpdate() {
      if (this.selectedDeal) {
        this.showDealEditModal(this.selectedDeal);
      }
    },

    applyNotesUpdate() {
      if (this.selectedDeal) {
        this.showDealEditModal(this.selectedDeal);
      }
    },

    showDealEditModal(deal) {
      this.dealModalConfig = {
        visible: true,
        mode: 'edit',
        deal: { ...deal }
      }
    },

    sshowDealDeleteModal(deal) {
      if (confirm('Sind Sie sicher, dass Sie diesen Deal löschen möchten?')) {
        axios.delete(`/deal/${deal.id}`)
          .then(() => {
            this.loadDeals() // Deals neu laden
            toastPlugin.makeToast('Erfolg', 'Deal erfolgreich gelöscht')
          })
          .catch(error => {
            console.error('Error deleting deal:', error)
            toastPlugin.makeToast('Fehler', 'Fehler beim Löschen des Deals')
          })
      }
    },
    async handleDealAdded(deal) {
      await this.loadDeals()
      toastPlugin.makeToast('Erfolg', 'Deal erfolgreich hinzugefügt')
    },

    async handleDealUpdated(deal) {
      await this.loadDeals()
      toastPlugin.makeToast('Erfolg', 'Deal erfolgreich aktualisiert')
    },
    howDealEditModal(deal) {
      // Implementierung ähnlich wie im KanbanBoard
      this.$refs.dealModal.showUpdateModal(null, deal)
    },

    showDealDeleteModal(deal) {
      if (confirm('Sind Sie sicher, dass Sie diesen Deal löschen möchten?')) {
        axios.delete(`/deal/${deal.id}`)
          .then(() => {
            this.loadDeals() // Deals neu laden
            toastPlugin.makeToast('Erfolg', 'Deal erfolgreich gelöscht')
          })
          .catch(error => {
            console.error('Error deleting deal:', error)
            toastPlugin.makeToast('Fehler', 'Fehler beim Löschen des Deals')
          })
      }
    },
    async loadPipelineData() {
      try {
        const response = await axios.get('/pipeline')
        if (response.data && response.data.length > 0) {
          const firstPipeline = response.data[0]
          this.selectedPipeline = firstPipeline.id
          this.pipelineSteps = firstPipeline.pipelineSteps.sort(
            (a, b) => a.orderNumber - b.orderNumber
          )
        }
      } catch (error) {
        console.error('Error loading pipeline:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Pipeline')
      }
    },
    formatTrendDate(date) {
      return moment(date).format('DD.MM.YY');
    },

    // Hilfsmethode für die Trend-Daten
    formatChartDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },

    // Hilfsmethode für die Anzeige von Zeiträumen
    formatDateRange(from, to) {
      return `${moment(from).format('DD.MM.YYYY')} - ${moment(to).format('DD.MM.YYYY')}`;
    },

    getPeriodDates() {
      const now = moment();
      let from, to;

      switch (this.dashboardMetricsPeriod) {
        case 'Letzte 24 Stunden':
          from = moment().subtract(24, 'hours');
          to = now;
          break;
        case 'Letzte 7 Tage':
          from = moment().subtract(7, 'days');
          to = now;
          break;
        case 'Letzte 30 Tage':
          from = moment().subtract(30, 'days');
          to = now;
          break;
        case 'Letzte 3 Monate':
          from = moment().subtract(3, 'months');
          to = now;
          break;
        case 'Letzte 6 Monate':
          from = moment().subtract(6, 'months');
          to = now;
          break;
        case 'Letzte 12 Monate':
          from = moment().subtract(12, 'months');
          to = now;
          break;
        case 'Dieser Tag':
          from = moment().startOf('day');
          to = moment().endOf('day');
          break;
        case 'Diese Woche':
          from = moment().startOf('week');
          to = moment().endOf('week');
          break;
        case 'Dieser Monat':
          from = moment().startOf('month');
          to = moment().endOf('month');
          break;
        case 'Dieses Jahr':
          from = moment().startOf('year');
          to = moment().endOf('year');
          break;
        case 'Alle':
          from = moment().subtract(10, 'years'); // Ein großer Zeitraum für "Alle"
          to = now;
          break;
        default:
          from = moment().subtract(7, 'days');
          to = now;
      }

      return { from, to };
    },

    setDashboardMetricsPeriod(period) {
      this.dashboardMetricsPeriod = period;
      this.$commons.setToStorage('dashboardMetricsPeriod', period);
      this.refreshAllData();
    },

    handlePeriodSelect(period) {
      this.setDashboardMetricsPeriod(period);
    },

    toggleMapFullscreen() {
      this.isMapFullscreen = !this.isMapFullscreen;
      this.updateBodyScroll();

      // Event Listener für Escape-Taste
      if (this.isMapFullscreen) {
        document.addEventListener('keydown', this.handleEscapeKey);
      } else {
        document.removeEventListener('keydown', this.handleEscapeKey);
      }
    },

    closeFullscreen() {
      this.isMapFullscreen = false;
      this.updateBodyScroll();
      document.removeEventListener('keydown', this.handleEscapeKey);
    },

    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        this.closeFullscreen();
      }
    },

    updateBodyScroll() {
      document.body.style.overflow = this.isMapFullscreen ? 'hidden' : '';
    },

    isNewLead(created) {
      if (!created) return false;
      const createdDate = new Date(created);
      const now = new Date();
      const diffTime = Math.abs(now - createdDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays <= 7; // Leads die jünger als 7 Tage sind werden als "neu" markiert
    },
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('darkMode', this.isDarkMode);
      if (this.isDarkMode) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    },

    formatNumber(value) {
      if (!value && value !== 0) return '0';
      return new Intl.NumberFormat('de-DE').format(value);
    },

    formatMoney(value) {
      if (!value && value !== 0) return '0,00 €';
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(value);
    },
    async loadDeals() {
      try {
        const response = await axios.get('/deal')
        this.activeDeals = response.data
      } catch (error) {
        console.error('Error loading deals:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Deals')
      }
    },

    async fetchLeads() {
      try {
        const response = await axios.get('/lead')
        this.leads = response.data.content || []
      } catch (error) {
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Leads')
        throw error
      }
    },

    async fetchSummary() {
      const { from, to } = this.getPeriodDates();
      try {
        const response = await axios.get('/dashboard/metrics/summary', {
          params: {
            from: from.toISOString(),
            to: to.toISOString()
          },
        });


        // Sicherstellen, dass wir Standardwerte haben, falls die API keine Daten zurückgibt
        this.cardData = {
          ...this.cardData,
          totalLeads: response.data?.totalLeads || 0,
          leads: response.data?.leads || 0,
          leadsWithPdf: response.data?.leadsWithPdf || 0,
          valueOfLeads: response.data?.valueOfLeads || 0,
        };

      } catch (error) {
        console.error('Error in fetchSummary:', error);
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Übersichtsdaten');
      }
    },

    async fetchTrend() {
      const { from, to } = this.getPeriodDates()
      try {

        const response = await axios.get('/dashboard/metrics/trend', {
          params: {
            from: from.toISOString(),
            to: to.toISOString()
          },
        })

        this.cardData.leadTrend = response.data || []
      } catch (error) {
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Trend-Daten')
        throw error
      }
    },

    async fetchSources() {
      const { from, to } = this.getPeriodDates()
      try {
        const response = await axios.get('/dashboard/metrics/sources', {
          params: { from: from.toISOString(), to: to.toISOString() },
        })
        this.cardData.leadSources = response.data || {}
      } catch (error) {
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Quellen-Daten')
        throw error
      }
    },

    async refreshAllData() {
      this.isUpdating = true
      this.loadingProgress = 0

      try {
        const tasks = [
          { action: this.fetchLeads, weight: 0.25 },
          { action: this.fetchSummary, weight: 0.25 },
          { action: this.fetchTrend, weight: 0.25 },
          { action: this.fetchSources, weight: 0.25 },
        ]

        for (const task of tasks) {
          await task.action()
          this.loadingProgress += task.weight * 100
          await new Promise(resolve => setTimeout(resolve, 200))
        }

        toastPlugin.makeToast('Erfolg', 'Dashboard erfolgreich aktualisiert')
      } catch (error) {
        toastPlugin.makeToast('Fehler', 'Fehler beim Aktualisieren des Dashboards')
      } finally {
        this.isUpdating = false
        this.loadingProgress = 100
        setTimeout(() => {
          this.loadingProgress = 0
        }, 500)
      }
    },

    showDealModal(deal) {
      this.dealModalConfig = {
        visible: true,
        mode: 'edit',
        deal: { ...deal }
      }
    },
  },

  async created() {
    const savedDarkMode = localStorage.getItem('darkMode')
    if (savedDarkMode !== null) {
      this.isDarkMode = JSON.parse(savedDarkMode)
    } else {
      // Wenn kein gespeicherter Wert existiert, Dark Mode als Default setzen
      this.isDarkMode = true
      localStorage.setItem('darkMode', 'true')
    }

    // Dark Mode Klasse setzen
    if (this.isDarkMode) {
      document.body.classList.add('dark-mode')
    }

    // Load initial data
    await this.refreshAllData()
    await this.loadPipelineData()
    await this.loadDeals()
  },

  beforeDestroy() {
    if (this.isMapFullscreen) {
      document.body.style.overflow = ''
    }
  },
}
</script>

<style lang="scss" scoped>
// Dashboard Layout
.dashboard {
  &-header {
    background: var(--card-bg);
    border-bottom: 1px solid var(--border-color);
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--shadow-sm);

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1600px;
      margin: 0 auto;
    }
  }

  &-content {
    padding: 0 2rem 2rem;
    max-width: 1600px;
    margin: 0 auto;
  }
}

// Cards
.dashboard-card {
  background: var(--card-bg);
  border-radius: 0.75rem;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
  }

  .card-header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--card-bg);
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      color: var(--text-primary);
      margin: 0;
    }
  }

  .card-content {
    flex: 1;
    min-height: 0;
    padding: 1.5rem;
    background: var(--card-bg);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    position: relative;
  }
}

// Trend Card
.trend-card {
  .card-content {
    height: 400px;
    min-height: 400px;
    padding: 1.5rem;
    position: relative;
    background: var(--dashboard-bg);
    display: flex;
    flex-direction: column;
  }

  .chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;

    canvas {
      filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
    }
  }

  &.dashboard-card {
    background: linear-gradient(
        to bottom,
        var(--card-bg) 0%,
        var(--dashboard-bg) 100%
    );
  }
}

// Chart Components
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.chart-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--card-bg);
  padding: 2rem;

  span {
    margin-top: 1rem;
    color: var(--text-secondary);
  }
}

.no-data-state {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: var(--text-secondary);
  text-align: center;

  i {
    font-size: 2rem;
    margin-bottom: 1rem;
    opacity: 0.5;
  }

  h3 {
    margin: 0 0 1rem 0;
    font-weight: 500;
  }
}

.sources-card {
  .card-content {
    height: 400px;
    padding: 1.5rem;
    position: relative;

    .chart-layout {
      display: flex;
      height: 100%;
      width: 100%;
      gap: 2rem;
      align-items: flex-start;

      .chart-area {
        width: 200px;
        height: 200px;
        flex: 0 0 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        position: relative;

        canvas {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }

      .legend-area {
        flex: 1;
        max-width: 260px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem 0;
        overflow-y: auto;
        margin-left: 1rem;

        .legend-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.375rem 0.5rem;
          font-size: 0.9375rem;
          border-radius: 0.375rem;
          transition: background-color 0.15s;

          &:hover {
            background-color: var(--border-color);
          }

          .legend-main {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            min-width: 0;
            flex: 1;
          }

          .legend-color {
            width: 12px;
            height: 12px;
            border-radius: 3px;
            flex-shrink: 0;
          }

          .legend-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--text-primary);
            font-weight: 500;
          }

          .legend-value {
            font-variant-numeric: tabular-nums;
            color: var(--text-secondary);
            font-weight: 500;
            margin-left: 1rem;
            min-width: 2rem;
            text-align: right;
          }
        }
      }
    }
  }

  &.dashboard-card {
    background: var(--card-bg);
  }
}

@media (max-width: 768px) {
  .sources-card {
    .card-content {
      .chart-layout {
        flex-direction: column;
        align-items: center;

        .chart-area {
          width: 180px;
          height: 180px;
          flex: 0 0 180px;
          margin: 0 0 1.5rem 0;
        }

        .legend-area {
          max-width: none;
          width: 100%;
          margin: 0;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 0.5rem;

          .legend-item {
            flex: 1 1 calc(50% - 0.5rem);
            min-width: 200px;
            background-color: var(--card-bg);
            box-shadow: var(--shadow-sm);
            padding: 0.5rem 0.75rem;
          }
        }
      }
    }
  }
}.sources-card {
   .card-content {
     height: 400px;
     padding: 1.5rem;
     position: relative;

     .chart-layout {
       display: flex;
       height: 100%;
       width: 100%;

       .chart-area {
         flex: 1.5;
         min-width: 0;
         display: flex;
         align-items: center;
         justify-content: center;
         padding-right: 2rem;

         canvas {
           width: 100% !important;
           height: auto !important;
           max-height: 340px !important;
         }
       }

       .legend-area {
         flex: 1;
         max-width: 280px;
         display: flex;
         flex-direction: column;
         gap: 0.625rem;
         padding: 1rem 0;
         overflow-y: auto;
         margin-top: 1rem;

         .legend-item {
           display: flex;
           align-items: center;
           justify-content: space-between;
           padding: 0.375rem 0.5rem;
           font-size: 0.9375rem;
           border-radius: 0.375rem;
           transition: background-color 0.15s;

           &:hover {
             background-color: var(--border-color);
           }

           .legend-main {
             display: flex;
             align-items: center;
             gap: 0.75rem;
             min-width: 0;
             flex: 1;
           }

           .legend-color {
             width: 14px;
             height: 14px;
             border-radius: 4px;
             flex-shrink: 0;
           }

           .legend-label {
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
             color: var(--text-primary);
             font-weight: 500;
           }

           .legend-value {
             font-variant-numeric: tabular-nums;
             color: var(--text-secondary);
             font-weight: 500;
             margin-left: 1rem;
           }
         }
       }
     }
   }

   &.dashboard-card {
     background: var(--card-bg);
   }

   .no-data-state {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     height: 100%;
     color: var(--text-secondary);

     i {
       font-size: 2.5rem;
       margin-bottom: 1rem;
       opacity: 0.7;
     }

     h3 {
       font-size: 1.1rem;
       font-weight: 500;
       margin-bottom: 0.5rem;
     }
   }
 }

@media (max-width: 768px) {
  .sources-card {
    .card-content {
      .chart-layout {
        flex-direction: column;

        .chart-area {
          padding: 0;
          margin-bottom: 1.5rem;
        }

        .legend-area {
          max-width: none;
          width: 100%;
          margin-top: 0;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 0.5rem;

          .legend-item {
            flex: 1 1 calc(50% - 0.5rem);
            min-width: 200px;
            background-color: var(--card-bg);
            box-shadow: var(--shadow-sm);
            padding: 0.5rem 0.75rem;
          }
        }
      }
    }
  }
}

// Grid Layout
.section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

// Header Actions
.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.refresh-btn,
.theme-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: var(--primary-color);
  }

  i {
    font-size: 1.25rem;
  }
}

// Animations
.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

// Responsive Design
@media (max-width: 768px) {
  .dashboard {
    &-header {
      padding: 1rem;
    }

    &-content {
      padding: 0 1rem 1rem;
    }
  }

  .section-grid {
    grid-template-columns: 1fr;
  }

  .dashboard-card {
    .card-header {
      padding: 1rem;
    }

    .card-content {
      padding: 1rem;
    }
  }
}
</style>
