<template>
  <div>
    <CRow>
      <CCol v-if="horizontal" sm="3" />
      <CCol
        v-if="currentList && currentList.length > 0"
        :sm="horizontal ? 9 : 12"
      >
        <div class="addable-list-wrapper">
          <div
            v-for="item in currentList"
            :class="computedAddableListItemClasses"
          >
            <div @click="removeFromList(item)" class="addable-list-item-icon">
              <CIcon name="cil-trash" height="16" />
            </div>
            {{ item }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CInput
      :label="label"
      :description="description"
      v-model="inputValue"
      @keyup.enter="addToList"
      :horizontal="horizontal"
      :isValid="invalidEntities.length === 0 ? null : false"
      :invalidFeedback="computedInvalidMessage"
      custom
    >
      <template v-slot:append>
        <CButton type="button" color="secondary" @click="addToList"
          >Hinzufügen</CButton
        >
      </template>
    </CInput>
  </div>
</template>

<script>
export default {
  name: "AddableList",
  props: {
    label: String,
    description: String,
    horizontal: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    validateRegex: {
      type: [String, RegExp],
      default: "",
    },
    invalidMessage: String,
    sideBySide: Boolean,
    allowCommaSeperated: Boolean,
    allowDuplicates: Boolean,
  },
  data() {
    return {
      currentList: [...this.value],
      inputValue: "",
      invalidEntities: [],
    };
  },
  computed: {
    computedAddableListItemClasses() {
      return this.sideBySide
        ? ["addable-list-item", "side-by-side"]
        : ["addable-list-item"];
    },
    computedInvalidMessage() {
      return this.invalidEntities.length
        ? this.invalidMessage + ` [${this.invalidEntities.join(", ")}]`
        : this.invalidMessage;
    },
  },
  methods: {
    addToList() {
      this.invalidEntities = []; // reset
      if (this.inputValue === "") return;
      if (this.allowCommaSeperated) {
        const all = this.inputValue.split(",");
        all.forEach((value) => {
          const v = value.trim();
          if (!this.validateByRegex(v)) return;
          this.pushToList(v);
        });
      } else {
        if (!this.validateByRegex()) return;
        this.pushToList(this.inputValue);
      }
      this.inputValue = "";
      this.$emit("input", this.currentList);
    },
    pushToList(value) {
      if (this.allowDuplicates || !this.currentList.includes(value)) {
        this.currentList.push(value);
      }
    },
    removeFromList(item) {
      this.currentList = this.currentList.filter((a) => a !== item);
      this.$emit("input", this.currentList);
    },
    validateByRegex(inputValue) {
      inputValue = inputValue || this.inputValue;
      const valid = this.validateRegex
        ? inputValue.match(this.validateRegex) !== null
        : true;
      if (!valid) this.invalidEntities.push(inputValue);
      return valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-input-field {
  margin: 14px 0;
}

.preview-image {
  height: 80px;
  width: auto;
}

.addable-list-wrapper {
  display: inline-block;
  margin-bottom: 8px;
  width: 100%;
}

.addable-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  &.side-by-side {
    margin-bottom: 10px;
    margin-right: 18px;
    display: inline-flex;
    width: auto;
  }
}

.addable-list-item-icon {
  background-color: #9f3a38;
  display: inline-flex;
  width: 26px;
  height: 26px;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 8px;
}

.addable-list-item-icon:hover {
  background-color: #992c29;
}
</style>
