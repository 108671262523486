<template>
  <div>
    <LgcModule
      class="lgc-module-input-range"
      @delete="$emit('delete', $event)"
      @add-below="$emit('add-below', $event)"
    >
      <div class="lgc-module-input-range__container">
        <!-- Einstellungs-Button -->
        <button
          class="lgc-module-input-range__settings-button"
          @click="openSettingsModal"
          title="Einstellungen öffnen"
        >
          <CIcon :content="icons.cilSettings" size="sm"/>
        </button>

        <RangeSlider
          :unit="dataInputUnit"
          :value="computedInputDefault"
          :min="computedInputMin"
          :max="computedInputMax"
          :labelEvery="computedLabelInterval"
          :show-min-label="true"
          :show-alternative-input="dataInputShowAlternative"
          :font-color="dataInputFontColor"
          :background-color="dataInputBackgroundColor"
          @input="handleSliderInput"
          @error="handleSliderError"
          aria-label="Range slider input"
        />
      </div>
    </LgcModule>

    <CModal
      :show.sync="modalVisible"
      :closeOnBackdrop="false"
      class="lgc-module-input-range-modal"
    >
      <template #header>
        <h4 class="modal-title">Slider Einstellungen</h4>
      </template>

      <div class="lgc-module-input-range-modal__content">
        <section class="lgc-module-input-range-modal__section">
          <h5 class="lgc-module-input-range-modal__section-title">
            Grundeinstellungen
          </h5>
          <div class="lgc-module-input-range-modal__grid">
            <div class="form-group">
              <label>Einheit</label>
              <CInput
                v-model="dataInputUnit"
                placeholder="z.B. m²"
                class="form-control-lg"
              />
            </div>

            <div class="form-group">
              <label>Mindestwert</label>
              <CInput
                type="number"
                :value="computedInputMin"
                @input="handleMinInput"
                class="form-control-lg"
                :class="{ 'is-invalid': hasMinError }"
              />
              <div class="invalid-feedback" v-if="hasMinError">
                Der Mindestwert muss kleiner als der Maximalwert sein
              </div>
            </div>

            <div class="form-group">
              <label>Maximalwert</label>
              <CInput
                type="number"
                :value="computedInputMax"
                @input="handleMaxInput"
                class="form-control-lg"
                :class="{ 'is-invalid': hasMaxError }"
              />
              <div class="invalid-feedback" v-if="hasMaxError">
                Der Maximalwert muss größer als der Mindestwert sein
              </div>
            </div>

            <div class="form-group">
              <label>Standardwert</label>
              <CInput
                type="number"
                v-model.number="dataInputDefault"
                class="form-control-lg"
                :class="{ 'is-invalid': hasDefaultError }"
              />
              <div class="invalid-feedback" v-if="hasDefaultError">
                Der Standardwert muss zwischen Min und Max liegen
              </div>
            </div>
          </div>
        </section>

        <section class="lgc-module-input-range-modal__section">
          <h5 class="lgc-module-input-range-modal__section-title">
            Design
          </h5>
          <div class="lgc-module-input-range-modal__grid">
            <div class="form-group">
              <label>Primärfarbe</label>
              <ColorPicker
                v-model="dataInputBackgroundColor"
                class="w-100"
              />
            </div>

            <div class="form-group">
              <label>Schriftfarbe</label>
              <ColorPicker
                v-model="dataInputFontColor"
                class="w-100"
              />
            </div>
          </div>

          <div class="form-group mt-4">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                :id="checkboxId"
                v-model="dataInputShowAlternative"
                @change="handleAlternativeInputChange"
              >
              <label class="custom-control-label" :for="checkboxId">
                Alternative Eingabe erlauben
              </label>
            </div>
          </div>
        </section>
      </div>

      <template #footer>
        <CButton
          color="secondary"
          variant="ghost"
          @click="handleCancel"
          type="button"
          class="mr-2"
        >
          Abbrechen
        </CButton>
        <CButton
          color="primary"
          @click="handleSave"
          type="button"
          :disabled="hasErrors"
        >
          Speichern
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { CIcon } from '@coreui/icons-vue'
import { cilSettings } from '@coreui/icons'
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
import ColorPicker from "@/components/ColorPicker";
import BooleanCheckbox from "@/components/BooleanCheckbox";
import RangeSlider from "@/components/RangeSlider";
import _ from "lodash";
import "vue-slider-component/theme/default.css";

export default {
  name: "LgcModuleInputRange",

  components: {
    RangeSlider,
    ColorPicker,
    LgcModule,
    BooleanCheckbox,
    CIcon,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
      validator: function(obj) {
        return typeof obj === 'object';
      }
    },
  },

  data() {
    return {
      icons: { cilSettings },
      modalVisible: false,
      tempData: null,
      checkboxId: `alternative-input-${Math.random().toString(36).substr(2, 9)}`,
      dataInputMin: this.value.inputMin || 0,
      dataInputMax: this.value.inputMax || 100,
      dataInputDefault: this.value.inputDefault || 50,
      dataInputUnit: this.value.inputUnit || "m²",
      dataInputShowAlternative: this.value.inputShowAlternative || false,
      dataInputFontColor: this.value.inputFontColor || "#333333",
      dataInputBackgroundColor: this.value.inputBackgroundColor || "#2D78A9",
      hasMinError: false,
      hasMaxError: false,
      hasDefaultError: false,
      sliderError: null,
    };
  },

  computed: {
    computedInputMin() {
      return Number.parseInt(this.dataInputMin) || 0;
    },

    computedInputMax() {
      return Number.parseInt(this.dataInputMax) || 100;
    },

    computedInputDefault() {
      return Number.parseInt(this.dataInputDefault) || this.computedInputMin;
    },

    computedLabelInterval() {
      return Math.max(1, Math.floor((this.computedInputMax - this.computedInputMin) / 5));
    },

    hasErrors() {
      return this.hasMinError || this.hasMaxError || this.hasDefaultError || this.sliderError;
    }
  },

  methods: {
    openSettingsModal() {
      this.tempData = _.cloneDeep({
        dataInputMin: this.dataInputMin,
        dataInputMax: this.dataInputMax,
        dataInputDefault: this.dataInputDefault,
        dataInputUnit: this.dataInputUnit,
        dataInputShowAlternative: this.dataInputShowAlternative,
        dataInputFontColor: this.dataInputFontColor,
        dataInputBackgroundColor: this.dataInputBackgroundColor,
      });
      this.$emit('modal-open');
      this.modalVisible = true;
    },

    handleSliderInput(value) {
      if (value >= this.computedInputMin && value <= this.computedInputMax) {
        this.dataInputDefault = value;
        this.hasDefaultError = false;
        this.emitInput();
      } else {
        this.hasDefaultError = true;
      }
    },

    handleSliderError(error) {
      this.sliderError = error;
    },

    handleDirectInput(event) {
      const value = Number(event.target.value);
      if (!isNaN(value)) {
        this.dataInputDefault = Math.min(
          this.computedInputMax,
          Math.max(this.computedInputMin, value)
        );
        this.emitInput();
      }
    },

    handleMinInput(event) {
      const value = Number(event);
      if (!isNaN(value)) {
        this.dataInputMin = value;
        this.hasMinError = value >= this.computedInputMax;
        this.validateDefault();
      }
    },

    handleMaxInput(event) {
      const value = Number(event);
      if (!isNaN(value)) {
        this.dataInputMax = value;
        this.hasMaxError = value <= this.computedInputMin;
        this.validateDefault();
      }
    },

    validateDefault() {
      this.hasDefaultError =
        this.computedInputDefault < this.computedInputMin ||
        this.computedInputDefault > this.computedInputMax;
    },

    handleCancel() {
      if (this.tempData) {
        Object.assign(this, this.tempData);
      }
      this.clearErrors();
      this.closeModal();
    },

    handleSave() {
      if (!this.hasErrors) {
        this.emitInput();
        this.closeModal();
      }
    },

    handleAlternativeInputChange() {
      this.emitInput();
    },

    clearErrors() {
      this.hasMinError = false;
      this.hasMaxError = false;
      this.hasDefaultError = false;
      this.sliderError = null;
    },

    closeModal() {
      this.$emit('modal-close');
      this.modalVisible = false;
      this.tempData = null;
    },

    emitInput() {
      this.$emit("input", {
        value: {
          inputMin: this.computedInputMin,
          inputMax: this.computedInputMax,
          inputDefault: this.computedInputDefault,
          inputUnit: this.dataInputUnit,
          inputShowAlternative: this.dataInputShowAlternative,
          inputFontColor: this.dataInputFontColor,
          inputBackgroundColor: this.dataInputBackgroundColor,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.lgc-module-input-range {
  --border-radius: 8px;
  --spacing-base: 1.25rem;
  --transition-speed: 0.2s;

  &__container {
    position: relative;
    padding: var(--spacing-base);
    background: #ffffff;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all var(--transition-speed) ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &__settings-button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    padding: 0.5rem;
    border: none;
    background: transparent;
    color: #6c757d;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
      color: var(--primary-color, #2D78A9);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(45, 120, 169, 0.25);
    }

    .c-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__alternative {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e9ecef;
  }

  &__alternative-label {
    display: block;
    margin-bottom: 0.75rem;
    color: #495057;
    font-weight: 500;
  }

  &__alternative-input {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    input {
      max-width: 120px;
      border: 1px solid #ced4da;
      border-radius: var(--border-radius);
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      transition: border-color var(--transition-speed) ease;

      &:focus {
        border-color: var(--primary-color, #2D78A9);
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(45, 120, 169, 0.25);
      }
    }
  }

  &__unit {
    color: #6c757d;
    font-weight: 500;
  }
}

.lgc-module-input-range-modal {
  &__content {
    padding: 1rem;
  }

  &__section {
    background: #fff;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__section-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: var(--primary-color, #2D78A9);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .form-group {
    margin-bottom: 0;

    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
      color: #495057;
    }
  }

  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.125rem;
  }

  .invalid-feedback {
    display: block;
    margin-top: 0.5rem;
  }

  .custom-control {
    padding-left: 1.75rem;
    margin-bottom: 0;

    &-input {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1.25rem;
      height: 1.25rem;
      opacity: 0;

      &:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: var(--primary-color, #2D78A9);
        background-color: var(--primary-color, #2D78A9);
      }

      &:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(45, 120, 169, 0.25);
      }
    }

    &-label {
      position: relative;
      margin-bottom: 0;
      cursor: pointer;

      &::before {
        position: absolute;
        left: -1.75rem;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        content: "";
        background-color: #fff;
        border: 1px solid #adb5bd;
        border-radius: 0.25rem;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
      }

      &::after {
        position: absolute;
        left: -1.75rem;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        content: "";
        background: no-repeat 50% / 50% 50%;
      }
    }
  }

  @media (max-width: 768px) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__alternative-input {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      input {
        max-width: 100%;
      }
    }
  }
}
</style>
