<template>
  <div class="form-group form-row">
    <CCol tag="label" sm="3" class="col-form-label">
      {{ label }}
    </CCol>
    <CCol sm="9">
      <button
        @click.prevent="showPicker = !showPicker"
        class="color-picker-btn"
        :style="{ backgroundColor: value }"
        type="button"
      >
        <span class="color-picker-btn-text">Farbe wählen</span>
      </button>
      <color-picker
        v-if="showPicker"
        :value="value"
        @input="$emit('input', $event.hex)"
      />
      <small
        style="margin-top: 8px"
        v-if="description"
        class="form-text text-muted w-100"
        v-text="description"
      />
    </CCol>
  </div>
</template>

<script>
import { Chrome } from "vue-color";

export default {
  name: "ColorPicker",
  components: {
    "color-picker": Chrome,
  },
  props: {
    value: String,
    label: String,
    description: String,
  },
  data() {
    return {
      showPicker: false,
    };
  },
};
</script>

<style scoped>
.color-picker-btn {
  margin: 4px 0 8px 0;
  border: 1px solid #aaa;
  border-radius: 2px;
  background: #a7a;
  padding: 0;
  outline: none;
  transition: box-shadow 300ms;
}

.color-picker-btn-text {
  background: #fff;
  display: block;
  margin-left: 30px;
  padding: 2px 6px;
  border: 0;
  border-radius: 0 2px 2px 0;
  border-left: 1px solid #aaa;
}

.color-picker-btn:hover {
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.2),
    0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>