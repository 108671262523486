<template>
  <div class="kanban-board">
    <CCard>
      <CCardHeader>
        <div class="header-content">
          <h2 class="mb-0">Deals</h2>
          <CSelect
            v-if="pipelines.length"
            v-model="selectedPipeline"
            :options="pipelines"
            placeholder="Pipeline wählen"
            class="pipeline-select"
            @change="logSelectedPipeline"
          />
        </div>
      </CCardHeader>

      <CCardBody>
        <div v-if="pipelines.length" class="kanban-container">
          <div
            v-for="step in selectedPipelineSteps"
            :key="step.id"
            class="kanban-column"
            @mouseover="showedButton = step.id"
            @mouseout="showedButton = null"
          >
            <div class="kanban-column-header">
              <h3>{{ step.name }}</h3>
              <div class="kanban-column-stats">
                {{ aggregateDealsOnStep(step) }}€ -
                {{ formatPluralStr(step.deals, "deal") }}
              </div>
            </div>

            <draggable
              :list="step.deals"
              group="deals"
              @change="log"
              class="kanban-list"
              :forceFallback="true"
              :class="{ dragging: showedButton === step.id && dragging }"
              v-bind="{
                ghostClass: 'ghost',
                dragClass: 'drag',
                chosenClass: 'chosen'
              }"
            >
              <div
                v-for="(deal, index) in step.deals"
                :key="deal.name + '-' + index"
                class="kanban-item"
                @click="showUpdateModal($event, deal)"
              >
                <div class="deal-info">
                  <h4>{{ deal.name }}</h4>
                  <p>{{ deal.realmPeople && deal.realmPeople.name }}</p>
                  <div class="deal-value">
                    <CIcon name="cil-user" />
                    <span>{{ deal.value ? numberWithCommas(deal.value) + "€" : "" }}</span>
                  </div>
                </div>
                <div class="deal-actions">
                  <CButton
                    @click.stop="showUpdateModal($event, deal)"
                    color="primary"
                    size="sm"
                  >
                    <CIcon name="cil-pencil" />
                  </CButton>
                  <CButton
                    @click.stop="showDeleteModal($event, deal)"
                    color="danger"
                    size="sm"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                </div>
              </div>
              <CButton
                v-show="showedButton === step.id"
                color="success"
                size="sm"
                class="mt-2"
                @click="showAddModal(step)"
              >
                <CIcon name="cil-plus" />
                Neuer Deal
              </CButton>
            </draggable>
          </div>
        </div>
        <div v-else class="no-pipeline-message">
          <h3>Bitte Pipeline hinzufügen, um Deal zu erstellen</h3>
        </div>
      </CCardBody>
    </CCard>

    <!-- Modals -->
    <DealModal
      :visible.sync="dealModalConfig.visible"
      :mode="dealModalConfig.mode"
      :deal="dealModalConfig.deal"
      :pipeline-steps="selectedPipelineSteps"
      :selected-step="dealModalConfig.selectedStep"
    @deal-added="handleDealAdded"
    @deal-updated="handleDealUpdated"
    />

    <CModal
      title="Aktivität planen"
      size="lg"
      :show.sync="visibleActivityModal"
      :closeOnBackdrop="false"
    >
      <ActivityWidget
        ref="activityWidget"
        :applyUpdate="applyActivityUpdate"
        :closeModal="closeActivityModal"
      />
      <template #footer>
        <CButton
          color="dark"
          variant="outline"
          @click="closeActivityModal"
          class="mr-3"
        >
          Abbrechen
        </CButton>
        <CButton
          color="success"
          @click="submitActivity"
        >
          Speichern
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Notizen"
      :show.sync="visibleNotesModal"
      :closeOnBackdrop="false"
    >
      <NotesWidget
        ref="notesWidget"
        :applyUpdate="applyNotesUpdate"
        :closeModal="closeNotesModal"
      />
      <template #footer>
        <CButton
          color="dark"
          variant="outline"
          @click="closeNotesModal"
          class="mr-3"
        >
          Abbrechen
        </CButton>
        <CButton
          color="success"
          @click="submitNotes"
        >
          Speichern
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import DealModal from './modals/DealModal.vue'
import ActivityWidget from '@/components/shared/widgets/ActivityWidget.vue'
import NotesWidget from '@/components/shared/widgets/NotesWidget.vue'
import axios from 'axios'
import commons from '@/commons'

export default {
  name: 'KanbanBoard',

  components: {
    draggable,
    DealModal,
    ActivityWidget,
    NotesWidget
  },

  data() {
    return {
      dealModalConfig: {
        visible: false,
        mode: 'add',
        deal: null,
        selectedStep: null
      },
      showedButton: null,
      pipelines: [],
      originalPipelines: [],
      selectedPipeline: null,
      selectedPipelineSteps: [],
      previousPipelineSteps: [],
      selectedDeal: null,
      movedDealId: null,
      dragging: false,
      visibleActivityModal: false,
      visibleNotesModal: false,
      selectedTaskId: null,
      selectedNotesId: null
    }
  },

  async mounted() {
    await this.loadPipelines()
    if (this.pipelines.length > 0) {
      this.selectedPipeline = this.pipelines[0].value
    }
  },

  methods: {
    async loadPipelines() {
      try {
        const ret = await axios.get('/pipeline')
        this.originalPipelines = ret.data || []
        this.pipelines = ret.data.map(item => ({
          value: item.id,
          label: item.name
        }))
      } catch (error) {
        console.error('Fehler beim Laden der Pipelines:', error)
      }
    },

    logSelectedPipeline(value) {
      console.log('Gewählte Pipeline:', value)
    },

    showAddModal(step) {
      this.dealModalConfig = {
        visible: true,
        mode: 'add',
        deal: {
          pipelineStepId: step.id,
          status: 'PROCESSING'
        },
        selectedStep: step
      }
    },

    showUpdateModal(e, deal) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      this.selectedDeal = deal
      this.dealModalConfig = {
        visible: true,
        mode: 'edit',
        deal: { ...deal }
      }
    },

    showDeleteModal(e, deal) {
      e.preventDefault()
      e.stopPropagation()
      if (confirm('Sind Sie sicher, dass Sie diesen Deal löschen möchten?')) {
        axios
          .delete(`/deal/${deal.id}`)
          .then(() => {
            this.loadPipelineDetail(this.selectedPipeline)
          })
          .catch(console.error)
      }
    },

    async handleDealAdded(deal) {
      const step = this.selectedPipelineSteps.find(
        step => step.id === deal.pipelineStepId
      )
      if (step) {
        step.deals.push(deal)
      }
      await this.loadPipelineDetail(this.selectedPipeline)
    },

    async handleDealUpdated(deal) {
      await this.loadPipelineDetail(this.selectedPipeline)
    },

    log(evt) {
      const keys = Object.keys(evt)
      if (keys.length > 0) {
        this.movedDealId = evt[keys[0]].element?.id
        this.handleStepChange()
      }
    },

    async loadPipelineDetail(id) {
      try {
        const pipeline = this.originalPipelines.find(p => p.id === Number(id))
        if (pipeline) {
          // Pipeline Steps laden und sortieren
          this.selectedPipelineSteps = pipeline.pipelineSteps.sort(
            (a, b) => a.orderNumber - b.orderNumber
          )

          // Deals laden
          const dealsResponse = await axios.get('/deal')
          const deals = dealsResponse.data

          // Nur Deals für die aktuelle Pipeline filtern
          const pipelineDeals = deals.filter(deal => deal.pipelineId === Number(id))

          // Deals den Steps zuordnen
          this.selectedPipelineSteps = this.selectedPipelineSteps.map(step => ({
            ...step,
            deals: pipelineDeals.filter(deal => deal.pipelineStepId === step.id) || []
          }))

          this.previousPipelineSteps = JSON.parse(JSON.stringify(this.selectedPipelineSteps))
        }
      } catch (error) {
        console.error('Fehler beim Laden der Pipeline-Details:', error)
      }
    },

    async handleStepChange() {
      for (let i = 0; i < this.previousPipelineSteps.length; i++) {
        const prevDealLen = this.previousPipelineSteps[i].deals.length
        const newDealLen = this.selectedPipelineSteps[i].deals.length
        if (newDealLen > prevDealLen) {
          await axios.post(
            `/deal/change-step/${this.movedDealId}/${this.selectedPipelineSteps[i].id}`
          )
        }
      }
      this.previousPipelineSteps = JSON.parse(
        JSON.stringify(this.selectedPipelineSteps)
      )
    },

    aggregateDealsOnStep(step) {
      if (!step.deals || !Array.isArray(step.deals)) {
        return 0;
      }
      return commons.numberWithCommas(
        step.deals.reduce((sum, deal) => sum + (deal.value || 0), 0)
      );
    },

    formatPluralStr(arr, str) {
      if (!arr || !Array.isArray(arr)) {
        return `0 ${str}s`;
      }
      return `${arr.length} ${str}${arr.length === 1 ? '' : 's'}`;
    },

    numberWithCommas(num) {
      return commons.numberWithCommas(num)
    },

    submitActivity() {
      this.$refs.activityWidget.submit(this.selectedTaskId)
    },

    closeActivityModal() {
      this.$refs.activityWidget.initialize()
      this.visibleActivityModal = false
    },

    submitNotes() {
      this.$refs.notesWidget.submit(this.selectedNotesId)
    },

    closeNotesModal() {
      this.$refs.notesWidget.initialize()
      this.visibleNotesModal = false
    },

    applyActivityUpdate() {
      this.showUpdateModal(null, this.selectedDeal)
    },

    applyNotesUpdate() {
      this.showUpdateModal(null, this.selectedDeal)
    }
  },

  watch: {
    selectedPipeline(val) {
      if (val) {
        this.loadPipelineDetail(val)
      }
    }
  }
}
</script>

<style scoped>
.kanban-board {
  background-color: #f5f7fa;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.kanban-container {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 100%;
}

.kanban-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 300px; /* Mindestbreite 300px, wächst wenn Platz verfügbar */
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.kanban-column-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.kanban-column-header h3 {
  font-size: 1.13125rem;
}

.kanban-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  min-height: 50px;
  user-select: none;
}

.kanban-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.kanban-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.deal-info h4 {
  margin: 0 0 5px;
}

.deal-value {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.deal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 10px;
}

.no-pipeline-message {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pipeline-select {
  width: 200px;
}

.kanban-column-stats {
  font-size: 0.9em;
  color: #666;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .kanban-container {
    flex-direction: column;
    align-items: center;
  }

  .kanban-column {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }
}

/* Drag & Drop Styles */
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.drag {
  transform: rotate(2deg);
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);
}

.chosen {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}
</style>
