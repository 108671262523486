<template>
  <div class="social-hub">
    <!-- Header Section -->
    <header class="hub-header">
      <div class="header-content">
        <h1 class="gradient-text">Social Hub</h1>
        <p class="subtitle">Verbinden Sie Ihre Social Media Präsenz</p>
      </div>
      <div class="stats-container">
        <div class="stat-item">
          <div class="stat-value">{{ getConnectedAccountsCount() }}/3</div>
          <div class="stat-label">Verbunden</div>
          <div class="progress-ring">
            <svg viewBox="0 0 36 36">
              <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
              <path :stroke-dasharray="`${(getConnectedAccountsCount() / 3) * 100}, 100`"
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
            </svg>
          </div>
        </div>
      </div>
    </header>

    <!-- Connection Cards -->
    <div class="connection-grid">
      <div v-for="network in socialNetworks"
           :key="network.id"
           class="connection-card"
           :class="[network.id.toLowerCase(), {'is-connected': isConnected(network.id)}]"
           @click="handleNetworkAction(network)">

        <div class="card-glass">
          <div class="network-status">
            <div class="status-indicator" :class="{'active': isConnected(network.id)}">
              <span class="pulse"></span>
            </div>
          </div>

          <div class="card-content">
            <div class="icon-wrapper">
              <CIcon :name="network.icon" />
              <div class="hover-effect"></div>
            </div>

            <div class="network-details">
              <h2>{{ network.name }}</h2>
              <p class="status-message">
                <template v-if="isConnected(network.id)">
                  Verbunden mit <strong>{{ connections[network.id].name }}</strong>
                </template>
                <template v-else>
                  Klicken zum Verbinden
                </template>
              </p>
            </div>

            <div class="action-state">
              <transition name="fade">
                <div v-if="processingStates[network.id]" class="processing">
                  <div class="loading-pulse"></div>
                </div>
                <div v-else class="connection-action">
                  <span class="action-icon">
                    {{ isConnected(network.id) ? '✓' : '+' }}
                  </span>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Connection Timeline -->
    <div class="connection-timeline">
      <div class="timeline-header">
        <h3>Verbindungsverlauf</h3>
      </div>
      <div class="timeline-events">
        <div v-for="network in socialNetworks"
             :key="network.id"
             class="timeline-event"
             :class="{'connected': isConnected(network.id)}">
          <div class="event-icon">
            <CIcon :name="network.icon" />
          </div>
          <div class="event-details">
            <h4>{{ network.name }}</h4>
            <p>{{ isConnected(network.id) ? 'Aktiv' : 'Nicht verbunden' }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Quick Actions -->
    <div class="quick-actions">
      <button class="action-button help" @click="showGuide = true">
        <span class="button-icon">?</span>
        <span class="button-text">Hilfe & Anleitung</span>
      </button>
    </div>

    <!-- Modals -->
    <FacebookModal
      v-if="showFacebookModal"
      @sdk-init="onSdkInit"
      :show-modal.sync="showFacebookModal"
    />
  </div>
</template>

<script>
import axios from 'axios'
import FacebookModal from '@/views/base/social-media/facebook/FacebookModal.vue'
import { CIcon } from '@coreui/icons-vue'

export default {
  name: 'SocialMediaConnections',

  components: {
    FacebookModal,
    CIcon
  },

  data() {
    return {
      connections: {},
      processingStates: {
        FACEBOOK: false,
        INSTAGRAM: false,
        LINKEDIN: false
      },
      showFacebookModal: false,
      showGuide: false,
      fbSdk: null,
      socialNetworks: [
        {
          id: 'FACEBOOK',
          name: 'Facebook',
          icon: 'cib-facebook'
        },
        {
          id: 'INSTAGRAM',
          name: 'Instagram',
          icon: 'cib-instagram'
        },
        {
          id: 'LINKEDIN',
          name: 'LinkedIn',
          icon: 'cib-linkedin'
        }
      ]
    }
  },

  computed: {
    isAnyConnected() {
      return Object.keys(this.connections).length > 0
    }
  },

  mounted() {
    this.loadConnections();

    // OAuth Callbacks prüfen
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code') || urlParams.has('token')) {
      const lastNetwork = localStorage.getItem('lastNetworkConnect');
      switch(lastNetwork) {
        case 'LINKEDIN':
          this.handleLinkedInCallback();
          break;
        case 'INSTAGRAM_BUSINESS':
          this.handleInstagramCallback();
          break;
        default:
          this.$router.push({ name: 'SocialMediaConnections' });
      }
    }
  },

  methods: {
    getConnectedAccountsCount() {
      return Object.keys(this.connections).length
    },

    loadConnections() {
      axios
        .get("/socialMedia/connections")
        .then((response) => {
          let content = {}
          for (const [key, value] of Object.entries(response.data)) {
            content[key] = this.flattenObject(value)
          }
          this.connections = content
        })
        .catch((err) => {
          console.error("Problem beim Laden der Social Media Verbindungen", err)
          this.$toaster.makeToast(
            "Fehler",
            "Social Media Verbindungen konnten nicht geladen werden"
          )
        })
    },

    handleNetworkAction(network) {
      switch(network.id) {
        case 'FACEBOOK':
          this.onClickFacebook()
          break
        case 'INSTAGRAM':
          this.onClickInstagram()
          break
        case 'LINKEDIN':
          this.onClickLinkedIn()
          break
      }
    },

    onClickFacebook() {
      if (this.isConnected('FACEBOOK')) {
        const facebookId = this.connections.FACEBOOK.id
        if (!facebookId) {
          this.$toaster.makeToast("Fehler", "Facebook-ID fehlt!")
          return
        }

        this.$modal.showModal(
          "delete",
          null,
          [
            `<b>Facebook-Verbindung</b> (${this.connections.FACEBOOK.name})`,
            `Alle zukünftigen Social-Media Beiträge für Facebook können nicht mehr gepostet werden.`
          ],
          async () => {
            this.processingStates.FACEBOOK = true
            try {
              await axios.delete("/socialMedia/connections/" + facebookId)
              this.$router.go()
              this.$toaster.makeToast(
                "Erfolgreich!",
                "<b>Facebook wurde erfolgreich entkoppelt!</b>"
              )
            } catch (err) {
              if (err.response) {
                this.$toaster.makeToast(
                  "Oops!",
                  "<b>" + err.response.data.code + "</b><br>" + err.response.data.message
                )
              } else {
                this.$toaster.makeToast("Error!", err.message)
              }
            } finally {
              this.processingStates.FACEBOOK = false
            }
          }
        )
      } else {
        this.showFacebookModal = true
      }
    },

    async onClickInstagram() {
      if (this.isConnected('INSTAGRAM')) {
        const instagramId = this.connections.INSTAGRAM.id;
        if (!instagramId) {
          this.$toaster.makeToast("Fehler", "Instagram-ID fehlt!");
          return;
        }

        // Entkopplungs-Dialog
        this.$modal.showModal(
            "delete",
            null,
            [
              `<b>Instagram-Verbindung</b> (${this.connections.INSTAGRAM.name})`,
              `Alle zukünftigen Social-Media Beiträge für Instagram können nicht mehr gepostet werden.`
            ],
            async () => {
              this.processingStates.INSTAGRAM = true;
              try {
                await axios.delete("/socialMedia/connections/" + instagramId);
                this.$router.go();
                this.$toaster.makeToast(
                    "Erfolgreich!",
                    "<b>Instagram wurde erfolgreich entkoppelt!</b>"
                );
              } catch (err) {
                if (err.response) {
                  this.$toaster.makeToast(
                      "Oops!",
                      "<b>" + err.response.data.code + "</b><br>" + err.response.data.message
                  );
                } else {
                  this.$toaster.makeToast("Error!", err.message);
                }
              } finally {
                this.processingStates.INSTAGRAM = false;
              }
            }
        );
      } else {
        try {
          this.processingStates.INSTAGRAM = true;
          const response = await axios.get("/socialMedia/instagram/business/auth");
          if (response.data && response.data.redirectUrl) {
            localStorage.setItem('lastNetworkConnect', 'INSTAGRAM_BUSINESS');
            localStorage.setItem('socialMediaReturnUrl', window.location.pathname);
            window.location.href = response.data.redirectUrl;
          }
        } catch (err) {
          console.error("Instagram Auth Error:", err);
          this.$toaster.makeToast(
            "Error!",
            "Fehler beim Verbinden mit Instagram: " +
            (err.response?.data?.message || err.message)
          );
        } finally {
          this.processingStates.INSTAGRAM = false;
        }
      }
    },

    onClickLinkedIn() {
      if (this.isConnected('LINKEDIN')) {
        const linkedInId = this.connections.LINKEDIN.id
        if (!linkedInId) {
          this.$toaster.makeToast("Fehler", "LinkedIn-ID fehlt!")
          return
        }

        this.$modal.showModal(
          "delete",
          null,
          [
            `<b>LinkedIn-Verbindung</b> (${this.connections.LINKEDIN.name})`,
            `Alle zukünftigen Social-Media Beiträge für LinkedIn können nicht mehr gepostet werden.`
          ],
          async () => {
            this.processingStates.LINKEDIN = true
            try {
              await axios.delete("/socialMedia/connections/" + linkedInId)
              this.$router.go()
              this.$toaster.makeToast(
                "Erfolgreich!",
                "<b>LinkedIn wurde erfolgreich entkoppelt!</b>"
              )
            } catch (err) {
              if (err.response) {
                this.$toaster.makeToast(
                  "Oops!",
                  "<b>" + err.response.data.code + "</b><br>" + err.response.data.message
                )
              } else {
                this.$toaster.makeToast("Error!", err.message)
              }
            } finally {
              this.processingStates.LINKEDIN = false
            }
          }
        )
      } else {
        this.processingStates.LINKEDIN = true
        const jwtToken = localStorage.getItem("jwtToken")
        localStorage.setItem('lastNetworkConnect', 'LINKEDIN')

        axios
          .get("/socialMedia/linkedin/auth", {
            headers: { Authorization: `Bearer ${jwtToken}` }
          })
          .then((response) => {
            window.location.href = response.data.redirectUrl
          })
          .catch((err) => {
            console.error("LinkedIn Auth Error:", err)
            this.$toaster.makeToast(
              "Error!",
              "Fehler beim Verbinden mit LinkedIn."
            )
          })
          .finally(() => {
            this.processingStates.LINKEDIN = false
          })
      }
    },

    async handleLinkedInCallback() {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      const token = urlParams.get('token') || localStorage.getItem("jwtToken")

      if (!code) {
        this.$toaster.makeToast("Fehler", "Code fehlt. Bitte erneut versuchen.")
        return
      }

      this.processingStates.LINKEDIN = true
      try {
        await axios.get(`/socialMedia/linkedin/callback?code=${encodeURIComponent(code)}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        this.$toaster.makeToast("Erfolg", "LinkedIn-Verbindung erfolgreich hergestellt.")
        this.loadConnections()
      } catch (err) {
        console.error("Fehler bei LinkedIn-Callback:", err)
        this.$toaster.makeToast(
          "Fehler",
          "LinkedIn-Verbindung fehlgeschlagen: " + (err.response?.data?.message || err.message)
        )
      } finally {
        this.processingStates.LINKEDIN = false
        localStorage.removeItem('lastNetworkConnect')
      }
    },

    async handleInstagramCallback() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state'); // Neuer Parameter für Business Auth
      const token = localStorage.getItem("jwtToken");

      // Prüfen ob es sich um Business Auth handelt
      if (state !== 'instagram_business') {
        this.$toaster.makeToast("Fehler", "Ungültige Instagram-Authentifizierung");
        return;
      }

      if (!code) {
        this.$toaster.makeToast("Fehler", "Code fehlt. Bitte erneut versuchen.");
        return;
      }

      this.processingStates.INSTAGRAM = true;
      try {
        const response = await axios.post('/socialMedia/instagram/callback',
            { code },
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data && response.data.success) {
          this.$toaster.makeToast(
              "Erfolg",
              "Instagram-Business-Account erfolgreich verbunden!"
          );
          this.loadConnections();
          this.$router.push({ name: 'SocialMediaConnections' });
        } else {
          throw new Error(response.data?.message || "Verbindung fehlgeschlagen");
        }
      } catch (err) {
        console.error("Fehler bei Instagram-Callback:", err);
        this.$toaster.makeToast(
            "Fehler",
            "Instagram-Business-Verbindung fehlgeschlagen: " +
            (err.response?.data?.message || err.message)
        );
        this.$router.push({ name: 'SocialMediaConnections' });
      } finally {
        this.processingStates.INSTAGRAM = false;
        localStorage.removeItem('lastNetworkConnect');
        localStorage.removeItem('socialMediaReturnUrl');
      }
    },

    onSdkInit(fbSdk) {
      this.fbSdk = fbSdk
    },

    isConnected(platform) {
      return this.connections.hasOwnProperty(platform)
    },

    flattenObject(obj) {
      const result = {}
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null) {
          Object.assign(result, this.flattenObject(value))
        } else {
          result[key] = value
        }
      }
      return result
    }
  }
}
</script>

<style lang="scss">
.social-hub {
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #f6f8fd 0%, #f1f4f9 100%);

  .hub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    .header-content {
      .gradient-text {
        font-size: 2.5rem;
        font-weight: 800;
        background: linear-gradient(135deg, #2D3436 0%, #000000 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
      }

      .subtitle {
        font-size: 1.1rem;
        color: #64748B;
        margin-top: 0.5rem;
      }
    }

    .stats-container {
      min-width: 200px;

      .stat-item {
        position: relative;
        text-align: center;
        padding: 2rem;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(8px);
        border-radius: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.8);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

        .stat-value {
          font-size: 2rem;
          font-weight: 700;
          color: #1E293B;
          margin-bottom: 0.5rem;
          position: relative;
          z-index: 1;
        }

        .stat-label {
          font-size: 1rem;
          color: #64748B;
          font-weight: 500;
          position: relative;
          z-index: 1;
        }

        .progress-ring {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-90deg);
          width: 140px;
          height: 140px;
          z-index: 0;

          svg {
            width: 100%;
            height: 100%;

            path {
              fill: none;
              stroke-width: 2;
              &:first-child {
                stroke: #E2E8F0;
              }
              &:last-child {
                stroke: #0EA5E9;
                transition: stroke-dasharray 0.5s ease;
              }
            }
          }
        }
      }
    }
  }

  .connection-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5rem;
    margin-bottom: 3rem;

    .connection-card {
      height: 100%;
      position: relative;
      cursor: pointer;
      transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);

      &:hover {
        transform: translateY(-4px);

        .card-glass {
          backdrop-filter: blur(12px);
          background: rgba(255, 255, 255, 0.95);
        }

        .hover-effect {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }

      .card-glass {
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(8px);
        border-radius: 1rem;
        padding: 2rem;
        border: 1px solid rgba(255, 255, 255, 0.8);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
      }

      .network-status {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 2;

        .status-indicator {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #E2E8F0;
          position: relative;

          &.active {
            background: #10B981;

            .pulse {
              animation: pulse 2s infinite;
            }
          }
        }
      }

      .card-content {
        height: 100%;
        display: flex;
        align-items: flex-start;
        gap: 1.5rem;
        position: relative;
        z-index: 1;

        .icon-wrapper {
          position: relative;
          width: 3.5rem;
          height: 3.5rem;
          flex-shrink: 0;

          .c-icon {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
          }

          .hover-effect {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: currentColor;
            opacity: 0.1;
            transition: all 0.3s ease;
          }
        }

        .network-details {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 3.5rem;

          h2 {
            font-size: 1.25rem;
            font-weight: 600;
            margin: 0 0 0.25rem 0;
            color: #1E293B;
          }

          .status-message {
            font-size: 0.875rem;
            color: #64748B;
            margin: 0;

            strong {
              color: #0EA5E9;
            }
          }
        }

        .action-state {
          width: 2.5rem;
          height: 2.5rem;
          flex-shrink: 0;

          .processing {
            .loading-pulse {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background: #E2E8F0;
              animation: pulse 1.5s infinite;
            }
          }

          .connection-action {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #F1F5F9;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25rem;
            color: #64748B;
            transition: all 0.3s ease;

            &:hover {
              background: #E2E8F0;
              transform: scale(1.1);
            }
          }
        }
      }

      // Network specific styles
      &.facebook {
        .icon-wrapper {
          color: #4267B2;
        }
      }

      &.instagram {
        .icon-wrapper {
          color: #E1306C;
        }
      }

      &.linkedin {
        .icon-wrapper {
          color: #0077B5;
        }
      }

      &.is-connected {
        .card-glass {
          border-color: #10B981;
          box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.1);
        }
      }
    }
  }

  .connection-timeline {
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

    .timeline-header {
      margin-bottom: 1.5rem;

      h3 {
        font-size: 1.25rem;
        font-weight: 600;
        color: #1E293B;
        margin: 0;
      }
    }

    .timeline-events {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .timeline-event {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background: #F8FAFC;
        transition: all 0.3s ease;

        &.connected {
          background: #F0FDF4;
        }

        .event-icon {
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          border-radius: 50%;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          flex-shrink: 0;

          .c-icon {
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        .event-details {
          flex: 1;

          h4 {
            font-size: 1rem;
            font-weight: 500;
            color: #1E293B;
            margin: 0;
          }

          p {
            font-size: 0.875rem;
            color: #64748B;
            margin: 0.25rem 0 0 0;
          }
        }
      }
    }
  }

  .quick-actions {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    gap: 1rem;
    z-index: 100;

    .action-button {
      background: white;
      border: none;
      border-radius: 2rem;
      padding: 0.75rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      cursor: pointer;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 12px -1px rgba(0, 0, 0, 0.1);
      }

      .button-icon {
        width: 1.5rem;
        height: 1.5rem;
        background: #F1F5F9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: #64748B;
      }

      .button-text {
        font-size: 0.875rem;
        font-weight: 500;
        color: #1E293B;
      }
    }
  }
}

// Animations
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(16, 185, 129, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0);
  }
}

// Responsive Adjustments
@media (max-width: 768px) {
  .social-hub {
    padding: 1rem;

    .hub-header {
      flex-direction: column;
      text-align: center;
      gap: 2rem;

      .stats-container {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;

        .stat-item {
          padding: 1.5rem;

          .progress-ring {
            width: 120px;
            height: 120px;
          }
        }
      }
    }

    .connection-grid {
      grid-template-columns: 1fr;
    }

    .quick-actions {
      bottom: 1rem;
      right: 1rem;
      left: 1rem;

      .action-button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
</style>
