<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Newsletter bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <NewsletterArticleForm
              v-if="ready"
              @submit="editArticle"
              @sendTestEmail="sendTestEmail"
              :enable-delete="true"
              :campaigns="campaigns"
              :passedData="article"
              :processing="processing"
              btn-text="Änderungen speichern"
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Platzhalter für E-Mails</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <PlaceholderManager />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import NewsletterArticleForm from "@/views/base/newsletter/NewsletterArticleForm";
import PlaceholderManager from "@/components/newsletter/PlaceholderManager";

export default {
  name: "EditArticle",
  components: {
    NewsletterArticleForm,
    PlaceholderManager
  },
  data() {
    return {
      campaigns: [],
      ready: false,
      article: null,
      processing: false,
    };
  },
  created() {
    this.loadCampaigns();
    axios
        .get("/newsletter/article/" + this.$route.params.id)
        .then((response) => {
          let data = response.data;
          data.message = JSON.parse(data.message);

          // Kampagnen-ID initialisieren, falls vorhanden
          data.campaignId = data.campaign?.id || null;

          this.article = data;
          this.ready = true;
        })
        .catch((err) => console.error(err));
  },
  beforeRouteLeave(to, from, next) {
    if (to.params.saved) {
      next();
      return;
    }
    this.$modal.showModal(
      "default",
      "Editor wirklich schließen?",
      [
        `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`,
      ],
      () => {
        next();
      },
      () => {
        next(false);
      },
      {
        btnCancel: "abbrechen",
        btnConfirm: "Trotzdem schließen",
        btnConfirmColor: "danger",
      },
    );
  },
  methods: {
    async loadCampaigns() {
      try {
        const response = await axios.get("/campaigns");
        this.campaigns = response.data.map((campaign) => ({
          value: campaign.id,
          text: campaign.name,
        }));
      } catch (error) {
        console.error("Fehler beim Laden der Kampagnen:", error);
      }
    },
    editArticle(data) {
      this.processing = true;
      axios
        .put("/newsletter/article/" + this.$route.params.id, data)
        .then(() => {
          this.$router.push({
            name: "NewsletterArticles",
            params: { saved: "1" },
          });
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Änderungen wurden gespeichert</b>",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Fehler!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    sendTestEmail(data) {
      axios
        .put(
          "/newsletter/article/" + this.$route.params.id + "/testEmail",
          data,
        )
        .then(() => {
          this.$toaster.makeToast(
            "Success!",
            "<b>Test E-Mail</b> wurde erfolgreich gesendet!",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Fehler!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
