<template>
  <div>
    <LgcModule
      class="lgc-module-input"
      @dblclick="modalVisible = true"
      @delete="$emit('delete', $event)"
      @add-below="$emit('add-below', $event)"
    >
      <label
        contenteditable="true"
        @focusout="
          dataInputText = $event.target.innerText;
          emitInput();
        "
        v-text="dataInputText"
      ></label>
      <input type="text" name="some-input" :placeholder="computedPlaceholder" />
    </LgcModule>
    <CModal
      title="Input bearbeiten"
      size=""
      :show.sync="modalVisible"
      :closeOnBackdrop="true"
    >
      <div class="lgc-module-input--edit lgc-modal-edit">
        <CRow>
          <CCol>
            <CInput label="Placeholder" v-model="dataInputPlaceholder" />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <BooleanCheckbox
              style="text-align: left"
              label=""
              name="is-input-required"
              text="Eingabe erforderlich?"
              v-model="dataInputRequired"
            />
          </CCol>
        </CRow>
      </div>
      <div slot="footer">
        <CButton
          class="mr-3"
          color="success"
          variant="outline"
          @click="modalVisible = false"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
import ColorPicker from "@/components/ColorPicker";
import BooleanCheckbox from "@/components/BooleanCheckbox";
import _ from "lodash";

export default {
  name: "LgcModuleInput",
  components: {
    ColorPicker,
    LgcModule,
    BooleanCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modalVisible: false,
      dataInputText: this.value.inputText,
      dataInputPlaceholder: this.value.inputPlaceholder,
      dataInputRequired: this.value.inputRequired || true,
    };
  },
  computed: {
    computedPlaceholder() {
      return this.dataInputPlaceholder + (this.dataInputRequired ? "*" : "");
    },
  },
  methods: {
    buttonInsertSpace() {
      document.execCommand("insertText", false, " ");
    },
    buttonInsertEnter() {
      document.execCommand("insertText", false, "\n");
    },
    emitInput() {
      this.$emit("input", {
        value: {
          inputText: _.trim(this.dataInputText),
          inputPlaceholder: _.trim(this.dataInputPlaceholder),
          inputRequired: Boolean(this.dataInputRequired),
        },
      });
    },
  },
  watch: {
    modalVisible() {
      this.emitInput();
    },
  },
};
</script>

<style lang="scss" scoped>
.lgc-module-input {
  margin: 0;
  padding: 10px 20px;

  input {
    max-width: 100%;
    width: 100%;
    padding: 12px 14px;
    margin: -1px;
    border: 1px solid #bfbfbf;
    outline: 0;
    font-size: 16px;
    line-height: normal;
    border-radius: 4px;
  }
}
</style>
