# Subscription.vue
<template>
  <div class="subscription-container p-3">
    <!-- Loading & Error States -->
    <div v-if="loading" class="text-center py-5">
      <CSpinner color="primary" size="lg" />
      <p class="mt-3 text-muted">Laden der Abonnementdaten...</p>
    </div>

    <CAlert v-if="error" color="danger" class="mb-4">
      <div class="d-flex align-items-center">
        <CIcon name="cil-warning" class="mr-3" />
        <div>
          <h4 class="alert-heading mb-1">Ein Fehler ist aufgetreten</h4>
          <p class="mb-0">{{ error }}</p>
        </div>
      </div>
    </CAlert>

    <template v-if="!loading && !error">
      <!-- Header Card -->
      <CCard class="mb-4 border-0 shadow-sm">
        <CCardBody class="d-flex justify-content-between align-items-center p-4">
          <div>
            <h1 class="h3 mb-1">{{ subscription.plan }}</h1>
            <p class="text-muted mb-0">
              {{ formatCurrency(subscription.amountDue, true) }} pro {{ translateInterval(subscription.interval) }}
            </p>
          </div>
          <CButton
            @click="openSelfServicePortal"
            color="primary"
            class="px-4"
          >
            <CIcon name="cil-settings" class="mr-2" />
            Abo verwalten
          </CButton>
        </CCardBody>
      </CCard>

      <!-- Subscription Details -->
      <div class="row">
        <div class="col-md-8">
          <CCard class="h-100 border-0 shadow-sm">
            <CCardHeader class="bg-transparent border-bottom-0">
              <h2 class="h5 mb-0">Details zum Abonnement</h2>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-sm-6 mb-4">
                  <div class="subscription-detail-item">
                    <div class="text-muted mb-1">Status</div>
                    <CBadge :color="subscription.status === 'active' ? 'success' : 'warning'">
                      {{ subscription.status === 'active' ? 'Aktiv' : 'Inaktiv' }}
                    </CBadge>
                  </div>
                </div>
                <div class="col-sm-6 mb-4">
                  <div class="subscription-detail-item">
                    <div class="text-muted mb-1">Zahlungsintervall</div>
                    <div>{{ translateInterval(subscription.interval) }}</div>
                  </div>
                </div>
                <div class="col-sm-6 mb-4">
                  <div class="subscription-detail-item">
                    <div class="text-muted mb-1">Nächste Abrechnung</div>
                    <div>{{ formatDate(subscription.currentPeriodEnd) }}</div>
                  </div>
                </div>
                <div class="col-sm-6 mb-4">
                  <div class="subscription-detail-item">
                    <div class="text-muted mb-1">Vertragsbeginn</div>
                    <div>{{ formatDate(subscription.currentPeriodStart) }}</div>
                  </div>
                </div>
              </div>

              <CCollapse :show="showDetails">
                <div class="border-top pt-4 mt-2">
                  <div class="row">
                    <div class="col-sm-6 mb-4">
                      <div class="subscription-detail-item">
                        <div class="text-muted mb-1">Zahlungsmethode</div>
                        <div>
                          {{ formatPaymentMethod(
                          subscription.paymentMethodType,
                          subscription.cardLast4,
                          subscription.cardExpMonth,
                          subscription.cardExpYear
                        ) }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 mb-4">
                      <div class="subscription-detail-item">
                        <div class="text-muted mb-1">Rechnungsadresse</div>
                        <div>{{ organization.contactAddress || "Keine Angabe" }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </CCollapse>

              <CButton
                color="link"
                class="px-0"
                @click="showDetails = !showDetails"
              >
                {{ showDetails ? 'Weniger anzeigen' : 'Mehr Details anzeigen' }}
              </CButton>
            </CCardBody>
          </CCard>
        </div>

        <div class="col-md-4">
          <CCard class="border-0 shadow-sm">
            <CCardHeader class="bg-transparent border-bottom-0">
              <h2 class="h5 mb-0">Zahlungsübersicht</h2>
            </CCardHeader>
            <CCardBody>
              <div class="d-flex justify-content-between mb-3">
                <div class="text-muted">Aktueller Tarif</div>
                <div class="font-weight-bold">{{ subscription.plan }}</div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div class="text-muted">Kosten pro {{ translateInterval(subscription.interval) }}</div>
                <div class="font-weight-bold">{{ formatCurrency(subscription.amountDue, true) }}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="text-muted">Nächste Zahlung</div>
                <div class="font-weight-bold">{{ formatDate(subscription.currentPeriodEnd) }}</div>
              </div>
            </CCardBody>
          </CCard>
        </div>
      </div>

      <!-- Invoices -->
      <CCard class="mt-4 border-0 shadow-sm">
        <CCardHeader class="bg-transparent border-bottom-0">
          <h2 class="h5 mb-0">Rechnungen</h2>
        </CCardHeader>
        <CCardBody>
          <table class="table">
            <thead>
            <tr>
              <th>Datum</th>
              <th>Betrag</th>
              <th>Status</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in invoices.slice(0, showAllInvoices ? undefined : 5)" :key="item.stripeId">
              <td>{{ formatDate(item.created) }}</td>
              <td>{{ formatCurrency(item.amountDue / 100) }}</td>
              <td>
                <CBadge :color="getStatusColor(item.status)">
                  {{ translateStatus(item.status) }}
                </CBadge>
              </td>
              <td class="text-right">
                <CButton
                  @click="downloadInvoice(item.stripeId)"
                  color="primary"
                  variant="ghost"
                  size="sm"
                >
                  <CIcon name="cil-cloud-download" class="mr-1" />
                  PDF
                </CButton>
              </td>
            </tr>
            </tbody>
          </table
          >
          <template #created="{ item }">
            <div>{{ formatDate(item.created) }}</div>
          </template>

          <template #amountDue="{ item }">
            <div>{{ formatCurrency(item.amountDue) }}</div>
          </template>

          <template #status="{ item }">
            <CBadge :color="getStatusColor(item.status)">
              {{ translateStatus(item.status) }}
            </CBadge>
          </template>

          <template #actions="{ item }">
            <CButton
              @click="downloadInvoice(item.stripeId)"
              color="primary"
              variant="ghost"
              size="sm"
            >
              <CIcon name="cil-cloud-download" class="mr-1" />
              PDF
            </CButton>
          </template>
          </CDataTable>

          <div v-if="invoices.length > 5" class="text-center mt-4">
            <CButton
              v-if="!showAllInvoices"
              @click="showAllInvoices = true"
              color="link"
            >
              Alle Rechnungen anzeigen
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SubscriptionDashboard',

  data() {
    return {
      loading: true,
      error: null,
      subscription: null,
      invoices: [],
      customerId: null,
      organization: null,
      showDetails: false,
      showAllInvoices: false
    }
  },

  mounted() {
    this.fetchRealmData()
  },

  methods: {
    async openSelfServicePortal() {
      try {
        const response = await axios.get('/stripe/self-service', {
          params: {
            customerId: this.subscription.organization.stripeCustomerId
          }
        })
        const selfServiceUrl = response.data.tokenId
        if (selfServiceUrl) {
          window.location.href = selfServiceUrl
        } else {
          throw new Error('Ungültige URL erhalten')
        }
      } catch (err) {
        console.error('Fehler beim Öffnen des Self-Service-Portals:', err)
        this.error = 'Das Self-Service-Portal konnte nicht geöffnet werden'
      }
    },

    async fetchRealmData() {
      try {
        const realmResponse = await axios.get('/realm/current')
        this.subscription = realmResponse.data
        this.customerId = realmResponse.data.organization?.stripeCustomerId
        this.organization = realmResponse.data.organization

        if (!this.customerId) {
          throw new Error('Keine Kunden-ID gefunden')
        }

        await this.fetchSubscriptionData(this.subscription.stripeSubscriptionId)
        await this.fetchInvoices(this.subscription.stripeSubscriptionId)
      } catch (err) {
        console.error('Fehler beim Laden der Realm-Daten:', err)
        this.error = 'Die Abonnementdaten konnten nicht geladen werden'
      } finally {
        this.loading = false
      }
    },

    async fetchSubscriptionData(id) {
      try {
        const response = await axios.get(`/subscription/${id}`)
        this.subscription = { ...this.subscription, ...response.data }
      } catch (err) {
        console.error('Fehler beim Laden der Abonnement-Daten:', err)
        this.error = 'Die Abonnementdetails konnten nicht geladen werden'
      }
    },

    async fetchInvoices(id) {
      try {
        const response = await axios.get(`/invoices/${id}`)
        this.invoices = response.data
      } catch (err) {
        console.error('Fehler beim Laden der Rechnungen:', err)
        this.error = 'Die Rechnungsübersicht konnte nicht geladen werden'
      }
    },

    translateInterval(interval) {
      const intervals = {
        month: 'Monat',
        year: 'Jahr'
      }
      return intervals[interval?.toLowerCase()] || 'Unbekannt'
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },

    formatCurrency(amount) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(Number(amount))
    },

    formatPaymentMethod(type, last4, expMonth, expYear) {
      if (type === 'card') {
        return `Kreditkarte •••• ${last4} (gültig bis ${expMonth}/${expYear})`
      } else if (type === 'sepa_debit') {
        return `SEPA Lastschrift •••• ${last4}`
      }
      return 'Keine Zahlungsmethode hinterlegt'
    },

    getStatusColor(status) {
      const colors = {
        open: 'warning',
        paid: 'success',
        failed: 'danger',
        pending: 'info'
      }
      return colors[status.toLowerCase()] || 'secondary'
    },

    translateStatus(status) {
      const translations = {
        open: 'Offen',
        paid: 'Bezahlt',
        failed: 'Fehlgeschlagen',
        pending: 'In Bearbeitung'
      }
      return translations[status.toLowerCase()] || 'Unbekannt'
    },

    async downloadInvoice(invoiceId) {
      try {
        const response = await axios.get(`/invoices/${invoiceId}/download`, {
          responseType: 'blob'
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Rechnung_${invoiceId}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (err) {
        console.error('Fehler beim Herunterladen der Rechnung:', err)
        this.error = 'Die Rechnung konnte nicht heruntergeladen werden'
      }
    }
  }
}
</script>

<style scoped>
.subscription-container {
  max-width: 1200px;
  margin: 0 auto;
}

.subscription-detail-item {
  height: 100%;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
}

.table th {
  border-top: none;
  font-weight: 500;
  color: #6c757d;
  padding: 1rem;
  text-align: left;
}

.table td {
  padding: 1rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.card {
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.btn-ghost-primary {
  color: #321fdb;
  background-color: transparent;
}

.btn-ghost-primary:hover {
  color: #fff;
  background-color: #321fdb;
}
</style>
