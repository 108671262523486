<template>
  <span class="trend-indicator" :class="trendClass">
    <CIcon :name="trendIcon" class="trend-icon" />
    <span v-if="showValue">{{ formattedValue }}</span>
  </span>
</template>

<script>
import { CIcon } from '@coreui/vue'

export default {
  name: 'TrendIndicator',

  components: {
    CIcon,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    trendClass() {
      return {
        'trend-up': this.value > 0,
        'trend-down': this.value < 0,
        'trend-neutral': this.value === 0,
      }
    },

    trendIcon() {
      if (this.value > 0) return 'cil-arrow-top'
      if (this.value < 0) return 'cil-arrow-bottom'
      return 'cil-minus'
    },

    formattedValue() {
      const absValue = Math.abs(this.value)
      return `${absValue}%`
    },
  },
}
</script>

<style scoped>
.trend-indicator {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.trend-icon {
  width: 16px;
  height: 16px;
}

.trend-up {
  background-color: rgba(56, 161, 105, 0.1);
  color: #38a169;
}

.trend-down {
  background-color: rgba(229, 62, 62, 0.1);
  color: #e53e3e;
}

.trend-neutral {
  background-color: rgba(160, 174, 192, 0.1);
  color: #718096;
}
</style>
