<template>
  <div class="device-chart">
    <div v-if="loading" class="chart-loader">
      <CSpinner />
    </div>
    <canvas ref="deviceChartCanvas"></canvas>
  </div>
</template>

<script>
import Chart from '@/plugins/charts'

export default {
  name: 'DeviceChart',

  props: {
    chartData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      chart: null
    }
  },

  watch: {
    chartData: {
      handler: 'updateChart',
      deep: true
    }
  },

  mounted() {
    this.createChart()
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  },

  methods: {
    createChart() {
      if (this.$refs.deviceChartCanvas) {
        const ctx = this.$refs.deviceChartCanvas.getContext('2d')
        this.chart = new Chart(ctx, {
          type: 'pie',
          data: this.getChartData(),
          options: this.getChartOptions()
        })
      }
    },

    updateChart() {
      if (this.chart) {
        this.chart.data = this.getChartData()
        this.chart.update()
      }
    },

    getChartData() {
      const colors = {
        iPhone: '#FF6384',
        Samsung: '#36A2EB',
        Nokia: '#FFCE56',
        Unknown: '#4BC0C0',
        Desktop: '#9966FF',
        Tablet: '#FF9F40',
        Android: '#33CC33'
      }

      const defaultColors = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
        '#9966FF', '#FF9F40', '#33CC33', '#EA526F'
      ]

      const mappedData = this.chartData.map((item) => {
        const color = colors[item.label] || defaultColors[Math.floor(Math.random() * defaultColors.length)]
        return {
          label: item.label,
          value: item.value,
          color
        }
      })

      return {
        labels: mappedData.map(item => item.label),
        datasets: [{
          data: mappedData.map(item => item.value),
          backgroundColor: mappedData.map(item => item.color),
          hoverBackgroundColor: mappedData.map(item => item.color)
        }]
      }
    },

    getChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            padding: 20,
            usePointStyle: true
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const total = dataset.data.reduce((acc, curr) => acc + curr, 0)
              const value = dataset.data[tooltipItem.index]
              const percentage = ((value * 100) / total).toFixed(1)
              return `${data.labels[tooltipItem.index]}: ${value} (${percentage}%)`
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.device-chart {
  position: relative;
  height: 300px;
}
.chart-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
