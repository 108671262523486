import axios from 'axios'

export const dealService = {
  async loadDeal(dealId) {
    try {
      const response = await axios.get(`/deal/${dealId}`)
      return response.data
    } catch (err) {
      console.error('Fehler beim Laden des Deals:', err)
      throw err
    }
  },

  async createDeal(dealData) {
    try {
      const response = await axios.post('/deal', dealData)
      return response.data
    } catch (err) {
      console.error('Fehler beim Erstellen des Deals:', err)
      throw err
    }
  },

  async updateDeal(dealId, dealData) {
    try {
      const response = await axios.put(`/deal/${dealId}`, dealData)
      return response.data
    } catch (err) {
      console.error('Fehler beim Aktualisieren des Deals:', err)
      throw err
    }
  },

  async deleteDeal(dealId) {
    try {
      await axios.delete(`/deal/${dealId}`)
    } catch (err) {
      console.error('Fehler beim Löschen des Deals:', err)
      throw err
    }
  },

  async changeDealStep(dealId, newStepId) {
    try {
      await axios.post(`/deal/change-step/${dealId}/${newStepId}`)
    } catch (err) {
      console.error('Fehler beim Ändern der Deal-Phase:', err)
      throw err
    }
  }
}

export default dealService
