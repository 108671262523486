<template>
  <div class="loading-overlay">
    <div class="loading-content">
      <ProgressRing
        :progress="progress"
        :size="64"
        :stroke-width="6"
      />
      <div class="loading-text">
        <h3>Daten werden geladen</h3>
        <p>Bitte haben Sie einen Moment Geduld...</p>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressRing from './ProgressRing.vue'

export default {
  name: 'LoadingOverlay',

  components: {
    ProgressRing,
  },

  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--card-background-rgb), 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  border-radius: 1rem;
  background: var(--card-background);
  box-shadow: var(--shadow-lg);
}

.loading-text {
  text-align: center;
}

.loading-text h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 0.5rem 0;
}

.loading-text p {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0;
}

@media (max-width: 640px) {
  .loading-content {
    padding: 1.5rem;
  }
}
</style>
