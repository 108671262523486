<template>
  <div>
    <CRow>
      <CCol size="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-task" />
                  Alle Aktivitäten
                </div>
              </div>
            </slot>
          </CCardHeader>

          <CCardBody style="position: relative">
            <TaskWidget />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import TaskWidget from "./TaskWidget";

export default {
  components: { TaskWidget },
};
</script>
