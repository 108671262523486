<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Neuen E-Mail-Newsletter erstellen</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <NewsletterArticleForm
                @submit="createNewsletterArticle"
                :processing="processing"
                :enable-delete="false"
                :campaigns="campaigns"
                btn-text="Speichern"
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Platzhalter für E-Mails</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <PlaceholderManager />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import NewsletterArticleForm from "@/views/base/newsletter/NewsletterArticleForm";
import PlaceholderManager from "@/components/newsletter/PlaceholderManager";
export default {
  name: "NewsletterArticleNew",
  components: {
    NewsletterArticleForm,
    PlaceholderManager
  },
  data() {
    return {
      campaigns: [],
      article: null,
      processing: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.params.saved) {
      next();
      return;
    }
    this.$modal.showModal(
      "default",
      "Editor wirklich schließen?",
      [
        `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`,
      ],
      () => {
        next();
      },
      () => {
        next(false);
      },
      {
        btnCancel: "abbrechen",
        btnConfirm: "Trotzdem schließen",
        btnConfirmColor: "danger",
      },
    );
  },
  created() {
    this.loadCampaigns();
  },
  methods: {
    async loadCampaigns() {
      try {
        const response = await axios.get("/campaigns");
        this.campaigns = response.data.map((campaign) => ({
          value: campaign.id,
          text: campaign.name,
        }));
      } catch (error) {
        console.error("Fehler beim Laden der Kampagnen:", error);
      }
    },
    createNewsletterArticle(data) {
      this.processing = true;
      axios
        .post("/newsletter/article", data)
        .then(() => {
          this.$router.push({
            name: "NewsletterArticles",
            params: { saved: "1" },
          });
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Artikel wurde erfolgreich angelegt!</b>",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Error!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
