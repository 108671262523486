<template>
  <div class="placeholder-manager">
    <!-- Search Section -->
    <div class="search-container">
      <CInput
          v-model="searchQuery"
          placeholder="Platzhalter suchen..."
          class="search-input"
      >
        <template #prepend-content>
          <CIcon name="cil-search" />
        </template>
      </CInput>
    </div>

    <!-- Category Filters -->
    <div class="category-filters">
      <CButton
          v-for="category in categories"
          :key="category.id"
          :color="selectedCategory === category.id ? 'primary' : 'light'"
          @click="selectCategory(category.id)"
          class="category-button"
      >
        {{ category.name }}
      </CButton>
    </div>

    <!-- Placeholders Grid -->
    <div class="placeholders-grid">
      <CCard
          v-for="placeholder in filteredPlaceholders"
          :key="placeholder.variable"
          class="placeholder-card"
      >
        <CCardBody>
          <div class="card-category-indicator" :class="placeholder.category"></div>
          <div class="placeholder-content">
            <div class="placeholder-syntax">
              <code>{{ placeholder.variable }}</code>
              <CButton
                  color="light"
                  size="sm"
                  class="copy-button"
                  @click="copyToClipboard(placeholder.variable)"
                  :title="copiedStates[placeholder.variable] ? 'Kopiert!' : 'Kopieren'"
              >
                <CIcon :name="copiedStates[placeholder.variable] ? 'cil-check' : 'cil-copy'" />
              </CButton>
            </div>
            <div class="placeholder-description">
              {{ placeholder.description }}
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderManager',
  data() {
    return {
      searchQuery: '',
      selectedCategory: 'all',
      copiedStates: {},
      copySuccess: false,
      categories: [
        { id: 'all', name: 'Alle' },
        { id: 'personal', name: 'Persönliche Daten' },
        { id: 'property', name: 'Immobilien Daten' },
        { id: 'results', name: 'Ergebnisse' }
      ],
      placeholders: [
        {
          variable: '{{ MAKLER }}',
          description: 'Ihr Maklername',
          category: 'personal'
        },
        {
          variable: '{{ FIRMA }}',
          description: 'Firma',
          category: 'personal'
        },
        {
          variable: '{{ ANREDE }}',
          description: 'Anrede',
          category: 'personal'
        },
        {
          variable: '{{ NACHNAME }}',
          description: 'Nachname des Eigentümers',
          category: 'personal'
        },
        {
          variable: '{{ VORNAME }}',
          description: 'Vorname des Eigentümers',
          category: 'personal'
        },
        {
          variable: '{{ TELEFON }}',
          description: 'Telefonnummer des Eigentümers',
          category: 'personal'
        },
        {
          variable: '{{ EMAIL }}',
          description: 'E-Mail Adresse des Eigentümers',
          category: 'personal'
        },
        {
          variable: '{{ IMMOBILIEN_TYP }}',
          description: 'Z.b. Haus, Eigentumswohnung etc.',
          category: 'property'
        },
        {
          variable: '{{ ADRESSE }}',
          description: 'Vollständige Anschrift der Immobilie',
          category: 'property'
        },
        {
          variable: '{{ WOHNFLACHE }}',
          description: 'Wohnfläche der Immobilie',
          category: 'property'
        },
        {
          variable: '{{ ZIMMER }}',
          description: 'Anzahl der Zimmer der Immobilie',
          category: 'property'
        },
        {
          variable: '{{ BAUJAHR }}',
          description: 'Baujahr der Immobilie',
          category: 'property'
        },
        {
          variable: '{{ GRUNDFLACHE }}',
          description: 'Grundstücksfläche der Immobilie',
          category: 'property'
        },
        {
          variable: '{{ ETAGE }}',
          description: 'Anzahl der Etagen der Immobilie',
          category: 'property'
        },
        {
          variable: '{{ ERSCHLOSSEN }}',
          description: 'Bei Grundstücken, angabe ob Erschlossen, Teilerschlossen oder nicht erschlossen',
          category: 'property'
        },
        {
          variable: '{{ BEBAUUNG }}',
          description: 'Bebaungsmöglichkeiten von Grundstücken',
          category: 'property'
        },
        {
          variable: '{{ ZUSCHNITT }}',
          description: 'Angaben zum Grundstückszuschnitt',
          category: 'property'
        },
        {
          variable: '{{ GRUND }}',
          description: 'Grund der Immobilienbewertung Kauf oder Verkauf',
          category: 'property'
        },
        {
          variable: '{{ GRUND_WANN }}',
          description: 'Verkaufszeitwunsch, wenn "Verkauf" als Grund ausgewählt',
          category: 'property'
        },
        {
          variable: '{{ GESAMT_ERGEBNIS }}',
          description: 'Ermittelter durchschnittlicher Marktwert',
          category: 'results'
        },
        {
          variable: '{{ MIN_GESAMT_ERGEBNIS }}',
          description: 'Resultierende Wertspanne minimum',
          category: 'results'
        },
        {
          variable: '{{ MAX_GESAMT_ERGEBNIS }}',
          description: 'Resultierende Wertspanne maximum',
          category: 'results'
        },
        {
          variable: '{{ ERGEBNIS_PRO_QM }}',
          description: 'Durchschnittlicher Wert pro m² Wohnfläche',
          category: 'results'
        },
        {
          variable: '{{ MIN_ERGEBNIS_PRO_QM }}',
          description: 'Ermittelte Wertspanne pro m² minimum',
          category: 'results'
        },
        {
          variable: '{{ MAX_ERGEBNIS_PRO_QM }}',
          description: 'Ermittelte Wertspanne pro m² maximum',
          category: 'results'
        },
        {
          variable: '{{ PDF_URL }}',
          description: 'Link zur ursprünglichen Wohnmarktanalyse, falls vorhanden.',
          category: 'results'
        }
      ]
    }
  },
  computed: {
    filteredPlaceholders() {
      return this.placeholders.filter(placeholder => {
        const matchesSearch = this.searchQuery === '' ||
            placeholder.variable.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            placeholder.description.toLowerCase().includes(this.searchQuery.toLowerCase())

        const matchesCategory = this.selectedCategory === 'all' ||
            placeholder.category === this.selectedCategory

        return matchesSearch && matchesCategory
      })
    }
  },
  methods: {
    selectCategory(categoryId) {
      this.selectedCategory = categoryId
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text)
        // Setze den Status nur für diesen spezifischen Platzhalter
        this.$set(this.copiedStates, text, true)
        setTimeout(() => {
          this.$set(this.copiedStates, text, false)
        }, 2000)
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
    }
  }
}
</script>

<style scoped>
.placeholder-manager {
  padding: 1rem;
}

.search-container {
  margin-bottom: 1.5rem;
}

.search-input {
  max-width: 400px;
}

.category-filters {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.category-button {
  margin-bottom: 0.5rem;
}

.placeholders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.placeholder-card {
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
}

.placeholder-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-category-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: 4px 4px 0 0;
}

.card-category-indicator.personal {
  background-color: #20a8d8;
}

.card-category-indicator.property {
  background-color: #4dbd74;
}

.card-category-indicator.results {
  background-color: #f86c6b;
}

.placeholder-content {
  padding-top: 0.5rem;
}

.placeholder-syntax {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  background: #f8f9fa;
  padding: 0.5rem;
  border-radius: 4px;
}

.placeholder-syntax code {
  font-family: monospace;
  color: #2c3e50;
}

.copy-button {
  padding: 0.25rem 0.5rem;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.copy-button:hover {
  opacity: 1;
}

.placeholder-description {
  color: #6c757d;
  font-size: 0.9rem;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .placeholders-grid {
    grid-template-columns: 1fr;
  }
}
</style>
