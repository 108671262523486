var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('div',{staticClass:"prt-card-header"},[_c('div',{staticClass:"header-naming"},[_c('CIcon',{attrs:{"name":"cil-sitemap"}}),_vm._v(" Landingpages ")],1),_c('div',{staticClass:"header-button"},[_c('CButton',{key:"key",attrs:{"to":"landingpage/new","name":"backagain","size":"sm","color":"dark"}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-plus"}}),_c('span',[_vm._v(" Neue Landingpage erstellen")])],1)],1)])]})],2),(!_vm.loading)?_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.tableData,"itemsPerPageSelect":{ label: 'Landingpages pro Seite' },"fields":_vm.getTableFields(),"noItemsView":{
              noResults: 'Keine Suchergebnisse verfügbar',
              noItems: 'Noch keine Landingpages erstellt',
            },"hover":"","striped":"","bordered":"","fixed":"","column-filter":"","table-filter":"","tableFilter":{
              label: 'Landingpage suchen',
              placeholder: 'Suchbegriff eingeben',
            },"items-per-page-select":"","sorter":"","sorterValue":{ column: 'sendAt', asc: true },"pagination":"","loading":_vm.tableShowLoading},scopedSlots:_vm._u([{key:"active",fn:function({ item }){return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getTableBadge(item.active)}},[_vm._v(" "+_vm._s(item.active ? "Aktiv" : "Deaktiv")+" ")])],1)]}},{key:"show_details",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"to":'landingpage/edit/' + item.id,"color":"primary","variant":"outline","square":"","size":"sm"}},[_vm._v(" Bearbeiten ")])],1)]}},{key:"sendAt",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.sendAt).format(_vm.$commons.DATE_TIME_FORMAT))+" ")])]}}],null,false,3258934064)})],1):_vm._e()],1),_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('strong',[_vm._v("Hilfe")])]})],2),_c('CCardBody',[_c('p',[_vm._v(" Dank der Marketing-Cloud benötigen Sie wenige Minuten, um ansprechende Landingpages zu entwerfen, mit denen Sie Ihre neue Leads gewinnen werden. "),_c('br'),_vm._v(" Mit unserem Baukasten können Sie Landingpages genau so gestalten, wie Sie es möchten. Sie können bei Bedarf aus über 150 Domains wählen und Ihre persönliche Subdomain erstellen. "),_c('br'),_vm._v(" Wenn Sie Interesse an der exklusiven Nutzung einer Domain haben, schreiben Sie uns gerne. ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }