<template>
  <div>
    <CModal title="" :show.sync="modal" style="text-align: center">
      <h2>Makler wird gewechselt...</h2>
      <h5 v-if="realmName">{{ realmName }}</h5>
      <br />
      <CSpinner color="primary" style="width: 4rem; height: 4rem" />
      <template v-slot:footer><br /></template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "TheRealmSwitcherModal",
  data() {
    return {
      modal: false,
      realmName: null,
    };
  },
  methods: {
    showModal(realmName) {
      this.modal = true;
      this.realmName = realmName;
    },
    hideModal() {
      this.modal = false;
      this.realmName = null;
    },
  },
};
</script>