<template>
  <div class="input-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CInputGroup",
};
</script>

<style scoped>
.input-group {
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
</style>