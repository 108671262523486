<template>
  <CFormGroup
    v-bind="{
      append,
      prepend,
      validFeedback,
      invalidFeedback,
      tooltipFeedback,
      description,
      wrapperClasses,
      class: computedClasses,
    }"
  >
    <template #label>
      <slot name="label">
        <label v-if="label" :for="safeId" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
    </template>
    <template #input>
      <PrismEditor
        v-if="showPrismEditor"
        class="prt-code-editor"
        v-model="state"
        :highlight="highlighter"
        line-numbers
      ></PrismEditor>
    </template>

    <template v-for="slot in $options.slots" #[slot]>
      <slot :name="slot" />
    </template>
  </CFormGroup>
</template>

<script>
import CFormGroup from "@coreui/vue/src/components/form/CFormGroup";
import { inputProps as props } from "@coreui/vue/src/components/form/form-props";
import * as allFormMixins from "@coreui/vue/src/components/form/form-mixins";
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-css";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-coy.css"; // import syntax highlighting styles

const mixins = Object.values(allFormMixins);

export default {
  name: "CodeEditor",
  slots: [
    "prepend",
    "prepend-content",
    "append-content",
    "append",
    "label-after-input",
    "valid-feedback",
    "invalid-feedback",
    "description",
  ],
  inheritAttrs: false,
  components: { CFormGroup, PrismEditor },
  mixins,
  props: {
    ...props,
    language: {
      type: String,
      default: "html",
    },
  },
  data() {
    return {
      state: this.value,
      syncTimeout: null,
      showPrismEditor: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showPrismEditor = true;
    }, 20); //fixes problem with line-numbers, so i move this code to the next ticks
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages[this.language], this.language); // languages.<insert language> to return html with markup
    },
  },
  watch: {
    state(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
<style>
.prt-code-editor {
  position: relative;
  background: #1e1e1e; /* Etwas dunklerer Hintergrund */
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #444; /* Rahmen */
  font-family: 'Courier New', Courier, monospace; /* Monospace-Schrift für Code-Stil */
  color: #ffffff; /* Hellere Schriftfarbe für besseren Kontrast */
  font-size: 14px;
  line-height: 1.6; /* Erhöhte Linienhöhe */
  height: 250px; /* Höhe angepasst */
  overflow: auto; /* Scrollen bei Bedarf */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Leichter Schatten */
}

/* Optional: Textschatten für bessere Lesbarkeit */
.prt-code-editor code {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* Scrollbar Styling */
.prt-code-editor::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.prt-code-editor::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.prt-code-editor::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.prt-code-editor::-webkit-scrollbar-track {
  background: #1e1e1e;
}

/* Anpassungen für Zeilennummern */
.prism-editor__line-numbers {
  background: #2a2a2a; /* Etwas dunklerer Hintergrund für Zeilennummern */
  color: #b0b0b0; /* Hellere Schriftfarbe für die Zeilennummern */
  padding-right: 10px;
}

</style>
