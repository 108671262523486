<template>
  <div class="login-container">
    <div class="login-overlay">
      <div class="login-card">
        <div class="logo-container">
          <img src="@/assets/img/brand/logo.svg" alt="Logo" class="logo" />
        </div>

        <form @submit.prevent="login" class="login-form">
          <h1>Willkommen zurück</h1>
          <p>Melden Sie sich an, um fortzufahren</p>

          <div class="form-group">
            <input
              v-model="email"
              type="text"
              placeholder="E-Mail oder Benutzername"
              :class="{ 'input-error': errors.email }"
              autocomplete="email username"
            />
            <span v-if="errors.email" class="error-text">{{
              errors.email
            }}</span>
          </div>

          <div class="form-group">
            <input
              v-model="password"
              type="password"
              placeholder="Passwort"
              :class="{ 'input-error': errors.password }"
              autocomplete="current-password"
            />
            <span v-if="errors.password" class="error-text">{{
              errors.password
            }}</span>
          </div>

          <div class="form-actions">
            <label class="remember-me">
              <input type="checkbox" v-model="rememberMe" />
              <span>Angemeldet bleiben</span>
            </label>
            <router-link to="/pages/forgotPassword" class="forgot-password">
              Passwort vergessen?
            </router-link>
          </div>

          <button type="submit" class="login-button" :disabled="loggingIn">
            <span v-if="!loggingIn">Anmelden</span>
            <span v-else class="loading-spinner"></span>
          </button>
        </form>

        <div class="register-section">
          <p>Noch kein Konto?</p>
          <a
            href="https://buy.stripe.com/fZe3cW6yUfjy2uA005"
            class="register-link"
          >
            Kostenfrei registrieren
          </a>
        </div>
        <div class="footer-links">
          <a
            href="https://www.leadmeup.de/rechtliches/datenschutz"
            target="_blank"
            rel="noopener noreferrer"
            class="privacy-link"
          >
            Datenschutzerklärung
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import commons from "../../commons";

export default {
  name: "Login",
  data() {
    return {
      loggingIn: false,
      email: this.$route.params.email || "",
      password: "",
      rememberMe: false,
      errors: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    validateForm() {
      let isValid = true;
      this.errors = {
        email: "",
        password: "",
      };

      if (!this.email) {
        this.errors.email = "E-Mail ist erforderlich";
        isValid = false;
      }

      if (!this.password) {
        this.errors.password = "Passwort ist erforderlich";
        isValid = false;
      }

      return isValid;
    },
    addToast(header, content) {
      this.$toaster.makeToast(header, content);
    },
    async login() {
      if (!this.validateForm()) return;

      this.loggingIn = true;
      try {
        const response = await axios.post("/user/login", {
          email: this.email,
          password: this.password,
        });

        commons.setToStorage("jwtToken", response.data.token);
        commons.setToStorage("loggedIn", true);
        commons.setToStorage("currentRealm", response.data.realm);
        commons.setToStorage("license", response.data.realm.license);
        commons.setToStorage("currentUser", response.data.user);
        commons.setToStorage(
          "currentOrganization",
          response.data.user.organization,
        );
        commons.setToStorage(
          "unlayerSignatureHmac",
          response.data.unlayerSignatureHmac,
        );

        // Hier WebSocket-Verbindung initialisieren, nachdem der Token gesetzt wurde
        const WebSocketService = (await import("@/services/WebSocketService"))
          .default;
        WebSocketService.connect();

        const currentPlan = commons.getCurrentPlan();
        if (!currentPlan || currentPlan.name === "TEMP_ADMIN_PLAN") {
          this.addToast(
            "Vorübergehender Zugriff",
            "Sie haben vorübergehenden Zugriff, obwohl kein aktiver Tarif vorhanden ist.",
          );
        }

        const redirectAfterLogin = commons.getFromStorage("redirectAfterLogin");
        if (typeof redirectAfterLogin === "string") {
          commons.removeFromStorage("redirectAfterLogin");
          window.location.replace(redirectAfterLogin);
        } else {
          this.$router.push({ name: "Home" });
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              this.addToast("Anmeldefehler", "Benutzer nicht gefunden.");
              break;
            case 406:
              this.addToast(
                "Passwort erforderlich",
                "Bitte setzen Sie Ihr Passwort.",
              );
              break;
            case 422:
              this.addToast(
                "Ungültige Anmeldedaten",
                "Überprüfen Sie Ihre E-Mail-Adresse und Passwort.",
              );
              break;
            case 403:
              this.addToast("Aktiver Tarif erforderlich", err.response.data);
              break;
            default:
              this.addToast(
                "Unbekannter Fehler",
                "Ein unerwarteter Fehler ist aufgetreten.",
              );
          }
        }
        console.error(err);
      } finally {
        this.loggingIn = false;
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
}

.login-overlay {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 480px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.logo-container {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  width: 150px;
  filter: brightness(0) invert(1); /* Macht das Logo weiß */
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-form h1 {
  color: white;
  font-size: 28px;
  margin: 0;
  text-align: center;
}

.login-form p {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin: 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

input {
  width: 100%;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 16px;
  transition: all 0.3s ease;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.15);
}

.input-error {
  border-color: #ff4444;
}

.error-text {
  color: #ff4444;
  font-size: 14px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  cursor: pointer;
}

.remember-me span {
  user-select: none; /* Verhindert unbeabsichtigtes Text-Highlighting */
}

.remember-me input[type="checkbox"] {
  margin: 0; /* Entfernt Standard-Margins der Checkbox */
  width: auto; /* Überschreibt die 100% Breite von input */
  padding: 0; /* Entfernt Standard-Padding */
}

.forgot-password {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.forgot-password:hover {
  color: white;
}

.login-button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background: white;
  color: #2a5298;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(42, 82, 152, 0.3);
  border-radius: 50%;
  border-top-color: #2a5298;
  animation: spin 1s linear infinite;
}

.register-section {
  margin-top: 30px;
  text-align: center;
  color: white;
}

.register-section p {
  margin: 0 0 10px 0;
}

.register-link {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 25px;
  transition: all 0.3s ease;
  display: inline-block;
}

.register-link:hover {
  background: white;
  color: #2a5298;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .login-card {
    padding: 20px;
  }

  .form-actions {
    flex-direction: column;
    gap: 15px;
  }

  .remember-me {
    order: 2;
  }

  .forgot-password {
    order: 1;
  }
}
.footer-links {
  margin-top: 20px;
  text-align: center;
}

.privacy-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.privacy-link:hover {
  color: white;
  text-decoration: underline;
}
</style>
