<template>
  <CSidebar
    class="c-sidebar-dark"
    fixed
    :minimize="minimize"
    :show="show"
  >
    <!-- Sidebar Header -->
    <CSidebarBrand :to="{ name: 'Home' }" class="brand-container">
      <CImg
        class="c-sidebar-brand-full"
        :src="computedLogoUrl"
        :style="{ maxWidth: '150px', height: 'auto' }"
      />
    </CSidebarBrand>

    <!-- Package Info -->
    <div class="current-package">
      <CIcon name="cil-credit-card" />
      <div class="package-details">
        <div>Aktuelles Paket: {{ currentPlan || "Keine Daten" }}</div>
        <div>
          Bis: {{ validUntil ? formatDate(validUntil) : "Keine Daten" }}
        </div>
      </div>
    </div>

    <!-- Navigation ohne PerfectScrollbar -->
    <CSidebarNav>
      <CRenderFunction flat :content-to-render="nav" />
    </CSidebarNav>

    <!-- Realm Switcher -->
    <button
      class="c-sidebar-minimizer"
      type="button"
      @click="toggleRealmSwitcher"
    >
      <span class="prt-realm-switcher">{{ currentRealm.name }}</span>
      <span v-if="otherRealms.length" class="minimizer" />
    </button>

    <div class="otherRealms" :style="calculatedStyles">
      <template v-for="realm in otherRealms">
        <button
          :key="realm.id"
          class="c-sidebar-minimizer"
          type="button"
          @click="realmSwitch(realm)"
        >
          <span class="prt-realm-switcher">{{ realm.name }}</span>
        </button>
      </template>
    </div>
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import navKensington from "./_nav_kensington";
import axios from "axios";
import commons from "@/commons";

export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      show: "responsive",
      nav: commons.hasExactRealmPlan("KENSINGTON") && commons.isOrganizationAdmin()
        ? navKensington
        : nav,
      otherRealms: [],
      showRealmSwitcher: false,
      calculatedStyles: "",
      currentRealm: commons.getFromStorage("currentRealm"),
      currentPlan: null,
      validUntil: null,
    };
  },
  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });

    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });

    this.fetchRealmData();
    this.fetchSubscriptionData();
    this.fetchTasks();
  },
  methods: {
    async fetchRealmData() {
      try {
        const response = await axios.get("/user/allowedRealms");
        this.otherRealms = response.data.filter(
          realm => realm.id !== this.currentRealm.id
        );
      } catch (err) {
        this.$toaster.makeToast(
          "Hinweis!",
          "Die Accounteinstellungen konnten nicht abgerufen werden."
        );
        console.error(err);
      }
    },
    async fetchSubscriptionData() {
      try {
        const response = await axios.get(
          `/subscription/${this.currentRealm.stripeSubscriptionId}`
        );
        this.currentPlan = response.data.plan;
        this.validUntil = new Date(response.data.currentPeriodEnd);
      } catch (err) {
        console.error("Fehler beim Laden des aktuellen Pakets:", err);
      }
    },
    async fetchTasks() {
      try {
        const response = await axios.get("/task/open");
        const count = response.data?.count || 0;
        if (count > 0) {
          const menuItem = document.querySelector('[href="#/tasks"]');
          if (menuItem) {
            const badge = document.createElement("span");
            badge.className = "badge badge-danger";
            badge.style.marginLeft = "10px";
            badge.textContent = count;
            menuItem.appendChild(badge);
          }
        }
      } catch (err) {
        console.error("Fehler beim Laden der Tasks:", err);
      }
    },
    realmSwitch(realm) {
      this.$realmSwitcherModal.showModal(realm.name);
      commons.setToStorage("realmContext", realm.id);
      commons.setToStorage("currentRealm", realm);
      commons.setToStorage("license", realm.license);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    toggleRealmSwitcher() {
      this.showRealmSwitcher = !this.showRealmSwitcher;
      this.calculateStyleRealms();
    },
    calculateStyleRealms() {
      const height = this.showRealmSwitcher
        ? `${Math.min(this.otherRealms.length * 50, 150)}px`
        : "0";
      this.calculatedStyles = `height: ${height}`;
    },
    formatDate(date) {
      if (date instanceof Date && !isNaN(date)) {
        return date.toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      }
      return "Ungültiges Datum";
    },
  },
  computed: {
    computedLogoUrl() {
      return this.currentRealm.organization?.logoUrl
        ? this.currentRealm.organization.logoUrl
        : require("@/assets/img/brand/logo.svg");
    },
  },
};
</script>

<style lang="scss">
.c-sidebar {
  &-dark {
    background: #2c3844;
  }

  .brand-container {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    border-bottom: 1px solid rgba(255,255,255,0.1);

    // Fügen Sie diese Zeile hinzu, um das Logo weiß zu machen
    img {
      filter: brightness(0) invert(1);
    }
  }
}

.current-package {
  color: white;
  padding: 10px;
  font-size: 0.9em;
  background-color: rgba(255,255,255,0.1);
  margin: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.package-details {
  margin-left: 10px;
  line-height: 1.2em;
}

.c-sidebar-minimizer {
  &::before {
    display: none;
  }
}

.minimizer {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  display: block;
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  transition: 0.25s;
  transform: rotate(-90deg);
}

.prt-realm-switcher {
  height: 50px;
  display: flex;
  text-align: left;
  justify-content: left;
  align-items: center;
  color: white;
  width: 100%;
  padding-left: 25px;
}

.otherRealms {
  max-height: 150px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  transition: height 0.3s;
  height: 0;

  .c-sidebar-minimizer {
    background-color: #2b3438;
  }
}
</style>
