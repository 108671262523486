<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <h4>Google My Business Integration</h4>
          </CCardHeader>
          <CCardBody>
            <ConnectionStatus />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow v-if="isConnected">
      <CCol lg="12">
        <LocationsList />
      </CCol>
    </CRow>

    <CRow v-if="isConnected">
      <CCol lg="12">
        <SyncManager />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ConnectionStatus from '@/components/google-my-business/ConnectionStatus'
import LocationsList from '@/components/google-my-business/LocationsList'
import SyncManager from '@/components/google-my-business/SyncManager'

export default {
  name: 'GoogleMyBusiness',

  components: {
    ConnectionStatus,
    LocationsList,
    SyncManager
  },

  computed: {
    ...mapState({
      connectionStatus: state => state.googleMyBusiness.connectionStatus
    }),

    isConnected() {
      return this.connectionStatus.enabled
    }
  },

  created() {
    this.$store.dispatch('googleMyBusiness/fetchStatus')
  }
}
</script>
