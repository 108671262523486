<template>
  <div class="modern-leadgen">
    <CRow>
      <!-- Left Sidebar -->
      <CCol sm="12" md="4" lg="4" xl="3">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <slot name="header">
                  <div class="prt-card-header">
                    <div class="header-naming">
                      <CIcon name="cil-window-restore" />
                      Übersicht der Funnel-Schritte
                    </div>
                  </div>
                </slot>
              </CCardHeader>
              <CCardBody>
                <div class="list-group-wrapper">
                  <LeadgeneratorOrderDraggable
                    :list="pagesComputed"
                    :activePage="activePage"
                    @newPage="addNewPage"
                    @deleteCurrentPage="deleteCurrentPage"
                    @pageClick="activePage = $event"
                    @pageDragMouseUp="reorderPages"
                    @changePageName="changePageName"
                  />
                </div>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <slot name="header">
                  <div class="prt-card-header">
                    <div class="header-naming">
                      <CIcon name="cil-window-restore" />
                      Ihr Funnel ist jetzt live
                    </div>
                  </div>
                </slot>
              </CCardHeader>
              <CCardBody>
                <p>
                  Klicken Sie auf den Button, um den Funnel zu öffnen. Das Einfügen des Funnels in Ihre Webseite mithilfe des untenstehenden Codes ist optional.
                </p>
                <br />
                <CButton
                  class="ml-2 mr-1"
                  :href="`${leadgenBaseUrl}/${iframeKey}/${id}/`"
                  target="_blank"
                  type="button"
                  name="open_funnel"
                  color="info"
                >
                  <CIcon size="sm" class="mr-2" name="cil-share-alt" />
                  <span>Funnel öffnen</span>
                </CButton>
              </CCardBody>
            </CCard>

          </CCol>
        </CRow>
      </CCol>

      <!-- Main Content Area -->
      <CCol sm="12" md="8" lg="8" xl="6">
        <CRow>
          <CCol>
            <CCard>

              <!-- In der Main Content Area, innerhalb der CCardHeader -->
              <ModernHeader
                :pageNumber="activePage + 1"
                :saveStatus="saveStatus"
                :canUndo="canUndo"
                :canRedo="canRedo"
                :previewMode="previewMode"
                @undo="undo"
                @redo="redo"
                @add-module="openAddModuleModal"
                @preview="previewPage"
                @open-templates="openTemplatesModal"
                @update:previewMode="previewMode = $event"
              />
              <CCardBody>
                <div class="leadgenerator-content">
                  <div
                    :class="previewClasses"
                    :style="computedLeadgeneratorContentBlockStyles"
                  >
                    <div class="leadgenerator-content">
                      <div
                        v-for="(component, index) in lgcModulesComputed"
                        :key="`lgcModules-${activePage}-${component.id}-${index}`"
                      >
                      <component
                        v-bind:is="component.type"
                        v-bind="component.props"
                        @input="handleModuleInput(component, $event)"
                        @delete="deleteModule(component.id)"
                        @add-below="openAddModuleModal(component.id)"
                        @modal-open="handleModalOpen"
                        @modal-close="handleModalClose"
                      >
                      </component>
                      </div>
                    </div>
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>

      <!-- Right Sidebar - Properties -->
      <CCol sm="12" md="12" lg="12" xl="3">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">

                <div class="header-button">
                  <CButton
                    color="secondary"
                    variant="ghost"
                    size="sm"
                    @click="openSettingsModal"
                  >
                    <CIcon name="cil-settings" /> Design & Tracking anpassen
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CForm ref="form">
              <CInput
                type="text"
                label="Funnel Name"
                v-model="name"
                required
              />
              <NewsletterGroupSelector
                v-model="groups"
                description="Wählen Sie die Empfängergruppe(n) für die Lead-Zuordnung"
              />
              <div class="text-right mt-4">
                <CButton
                  type="button"
                  @click="publishLeadgenerator"
                  color="success"
                  :disabled="isDisabled"
                >
                  {{ btnText }}
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-code" />
                  Code
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <strong>Einbettung des Lead-Funnels auf Ihrer Webseite</strong>
            <p>
              Kopieren und fügen Sie den folgenden Integrationscode auf jeder Seite Ihrer Webseite ein, auf der der Funnel angezeigt werden soll.
            </p>
            <div class="code-wrapper">
  <textarea
    readonly
    class="form-control font-monospace"
    rows="3"
  >&lt;script type=&quot;text/javascript&quot; src=&quot;{{iframeUrl}}/leadgenerator/{{ id }}/script?key={{iframeKey}}&quot;&gt;&lt;/script&gt;&lt;div id=&quot;prt-leadgen-iframe-placeholder&quot;&gt;&lt;/div&gt;</textarea>
              <CButton
                color="primary"
                variant="ghost"
                size="sm"
                @click="copyIntegrationCode"
              >
                <CIcon name="cil-copy" /> Code kopieren
              </CButton>
            </div>
          </CCardBody>
        </CCard>

      </CCol>
    </CRow>

    <!-- Modals -->
    <!-- Add Module Modal -->
    <CModal
      title="Neuen Modul-Abschnitt hinzufügen..."
      size="lg"
      :show.sync="visibleAddModal"
      :closeOnBackdrop="false"
    >
      <div class="lgc-add-module-list">
        <CCard
          v-for="module in addableModules"
          @click="appendNewModule(module)"
          :key="'addableModule-' + module.title"
        >
          <CCardBody>
            <CIcon :name="module.iconName" size="lg" class="lgc-add-module-list-icon" />
          </CCardBody>
          <CCardFooter>
            {{ module.title }}
          </CCardFooter>
        </CCard>
      </div>
      <div slot="footer">
        <CButton
          class="mr-3"
          color="dark"
          variant="outline"
          @click="visibleAddModal = false"
        >
          Abbrechen
        </CButton>
      </div>
    </CModal>

    <CModal
      title="Design & Tracking anpassen"
      size="lg"
      :show.sync="visibleSettingsModal"
      :closeOnBackdrop="false"
    >
      <CForm>
        <!-- Erscheinungsbild Sektion -->
        <div class="settings-section">
          <h6 class="text-muted mb-3">Erscheinungsbild</h6>
          <PrtFontPicker
            label="Schriftart"
            name="leadgenFontPicker"
            v-model="settings.font"
            class="mb-3"
          />
          <ImageUploadField
            v-model="faviconUrl"
            label="Favicon"
            description="Das Favicon sollte 32x32px groß sein"
            :max-image-size="{
          width: 32,
          height: 32,
          fileSizeInKB: 48,
        }"
          />
        </div>

        <!-- Neue Tracking-Sektion -->
        <div class="settings-section mt-4">
          <h6 class="text-muted mb-3">Tracking</h6>
          <CInput
            type="text"
            label="Google Tag Manager ID"
            v-model="gtmId"
            placeholder="GTM-XXXXXXX"
            class="mb-3"
          />
          <CInput
            type="text"
            label="Facebook Pixel ID"
            v-model="facebookPixelId"
            class="mb-3"
          />
        </div>

        <!-- Neue Custom Header Sektion -->
        <div class="settings-section mt-4">
          <h6 class="text-muted mb-3">Custom Header</h6>
          <CodeEditor
            label="Custom Header Code"
            v-model="customHeader"
            language="markup"
            description="Fügen Sie hier zusätzlichen HTML-Code für den Header ein"
          />
        </div>
      </CForm>

      <template #footer>
        <CButton
          class="mr-3"
          color="secondary"
          @click="closeSettingsModal"
        >
          Abbrechen
        </CButton>
        <CButton
          color="success"
          @click="saveSettingsAndClose"
        >
          Änderungen speichern
        </CButton>
      </template>
    </CModal>

    <!-- Templates Modal -->
    <CModal
      title="Vorlage auswählen und anwenden"
      size="xl"
      :show.sync="visibleTemplatesModal"
      :closeOnBackdrop="false"
    >
      <CTabs>
        <CTab title="Vorlagen">
          <div class="leadgenerator-template-wrapper">
            <div
              v-for="template in leadgeneratorGlobalTemplates"
              @click="loadTemplate(template)"
              class="leadgenerator-template"
            >
              <CImg :src="template.thumbnailUrl" />
              <label>{{ template.name }}</label>
              <CButton
                v-if="$commons.isSystemAdmin()"
                @click.stop.prevent="deleteTemplate(template)"
                size="sm"
                color="danger"
                class="deletable"
              >
                <CIcon size="sm" name="cil-trash"></CIcon>
              </CButton>
            </div>
          </div>
        </CTab>
        <CTab title="Eigene Vorlagen">
          <div class="leadgenerator-template-wrapper">
            <div
              v-for="template in leadgeneratorTemplates"
              @click="loadTemplate(template)"
              class="leadgenerator-template"
            >
              <CImg :src="template.thumbnailUrl" />
              <label>{{ template.name }}</label>
              <CButton
                @click.stop.prevent="deleteTemplate(template)"
                size="sm"
                color="danger"
                class="deletable"
              >
                <CIcon size="sm" name="cil-trash"></CIcon>
              </CButton>
            </div>
          </div>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton color="secondary" @click="visibleTemplatesModal = false"
        >Schließen</CButton
        >
      </template>
    </CModal>
    <CModal
      title="Neue Vorlage erstellen"
      size="sm"
      :show.sync="visibleCreateTemplateModal"
      :closeOnBackdrop="true"
    >
      <CForm>
        <CInput
          v-model="templateCreateName"
          required
          label="Vorlagenname"
        />
        <CInputCheckbox
          v-if="$commons.isSystemAdmin()"
          label="Globale Vorlage erstellen?"
          description="Nur für Administratoren verfügbar."
          id="active"
          name="active"
          value="Aktiv"
          :custom="true"
          :checked.sync="templateCreateIsGlobal"
          :inline="false"
        />
      </CForm>
      <template #footer>
        <CButton color="secondary" @click="visibleCreateTemplateModal = false"
        >Abbrechen</CButton
        >
        <CButton
          v-if="!createTemplateProcessing"
          color="success"
          @click="createLeadgeneratorTemplateFromCurrentDesign"
        >
          Erstellen
        </CButton>
        <CButton v-else color="warning" disabled>Bitte warten...</CButton>
      </template>
    </CModal>

  </div>
</template>

<script>
import ModernHeader from './ModernHeader.vue'
import _ from 'lodash';
import commons from '@/commons';
import axios from 'axios';
import { CButtonGroup } from "@coreui/vue";
import {
  cilActionUndo,
  cilActionRedo,
  cilCheckCircle,
  cilLoopCircular,
  cilWarning,
  cilScreenDesktop,
  cilMobile
} from '@coreui/icons';
import { debounce } from 'lodash';
import html2canvas from 'html2canvas';
import LeadgeneratorOrderDraggable from '@/components/LeadgeneratorOrderDraggable';
import LgcModuleQuiz from '@/views/base/leadgenerator/lgc-modules/LgcModuleQuiz';
import LgcModuleText from '@/views/base/leadgenerator/lgc-modules/LgcModuleText';
import LgcModuleTitle from '@/views/base/leadgenerator/lgc-modules/LgcModuleTitle';
import LgcModuleImage from '@/views/base/leadgenerator/lgc-modules/LgcModuleImage';
import LgcModulePersonalInfo from '@/views/base/leadgenerator/lgc-modules/LgcModulePersonalInfo';
import LgcModuleButton from '@/views/base/leadgenerator/lgc-modules/LgcModuleButton';
import LgcModuleChoices from '@/views/base/leadgenerator/lgc-modules/LgcModuleChoices';
import LgcModuleInput from '@/views/base/leadgenerator/lgc-modules/LgcModuleInput';
import LgcModuleConditionsPrivacy from '@/views/base/leadgenerator/lgc-modules/LgcModuleConditionsPrivacy';
import LgcModuleInputRange from '@/views/base/leadgenerator/lgc-modules/LgcModuleInputRange';
import NewsletterGroupSelector from '@/components/NewsletterGroupSelector';
import PrtFontPicker from '@/components/PrtFontPicker';
import CodeEditor from '@/components/CodeEditor';
import ImageUploadField from '@/components/ImageUploadField';

const defaultContentJson = {
  version: '1',
  metadata: {
    name: 'Neuer Funnel',
    tracking: {},
  },
  pages: [
    {
      name: 'Willkommen',
      id: 1,
      elements: [
        {
          id: 1,
          type: 'LgcModuleTitle',
          props: { value: 'Willkommen zu Ihrem neuen Funnel' },
        },
        {
          id: 2,
          type: 'LgcModulePersonalInfo',
          props: {
            value: {
              header: 'Kontaktinformationen',
              buttonText: 'Weiter',
              conditionsPrivacyText:
                'Der Schutz Ihrer Daten ist uns wichtig. ' +
                commons.getCurrentRealm().name +
                ' verwendet Ihre Informationen ausschließlich für die angeforderten Dienste. Sie können sich jederzeit abmelden. Weitere Details finden Sie in unserer Datenschutzerklärung.',
            },
          },
        },
      ],
    },
  ],
};

export default {
  name: 'Leadgenerator',
  components: {
    ModernHeader,
    CButtonGroup,
    ImageUploadField,
    PrtFontPicker,
    NewsletterGroupSelector,
    LgcModuleTitle,
    LgcModuleText,
    LgcModuleQuiz,
    LgcModuleImage,
    LeadgeneratorOrderDraggable,
    LgcModulePersonalInfo,
    LgcModuleButton,
    LgcModuleChoices,
    LgcModuleInput,
    LgcModuleInputRange,
    LgcModuleConditionsPrivacy,
    CodeEditor,
  },

  props: {
    passedData: {
      type: Object,
      default: () => ({}),
    },
    btnText: {
      type: String,
      default: 'Veröffentlichen'
    },
    processing: {  // Moved inside props object
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      icons: {
        cilActionUndo,
        cilActionRedo,
        cilCheckCircle,
        cilLoopCircular,
        cilWarning,
        cilScreenDesktop,
        cilMobile
      },
      previewMode: 'desktop',
      activePage: 0,
      visibleAddModal: false,
      visibleSettingsModal: false,
      visibleTemplatesModal: false,
      visibleCreateTemplateModal: false,
      insertNewModuleAfterId: -1,
      iframeUrl: process.env.VUE_APP_IFRAME_URL,
      iframeKey: commons.getFromStorage('currentRealm').iframeKey,
      addableModules: [
        { title: 'Titel', iconName: 'cil-text', type: 'title' },
        { title: 'Text', iconName: 'cil-description', type: 'text' },
        { title: 'Quiz', iconName: 'cil-BorderAll', type: 'quiz' },
        { title: 'Bild', iconName: 'cil-image', type: 'image' },
        { title: 'Formular', iconName: 'cil-file', type: 'form' },
        { title: 'Button', iconName: 'cil-hand-point-right', type: 'button' },
        { title: 'Auswahl', iconName: 'cil-list', type: 'choices' },
        { title: 'Eingabefeld', iconName: 'cil-input', type: 'input' },
        { title: 'Slidebar', iconName: 'cil-arrow-thick-to-right', type: 'range' },
        { title: 'AGB & Datenschutz', iconName: 'cil-lock-locked', type: 'privacy' },
      ],
      id: this.passedData.id,
      name: this.passedData.name || '',
      gtmId: this.passedData.gtmId || '',
      facebookPixelId: this.passedData.facebookPixelId || '',
      groups: this.passedData.groups || [],
      leadgenBaseUrl: process.env.VUE_APP_LEADGEN_BASE_URL,
      customHeader: '',
      faviconUrl: '',
      settings: {},
      leadgeneratorTemplates: [],
      leadgeneratorGlobalTemplates: [],
      templateCreateName: '',
      templateCreateIsGlobal: false,
      createTemplateProcessing: false,
      screenshotBlob: null,
      isModalOpen: false,
      lastSavedContent: null,
      isProcessing: false,
      isProgrammaticChange: false,
      isSaving: false,
      saveStatus: 'saved', // 'saved', 'saving', 'error'
      changeHistory: [],
      currentHistoryIndex: -1,
      isPublishing: false,
      keyboardShortcuts: {
        'ctrl+s': this.save,
        'ctrl+z': this.undo,
        'ctrl+y': this.redo,
      },
      contentJson: this.passedData.contentJson || {
        version: '1',
        metadata: {
          name: 'Neuer Funnel',
          tracking: {},
        },
        pages: [
          {
            name: 'Willkommen',
            id: 1,
            elements: [
              {
                id: 1,
                type: 'LgcModuleTitle',
                props: { value: 'Willkommen zu Ihrem neuen Funnel' },
              }
            ],
          },
        ],
      },
      initialLoad: true, // Neue Property für initiales Laden
    };
  },
  computed: {

    isDisabled() {
      return this.processing || this.isPublishing || this.isProcessing;
    },

      previewClasses() {
        return {
          'content-preview': true,
          'preview-desktop': this.previewMode === 'desktop',
          'preview-mobile': this.previewMode === 'mobile'
        };
      },

      pagesComputed() {
        return this.contentJson.pages.map((page) => page.name);
      },

      lgcModulesComputed() {
        const currentPage = this.contentJson.pages[this.activePage];
        return currentPage ? currentPage.elements : [];
      },

      computedLeadgeneratorContentBlockStyles() {
        return {
          fontFamily: this.settings.font || 'inherit',
        };
      },

    hasUnsavedChanges() {
      if (this.isModalOpen || !this.lastSavedContent || this.isProgrammaticChange || this.initialLoad) {
        return false;
      }
      const currentContent = _.cloneDeep(this.contentJson);
      const lastSaved = _.cloneDeep(this.lastSavedContent);
      return !_.isEqual(currentContent, lastSaved);
    },

    canUndo() {
      return this.currentHistoryIndex > 0;
    },

    canRedo() {
      const canRedo = this.currentHistoryIndex < this.changeHistory.length - 1;
      console.log('Can redo:', canRedo, 'Current index:', this.currentHistoryIndex, 'History length:', this.changeHistory.length);
      return canRedo;
    },

    saveStatusIcon() {
      const icons = {
        saved: 'cil-check-circle',
        saving: 'cil-loop-circular',
        error: 'cil-warning'
      };
      return icons[this.saveStatus] || 'cil-check-circle';
    },
  },

  created() {
    this.$root.$options.icons = {
      ...this.$root.$options.icons,
      ...this.icons
    };
    this.initializeComponent();
    this.fetchAllTemplates();
    // Setze lastSavedContent erst nach dem initialen Laden
    this.$nextTick(() => {
      this.initializeHistory();
      this.lastSavedContent = _.cloneDeep(this.contentJson);
      this.initialLoad = false;
    });
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },

  methods: {
    async publishLeadgenerator() {
      try {
        this.isPublishing = true;
        const payload = {
          name: this.name,
          gtmId: this.gtmId,
          facebookPixelId: this.facebookPixelId,
          contentJson: this.contentJson,
          groups: this.groups,
          isPublishing: true
        };
        await this.$emit('submit', payload);

        // Nach erfolgreicher Veröffentlichung zur Liste navigieren
        this.$router.push({
          name: "LeadgeneratorList",
          params: { saved: "1" }
        });

        // Erfolgsmeldung anzeigen
        this.$toaster.makeToast(
          "Erfolgreich!",
          "<b>Funnel wurde erfolgreich veröffentlicht</b>"
        );
      } catch (error) {
        // Fehlermeldung anzeigen
        this.$toaster.makeToast(
          "Fehler!",
          "<b>Beim Veröffentlichen ist ein Fehler aufgetreten</b>"
        );
        console.error('Publishing error:', error);
      } finally {
        this.isPublishing = false;
      }
    },
    debouncedSave: _.debounce(function() {
      if (this.isSaving || this.isModalOpen) return;

      this.saveStatus = 'saving';
      this.submitLeadgen()
        .then(() => {
          this.saveStatus = 'saved';
        })
        .catch(() => {
          this.saveStatus = 'error';
        });
    }, 1000),

    // Historie-Management
    addToHistory() {
      if (this.isProgrammaticChange || this.initialLoad || this.isProcessing) {
        return;
      }

      // Entferne zukünftige Historie
      if (this.currentHistoryIndex < this.changeHistory.length - 1) {
        this.changeHistory = this.changeHistory.slice(0, this.currentHistoryIndex + 1);
      }

      // Vergleiche mit dem letzten Zustand, um doppelte Einträge zu vermeiden
      const currentState = _.cloneDeep(this.contentJson);
      const lastState = this.changeHistory[this.changeHistory.length - 1];

      if (!_.isEqual(currentState, lastState)) {
        this.changeHistory.push(currentState);
        this.currentHistoryIndex = this.changeHistory.length - 1;

        if (!this.isModalOpen) {
          this.saveStatus = 'saving';
          this.debouncedSave();
        }

      } else {
        console.log('Skipping duplicate state');
      }
    },

    initializeHistory() {
      this.changeHistory = [_.cloneDeep(this.contentJson)];
      this.currentHistoryIndex = 0;
    },

    undo() {
      if (!this.canUndo) {
        return;
      }

      console.log('Performing undo', {
        fromIndex: this.currentHistoryIndex,
        historyLength: this.changeHistory.length
      });

      this.isProgrammaticChange = true;
      try {
        this.currentHistoryIndex--;
        this.contentJson = _.cloneDeep(this.changeHistory[this.currentHistoryIndex]);

        console.log('Undo complete', {
          newIndex: this.currentHistoryIndex,
          canRedo: this.canRedo
        });
      } finally {
        this.isProgrammaticChange = false;
      }
    },

    redo() {
      if (!this.canRedo) {
        return;
      }

      console.log('Performing redo', {
        fromIndex: this.currentHistoryIndex,
        historyLength: this.changeHistory.length
      });

      this.isProgrammaticChange = true;
      try {
        this.currentHistoryIndex++;
        this.contentJson = _.cloneDeep(this.changeHistory[this.currentHistoryIndex]);

        console.log('Redo complete', {
          newIndex: this.currentHistoryIndex,
          canUndo: this.canUndo
        });
      } finally {
        this.isProgrammaticChange = false;
      }
    },

    // Keyboard Shortcuts
    setupKeyboardShortcuts() {
      Object.entries(this.keyboardShortcuts).forEach(([shortcut, handler]) => {
        this.$mousetrap.bind(shortcut, (e) => {
          e.preventDefault();
          handler();
        });
      });
    },

    removeKeyboardShortcuts() {
      Object.keys(this.keyboardShortcuts).forEach(shortcut => {
        this.$mousetrap.unbind(shortcut);
      });
    },

    previewPage() {
      const previewUrl = `${this.leadgenBaseUrl}/${this.iframeKey}/${this.id}/preview/${this.activePage}`;
      window.open(previewUrl, '_blank');
    },
    handleModuleInput(component, $event) {
      this.isProgrammaticChange = true;
      try {
        component.props = $event;
        // Änderungen in den Content übernehmen, aber nicht sofort speichern
        if (!this.isModalOpen) {
          this.debouncedSave();
        }
      } finally {
        this.isProgrammaticChange = false;
      }
    },
    deleteModule(id) {
      console.log('Deleting module:', id);

      this.isProcessing = true;
      try {
        const currentPage = this.contentJson.pages[this.activePage];
        currentPage.elements = _.filter(currentPage.elements, (c) => c.id !== id);
        this.reorderElementsIds();

        // Füge den neuen Zustand zur Historie hinzu
        this.isProcessing = false;
        this.addToHistory();
        this.saveStatus = 'saving'; // Status aktualisieren

        if (!this.isModalOpen) {
          this.debouncedSave();
        }
      } finally {
        this.isProcessing = false;
      }
    },
    handleBeforeUnload(event) {
      if (this.hasUnsavedChanges && !this.isModalOpen) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    },
    async createLeadgeneratorTemplateFromCurrentDesign() {
      this.createTemplateProcessing = true;
      try {
        // Upload screenshot first
        const data = new FormData();
        const file = commons.blobToFile(
          this.screenshotBlob,
          "leadgenerator-template-preview.jpg"
        );
        data.append("file", file, file.name);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        };
        const responseFileUpload = await axios.post(
          "/file/upload",
          data,
          options
        );
        const responseCreate = await axios.post(
          this.templateCreateIsGlobal
            ? "/leadgeneratorTemplate/global"
            : "/leadgeneratorTemplate",
          {
            name: this.templateCreateName,
            thumbnailUrl: responseFileUpload.data.fileDownloadUrl,
            contentJson: JSON.stringify(this.contentJson),
          }
        );
        this.visibleCreateTemplateModal = false;
        this.screenshotBlob = null;
        this.fetchAllTemplates();
        this.$toaster.makeToast("Erfolg", "Die Vorlage wurde erfolgreich erstellt.");
      } catch (error) {
        this.$toaster.makeToast("Fehler", "Beim Erstellen der Vorlage ist ein Fehler aufgetreten.");
        console.error(error);
      } finally {
        this.createTemplateProcessing = false;
      }
    },
    loadTemplate(template) {
      this.$modal.showModal(
        'default',
        'Möchten Sie diese Vorlage verwenden?',
        [
          `Nicht gespeicherte Änderungen im Editor gehen mit dieser Aktion verloren.`,
        ],
        () => {
          const contentJsonFromTemplate = JSON.parse(template.contentJson);
          this.contentJson = { pages: [] };
          setTimeout(() => {
            this.contentJson = contentJsonFromTemplate;
            this.visibleTemplatesModal = false;
          }, 10);
        },
        () => {},
        {
          btnCancel: 'abbrechen',
          btnConfirm: 'Vorlage laden und anwenden',
          btnConfirmColor: 'danger',
        }
      );
    },
    deleteCurrentPage(pageIndex) {
      this.$modal.showModal(
        'delete',
        null,
        [
          `<b>Funnel-Schritt</b> (${this.contentJson.pages[pageIndex].name})`,
        ],
        async () => {
          console.log('Deleting page at index:', pageIndex);

          this.isProcessing = true;
          try {
            const previousPages = _.cloneDeep(this.contentJson.pages);
            const id = pageIndex + 1;

            this.contentJson.pages = this.contentJson.pages.filter(page => page.id !== id);

            // Neu-Indizierung der verbleibenden Seiten
            this.contentJson.pages.forEach((page, index) => {
              page.id = index + 1;
            });

            // Aktive Seite anpassen
            this.activePage = Math.max(0, Math.min(this.activePage, this.contentJson.pages.length - 1));

            // Nur zur Historie hinzufügen, wenn sich wirklich etwas geändert hat
            if (!_.isEqual(previousPages, this.contentJson.pages)) {
              this.isProcessing = false; // Temporär deaktivieren für addToHistory
              this.addToHistory();
              this.saveStatus = 'saving';
              if (!this.isModalOpen) {
                this.debouncedSave();
              }
            }
          } finally {
            this.isProcessing = false;
          }
        }
      );
    },
    async initializeComponent() {
      this.settings = this.contentJson.settings || {
        font: 'Open Sans',
      };
      this.customHeader = _.get(this.contentJson, 'metadata.tracking.customHeader', '');
      this.faviconUrl = _.get(this.contentJson, 'metadata.faviconUrl', '');
      this.lastSavedContent = _.cloneDeep(this.contentJson);
    },

    handlePageClick(page) {
      if (this.hasUnsavedChanges) {
        this.$modal.showModal(
          'confirm',
          'Ungesicherte Änderungen',
          ['Möchten Sie die Änderungen speichern, bevor Sie die Seite wechseln?'],
          () => {
            this.submitLeadgen();
            this.activePage = page;
          },
          () => {
            this.activePage = page;
          }
        );
      } else {
        this.activePage = page;
      }
    },

    openAddModuleModal(insertAfterId) {
      this.isProcessing = true;
      this.visibleAddModal = true;
      this.insertNewModuleAfterId = Number.isInteger(insertAfterId) ? insertAfterId : -1;
    },

    async appendNewModule(module) {

      this.isProcessing = true;
      try {
        const currentPage = this.contentJson.pages[this.activePage];
        const newId = currentPage.elements.length + 1;
        const newModule = this.createNewModule(module.type, newId);

        if (newModule) {
          // Speichere den vorherigen Zustand
          const previousElements = _.cloneDeep(currentPage.elements);

          if (this.insertNewModuleAfterId !== -1) {
            currentPage.elements.splice(this.insertNewModuleAfterId, 0, newModule);
            this.reorderElementsIds();
          } else {
            currentPage.elements.push(newModule);
          }

          // Nur zur Historie hinzufügen, wenn sich wirklich etwas geändert hat
          if (!_.isEqual(previousElements, currentPage.elements)) {
            this.isProcessing = false;
            this.addToHistory();
            this.saveStatus = 'saving';
          }
        }

        this.visibleAddModal = false;
        this.insertNewModuleAfterId = -1;
      } finally {
        // Verzögerung für UI-Updates
        setTimeout(() => {
          this.isProcessing = false;
        }, 100);
      }
    },

    updateContentJson(updater) {
      this.isProgrammaticChange = true;
      try {
        updater();
      } finally {
        this.isProgrammaticChange = false;
      }
    },

    openCreateTemplateModal() {
      this.createTemplateProcessing = true;
      setTimeout(() => {
        this.screenshotLeadgeneratorContent()
          .then((blob) => {
            this.screenshotBlob = blob;
            this.createTemplateProcessing = false;
            this.visibleCreateTemplateModal = true;
          })
          .catch((err) => {
            this.createTemplateProcessing = false;
            this.$toaster.makeToast(
              "Probleme beim Erstellen einer Template-Preview. Bitte später versuchen."
            );
            console.error(err);
          });
      }, 1);
    },

    screenshotLeadgeneratorContent() {
      return new Promise((resolve, reject) => {
        const captureElement = document.querySelector(
          ".content-preview"  // Geänderter Selektor
        );
        if (!captureElement) {
          reject(new Error('Preview element not found'));
          return;
        }
        captureElement.classList.add("html2canvas-screenshotting");
        html2canvas(captureElement, {
          allowTaint: false,
          useCORS: true,
          ignoreElements: (el) =>
            el.classList.contains("leadgenerator-content-more"),
        })
          .then((canvas) => {
            captureElement.classList.remove("html2canvas-screenshotting");
            canvas.style.display = "none";
            document.body.appendChild(canvas);
            return canvas;
          })
          .then((canvas) => {
            canvas.toBlob(
              (blob) => {
                canvas.remove();
                resolve(blob);
              },
              "image/jpeg",
              0.85
            );
          })
          .catch(reject);
      });
    },

    copyIntegrationCode() {
      const codeElement = document.querySelector('textarea.form-control.font-monospace');
      if (codeElement) {
        codeElement.select();
        document.execCommand('copy');
        this.$toaster.makeToast('Erfolg', 'Der Code wurde in die Zwischenablage kopiert.');
      }
    },

    getPreviewScale() {
      if (this.previewMode === 'mobile') {
        const containerWidth = this.$el.querySelector('.content-preview').offsetWidth;
        return Math.min(1, containerWidth / 375);
      }
      return 1;
    },

    updatePreviewScaling() {
      const scale = this.getPreviewScale();
      const preview = this.$el.querySelector('.content-preview');
      if (preview) {
        preview.style.transform = `scale(${scale})`;
        preview.style.transformOrigin = 'top center';
      }
  },

    mounted() {
      window.addEventListener('resize', this.updatePreviewScaling);
      this.$nextTick(this.updatePreviewScaling);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.updatePreviewScaling);
    },

    createNewModule(type, id) {
      const moduleTemplates = {
        title: {
          type: 'LgcModuleTitle',
          props: { value: 'Neuer Titel' }
        },
        text: {
          type: 'LgcModuleText',
          props: { value: 'Neuer Text' }
        },
        quiz: {
          type: 'LgcModuleQuiz',
          props: {
            value: [
              {
                id: 1,
                title: 'Option 1',
                image: 'https://picsum.photos/seed/1/400/200'
              }
            ]
          }
        },
        image: {
          type: 'LgcModuleImage',
          props: {
            value: "https://picsum.photos/seed/" + Math.floor(Math.random() * 1000) + "/400/200"
          }
        },
        form: {
          type: 'LgcModulePersonalInfo',
          props: {
            value: {
              header: 'Ein Strategiegespräch ist für Sie unverbindlich und kostenfrei',
              buttonText: 'Jetzt Kontakt aufnehmen!',
              conditionsPrivacyTextSize: '12px',
              conditionsPrivacyText: 'Ihr Datenschutz ist uns wichtig. ' +
                commons.getCurrentRealm().name +
                ' nutzt Ihre Angaben, um Sie hinsichtlich relevanter Inhalte, Produkte und Dienstleistungen zu kontaktieren. Sie können sich jederzeit von jeglicher Kommunikation seitens ' +
                commons.getCurrentRealm().name +
                ' abmelden. Weitere Informationen finden Sie in unserer Datenschutzerklärung.'
            }
          }
        },
        button: {
          type: 'LgcModuleButton',
          props: {
            value: {
              buttonText: 'Klick mich!'
            }
          }
        },
        choices: {
          type: 'LgcModuleChoices',
          props: {
            value: [
              { id: 1, title: 'Immobilie 1' },
              { id: 2, title: 'Immobilie 2' },
              { id: 3, title: 'Immobilie 3' },
              { id: 4, title: 'Immobilie 4' }
            ]
          }
        },
        input: {
          type: 'LgcModuleInput',
          props: {
            value: {
              inputText: 'Beratung zur Immobilienfinanzierung',
              inputPlaceholder: 'Ihr Beratungswunsch',
              inputRequired: true
            }
          }
        },
        range: {
          type: 'LgcModuleInputRange',
          props: {
            value: {
              inputText: 'Dies ist ein schöner Slidebar!',
              inputMin: 0,
              inputMax: 100,
              inputStep: 1,
              inputRequired: true
            }
          }
        },
        privacy: {
          type: 'LgcModuleConditionsPrivacy',
          props: {
            value: {
              conditionsPrivacyActive: true,
              conditionsPrivacyTextSize: '12px',
              conditionsPrivacyText: 'Ihr Datenschutz ist uns wichtig. ' +
                commons.getCurrentRealm().name +
                ' nutzt Ihre Angaben, um Sie hinsichtlich relevanter Inhalte, Produkte und Dienstleistungen zu kontaktieren. Sie können sich jederzeit von jeglicher Kommunikation seitens ' +
                commons.getCurrentRealm().name +
                ' abmelden. Weitere Informationen finden Sie in unserer Datenschutzerklärung.'
            }
          }
        }
      };

      const template = moduleTemplates[type];
      return template ? { id, ...template } : null;
    },

    async submitLeadgen() {
      if (this.isProcessing) return;

      try {
        this.isProcessing = true;
        const payload = {
          name: this.name,
          gtmId: this.gtmId,
          facebookPixelId: this.facebookPixelId,
          contentJson: this.contentJson,
          groups: this.groups,
          isPublishing: false
        };
        await this.$emit('submit', payload);
        this.lastSavedContent = _.cloneDeep(this.contentJson);
      } catch (error) {
        console.error('Submit error:', error);
        throw error; // Wichtig: Error weiterwerfen für die Status-Behandlung
      } finally {
        this.isProcessing = false;
      }
    },

    handleModalOpen() {
      this.isModalOpen = true;
    },

    handleModalClose() {
      this.isModalOpen = false;
      // Jetzt speichern, wenn Änderungen vorliegen
      if (this.hasUnsavedChanges) {
        this.debouncedSave();
      }
    },

    async deleteLeadgen() {
      this.$modal.showModal(
        'delete',
        null,
        [`<b>Leadgenerator</b> (${this.name})`],
        async () => {
          try {
            await axios.delete(`/leadgenerator/${this.id}`);
            this.$toaster.makeToast('Erfolg', 'Der Leadgenerator wurde gelöscht.');
            this.$router.push({ name: 'LeadgeneratorList' });
          } catch (error) {
            this.$toaster.makeToast('Fehler', 'Beim Löschen ist ein Fehler aufgetreten.');
            console.error('Delete error:', error);
          }
        }
      );
    },

    fetchAllTemplates() {
      axios
        .get('/leadgeneratorTemplate/global')
        .then((response) => {
          this.leadgeneratorGlobalTemplates = response.data;
        })
        .catch((err) =>
          console.error('Problem while fetching global templates', err),
        );
      axios
        .get('/leadgeneratorTemplate')
        .then((response) => {
          this.leadgeneratorTemplates = response.data;
        })
        .catch((err) =>
          console.error('Problem while fetching leadgenerator templates', err),
        );
    },

    addNewPage() {
      console.log('Adding new page');

      this.isProcessing = true;
      try {
        const currentPageCount = this.contentJson.pages.length;
        const previousPages = _.cloneDeep(this.contentJson.pages);

        this.contentJson.pages.push({
          name: "Seite " + (currentPageCount + 1),
          id: currentPageCount + 1,
          elements: [
            {
              id: 1,
              type: "LgcModuleTitle",
              props: { value: "Eine frische neue Seite!" },
            },
          ],
        });

        // Nur zur Historie hinzufügen, wenn sich wirklich etwas geändert hat
        if (!_.isEqual(previousPages, this.contentJson.pages)) {
          this.isProcessing = false; // Temporär deaktivieren für addToHistory
          this.addToHistory();
          this.saveStatus = 'saving';
          if (!this.isModalOpen) {
            this.debouncedSave();
          }
        }
      } finally {
        // Kurze Verzögerung vor dem Zurücksetzen des Flags
        setTimeout(() => {
          this.isProcessing = false;
        }, 100);
      }
    },

    reorderPages(newPageOrder) {
      console.log('Reordering pages:', newPageOrder);

      this.isProcessing = true;
      try {
        const previousPages = _.cloneDeep(this.contentJson.pages);
        const newPages = [];

        for (const key in newPageOrder) {
          const newValue = newPageOrder[key];
          newPages[key] = this.contentJson.pages[newValue];
        }

        newPages.forEach((page, index) => {
          page.id = index + 1;
        });

        const newActivePage = newPages.indexOf(
          this.contentJson.pages[this.activePage]
        );

        this.contentJson.pages = newPages;
        this.activePage = newActivePage;

        // Nur zur Historie hinzufügen, wenn sich wirklich etwas geändert hat
        if (!_.isEqual(previousPages, this.contentJson.pages)) {
          this.isProcessing = false; // Temporär deaktivieren für addToHistory
          this.addToHistory();
          this.saveStatus = 'saving';
          if (!this.isModalOpen) {
            this.debouncedSave();
          }
        }
      } finally {
        this.isProcessing = false;
      }
    },
    reorderElementsIds() {
      const currentPage = this.contentJson.pages[this.activePage];
      if (currentPage) {
        currentPage.elements.forEach((elm, index) => {
          elm.id = index + 1;
        });
      }
    },
    changePageName(index, newPageName) {
      console.log('Changing page name:', { index, newPageName });

      this.isProcessing = true;
      try {
        const previousPages = _.cloneDeep(this.contentJson.pages);
        this.contentJson.pages[index].name = newPageName;

        // Nur zur Historie hinzufügen, wenn sich wirklich etwas geändert hat
        if (!_.isEqual(previousPages, this.contentJson.pages)) {
          this.isProcessing = false; // Temporär deaktivieren für addToHistory
          this.addToHistory();
          this.saveStatus = 'saving';
          if (!this.isModalOpen) {
            this.debouncedSave();
          }
        }
      } finally {
        this.isProcessing = false;
      }
    },

    openTemplatesModal() {
      this.visibleTemplatesModal = true;
    },

    saveSettingsAndClose() {
      // Speichern der Einstellungen
      this.settings = {
        ...this.settings,
        tracking: {
          googleTagManager: this.gtmId,
          facebookPixel: this.facebookPixelId,
          customHeader: this.customHeader
        }
      };

      this.closeSettingsModal();

      // Optional: Toast-Nachricht anzeigen
      this.$toaster.makeToast('Erfolg', 'Die Einstellungen wurden gespeichert.');
    },

    closeSettingsModal() {
      this.visibleSettingsModal = false;
      this.handleModalClose();
    },

    openSettingsModal() {
      // Laden der aktuellen Einstellungen
      this.gtmId = _.get(this.settings, 'tracking.googleTagManager', '');
      this.facebookPixelId = _.get(this.settings, 'tracking.facebookPixel', '');
      this.customHeader = _.get(this.settings, 'tracking.customHeader', '');

      this.handleModalOpen();
      this.visibleSettingsModal = true;
    }
  },

    closeModal() {
      this.isModalOpen = false;
      if (this._originalBeforeUnload) {
        window.onbeforeunload = this._originalBeforeUnload;
        this._originalBeforeUnload = null;
      }
    },

    previewPage() {
      const previewUrl = `${this.leadgenBaseUrl}/${this.iframeKey}/${this.id}/preview/${this.activePage}`;
      window.open(previewUrl, '_blank');
    },

    deleteModule(id) {
      const currentPage = this.contentJson.pages[this.activePage];
      currentPage.elements = _.filter(currentPage.elements, (c) => {
        return c.id !== id;
      });
      this.reorderElementsIds();
    },

    reorderElementsIds() {
      const currentPage = this.contentJson.pages[this.activePage];
      if (currentPage) {
        currentPage.elements.forEach((elm, index) => {
          elm.id = index + 1;
        });
      }
    },

    updateModule(value, moduleId) {
      const currentPage = this.contentJson.pages[this.activePage];
      const module = currentPage.elements.find(m => m.id === moduleId);
      if (module) {
        module.props = value;
      }
    },

    handleBeforeUnload(event) {
      if (this.hasUnsavedChanges && !this.isModalOpen) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    },

  watch: {
    previewMode() {
      this.$nextTick(this.updatePreviewScaling);
    },
    gtmId(newVal) {
      _.set(this.contentJson, 'metadata.tracking.googleTagManager', newVal);
    },
    facebookPixelId(newVal) {
      _.set(this.contentJson, 'metadata.tracking.facebookPixel', newVal);
    },
    customHeader(newVal) {
      _.set(this.contentJson, 'metadata.tracking.customHeader', newVal);
    },
    faviconUrl(newVal) {
      _.set(this.contentJson, 'metadata.faviconUrl', newVal);
    },
    settings: {
      handler(newSettings) {
        this.contentJson.settings = { ...newSettings };
      },
      deep: true,
    },
    contentJson: {
      deep: true,
      handler(newVal) {
        // Verhindere ungewollte History-Einträge
        if (this.initialLoad || this.isProgrammaticChange || this.isModalOpen || this.isProcessing) {
          return;
        }

        if (this.hasUnsavedChanges) {
          this.saveStatus = 'saving'; // Status aktualisieren
          this.debouncedSave();
        }
      }
    }
  },
  'settings.tracking': {
    deep: true,
    handler(newVal) {
      if (newVal) {
        this.gtmId = newVal.googleTagManager || '';
        this.facebookPixelId = newVal.facebookPixel || '';
        this.customHeader = newVal.customHeader || '';
      }
    }
  },
};
</script>

<style lang="scss">
.modern-leadgen {
  // Variablen
  --primary: #3b82f6;
  --primary-light: #eff6ff;
  --secondary: #6b7280;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --success: #10b981;
  --success-light: rgba(16, 185, 129, 0.1);
  --danger: #ef4444;
  --danger-light: rgba(239, 68, 68, 0.1);
  --warning: #f59e0b;
  --warning-light: rgba(245, 158, 11, 0.1);
  --info: #3b82f6;
  --info-light: rgba(59, 130, 246, 0.1);

  // Basis Layout
  padding: 1.5rem;
  background: var(--gray-50);

  // Card Styles
  .card {
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    background: white;
    margin-bottom: 1rem;

    .card-header {
      background: white;
      border-bottom: 1px solid var(--gray-200);
      padding: 1rem 1.25rem;
      border-radius: 0.75rem 0.75rem 0 0;
    }

    .card-body {
      padding: 1.25rem;
    }
  }

  // Button Styles
  .btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
    font-size: 0.875rem;

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }

    &.btn-primary {
      background: var(--primary);
      border: none;
      color: white;

      &:hover:not(:disabled) {
        background: darken(#3b82f6, 5%);
      }
    }

    &.btn-secondary {
      background: var(--gray-100);
      border: 1px solid var(--gray-200);
      color: var(--gray-700);

      &:hover:not(:disabled) {
        background: var(--gray-200);
      }
    }

    &.btn-success {
      background: var(--success);
      border: none;
      color: white;

      &:hover:not(:disabled) {
        background: darken(#10b981, 5%);
      }
    }

    &.btn-ghost {
      background: transparent;
      border: 1px solid transparent;
      color: var(--gray-600);

      &:hover:not(:disabled) {
        background: var(--gray-100);
      }
    }
  }

  // Content Preview
  .content-preview {
    background: white;
    margin: 1rem auto;
    transition: all 0.3s ease;
    border: 1px solid var(--gray-200);

    &.preview-desktop {
      max-width: 800px;
      min-height: 600px;
      padding: 2rem;
      border-radius: 0.75rem;
    }

    &.preview-mobile {
      max-width: 375px;
      height: 667px;
      padding: 1rem;
      border-radius: 2rem;
      position: relative;
      border: 12px solid var(--gray-800);
      margin: 2rem auto;

      .leadgenerator-content {
        height: calc(100% - 24px);
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--gray-300);
          border-radius: 2px;
        }
      }
    }
  }

  // Module Bereich
  .module-wrapper {
    position: relative;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px dashed transparent;
    border-radius: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
      border-color: var(--primary);
      background: var(--primary-light);

      .module-actions {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .module-actions {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      opacity: 0;
      transform: translateX(10px);
      transition: all 0.2s ease;
      display: flex;
      gap: 0.25rem;
    }
  }

  // Modal Styles
  .modal {
    .modal-content {
      border: none;
      border-radius: 0.75rem;
      overflow: hidden;
    }

    .modal-header {
      background: white;
      border-bottom: 1px solid var(--gray-200);
      padding: 1.25rem;

      .modal-title {
        font-weight: 600;
        color: var(--gray-800);
      }
    }

    .modal-body {
      padding: 1.5rem;
      max-height: 70vh;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: var(--gray-100);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--gray-300);
        border-radius: 3px;
      }
    }

    .modal-footer {
      background: white;
      border-top: 1px solid var(--gray-200);
      padding: 1.25rem;
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
    }
  }

  // Template Grid
  .leadgenerator-template-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;

    .leadgenerator-template {
      position: relative;
      border: 1px solid var(--gray-200);
      border-radius: 0.5rem;
      overflow: hidden;
      transition: all 0.2s ease;
      cursor: pointer;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }

      label {
        display: block;
        padding: 0.75rem;
        margin: 0;
        font-size: 0.875rem;
        font-weight: 500;
        background: white;
        border-top: 1px solid var(--gray-200);
      }
    }
  }
  .lgc-add-module-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    padding: 15px;

    .card {
      margin: 0;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border: 1px solid var(--gray-200);
      border-radius: 12px;
      background: white;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        border-color: var(--primary);

        .card-body {
          color: var(--primary);
        }

        .card-footer {
          background-color: var(--primary);
          color: white;
        }

        .lgc-add-module-list-icon {
          color: var(--primary);
          transform: scale(1.1);
        }
      }
    }

    .card-body {
      padding: 1.25rem;
      text-align: center;
    }

    .card-footer {
      background-color: transparent;
      border-top: 1px solid var(--gray-200);
      padding: 0.75rem;
      text-align: center;
      font-weight: 500;
      font-size: 0.9rem;
      width: 100%;
      transition: all 0.3s ease;
    }

    .lgc-add-module-list-icon {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      color: var(--gray-600);
      transition: all 0.3s ease;
    }
  }
  // Form Elements
  .form-control {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid var(--gray-300);
    font-size: 0.875rem;
    transition: all 0.2s ease;

    &:focus {
      border-color: var(--primary);
      box-shadow: 0 0 0 3px var(--primary-light);
      outline: none;
    }
  }
  .code-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .code-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      textarea {
        width: 100%;
        font-size: 0.875rem;
        padding: 0.5rem;
        border: 1px solid var(--gray-200);
        border-radius: 0.375rem;
        font-family: monospace;
        min-height: 80px;
      }

      .btn {
        align-self: flex-end;  // Button rechtsbündig ausrichten
      }
    }

    .btn {
      align-self: flex-end;  // Button rechtsbündig ausrichten
    }
  }
}

</style>
