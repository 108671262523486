<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-window-restore" />
                  Lead Funnels
                </div>
                <div class="header-button">
                  <CButton
                    to="leadGenerator/new"
                    name="backagain"
                    size="sm"
                    key="key"
                    color="dark"
                  >
                    <CIcon size="sm" name="cil-plus" />
                    <span>Neuen Funnel erstellen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CDataTable
              :items="tableData"
              :itemsPerPageSelect="{ label: 'Leadgeneratoren pro Seite' }"
              :fields="getTableFields()"
              :noItemsView="{
                noResults: 'Keine Suchergebnisse verfügbar',
                noItems: 'Keine Lead-Funnels verfügbar',
              }"
              hover
              striped
              bordered
              fixed
              column-filter
              table-filter
              :tableFilter="{
                label: 'Suchen',
                placeholder: 'Suchbegriff eingeben',
              }"
              items-per-page-select
              sorter
              :sorterValue="{ column: 'name', asc: true }"
              pagination
              :loading="tableShowLoading"
            >
              <template #pages="{ item }">
                <td>
                  {{ JSON.parse(item.contentJson).pages.length }} Schritte
                </td>
              </template>
              <template #active="{ item }">
                <td>
                  <CBadge :color="getTableBadge(item.active)">
                    {{ item.active ? "Aktiv" : "Deaktiv" }}
                  </CBadge>
                </td>
              </template>
              <template #updated="{ item }">
                <td>
                  {{ $moment(item.updated).format($commons.DATE_TIME_FORMAT) }}
                </td>
              </template>
              <template #show_details="{ item, index }">
                <td class="py-2">
                  <CButton
                    :to="'leadGenerator/edit/' + item.id"
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                  >
                    Bearbeiten
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Hilfe</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <p>
              Hier können Sie neue Funnels erstellen und bestehende Funnels
              bearbeiten.
            </p>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DoubleBounceLoader from "@/components/loaders/DoubleBounceLoader";
import commons from "@/commons";
import axios from "axios";

export default {
  name: "LeadgeneratorList",
  components: {
    DoubleBounceLoader,
  },
  data() {
    return {
      loading: false,
      saving: false,
      tableData: [],
      tableShowLoading: true,
    };
  },
  mounted() {
    this.loading = true;
    this.loadLeadgenerator();
  },
  methods: {
    loadLeadgenerator() {
      axios
        .get("/leadgenerator")
        .then((response) => {
          let content = [];
          response.data.forEach((value) => {
            content.push(commons.flatObject(value));
          });
          this.tableData = content;
          this.loading = false;
        })
        .catch((err) =>
          console.error("Problem while fetching leadgenerators", err),
        )
        .finally(() => (this.tableShowLoading = false));
    },
    getTableFields() {
      return [
        { key: "name", label: "Name" },
        { key: "pages", label: "Schritte" },
        { key: "active", label: "Aktiv?" },
        { key: "updated", label: "Zuletzt aktualisiert" },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ];
    },
    getTableBadge(type) {
      return type ? "success" : "danger";
    },
  },
};
</script>

<style scoped></style>
