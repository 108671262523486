<template>
  <div class="d-flex justify-content-center align-items-center" style="min-height: 200px">
    <div class="text-center">
      <h2>LinkedIn Verbindung wird hergestellt...</h2>
      <div v-if="error" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LinkedInCallback',
  data() {
    return {
      error: false,
      errorMessage: ''
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (!code || !state) {
      this.error = true;
      this.errorMessage = 'Fehlende Parameter in der Callback-URL';
      return;
    }

    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) {
      this.error = true;
      this.errorMessage = 'Nicht authentifiziert';
      return;
    }

    // Sende direkt zum LinkedIn-Callback endpoint
    axios.get('/socialMedia/linkedin/callback', {
      params: {
        code,
        state,
        redirectUri: window.location.origin + '/linkedin-callback'
      },
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then(response => {
        this.$toaster.makeToast('Erfolg', 'LinkedIn wurde erfolgreich verbunden');
        this.$router.push('/socialMedia/connections');
      })
      .catch(error => {
        console.error('LinkedIn connection error:', error);
        this.error = true;
        this.errorMessage = error.response?.data?.message || 'Fehler bei der Verbindung mit LinkedIn';
      });
  }
}
</script>
