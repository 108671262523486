<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Neuen Abonnenten anlegen</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <NewsletterSubscriberForm
              @submit="createNewsletterSubscriber"
              :processing="processing"
              :enable-delete="false"
              btn-text="Speichern"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import NewsletterSubscriberForm from "@/views/base/newsletter/NewsletterSubscriberForm";

export default {
  name: "NewsletterSubscriberNew",
  components: { NewsletterSubscriberForm },
  data() {
    return {
      subscriber: null,
      processing: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.params.saved) {
      next();
      return;
    }
    this.$modal.showModal(
      "default",
      "Editor wirklich schließen?",
      [
        `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`,
      ],
      () => {
        next();
      },
      () => {
        next(false);
      },
      {
        btnCancel: "abbrechen",
        btnConfirm: "Trotzdem schließen",
        btnConfirmColor: "danger",
      },
    );
  },
  methods: {
    createNewsletterSubscriber(data) {
      this.processing = true;
      axios
        .post("/newsletter/subscriber", data)
        .then(() => {
          this.$router.push({
            name: "NewsletterSubscribers",
            params: { saved: "1" },
          });
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Abonnent wurde erfolgreich angelegt!</b>",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Error!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
