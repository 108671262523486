<template>
  <div class="propstack-integration">
    <CCard>
      <CCardHeader>
        <h4 class="mb-0">Propstack Integration</h4>
      </CCardHeader>
      <CCardBody>
        <!-- Integration Status & API Key -->
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center bg-light">
            <h5 class="mb-0">Verbindungseinstellungen</h5>
          </CCardHeader>
          <CCardBody>
            <div class="position-relative">
              <!-- API Key Input -->
              <CInput
                v-model="settings.apiKey"
                label="API-Key"
                placeholder="Propstack API-Key eingeben"
                :is-valid="isApiKeyValid"
                :is-invalid="isApiKeyInvalid"
                maxlength="40"
              >
                <template #prepend-content>
                  <CIcon name="cil-lock-locked"/>
                </template>
                <template #append-content>
                  <small class="text-muted">{{ settings.apiKey.length }}/40</small>
                </template>
                <template #description>
                  <small class="text-muted">
                    Den API-Key finden Sie in Ihren Propstack-Einstellungen unter "API & Integrationen"
                  </small>
                </template>
              </CInput>

              <!-- Test und Speichern Buttons -->
              <div class="d-flex gap-2 mt-3">
                <CButton
                  v-if="isApiKeyValid"
                  color="primary"
                  variant="outline"
                  size="sm"
                  :disabled="isLoading"
                  @click="testConnection"
                >
                  <CIcon name="cil-link" v-if="!isLoading"/>
                  <CSpinner size="sm" v-else/>
                  Verbindung testen
                </CButton>

                <CButton
                  color="success"
                  size="sm"
                  :disabled="!hasUnsavedChanges || isSaving"
                  @click="saveSettings"
                >
                  <CIcon name="cil-save" v-if="!isSaving"/>
                  <CSpinner size="sm" v-else/>
                  Einstellungen speichern
                </CButton>
              </div>
            </div>

            <!-- Erfolgreiche Verbindung und Aktivierung -->
            <div v-if="isApiKeyValid && propStackTestStatus.success" class="mt-3">
              <div class="alert" :class="settings.enabled ? 'alert-success' : 'alert-secondary'">
                <div class="connection-status">
                  <div class="connection-status-text">
                    <CIcon name="cil-link" v-if="!settings.enabled"/>
                    <CIcon name="cil-check-circle" v-if="settings.enabled"/>
                    <span>{{ settings.enabled ? 'Verbindung aktiv' : 'Verbindung verfügbar' }}</span>
                  </div>
                  <div class="connection-actions">
                    <CSwitch
                      :checked="settings.enabled"
                      @update:checked="toggleEnabled"
                      color="primary"
                      variant="3d"
                      size="lg"
                      :label-on="'An'"
                      :label-off="''"
                    />

                    <CButton
                      v-if="settings.enabled"
                      color="danger"
                      variant="outline"
                      size="sm"
                      @click="disconnectPropstack"
                    >
                      <CIcon name="cil-x-circle"/> Verbindung trennen
                    </CButton>
                  </div>
                </div>
              </div>
            </div>

            <!-- Error Message -->
            <div v-if="propStackTestStatus.error" class="mt-3">
              <CAlert color="danger">
                <CIcon name="cil-x-circle"/> {{ propStackTestStatus.error }}
              </CAlert>
            </div>
          </CCardBody>
        </CCard>

        <!-- Lead Sync Configuration -->
        <CCard v-if="settings.enabled && isApiKeyValid" class="mt-4">
          <CCardHeader class="bg-light">
            <h5 class="mb-0">Lead-Synchronisation</h5>
          </CCardHeader>
          <CCardBody>
            <div class="sync-config">
              <div class="mb-4">
                <label class="form-label">Leads automatisch synchronisieren</label>
                <p class="text-muted mb-3">
                  Wählen Sie aus, in welcher Phase Ihre Leads automatisch als Kontakte in Propstack angelegt werden sollen.
                </p>
                <CSelect
                  v-model="settings.leadSyncPhase"
                  :options="leadPhaseOptions"
                  :placeholder="'Phase für Propstack-Synchronisation auswählen'"
                  @change="handleSyncPhaseChange"
                />
                <small class="text-muted mt-2 d-block">
                  <CIcon name="cil-info"/> Die Synchronisation erfolgt automatisch, sobald ein Lead in die ausgewählte Phase
                  verschoben wird.
                </small>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <!-- Gruppen-Synchronisation Card -->
        <CCard v-if="settings.enabled && isApiKeyValid" class="mt-4">
          <CCardHeader class="bg-light">
            <h5 class="mb-0">Gruppen-Synchronisation</h5>
          </CCardHeader>
          <CCardBody>
            <div class="sync-config">
              <!-- Auto-Sync Switch - neu positioniert und besser gestyled -->
              <div class="auto-sync-container mb-4">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <label class="form-label mb-0">Automatische Synchronisation</label>
                    <small class="text-muted d-block">
                      {{ settings.autoSyncContacts ?
                      'Kontakte werden automatisch mit aktivierten Gruppen synchronisiert' :
                      'Kontakte müssen manuell synchronisiert werden' }}
                    </small>
                  </div>
                  <CSwitch
                    v-model="settings.autoSyncContacts"
                    color="primary"
                    variant="3d"
                    size="lg"
                    :checked="settings.autoSyncContacts"
                    @update:checked="handleAutoSyncChange"
                  />
                </div>
              </div>

              <!-- Propstack-Gruppen -->
              <div v-if="contactGroups.length > 0" class="mt-4">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <label class="form-label mb-0">Propstack-Gruppen</label>
                  <CButton
                    color="secondary"
                    size="sm"
                    variant="outline"
                    @click="toggleSelectAll"
                  >
                    {{ areAllGroupsSelected ? 'Alle deaktivieren' : 'Alle aktivieren' }}
                  </CButton>
                </div>

                <div class="group-list">
                  <CCard v-for="group in contactGroups"
                         :key="group.id"
                         class="mb-2 p-3 group-card"
                         :class="{
                           'group-card-enabled': group.enabled,
                           'group-card-selected': selectedGroup?.id === group.id
                         }"
                         @click="selectGroup(group)">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <CSwitch
                          :checked="group.enabled"
                          @update:checked="value => toggleGroupSync(group.id, value, $event)"
                          color="primary"
                          variant="3d"
                          size="md"
                          class="mr-3"
                          @click.stop
                        />
                        <strong class="group-name">{{ group.name }}</strong>
                        <small class="text-muted ml-2">({{ group.count }} Kontakte)</small>
                      </div>
                      <div class="sync-status" v-if="group.enabled">
                        <small class="text-muted">
                          {{ group.lastSync ? `Letzte Sync: ${formatDate(group.lastSync)}` : 'Noch nicht synchronisiert' }}
                        </small>
                      </div>
                    </div>

                    <!-- Details Panel -->
                    <div v-if="selectedGroup?.id === group.id" class="group-details mt-3 pt-3 border-top">
                      <div class="group-actions mb-3">
                        <CButton
                          color="primary"
                          size="sm"
                          variant="outline"
                          class="mr-2"
                          :disabled="!group.enabled || isSyncing"
                          @click.stop="syncGroup(group.id)"
                        >
                          <CIcon name="cil-sync" v-if="!isSyncing"/>
                          <CSpinner size="sm" v-else/>
                          {{ isSyncing ? 'Synchronisiere...' : 'Jetzt synchronisieren' }}
                        </CButton>
                        <CButton
                          color="secondary"
                          size="sm"
                          variant="outline"
                          @click.stop="showGroupMembers(group.id, group.name)"
                        >
                          <CIcon name="cil-people"/> Kontakte anzeigen
                        </CButton>
                      </div>
                    </div>
                  </CCard>
                </div>

                <!-- Sync alle aktivierten Gruppen -->
                <div v-if="hasEnabledGroups" class="mt-4">
                  <CButton
                    color="primary"
                    :disabled="isSyncing"
                    @click="syncEnabledGroups"
                  >
                    <CIcon name="cil-sync" v-if="!isSyncing"/>
                    <CSpinner size="sm" v-else/>
                    Alle aktivierten Gruppen synchronisieren
                  </CButton>
                </div>

                <!-- Manueller Sync Button -->
                <CButton
                  v-if="!settings.autoSyncContacts"
                  color="primary"
                  variant="outline"
                  class="mt-4"
                  @click="syncContacts"
                  :disabled="isSyncing"
                >
                  <CIcon name="cil-sync" v-if="!isSyncing"/>
                  <CSpinner size="sm" v-else/>
                  Kontakte jetzt synchronisieren
                </CButton>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <!-- DSGVO Info -->
        <CCard class="mt-4 bg-light border-0">
          <CCardBody>
            <div class="d-flex">
              <CIcon name="cil-shield-alt" class="mr-3 text-info h4 mb-0"/>
              <div>
                <h6 class="text-info mb-2">DSGVO-Hinweis</h6>
                <p class="mb-0 text-muted">
                  Die Synchronisation erfolgt unter Berücksichtigung der DSGVO. Es werden nur Kontakte übertragen,
                  die einer Verarbeitung ihrer Daten zugestimmt haben.
                </p>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCardBody>
    </CCard>

    <!-- Bestätigungsdialog für Import/Export -->
    <CModal
      :show.sync="showConfirmDialog"
      title="Bestätigung erforderlich"
      color="primary"
    >
      <div v-html="confirmDialogMessage"></div>
      <template #footer>
        <CButton
          color="secondary"
          @click="showConfirmDialog = false"
        >
          Abbrechen
        </CButton>
        <CButton
          color="primary"
          @click="confirmAction"
        >
          Bestätigen
        </CButton>
      </template>
    </CModal>
    <!-- Modal für Gruppenmitglieder -->

    <!-- Modal für Gruppenmitglieder -->
    <CModal
      :show.sync="showMembersDialog"
      :title="`Kontakte in Gruppe '${selectedGroupName}'`"
      size="lg"
      class="members-modal"
    >
      <CDataTable
        :items="groupMembers"
        :fields="[
    {
      key: 'name',
      label: 'Name',
      _style: 'min-width:200px;',
      _classes: 'font-weight-bold'
    },
    {
      key: 'email',
      label: 'E-Mail',
      _style: 'min-width:250px;'
    },
    {
      key: 'phone',
      label: 'Telefon',
      _style: 'min-width:150px;'
    },
    {
      key: 'lastSync',
      label: 'Letzte Sync',
      _style: 'min-width:180px;'
    }
  ]"
        :items-per-page="membersPagination.itemsPerPage"
        :pagination="membersPagination"
        hover
        responsive
        sorting
        :table-filter="{
    label: 'Filter:',
    placeholder: 'Kontakte Filtern...'
  }"
        :noItemsView="{
    noResults: 'Keine Kontakte gefunden',
    noItems: 'Keine Kontakte vorhanden'
  }"
        class="mb-0"
      >
        <template #name="{ item }">
          <td>
            {{ item.firstName }} {{ item.lastName }}
          </td>
        </template>

        <template #phone="{ item }">
          <td>
            {{ item.phone || item.mobile || '-' }}
          </td>
        </template>

        <template #lastSync="{ item }">
          <td>
            {{ item.lastSync ? formatDate(item.lastSync) : 'Noch nicht synchronisiert' }}
          </td>
        </template>
      </CDataTable>

      <template #footer>
        <CButton
          color="secondary"
          @click="showMembersDialog = false"
        >
          Schließen
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import { CSwitch } from '@coreui/vue'

// API Client Konfiguration
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL.replace('/backend', '')
});

// Request Interceptor für JWT Token
apiClient.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem('jwtToken');
  if (jwtToken) {
    // Token von Anführungszeichen bereinigen
    const cleanToken = jwtToken.replace(/^"(.*)"$/, '$1');
    config.headers.Authorization = `Bearer ${cleanToken}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default {
  name: 'PropstackIntegration',

  components: {
    CSwitch,
  },

  data() {
    return {
      groupMembers: [],
      showMembersDialog: false,
      selectedGroupName: '',
      membersPagination: {
        totalItems: 0,
        currentPage: 0,
        itemsPerPage: 20,
        totalPages: 0
      },
      isLoading: false,
      isSaving: false,
      isLoadingTypes: false,
      isSyncing: false,
      syncingGroupId: null,
      isImporting: false,
      isExporting: false,
      hasUnsavedChanges: false,
      settings: {
        apiKey: '',
        enabled: false,
        leadSyncPhase: '',
        autoSyncContacts: false
      },
      importSettings: {
        mode: 'all'
      },
      importStatus: null,
      selectedGroup: null,
      contactGroups: [],
      contactTypes: [],
      contactCount: 0,
      showConfirmDialog: false,
      confirmDialogMessage: '',
      pendingAction: null,
      leadPhaseOptions: [
        { value: '', label: 'Keine Synchronisation' },
        { value: 'Neu', label: 'Neu' },
        { value: 'In Bearbeitung', label: 'In Bearbeitung' },
        { value: 'Qualifiziert', label: 'Qualifiziert' },
        { value: 'Abgeschlossen', label: 'Abgeschlossen' }
      ],
      propStackTestStatus: {
        loading: false,
        error: null,
        success: false
      }
    }
  },

  computed: {
    hasEnabledGroups() {
      return this.contactGroups.some(group => group.enabled);
    },

    areAllGroupsSelected() {
      return this.contactGroups.length > 0 &&
        this.contactGroups.every(group => group.enabled);
    },

    isApiKeyValid() {
      // Prüfen ob apiKey null oder undefined ist
      return this.settings.apiKey != null && typeof this.settings.apiKey === 'string' && this.settings.apiKey.length === 40
    },
    isApiKeyInvalid() {
      // Prüfen ob apiKey existiert und eine Länge hat
      return this.settings.apiKey != null && this.settings.apiKey.length > 0 && !this.isApiKeyValid
    },
    currentRealmId() {
      return this.$commons.getFromStorage('currentRealm').id
    }
  },

  watch: {
    'settings.apiKey': {
      immediate: true,
      handler(newValue) {
        // Wenn der API-Key gültig ist, setzen wir den Success-Status
        if (this.isApiKeyValid) {
          this.propStackTestStatus.success = true;
        } else {
          this.propStackTestStatus.success = false;
        }
      }
    },
    'settings.leadSyncPhase'() {
      this.hasUnsavedChanges = true;
    },
    'settings.enabled': {
      immediate: true,
      async handler(newValue) {
        if (newValue && this.isApiKeyValid) {
          await this.loadContactGroups();
        }
      }
    }
  },

  methods: {
    selectGroup(group) {
      if (this.selectedGroup?.id === group.id) {
        this.selectedGroup = null; // Schließen wenn bereits ausgewählt
      } else {
        this.selectedGroup = group; // Neue Gruppe auswählen
      }
    },
    async syncGroup(groupId) {
      this.syncingGroupId = groupId;

      try {
        console.log('Making API call for group:', groupId);
        console.log('API URL:', `/api/v1/propstack/sync/group/${this.currentRealmId}/${groupId}/sync`);

        const response = await apiClient.post(
            `/api/v1/propstack/sync/group/${this.currentRealmId}/${groupId}/sync`
        );

        console.log('API response:', response);

        this.$toaster.makeToast(
            "Erfolg",
            "Gruppe wurde erfolgreich synchronisiert"
        );

        await this.loadContactGroups();
      } catch (error) {
        console.error('Error syncing group:', error);
        this.$toaster.makeToast(
            "Fehler",
            "Gruppe konnte nicht synchronisiert werden"
        );
      } finally {
        this.syncingGroupId = null;
        // Entferne das Setzen von isSyncing hier, da es in syncEnabledGroups gesetzt wird
      }
    },

    async toggleSelectAll() {
      const newState = !this.areAllGroupsSelected;

      for (const group of this.contactGroups) {
        if (group.enabled !== newState) {
          await this.toggleGroupSync(group.id, newState);
        }
      }
    },

    async syncEnabledGroups() {
      if (this.isSyncing) return;

      this.isSyncing = true;
      try {
        const enabledGroups = this.contactGroups.filter(group => group.enabled);
        console.log('Enabled groups to sync:', enabledGroups); // Logging

        for (const group of enabledGroups) {
          console.log('Attempting to sync group:', group.id); // Logging
          await this.syncGroup(group.id);
        }

        this.$toaster.makeToast(
            "Erfolg",
            "Alle aktivierten Gruppen wurden synchronisiert"
        );
      } catch (error) {
        console.error('Error syncing groups:', error);
        this.$toaster.makeToast(
            "Fehler",
            "Fehler bei der Synchronisation der Gruppen"
        );
      } finally {
        this.isSyncing = false;
      }
    },

    async showGroupMembers(groupId, groupName) {
      try {
        console.log('Loading members for group:', groupId); // Debug log
        const response = await apiClient.get(
          `/api/v1/propstack/group/${this.currentRealmId}/${groupId}/members`
        );

        this.groupMembers = response.data.members;
        this.membersPagination = response.data.pagination;
        this.selectedGroupName = groupName;
        this.showMembersDialog = true;
      } catch (error) {
        console.error('Error loading group members:', error);
        this.$toaster.makeToast(
          "Fehler",
          "Kontakte konnten nicht geladen werden"
        );
      }
    },

    formatDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleString('de-DE', {
        dateStyle: 'medium',
        timeStyle: 'short'
      });
    },
    async loadContactGroups() {
      try {
        console.log('Loading contact groups...'); // Debug Log
        const response = await apiClient.get(
          `/api/v1/propstack/contact-groups/${this.currentRealmId}`
        );
        console.log('Contact groups response:', response.data); // Debug Log
        this.contactGroups = response.data || [];
      } catch (error) {
        console.error('Error loading contact groups:', error);
        this.$toaster.makeToast(
          "Fehler",
          "Propstack-Gruppen konnten nicht geladen werden"
        );
      }
    },
    async toggleGroupSync(groupId, enabled, event) {
      event?.stopPropagation();
      try {
        await apiClient.post(
          `/api/v1/propstack/sync/group/${this.currentRealmId}`,
          null,
          {
            params: {
              groupId: groupId,
              enabled: enabled
            }
          }
        );

        // Aktualisiere den Status in der lokalen Liste
        const group = this.contactGroups.find(g => g.id === groupId);
        if (group) {
          group.enabled = enabled;
        }

        this.$toaster.makeToast(
          "Erfolg",
          enabled ? "Gruppe zur Synchronisation aktiviert" : "Gruppe von Synchronisation deaktiviert"
        );

        // Gruppen neu laden für aktuellen Status
        await this.loadContactGroups();
      } catch (error) {
        console.error('Error toggling group sync:', error);
        this.$toaster.makeToast(
          "Fehler",
          "Gruppensynchronisation konnte nicht geändert werden"
        );
      }
    },
    async handleAutoSyncChange(value) {
      try {
        await apiClient.post(
          `/api/v1/propstack/settings/${this.currentRealmId}/auto-sync`,
          null,
          { params: { enabled: value } }
        );

        this.settings.autoSyncContacts = value;
        this.$toaster.makeToast(
          "Erfolg",
          value ? "Automatische Synchronisation aktiviert" : "Automatische Synchronisation deaktiviert"
        );
      } catch (error) {
        console.error('Error changing auto-sync:', error);
        this.$toaster.makeToast(
          "Fehler",
          "Automatische Synchronisation konnte nicht geändert werden"
        );
        // Reset switch state
        this.settings.autoSyncContacts = !value;
      }
    },

    // Manuelle Synchronisation
    async syncContacts() {
      this.isSyncing = true;
      try {
        const response = await apiClient.post(
          `/api/v1/propstack/sync/contacts/${this.currentRealmId}`,
          { fullSync: true }
        );

        this.$toaster.makeToast(
          "Erfolg",
          "Kontakte wurden erfolgreich synchronisiert"
        );

        // Gruppen neu laden
        await this.loadContactGroups();
      } catch (error) {
        console.error('Error syncing contacts:', error);
        this.$toaster.makeToast(
          "Fehler",
          "Kontakte konnten nicht synchronisiert werden"
        );
      } finally {
        this.isSyncing = false;
      }
    },

    async updateApiKey(value) {
      this.settings.apiKey = value;
      this.hasUnsavedChanges = true;
    },

    async toggleEnabled(value) {
      this.isLoading = true;
      try {
        // API-Call zum Ändern des Status
        await apiClient.post(`/api/v1/propstack/settings/${this.currentRealmId}`, null, {
          params: {
            apiKey: this.settings.apiKey,
            enabled: value,
            leadSyncPhase: this.settings.leadSyncPhase || '',
            autoSyncContacts: this.settings.autoSyncContacts || false
          }
        });

        // Status aktualisieren
        this.settings.enabled = value;

        // Wichtig: Behalte den Success-Status bei
        this.propStackTestStatus.success = true;

        this.$toaster.makeToast(
          "Erfolg",
          value ? "Propstack-Integration aktiviert" : "Propstack-Integration deaktiviert"
        );

        // Wenn aktiviert, Gruppen neu laden
        if (value && this.isApiKeyValid) {
          await this.loadContactGroups();
        }
      } catch (error) {
        console.error('Error toggling enabled state:', error);
        this.$toaster.makeToast(
          "Fehler",
          "Status konnte nicht geändert werden"
        );
        // Status zurücksetzen
        this.settings.enabled = !value;
      } finally {
        this.isLoading = false;
      }
    },

    async disconnectPropstack() {
      this.confirmDialogMessage = `    <p>Möchten Sie wirklich die Verbindung zu Propstack trennen?</p>
    <p class="text-muted">Dies wird alle Einstellungen zurücksetzen und die Synchronisation stoppen.</p>
  `;
      this.showConfirmDialog = true;
      this.pendingAction = async () => {
        try {
          await apiClient.delete(`/api/v1/propstack/settings/${this.currentRealmId}`);

          // Alle Einstellungen zurücksetzen
          this.settings = {
            apiKey: '',
            enabled: false,
            leadSyncPhase: '',
            autoSyncContacts: false
          };

          // Gruppen leeren
          this.contactGroups = [];

          // Reset des Verbindungsstatus
          this.propStackTestStatus = {
            loading: false,
            error: null,
            success: false
          };

          this.$toaster.makeToast(
            "Erfolg",
            "Propstack-Verbindung wurde erfolgreich getrennt"
          );
        } catch (error) {
          console.error('Error disconnecting Propstack:', error);
          this.$toaster.makeToast(
            "Fehler",
            "Verbindung konnte nicht getrennt werden"
          );
        }
      };
    },

    async handleSyncPhaseChange(event) {
      const value = event?.target?.value || event;
      this.settings.leadSyncPhase = value;
      this.hasUnsavedChanges = true;
    },

    async testConnection() {
      if (!this.isApiKeyValid) {
        return;
      }

      this.propStackTestStatus.loading = true;
      this.propStackTestStatus.error = null;
      this.propStackTestStatus.success = false;

      try {
        // Erst die Propstack-Einstellungen speichern
        const params = new URLSearchParams({
          apiKey: this.settings.apiKey,
          enabled: 'true',
          leadSyncPhase: this.settings.leadSyncPhase || '',
          autoSyncContacts: 'false'
        });

        await apiClient.post(`/api/v1/propstack/settings/${this.currentRealmId}?${params.toString()}`);

        // Kurze Verzögerung einfügen
        await new Promise(resolve => setTimeout(resolve, 500));

        // Dann Verbindung testen
        const response = await apiClient.get(`/api/v1/propstack/test-connection/${this.currentRealmId}`);

        // Die Antwort validieren
        if (response.data && response.data.success === true) {
          this.propStackTestStatus = {
            loading: false,
            error: null,
            success: true
          };

          this.$toaster.makeToast(
            "Erfolg",
            "Die Verbindung zu Propstack wurde erfolgreich getestet."
          );

          // Nach erfolgreicher Verbindung aktuelle Einstellungen neu laden
          const settingsResponse = await apiClient.get(`/api/v1/propstack/settings/${this.currentRealmId}`);
          this.settings = {
            apiKey: settingsResponse.data?.apiKey || '',
            enabled: Boolean(settingsResponse.data?.enabled),
            leadSyncPhase: settingsResponse.data?.leadSyncPhase || '',
            autoSyncContacts: Boolean(settingsResponse.data?.autoSyncContacts)
          };

          await this.loadContactGroups();

          // Broker-Informationen speichern, falls vorhanden
          if (response.data.brokers && Array.isArray(response.data.brokers)) {
            this.brokerInfo = response.data.brokers;
          }
        } else {
          throw new Error(response.data?.message || "Verbindungstest fehlgeschlagen");
        }
      } catch (err) {
        console.error('Connection test error:', err);
        this.propStackTestStatus.success = false;
        this.propStackTestStatus.error = err.response?.data?.message || err.message;
        this.$toaster.makeToast(
          "Fehler",
          `Propstack-Verbindungstest fehlgeschlagen: ${this.propStackTestStatus.error}`
        );
      } finally {
        this.propStackTestStatus.loading = false;
      }
    },

    async saveSettings() {
      this.isSaving = true;
      try {
        // Einstellungen als Query-Parameter senden
        const response = await apiClient.post(`/api/v1/propstack/settings/${this.currentRealmId}`, null, {
          params: {
            apiKey: this.settings.apiKey,
            enabled: this.settings.enabled,
            leadSyncPhase: this.settings.leadSyncPhase || '',
            autoSyncContacts: this.settings.autoSyncContacts || false
          }
        });

        if (response.status === 200) {
          this.hasUnsavedChanges = false;
          this.$toaster.makeToast("Erfolg", "Einstellungen wurden gespeichert");
        } else {
          throw new Error("Fehler beim Speichern der Einstellungen");
        }
      } catch (error) {
        let errorMessage = "Einstellungen konnten nicht gespeichert werden";
        if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        }
        this.$toaster.makeToast("Fehler", errorMessage);
      } finally {
        this.isSaving = false;
      }
    },

    async loadContactCount() {
      try {
        const response = await apiClient.get(`/api/v1/propstack/contact-count/${this.currentRealmId}`);
        this.contactCount = response.data.count;
      } catch (error) {
        console.error('Fehler beim Laden der Kontaktanzahl:', error);
      }
    },

    async startImport() {
      this.confirmDialogMessage = `
        <p>Möchten Sie wirklich die Kontakte aus Propstack importieren?</p>
      `;
      this.showConfirmDialog = true;
      this.pendingAction = this.performImport;
    },

    async performImport() {
      this.isImporting = true;
      try {
        await apiClient.post(`/api/v1/propstack/sync/contacts/${this.currentRealmId}`, {
          fullSync: true
        });
        this.$toaster.makeToast(
          "Erfolg",
          "Import wurde gestartet. Sie erhalten eine E-Mail nach Abschluss."
        );
      } catch (error) {
        this.$toaster.makeToast(
          "Fehler",
          "Import konnte nicht gestartet werden"
        );
      } finally {
        this.isImporting = false;
      }
    },

    async startExport() {
      this.confirmDialogMessage = `
        <p>Möchten Sie wirklich Ihre Kontakte nach Propstack exportieren?</p>
      `;
      this.showConfirmDialog = true;
      this.pendingAction = this.performExport;
    },

    async performExport() {
      this.isExporting = true;
      try {
        await apiClient.post(`/api/v1/propstack/export/${this.currentRealmId}`);
        this.$toaster.makeToast(
          "Erfolg",
          "Export wurde gestartet. Sie erhalten eine E-Mail nach Abschluss."
        );
      } catch (error) {
        this.$toaster.makeToast("Fehler", "Export konnte nicht gestartet werden");
      } finally {
        this.isExporting = false;
      }
    },

    confirmAction() {
      this.showConfirmDialog = false;
      if (this.pendingAction) {
        this.pendingAction();
        this.pendingAction = null;
      }
    }
  },

  async mounted() {
    try {
      const response = await apiClient.get(`/api/v1/propstack/settings/${this.currentRealmId}`);

      this.settings = {
        apiKey: response.data?.apiKey || '',
        enabled: Boolean(response.data?.enabled),
        leadSyncPhase: response.data?.leadSyncPhase || '',
        autoSyncContacts: Boolean(response.data?.autoSyncContacts)
      };

      // Wichtig: Wenn ein gültiger API-Key existiert, setzen wir den Success-Status
      if (this.isApiKeyValid) {
        this.propStackTestStatus.success = true;
      }

      // Wenn es eine aktive Verbindung gibt und der API-Key gültig ist, laden wir die Gruppen
      if (this.settings.enabled && this.isApiKeyValid) {
        await this.loadContactGroups();
      }
    } catch (error) {
      console.error('Error loading settings:', error);
      this.$toaster.makeToast(
        "Fehler",
        "Fehler beim Laden der Propstack-Einstellungen"
      );
    }
  }
}
</script>

<style scoped>
.propstack-integration {
  max-width: 800px;
  margin: 0 auto;
}

/* Card Styles */
.card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
  background-color: #f8f9fa;
}

.card-body {
  padding: 1.5rem;
}

/* Connection Status */
.alert {
  border-radius: 8px;
  margin: 1rem 0;
  padding: 1rem;
  transition: all 0.3s ease;
}

.alert-success {
  background-color: rgba(46, 184, 92, 0.1);
  border: 1px solid rgba(46, 184, 92, 0.2);
  border-left: 4px solid #2eb85c;
}

.alert-secondary {
  background-color: rgba(108, 117, 125, 0.1);
  border: 1px solid rgba(108, 117, 125, 0.2);
  border-left: 4px solid #6c757d;
}

.connection-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.connection-status-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.connection-status-text .c-icon {
  transition: all 0.3s ease;
}

.connection-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Group Card Styles */
.group-card {
  border: 1px solid #eee;
  transition: all 0.3s ease;
  border-radius: 8px;
  margin-bottom: 0.75rem;
}

.group-card-enabled {
  border-left: 4px solid #2eb85c;
}

.group-card:hover {
  border-color: #321fdb;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.group-card-selected {
  border-color: #321fdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.group-details {
  animation: fadeIn 0.3s ease;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #eee;
}

.group-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.group-name {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

/* Switch Styles */
.c-switch {
  margin: 0;
}

.c-switch-primary .c-switch-input:checked + .c-switch-slider {
  background-color: #321fdb !important;
  border-color: #321fdb !important;
}

/* Button Styles */
.btn {
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #321fdb;
  border-color: #321fdb;
}

.btn-primary:hover {
  background-color: #2819b9;
  border-color: #2819b9;
}

.btn-outline-primary {
  color: #321fdb;
  border-color: #321fdb;
}

.btn-outline-primary:hover {
  background-color: #321fdb;
  color: white;
}

.btn-danger {
  background-color: #e55353;
  border-color: #e55353;
}

.btn-outline-danger {
  color: #e55353;
  border-color: #e55353;
}

.btn-outline-danger:hover {
  background-color: #e55353;
  color: white;
}

/* Form Styles */
.form-label {
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.form-text {
  color: #6c757d;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Utility Classes */
.gap-2 {
  gap: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.sync-status {
  min-width: 200px;
  text-align: right;
  color: #6c757d;
  font-size: 0.875rem;
}

/* Status Badge */
.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge-success {
  background-color: rgba(46, 184, 92, 0.1);
  color: #2eb85c;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .connection-status {
    flex-direction: column;
    align-items: flex-start;
  }

  .connection-actions {
    width: 100%;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .group-actions {
    flex-direction: column;
    width: 100%;
  }

  .group-actions .btn {
    width: 100%;
  }
}

/* DSGVO Info Card */
.info-card {
  background-color: #f8f9fa;
  border: none;
  border-radius: 8px;
}

.info-card .card-body {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.info-icon {
  color: #321fdb;
  font-size: 1.5rem;
}

.info-content h6 {
  color: #321fdb;
  margin-bottom: 0.5rem;
}

.info-content p {
  color: #6c757d;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Member List Styles */
.members-list {
  margin: -1rem;
  padding: 1rem;
  max-height: 60vh;
  overflow-y: auto;
}

.members-list .table {
  margin-bottom: 0;
  width: 100%;
  border-collapse: collapse;
}

.members-list th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  padding: 1rem;
  text-align: left;
  border-bottom: 2px solid #e9ecef;
  font-weight: 600;
  color: #495057;
}

.members-list td {
  vertical-align: middle;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e9ecef;
  color: #495057;
}

.members-list tr:hover {
  background-color: #f8f9fa;
}

/* Modal Styles */
.modal-content {
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
}

.modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  padding: 1rem 1.5rem;
}

.modal-title {
  font-weight: 600;
  color: #333;
  margin: 0;
}

.modal-body {
  padding: 0;
}

.modal-footer {
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  padding: 1rem 1.5rem;
}

/* DataTable Customization */
.c-data-table {
  border: none;
}

.c-data-table thead th {
  background-color: #f8f9fa;
  font-weight: 600;
  padding: 1rem;
  border-bottom: 2px solid #e9ecef;
}

.c-data-table tbody td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e9ecef;
}

/* Pagination Styles */
.c-data-table-pagination {
  padding: 1rem;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}

/* CoreUI Modal Styles */
.members-modal {
  .modal-content {
    border: none;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  }

  .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem 1.5rem;
  }

  .modal-body {
    padding: 0;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .modal-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    padding: 1rem 1.5rem;
  }

  /* CoreUI 3 DataTable Anpassungen */
  .c-datatable-filter {
    padding: 1rem 1rem 0.5rem;
    background: #fff;
  }

  .table {
    margin: 0;

    thead th {
      position: sticky;
      top: 0;
      background: #f8f9fa;
      border-bottom: 2px solid #e9ecef;
      padding: 0.75rem 1rem;
      font-weight: 600;
      color: #4f5d73;
      white-space: nowrap;
      z-index: 1;
    }

    tbody td {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid #e9ecef;
      vertical-align: middle;
    }

    tbody tr:hover {
      background-color: rgba(0, 0, 21, 0.03);
    }
  }

  /* Pagination Anpassungen */
  .c-datatable-items-per-page,
  .c-datatable-pagination {
    padding: 0.75rem 1rem;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
  }
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .members-modal {
    .modal-body {
      max-height: calc(100vh - 150px);
    }

    .table {
      thead th,
      tbody td {
        padding: 0.5rem;
      }
    }

    .c-datatable-filter {
      padding: 0.75rem 0.75rem 0.5rem;
    }
  }
}

/* Scrollbar Styling */
.members-modal .modal-body {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
}
</style>
