<template>
  <div class="modern-lead-container">
    <CSpinner
      v-if="!ready"
      color="primary"
      style="display: block; margin: 20px auto"
    />
    <template v-else-if="lead">
      <!-- Header Section -->
      <CCard class="mb-4 header-card">
        <CCardBody>
          <div class="d-flex justify-content-between align-items-center">
            <div class="lead-title">
              <h1 class="mb-0">
                {{ lead.holder.firstName }} {{ lead.holder.lastName }}
                <CBadge :color="getBadgeColor" class="ml-2">{{ getBadgeText }}</CBadge>
              </h1>
              <p class="text-muted mb-0">Lead #{{ lead.id }}</p>
            </div>
            <div class="action-buttons">
              <template v-if="lead.optInRequest">
                <!-- Opt-Out Button (existierend) -->
                <CButton
                  v-if="!lead.optInRequest.cancelled"
                  color="danger"
                  variant="outline"
                  class="mr-2"
                  @click="manualOptOut"
                >
                  <CIcon name="cil-ban" class="mr-1" /> Opt-Out
                </CButton>

                <!-- Neuer Re-Opt-In Button -->
                <CButton
                  v-if="lead.optInRequest.cancelled"
                  color="success"
                  variant="outline"
                  class="mr-2"
                  @click="initiateReOptIn"
                  :disabled="isReOptInLoading"
                >
                  <CSpinner
                    v-if="isReOptInLoading"
                    size="sm"
                    class="mr-2"
                  />
                  <CIcon
                    v-else
                    name="cil-reload"
                    class="mr-1"
                  />
                  {{ isReOptInLoading ? 'Wird verarbeitet...' : 'Re-Opt-In' }}
                </CButton>
              </template>

              <!-- Speichern Button im Edit-Modus -->
              <CButton
                  v-if="editMode"
                  color="success"
                  class="mr-2"
                  @click="saveLead"
              >
                <CIcon name="cil-save" class="mr-1" />
                Speichern
              </CButton>

              <CButton
                  color="primary"
                  @click="toggleEditMode"
              >
                <CIcon :name="editMode ? 'cil-x' : 'cil-pencil'" class="mr-1" />
                {{ editMode ? "Abbrechen" : "Bearbeiten" }}
              </CButton>
            </div>
          </div>
        </CCardBody>
      </CCard>

    <!-- Quick Info Cards -->
    <div class="quick-info-grid mb-4">
      <CCard>
        <CCardBody>
          <div class="d-flex align-items-center">
            <div class="quick-info-icon">
              <CIcon name="cil-user" />
            </div>
            <div>
              <h6 class="mb-1">Kontakt</h6>
              <p class="mb-0">{{ lead.holder.email }}</p>
            </div>
          </div>
        </CCardBody>
      </CCard>

      <CCard>
        <CCardBody>
          <div class="d-flex align-items-center">
            <div class="quick-info-icon">
              <CIcon name="cil-home" />
            </div>
            <div>
              <h6 class="mb-1">Objekttyp</h6>
              <p class="mb-0">{{ translateObjectType(lead.type) }}</p>
            </div>
          </div>
        </CCardBody>
      </CCard>

      <CCard>
        <CCardBody>
          <div class="d-flex align-items-center">
            <div class="quick-info-icon">
              <CIcon name="cil-euro" />
            </div>
            <div>
              <h6 class="mb-1">Geschätzter Wert</h6>
              <p class="mb-0">{{ formatCurrency(lead.results?.resultAbsolute) }}</p>
            </div>
          </div>
        </CCardBody>
      </CCard>

      <CCard v-if="lead.optInRequest">
        <CCardBody>
          <div class="d-flex align-items-center">
            <div class="quick-info-icon">
              <CIcon name="cilEnvelopeClosed" />
            </div>
            <div>
              <h6 class="mb-1">Newsletter Status</h6>
              <p class="mb-0">
                {{ getBadgeText }}
                <small v-if="lead.optInRequest.cancelled" class="d-block text-muted">
                  Abgemeldet am: {{ formatDate(lead.optInRequest.cancelled) }}
                </small>
                <small v-if="lead.optInRequest.confirmedAt" class="d-block text-muted">
                  Bestätigt am: {{ formatDate(lead.optInRequest.confirmedAt) }}
                </small>
              </p>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <!-- Main Content -->
    <CCard>
      <CCardBody class="p-0">
        <CTabs
          variant="pills"
          class="modern-tabs"
          :active-tab="activeTab"
          @update:activeTab="handleTabChange"
        >
          <!-- Tab 0: Kontakt -->
          <CTab title="0">
            <template #title>
              <div class="d-flex align-items-center">
                <CIcon name="cil-user" class="mr-2" />
                Kontakt
              </div>
            </template>
            <CCardBody>
              <div v-if="!editMode">
                <div class="contact-info-grid">
                  <div class="info-group">
                    <label>Name</label>
                    <p>{{ lead.holder.firstName }} {{ lead.holder.lastName }}</p>
                  </div>
                  <div class="info-group">
                    <label>E-Mail</label>
                    <p>{{ lead.holder.email }}</p>
                  </div>
                  <div class="info-group">
                    <label>Telefon</label>
                    <p>{{ lead.holder.phoneNumber }}</p>
                  </div>
                </div>
              </div>
              <LeadForm
                  v-else
                  :passed-data="lead"
                  @submit="saveLead"
              />
            </CCardBody>
          </CTab>

          <!-- Tab 1: Objekt -->
          <CTab title="1">
            <template #title>
              <div class="d-flex align-items-center">
                <CIcon name="cil-home" class="mr-2" />
                Objekt
              </div>
            </template>
            <CCardBody>
              <div class="property-info-grid">
                <div class="info-card">
                  <h3>Basisdaten</h3>
                  <div class="info-content">
                    <div class="info-row">
                      <span class="label">Baujahr</span>
                      <span class="value">{{ lead.values?.constructionYear }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Zimmer</span>
                      <span class="value">{{ lead.values?.rooms }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Wohnfläche</span>
                      <span class="value">{{ lead.values?.livingArea }} m²</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Grundstücksfläche</span>
                      <span class="value">{{ lead.values?.siteArea }} m²</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Etage</span>
                      <span class="value">{{ lead.values?.floor || 'Nicht angegeben' }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Objektkategorie</span>
                      <span class="value">{{ translateObjectType(lead.type) }}</span>
                    </div>
                  </div>
                </div>

                <div class="info-card">
                  <h3>Verkaufsinformationen</h3>
                  <div class="info-content">
                    <div class="info-row">
                      <span class="label">Grund der Bewertung</span>
                      <span class="value">{{ lead.values?.reason || 'Nicht angegeben' }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Verkaufszeitwunsch</span>
                      <span class="value">{{ lead.values?.reasonWhen || 'Nicht angegeben' }}</span>
                    </div>
                  </div>
                </div>

                <!-- Nur für Grundstücke -->
                <div class="info-card" v-if="lead.type === 'LAND'">
                  <h3>Grundstücksdetails</h3>
                  <div class="info-content">
                    <div class="info-row">
                      <span class="label">Erschlossen</span>
                      <span class="value">{{ lead.values?.developedProperty || 'Nicht angegeben' }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Bebauung</span>
                      <span class="value">{{ lead.values?.constructionPossibilities || 'Nicht angegeben' }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Zuschnitt</span>
                      <span class="value">{{ lead.values?.siteLayout || 'Nicht angegeben' }}</span>
                    </div>
                  </div>
                </div>

                <div class="info-card">
                  <h3>Standort</h3>
                  <div class="info-content">
                    <div class="info-row">
                      <span class="label">Adresse</span>
                      <span class="value">{{ lead.values?.address?.formattedAddress }}</span>
                    </div>
                    <!-- Google Maps Karte -->
                    <div class="map-container mt-3">
                      <lead-map
                        v-if="lead.values?.address?.latitude && lead.values?.address?.longitude"
                        :markers="[{
                position: {
                  lat: lead.values.address.latitude,
                  lng: lead.values.address.longitude
                },
                title: lead.values.address.formattedAddress,
                name: `${lead.holder.firstName} ${lead.holder.lastName}`,
                email: lead.holder.email,
                phone: lead.holder.phoneNumber
              }]"
                        :center="{
                lat: lead.values.address.latitude,
                lng: lead.values.address.longitude
              }"
                        :zoom="14"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CCardBody>
          </CTab>

          <!-- Tab 2: E-Mail Aktivitäten -->
          <CTab title="2">
            <template #title>
              <div class="d-flex align-items-center">
                <CIcon name="cil-envelope-closed" class="mr-2" />
                E-Mail Aktivitäten
              </div>
            </template>
            <CCardBody>
              <EmailActivityList
                  :email-interactions="lead?.emailInteractions || []"
                  :activities="lead?.activities || []"
                  @page-change="handleEmailInteractionsPageChange"
              />
            </CCardBody>
          </CTab>

          <!-- Tab 3: Extra Schritte -->
          <CTab title="3">
            <template #title>
              <div class="d-flex align-items-center">
                <CIcon name="cil-list" class="mr-2" />
                Extra Schritte
              </div>
            </template>
            <CCardBody>
              <div v-if="lead.extraSteps && lead.extraSteps.length > 0">
                <div class="grid-extra-steps">
                  <div v-for="(step, index) in lead.extraSteps" :key="index" class="extra-step-item">
                    <label>{{ step.title }}</label>
                    <p>{{ step.answer }}</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <CAlert color="info">
                  Keine extra Schritte vorhanden.
                </CAlert>
              </div>
            </CCardBody>
          </CTab>

          <!-- Tab 4 Wertermittlung -->
          <CTab title="4">
            <template #title>
              <div class="d-flex align-items-center">
                <CIcon name="cil-euro" class="mr-2" />
                Wertermittlung
              </div>
            </template>
            <CCardBody>
              <div class="property-info-grid">
                <div class="info-card">
                  <h3>Marktwertermittlung</h3>
                  <div class="info-content">
                    <div class="info-row">
                      <span class="label">Geschätzter Wert</span>
                      <span class="value">{{ formatCurrency(lead.results?.resultAbsolute) }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Untere Grenze</span>
                      <span class="value">{{ formatCurrency(lead.results?.lowAbsolute) }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Obere Grenze</span>
                      <span class="value">{{ formatCurrency(lead.results?.highAbsolute) }}</span>
                    </div>
                  </div>
                </div>

                <div class="info-card">
                  <h3>Quadratmeterpreise</h3>
                  <div class="info-content">
                    <div class="info-row">
                      <span class="label">Preis pro m²</span>
                      <span class="value">{{ formatCurrency(lead.results?.resultPerSqm) }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Untere Grenze pro m²</span>
                      <span class="value">{{ formatCurrency(lead.results?.lowPerSqm) }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Obere Grenze pro m²</span>
                      <span class="value">{{ formatCurrency(lead.results?.highPerSqm) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </CCardBody>
          </CTab>

          <!-- Tab 5 Wohnmarktanalyse PDF -->
          <CTab title="5">
            <template #title>
              <div class="d-flex align-items-center">
                <CIcon name="cil-description" class="mr-2" />
                Wohnmarktanalyse
              </div>
            </template>
            <CCardBody>
              <div v-if="lead.pdfFile">
                <div class="pdf-actions mb-3">
                  <CButton
                    color="primary"
                    :href="lead.pdfFile.url"
                    target="_blank"
                  >
                    <CIcon name="cil-cloud-download" class="mr-2" />
                    PDF herunterladen
                  </CButton>
                </div>
                <!-- PDF Vorschau -->
                <div class="pdf-preview">
                  <iframe
                    :src="lead.pdfFile.url"
                    width="100%"
                    height="600px"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
              <CAlert
                v-else
                color="warning"
                class="d-flex align-items-center"
              >
                <CIcon name="cil-warning" class="mr-2" />
                Keine Wohnmarktanalyse verfügbar.
              </CAlert>
            </CCardBody>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import EmailActivityList from '@/components/features/leads/EmailActivityList.vue';
import LeadForm from './LeadForm';
import moment from 'moment';
import LeadMap from "@/components/LeadMap.vue";

export default {
  name: 'EditLead',
  components: {
    LeadForm,
    LeadMap,
    EmailActivityList
  },

  data() {
    return {
      lead: null,
      ready: false,
      editMode: false,
      activeTab: 0,
      emailInteractionsPage: {
        interactions: [],
        totalPages: 0,
        totalElements: 0,
        currentPage: 0,
        pageSize: 20
      },
      objectTypes: [
        { value: "APARTMENT", label: "Wohnung" },
        { value: "HOUSE", label: "Haus" },
        { value: "LAND", label: "Grundstück" },
        { value: "APARTMENT_HOUSE", label: "Mehrfamilienhaus" },
        { value: "RENT_APARTMENT", label: "Mietwohnung" },
        { value: "RENT_HOUSE", label: "Miethaus" },
      ]
    };
  },

  computed: {
    getBadgeColor() {
      if (!this.lead.optInRequest) return 'secondary';
      if (this.lead.optInRequest.cancelled) return 'danger';
      if (this.lead.optInRequest.confirmedAt) return 'success';
      if (this.lead.optInRequest.reOptInPending) return 'warning';
      return 'primary';
    },

    getBadgeText() {
      if (!this.lead.optInRequest) return 'Kein Opt-In';
      if (this.lead.optInRequest.cancelled) return 'Opt-Out';
      if (this.lead.optInRequest.accepted) return 'Aktiv';
      if (this.lead.optInRequest.reOptInPending) return 'Re-Opt-In ausstehend';
      return 'Ausstehend';
    }
  },

  async created() {
    await this.fetchLead();
    if (this.lead?.id) {
      await this.loadEmailInteractions();
    }
  },

  methods: {
    async initiateReOptIn() {
      console.log('Lead data before request:', {
        lead: this.lead,
        realmId: this.lead.realm?.id,
        directRealmId: this.lead.realmId,
        realm: this.lead.realm,
        holder: this.lead.holder
      });

      this.$modal.showModal(
          "default",
          "Re-Opt-In durchführen?",
          [
            `Möchten Sie einen Re-Opt-In für ${this.lead.holder.firstName} ${this.lead.holder.lastName} durchführen?`,
            `<i>Der Kunde erhält eine Bestätigungs-E-Mail und muss den Re-Opt-In bestätigen.</i>`,
          ],
          async () => {
            this.isReOptInLoading = true;

            try {
              const requestData = {
                email: this.lead.holder.email,
                realmId: parseInt(this.lead.realm?.id || this.lead.realmId, 10)
              };

              if (!requestData.realmId) {
                throw new Error(`RealmId is missing or invalid: ${JSON.stringify(requestData)}`);
              }

              console.log('Sending request with data:', requestData);

              const response = await axios.post(`/opt-in/re-optin`, requestData);

              if (response.data.status === 'success') {
                this.$toaster.makeToast(
                    "Erfolgreich!",
                    "Die Re-Opt-In E-Mail wurde versendet. Der Kunde muss den Link in der E-Mail bestätigen.",
                );

                if (response.data.optInRequestId) {
                  this.lead.optInRequest.id = response.data.optInRequestId;
                }

                await this.fetchLead();
              }
            } catch (error) {
              console.error('Complete error details:', {
                error,
                lead: this.lead,
                requestAttempt: {
                  email: this.lead.holder.email,
                  realmId: this.lead.realm?.id || this.lead.realmId
                }
              });

              let errorMessage = "Der Re-Opt-In konnte nicht durchgeführt werden. ";

              if (!this.lead.realm?.id && !this.lead.realmId) {
                errorMessage += "Keine gültige Realm-ID gefunden. ";
              }

              this.$toaster.makeToast(
                  "Fehler!",
                  errorMessage,
                  "danger"
              );
            } finally {
              this.isReOptInLoading = false;
            }
          }
      );
    },

    async fetchLead() {
      try {
        console.log('Fetching lead data...');
        const response = await axios.get("/lead/" + this.$route.params.id);
        console.log('Lead data received:', response.data);
        this.lead = response.data;
        this.ready = true;

        if (this.lead && this.lead.id) {
          console.log('Loading email interactions for lead:', this.lead.id);
          await this.loadEmailInteractions();
        }

        console.log('Final lead data:', {
          lead: this.lead,
          emailInteractions: this.lead.emailInteractions,
          activities: this.lead.activities
        });
      } catch (err) {
        console.error('Error fetching lead:', err);
        this.$toaster.makeToast(
            "Fehler",
            "Der Lead konnte nicht geladen werden.",
            "danger",
        );
      }
    },

    async loadEmailInteractions(page = 0) {
      try {
        const response = await axios.get(`/lead/${this.lead.id}/email-interactions`, {
          params: {
            page: page,
            size: 20
          }
        });
        this.lead.emailInteractions = response.data.interactions;
      } catch (err) {
        console.error("Error loading email interactions:", err);
        this.$toaster.makeToast(
            "Fehler",
            "Die E-Mail-Aktivitäten konnten nicht geladen werden.",
            "danger"
        );
      }
    },

    async handleEmailInteractionsPageChange(page) {
      await this.loadEmailInteractions(page);
    },

    toggleEditMode() {
      this.editMode = !this.editMode;
    },

    handleTabChange(tab) {
      this.activeTab = Number(tab);
    },

    formatCurrency(value) {
      return value
          ? new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          }).format(value)
          : 'Nicht verfügbar';
    },

    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    },

    translateObjectType(type) {
      const typeMap = {
        APARTMENT: 'Wohnung',
        HOUSE: 'Haus',
        LAND: 'Grundstück',
        APARTMENT_HOUSE: 'Mehrfamilienhaus',
        RENT_APARTMENT: 'Mietwohnung',
        RENT_HOUSE: 'Miethaus'
      };
      return typeMap[type] || type || 'Unbekannt';
    },

    saveLead(lead) {
      const editLeadDTO = {
        id: this.lead.id,
        holder: {
          salutation: this.lead.holder.salutation,
          firstName: this.lead.holder.firstName,
          lastName: this.lead.holder.lastName,
          phoneNumber: this.lead.holder.phoneNumber,
          email: this.lead.holder.email
        },
        values: this.lead.values,
        status: this.lead.status
      };

      axios
          .put('/lead', editLeadDTO)
          .then((response) => {
            this.$toaster.makeToast(
                "Gespeichert!",
                "Der Lead wurde erfolgreich gespeichert.",
            );
            this.$router.push({ name: "Leads" });
          })
          .catch((err) => {
            console.error(err);
            this.$toaster.makeToast(
                "Fehler",
                "Der Lead konnte nicht gespeichert werden.",
                "danger",
            );
          });
    },

    manualOptOut() {
      this.$modal.showModal(
          "default",
          "Manuellen Opt-Out durchführen?",
          [
            `Möchten Sie einen manuellen Opt-Out für den Kunden <b>${this.lead.holder.firstName} ${this.lead.holder.lastName}</b> ausführen?`,
            `<i>Die Kund:innen erhalten somit keine E-Mails mehr von der Marketing-Cloud.</i>`,
          ],
          async () => {
            try {
              let resp = await axios.get(
                  `/opt-in/cancel/${this.lead.optInRequest.id}`,
              );
              this.$toaster.makeToast(
                  "Erfolgreich!",
                  "Der Opt-Out wurde erfolgreich durchgeführt.",
              );
              this.lead.optInRequest.cancelled = moment().format();
            } catch (e) {
              this.$toaster.makeToast(
                  "Fehler!",
                  "Der Opt-Out konnte nicht durchgeführt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
              );
              console.error(e);
            }
          },
      );
    }
  }
};
</script>

<style scoped>
.modern-lead-container {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-card {
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.lead-title h1 {
  font-size: 1.75rem;
  font-weight: 600;
}

.quick-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.quick-info-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.modern-tabs {
  border: none;
}

.modern-tabs :deep(.nav-pills) {
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  gap: 0.5rem;
}

.modern-tabs :deep(.nav-pills .nav-link) {
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  color: #6c757d;
  font-weight: 500;
  transition: all 0.2s ease;
}

.modern-tabs :deep(.nav-pills .nav-link.active) {
  background-color: var(--primary);
  color: white;
}

.email-tracking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  margin-bottom: 1.5rem;
}

.search-bar {
  flex: 1;
  min-width: 300px;
}

.tracking-stats {
  display: flex;
  gap: 2rem;
}

.stat-item {
  text-align: center;
}

.stat-label {
  display: block;
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
}

.stat-value {
  display: block;
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
}

.activities-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.activity-card {
  display: flex;
  padding: 1.25rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: all 0.2s ease;
  border-left: 4px solid transparent;
  gap: 1rem;
}

.activity-card:hover {
  transform: translateX(4px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.activity-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: #f8f9fa;
  transition: all 0.2s ease;
}

.activity-content {
  flex: 1;
  min-width: 0;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  gap: 1rem;
}

.activity-header h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #2c3e50;
}

.activity-time {
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.activity-details {
  color: #495057;
  margin-bottom: 0.75rem;
  line-height: 1.5;
}

.activity-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.campaign-name {
  font-size: 0.875rem;
  color: #0d6efd;
  font-weight: 500;
}

.device-info, .location-info {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.device-info span, .location-info span {
  display: flex;
  align-items: center;
}

/* Activity Types */
.activity-sent {
  border-left-color: #20a8d8;
}

.activity-sent .activity-icon {
  color: #20a8d8;
  background-color: #e8f5ff;
}

.activity-opened {
  border-left-color: #4dbd74;
}

.activity-opened .activity-icon {
  color: #4dbd74;
  background-color: #e8fff0;
}

.activity-clicked {
  border-left-color: #ffc107;
}

.activity-clicked .activity-icon {
  color: #ffc107;
  background-color: #fff7e6;
}

.activity-error {
  border-left-color: #f86c6b;
}

.activity-error .activity-icon {
  color: #f86c6b;
  background-color: #fff0f0;
}

/* Contact Info and Property Info */
.contact-info-grid,
.property-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.info-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.info-card h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #495057;
}

.info-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e9ecef;
}

.info-row:last-child {
  border-bottom: none;
}

.info-row .label {
  color: #6c757d;
  font-weight: 500;
}

.info-row .value {
  color: #2c3e50;
  text-align: right;
}

/* Map and PDF */
.map-container {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.pdf-preview {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.pdf-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.pdf-actions :deep(.btn) {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .email-tracking-header {
    flex-direction: column;
  }

  .search-bar {
    width: 100%;
  }

  .tracking-stats {
    width: 100%;
    justify-content: space-around;
  }

  .activity-header {
    flex-direction: column;
  }

  .activity-meta {
    flex-direction: column;
    align-items: flex-start;
  }

  .quick-info-grid {
    grid-template-columns: 1fr;
  }

  .contact-info-grid,
  .property-info-grid {
    grid-template-columns: 1fr;
  }

  .modern-tabs :deep(.nav-pills) {
    flex-wrap: wrap;
  }

  .device-info, .location-info {
    flex-direction: column;
    gap: 0.5rem;
  }
}

/* Extra Styles für bessere Accessibility und Interaktivität */
.activity-card:focus-within {
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}

.activity-icon :deep(svg) {
  transition: transform 0.2s ease;
}

.activity-card:hover .activity-icon :deep(svg) {
  transform: scale(1.1);
}

/* Hover Effekte für interaktive Elemente */
.campaign-name:hover {
  color: #0b5ed7;
  text-decoration: underline;
}

.device-info span:hover,
.location-info span:hover {
  color: #495057;
}

/* Loading States */
.activity-card.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Animation für neue Aktivitäten */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.activity-card {
  animation: slideIn 0.3s ease-out;
}
</style>
