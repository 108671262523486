<template>
  <div class="form-group form-row" :key="name">
    <CCol tag="label" sm="3" class="col-form-label">
      {{ label }}
    </CCol>
    <CCol sm="9">
      <CInputRadio
        v-for="(option, optionsIndex) in computedOptions"
        :key="optionsIndex"
        :label="option.label"
        :value="option.value"
        :custom="true"
        type="radio"
        :name="name"
        :checked="value === option.value"
        @change="onSelected(option.value)"
        :inline="false"
      />
      <small
        v-if="description"
        class="form-text text-muted w-100"
        v-text="description"
      />
    </CCol>
  </div>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    label: String,
    name: String,
    options: Array,
    description: String,
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  computed: {
    computedOptions() {
      return this.options.map((opt) => {
        if (typeof opt === "string") {
          return { label: opt, value: opt };
        }
        return { label: opt.label || opt.value, value: opt.value };
      });
    },
  },
  methods: {
    onSelected(selectedValue) {
      this.$emit("input", selectedValue); // Emit the selected value
    },
  },
};
</script>
