var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('div',{staticClass:"prt-card-header"},[_c('div',{staticClass:"header-naming"},[_c('CIcon',{attrs:{"name":"cil-book"}}),_vm._v(" Freizugebene Leads ")],1)])]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.tableData,"itemsPerPageSelect":{ label: 'Leads pro Seite' },"fields":_vm.getTableFields(),"noItemsView":{
              noResults: 'Keine Suchergebnisse verfügbar',
              noItems: 'Keine Leads in der Freigabe',
            },"hover":"","striped":"","bordered":"","fixed":"","column-filter":"","table-filter":"","tableFilter":{
              label: 'Leads suchen',
              placeholder: 'Suchbegriff eingeben',
            },"items-per-page-select":"","sorter":"","sorterValue":{ column: 'updated', asc: false },"pagination":"","loading":_vm.tableShowLoading},scopedSlots:_vm._u([{key:"type",fn:function({ item }){return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getTableBadge(item.type)}},[_vm._v(" "+_vm._s(item.type)+" ")])],1)]}},{key:"show_details",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"to":'queuedLeads/edit/' + item.id,"color":"primary","variant":"outline","square":"","size":"sm"}},[_vm._v(" Edit ")])],1)]}},{key:"created",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.created).format(_vm.$commons.DATE_TIME_FORMAT))+" ")])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }