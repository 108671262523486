<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Account bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <OrganizationForm
              v-if="ready"
              :name="organization.name"
              :contact-phone="organization.contactPhone"
              :contact-address="organization.contactAddress"
              :status="organization.status"
              :stripe-customer-id="organization.stripeCustomerId"
              :logo-url="organization.logoUrl"
              @submit="editOrganization"
              :processing="processing"
              :is-create="false"
              btn-text="Organisation speichern"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import OrganizationForm from "./base/OrganizationForm";

export default {
  name: "EditOrganization",
  components: { OrganizationForm },
  data() {
    return {
      ready: false,
      organization: null,
      processing: false,
    };
  },
  created() {
    axios
      .get("/organization/" + this.$route.params.id)
      .then((response) => {
        this.organization = response.data;
        this.ready = true;
      })
      .catch((err) => console.error(err));
  },
  methods: {
    editOrganization(data) {
      this.processing = true;
      axios
        .put("/organization/" + this.$route.params.id, data)
        .then((response) => {
          this.organization = response.data; // Aktualisierte Daten setzen
          this.$router.push({ name: "Organizations" });
          this.$toaster.makeToast(
            "Erfolg!",
            "<b>Organisation erfolgreich bearbeitet!</b>",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Ups!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Fehler!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
