<template>
  <div>
    <CInput
      :label="label"
      :name="name"
      :value="computedValue"
      :required="required"
      :isValid="validateSubdomain"
      :invalidFeedback="invalidFeedback"
      @input="inputEvent"
    >
      <template v-if="!customDomain" #append>
        <CSelect
          class="prt-landingpage-domain-input-select"
          :value="chosenPrtDomain"
          @update:value="chosenPrtDomain = $event"
          required
          :options="[
            '.immobilienmakler-rheinbach.de',
            '.gardasee-wohnungen.de',
            '.gardaseewohnungen.de',
            '.gratis-immobewertung.de',
            '.haus-verkaufen-haan.de',
            '.ihr-immobilienmakler-koeln.de',
            '.immobilien-bad-kissingen.de',
            '.immobilien-badtoelz.de',
            '.immobilien-datteln.de',
            '.immobilien-geilenkirchen.de',
            '.immobilien-lead.de',
            '.immobilien-luftbilder.de',
            '.immobilien-makler-bonn.de',
            '.immobilien-makler-duesseldorf.de',
            '.immobilien-news.at',
            '.immobilien-wertberechnung.de',
            '.immobilien-wertheim.de',
            '.immobilien-winterberg.de',
            '.immobilienangebote-augsburg.de',
            '.immobilienangebote-berlin.de',
            '.immobilienangebote-buende.de',
            '.immobilienangebote-fuerth.de',
            '.immobilienangebote-gladbeck.de',
            '.immobilienangebote-muenchen.de',
            '.immobilienangebote-passau.de',
            '.immobilienauktion-bergisches-land.de',
            '.immobilienbewertung-arnsberg.de',
            '.immobilienbewertung-baden-baden.de',
            '.immobilienbewertung-bergisch-gladbach.de',
            '.immobilienbewertung-bergisches-land.de',
            '.immobilienbewertung-bogenhausen.de',
            '.immobilienbewertung-detmold.de',
            '.immobilienbewertung-dinslaken.de',
            '.immobilienbewertung-dormagen.de',
            '.immobilienbewertung-eckernfoerde.de',
            '.immobilienbewertung-eifel.de',
            '.immobilienbewertung-freising.de',
            '.immobilienbewertung-gladbeck.de',
            '.immobilienbewertung-grevenbroich.de',
            '.immobilienbewertung-gruenwald.de',
            '.immobilienbewertung-herne.de',
            '.immobilienbewertung-hilden.de',
            '.immobilienbewertung-iserlohn.de',
            '.immobilienbewertung-kerpen.de',
            '.immobilienbewertung-koeln-bonn.de',
            '.immobilienbewertung-koelnbonn.de',
            '.immobilienbewertung-leipzig.info',
            '.immobilienbewertung-leverkusen.de',
            '.immobilienbewertung-lippstadt.de',
            '.immobilienbewertung-minden.de',
            '.immobilienbewertung-passau.de',
            '.immobilienbewertung-ravensburg.de',
            '.immobilienbewertung-recklinghausen.de',
            '.immobilienbewertung-rheine.de',
            '.immobilienbewertung-salzgitter.de',
            '.immobilienbewertung-starnberg.de',
            '.immobilienbewertung-starnberg.info',
            '.immobilienbewertung-stralsund.de',
            '.immobilienbewertung-thueringen.de',
            '.immobilienbewertungen-online.de',
            '.immobilienmakler-ahaus.de',
            '.immobilienmakler-bad-salzuflen.de',
            '.immobilienmakler-bad-toelz.de',
            '.immobilienmakler-bad-vilbel.de',
            '.immobilienmakler-badtoelz.de',
            '.immobilienmakler-beckum.de',
            '.immobilienmakler-berchtesgaden.de',
            '.immobilienmakler-bergkamen.de',
            '.immobilienmakler-berlin-charlottenburg.de',
            '.immobilienmakler-berlin-mitte.de',
            '.immobilienmakler-berlin-wilmersdorf.de',
            '.immobilienmakler-bonn-immobilien.de',
            '.immobilienmakler-borken.de',
            '.immobilienmakler-daun.de',
            '.immobilienmakler-duesseldorf.immo',
            '.immobilienmakler-eifel.de',
            '.immobilienmakler-gauting.de',
            '.immobilienmakler-greven.de',
            '.immobilienmakler-gronau.de',
            '.immobilienmakler-haltern.de',
            '.immobilienmakler-heppenheim.de',
            '.immobilienmakler-italien.de',
            '.immobilienmakler-kempten.de',
            '.immobilienmakler-koeln-bonn.de',
            '.immobilienmakler-koeln.net',
            '.immobilienmakler-kreuzberg.de',
            '.immobilienmakler-lampertheim.de',
            '.immobilienmakler-leverkusen.immo',
            '.immobilienmakler-memmingen.de',
            '.immobilienmakler-nuertingen.de',
            '.immobilienmakler-raesfeld.de',
            '.immobilienmakler-refrath.de',
            '.immobilienmakler-reken.de',
            '.immobilienmakler-rhein-main.de',
            '.immobilienmakler-rheinbach.de',
            '.immobilienmakler-sachsen.de',
            '.immobilienmakler-schermbeck.de',
            '.immobilienmakler-taunusstein.de',
            '.immobilienmakler-thueringen.de',
            '.immobilienmakler-velbert.de',
            '.immobilienmakler-viernheim.de',
            '.immobilienmakler-worms.de',
            '.immobilienmakler.estate',
            '.immobilienmakler.haus',
            '.immobilienmaklerin-hamburg.de',
            '.immobilienmaklerin-muenchen.de',
            '.immobilienmaklernrw.de',
            '.immobilienmarkt-ruhrgebiet.de',
            '.immobilienverkauf-neuss.de',
            '.immobilienvermietung-frankfurt.de',
            '.immobilienwert-hannover.de',
            '.immobilienwert-koeln.de',
            '.immobilienwert.online',
            '.immobilienwertberechnung.de',
            '.leben-am-gardasee.de',
            '.lebenamgardasee.de',
            '.makler-dorsten.de',
            '.makler-dorsten.info',
            '.makler-meerbusch.de',
            '.makler-schermbeck.de',
            '.muenchens-immobilienmakler.de',
            '.online-immobilienbewertungen.de',
            '.online-leadgenerierung.de',
            '.stuttgart-immobilien-makler.de',
            '.top-immobilien-hannover.de',
            '.verkehrswert-bayern.de',
          ]"
        />
      </template>
      <template #description>
        <template v-if="customDomain">
          <small class="form-text text-muted w-100">
            Bitte geben Sie Ihre Landingpage Domain ein. Diese Domain muss auf
            unseren Server (<b>landingpage.makler-anfragen.immo</b>) zeigen.
          </small>
          <CButton
            class="mt-1 prt-no-focus-box-shadow"
            variant="outline"
            color="info"
            @click="customDomain = !customDomain"
          >
            Unsere Domains verwenden
          </CButton>
        </template>
        <template v-else>
          <small class="form-text text-muted w-100">
            Bitte geben Sie eine Subdomain ein.
          </small>
          <CButton
            class="mt-1 prt-no-focus-box-shadow"
            variant="outline"
            color="danger"
            @click="customDomain = !customDomain"
          >
            Eigene Domain verwenden
          </CButton>
        </template>
      </template>
    </CInput>
  </div>
</template>

<script>
export default {
  name: "LandingpageDomainInput",
  props: {
    label: String,
    name: String,
    required: Boolean,
    value: String,
  },
  data() {
    return {
      customDomain: false,
      chosenPrtDomain: ".immobilienmakler-rheinbach.de",
      invalidFeedback:
        "Sie dürfen nur alphanumerische Zeichen und folgende Sonderzeichen nutzen: - (Minus) und _ (Unterstrich)",
    };
  },
  mounted() {
    if (this.value) {
      if (this.isOneOfPrtDomains()) {
        this.customDomain = false;
        this.chosenPrtDomain = this.parsePrtDomain();
      } else {
        this.customDomain = true;
      }
    }
  },
  computed: {
    computedValue() {
      if (!this.customDomain) {
        const urlParts = this.value.split(".");
        return urlParts[0];
      } else {
        return this.value;
      }
    },
  },
  watch: {
    customDomain() {
      this.inputEvent(this.computedValue);
    },
    chosenPrtDomain() {
      this.inputEvent(this.computedValue);
    },
  },
  methods: {
    inputEvent($event) {
      const domain = $event.toLowerCase();
      if (!this.customDomain) {
        if (this.validateSubdomain(domain)) {
          this.$emit("input", domain + this.chosenPrtDomain);
        }
      } else {
        this.$emit("input", domain);
      }
    },
    validateSubdomain(value) {
      if (this.customDomain || value.length === 0) return null;
      let re = /^[^\W]+[_-a-zA-Z0-9]*$/i;
      return re.test(value);
    },
    isOneOfPrtDomains() {
      let domain = this.parsePrtDomain();
      return (
        [
          ".landingpagemeister.de",
          ".superdupageilomat.de",
          ".andreas-der-geile.de",
        ].indexOf("." + domain.toLowerCase()) !== -1
      );
    },
    parsePrtDomain() {
      let split = this.value.split(".");
      split.shift();
      return split.join(".");
    },
  },
};
</script>

<style>
.prt-landingpage-domain-input-select {
  margin-bottom: 0;
}

.prt-landingpage-domain-input-select select.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #768192;
  background-color: #ebedef;
  border-color: #d8dbe0;
  outline: 0;
}

.prt-landingpage-domain-input-select select.form-control:focus {
  outline: 0;
  box-shadow: unset;
}
</style>
