<template>
  <CRow>
    <CCol md="12">
      <CCard>
        <CCardHeader>
          <slot name="header">
            <div class="prt-card-header">
              <div class="header-naming">
                <CIcon name="cil-grid" />
                Alle Merkmale
              </div>
              <div class="header-button">
                <CButton
                  name="backagain"
                  size="sm"
                  key="key"
                  color="dark"
                  @click="showAddModal"
                >
                  <CIcon size="sm" name="cil-plus" />
                  <span> Neues Merkmal hinzufügen</span>
                </CButton>
              </div>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="tableData"
            :itemsPerPageSelect="{ label: 'Merkmale pro Seite' }"
            :fields="getTableFields()"
            :noItemsView="{
              noResults: 'Keine Suchergebnisse verfügbar',
              noItems: 'Noch keine Merkmale erstellt',
            }"
            hover
            striped
            bordered
            fixed
            column-filter
            table-filter
            :tableFilter="{
              label: 'Merkmale suchen',
              placeholder: 'Merkmal eingeben',
            }"
            items-per-page-select
            sorter
            pagination
            :loading="tableShowLoading"
          >
            <template #show_details="{ item, index }">
              <td class="py-2">
                <CButton
                  @click="showUpdateModal(item)"
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                >
                  Bearbeiten
                </CButton>
              </td>
            </template>
            <template #delete_item="{ item, index }">
              <td class="py-2">
                <CButton
                  @click="deleteItem(item.id)"
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                >
                  Löschen
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol md="12">
      <CForm @submit="submit">
        <CModal
          title="Merkmale hinzufügen"
          size="sm"
          :show.sync="visibleAddModal"
          :closeOnBackdrop="false"
        >
          <CRow>
            <CCol sm="12">
              <label for="title">Name</label>
              <CInput
                id="title"
                required="true"
                placeholder="Name des Merkmals"
                v-model="title"
              />
            </CCol>
          </CRow>
          <div slot="footer">
            <CButton
              class="mr-3"
              color="dark"
              variant="outline"
              @click="visibleAddModal = false"
            >
              Abbrechen
            </CButton>
            <CButton color="success" type="submit"> Speichern </CButton>
          </div>
        </CModal>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import GoogleMapsAutocompleteInput from "@/components/GoogleMapsAutocompleteInput";

export default {
  mounted() {
    this.loadOrganizations();
    window.initGoogleMaps = () => {
      this.googleApiLoaded = true;
    };
  },
  components: {
    GoogleMapsAutocompleteInput,
  },
  data() {
    return {
      visibleAddModal: false,
      title: "",
      tableData: [],
      tableShowLoading: false,
      actionFlag: "none",
      selectedItem: null,
    };
  },
  methods: {
    loadOrganizations() {
      this.tableShowLoading = true;
      axios
        .get("/realm-group")
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableShowLoading = false;
        });
    },
    submit(e) {
      this.tableShowLoading = true;
      e.preventDefault();
      const data = {
        title: this.title,
      };

      if (this.actionFlag === "add") {
        axios
          .post("/realm-group", data)
          .then((response) => {
            if (this.tableData.length === 0) {
              this.tableData = [response.data];
            } else {
              this.tableData.push(response.data);
            }

            this.initialize();
            this.visibleAddModal = false;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.tableShowLoading = false;
          });
      } else if (this.actionFlag === "update") {
        axios
          .put(`/realm-group/${this.selectedItem.id}`, data)
          .then(() => {
            const entity = this.tableData.find(
              (item) => item.id === this.selectedItem.id,
            );
            entity.title = data.title;

            this.initialize();
            this.visibleAddModal = false;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.tableShowLoading = false;
          });
      }
    },
    initialize() {
      this.title = "";
    },
    getTableFields() {
      return [
        { key: "title", label: "Merkmale" },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
        {
          key: "delete_item",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ];
    },
    showUpdateModal(item) {
      this.title = item.title;
      this.selectedItem = item;
      this.actionFlag = "update";
      this.visibleAddModal = true;
    },
    showAddModal() {
      this.initialize();
      this.actionFlag = "add";
      this.visibleAddModal = true;
    },
    deleteItem(id) {
      this.tableShowLoading = true;
      axios
        .delete(`realm-group/${id}`)
        .then(() => {
          this.tableData = this.tableData.filter((item) => item.id !== id);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableShowLoading = false;
        });
    },
  },
};
</script>

<style>
.pac-container {
  z-index: 10000 !important;
}

.pac-target-input {
  width: 135%;
}

@media (max-width: 576px) {
  .pac-target-input {
    width: 100%;
  }
}
</style>
