<template>
  <Leadgenerator
    v-if="ready"
    @submit="editLeadgenerator"
    :enable-delete="true"
    :passedData="leadgenerator"
    :processing="processing"
    btn-text="Funnel veröffentlichen"
  />
</template>

<script>
import axios from "axios";
import Leadgenerator from "@/views/base/leadgenerator/Leadgenerator";

export default {
  name: "LeadgeneratorEdit",
  components: { Leadgenerator },

  data() {
    return {
      ready: false,
      leadgenerator: null,
      processing: false,
    };
  },

  created() {
    axios
      .get("/leadgenerator/" + this.$route.params.id)
      .then((response) => {
        let data = response.data;
        data.contentJson = JSON.parse(data.contentJson);
        this.leadgenerator = data;
        this.ready = true;
      })
      .catch((err) => console.error(err));
  },

  beforeRouteLeave(to, from, next) {
    if (to.params.saved) {
      next();
      return;
    }
    this.$modal.showModal(
      "default",
      "Editor wirklich schließen?",
      [
        `Es gibt noch Änderungen im Funnel, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`,
      ],
      () => {
        next();
      },
      () => {
        next(false);
      },
      {
        btnCancel: "Abbrechen",
        btnConfirm: "Trotzdem schließen",
        btnConfirmColor: "danger",
      },
    );
  },

  methods: {
    async editLeadgenerator(data) {
      this.processing = true;
      try {
        data.contentJson = JSON.stringify(data.contentJson);
        await axios.put("/leadgenerator/" + this.$route.params.id, data);

        if (data.isPublishing) {
          // Nur bei Veröffentlichung zur Liste navigieren und Toast anzeigen
          this.$router.push({
            name: "LeadgeneratorList",
            params: { saved: "1" }
          });
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Funnel wurde erfolgreich veröffentlicht</b>"
          );
        }
        // Toast bei automatischem Speichern entfernt
      } catch (err) {
        if (err.response) {
          this.$toaster.makeToast(
            "Oops!",
            "<b>" + err.response.data.code + "</b><br>" + err.response.data.message
          );
        } else {
          this.$toaster.makeToast("Fehler!", err.message);
        }
        throw err;
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
