<template>
  <div>
    <CRow>
      <CCol sm="4">
        <CInput
          type="number"
          :label="label"
          :value="durationTime"
          @input="$emit('duration:time', $event)"
          :min="min"
          :max="max"
          :horizontal="false"
        />
      </CCol>
      <CCol sm="8">
        <CSelect
          label="Zeitraum"
          :horizontal="false"
          :value="durationType.toLowerCase()"
          @update:value="$emit('duration:type', $event)"
          :options="[
            { label: 'Minute(n)', value: 'minutes' },
            { label: 'Stunde(n)', value: 'hours' },
            { label: 'Tag(e)', value: 'days' },
          ]"
        />
      </CCol>
    </CRow>
    <slot name="description">
      <small
        v-if="description"
        class="form-text text-muted w-100"
        v-text="description"
        style="margin-top: -10px; margin-bottom: 20px"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: "DurationInput",
  props: {
    durationTime: {
      type: Number,
      default: 1,
    },
    durationType: {
      type: String,
      default: "minutes",
      validator: (value) =>
        ["minutes", "hours", "days"].indexOf(value.toLowerCase()) !== -1,
    },
    label: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1000,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
