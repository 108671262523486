<template>
  <div>
    <KensingtonLocationImport />
  </div>
</template>

<script>
import axios from "axios";
import KensingtonLocationImport from "@/plugin/kensington/KensingtonLocationImport";

export default {
  name: "ImportExport",
  data() {
    return {
      placeholder: "Datei auswählen...",
      file: null,
    };
  },
  components: {
    KensingtonLocationImport,
  },
  methods: {},
};
</script>

<style scoped></style>
