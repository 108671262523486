<template>
  <div class="deals-container">
    <!-- Header Bereich -->
    <div class="header-section mb-4">
      <CCard>
        <CCardBody>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h1 class="mb-0">Deals</h1>
            </div>
            <div class="button-group">
              <CButton
                color="primary"
                class="mr-2"
                to="/deals/pipeline"
              >
                <CIcon name="cil-settings" class="mr-1" />
                Pipeline Einstellungen
              </CButton>
              <CButton
                color="success"
                v-if="hasPipelines"
                @click="showAddDealModal"
              >
                <CIcon name="cil-plus" class="mr-1" />
                Neuer Deal
              </CButton>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <!-- Hauptbereich -->
    <div class="main-section">
      <!-- Fehleranzeige -->
      <CAlert
        v-if="error"
        color="danger"
        closeButton
        @dismiss="error = null"
      >
        {{ error }}
      </CAlert>

      <!-- Loading Zustand -->
      <div v-if="isLoading" class="text-center py-5">
        <CSpinner color="primary" />
        <div class="mt-2">Daten werden geladen...</div>
      </div>

      <!-- Kanban Board -->
      <template v-else>
        <KanbanBoard
          ref="kanbanBoard"
          @error="handleError"
          @dealAdded="handleDealAdded"
          @dealUpdated="handleDealUpdated"
          @dealDeleted="handleDealDeleted"
        />
      </template>
    </div>

    <!-- Quick Add Deal Modal -->
    <DealModal
      v-if="showQuickAddModal"
      :visible.sync="showQuickAddModal"
      mode="add"
      :deal="initialDealData"
      :pipeline-steps="pipelineSteps"
      @deal-added="handleQuickDealAdded"
    />
  </div>
</template>

<<script>
import KanbanBoard from '@/components/deals/KanbanBoard.vue'
import DealModal from '@/components/deals/modals/DealModal.vue'
import axios from 'axios'

export default {
  name: 'Deals',

  components: {
    KanbanBoard,
    DealModal
  },

  data() {
    return {
      error: null,
      isLoading: false,
      showQuickAddModal: false,
      pipelines: [],
      originalPipelines: [],
      pipelineSteps: [],
      initialDealData: {
        name: '',
        value: null,
        realmPeopleId: '',
        realmGroupId: '',
        pipelineStepId: ''
      }
    }
  },

  computed: {
    hasPipelines() {
      return this.pipelines && Array.isArray(this.pipelines) && this.pipelines.length > 0
    },

    kanbanBoard() {
      return this.$refs.kanbanBoard
    }
  },

  async mounted() { // Änderung von created zu mounted wie im Original
    await this.loadPipelines()
  },

  methods: {
    async loadPipelines() {
      try {
        const ret = await axios.get('/pipeline')
        this.originalPipelines = ret.data || []
        this.pipelines = ret.data.map(item => ({
          value: item.id,
          label: item.name
        }))

        if (this.pipelines.length > 0) {
          // Da die Pipeline-Schritte bereits in der Antwort enthalten sind,
          // können wir sie direkt verwenden
          const firstPipeline = this.originalPipelines[0]
          this.pipelineSteps = firstPipeline.pipelineSteps.sort(
            (a, b) => a.orderNumber - b.orderNumber
          )
          // Setze die erste Pipeline als ausgewählt
          this.selectedPipeline = firstPipeline.id
        }
      } catch (error) {
        console.error('Fehler beim Laden der Pipelines:', error)
        this.handleError('Fehler beim Laden der Pipelines')
      }
    },

    async loadPipelineSteps(pipelineId) {
      try {
        const pipeline = this.originalPipelines.find(p => p.id === pipelineId)
        if (pipeline) {
          this.pipelineSteps = pipeline.pipelineSteps.sort(
            (a, b) => a.orderNumber - b.orderNumber
          )
        }
      } catch (error) {
        console.error('Fehler beim Laden der Pipeline-Schritte:', error)
        this.handleError('Fehler beim Laden der Pipeline-Schritte')
      }
    },

    handleError(message) {
      this.error = message;
      setTimeout(() => {
        this.error = null;
      }, 5000);
    },

    handleCloseModal() {
      if (this.$refs.kanbanBoard) {
        this.$refs.kanbanBoard.closeActivityModal();
      }
    },

    handleActivityUpdate(updatedActivity) {
      if (this.$refs.kanbanBoard) {
        this.$refs.kanbanBoard.handleActivityUpdate(updatedActivity);
      }
    },

    async handleDealAdded() {
      if (this.kanbanBoard?.refreshData) {
        await this.kanbanBoard.refreshData();
      }
    },

    async handleDealUpdated() {
      if (this.kanbanBoard?.refreshData) {
        await this.kanbanBoard.refreshData();
      }
    },

    async handleDealDeleted() {
      if (this.kanbanBoard?.refreshData) {
        await this.kanbanBoard.refreshData();
      }
    },

    showAddDealModal() {
      this.showQuickAddModal = true
    },

    async handleQuickDealAdded(deal) {
      this.showQuickAddModal = false
      await this.handleDealAdded()
    }
  }
}
</script>

<style scoped>
.deals-container {
  @apply space-y-4;
}

.header-section {
  @apply sticky top-0 z-10;
  backdrop-filter: blur(8px);
}

.button-group {
  @apply flex gap-2;
}

.main-section {
  @apply min-h-[calc(100vh-200px)];
}

@media (max-width: 768px) {
  .button-group {
    @apply flex-col;
  }

  .button-group .btn {
    @apply w-full;
  }
}
</style>
