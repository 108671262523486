<template>
  <div class="leads-container p-3">
    <CCard>
      <CCardHeader class="bg-white border-bottom-0">
        <!-- Header Section -->
        <CRow class="align-items-center mb-4">
          <CCol sm="6">
            <div class="d-flex align-items-center">
              <CIcon name="cil-people" size="xl" class="mr-3"/>
              <h2 class="mb-0">Lead-Übersicht</h2>
            </div>
          </CCol>
          <CCol sm="6">
            <div class="d-flex justify-content-end">
              <CButtonGroup>
                <CButton
                    :color="viewMode === 'kanban' ? 'primary' : 'secondary'"
                    @click="viewMode = 'kanban'"
                >
                  <CIcon name="cil-columns"/>
                  Kanban
                </CButton>
                <CButton
                    :color="viewMode === 'list' ? 'primary' : 'secondary'"
                    @click="viewMode = 'list'"
                >
                  <CIcon name="cil-list"/>
                  Liste
                </CButton>
              </CButtonGroup>
            </div>
          </CCol>
        </CRow>

        <!-- Statistics Section -->
        <CRow class="mb-4">
          <CCol lg="4">
            <CCard class="bg-light border-0 h-100">
              <CCardBody>
                <h6 class="text-muted mb-3">Hauptstatistiken</h6>
                <div class="d-flex justify-content-between mb-2">
                  <div class="text-muted">Gesamt</div>
                  <div class="h4 mb-0">{{ totalLeads }}</div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div class="text-muted">Heute neu</div>
                  <div class="h4 mb-0">{{ newLeadsToday || 0 }}</div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="text-muted">Ø Wert</div>
                  <div class="h4 mb-0">{{ formatCurrency(averageLeadValue || 0) }}</div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol lg="4">
            <CCard class="bg-light border-0 h-100">
              <CCardBody>
                <h6 class="text-muted mb-3">Zeitbasierte Statistiken</h6>
                <div class="d-flex justify-content-between mb-2">
                  <div class="text-muted">Letzte 24h</div>
                  <div class="h5 mb-0">{{ recentLeads }}</div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                  <div class="text-muted">Letzte Woche</div>
                  <div class="h5 mb-0">{{ leadsLastWeek }}</div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="text-muted">Letzter Monat</div>
                  <div class="h5 mb-0">{{ leadsLastMonth }}</div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol lg="4">
            <CCard class="bg-light border-0 h-100">
              <CCardBody>
                <h6 class="text-muted mb-3">Wertbasierte Statistiken</h6>
                <div class="d-flex justify-content-between mb-2">
                  <div class="text-muted">Gesamtwert</div>
                  <div class="h5 mb-0">{{ formatCurrency(totalLeadValue || 0) }}</div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="text-muted">Ø Wert (Monat)</div>
                  <div class="h5 mb-0">{{ formatCurrency(averageLeadValueLastMonth || 0) }}</div>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <!-- Actions Section -->
        <CRow class="mb-4">
          <CCol>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex gap-2">
                <CButton
                    v-if="$commons.isOrganizationUser()"
                    :color="organizationWide ? 'primary' : 'secondary'"
                    @click="toggleOrganizationWide"
                >
                  {{ organizationWide ? "Organisations-weit" : "Instanz-weit" }}
                </CButton>
                <CButton color="success" @click="exportCSV">
                  <CIcon name="cil-cloud-download"/>
                  Export (CSV)
                </CButton>
                <!-- Neuer Edit-Button -->
                <CButton
                    v-if="selectedLead"
                    color="primary"
                    :to="`/leads/edit/${selectedLead.id}`"
                >
                  <CIcon name="cil-pencil"/>
                  Lead bearbeiten
                </CButton>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>

      <CCardBody>

        <!-- Filters Section -->
        <div class="filter-section bg-light p-3 mb-2 rounded">
          <div class="d-flex gap-3 align-items-end">
            <div class="flex-grow-1">
              <label class="text-muted mb-2">Schnellsuche</label>
              <CInput
                  v-model="quickSearchQuery"
                  placeholder="Name, Email, Telefon..."
                  @input="onQuickSearchInput"
              >
                <template #prepend-content>
                  <CIcon name="cil-search"/>
                </template>
              </CInput>
            </div>

            <div class="w-25">
              <label class="text-muted mb-2">Typ</label>
              <CSelect
                  v-model="selectedType"
                  :options="typeOptions"
                  placeholder="Alle Typen"
                  @change="onTypeChange"
              />
            </div>

            <CCol lg="3" md="6" class="mb-3">
              <label class="text-muted mb-2">Datum</label>
              <div class="position-relative d-flex">
                <div class="flex-grow-1">
                  <Datepicker
                    v-model="selectedDate"
                    placeholder="Datum auswählen"
                    input-class="form-control"
                    @change="onDateChange"
                    wrapper-class="date-wrapper"
                  />
                </div>
                <CButton
                    v-if="selectedDate"
                    color="secondary"
                    variant="outline"
                    class="ms-2"
                    @click="resetDateFilter"
                >
                  <CIcon name="cil-x" />
                </CButton>
              </div>
            </CCol>

            <div class="w-15">
              <label class="text-muted mb-2">Einträge</label>
              <CSelect
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  @change="handleItemsPerPageChange"
              />
            </div>
          </div>
        </div>


        <!-- Advanced Search -->
        <CRow v-if="showAdvancedSearch" class="mb-4">
          <CCol>
            <div class="d-flex gap-2">
              <CInput
                  v-model="advancedSearchQuery"
                  placeholder="Erweiterte Suche über alle Daten..."
              >
                <template #prepend-content>
                  <CIcon name="cil-search"/>
                </template>
              </CInput>
              <CButton color="primary" @click="performAdvancedSearch">
                Suche starten
              </CButton>
            </div>
          </CCol>
        </CRow>

        <!-- Content Section -->
        <div v-if="viewMode === 'list'" class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr>
              <th v-for="field in tableFields" :key="field.key">
                {{ field.label }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in tableData" :key="item.id">
              <td>
                <CBadge :color="getTableBadge(item.type)">
                  {{ translateType(item.type) }}
                </CBadge>
              </td>
              <td>
                <div>{{ item.holder.firstName }}</div>
                <div>{{ item.holder.lastName }}</div>
              </td>
              <td>{{ item.holder.phoneNumber }}</td>
              <td>{{ item.holder.email }}</td>
              <td>{{ item.values?.address?.formattedAddress || "Nicht angegeben" }}</td>
              <td>{{ formatCurrency(item.results?.resultAbsolute) }}</td>
              <td>{{ formatDate(item.created) }}</td>
              <td>
                <div class="d-flex gap-2">
                  <CButton
                      :to="'leads/edit/' + item.id"
                      color="primary"
                      variant="ghost"
                      size="sm"
                  >
                    Details
                  </CButton>
                  <CButton
                      @click="deleteLead(item)"
                      color="danger"
                      variant="ghost"
                      size="sm"
                  >
                    <CIcon name="cil-trash"/>
                  </CButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- Kanban Board -->
        <div v-else-if="viewMode === 'kanban'" class="kanban-container">
          <div class="kanban-board">
            <div
              v-for="status in kanbanStatuses"
              :key="status"
              class="kanban-column bg-light rounded p-3"
              :data-status="status"
            >
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h6 class="mb-0">{{ status }}</h6>
                <CBadge color="primary" class="rounded-pill">
                  {{ paginatedKanbanItems(status).length }}
                </CBadge>
              </div>
              <div class="progress mb-3" style="height: 4px">
                <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: getProgressPercentage(status) + '%' }"
                ></div>
              </div>
              <draggable
                  :list="paginatedKanbanItems(status)"
                  class="kanban-items"
                  :group="{ name: 'status', pull: 'clone', put: true }"
                  @end="onDragEnd($event, status)"
              >
                <div
                    v-for="item in paginatedKanbanItems(status)"
                    :key="item.id"
                    :data-id="item.id"
                    class="kanban-item bg-white rounded p-3 mb-3 position-relative"
                    :class="getLeadTypeClass(item.type)"
                >
                  <small class="text-muted d-block mb-2">{{ formatDate(item.created) }}</small>
                  <h6 class="mb-2">
                    {{ item.holder.firstName }} {{ item.holder.lastName }}
                  </h6>
                  <p class="mb-2 text-muted">
                    {{ translateType(item.type) }} in {{ item.values?.address?.city }}
                  </p>
                  <p class="mb-2">
                    {{ formatCurrency(item.results?.resultAbsolute) }}
                  </p>
                  <div class="kanban-actions">
                    <CButton
                        :to="'/leads/edit/' + item.id"
                        color="primary"
                        variant="ghost"
                        size="sm"
                    >
                      <CIcon name="cil-pencil"/>
                    </CButton>
                    <CButton
                        @click.stop="deleteLead(item)"
                        color="danger"
                        variant="ghost"
                        size="sm"
                    >
                      <CIcon name="cil-trash"/>
                    </CButton>
                  </div>
                </div>
              </draggable>
              <CPagination
                v-if="totalKanbanPages[status] > 1"
                :pages="totalKanbanPages[status]"
                :activePage="kanbanPage[status] + 1"
                @update:activePage="(newPage) => handleKanbanPageChange(status, newPage)"
                align="center"
                class="mt-3"
              />
            </div>
          </div>
        </div>

        <!-- Pagination -->
        <CRow v-if="viewMode === 'list'" class="mt-4">
          <CCol class="d-flex justify-content-center">
            <CPagination
              v-if="totalPages > 1"
              :pages="totalPages"
              :activePage="currentPage + 1"
              @update:activePage="pageChanged"
              align="center"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <!-- Loading Spinner -->
    <div v-if="loading" class="loading-overlay">
      <CSpinner color="primary"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import {ref, computed, onMounted, watch} from "vue";
import axios from "axios";
import commons from "../../commons";
import {CButtonGroup} from "@coreui/vue";
import {saveAs} from "file-saver";
import Datepicker from "vuejs-datepicker";
import draggable from "vuedraggable";
import toastPlugin from "@/toastPlugin";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL.replace('/backend', ''),
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request Interceptor für JWT Token
apiClient.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem('jwtToken');
  if (jwtToken) {
    // Token von Anführungszeichen bereinigen
    const cleanToken = jwtToken.replace(/^"(.*)"$/, '$1');
    config.headers.Authorization = `Bearer ${cleanToken}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default {
  name: "Leads",
  components: {
    Datepicker,
    draggable,
    CButtonGroup,
  },

  computed: {
    ...mapState('propstack', [
      'settings',
      'contactTypes',
      'isLoading',
      'error',
      'syncStatus'
    ]),
    ...mapGetters('propstack', [
      'isConnected',
      'canStartSync'
    ]),
    currentRealmId() {
      return this.$commons.getFromStorage('currentRealm').id;
    }
  },

  mounted() {
    this.$nextTick(() => {
      // Force re-render of datepicker
      this.$forceUpdate();
    });
  },

  setup() {
    const tableData = ref([]);
    const searchQuery = ref("");
    const selectedType = ref("");
    const organizationWide = ref(
        commons.getFromStorage("leads_organizationWide", false),
    );
    const itemsPerPage = ref(20);
    const currentPage = ref(0);
    const sortKey = ref("created");
    const sortOrder = ref("desc");
    const totalLeads = ref(0);
    const recentLeads = ref(0);
    const leadsLastWeek = ref(0);
    const leadsLastMonth = ref(0);
    const totalLeadValue = ref(0);
    const averageLeadValueLastMonth = ref(0);
    const loading = ref(false);
    const newLeadsToday = ref(0);
    const averageLeadValue = ref(0);
    const selectedDate = ref(null);
    const quickSearchQuery = ref("");
    const advancedSearchQuery = ref("");
    const showAdvancedSearch = ref(false);
    const viewMode = ref("kanban"); // Kanban als Standardansicht
    const kanbanStatuses = [
      "Neu",
      "In Bearbeitung",
      "Qualifiziert",
      "Abgeschlossen",
    ];
    const handleItemsPerPageChange = async (event) => {
      const value = parseInt(event?.target?.value || 20);
      if (!isNaN(value) && value > 0) {
        itemsPerPage.value = value;
        currentPage.value = 0;
        await loadLeads();
      }
    };

    const resetDateFilter = async () => {
      selectedDate.value = null;
      await loadLeads();  // Lade die Daten neu ohne Datumsfilter
    };

    // Reaktive Objekte für Kanban-Pagination
    const kanbanPage = ref({
      "Neu": 0,
      "In Bearbeitung": 0,
      "Qualifiziert": 0,
      "Abgeschlossen": 0
    });

    const totalKanbanPages = ref({
      "Neu": 1,
      "In Bearbeitung": 1,
      "Qualifiziert": 1,
      "Abgeschlossen": 1
    });

    const typeOptions = [
      {value: "", label: "Alle Typen"},
      {value: "APARTMENT", label: "Wohnung"},
      {value: "HOUSE", label: "Haus"},
      {value: "LAND", label: "Grundstück"},
      {value: "APARTMENT_HOUSE", label: "Mehrfamilienhaus"},
      {value: "RENT_APARTMENT", label: "Mietwohnung"},
      {value: "RENT_HOUSE", label: "Miethaus"},
    ];

    const itemsPerPageOptions = [
      {value: 10, label: "10"},
      {value: 20, label: "20"},
      {value: 50, label: "50"},
      {value: 100, label: "100"},
    ];

    const onTypeChange = (value) => {
      selectedType.value = value;
      currentPage.value = 0;
      loadLeads();
    };

    const getLeadTypeClass = (type) => {
      const classes = {
        APARTMENT: "lead-apartment",
        HOUSE: "lead-house",
        LAND: "lead-land",
        APARTMENT_HOUSE: "lead-apartment-house",
        RENT_APARTMENT: "lead-rent-apartment",
        RENT_HOUSE: "lead-rent-house",
      };
      return classes[type] || "";
    };

    const editLead = (item) => {
      window.location.href = `/leads/edit/${item.id}`;
    };

    const getProgressPercentage = (status) => {
      const total = tableData.value.length;
      const count = paginatedKanbanItems(status).length;
      return total === 0 ? 0 : (count / total) * 100;
    };

    const tableFields = [
      {key: "type", label: "Typ"},
      {key: "holder.fullName", label: "Name"},
      {key: "holder.phoneNumber", label: "Telefon"},
      {key: "holder.email", label: "E-Mail"},
      {key: "values.address.formattedAddress", label: "Anschrift"},
      {key: "results.resultAbsolute", label: "Leadwert"},
      {key: "created", label: "Datum"},
      {key: "actions", label: "Aktionen"},
    ];

    const filterAndPaginateData = (data) => {
      return data
          .filter((item) => {
            const quickSearchLower = quickSearchQuery.value.toLowerCase();
            const advancedSearchLower = advancedSearchQuery.value.toLowerCase();

            const quickSearchMatch =
                item.holder.firstName.toLowerCase().includes(quickSearchLower) ||
                item.holder.lastName.toLowerCase().includes(quickSearchLower) ||
                item.holder.email.toLowerCase().includes(quickSearchLower) ||
                (item.holder.phoneNumber &&
                    item.holder.phoneNumber.includes(quickSearchLower));

            const advancedSearchMatch = advancedSearchLower
                ? JSON.stringify(item).toLowerCase().includes(advancedSearchLower)
                : true;

            const typeMatch = selectedType.value
                ? item.type === selectedType.value
                : true;

            return quickSearchMatch && advancedSearchMatch && typeMatch;
          })
          .slice(
              currentPage.value * itemsPerPage.value,
              (currentPage.value + 1) * itemsPerPage.value,
          );
    };

    const paginatedKanbanItems = (status) => {
      return tableData.value.filter(item => item.status === status);
    };

    const loadLeads = async () => {
      loading.value = true;
      try {
        const response = await axios.get("/lead", {
          params: {
            page: currentPage.value,
            size: Number(itemsPerPage.value),
            organizationWide: organizationWide.value,
            sort: `${sortKey.value},${sortOrder.value}`,
            type: selectedType.value,
            date: selectedDate.value ? formatDateForAPI(selectedDate.value) : null,
            // Wenn Schnellsuche aktiv ist
            search: quickSearchQuery.value || undefined,
          },
        });
        if (response.data) {
          // Direkt die Daten aus der API verwenden
          tableData.value = response.data.content;
          totalLeads.value = response.data.totalElements;
          // Wichtig: Setze die korrekten Paginierungswerte
          currentPage.value = response.data.number; // API page number
        }
      } catch (error) {
        console.error("Problem beim Laden der Leads", error);
        tableData.value = [];
        totalLeads.value = 0;
      } finally {
        loading.value = false;
      }
    };

    const onQuickSearchInput = () => {
      currentPage.value = 0;
      loadLeads();
    };

    const formatDateForAPI = (date) => {
      if (!date) return null;
      return date.toISOString().split("T")[0]; // Format: YYYY-MM-DD
    };

    const onDateChange = async () => {
      currentPage.value = 0;
      await loadLeads();
    };

    const toggleAdvancedSearch = () => {
      showAdvancedSearch.value = !showAdvancedSearch.value;
    };

    const performAdvancedSearch = async () => {
      if (!advancedSearchQuery.value.trim()) {
        toastPlugin.makeToast(
            "Hinweis",
            "Bitte geben Sie einen Suchbegriff ein",
            "warning"
        );
        return;
      }

      loading.value = true;
      try {
        const response = await axios.get("/lead", {
          params: {
            page: 0,
            size: 1000,
            organizationWide: organizationWide.value,
            sort: `${sortKey.value},${sortOrder.value}`,
            search: advancedSearchQuery.value,
            type: selectedType.value,
            date: selectedDate.value ? formatDateForAPI(selectedDate.value) : null,
          },
        });

        if (response.data && Array.isArray(response.data.content)) {
          tableData.value = response.data.content;
          totalLeads.value = response.data.totalElements;

          if (response.data.content.length === 0) {
            toastPlugin.makeToast(
                "Information",
                "Keine Ergebnisse gefunden",
                "info"
            );
          } else {
            toastPlugin.makeToast(
                "Erfolg",
                `${response.data.content.length} Ergebnisse gefunden`,
                "success"
            );
          }
        }
      } catch (error) {
        console.error("Problem bei der erweiterten Suche", error);
        toastPlugin.makeToast(
            "Fehler",
            "Fehler bei der erweiterten Suche. Bitte versuchen Sie es später erneut.",
            "error"
        );
      } finally {
        loading.value = false;
      }
    };

    const updateSummaryData = async () => {
      try {
        const response = await axios.get("/lead/summary", {
          params: {organizationWide: organizationWide.value},
        });

        totalLeads.value = response.data.totalLeads || 0;
        newLeadsToday.value = response.data.newLeadsToday || 0;
        averageLeadValue.value = response.data.averageLeadValue || 0;
        recentLeads.value = response.data.recentLeads || 0;
        leadsLastWeek.value = response.data.leadsLastWeek || 0;
        leadsLastMonth.value = response.data.leadsLastMonth || 0;
        totalLeadValue.value = response.data.totalLeadValue || 0;
        averageLeadValueLastMonth.value = response.data.averageLeadValueLastMonth || 0;

      } catch (error) {
        console.error("Problem beim Laden der Zusammenfassung", error);
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Zusammenfassung', 'error');
      }
    };

    const toggleOrganizationWide = () => {
      organizationWide.value = !organizationWide.value;
      commons.setToStorage("leads_organizationWide", organizationWide.value);
      currentPage.value = 0;
      loadLeads();
      updateSummaryData();
    };

    const exportCSV = async () => {
      try {
        const response = await axios.get("/lead/export", {
          params: {organizationWide: organizationWide.value},
          responseType: "blob",
        });
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, `leads_${new Date().toISOString()}.csv`);
      } catch (error) {
        console.error("Problem beim Exportieren der Leads", error);
        alert(
            "Fehler beim Exportieren der Leads. Bitte versuchen Sie es später erneut.",
        );
      }
    };

    const deleteLead = async (item) => {
      if (
          confirm(
              `Sind Sie sicher, dass Sie den Lead von ${item.holder.firstName} ${item.holder.lastName} löschen möchten?`,
          )
      ) {
        try {
          await axios.delete(`/lead/${item.id}`);
          alert("Lead erfolgreich gelöscht.");
          loadLeads();
          updateSummaryData();
        } catch (error) {
          console.error("Problem beim Löschen des Leads", error);
          alert(
              "Fehler beim Löschen des Leads. Bitte versuchen Sie es später erneut.",
          );
        }
      }
    };

    const getTableBadge = (type) => {
      const badgeColors = {
        APARTMENT: "success",
        HOUSE: "secondary",
        LAND: "warning",
        APARTMENT_HOUSE: "danger",
        RENT_APARTMENT: "info",
        RENT_HOUSE: "info",
      };
      return badgeColors[type] || "primary";
    };

    const translateType = (type) => {
      const translations = {
        APARTMENT: "Wohnung",
        HOUSE: "Haus",
        LAND: "Grundstück",
        APARTMENT_HOUSE: "Mehrfamilienhaus",
        RENT_APARTMENT: "Mietwohnung",
        RENT_HOUSE: "Miethaus",
      };
      return translations[type] || type;
    };

    const formatCurrency = (value) => {
      if (value === undefined || value === null || isNaN(value)) {
        return "-";
      }
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    };

    const formatDate = (dateString) => {
      if (!dateString) return "-";
      return new Date(dateString).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    };

    const sortBy = (key) => {
      if (sortKey.value === key) {
        sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
      } else {
        sortKey.value = key;
        sortOrder.value = "asc";
      }
      loadLeads();
    };

    const pageChanged = async (page) => {
      if (page !== currentPage.value + 1) { // Nur laden wenn sich die Seite wirklich ändert
        currentPage.value = page - 1;
        await loadLeads();
      }
    };
    const handleKanbanPageChange = async (status, newPage) => {
      kanbanPage.value[status] = newPage - 1;
      await loadLeads();
    };

    const totalPages = computed(() => {
      return Math.ceil(totalLeads.value / itemsPerPage.value);
    });

    const onDragEnd = async (event, status) => {
      try {
        const draggedElement = event.item;
        const itemId = draggedElement.getAttribute("data-id");
        const item = tableData.value.find((i) => i.id === parseInt(itemId, 10));

        if (!item) {
          throw new Error("Fehler beim Zugreifen auf das verschobene Item.");
        }

        const targetColumn = event.to.closest('.kanban-column');
        if (!targetColumn) {
          throw new Error("Zielspalte nicht gefunden.");
        }

        const newStatus = targetColumn.getAttribute('data-status');
        if (!newStatus) {
          throw new Error("Der neue Status konnte nicht ermittelt werden.");
        }

        // Update the status of the item
        item.status = newStatus;

        // Make the API call to update the status on the server
        await axios.put(`/lead/${item.id}/status`, { status: newStatus });

        // Aktualisiere die Ansicht
        await loadLeads();

        toastPlugin.makeToast(
            "Erfolg",
            `Status erfolgreich auf "${newStatus}" aktualisiert`
        );
      } catch (error) {
        console.error('Fehler beim Aktualisieren des Status:', error);
        toastPlugin.makeToast(
            "Fehler",
            error.message || "Status konnte nicht aktualisiert werden"
        );

        // Lade die Ansicht neu, um den ursprünglichen Zustand wiederherzustellen
        await loadLeads();
      }
    };

    const zoomIn = () => {
      const kanbanItems = document.querySelectorAll(".kanban-item");
      kanbanItems.forEach((item) => {
        const currentSize = parseFloat(getComputedStyle(item).fontSize);
        item.style.fontSize = `${currentSize * 1.1}px`;
      });
    };

    const zoomOut = () => {
      const kanbanItems = document.querySelectorAll(".kanban-item");
      kanbanItems.forEach((item) => {
        const currentSize = parseFloat(getComputedStyle(item).fontSize);
        item.style.fontSize = `${currentSize * 0.9}px`;
      });
    };

    document.addEventListener("keydown", (event) => {
      const focusedElement = document.activeElement;

      if (event.key === "ArrowRight") {
        const nextElement = focusedElement.nextElementSibling;
        if (nextElement) {
          nextElement.focus();
        }
      }

      if (event.key === "ArrowLeft") {
        const prevElement = focusedElement.previousElementSibling;
        if (prevElement) {
          prevElement.focus();
        }
      }

      if (event.key === "Enter") {
        if (focusedElement.classList.contains("kanban-item")) {
          const itemId = focusedElement.getAttribute("data-id");
          const item = tableData.value.find((i) => i.id == itemId);
        }
      }
    });

      onMounted(() => {
      loadLeads();
      updateSummaryData();
    });

    watch([itemsPerPage, selectedType, selectedDate], () => {
      if (itemsPerPage.value && !isNaN(itemsPerPage.value)) {
        currentPage.value = 0;
        loadLeads();
      }
    });

    return {
      tableData,
      searchQuery,
      selectedType,
      organizationWide,
      itemsPerPage,
      currentPage,
      sortKey,
      sortOrder,
      typeOptions,
      itemsPerPageOptions,
      tableFields,
      totalPages,
      totalLeads,
      newLeadsToday,
      averageLeadValue,
      recentLeads,
      leadsLastWeek,
      leadsLastMonth,
      totalLeadValue,
      averageLeadValueLastMonth,
      loading,
      toggleOrganizationWide,
      exportCSV,
      deleteLead,
      getTableBadge,
      translateType,
      formatCurrency,
      formatDate,
      sortBy,
      pageChanged,
      selectedDate,
      onDateChange,
      onQuickSearchInput,
      performAdvancedSearch,
      quickSearchQuery,
      advancedSearchQuery,
      showAdvancedSearch,
      toggleAdvancedSearch,
      viewMode,
      kanbanStatuses,
      handleKanbanPageChange,
      onDragEnd,
      onTypeChange,
      paginatedKanbanItems,
      getLeadTypeClass,
      getProgressPercentage,
      zoomIn,
      zoomOut,
      editLead,
      kanbanPage,
      totalKanbanPages,
      handleItemsPerPageChange,
      resetDateFilter,
      loadLeads,
    };
  },
};
</script>

<style>
/* Base Styles */
.leads-container {
  min-height: calc(100vh - 150px);
}

/* Filter Styles */
.filter-group {
  margin-bottom: 1rem;
}

.filter-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #768192;
  font-weight: 500;
}

.search-container {
  display: flex;
  gap: 0.5rem;
}

.search-input {
  flex: 1;
}

/* Datepicker Styles */
.date-wrapper {
  position: relative !important;
  width: 100%;
}


.date-wrapper input[readonly],
.date-wrapper input.form-control {
  background-color: #fff !important;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  height: 35px;
}

.date-wrapper input[readonly]:focus {
  border-color: var(--cui-form-focus-border-color);
  box-shadow: 0 0 0 0.25rem rgb(50 31 219 / 25%);
}

/* Verbessere das Styling für den Reset-Button */
.date-filter-container {
  position: relative;
  display: flex;
  align-items: stretch;
}

.date-filter-container .reset-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
}

.reset-button:hover {
  color: var(--cui-danger);
}

button.btn.ms-2.btn-outline-secondary {
  padding: 5px;
  margin-left: 3px;
}

/* Advanced Search Styles */
.advanced-search-container {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.advanced-search-container.collapsed {
  max-height: 0;
  opacity: 0;
}

.advanced-search-container.expanded {
  max-height: 500px;
  opacity: 1;
}

.advanced-search .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.advanced-search .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Kanban Styles */
.kanban-container {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1rem 0;
  margin: 0 -1rem;
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f0f0f0;
}

.kanban-container::-webkit-scrollbar {
  height: 8px;
}

.kanban-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
}

.kanban-container::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px;
}

.kanban-board {
  display: flex;
  gap: 1rem;
  min-height: calc(100vh - 300px);
  padding: 0 1rem;
}

.kanban-column {
  flex: 0 0 300px;
  max-width: 300px;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.kanban-items {
  min-height: 100px;
}

.kanban-item {
  background: white;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: grab;
  padding: 12px;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.kanban-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.kanban-item:active {
  cursor: grabbing;
}

.kanban-item::before {
  content: '⋮⋮';
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.kanban-item:hover::before {
  opacity: 1;
}

/* Lead Type Indicators */
.lead-apartment {
  border-left: 4px solid var(--success);
}

.lead-house {
  border-left: 4px solid var(--primary);
}

.lead-land {
  border-left: 4px solid var(--warning);
}

.lead-apartment-house {
  border-left: 4px solid var(--danger);
}

.lead-rent-apartment {
  border-left: 4px solid var(--info);
}

.lead-rent-house {
  border-left: 4px solid var(--secondary);
}

/* Quick Actions */
.kanban-actions {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.kanban-item:hover .kanban-actions {
  opacity: 1;
}

.quick-actions {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.kanban-item:hover .quick-actions {
  opacity: 1;
}

.quick-actions button {
  background: none;
  border: none;
  font-size: 1.2em;
  color: #6c757d;
  cursor: pointer;
  padding: 2px;
  transition: color 0.3s ease;
}

.quick-actions button:hover {
  color: #007bff;
}

.quick-actions .edit-button,
.quick-actions .delete-button {
  padding: 4px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.quick-actions .edit-button:hover,
.quick-actions .delete-button:hover {
  transform: scale(1.1);
}

.quick-actions .edit-button {
  background-color: rgba(0, 123, 255, 0.1);
  color: #007bff;
}

.quick-actions .delete-button {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

/* Drag and Drop Styles */
.sortable-ghost {
  opacity: 0.5;
  background-color: #e9ecef;
}

.sortable-drag {
  opacity: 0.9;
  transform: rotate(3deg);
}

/* Table Styles */
.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.table th {
  background-color: #f8f9fa;
  font-weight: bold;
  cursor: pointer;
}

.table th:hover {
  background-color: #e9ecef;
}

.table td div {
  white-space: nowrap;
}

.table td div:first-child {
  font-weight: bold;
}

/* Utility Classes */
.gap-2 {
  gap: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .kanban-board {
    flex-direction: column;
    align-items: center;
  }

  .kanban-column {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .search-container {
    flex-direction: column;
  }

  .search-container .btn {
    width: 100%;
  }

  .filter-group {
    margin-bottom: 1.5rem;
  }
}

/* Zoom Controls */
.zoom-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.zoom-controls button {
  margin: 0 5px;
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #ced4da;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 1.2em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.zoom-controls button:hover {
  background-color: #e9ecef;
  color: #212529;
}

/* Pagination */
.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* Selection Prevention */
.kanban-container,
.kanban-board,
.kanban-column,
.kanban-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Allow Text Selection */
.kanban-item .selectable-text,
.kanban-item input,
.kanban-item textarea {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.filter-section {
  border: 1px solid #ebedef;
}

.gap-3 {
  gap: 1rem;
}

.w-15 {
  width: 15%;
}

.date-wrapper {
  width: 100%;
}
.date-wrapper input {
  width: 100%;
  height: 35px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--cui-input-color);
  background-color: var(--cui-input-bg);
  border: 1px solid var(--cui-input-border-color);
  border-radius: var(--cui-input-border-radius);
}

.vdp-datepicker__calendar {
  position: absolute !important;
  z-index: 1000;
  top: 100% !important;
  left: 0 !important;
}

.date-wrapper .vdp-datepicker__calendar {
  width: auto;
  min-width: 100%;
}

.position-relative.d-flex {
  min-height: 35px;
}
</style>
