<template>
  <div class="c-app">
    <TheSidebar />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import TheToaster from "./TheToaster";
import commons from "@/commons";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheToaster,
  },
  mounted() {
    const user = commons.getFromStorage("currentUser");
    window.Canny("identify", {
      appID: process.env.VUE_APP_CANNY_APPID,
      user: {
        // Replace these values with the current user's data
        email: user.email,
        name: user.firstName + " " + user.lastName, // Full name,
        id: user.id,

        // These fields are optional, but recommended:
        // avatarURL: user.avatarURL,
        // created: new Date(user.created).toISOString(),
      },
    });

    // Canny SSO
    var jwt = require("jsonwebtoken");

    var PrivateKey = "72543d1f-ec75-1c30-ea30-c8b1eff6ccdd";

    function createCannyToken(user) {
      var userData = {
        // optional, but preferred avatarURL: user.avatarURL,
        email: user.email,
        id: user.id,
        name: user.firstName + " " + user.lastName,
      };
      return jwt.sign(userData, PrivateKey, { algorithm: "HS256" });
    }

    /* Track a page view */
    if (typeof analytics !== "undefined") {
      analytics.page();

      /* Identify a visitor */
      analytics.identify({
        name: user.firstName + " " + user.lastName,
        email: user.email, // email is required
      });
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
