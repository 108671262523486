
<template>
  <div class="social-media-post-creator">
    <!-- Fortschrittsleiste -->
    <div class="progress-tracker mb-4">
      <div class="progress">
        <div
          class="progress-bar"
          :style="{ width: `${completionProgress}%` }"
          role="progressbar"
          :aria-valuenow="completionProgress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div class="progress-steps">
        <div
          v-for="(step, index) in steps"
          :key="index"
          :class="['step', {
        'active': currentStep >= index,
        'completed': currentStep > index
      }]"
        >
          <div class="step-number">
            <template v-if="currentStep > index">
              <CIcon name="cil-check" class="check-icon"/>
            </template>
            <template v-else>
              {{ index + 1 }}
            </template>
          </div>
          <div class="step-label">{{ step }}</div>
          <div class="step-progress">{{ (index + 1) * 25 }}%</div>
        </div>
      </div>
    </div>

    <!-- Hauptformular -->
    <CCard>
      <CCardBody>
        <!-- Interview Incomplete Overlay -->
        <div v-if="isInterviewIncomplete" class="no-platforms-overlay">
          <div class="no-platforms-content">
            <CIcon name="cil-user" size="xl" class="text-primary mb-3"/>
            <h4>Profil nicht vollständig</h4>
            <p>
              Bevor Sie Social Media Beiträge erstellen können, müssen Sie Ihr Unternehmensprofil
              vervollständigen. Dies hilft uns, optimale Inhalte für Ihre Zielgruppe zu generieren.
            </p>
            <CButton
                color="primary"
                @click="$router.push('/socialMedia/profilesetup')"
            >
              <CIcon name="cil-pencil" class="me-2"/>
              Zum Unternehmensprofil
            </CButton>
          </div>
        </div>
        <!-- Schritt 1: Grundeinstellungen -->
        <section v-show="currentStep === 0" class="step-section">
          <h3 class="section-title">Grundeinstellungen</h3>
          <CRow>
            <CCol sm="12">
              <CInput
                id="internal-name-input"
                label="Beitragstitel"
                v-model="socialMediaPostName"
                placeholder="z.B. Wochenendtipps KW 51"
                :description="descriptions.postName"
              />
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol sm="12">
              <div class="position-relative">
                <SocialMediaSelector
                    v-model="connectionSelected"
                    :description="descriptions.platforms"
                />

                <!-- No Platforms Overlay -->
                <div v-if="!hasPlatforms" class="no-platforms-overlay">
                  <div class="no-platforms-content">
                    <CIcon name="cil-warning" size="xl" class="text-warning mb-3"/>
                    <h4>Keine Social Media Plattformen verbunden</h4>
                    <p>Bevor Sie einen Beitrag erstellen können, müssen Sie mindestens eine Social Media Plattform anbinden.</p>
                    <CButton
                        color="primary"
                        @click="$router.push('/socialMedia/connections')"
                    >
                      <CIcon name="cil-link" class="me-2"/>
                      Plattformen verwalten
                    </CButton>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
          <div class="step-actions mt-4">
            <CButton color="primary" @click="nextStep">Weiter</CButton>
          </div>
        </section>

        <!-- Schritt 2: Content-Erstellung -->
        <section v-show="currentStep === 1" class="step-section">
          <h3 class="section-title">Content erstellen</h3>

          <!-- Content-Erstellung Modi -->
          <div class="content-creation-mode mb-4">
            <CButtonGroup>
              <CButton
                :color="ai.active ? 'primary' : 'secondary'"
                @click="toggleAiMode(true)"
                class="px-4"
              >
                <CIcon name="cibProbot" class="me-2"/>
                Mit KI erstellen
              </CButton>
              <CButton
                :color="!ai.active ? 'primary' : 'secondary'"
                @click="toggleAiMode(false)"
                class="px-4"
              >
                <CIcon name="cil-pencil" class="me-2"/>
                Manuell erstellen
              </CButton>
            </CButtonGroup>
          </div>

          <div v-if="ai.active">
            <div class="prompt-section mb-4">
              <h4>Beschreiben Sie Ihr Anliegen</h4>
              <p class="text-muted mb-3">
                Beschreiben Sie kurz, worüber Sie einen Social Media Beitrag erstellen möchten.
                Die KI wird daraus einen optimierten Beitrag generieren.
              </p>
              <CTextarea
                v-model="socialMediaPostMainText"
                rows="3"
                placeholder="Beispiel: 'Erstelle einen Post über eine neugelistete 3-Zimmer Wohnung in bester Lage. Die Wohnung hat 85m², einen Balkon und einen Tiefgaragenstellplatz. Der Preis liegt bei 389.000€.'"
              />
            </div>

            <CRow>
              <CCol sm="12" md="6">
                <KeywordsInput
                  v-model="ai.keywords"
                  :options="ai.options"
                  :description="descriptions.keywords"
                />
              </CCol>
              <CCol sm="12" md="6">
                <CSelect
                  label="Tonalität"
                  v-model="ai.voiceStyle"
                  :options="voiceStyleOptions"
                  :description="descriptions.voiceStyle"
                />
              </CCol>
            </CRow>

            <!-- KI-Vorschläge -->
            <div v-if="ai.generatedContent" class="ai-suggestions mt-4">
              <div class="suggestion-card">
                <div class="suggestion-header">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h4 class="mb-0">Generierter Beitrag</h4>
                    <CButton
                      color="primary"
                      variant="outline"
                      size="sm"
                      @click="generateByAi"
                    >
                      <CIcon name="cil-loop-circular" class="me-2"/>
                      Alternative generieren
                    </CButton>
                  </div>
                  <div class="platform-badges mb-3">
                    <CBadge
                      v-for="platform in connectionSelected"
                      :key="platform.id"
                      color="info"
                      class="me-2"
                    >
                      {{ platform.title }}
                    </CBadge>
                  </div>
                </div>

                <div class="suggestion-content">
                  <div class="mb-4">
                    <strong>Titel:</strong>
                    <CInput
                      v-model="ai.generatedContent.title"
                      class="mt-2"
                    />
                  </div>
                  <div class="mb-4">
                    <strong>Beitragstext:</strong>
                    <CTextarea
                      v-model="ai.generatedContent.content"
                      rows="4"
                      class="mt-2"
                    />
                  </div>
                  <div v-if="ai.generatedContent.cta" class="mb-4">
                    <strong>Call to Action:</strong>
                    <CInput
                      v-model="ai.generatedContent.cta"
                      class="mt-2"
                    />
                  </div>
                  <div class="mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <strong>Empfohlener Veröffentlichungszeitpunkt:</strong>
                        <p class="mt-2 mb-0">
                          {{ ai.generatedContent.bestDay }} um {{ ai.generatedContent.bestTime }} Uhr
                        </p>
                      </div>
                      <CButton
                        color="primary"
                        variant="outline"
                        size="sm"
                        @click="togglePublishTimeEditor"
                      >
                        <CIcon :name="showPublishTimeEditor ? 'cil-x' : 'cil-pencil'" class="me-2"/>
                        {{ showPublishTimeEditor ? 'Zeitpunkt ausblenden' : 'Zeitpunkt anpassen' }}
                      </CButton>
                    </div>
                  </div>

                  <!-- Zeitpunkt-Editor mit Label -->
                  <div v-if="showPublishTimeEditor" class="publish-time-editor mt-3">
                    <h5 class="mb-3">Veröffentlichungszeitpunkt anpassen</h5>
                    <DateTimePicker
                      v-model="publishAt"
                      :horizontal="false"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Hauptaktionen -->
            <div class="step-actions mt-4">
              <CButton
                color="secondary"
                @click="previousStep"
              >
                Zurück
              </CButton>

              <template v-if="!ai.generatedContent">
                <CButton
                  color="primary"
                  size="lg"
                  :disabled="ai.processing"
                  @click="generateByAi"
                >
                  <template v-if="ai.processing">
                    <DoubleBounceLoader class="me-2" />
                    Generiere Beitrag...
                  </template>
                  <template v-else>
                    <CIcon name="cil-media-play" class="me-2"/>
                    Beitrag generieren
                  </template>
                </CButton>
              </template>

              <template v-else>
                <CButton
                  color="success"
                  size="lg"
                  @click="applyAndContinue"
                >
                  <CIcon name="cil-arrow-right" class="me-2"/>
                  Weiter zur Bildauswahl
                </CButton>
              </template>
            </div>
          </div>

          <!-- Manueller Modus -->
          <div v-else class="manual-content-section">
            <CTextarea
              label="Beitragstext"
              v-model="socialMediaPostMainText"
              rows="4"
              placeholder="Verfassen Sie hier Ihren Beitragstext..."
            />
            <div class="step-actions mt-4">
              <CButton color="secondary" @click="previousStep">Zurück</CButton>
              <CButton color="primary" @click="nextStep">Weiter</CButton>
            </div>
          </div>
        </section>

        <!-- Schritt 3: Medien-Auswahl -->
        <section v-show="currentStep === 2" class="step-section">
          <h3 class="section-title">Bild hinzufügen</h3>
          <ImageUploadAndSearch
            :firstKeyword="getFirstKeyword"
            @image-upload="handleImageUpload"
            @image-select="handleImageSelect"
            @image-remove="handleImageRemove"
            @add-attribution="handleAddAttribution"
          />
          <div class="step-actions mt-4">
            <CButton color="secondary" @click="previousStep">Zurück</CButton>
            <CButton color="primary" @click="nextStep">Weiter</CButton>
          </div>
        </section>

        <!-- Vorschau-Sektion -->
        <section v-show="currentStep === 3" class="step-section">
          <h3 class="section-title">Vorschau und Veröffentlichung</h3>
          <div class="preview-card">
            <h4 class="mb-4">Beitragsvorschau</h4>

            <!-- LinkedIn Vorschau -->
            <div v-if="isLinkedIn" class="social-preview linkedin-preview">
              <div class="social-preview-header">
                <div class="profile-info">
                  <!-- Logo mit Error-Handling -->
                  <img
                      :src="getCompanyLogo"
                      class="company-logo"
                      alt="Company Logo"
                      @error="handleImageError"
                  />
                  <div class="profile-text">
                    <div class="profile-name">{{ connectionSelected[0].name }}</div>
                    <div class="post-meta">
                      <span>1s</span> • <span><CIcon name="cil-globe-alt" /> Öffentlich</span>
                    </div>
                  </div>
                </div>
                <div class="linkedin-more">
                  <CIcon name="cil-options" />
                </div>
              </div>

              <div class="post-content">
                <p class="post-text">{{ socialMediaPostMainText }}</p>

                <div v-if="socialMediaImage" class="post-image">
                  <img :src="socialMediaImage" alt="Post Image" />
                </div>

                <div v-if="socialMediaPostCTA" class="post-cta">
                  {{ socialMediaPostCTA }}
                </div>
              </div>

              <div class="social-preview-footer">
                <div class="interaction-buttons">
                  <button class="interaction-btn">
                    <CIcon name="cib-linkedin" /> Gefällt mir
                  </button>
                  <button class="interaction-btn">
                    <CIcon name="cil-comment-square" /> Kommentieren
                  </button>
                  <button class="interaction-btn">
                    <CIcon name="cil-share" /> Teilen
                  </button>
                </div>
              </div>
            </div>

            <!-- Facebook Vorschau -->
            <div v-if="isFacebook" class="social-preview facebook-preview">
              <div class="social-preview-header">
                <div class="profile-info">
                  <img :src="getCompanyLogo" class="company-logo" alt="Company Logo" />
                  <div class="profile-text">
                    <div class="profile-name">{{ connectionSelected[0].name }}</div>
                    <div class="post-meta">
                      <span>Jetzt</span> • <CIcon name="cil-globe-alt" />
                    </div>
                  </div>
                </div>
                <div class="facebook-more">
                  <CIcon name="cil-options" />
                </div>
              </div>

              <div class="post-content">
                <p class="post-text">{{ socialMediaPostMainText }}</p>

                <div v-if="socialMediaImage" class="post-image">
                  <img :src="socialMediaImage" alt="Post Image" />
                </div>

                <div v-if="socialMediaPostCTA" class="post-cta facebook-cta">
                  {{ socialMediaPostCTA }}
                </div>
              </div>

              <div class="social-preview-footer">
                <div class="interaction-buttons">
                  <button class="interaction-btn">
                    <CIcon name="cil-thumb-up" /> Gefällt mir
                  </button>
                  <button class="interaction-btn">
                    <CIcon name="cil-comment-square" /> Kommentieren
                  </button>
                  <button class="interaction-btn">
                    <CIcon name="cil-share" /> Teilen
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="step-actions mt-4">
            <CButton color="secondary" @click="previousStep">Zurück</CButton>
            <CButton
              color="success"
              @click="publishPost"
              :disabled="!canPublish"
            >
              Beitrag veröffentlichen
            </CButton>
          </div>
        </section>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import {
  CInput,
  CTextarea,
  CSelect,
  CButton,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CButtonGroup,
  CBadge
} from '@coreui/vue';
import SocialMediaSelector from '@/components/SocialMediaSelector.vue';
import ImageUploadAndSearch from '@/components/ImageUploadAndSearch.vue';
import DateTimePicker from '@/components/DateTimePicker.vue';
import KeywordsInput from '@/components/KeywordsInput.vue';
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader.vue';
import commons from '@/commons';

export default {
  name: 'SocialMediaPostForm',

  components: {
    CInput,
    CTextarea,
    CSelect,
    CButton,
    CRow,
    CCol,
    CCard,
    CCardBody,
    CButtonGroup,
    CBadge,
    SocialMediaSelector,
    ImageUploadAndSearch,
    DateTimePicker,
    KeywordsInput,
    DoubleBounceLoader
  },

  props: {
    passedData: {
      type: Object,
      default: () => ({ id: 0 }),
      description: 'Existierende Post-Daten für die Bearbeitung'
    },
    companyLogo: {
      type: String,
      default: null,
      description: 'URL zum Firmenlogo'
    },
    btnText: {
      type: String,
      default: 'Speichern',
      description: 'Text für den Submit-Button'
    },
    enableDelete: {
      type: Boolean,
      default: false,
      description: 'Aktiviert die Lösch-Option'
    },
    processing: {
      type: Boolean,
      default: false,
      description: 'Loading-Status der Komponente'
    }
  },

  data() {
    return {
      currentStep: 0,
      steps: [
        'Grundeinstellungen',
        'Content erstellen',
        'Bild hinzufügen',
        'Vorschau'
      ],
      descriptions: {
        postName: 'Interner Name für die Verwaltung des Beitrags',
        platforms: 'Wählen Sie die Plattformen für diesen Beitrag',
        keywords: 'Zusätzliche Stichwörter für die KI-Generierung',
        voiceStyle: 'Bestimmen Sie den Ton Ihres Beitrags',
        aiPrompt: 'Beschreiben Sie, worüber die KI einen Beitrag erstellen soll'
      },
      voiceStyleOptions: [
        { label: 'Energisch', value: 'ENERGETIC' },
        { label: 'Aufregend', value: 'EXCITING' },
        { label: 'Enthusiastisch', value: 'ENTHUSIASTIC' },
        { label: 'Freundlich', value: 'FRIENDLY' },
        { label: 'Informativ', value: 'INFORMATIONAL' },
        { label: 'Lustig', value: 'FUNNY' },
        { label: 'Professionell', value: 'PROFESSIONAL' },
        { label: 'Lässig', value: 'CASUAL' }
      ],
      isProcessing: false,
      isInterviewIncomplete: false,
      id: 0,
      connectionSelected: [],
      socialMediaPostName: '',
      socialMediaPostHeadline: '',
      socialMediaPostMainText: '',
      socialMediaPostCTA: '',
      socialMediaPostAdditionalInfo: '',
      socialMediaImage: '',
      publishAt: moment().add(2, 'days').toISOString(),
      ai: {
        active: true,
        processing: false,
        keywords: [],
        options: [
          'Immobilienbewertung',
          'Wohnungsverkauf',
          'Hauskaufberatung',
          'Grundstückssuche',
          'Exklusive Immobilien',
          'Neubauprojekte',
          'Marktanalyse',
          'Investorenberatung',
          'Baufinanzierung',
          'Luxusimmobilien',
          'Immobilienentwicklung',
          'Immobilienmakler'
        ].map(str => ({ name: str })),
        voiceStyle: 'ENERGETIC',
        generatedContent: null,
        synonym: ''
      },
      showPublishTimeEditor: false,
      validationErrors: {
        platforms: false,
        content: false
      },
      defaultLogo: '/img/brand/immobilien.svg',
      realmLogo: null
    };
  },

  computed: {
    getCompanyLogo() {
      return this.companyLogo || this.realmLogo || this.defaultLogo;
    },
    hasPlatforms() {
      return this.connectionSelected?.length > 0;
    },
    isLinkedIn() {
      return this.connectionSelected[0]?.socialMedia === 'LINKEDIN';
    },
    isFacebook() {
      return this.connectionSelected[0]?.socialMedia === 'FACEBOOK';
    },
    completionProgress() {
      return (this.currentStep / (this.steps.length - 1)) * 100;
    },
    canPublish() {
      return this.socialMediaPostName &&
          this.socialMediaPostMainText &&
          this.connectionSelected.length > 0;
    },
    getContentPlaceholder() {
      return this.ai.active
          ? 'KI generiert Vorschläge basierend auf Ihren Eingaben...'
          : 'Verfassen Sie hier Ihren Beitragstext...';
    },
    getFirstKeyword() {
      return this.ai.generatedContent?.synonym || null;
    },
    isFormValid() {
      return !Object.values(this.validationErrors).some(error => error);
    }
  },

  watch: {
    dataOptions: {
      handler(newOptions) {
        console.log('Data options updated:', newOptions);
      },
      deep: true
    },
    passedData: {
      immediate: true,
      handler(newData) {
        if (newData && newData.id) {
          this.id = newData.id;
          this.loadExistingPost(newData.id);
        }
      }
    },
    'ai.keywords'(newVal) {
      if (newVal.length >= 2) {
        this.validationErrors.content = false;
      }
    },
    connectionSelected(newVal) {
      this.validationErrors.platforms = newVal.length === 0;
    }
  },

  async created() {
    try {
      const currentRealm = commons.getCurrentRealm();
      if (currentRealm?.logoUrl) {
        this.realmLogo = currentRealm.logoUrl;
      }
    } catch (error) {
      console.error('Fehler beim Laden des Realm-Logos:', error);
    }

    if (this.passedData?.id) {
      await this.loadExistingPost(this.passedData.id);
    }
  },

  mounted() {
    this.checkCustomerProfile();
    const publishAtParam = this.$route.query.publishAt;
    if (publishAtParam) {
      this.publishAt = publishAtParam;
    }
  },

  methods: {
    handleImageError(e) {
      e.target.src = this.defaultLogo;
      console.warn('Logo konnte nicht geladen werden, verwende Standard-Logo');
    },
    async checkCustomerProfile() {
      try {
        const realmId = commons.getCurrentRealm().id;
        const response = await axios.get(`/socialMedia/profile/completed?realmId=${realmId}`);
        this.isInterviewIncomplete = !response.data;
      } catch (error) {
        console.error('Error checking customer profile:', error);
        this.$toaster.makeToast(
            'Fehler',
            'Profilstatus konnte nicht überprüft werden.'
        );
        // Set to true on error to prevent form usage until status is confirmed
        this.isInterviewIncomplete = true;
      }
    },
    applyAndContinue() {
      if (!this.ai.generatedContent) return;

      // Speichere den editierten Content für die weitere Verarbeitung
      this.socialMediaPostMainText = this.ai.generatedContent.content;
      this.socialMediaPostHeadline = this.ai.generatedContent.title;
      this.socialMediaPostCTA = this.ai.generatedContent.cta;

      // Setze den Zeitpunkt
      if (this.publishAt) {
        this.socialMediaPostSchedule = this.publishAt;
      }

      // Gehe zum nächsten Schritt (Bildauswahl)
      this.nextStep();
    },
    togglePublishTimeEditor() {
      this.showPublishTimeEditor = !this.showPublishTimeEditor;
    },
    async loadExistingPost(id) {
      try {
        const { data } = await axios.get(`/socialMedia/posts/${id}`);
        this.socialMediaPostName = data.name;
        this.socialMediaPostMainText = data.content;
        this.socialMediaImage = data.imageUrl;
        this.publishAt = data.publishAt;
        this.connectionSelected = data.connection;
      } catch (error) {
        this.$toaster.makeToast(
          'Fehler',
          'Der Beitrag konnte nicht geladen werden.'
        );
        console.error('Error loading post:', error);
      }
    },

    nextStep() {
      if (this.validateCurrentStep()) {
        if (this.currentStep < this.steps.length - 1) {
          this.currentStep++;
        }
      }
    },

    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },

    validateCurrentStep() {
      switch(this.currentStep) {
        case 0:
          if (!this.socialMediaPostName) {
            this.$toaster.makeToast('Fehler', 'Bitte geben Sie einen Beitragstitel ein.');
            return false;
          }
          if (this.connectionSelected.length === 0) {
            this.$toaster.makeToast('Fehler', 'Bitte wählen Sie mindestens eine Plattform aus.');
            return false;
          }
          break;
        case 1:
          // Nur validieren wenn wir im AI Modus sind UND noch keinen Text haben
          if (this.ai.active && !this.socialMediaPostMainText && !this.ai.processing && !this.ai.generatedContent) {
            this.$toaster.makeToast('Fehler', 'Bitte geben Sie einen Beitragstext ein oder generieren Sie einen mit KI.');
            return false;
          }
          // Wenn wir im manuellen Modus sind, nur Text prüfen
          if (!this.ai.active && !this.socialMediaPostMainText) {
            this.$toaster.makeToast('Fehler', 'Bitte geben Sie einen Beitragstext ein.');
            return false;
          }
          break;
      }
      return true;
    },

    toggleAiMode(active) {
      this.ai.active = active;
      // Reset AI-spezifische Felder wenn manueller Modus aktiviert
      if (!active) {
        this.ai.keywords = [];
        this.ai.generatedContent = null;
        this.ai.processing = false;
        this.validationErrors.content = false; // Reset der Validierungsfehler
      } else {
        // Optional: Setze Standard-Keywords wenn KI-Modus aktiviert
        if (this.ai.keywords.length === 0) {
          // Zum Beispiel die ersten beiden Options als Standard-Keywords
          this.ai.keywords = this.ai.options.slice(0, 2);
        }
      }
    },

    async generateByAi() {
      if (this.ai.processing) return;

      if (!this.validateAiInputs()) return;

      this.ai.processing = true;
      const primaryConnection = this.connectionSelected[0];

      try {
        const { data } = await axios.post('/socialMedia/generateByAi', {
          useEmojis: true,
          voiceStyle: this.ai.voiceStyle,
          keywords: this.ai.keywords.map(k => k.name),
          mainContent: this.socialMediaPostMainText,
          targetPlatform: primaryConnection.socialMedia
        });

        const generatedContent = data.generatedContents[0];
        this.ai.generatedContent = this.processGeneratedContent(generatedContent);

        // Setze das Synonym für die Bildsuche
        if (this.ai.generatedContent.synonym) {
          this.ai.synonym = this.ai.generatedContent.synonym;
        }

        // Scroll zum generierten Content
        this.$nextTick(() => {
          const element = document.querySelector('.ai-suggestions');
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        });

      } catch (error) {
        this.handleGenerationError(error);
      } finally {
        this.ai.processing = false;
      }
    },

    validateAiInputs() {
      // Nur validieren wenn wirklich ein Content generiert werden soll
      if (!this.socialMediaPostMainText) {
        this.$toaster.makeToast('Fehler', 'Bitte beschreiben Sie, worüber die KI einen Beitrag erstellen soll.');
        return false;
      }
      if (this.connectionSelected.length === 0) {
        this.$toaster.makeToast('Fehler', 'Bitte wählen Sie mindestens eine Plattform aus.');
        return false;
      }
      // Keywords sind optional - KI kann auch ohne arbeiten
      return true;
    },

    processGeneratedContent(content) {
      return {
        title: this.removeQuotes(content.title || ''),
        content: content.content || '',
        bestDay: content.bestDay || '',
        bestTime: content.bestTime || '',
        cta: this.removeQuotes(content.postFrequencySuggestion || ''),
        keywords: content.keywords || [],
        synonym: content.synonym || '',
        isInterviewIncomplete: false,
      };
    },

    handleGenerationError(error) {
      console.error('Fehler bei der KI-Generierung:', error);
      this.$toaster.makeToast(
        'Fehler',
        'Der Content konnte nicht generiert werden: ' +
        (error.response?.data.message || error.message)
      );
    },

    applyAiContent() {
      if (!this.ai.generatedContent) return;

      const content = this.ai.generatedContent;

      this.socialMediaPostMainText = content.content;
      this.socialMediaPostHeadline = content.title;
      this.socialMediaPostCTA = content.cta;

      // Setze den Zeitpunkt direkt ohne Computed Property
      if (content.bestDay && content.bestTime) {
        this.publishAt = this.calculatePublishDate(
          content.bestDay,
          content.bestTime
        );
      }

      // Nutze das Synonym für die Bildsuche
      if (content.synonym) {
        this.ai.synonym = content.synonym;
      }

      this.$toaster.makeToast(
        'Erfolg',
        'KI-Content wurde erfolgreich übernommen!'
      );
    },

    calculatePublishDate(bestDay, bestTime) {
      try {
        const today = moment();
        const [hours, minutes] = bestTime.split(':');
        const targetDay = this.getDayOfWeek(bestDay);

        let nextDate = moment();

        if (today.isoWeekday() === targetDay) {
          const targetTime = moment().set({ hours, minutes, seconds: 0 });
          nextDate = targetTime.isAfter(today) ? targetTime : targetTime.add(1, 'weeks');
        } else {
          nextDate = today.isoWeekday() < targetDay
            ? today.isoWeekday(targetDay)
            : today.add(1, 'weeks').isoWeekday(targetDay);
          nextDate.set({ hours, minutes, seconds: 0 });
        }

        return nextDate.format('YYYY-MM-DD HH:mm');
      } catch (error) {
        console.error('Fehler bei der Datumsberechnung:', error);
        return moment().add(1, 'days').format('YYYY-MM-DD HH:mm');
      }
    },

    getDayOfWeek(day) {
      const daysOfWeek = {
        'Montag': 1,
        'Dienstag': 2,
        'Mittwoch': 3,
        'Donnerstag': 4,
        'Freitag': 5,
        'Samstag': 6,
        'Sonntag': 7
      };
      return daysOfWeek[day] || moment().isoWeekday();
    },

    handleImageUpload(imageUrl) {
      this.socialMediaImage = imageUrl;
      this.$toaster.makeToast(
        'Erfolg',
        'Das Bild wurde erfolgreich hochgeladen.'
      );
    },

    handleImageSelect(imageUrl) {
      this.socialMediaImage = imageUrl;
      this.$emit("image-select", imageUrl);
    },

    // Neue Methode für die Attribution
    handleAddAttribution(attribution) {
      // Aktuelle Hashtags extrahieren
      const hashtags = this.socialMediaPostMainText.match(/#\w+/g) || [];
      // Text ohne Hashtags
      const mainText = this.socialMediaPostMainText.replace(/#\w+/g, '').trim();

      // Neuen Text zusammensetzen: Haupttext + Attribution + Hashtags
      this.socialMediaPostMainText = mainText +
        attribution +
        (hashtags.length ? '\n\n' + hashtags.join(' ') : '');
    },

    handleImageRemove() {
      this.socialMediaImage = '';
      this.$toaster.makeToast(
        'Erfolg',
        'Das Bild wurde erfolgreich entfernt.'
      );
    },

    removeQuotes(text) {
      return text ? text.replace(/^["'](.*)["']$/, '$1') : '';
    },

    formatDateTime(datetime) {
      return moment(datetime).format('DD.MM.YYYY HH:mm');
    },

    async publishPost() {
      if (!this.canPublish || this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      const postData = {
        name: this.socialMediaPostName,
        publishAt: this.publishAt,
        imageUrl: this.socialMediaImage,
        content: this.socialMediaPostMainText,
        connection: this.connectionSelected,
        approved: true
      };

      try {
        await axios.post('/socialMedia/posts', postData);
        this.$toaster.makeToast(
          'Erfolg',
          `Beitrag wurde erfolgreich für ${this.connectionSelected.length} Plattform(en) angelegt!`
        );
        this.$router.push({
          name: 'SocialMediaPosts',
          params: { saved: '1' }
        });
      } catch (error) {
        this.handlePublishError(error);
      } finally {
        this.isProcessing = false;
      }
    },

    handlePublishError(error) {
      console.error('Fehler beim Veröffentlichen:', error);
      const errorMessage = error.response?.data.message || error.message;
      this.$toaster.makeToast(
        'Fehler',
        `Der Beitrag konnte nicht veröffentlicht werden: ${errorMessage}`
      );
    },

    deletePost() {
      this.$modal.showModal(
        'delete',
        null,
        [`<b>Social Media Beitrag</b> (${this.socialMediaPostName})`],
        async () => {
          try {
            await axios.delete(`/socialMedia/posts/${this.id}`);
            this.$toaster.makeToast(
              'Erfolg',
              'Der Beitrag wurde erfolgreich gelöscht.'
            );
            this.$router.push({
              name: 'SocialMediaPosts',
              params: { saved: '1' }
            });
          } catch (error) {
            this.$toaster.makeToast(
              'Fehler',
              'Der Beitrag konnte nicht gelöscht werden.'
            );
          }
        }
      );
    }
  }
};
</script>

<style scoped>
.no-platforms-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white; /* Änderung von rgba(255, 255, 255, 0.95) zu solidem Weiß */
  border-radius: 8px;
  border: 2px dashed #e4e7ea;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  z-index: 10;
  /* Optional: Leichter Schatten für bessere Abgrenzung */
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.no-platforms-content {
  max-width: 400px;
  /* Optionale Verbesserungen für bessere Lesbarkeit */
  background: white;
  padding: 1rem;
}

.no-platforms-content h4 {
  margin-bottom: 1rem;
  color: #321fdb;
  /* Optional: Stärkerer Kontrast für die Überschrift */
  font-weight: 600;
}

.no-platforms-content p {
  color: #768192;
  margin-bottom: 1.5rem;
  /* Optional: Etwas dunklere Textfarbe für besseren Kontrast */
  line-height: 1.5;
}

.no-platforms-content h4 {
  margin-bottom: 1rem;
  color: #321fdb;
}

.no-platforms-content p {
  color: #768192;
  margin-bottom: 1.5rem;
}
.company-logo {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 8px;
  object-fit: cover;     /* Bessere Bildanpassung */
  background: #f8f9fa;   /* Hintergrund während des Ladens */
}

/* Verbesserte Vorschau-Container */
.social-preview {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 1rem auto;
  max-width: 550px;
  overflow: hidden;      /* Verhindert Overflow von Kindelementen */
}

/* LinkedIn spezifisches Styling */
.linkedin-preview {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
  border: 1px solid #e0e0e0;
}

.linkedin-preview .social-preview-header {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.linkedin-preview .company-logo {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 8px;
}

.linkedin-preview .profile-name {
  font-weight: 600;
  color: #000;
  font-size: 14px;
}

.linkedin-preview .post-meta {
  color: #666;
  font-size: 12px;
}

.linkedin-preview .post-content {
  padding: 0 16px;
}

.linkedin-preview .post-text {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 12px;
}

.linkedin-preview .post-image {
  margin: 0 -16px;
}

.linkedin-preview .post-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

/* Facebook spezifisches Styling */
.facebook-preview {
  font-family: Helvetica, Arial, sans-serif;
  border: 1px solid #dddfe2;
}

.facebook-preview .social-preview-header {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.facebook-preview .company-logo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 8px;
}

.facebook-preview .profile-name {
  font-weight: 600;
  color: #1c1e21;
  font-size: 14px;
}

.facebook-preview .post-meta {
  color: #65676b;
  font-size: 12px;
}

.facebook-preview .post-content {
  padding: 0 12px;
}

.facebook-preview .post-text {
  font-size: 15px;
  line-height: 1.3333;
  margin-bottom: 12px;
}

.post-text {
  white-space: pre-line;  /* Respektiert Zeilenumbrüche */
  word-wrap: break-word;  /* Verhindert horizontales Scrollen */
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 12px;
  max-width: 100%;        /* Verhindert Overflow */
  overflow-wrap: break-word; /* Moderne Alternative zu word-wrap */
}

.facebook-preview .post-image {
  margin: 0 -12px;
}

.facebook-preview .post-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

/* Gemeinsame Styles für die Interaktions-Buttons */
.social-preview-footer {
  padding: 4px 16px 12px;
  border-top: 1px solid #e0e0e0;
  margin-top: 12px;
}

.interaction-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
}

.interaction-btn {
  background: none;
  border: none;
  color: #65676b;
  font-size: 14px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.interaction-btn:hover {
  background-color: #f2f2f2;
  border-radius: 4px;
}

.profile-info {
  display: flex;
  align-items: flex-start;
}

.post-cta {
  font-weight: 600;
  color: #0a66c2;  /* LinkedIn Blau */
  margin-top: 8px;
}

.facebook-cta {
  color: #1877f2;  /* Facebook Blau */
}

.social-media-post-creator {
  max-width: 1200px;
  margin: 0 auto;
}

.progress-tracker {
  position: relative;
  padding: 20px 0;
  margin-bottom: 2rem;
}

.progress {
  height: 4px;
  background-color: #e9ecef;
  margin-bottom: 30px;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #321fdb;
  transition: width 0.4s ease;
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 10px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 120px;
  text-align: center;
}

.step-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}

.step-actions .btn-lg {
  margin-left: auto; /* Schiebt den Button nach rechts */
}

.step-number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

.step.active .step-number {
  background-color: #321fdb;
  border-color: #321fdb;
  color: white;
}

.step.completed .step-number {
  background-color: #2eb85c;
  border-color: #2eb85c;
  color: white;
}

.check-icon {
  width: 16px;
  height: 16px;
}

.step-label {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 4px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.step.active .step-label {
  color: #321fdb;
  font-weight: 600;
}

.step.completed .step-label {
  color: #2eb85c;
}

.step-progress {
  font-size: 12px;
  color: #adb5bd;
  font-weight: 500;
}

.step.active .step-progress {
  color: #321fdb;
}

.step.completed .step-progress {
  color: #2eb85c;
}

/* Verbindungslinien zwischen den Schritten */
.progress-steps::before {
  content: '';
  position: absolute;
  top: 16px; /* Hälfte der step-number Höhe */
  left: 60px;
  right: 60px;
  height: 2px;
  background-color: #e9ecef;
  z-index: 1;
}

/* Hover-Effekte */
.step:not(.active):hover .step-number {
  transform: scale(1.1);
  box-shadow: 0 0 0 4px rgba(50, 31, 219, 0.1);
}

/* Animation für den Übergang */
.step-number,
.step-label,
.step-progress {
  transition: all 0.3s ease;
}

@keyframes checkmark {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.check-icon {
  animation: checkmark 0.3s ease-in-out;
}

.content-creation-mode {
  text-align: center;
  margin-bottom: 2rem;
}

.prompt-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid #e9ecef;
}

.prompt-section h4 {
  color: #321fdb;
  margin-bottom: 1rem;
}

.suggestion-card {
  background: #fff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.publish-time-editor {
  background-color: #fff;
  border-radius: 6px;
  padding: 1.5rem;
  border: 1px solid #e9ecef;
  margin-top: 1rem;
}

.btn-success.btn-lg {
  padding-left: 2rem;
  padding-right: 2rem;
}

.publish-time-editor h5 {
  color: #321fdb;
  margin-bottom: 1rem;
}

.suggestion-header {
  margin-bottom: 1rem;
}

.suggestion-header .btn-outline-primary {
  border-width: 2px;
  font-weight: 500;
}

.btn-outline-primary:hover {
  background-color: #321fdb;
  border-color: #321fdb;
  color: white;
}

.suggestion-content {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 1.5rem;
}

.suggestion-content .form-control {
  background-color: #fff;
}

.preview-card {
  background: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
}

.preview-header {
  margin-bottom: 1rem;
}

.preview-image img {
  max-width: 100%;
  border-radius: 4px;
  margin: 1rem 0;
}

.preview-content {
  padding: 1rem 0;
}

.cta-text {
  color: #321fdb;
  font-weight: 500;
}

.preview-meta {
  color: #666;
  margin-top: 1rem;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}
.no-platforms-overlay {
  z-index: 1000; /* Ensure it's above other content */
}

.no-platforms-content {
  animation: fadeIn 0.3s ease-out;
}

.no-platforms-content .icon-xl {
  font-size: 48px;
  margin-bottom: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
