<template>
  <LgcModule
    class="lgc-module-text"
    @delete="$emit('delete', $event)"
    @add-below="$emit('add-below', $event)"
  >
    <p @input="inputEvent" contentEditable="true" v-text="dataValue"></p>
  </LgcModule>
</template>

<script>
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";
import _ from "lodash";

export default {
  name: "LgcModuleText",
  components: { LgcModule },
  props: {
    value: String,
  },
  data() {
    return {
      dataValue: this.value,
    };
  },
  methods: {
    inputEvent(e) {
      this.$emit("input", { value: _.trim(e.target.innerText) });
    },
  },
};
</script>

<style lang="scss" scoped>
.lgc-module-text {
  p {
    margin: 0;
  }
}
</style>
