<template>
  <div class="form-group form-row" :key="name">
    <CCol tag="label" :sm="horizontal ? 3 : 12" class="col-form-label">
      {{ label }}
      <small
        v-if="description && !horizontal"
        class="form-text text-muted w-100"
        v-text="description"
      />
    </CCol>
    <CCol :sm="horizontal ? 9 : 12">
      <CInputCheckbox
        v-for="(option, optionsIndex) in computedOptions"
        :key="optionsIndex"
        :label="option.label"
        :value="option.value"
        :custom="true"
        :name="name"
        :checked="computedValue.includes(option.value)"
        @change="onChecked"
        :inline="false"
        :disabled="disabled"
      />
      <small
        v-if="description && horizontal"
        class="form-text text-muted w-100"
        v-text="description"
      />
    </CCol>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    label: String,
    name: String,
    options: Array,
    description: String,
    disabled: [Boolean, String],
    horizontal: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Array, String, Boolean],
      default: () => [],
    },
  },
  computed: {
    computedOptions() {
      return this.options.map((opt) => {
        if (typeof opt === "string") {
          return { label: opt, value: opt };
        }
        return { label: opt.label || opt.value, value: opt.value };
      });
    },
    computedValue() {
      if (!Array.isArray(this.value)) {
        return [this.value];
      }
      return this.value;
    },
  },
  methods: {
    onChecked(event) {
      let tempValue = [...this.computedValue];
      let val = event.target.value;
      let checked = event.target.checked;
      let exists = tempValue.includes(val);
      if (checked && !exists) {
        tempValue.push(val);
      }
      if (!checked && exists) {
        tempValue = tempValue.filter((item) => item !== val);
      }
      this.$emit("input", tempValue);
    },
  },
};
</script>