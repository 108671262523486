<template>
  <div>
    <label v-if="horizontal === false" v-text="label"></label>
    <CInputFile
      :label="horizontal ? label : null"
      :placeholder="dataPlaceholder"
      @change="uploadImage"
      :multiple="false"
      :class="horizontal ? 'image-input-field' : ''"
      accept="image/*"
      :horizontal="horizontal"
      custom
    />
    <small
      style="margin-top: 8px"
      v-if="description"
      class="form-text text-muted w-100"
      v-text="description"
    />
    <CRow v-if="showPreview">
      <CCol sm="3" />
      <CCol sm="9">
        <div class="preview-wrapper">
          <img alt="preview" class="preview-image" :src="imageSrc" />
          <p>{{ imageTitle }}</p>
          <!-- Titel anzeigen -->
          <CButton type="button" color="danger" @click="deleteImage"
            >Delete</CButton
          >
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImageUploadField",
  props: {
    label: String,
    settingKey: String,
    value: String,
    horizontal: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: null,
    },
    maxImageSize: {
      type: Object,
      default: () => {
        return { width: 10000, height: 10000, fileSizeInKB: 4096 };
      },
    },
    placeholder: {
      type: String,
      default: "Bild auswählen...",
    },
    imageTitle: {
      // Hinzugefügt
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.value) {
      this.imageSrc = this.value;
      this.dataPlaceholder = this.value.split("/").pop();
      this.showPreview = true;
    }
  },
  data() {
    return {
      dataPlaceholder: this.placeholder,
      imageSrc: "",
      showPreview: false,
      selectedFile: null,
    };
  },
  methods: {
    uploadImage($event) {
      if ($event[0]) {
        const file = $event[0];
        this.selectedFile = $event[0];

        this.validateImageSize(file)
          .then(() => {
            this.previewImage(file);

            const data = new FormData();
            data.append("file", file, file.name);
            data.append("saveAsSetting", this.settingKey); // saves this image's public url as a realm setting with this key
            const options = {
              headers: {
                Accept: "application/json",
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
              },
            };
            axios
              .post("/file/upload", data, options)
              .then((response) => {
                this.$toaster.makeToast(
                  "Erfolgreich!",
                  "Bild wurde erfolgreich hochgeladen!",
                );
                this.imageSrc = response.data.fileDownloadUrl;
                this.$emit("input", response.data.fileDownloadUrl);
                this.$emit("image-title-update", file.name); // Hinzugefügt
              })
              .catch((err) => {
                this.$toaster.makeToast(
                  "Fehler!",
                  "Das Bild konnte nicht hochgeladen werden: " + err.toString(),
                );
              });
          })
          .catch(() => {
            console.error("Error while uploading image.");
          });
      }
    },
    previewImage(file) {
      this.dataPlaceholder = file.name;
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.imageSrc = reader.result;
        this.showPreview = true;
      });
      reader.readAsDataURL(file);
    },
    deleteImage() {
      const fileUrl = this.imageSrc;
      const split = fileUrl.split("/");
      const fileName = split.pop();
      const cloudFileId = split.pop();
      const realmUniqueHash = split.pop();
      axios
        .delete(
          `/file/delete/${realmUniqueHash}/${cloudFileId}/${fileName}?deleteSetting=${this.settingKey}`,
        )
        .then((response) => {
          this.$toaster.makeToast("Erfolgreich!", "Bild wurde gelöscht!");
          this.showPreview = false;
          this.imageSrc = "";
          this.dataPlaceholder = "Bild auswählen...";
          this.$emit("input", "");
          this.$emit("image-title-update", ""); // Hinzugefügt
        })
        .catch((err) => {
          this.$toaster.makeToast(
            "Fehler!",
            "Bild konnte nicht gelöscht werden: " + err.toString(),
          );
        });
      this.$emit("delete", { settingKey: this.settingKey, fileUrl });
    },
    validateImageSize(file) {
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        const objectUrl = _URL.createObjectURL(file);
        const maxImageSize = this.maxImageSize;
        const that = this;
        img.onload = function () {
          if (file.size > maxImageSize.fileSizeInKB * 1024) {
            // Kilo-Byte calc
            if (maxImageSize.fileSizeInKB >= 1024) {
              that.$toaster.makeToast(
                "Fehler!",
                `Dateigröße darf nicht über ${Math.round(maxImageSize.fileSizeInKB / 1024)}MB (${maxImageSize.fileSizeInKB}KB) groß sein.`,
              );
            } else {
              that.$toaster.makeToast(
                "Fehler!",
                `Dateigröße darf nicht über ${maxImageSize.fileSizeInKB}KB groß sein.`,
              );
            }
            reject();
            return;
          }
          if (
            this.width > maxImageSize.width ||
            this.height > maxImageSize.height
          ) {
            that.$toaster.makeToast(
              "Fehler!",
              `Das Bild darf nicht größer sein als ${maxImageSize.width}x${maxImageSize.height}px.`,
            );
            reject();
            return;
          }
          _URL.revokeObjectURL(objectUrl);
          resolve();
        };
        img.src = objectUrl;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-input-field {
  margin: 14px 0;
}

.preview-image {
  max-height: 80px;
  width: auto;
}

.preview-wrapper {
  margin-top: 6px;
  display: inline-block;

  button {
    margin-left: 8px;
  }
}
</style>
