<template>
  <CHeader fixed with-subheader dark>
    <!-- Bestehender Header-Content -->
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      to="/"
      src="img/brand/cloud-prt-logo.svg"
      width="190"
      height="46"
      alt="Makler-Anfragen"
    >
      <CIcon name="logo" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"> Dashboard</CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/users" exact> Benutzer</CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="px-3">
      <CHeaderNavItem class="px-3" style="padding-right: 4px !important">
        <CButton
          class="ml-1"
          size="sm"
          @click="$router.push({ name: 'PlanSettings' })"
        >
          <strong>{{
            this.$commons.getFromStorage("currentRealm").name
          }}</strong>
        </CButton>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav v-if="!notificationsEnabled" class="mr-4">
      <CHeaderNavItem class="d-md-down-none">
        <CButton
          color="light"
          variant="outline"
          size="sm"
          @click="requestNotificationPermission"
          class="notification-permission-btn"
        >
          <CIcon name="cil-bell-exclamation" class="mr-1" />
          Benachrichtigungen aktivieren
        </CButton>
      </CHeaderNavItem>
    </CHeaderNav>

    <!-- Tasks -->
    <CHeaderNavItem class="d-md-down-none mx-2">
      <div class="position-relative" @click="toggleNotifications">
        <CHeaderNavLink>
          <CIcon name="cil-list" />
        </CHeaderNavLink>
        <CBadge
          v-if="numberOfOpenTask > 0"
          color="danger"
          shape="pill"
          class="position-absolute notification-badge"
        >
          {{ numberOfOpenTask }}
        </CBadge>
        <div v-if="showNotifications" class="notification-menu">
          <div class="notification-header">
            <strong>Aufgaben</strong>
            <CIcon
              name="cil-x"
              @click.stop="toggleNotifications"
              class="close-icon"
            />
          </div>
          <div class="notification-body">
            <div v-if="openTasks.length > 0">
              <div
                v-for="task in openTasks"
                :key="task.id"
                class="notification-item"
                @click="goToTask(task)"
              >
                <div class="notification-title">{{ task.title }}</div>
                <div class="notification-time">
                  {{ task.startTime }} - {{ task.endTime }}
                </div>
                <div class="notification-description">
                  {{ task.description }}
                </div>
              </div>
            </div>
            <div v-else class="no-tasks-message">
              <CIcon
                name="cil-check-circle"
                size="xl"
                class="text-success mb-2"
              />
              <p>Keine offenen Aufgaben vorhanden.</p>
            </div>
          </div>
        </div>
      </div>
    </CHeaderNavItem>

    <!-- Notifications -->
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <div class="position-relative" @click="toggleBellNotifications">
          <CHeaderNavLink>
            <CIcon name="cil-bell" />
          </CHeaderNavLink>
          <CBadge
            v-if="unreadNotificationsCount > 0"
            color="danger"
            shape="pill"
            class="position-absolute notification-badge"
          >
            {{ unreadNotificationsCount }}
          </CBadge>

          <!-- Notifications Dropdown -->
          <div v-if="showBellNotifications" class="notification-menu">
            <div class="notification-header">
              <strong>Benachrichtigungen</strong>
              <CIcon
                name="cil-x"
                @click.stop="toggleBellNotifications"
                class="close-icon"
              />
            </div>

            <!-- Notification Tabs -->
            <div class="notification-tabs">
              <button
                :class="['tab-button', { active: activeTab === 'unread' }]"
                @click.stop="handleTabChange('unread')"
              >
                Ungelesen ({{ unreadNotificationsCount }})
              </button>
              <button
                :class="['tab-button', { active: activeTab === 'all' }]"
                @click.stop="handleTabChange('all')"
              >
                Alle
              </button>
            </div>

            <!-- Notifications List -->
            <div class="notification-body">
              <template v-if="activeNotifications.length > 0">
                <div
                  v-for="notification in activeNotifications"
                  :key="notification.id"
                  class="notification-item"
                  :class="{
                    unread: !notification.read,
                    read: notification.read,
                  }"
                  :style="{
                    borderLeft: `4px solid ${getPriorityColor(notification.priority)}`,
                  }"
                >
                  <div class="notification-title">
                    {{ notification.title }}
                    <span
                      v-if="!notification.read"
                      class="unread-indicator"
                    ></span>
                  </div>
                  <div class="notification-time">{{ notification.time }}</div>
                  <div class="notification-description">
                    {{ notification.description }}
                  </div>
                  <div class="notification-actions">
                    <button
                      v-if="!notification.read"
                      class="mark-as-read-button"
                      @click.stop="markNotificationAsRead(notification.id)"
                    >
                      Als gelesen markieren
                    </button>
                    <!-- Neuer Delete Button, nur im "Alle" Tab sichtbar -->
                    <button
                      v-if="activeTab === 'all'"
                      class="delete-notification-button"
                      @click.stop="handleDeleteNotification(notification.id)"
                    >
                      <CIcon name="cil-trash" class="mr-1" />
                      Löschen
                    </button>
                  </div>
                </div>
              </template>
              <div v-else class="no-notifications-message">
                <CIcon name="cil-bell" size="xl" class="text-muted mb-2" />
                <p>{{ getEmptyMessage() }}</p>
                <p>
                  Wir informieren Sie hier über wichtige Updates und
                  Wartungsarbeiten.
                </p>
              </div>
            </div>
          </div>
        </div>
      </CHeaderNavItem>

      <!-- E-Mail Notifications -->
      <CHeaderNavItem class="d-md-down-none mx-2">
        <div class="position-relative" @click="toggleEmailNotifications">
          <CHeaderNavLink>
            <CIcon name="cil-envelope-open" />
          </CHeaderNavLink>
          <CBadge
            v-if="openedEmailsCount > 0"
            color="info"
            shape="pill"
            class="position-absolute notification-badge"
          >
            {{ openedEmailsCount }}
          </CBadge>
          <div v-if="showEmailNotifications" class="notification-menu">
            <div class="notification-header">
              <strong>Geöffnete E-Mails</strong>
              <CIcon
                name="cil-x"
                @click.stop="toggleEmailNotifications"
                class="close-icon"
              />
            </div>
            <div class="notification-body">
              <div v-if="allOpenedEmails.length > 0">
                <div
                  v-for="email in allOpenedEmails"
                  :key="email.id"
                  class="notification-item"
                >
                  <div class="notification-title">{{ email.subject }}</div>
                  <div class="notification-time">{{ email.openedAt }}</div>
                </div>
              </div>
              <div v-else class="no-tasks-message">
                <CIcon
                  name="cil-check-circle"
                  size="xl"
                  class="text-success mb-2"
                />
                <p>Keine kürzlich geöffneten E-Mails.</p>
              </div>
            </div>
          </div>
        </div>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>

    <CModal
      title="Aktivität planen"
      size="lg"
      :show.sync="visibleActivityModal"
      :close-on-backdrop="false"
    >

      <ActivityWidget
        ref="activityWidget"
        :applyUpdate="applyUpdate"
        :closeModal="closeModal"
        @mounted="() => console.log('Widget mounted event')"
      />
      <template #footer>
        <CButton
          class="mr-3"
          color="dark"
          variant="outline"
          @click="visibleActivityModal = false"
        >
          Abbrechen
        </CButton>
        <CButton color="success" @click="submit"> Speichern</CButton>
      </template>
    </CModal>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import moment from "moment";
import "moment/locale/de";
import axios from "axios";
import WebSocketService from "@/services/WebSocketService";
import toastPlugin from "@/toastPlugin";
import ActivityWidget from "@/components/shared/widgets/ActivityWidget.vue";
import { CModal } from "@coreui/vue";
import {
  CIcon,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CBreadcrumbRouter,
  CHeader,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CHeaderBrand,
  CToggler,
  CBadge,
  CButton,
  CSubheader,
} from "@coreui/vue";

export default {
  name: "TheHeader",
  components: {
    ActivityWidget,
    TheHeaderDropdownAccnt,
    CIcon,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CBreadcrumbRouter,
    CHeader,
    CHeaderNav,
    CHeaderNavItem,
    CHeaderNavLink,
    CHeaderBrand,
    CToggler,
    CBadge,
    CButton,
    CSubheader,
  },

  data() {
    return {
      numberOfOpenTask: 0,
      openTasks: [],
      showNotifications: false,
      visibleActivityModal: false,
      selectedTaskId: null,
      showEmailNotifications: false,
      showBellNotifications: false,
      notificationsEnabled: false,
    };
  },

  computed: {
    activeTab() {
      return this.$store.state.notifications?.activeTab || "unread";
    },
    activeNotifications() {
      const state = this.$store.state.notifications;
      return this.activeTab === "unread"
        ? state?.unreadNotifications || []
        : state?.notifications || [];
    },
    unreadNotificationsCount() {
      return this.$store.state.notifications?.unreadNotifications?.length || 0;
    },
    highPriorityNotificationsCount() {
      return (
        this.$store.state.notifications?.notifications?.filter(
          (n) => n.priority === "high",
        )?.length || 0
      );
    },
    openedEmailsCount() {
      return this.$store.state.notifications?.openedEmails?.length || 0;
    },
    allOpenedEmails() {
      return this.$store.state.notifications?.openedEmails || [];
    },
  },

  created() {
    this.$on('initializeTask', this.handleInitializeTask);

    this.fetchOpenTasks();

    this.checkNotificationPermission();
    this.$store.dispatch("notifications/fetchNotifications");
    WebSocketService.connect();

    WebSocketService.subscribeToTopic("/topic/notifications", (message) => {
      this.$store.dispatch("notifications/handleNewNotification", message);
    });

    WebSocketService.subscribeToTopic("/topic/mailOpened", (message) => {
      this.handleMailOpened(message);
    });
  },

  methods: {
    handleInitializeTask(task) {
      console.log('handleInitializeTask called with:', task);

      try {
        if (!task) {
          console.error('No task provided');
          return;
        }

        // Zeitoptionen initialisieren
        this.times = [""];
        for (let i = 0; i < 24; i++) {
          for (const minutes of ['00', '15', '30', '45']) {
            const timeStr = `${String(i).padStart(2, '0')}:${minutes}`;
            this.times.push({
              value: timeStr,
              text: timeStr
            });
          }
        }

        // Parse dates
        const startMoment = moment(task.startTime, "DD. MMMM YYYY HH:mm", 'de');
        const endMoment = moment(task.endTime, "DD. MMMM YYYY HH:mm", 'de');

        // Formatiere die Zeiten
        const formattedStartTime = startMoment.format('HH:mm');
        const formattedEndTime = endMoment.format('HH:mm');

        // Round times to nearest 15 minutes
        const roundTo15Minutes = (time) => {
          const [hours, minutes] = time.split(':').map(Number);
          const roundedMinutes = Math.round(minutes / 15) * 15;
          return `${String(hours).padStart(2, '0')}:${String(roundedMinutes).padStart(2, '0')}`;
        };

        this.startTime = roundTo15Minutes(formattedStartTime);
        this.endTime = roundTo15Minutes(formattedEndTime);

        console.log('Gerundete Zeiten:', {
          original: { start: formattedStartTime, end: formattedEndTime },
          rounded: { start: this.startTime, end: this.endTime },
          inList: {
            start: this.times.some(t => typeof t === 'object' && t.value === this.startTime),
            end: this.times.some(t => typeof t === 'object' && t.value === this.endTime)
          }
        });

        // Rest der Werte setzen
        this.selectedDate = startMoment.toDate();
        this.startDate = startMoment.toDate();
        this.endDate = endMoment.toDate();
        this.title = task.title;
        this.type = task.type;
        this.description = task.description || '';
        this.location = task.location || '';
        this.freeType = task.freeType || 'FREE';
        this.notes = task.notes || '';
        this.done = task.done || false;
        this.selectedActivityId = task.id;

      } catch (error) {
        console.error('Error in handleInitializeTask:', error);
        console.error('Stack:', error.stack);
      }
    },
    toggleActivityModal() {
      this.visibleActivityModal = !this.visibleActivityModal;
      if (this.visibleActivityModal) {
        this.$nextTick(() => {
          if (this.$refs.activityWidget) {
            this.$refs.activityWidget.initialize();
          }
        });
      }
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
      this.showBellNotifications = false;
      this.showEmailNotifications = false;
    },

    fetchOpenTasks() {
      axios
        .get("/task/realm")
        .then((response) => {
          const tasks = response.data.filter((task) => !task.done);
          this.numberOfOpenTask = tasks.length;
          this.openTasks = tasks.map((task) => ({
            ...task,
            startTime: moment(task.startTime).format("LLL"),
            endTime: moment(task.endTime).format("LLL"),
          }));
        })
        .catch((err) => {
          console.error("Fehler beim Laden der Aufgaben:", err);
        });
    },

    // In TheHeader.vue
    async goToTask(task) {

      try {
        this.selectedTaskId = task.id;
        this.visibleActivityModal = true;

        await this.$nextTick();

        if (!this.$refs.activityWidget) {
          throw new Error('ActivityWidget nicht gefunden');
        }

        // Event-basierter Ansatz
        console.log('Sende initializeTask Event');
        this.$refs.activityWidget.$emit('initializeTask', task);

      } catch (error) {
        console.error('Fehler in goToTask:', error);
      }
    },

    submit() {
      if (
        this.$refs.activityWidget &&
        typeof this.$refs.activityWidget.submit === "function"
      ) {
        this.$refs.activityWidget.submit(this.selectedTaskId);
      }
    },

    closeModal() {
      this.$refs.activityWidget.initialize();
      this.visibleActivityModal = false;
    },

    applyUpdate(data) {
      this.openTasks = this.openTasks.map((task) => {
        if (task.id === data.id) {
          return {
            ...task,
            title: data.title,
            description: data.description,
            startTime: moment(data.startTime).format("LLL"),
            endTime: moment(data.endTime).format("LLL"),
          };
        }
        return task;
      });
    },

    async handleTabChange(tab) {
      try {
        await this.$store.dispatch("notifications/setActiveTab", tab);
        if (tab === "all") {
          await this.$store.dispatch("notifications/fetchNotifications");
        }
      } catch (error) {
        console.error("Fehler beim Tab-Wechsel:", error);
      }
    },

    async handleDeleteNotification(notificationId) {
      try {
        await this.$store.dispatch(
          "notifications/removeNotification",
          notificationId,
        );
      } catch (error) {
        console.error("Fehler beim Löschen der Benachrichtigung:", error);
        toastPlugin.makeToast(
          "Fehler",
          "Fehler beim Löschen der Benachrichtigung",
        );
      }
    },

    async markNotificationAsRead(id) {
      try {
        await this.$store.dispatch("notifications/markNotificationAsRead", id);
      } catch (error) {
        console.error("Fehler beim Markieren als gelesen:", error);
      }
    },

    checkNotificationPermission() {
      if ("Notification" in window) {
        this.notificationsEnabled = Notification.permission === "granted";
      }
    },

    requestNotificationPermission() {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          this.notificationsEnabled = permission === "granted";
          if (this.notificationsEnabled) {
            this.$toast.success("Benachrichtigungen wurden aktiviert");
          } else {
            this.$toast.info(
              "Benachrichtigungen wurden nicht aktiviert. Sie können diese jederzeit in den Browser-Einstellungen aktivieren.",
            );
          }
        });
      }
    },

    toggleBellNotifications() {
      this.showBellNotifications = !this.showBellNotifications;
      this.showEmailNotifications = false;
    },

    toggleEmailNotifications() {
      this.showEmailNotifications = !this.showEmailNotifications;
      this.showBellNotifications = false;
    },

    handleMailOpened(message) {
      let leadId;
      try {
        leadId =
          typeof message === "string" ? JSON.parse(message) : message.leadId;
      } catch (error) {
        console.error("Fehler beim Parsen der Mail-Öffnungs-Nachricht:", error);
        return;
      }

      const emailData = {
        id: leadId,
        subject: `Lead ${leadId} hat die E-Mail geöffnet`,
        openedAt: moment().format("LLL"),
      };

      this.$store.dispatch("notifications/addOpenedEmail", emailData);
      this.$toast.info(`Lead ${leadId} hat die E-Mail geöffnet`);
    },

    getPriorityColor(priority) {
      switch (priority) {
        case "high":
          return "#FF4136";
        case "normal":
          return "#FFDC00";
        case "low":
          return "#2ECC40";
        default:
          return "#AAAAAA";
      }
    },

    getEmptyMessage() {
      return this.activeTab === "unread"
        ? "Keine ungelesenen Benachrichtigungen vorhanden."
        : "Keine Benachrichtigungen vorhanden.";
    },
  },

  beforeDestroy() {
    WebSocketService.disconnect();
  },
};
</script>

<style scoped>
.notification-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1050;
}

.c-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: white;
  border-bottom: 1px solid #e4e7ea;
}

.c-header-nav {
  display: flex;
  align-items: center;
}

.c-header-nav-item {
  list-style: none;
}

.c-header-nav .btn {
  color: #23282c;
  background-color: #f8f9fa;
  border: 1px solid #d8dbe0;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}


.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: rgb(232 234 238) !important;
}

.close-icon {
  cursor: pointer;
}

.notification-body {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s ease;
  list-style: none; /* Entfernt den Punkt */
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.notification-title {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-time {
  font-size: 0.8em;
  color: #666;
  margin-bottom: 5px;
}

.notification-description {
  font-size: 0.9em;
  color: #333;
}

.notification-item.unread {
  background-color: #f8f9fa;
}

.notification-item.read {
  background-color: #ffffff;
  opacity: 0.8;
}

.notification-permission-btn {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
}

.notification-permission-btn:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.notification-tabs {
  display: flex;
  border-bottom: 1px solid #dee2e6;
  background: #f8f9fa;
}

.tab-button {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.9em;
  color: #495057;
  transition: all 0.3s ease;
}

.tab-button.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.tab-button:hover {
  background-color: #e9ecef;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.position-relative {
  position: relative;
}

.unread-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #007bff;
  margin-left: 5px;
}

.no-tasks-message,
.no-notifications-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: #6c757d;
}

.no-tasks-message p,
.no-notifications-message p {
  margin-top: 10px;
  font-size: 0.9em;
}

.mark-as-read-button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057;
  font-size: 0.75em;
  padding: 2px 6px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 5px;
}

.mark-as-read-button:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
  color: #212529;
}

.delete-notification-button {
  background-color: #fff;
  border: 1px solid #dc3545;
  color: #dc3545;
  font-size: 0.75em;
  padding: 2px 6px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.delete-notification-button:hover {
  background-color: #dc3545;
  color: #fff;
}

.notification-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
</style>
