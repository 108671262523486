<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-sitemap" />
                  Social Media Beiträge
                </div>
                <div class="header-button">
                  <CButton
                    to="socialMedia/post/new"
                    name="backagain"
                    size="sm"
                    key="key"
                    color="dark"
                  >
                    <CIcon size="sm" name="cil-plus" />
                    <span> Neuen Beitrag erstellen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <div id="calendar"></div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CButton,
  CIcon,
} from "@coreui/vue";

export default {
  name: "SocialMediaPosts",
  components: {
    CCard,
    CCardBody,
    CCardHeader,
    CRow,
    CCol,
    CButton,
    CIcon,
  },
  data() {
    return {
      loading: false,
      calendarEvents: [],
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      selectedDate: null,
      calendar: null
    };
  },
  mounted() {
    this.loadSocialMediaPosts();
    // Beobachte Größenänderungen des Containers
    const resizeObserver = new ResizeObserver(() => {
      if (this.calendar) {
        this.calendar.updateSize();
      }
    });

    const calendarEl = document.getElementById("calendar");
    if (calendarEl) {
      resizeObserver.observe(calendarEl);
    }
  },
  methods: {
    loadSocialMediaPosts() {
      axios
        .get("/socialMedia/posts")
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.calendarEvents = response.data
              .filter((post) => post.approved === true)
              .map((post) => {
                let networks = [];
                if (Array.isArray(post.connection)) {
                  networks = post.connection;
                } else if (post.connection && typeof post.connection === 'object') {
                  networks = [post.connection];
                }

                return {
                  id: post.id,
                  title: post.name,
                  start: post.publishAt,
                  extendedProps: {
                    networks: networks.map(conn => ({
                      socialMedia: conn.socialMedia,
                      title: conn.title,
                      id: conn.id,
                      icon: conn.icon
                    })),
                    imageUrl: post.imageUrl,
                    content: post.content,
                    approved: post.approved,
                  },
                };
              });

            this.initializeCalendar();
          } else {
            console.error("Unexpected response data format:", response.data);
          }
        })
        .catch((err) => {
          console.error("Problem while fetching social media posts", err);
          this.loading = false;
        });
    },
    initializeCalendar() {
      const calendarEl = document.getElementById("calendar");

      if (!calendarEl) {
        console.error('Calendar element not found');
        return;
      }

      try {
        // Erst die alte Kalender-Instanz zerstören, falls vorhanden
        if (this.calendar) {
          this.calendar.destroy();
        }

        const calendarConfig = {
          plugins: this.plugins,
          initialView: "dayGridMonth",
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,dayGridMonth",
          },
          events: this.calendarEvents,
          locales: [deLocale],
          locale: "de",
          editable: true,
          droppable: true,
          selectable: true,
          dateClick: (info) => {
            const selectedDateTime = new Date(info.dateStr);
            selectedDateTime.setHours(9, 0, 0);
            const formattedDateTime = selectedDateTime.toISOString();

            this.$router.push({
              path: '/socialMedia/post/new',
              query: {
                publishAt: formattedDateTime
              }
            });
          },
          eventDurationEditable: true,
          eventContent: function (arg) {
            let arrayOfDomNodes = [];

            // Haupt-Container für alle Inhalte
            let mainContainer = document.createElement("div");
            mainContainer.style.width = "100%";
            mainContainer.style.height = "100%";
            mainContainer.style.display = "flex";
            mainContainer.style.flexDirection = "column";
            mainContainer.style.position = "relative";

            // Container für Network Badges
            let networkContainer = document.createElement("div");
            networkContainer.classList.add("network-container");

            // Badges für Netzwerke
            const networks = arg.event.extendedProps.networks || [];
            networks.forEach(network => {
              let networkBadge = document.createElement("span");
              networkBadge.className = `badge badge-${this.getNetworkBadgeColor(network.socialMedia)}`;

              // Icon für das Netzwerk
              if (network.icon) {
                let icon = document.createElement("i");
                icon.className = network.icon;
                icon.style.marginRight = "4px";
                networkBadge.appendChild(icon);
              }

              let networkName = document.createElement("span");
              networkName.textContent = network.socialMedia.toLowerCase();
              networkBadge.appendChild(networkName);

              networkContainer.appendChild(networkBadge);
            });

            // Titel
            let titleEvent = document.createElement("div");
            if (arg.event._def.title) {
              titleEvent.classList = "fc-event-title";
              // Titel in einem span-Element, um Zeilenumbruch zu ermöglichen
              let titleSpan = document.createElement("span");
              titleSpan.style.display = "inline-block";
              titleSpan.style.width = "100%";
              titleSpan.style.whiteSpace = "normal";
              titleSpan.style.wordWrap = "break-word";
              titleSpan.textContent = arg.event._def.title;
              titleEvent.appendChild(titleSpan);
            }

            // Bild
            let imgEventWrap = document.createElement("div");
            if (arg.event.extendedProps.imageUrl) {
              imgEventWrap.classList.add("fc-event-img");
              let imgEvent = document.createElement("img");
              imgEvent.src = arg.event.extendedProps.imageUrl;
              imgEventWrap.appendChild(imgEvent);
            }

            // Meta-Info
            let metaInfo = document.createElement("div");
            metaInfo.classList.add("event-meta");

            let timeSpan = document.createElement("span");
            timeSpan.textContent = new Date(arg.event.start).toLocaleTimeString('de-DE', {
              hour: '2-digit',
              minute: '2-digit'
            });

            let statusSpan = document.createElement("span");
            statusSpan.textContent = arg.event.extendedProps.posted ? "✓ Gepostet" : "⏰ Geplant";

            metaInfo.appendChild(timeSpan);
            metaInfo.appendChild(statusSpan);
            mainContainer.appendChild(networkContainer);
            mainContainer.appendChild(titleEvent);
            mainContainer.appendChild(imgEventWrap);
            mainContainer.appendChild(metaInfo);

            // Nur die notwendigen Elemente hinzufügen
            arrayOfDomNodes = [networkContainer, titleEvent, imgEventWrap, metaInfo];
            return { domNodes: [mainContainer] };
          }.bind(this),

          eventDidMount: function (info) {
            // Einheitliche Kartenhöhe und Styling
            info.el.style.display = "flex";
            info.el.style.flexDirection = "column";
            info.el.style.minHeight = "240px";
            info.el.style.padding = "16px";
            info.el.style.backgroundColor = "white";
            info.el.style.borderRadius = "16px";
            info.el.style.boxShadow = "0 2px 8px rgba(0,0,0,0.05)";
          },

          eventClick: this.handleEventClick,
          eventDrop: this.handleEventDrop,
          eventResize: this.handleEventResize,
        };

        this.calendar = new Calendar(calendarEl, calendarConfig);
        this.calendar.render();

      } catch (error) {
        console.error('Error initializing calendar:', error);
        this.loading = false;
      }
    },
    handleEventClick(info) {
      this.$router.push(`/socialMedia/post/edit/${info.event.id}`);
    },
    handleEventDrop(info) {
      const updatedPost = {
        id: info.event.id,
        name: info.event.title,
        publishAt: info.event.start.toISOString(),
        content: info.event.extendedProps.content,
        imageUrl: info.event.extendedProps.imageUrl,
        approved: info.event.extendedProps.approved,
        connection: info.event.extendedProps.networks
      };

      axios
        .put(`/socialMedia/posts/${info.event.id}`, updatedPost)
        .then(() => {
          this.$toaster.makeToast(
            "Erfolgreich!",
            "Der Beitrag wurde erfolgreich umgeplant."
          );
          this.loadSocialMediaPosts();
        })
        .catch((err) => {
          console.error("Fehler beim Aktualisieren des Beitrags:", err);
          this.$toaster.makeToast(
            "Fehler!",
            "Der Beitrag konnte nicht verschoben werden. Bitte versuchen Sie es später erneut."
          );
        });
    },
    handleEventResize(info) {
      const updatedPost = {
        id: info.event.id,
        name: info.event.title,
        publishAt: info.event.start.toISOString(),
        content: info.event.extendedProps.content,
        imageUrl: info.event.extendedProps.imageUrl,
        approved: info.event.extendedProps.approved,
        connection: info.event.extendedProps.networks
      };

      axios
        .put(`/socialMedia/posts/${info.event.id}`, updatedPost)
        .then(() => {
          this.$toaster.makeToast(
            "Erfolgreich!",
            "Der Beitrag wurde erfolgreich geändert."
          );
          this.loadSocialMediaPosts();
        })
        .catch((err) => {
          console.error("Fehler beim Aktualisieren des Beitrags:", err);
          if (err.response) {
            console.log("Server response:", err.response.data);
          }
          this.$toaster.makeToast(
            "Fehler!",
            "Der Beitrag konnte nicht geändert werden. Bitte versuchen Sie es später erneut."
          );
        });
    },
    getNetworkBadgeColor(network) {
      switch (network) {
        case "FACEBOOK":
          return "primary";
        case "INSTAGRAM":
          return "danger";
        case "LINKEDIN":
          return "info";
        default:
          return "secondary";
      }
    },
  },
  beforeDestroy() {
    if (this.calendar) {
      this.calendar.destroy();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
};
</script>

<style scoped>
/* Einheitliche Kartengröße */
::v-deep .fc-event {
  padding: 8px !important;
  min-height: 180px !important; /* Reduzierte Mindesthöhe */
  display: flex !important;
  flex-direction: column !important;
  gap: 4px !important;
}

::v-deep .fc-event:hover {
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1) !important;
}

/* Grundlegendes Badge-Container Styling */
::v-deep .network-container {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important; /* Erlaubt Umbruch wenn nötig */
  gap: 4px !important; /* Kleinerer Abstand zwischen Badges */
  min-height: 32px !important;
  margin-bottom: 8px !important;
  padding: 0 !important;
  position: relative !important;
  z-index: 1 !important; /* Ensures badges are above other content */
}

/* Verbessertes Badge Styling */
::v-deep .badge {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 4px 12px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  letter-spacing: 0.2px !important;
  border-radius: 16px !important;
  white-space: nowrap !important;
  height: 24px !important;
  min-width: auto !important; /* Entfernt die feste Mindestbreite */
  max-width: none !important; /* Entfernt die maximale Breite */
  margin: 0 !important;
}

/* Badge Icon Styling */
::v-deep .badge i {
  margin-right: 4px !important;
  font-size: 12px !important;
}

/* Badge-Farben */
::v-deep .badge-primary {
  background-color: #1877f2 !important; /* Facebook Blau */
  color: white !important;
}

::v-deep .badge-info {
  background-color: #0A66C2 !important; /* LinkedIn Blau */
  color: white !important;
}

::v-deep .badge-danger {
  background-color: #E4405F !important; /* Instagram */
  color: white !important;
}

/* Verbesserte Titel-Styles */
::v-deep .fc-event-title {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
  color: #333 !important;
  margin: 4px 0 !important;
  width: 100% !important;
  min-height: 36px !important;
  display: block !important;
}

::v-deep .fc-event-title span {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
  word-break: break-word !important;
  white-space: normal !important;
}

/* Content-Styles */
::v-deep .event-content {
  font-size: 13px !important;
  color: #666 !important;
  line-height: 1.5 !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

/* Meta-Info */
::v-deep .event-meta {
  margin-top: auto !important;
  padding-top: 8px !important;
  border-top: 1px solid rgba(0,0,0,0.05) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  font-size: 12px !important;
  color: #6B7280 !important;
}

::v-deep .event-meta span {
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
}

/* Bild-Container */
::v-deep .fc-event-img {
  width: 100% !important;
  height: 100px !important;
  border-radius: 6px !important;
  overflow: hidden !important;
  margin: 4px 0 !important;
}

::v-deep .fc-event-img img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
</style>
