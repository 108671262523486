<!-- src/components/google-my-business/ConnectionStatus.vue -->
<template>
  <CCard>
    <CCardHeader>
      <h4>Verbindungsstatus</h4>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12" lg="6">
          <div class="connection-status">
            <div class="status-indicator">
              <CIcon
                  :name="connectionStatus.enabled ? 'cil-check-circle' : 'cil-x-circle'"
                  :class="connectionStatus.enabled ? 'text-success' : 'text-danger'"
                  size="xl"
              />
              <span class="ml-2">
                {{ connectionStatus.enabled ? 'Verbunden' : 'Nicht verbunden' }}
              </span>
            </div>

            <div v-if="connectionStatus.enabled" class="mt-3">
              <p>
                Letzte Synchronisation:
                {{ connectionStatus.lastSync ? formatDate(connectionStatus.lastSync) : 'Noch keine Synchronisation' }}
              </p>

              <CSwitch
                  class="mx-1"
                  color="primary"
                  :checked="connectionStatus.autoSyncEnabled"
                  @update:checked="toggleAutoSync"
                  label="Automatische Synchronisation"
              />

              <div v-if="connectionStatus.autoSyncEnabled" class="mt-3">
                <CInput
                    label="Sync-Interval (Minuten)"
                    type="number"
                    :value="connectionStatus.syncIntervalMinutes"
                    @input="updateSyncInterval"
                />
              </div>
            </div>
          </div>
        </CCol>

        <CCol sm="12" lg="6" class="d-flex align-items-center justify-content-end">
          <CButton
              v-if="!connectionStatus.enabled"
              color="primary"
              @click.prevent="initiateConnection"
              :disabled="loading"
          >
            <CIcon name="cil-link" />
            Mit Google My Business verbinden
          </CButton>
          <CButton
              v-else
              color="danger"
              @click.prevent="disconnect"
              :disabled="loading"
          >
            <CIcon name="cil-link-broken" />
            Verbindung trennen
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import toastPlugin from '@/toastPlugin'

export default {
  name: 'ConnectionStatus',

  data() {
    return {
      loading: false,
      baseUrl: process.env.VUE_APP_BACKEND_BASE_URL || 'https://localhost:8080'
    }
  },

  computed: {
    ...mapState({
      connectionStatus: state => state.googleMyBusiness.connectionStatus
    }),

    apiBaseUrl() {
      // Entfernt 'backend' am Ende der URL, falls vorhanden
      return this.baseUrl.replace(/\/backend$/, '')
    }
  },

  methods: {
    ...mapActions({
      fetchStatus: 'googleMyBusiness/fetchStatus'
    }),

    formatDate(date) {
      return new Date(date).toLocaleString('de-DE')
    },

    async initiateConnection() {
      console.log('Initiating connection...') // Debug log
      this.loading = true
      try {
        const response = await axios.get(`${this.apiBaseUrl}/api/v1/google-my-business/auth`)
        if (response.data && response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl
        } else {
          console.error('No redirect URL received')
          toastPlugin.makeToast('Fehler', 'Keine Redirect-URL erhalten')
        }
      } catch (error) {
        console.error('Connection error:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Verbindungsaufbau')
      } finally {
        this.loading = false
      }
    },

    async disconnect() {
      this.loading = true
      try {
        await axios.post(`${this.apiBaseUrl}/api/v1/google-my-business/disconnect`)
        await this.fetchStatus()
        toastPlugin.makeToast('Erfolg', 'Verbindung erfolgreich getrennt')
      } catch (error) {
        console.error('Disconnect error:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Trennen der Verbindung')
      } finally {
        this.loading = false
      }
    },

    async toggleAutoSync(enabled) {
      try {
        await axios.post(`${this.apiBaseUrl}/api/v1/google-my-business/settings`, {
          autoSyncEnabled: enabled
        })
        await this.fetchStatus()
        toastPlugin.makeToast('Erfolg', 'Einstellungen gespeichert')
      } catch (error) {
        console.error('Toggle auto sync error:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Aktualisieren der Einstellungen')
      }
    },

    async updateSyncInterval(value) {
      try {
        await axios.post(`${this.apiBaseUrl}/api/v1/google-my-business/settings`, {
          syncIntervalMinutes: parseInt(value)
        })
        await this.fetchStatus()
        toastPlugin.makeToast('Erfolg', 'Sync-Interval aktualisiert')
      } catch (error) {
        console.error('Update sync interval error:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Aktualisieren des Sync-Intervals')
      }
    }
  }
}
</script>

<style scoped>
.connection-status {
  padding: 1rem;
}
.status-indicator {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
</style>
