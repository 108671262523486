// src/store/modules/propstack.js
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        settings: {
            apiKey: '',
            enabled: false,
            autoSync: false,
            selectedContactTypes: []
        },
        contactTypes: [],
        isLoading: false,
        error: null,
        syncStatus: {
            isRunning: false,
            lastSync: null,
            message: 'Die Synchronisation ist inaktiv'
        }
    },

    getters: {
        isConnected: state => state.settings.enabled && state.settings.apiKey.length === 40,
        canStartSync: (state, getters) => getters.isConnected && state.settings.selectedContactTypes.length > 0
    },

    mutations: {
        SET_API_KEY(state, apiKey) {
            state.settings.apiKey = apiKey
        },
        SET_ENABLED(state, enabled) {
            state.settings.enabled = enabled
        },
        SET_AUTO_SYNC(state, autoSync) {
            state.settings.autoSync = autoSync
        },
        SET_SELECTED_CONTACT_TYPES(state, types) {
            state.settings.selectedContactTypes = types
        },
        SET_CONTACT_TYPES(state, types) {
            state.contactTypes = types
        },
        SET_LOADING(state, loading) {
            state.isLoading = loading
        },
        SET_ERROR(state, error) {
            state.error = error
        },
        SET_SYNC_STATUS(state, status) {
            state.syncStatus = { ...state.syncStatus, ...status }
        }
    },

    actions: {
        async testConnection({ commit, state }) {
            commit('SET_LOADING', true)
            commit('SET_ERROR', null)

            try {
                const response = await axios.post('/api/v1/propstack/test-connection', {
                    apiKey: state.settings.apiKey
                })
                commit('SET_ENABLED', true)
                return true
            } catch (error) {
                commit('SET_ERROR', 'Verbindung zu Propstack konnte nicht hergestellt werden')
                commit('SET_ENABLED', false)
                return false
            } finally {
                commit('SET_LOADING', false)
            }
        },

        async loadContactTypes({ commit, getters }) {
            if (!getters.isConnected) return

            commit('SET_LOADING', true)
            try {
                const response = await axios.get('/api/v1/propstack/contact-types')
                commit('SET_CONTACT_TYPES', response.data)
            } catch (error) {
                commit('SET_ERROR', 'Kontaktarten konnten nicht geladen werden')
            } finally {
                commit('SET_LOADING', false)
            }
        },

        async startSync({ commit, state, getters }) {
            if (!getters.canStartSync) return

            commit('SET_SYNC_STATUS', {
                isRunning: true,
                message: 'Synchronisation läuft...'
            })

            try {
                await axios.post('/api/v1/propstack/sync', {
                    contactTypes: state.settings.selectedContactTypes,
                    autoSync: state.settings.autoSync
                })
            } catch (error) {
                commit('SET_SYNC_STATUS', {
                    isRunning: false,
                    message: 'Synchronisation fehlgeschlagen'
                })
                commit('SET_ERROR', 'Synchronisation konnte nicht gestartet werden')
            }
        },

        async saveSettings({ state, commit }) {
            try {
                await axios.post('/api/v1/propstack/settings', state.settings)
            } catch (error) {
                commit('SET_ERROR', 'Einstellungen konnten nicht gespeichert werden')
            }
        }
    }
}
