export const DEFAULT_TEMPLATES = {
  // Re-Opt-In Formular
  reOptInFormHtml: `<!DOCTYPE html>
<html lang="de">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Newsletter erneut abonnieren - {{REALM_NAME}}</title>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Montserrat:wght@500;700&display=swap" rel="stylesheet">
    <style>
        :root {
            --primary-color: #2563eb;
            --success-color: #16a34a;
            --text-color: #1f2937;
            --background-color: #f8fafc;
            --card-background: #ffffff;
        }

        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        html, body {
            background: var(--background-color);
            color: var(--text-color);
            line-height: 1.6;
            font-family: 'Open Sans', sans-serif;
            -webkit-font-smoothing: antialiased;
        }

        .container {
            position: relative;
            width: 100%;
            max-width: 800px;
            margin: 40px auto;
            padding: 0 20px;
        }

        .card {
            background: var(--card-background);
            border-radius: 16px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            padding: 40px;
            margin: 20px 0;
            transition: transform 0.2s ease;
        }

        h1.title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 2.2rem;
            color: var(--primary-color);
            margin-bottom: 16px;
            text-align: center;
        }

        .content {
            font-size: 1.1rem;
            line-height: 1.7;
            color: var(--text-color);
            margin: 20px 0;
            text-align: center;
        }

        form {
            max-width: 500px;
            margin: 30px auto;
        }

        .form-group {
            margin-bottom: 20px;
        }

        label {
            display: block;
            font-weight: 600;
            margin-bottom: 8px;
            color: var(--text-color);
        }

        input[type="email"] {
            width: 100%;
            padding: 12px;
            border: 2px solid #e2e8f0;
            border-radius: 8px;
            font-size: 1rem;
            transition: all 0.3s ease;
        }

        input[type="email"]:focus {
            border-color: var(--primary-color);
            outline: none;
            box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
        }

        button {
            width: 100%;
            padding: 14px 24px;
            background-color: var(--primary-color);
            color: white;
            border: none;
            border-radius: 8px;
            font-size: 1.1rem;
            font-weight: 600;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        button:hover {
            background-color: #1d4ed8;
        }

        .privacy-notice {
            margin-top: 24px;
            font-size: 0.9rem;
            color: #64748b;
            text-align: center;
        }

        .privacy-notice a {
            color: var(--primary-color);
            text-decoration: none;
        }

        .privacy-notice a:hover {
            text-decoration: underline;
        }

        @media (max-width: 640px) {
            .container {
                padding: 0 15px;
                margin: 20px auto;
            }

            .card {
                padding: 30px 20px;
            }

            h1.title {
                font-size: 1.8rem;
            }

            .content {
                font-size: 1rem;
            }
        }
    </style>
</head>
<body>
<div class="container">
    <div class="card">
        <h1 class="title">Willkommen zurück! 👋</h1>
        <div class="content">
            <p>Schön, dass Sie wieder Teil unserer Newsletter-Community werden möchten.
                Mit wenigen Klicks sind Sie wieder dabei und verpassen keine wichtigen Updates mehr.</p>
        </div>

        <form action="/opt-in/re-optin" method="post" id="reOptInForm">
            <div class="form-group">
                <label for="email">Ihre E-Mail-Adresse</label>
                <input
                        type="email"
                        id="email"
                        name="email"
                        required
                        placeholder="max.mustermann@example.de"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
                >
                <div class="error">Bitte geben Sie eine gültige E-Mail-Adresse ein.</div>
            </div>

            <input type="hidden" name="realmId" value="{{REALM_ID}}">

            <button type="submit">Newsletter abonnieren</button>
        </form>

        <p class="privacy-notice">
            Mit Ihrer Anmeldung akzeptieren Sie unsere <a href="{{PRIVACY_URL}}">Datenschutzbestimmungen</a>.
            Sie können den Newsletter jederzeit wieder abbestellen.
        </p>
    </div>
</div>

<script>
    document.getElementById('reOptInForm').addEventListener('submit', function(e) {
        const emailInput = document.getElementById('email');
        if (!emailInput.checkValidity()) {
            e.preventDefault();
            emailInput.reportValidity();
        }
    });
</script>
</body>
</html>`,

  // Re-Opt-In Bestätigungs-E-Mail
  reOptInEmailContent: `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Newsletter-Anmeldung bestätigen</title>
</head>
<body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
    <div style="max-width: 600px; margin: 0 auto; padding: 20px;">
        <h1 style="color: #2c3e50;">Ihre Newsletter-Anmeldung bestätigen</h1>
        
        <p>Hallo,</p>
        
        <p>vielen Dank für Ihre erneute Anmeldung zum Newsletter von {{REALM_NAME}}.</p>
        
        <p>Bitte klicken Sie auf den folgenden Link, um Ihre E-Mail-Adresse zu bestätigen:</p>
        
        <p style="text-align: center; margin: 30px 0;">
            <a href="{{CONFIRM_URL}}" 
               style="background-color: #3498db; color: white; padding: 12px 25px; text-decoration: none; border-radius: 4px; display: inline-block;">
                Newsletter-Anmeldung bestätigen
            </a>
        </p>
        
        <p>Oder kopieren Sie diesen Link in Ihren Browser:</p>
        <p>{{CONFIRM_URL}}</p>
        
        <p style="margin-top: 30px; font-size: 12px; color: #666;">
            Falls Sie diese E-Mail nicht angefordert haben, können Sie sie einfach ignorieren.
        </p>
    </div>
</body>
</html>`,

  // Newsletter Opt-In Bestätigungsseite
  optInAcceptedHtml: `<!DOCTYPE html>
<html lang="de">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>E-Mail Adresse wurde verifiziert</title>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Montserrat:wght@500;700&display=swap" rel="stylesheet">
    <style>
        :root {
            --primary-color: #2563eb;
            --success-color: #16a34a;
            --text-color: #1f2937;
            --background-color: #f8fafc;
            --card-background: #ffffff;
        }

        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        html, body {
            background: var(--background-color);
            color: var(--text-color);
            line-height: 1.6;
            font-family: 'Open Sans', sans-serif;
            -webkit-font-smoothing: antialiased;
        }

        .container {
            position: relative;
            width: 100%;
            max-width: 800px;
            margin: 40px auto;
            padding: 0 20px;
        }

        .card {
            background: var(--card-background);
            border-radius: 16px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            padding: 40px;
            margin: 20px 0;
            transition: transform 0.2s ease;
        }

        .success-icon {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
        }

        .success-icon img {
            max-width: 200px;
            height: auto;
            border-radius: 12px;
        }

        h1.title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 2.2rem;
            color: var(--success-color);
            margin-bottom: 16px;
            text-align: center;
        }

        h2.subtitle {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 1.4rem;
            color: var(--text-color);
            margin-bottom: 24px;
            text-align: center;
        }

        .content {
            font-size: 1.1rem;
            line-height: 1.7;
            color: var(--text-color);
            margin: 20px 0;
            text-align: center;
        }

        .signature {
            font-style: italic;
            color: var(--text-color);
            margin-top: 30px;
            text-align: center;
            font-size: 1.1rem;
        }

        .footer {
            margin-top: 30px;
            padding: 20px;
            text-align: center;
            font-size: 0.9rem;
            color: #64748b;
        }

        .footer a {
            color: var(--primary-color);
            text-decoration: none;
            transition: color 0.2s ease;
        }

        .footer a:hover {
            color: #1e40af;
            text-decoration: underline;
        }

        @media (max-width: 640px) {
            .container {
                padding: 0 15px;
                margin: 20px auto;
            }

            .card {
                padding: 30px 20px;
            }

            h1.title {
                font-size: 1.8rem;
            }

            h2.subtitle {
                font-size: 1.2rem;
            }

            .content {
                font-size: 1rem;
            }
        }
    </style>
</head>
<body>
<div class="container">
    <div class="card animate-in">
        <div class="success-icon">
            <img alt="Willkommen im Newsletter" src="https://cloud-iframe-cdn.makler-anfragen.immo/img/email_verified.jpg"/>
        </div>
        <h1 class="title animate-in">Willkommen an Bord! 🎉</h1>
        <h2 class="subtitle animate-in">Schön, dass Sie dabei sind!</h2>

        <div class="content animate-in">
            <p>Wir freuen uns sehr, Sie in unserer Newsletter-Community begrüßen zu dürfen! Ab jetzt halten wir Sie über alle spannenden Neuigkeiten, exklusive Einblicke und wichtige Updates auf dem Laufenden.</p>

            <p style="margin-top: 20px">Was Sie erwarten dürfen:</p>
            <ul style="list-style: none; margin: 15px 0; text-align: left; padding-left: 20px;">
                <li style="margin: 8px 0;">✨ Exklusive Immobilien-Insights</li>
                <li style="margin: 8px 0;">📈 Aktuelle Markttrends</li>
                <li style="margin: 8px 0;">💡 Wertvolle Tipps und Expertise</li>
            </ul>
        </div>

        <div class="signature animate-in">
            Mit besten Grüßen,<br>
            Ihr {{ REALM_NAME }} Team
        </div>
    </div>

    <div class="footer animate-in">
        <p>Ihre Flexibilität ist uns wichtig: Falls Sie den Newsletter später einmal nicht mehr erhalten möchten, können Sie ihn
            <a href="{{ OPT_OUT_URL }}" target="_blank" rel="noopener">hier ganz einfach abbestellen</a>.
        </p>
    </div>
</div>
</body>
</html>`
};
