import Vue from "vue";
import Router from "vue-router";
import commons from '@/commons';

// TODO: use auth guard or so?

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// LinkedIN
const LinkedInCallback = () => import("@/views/LinkedInCallback");

// Instagram
const InstagramCallback = () => import("@/views/InstagramCallback");

// Notifications
const NotificationsPage = () => import("@/views/notifications/NotificationsPage");

// Views
const Dashboard = () => import('@/views/Dashboard.vue');
const Dashboard1 = () => import("@/views/Dashboard1");

const AdminRealms = () => import("@/views/admin/Realms");
const AdminNewRealm = () => import("@/views/admin/NewRealm");
const AdminEditRealm = () => import("@/views/admin/EditRealm");

const Leads = () => import("@/views/base/Leads");
const QueuedLeads = () => import("@/views/base/QueuedLeads");
const EditLead = () => import("@/views/base/EditLead");
const EditQueuedLead = () => import("@/views/base/EditQueuedLead");
const Tasks = () => import("@/views/base/Tasks");

const AdminOrganizations = () => import("@/views/admin/Organizations");
const AdminNewOrganization = () => import("@/views/admin/NewOrganization");
const AdminEditOrganization = () => import("@/views/admin/EditOrganization");
const NotificationManagement = () =>
  import("@/views/admin/NotificationManagement.vue");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const PageEditor = () => import("@/views/pages/PageEditor");
const Login = () => import("@/views/pages/Login");
const Logout = () => import("@/views/pages/Logout");
const UserRegisterByToken = () => import("@/views/pages/UserRegisterByToken");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");
const PasswordResetByToken = () => import("@/views/pages/PasswordResetByToken");

const IframeSettings = () => import("@/views/iframe/IframeSettings");
const RealmSettings = () => import("@/views/base/RealmSettings");
const PdfSettings = () => import("@/views/base/PdfSettings");
const Support = () => import("@/views/base/form");
const ChatbotSettings = () => import("@/views/chatbot/ChatbotSettings");
const SeoAudit = () => import("@/views/base/SeoAudit");
const ImportExport = () => import("@/views/base/ImportExport");
const PlanSettings = () => import("@/views/base/PlanSettings");
const Users = () => import("@/views/base/Users");
const NewUser = () => import("@/views/base/NewUser");
const EditUser = () => import("@/views/base/EditUser");
const NewsletterSubscribers = () => import("@/views/base/newsletter/NewsletterSubscribers");
const NewsletterCampaigns = () => import("@/views/base/newsletter/NewsletterCampaigns");
const NewsletterCampaignNew = () => import("@/views/base/newsletter/NewsletterCampaignNew");
const NewsletterCampaignEdit = () => import("@/views/base/newsletter/NewsletterCampaignEdit");

const NewsletterSubscriberEdit = () =>  import("@/views/base/newsletter/NewsletterSubscriberEdit");
const NewsletterSubscriberNew = () =>  import("@/views/base/newsletter/NewsletterSubscriberNew");
const NewsletterGroups = () =>  import("@/views/base/newsletter/NewsletterGroups");
const NewsletterGeneratorPage = () =>  import("@/views/base/newsletter/NewsletterGeneratorPage");
const NewsletterOptIn = () => import("@/views/base/newsletter/NewsletterOptIn");
const NewsletterArticles = () =>  import("@/views/base/newsletter/NewsletterArticles");
const NewsletterArticleEdit = () =>  import("@/views/base/newsletter/NewsletterArticleEdit");
const NewsletterArticleNew = () =>  import("@/views/base/newsletter/NewsletterArticleNew");

const Pipeline = () => import("@/views/base/Pipeline");
const Deals = () => import("@/views/base/Deals");

const People = () => import("@/views/base/People");
const Organization = () => import("@/views/base/Organization");

const Statistics = () => import("@/views/admin/Statistics");
const EMailAnalytics = () => import("@/views/admin/EmailAnalytics");

const Funnel = () => import("@/views/admin/Funnel");
const EditFunnel = () => import("@/views/admin/EditFunnel");
const NewFunnel = () => import("@/views/admin/NewFunnel");

const LandingPages = () => import("@/views/base/landingpage/LandingPages");
const LandingPageNew = () => import("@/views/base/landingpage/LandingPageNew");
const LandingPageEdit = () =>
  import("@/views/base/landingpage/LandingPageEdit");

const LeadgeneratorList = () =>
  import("@/views/base/leadgenerator/LeadgeneratorList");
const LeadgeneratorNew = () =>
  import("@/views/base/leadgenerator/LeadgeneratorNew");
const LeadgeneratorEdit = () =>
  import("@/views/base/leadgenerator/LeadgeneratorEdit");

const KensingtonLocations = () =>
  import("@/plugin/kensington/KensingtonLocations");
const KensingtonLocationNew = () =>
  import("@/plugin/kensington/KensingtonLocationNew");
const KensingtonLocationEdit = () =>
  import("@/plugin/kensington/KensingtonLocationEdit");
const KensingtonLocationImportPage = () =>
  import("@/plugin/kensington/KensingtonLocationImportPage");

const SocialMediaPosts = () =>
  import("@/views/base/social-media/SocialMediaPosts");
const SocialMediaPostNew = () =>
  import("@/views/base/social-media/SocialMediaPostNew");
const SocialMediaPostEdit = () =>
  import("@/views/base/social-media/SocialMediaPostEdit");
const SocialMediaConnections = () =>
  import("@/views/base/social-media/SocialMediaConnections");
const SocialMediaSetup = () =>
  import("@/views/base/social-media/ProfileSetup");
const SocialMediaHilfe = () =>
  import("@/views/base/social-media/SocialMediaHelp");
const SocialMediaAutomation = () =>
  import("@/views/base/social-media/SocialMediaAutomation.vue");

const Subscription = () => import("@/views/base/Subscription");

const PlanForbidden = () => import("@/views/admin/PlanForbidden");

const PropstackIntegration = () => import("@/components/crm/PropstackIntegration.vue")

const DefaultRealmSettings = () => import("@/views/admin/DefaultRealmSettings.vue")

const publicPaths = [
  '/pages/login',
  '/pages/404',
  '/pages/500',
  '/pages/forgotPassword',
  '/pages/userRegister',
  '/pages/passwordResetByToken',
  '/linkedin-callback',
  '/instagram-callback',
  '/forbidden'
];

Vue.use(Router);

// Router mit allen CoreUI-Anforderungen erstellen
const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

router.beforeEach((to, from, next) => {
  console.debug('Route navigation:', {
    from: from.path,
    to: to.path,
    isLoggedIn: commons.isLoggedIn()
  });

  // Wenn wir bereits auf der Login-Seite sind, keine weitere Überprüfung
  if (to.path === '/pages/login') {
    next();
    return;
  }

  // Öffentliche Routen immer erlauben
  if (publicPaths.includes(to.path)) {
    next();
    return;
  }

  // Für geschützte Routen
  if (!commons.isLoggedIn()) {
    // Speichern Sie die ursprüngliche URL für Redirect nach Login
    if (to.path !== '/pages/login') {
      commons.setToStorage('redirectAfterLogin', to.fullPath);
    }
    next('/pages/login');
    return;
  }

  // Prüfe die Token-Gültigkeit für geschützte Routen
  if (to.matched.some(record => record.meta.requiresAuth !== false)) {
    if (!commons.isLoggedIn()) {
      console.warn('Authentication required, redirecting to login');
      next('/pages/login');
      return;
    }

    // Validiere Rollen nur für eingeloggte Benutzer
    if (!commons.validateRoles()) {
      console.warn('Invalid role structure detected, redirecting to login');
      commons.logout();
      next('/pages/login');
      return;
    }
  }

  // Prüfe ADMIN-Rechte
  if (to.matched.some(record => record.meta.requiredRole === 'ADMIN')) {
    if (!commons.isSystemAdmin()) {
      console.warn('Route requires ADMIN rights, but user is not an admin');
      next('/forbidden');
      return;
    }
  }

  // Prüfe andere required Roles
  const requiredRole = to.matched.find(record => record.meta.requiredRole)?.meta.requiredRole;
  if (requiredRole && !commons.isRole(requiredRole)) {
    console.warn(`Route requires role ${requiredRole}, but user doesn't have it`);
    next('/forbidden');
    return;
  }

  // Prüfe required Plan
  const requiredPlan = to.matched.find(record => record.meta.requiredPlan)?.meta.requiredPlan;
  if (requiredPlan && !commons.hasRealmPlan(requiredPlan)) {
    console.warn(`Route requires plan ${requiredPlan}, but realm doesn't have it`);
    next('/forbidden');
    return;
  }

  // Wenn alle Checks bestanden wurden
  next();
});

// Optional: Fügen Sie afterEach für Logging hinzu
router.afterEach((to, from) => {
  console.debug('Route changed:', {
    from: from.path,
    to: to.path
  });
});

// Exportieren Sie den Router
export default router;

function configRoutes() {
  return [
    {
      path: "/linkedin-callback",
      name: "LinkedInCallback",
      component: LinkedInCallback,
      meta: {
        requiresAuth: false
      }
      },
    {
      path: "/instagram-callback",
      name: "InstagramCallback",
      component: InstagramCallback,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "dashboard1",
          name: "Dashboard1",
          component: Dashboard1,
        },
        {
          path: "page-editor",
          name: "PageEditor",
          component: PageEditor,
        },
        {
          path: "google-my-business",
          name: "GoogleMyBusiness",
          component: () => import("@/views/google-my-business/GoogleMyBusiness.vue"),
          meta: {
            label: "Google My Business",
            requiresAuth: true
          }
        },
        {
          path: "admin",
          redirect: "/admin/realms",
          name: "Admin",
          meta: {
            requiresAuth: true,
            requiredRole: "ADMIN",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "realms",
              name: "Realms",
              component: AdminRealms,
            },
            {
              path: "realms/new",
              name: "New realm",
              component: AdminNewRealm,
            },
            {
              path: "realms/edit/:id",
              name: "Edit realm",
              component: AdminEditRealm,
            },
            {
              path: "users",
              name: "AdminUsers",
              component: Users,
            },
            {
              path: "organizations",
              name: "Organizations",
              component: AdminOrganizations,
            },
            {
              path: "organizations/edit/:id",
              name: "Edit organization",
              component: AdminEditOrganization,
            },
            {
              path: "organizations/new",
              name: "New organizations",
              component: AdminNewOrganization,
            },
            {
              path: "notifications",
              name: "NotificationManagement",
              component: NotificationManagement,
              meta: {
                label: "Benachrichtigungen verwalten",
                requiredRole: "ADMIN",
                requiresAuth: true,
              },
            },
            {
              path: "default-realm-settings",
              name: "DefaultRealmSettings",
              component: DefaultRealmSettings,
              meta: {
                label: "Default Realm Settings",
                requiredRole: "ADMIN"
              },
            },
            {
              path: "system",
              name: "SystemOperations",
              component: () => import("@/views/admin/SystemOperations"),
              meta: {
                label: "System-Operationen",
                requiredRole: "ADMIN"
              },
            },
          ],
        },
        {
          path: "leads",
          name: "Leads",
          component: Leads,
          meta: {
            label: "Leads",
          },
        },
        {
          path: "tasks",
          name: "Tasks",
          component: Tasks,
          meta: {
            label: "Tasks",
            //requiredPlan 'PROFESSIONAL'
          },
        },
        {
          path: "deals",
          redirect: "/deals/index",
          name: "Deals",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            label: "Deals",
          },
          children: [
            {
              path: "index",
              name: "DealsIndex",
              component: Deals,
              meta: {
                label: "Deals",
                //requiredPlan 'PROFESSIONAL'
              },
            },
            {
              path: "pipeline",
              name: "Pipeline",
              component: Pipeline,
              meta: {
                label: "Pipeline",
                //requiredPlan 'PROFESSIONAL'
              },
            },
          ],
        },
        {
          path: "queuedLeads",
          name: "QueuedLeads",
          component: QueuedLeads,
          meta: {
            label: "Freizugebene Leads",
          },
        },
        {
          path: "queuedLeads/edit/:id",
          name: "Edit queued lead",
          component: EditQueuedLead,
          meta: {
            label: "Lead bearbeiten",
          },
        },
        {
          path: "leads/edit/:id",
          name: "Edit Lead",
          component: EditLead,
          meta: {
            label: "Lead bearbeiten",
          },
        },
        {
          path: "contacts",
          redirect: "/contacts/people",
          name: "Contacts",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            label: "Contacts",
            //requiredPlan 'PROFESSIONAL'
          },
          children: [
            {
              path: "people",
              name: "People",
              component: People,
              meta: {
                label: "People",
                //requiredPlan 'PROFESSIONAL'
              },
            },
            {
              path: "organization",
              name: "Organization",
              component: Organization,
              meta: {
                label: "Organization",
                //requiredPlan 'PROFESSIONAL'
              },
            },
          ],
        },
        {
          path: "iframe",
          name: "Iframe Settings",
          component: IframeSettings,
          meta: {
            label: "Iframe Einstellungen",
          },
        },
        {
          path: "statistics",
          name: "Statistics",
          component: Statistics,
          meta: {
            label: "Statistiken",
          },
        },
        {
          path: "emailAnalytics",
          name: "Email Analytics",
          component: EMailAnalytics,
          meta: {
            label: "Mail Analyse",
          },
        },
        {
          path: "subscription",
          name: "Subscription",
          component: Subscription,
          meta: {
            label: "Abonnement-Verwaltung",
          },
        },
        {
          path: "funnel",
          name: "Funnel",
          component: Funnel,
          meta: {
            label: "E-Mail Kampagnen",
            //requiredPlan 'STARTUP',
            featureName: "E-Mail Kampagnen",
          },
        },
        {
          path: "funnel/edit/:id",
          name: "Edit Funnel",
          component: EditFunnel,
          meta: {
            label: "Kampagne bearbeiten",
          },
        },
        {
          path: "funnel/new",
          name: "New Funnel",
          component: NewFunnel,
          meta: {
            label: "Neue Kampagne",
          },
        },
        {
          path: "landingpage",
          name: "Landingpages",
          component: LandingPages,
          meta: {
            label: "Landingpages",
          },
        },
        {
          path: "landingpage/new",
          name: "Landingpage erstellen",
          component: LandingPageNew,
          meta: {
            label: "Landingpage erstellen",
          },
        },
        {
          path: "landingpage/edit/:id",
          name: "Landingpage bearbeiten",
          component: LandingPageEdit,
          meta: {
            label: "Landingpage bearbeiten",
          },
        },
        {
          path: "leadGenerator",
          name: "LeadgeneratorList",
          component: LeadgeneratorList,
          meta: {
            label: "Leadgenerator",
          },
        },
        {
          path: "leadGenerator/new",
          name: "LeadgeneratorNew",
          component: LeadgeneratorNew,
          meta: {
            label: "Leadgenerator erstellen",
          },
        },
        {
          path: "leadGenerator/edit/:id",
          name: "LeadgeneratorEdit",
          component: LeadgeneratorEdit,
          meta: {
            label: "Funnel bearbeiten",
          },
        },
        {
          path: "socialMedia",
          name: "SocialMediaPosts",
          component: SocialMediaPosts,
          meta: {
            label: "Social Media",
          },
        },
        {
          path: "socialMedia/post/new",
          name: "SocialMediaPostNew",
          component: SocialMediaPostNew,
          meta: {
            label: "Beitrag erstellen",
          },
        },
        {
          path: "socialMedia/post/edit/:id",
          name: "SocialMediaPostEdit",
          component: SocialMediaPostEdit,
          meta: {
            label: "Beitrag bearbeiten",
          },
        },
        {
          path: "socialMedia/connections",
          name: "SocialMediaConnections",
          component: SocialMediaConnections,
          meta: {
            label: "Social Media",
          },
        },
        {
          path: '/socialMedia/profilesetup',
          name: 'ProfileSetup',
          component: SocialMediaSetup,
        },
        {
          path: "/socialMedia/hilfe",
          name: "SocialMediaHilfe",
          component: SocialMediaHilfe,
        },
        {
          path: "/socialMedia/automation",
          name: "SocialMediaAutomation",
          component: SocialMediaAutomation,
        },
        {
          path: "settings",
          redirect: "/settings/general",
          name: "Settings",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            label: "Einstellungen",
          },
          children: [
            {
              path: "general",
              name: "RealmSettings",
              component: RealmSettings,
              meta: {
                label: "Instanz Einstellungen",
              },
            },
            {
              path: "import",
              name: "Import & Export",
              component: ImportExport,
              meta: {
                label: "Import & Export",
              },
            },
            {
              path: "plan",
              name: "PlanSettings",
              component: PlanSettings,
              meta: {
                label: "Tarif",
              },
            },
          ],
        },
        {
          path: "pdf-settings",
          name: "PDF Settings",
          component: PdfSettings,
          meta: {
            label: "PDF Einstellungen",
          },
        },
        {
          path: "form",
          name: "Support",
          component: Support,
          meta: {
            label: "Support",
          },
        },
        {
          path: "chatbot-settings",
          name: "Chatbot Settings",
          component: ChatbotSettings,
          meta: {
            label: "Chatbot Einstellungen",
            //requiredPlan 'STARTUP',
            featureName: "Chatbot",
          },
        },
        {
          path: "seo-audit",
          name: "SEO Audit",
          component: SeoAudit,
          meta: {
            label: "SEO Audit ",
            featureName: "SEO Audit",
          },
        },
        {
          path: "newsletter",
          redirect: "/newsletter/article",
          name: "Newsletter",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            label: "Newsletter",
            //requiredPlan 'STARTUP',
            featureName: "E-Mail Marketing",
          },
          children: [
            {
              path: "article",
              name: "NewsletterArticles",
              component: NewsletterArticles,
              meta: {
                label: "Artikel",
                // requiredPlan: 'STARTUP'
              },
            },
            {
              path: "article/edit/:id",
              name: "NewsletterArticleEdit",
              component: NewsletterArticleEdit,
              meta: {
                label: "Newsletter bearbeiten",
                //requiredPlan 'STARTUP'
              },
            },
            {
              path: "article/new",
              name: "NewsletterArticleNew",
              component: NewsletterArticleNew,
              meta: {
                label: "Newsletter erstellen",
                // requiredPlan: 'STARTUP'
              },
            },
            {
              path: "campaigns",
              name: "NewsletterCampaigns",
              component: NewsletterCampaigns,
              meta: {
                label: "Kampagnen",
              },
            },
            {
              path: "campaigns/new",
              name: "NewsletterCampaignNew",
              component: NewsletterCampaignNew,
              meta: {
                label: "Neue Kampagne",
              },
            },
            {
              path: "campaigns/edit/:id",
              name: "NewsletterCampaignEdit",
              component: NewsletterCampaignEdit,
              meta: {
                label: "Kampagne bearbeiten",
              },
            },
          ],
        },
        {
          path: "emailBasic",
          redirect: "/emailBasic/subscriber",
          name: "EmailBasic",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            label: "Newsletter",
            requiredPlan: "STARTUP",
            featureName: "E-Mail Marketing",
          },
          children: [
            {
              path: "campaigns",
              name: "EmailBasicCampaigns",
              component: NewsletterCampaigns,
              meta: {
                label: "Kampagnen",
              },
            },
            {
              path: "subscriber",
              name: "NewsletterSubscribers",
              component: NewsletterSubscribers,
              meta: {
                label: "Abonnenten",
                // requiredPlan: 'STARTUP'
              },
            },
            {
              path: "subscriber/edit/:id",
              name: "NewsletterSubscriberEdit",
              component: NewsletterSubscriberEdit,
              meta: {
                label: "Abonnent bearbeiten",
                // requiredPlan: 'STARTUP'
              },
            },
            {
              path: "subscriber/new",
              name: "NewsletterSubscriberNew",
              component: NewsletterSubscriberNew,
              meta: {
                label: "Abonnenten anlegen",
                // requiredPlan: 'STARTUP'
              },
            },
            {
              path: "groups",
              name: "NewsletterGroups",
              component: NewsletterGroups,
              meta: {
                label: "Gruppen",
                // requiredPlan: 'STARTUP'
              },
            },
            {
              path: "generator",
              name: "Generator",
              component: NewsletterGeneratorPage,
              meta: {
                label: "Generator",
                //requiredPlan 'STARTUP'
              },
            },
            {
              path: "opt-in",
              name: "Opt-In",
              component: NewsletterOptIn,
              meta: {
                label: "Opt-In",
                //requiredPlan 'STARTUP'
              },
            },
          ],
        },
        {
          path: "users",
          name: "Users",
          component: Users,
          props: {
            showIds: false,
          },
          meta: {
            label: "Benutzer",
            requiredRole: "REALM_ADMIN",
          },
        },
        {
          path: "contacts/propstack",  // Dies entspricht dem 'to' Pfad in _nav.js
          name: "PropstackIntegration",
          component: PropstackIntegration,
          meta: {
            label: "Propstack Integration",
            requiresAuth: true
          }
        },
        {
          path: "users/new",
          name: "New user",
          component: NewUser,
          meta: {
            label: "Benutzer hinzufügen",
            requiredRole: "REALM_ADMIN",
          },
        },
        {
          path: "users/edit/:id",
          name: "Edit user",
          component: EditUser,
          meta: {
            label: "Benutzer editieren",
            requiredRole: "REALM_ADMIN",
          },
        },
        {
          path: "kensington-locations",
          name: "KensingtonLocations",
          component: KensingtonLocations,
          meta: {
            label: "Kensington Standorte",
            requiredRole: "ORGANIZATION_ADMIN",
            requiredPlan: "KENSINGTON",
          },
        },
        {
          path: "kensington-locations/new",
          name: "KensingtonLocationNew",
          component: KensingtonLocationNew,
          meta: {
            label: "Kensington Standort hinzufügen",
            requiredRole: "ORGANIZATION_ADMIN",
            requiredPlan: "KENSINGTON",
          },
        },
        {
          path: "kensington-locations/edit/:id",
          name: "KensingtonLocationEdit",
          component: KensingtonLocationEdit,
          meta: {
            label: "Kensington Standorte",
            requiredRole: "ORGANIZATION_ADMIN",
            requiredPlan: "KENSINGTON",
          },
        },
        {
          path: "kensington-locations-import",
          name: "KensingtonLocationImportPage",
          component: KensingtonLocationImportPage,
          meta: {
            label: "Kensington Import",
            requiredRole: "ORGANIZATION_ADMIN",
            requiredPlan: "KENSINGTON",
          },
        },
        {
          path: "forbidden",
          name: "PlanForbidden",
          component: PlanForbidden,
          meta: {
            label: "Zugriff verwährt",
          },
        },
        {
          path: "notifications",
          name: "Notifications",
          component: NotificationsPage,
          meta: {
            label: "Benachrichtigungen",
            requiresAuth: true
          }
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
          meta: { requiresAuth: false }
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
          meta: { requiresAuth: false }
        },
        {
          path: "login",
          name: "Login",
          component: Login,
          meta: { requiresAuth: false }
        },
        {
          path: "userRegister",
          name: "UserRegister",
          component: UserRegisterByToken,
          meta: { requiresAuth: false }
        },
        {
          path: "forgotPassword",
          name: "ForgotPassword",
          component: ForgotPassword,
          meta: { requiresAuth: false }
        },
        {
          path: "passwordResetByToken",
          name: "PasswordResetByToken",
          component: PasswordResetByToken,
          meta: { requiresAuth: false }
        },
        {
          path: "logout",
          name: "Logout",
          component: Logout,
        },
      ],
    },
    {
      path: "*",
      redirect: "/pages/404",
      name: "EverythingElse",
      component: {
        render(c) {
          return c("router-view");
        },
      },
    },
  ];
}
