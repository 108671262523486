import axios from "axios";
import toastPlugin from "@/toastPlugin";
import moment from "moment-timezone";

const state = {
  notifications: [],
  unreadNotifications: [],
  activeTab: 'unread',
  openedEmails: [],
};

const getters = {
  activeNotifications: (state) =>
    state.activeTab === "unread"
      ? state.unreadNotifications
      : state.notifications,
  unreadNotificationsCount: (state) => state.unreadNotifications.length,
  highPriorityNotificationsCount: (state) =>
    state.notifications.filter(
      (notification) => notification.priority === "high"
    ).length,
  openedEmailsCount: (state) => state.openedEmails.length,
  allOpenedEmails: (state) => state.openedEmails,
};

const actions = {
  setActiveTab({ commit }, tab) {
    commit("SET_ACTIVE_TAB", tab);
  },

  async fetchNotifications({ commit }) {
    try {
      const [allResponse, unreadResponse] = await Promise.all([
        axios.get("/api/notifications"),
        axios.get("/api/notifications/unread"),
      ]);

      if (allResponse.data) {
        const formattedAll = allResponse.data.map((notification) => ({
          ...notification,
          time: moment(notification.createdAt).format("LLL"),
        }));
        commit("SET_NOTIFICATIONS", formattedAll);
      }

      if (unreadResponse.data) {
        const formattedUnread = unreadResponse.data.map((notification) => ({
          ...notification,
          time: moment(notification.createdAt).format("LLL"),
        }));
        commit("SET_UNREAD_NOTIFICATIONS", formattedUnread);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  },

  addNotification({ commit }, notification) {
    const formattedNotification = {
      ...notification,
      time: moment(notification.createdAt).format("LLL"),
    };
    commit("ADD_NOTIFICATION", formattedNotification);
    if (!formattedNotification.read) {
      commit("ADD_UNREAD_NOTIFICATION", formattedNotification);
    }
  },

  async createNotification({ commit }, notification) {
    try {
      const localDate = moment().tz("Europe/Berlin").format();
      const notificationWithCorrectTime = {
        ...notification,
        createdAt: localDate,
      };
      const response = await axios.post(
        "/api/notifications",
        notificationWithCorrectTime
      );
      const correctedNotification = {
        ...response.data,
        time: moment(response.data.createdAt).format("LLL"),
      };
      commit("ADD_NOTIFICATION", correctedNotification);
      if (!correctedNotification.read) {
        commit("ADD_UNREAD_NOTIFICATION", correctedNotification);
      }
    } catch (error) {
      console.error("Error creating notification:", error);
    }
  },

  async markAllAsRead({ commit }) {
    try {
      await axios.put('/api/notifications/read-all');
      const [allResponse, unreadResponse] = await Promise.all([
        axios.get('/api/notifications'),
        axios.get('/api/notifications/unread')
      ]);

      if (allResponse.data) {
        const formattedAll = allResponse.data.map(notification => ({
          ...notification,
          time: moment(notification.createdAt).format('LLL')
        }));
        commit('SET_NOTIFICATIONS', formattedAll);
      }

      if (unreadResponse.data) {
        commit('SET_UNREAD_NOTIFICATIONS', []);
      }

      toastPlugin.makeToast('Erfolg', 'Alle Benachrichtigungen als gelesen markiert');
      return true;
    } catch (error) {
      console.error('Error marking all as read:', error);
      toastPlugin.makeToast('Fehler', 'Fehler beim Markieren aller Benachrichtigungen');
      return false;
    }
  },

    async markNotificationAsRead({ commit }, id) {
    try {
      const response = await axios.put(`/api/notifications/${id}/read`);
      const updatedNotification = {
        ...response.data,
        time: moment(response.data.createdAt).format("LLL"),
      };

      commit("UPDATE_NOTIFICATION", updatedNotification);
      commit("REMOVE_FROM_UNREAD", id);

      toastPlugin.makeToast("Erfolg", "Benachrichtigung als gelesen markiert.");
      return true;
    } catch (error) {
      console.error("Error marking notification as read:", error);
      toastPlugin.makeToast(
        "Fehler",
        "Fehler beim Markieren der Benachrichtigung als gelesen."
      );
      return false;
    }
  },

  handleNewNotification({ commit }, notification) {
    const formattedNotification = {
      ...notification,
      time: moment(notification.createdAt).format("LLL"),
    };

    commit("UPDATE_NOTIFICATION", formattedNotification);
    if (!formattedNotification.read) {
      commit("ADD_UNREAD_NOTIFICATION", formattedNotification);
    } else {
      commit("REMOVE_FROM_UNREAD", formattedNotification.id);
    }
  },

  async removeNotification({ commit }, id) {
    try {
      const apiUrl = `/api/notifications/${id}`;
      const response = await axios.delete(apiUrl);

      if (response.status === 200 || response.status === 204) {
        commit("REMOVE_NOTIFICATION", id);
        commit("REMOVE_FROM_UNREAD", id);

        // Aktualisiere die Listen
        const [allResponse, unreadResponse] = await Promise.all([
          axios.get("/api/notifications"),
          axios.get("/api/notifications/unread"),
        ]);

        const formattedAll = allResponse.data.map((notification) => ({
          ...notification,
          time: moment(notification.createdAt).format("LLL"),
        }));
        commit("SET_NOTIFICATIONS", formattedAll);

        const formattedUnread = unreadResponse.data.map((notification) => ({
          ...notification,
          time: moment(notification.createdAt).format("LLL"),
        }));
        commit("SET_UNREAD_NOTIFICATIONS", formattedUnread);

        // Korrigierter Toast-Aufruf mit zwei Parametern
        toastPlugin.makeToast("Erfolg", "Benachrichtigung erfolgreich gelöscht.");
        return true;
      }
      return false;
    } catch (error) {
      console.error('Fehler in removeNotification:', error);
      // Korrigierter Toast-Aufruf für den Fehlerfall
      toastPlugin.makeToast("Fehler", "Fehler beim Löschen der Benachrichtigung");
      return false;
    }
  },

  addOpenedEmail({ commit }, email) {
    commit("ADD_OPENED_EMAIL", email);
  },

  updateOpenedEmail({ commit }, email) {
    commit("UPDATE_OPENED_EMAIL", email);
  },

  clearOpenedEmails({ commit }) {
    commit("CLEAR_OPENED_EMAILS");
  }
};

const mutations = {
  SET_ACTIVE_TAB(state, tab) {
    state.activeTab = tab;
  },

  SET_NOTIFICATIONS(state, notifications) {
    if (Array.isArray(notifications)) {
      state.notifications = notifications;
    } else {
      console.error(
        "Expected notifications to be an array, but got:",
        notifications
      );
    }
  },

  SET_UNREAD_NOTIFICATIONS(state, notifications) {
    if (Array.isArray(notifications)) {
      state.unreadNotifications = notifications;
    } else {
      console.error(
        "Expected unread notifications to be an array, but got:",
        notifications
      );
    }
  },

  ADD_NOTIFICATION(state, notification) {
    const existingIndex = state.notifications.findIndex(
      (n) => n.id === notification.id
    );
    if (existingIndex >= 0) {
      state.notifications[existingIndex] = notification;
    } else {
      state.notifications.unshift(notification);
    }
  },

  ADD_UNREAD_NOTIFICATION(state, notification) {
    const existingIndex = state.unreadNotifications.findIndex(
      (n) => n.id === notification.id
    );
    if (existingIndex >= 0) {
      state.unreadNotifications[existingIndex] = notification;
    } else {
      state.unreadNotifications.unshift(notification);
    }
  },

  UPDATE_NOTIFICATION(state, notification) {
    const index = state.notifications.findIndex(
      (n) => n.id === notification.id
    );
    if (index >= 0) {
      state.notifications[index] = notification;
    } else {
      state.notifications.unshift(notification);
    }

    if (notification.read) {
      state.unreadNotifications = state.unreadNotifications.filter(
        (n) => n.id !== notification.id
      );
    } else {
      const unreadIndex = state.unreadNotifications.findIndex(
        (n) => n.id === notification.id
      );
      if (unreadIndex >= 0) {
        state.unreadNotifications[unreadIndex] = notification;
      } else {
        state.unreadNotifications.unshift(notification);
      }
    }
  },

  REMOVE_FROM_UNREAD(state, id) {
    state.unreadNotifications = state.unreadNotifications.filter(
      (n) => n.id !== id
    );
  },

  REMOVE_NOTIFICATION(state, id) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== id
    );
    state.unreadNotifications = state.unreadNotifications.filter(
      (notification) => notification.id !== id
    );
  },

  SET_OPENED_EMAILS(state, emails) {
    if (Array.isArray(emails)) {
      state.openedEmails = emails;
    } else {
      console.error("Expected emails to be an array, but got:", emails);
      state.openedEmails = [];
    }
  },

  ADD_OPENED_EMAIL(state, email) {
    state.openedEmails.unshift(email);
    if (state.openedEmails.length > 10) {
      state.openedEmails.pop();
    }
  },

  CLEAR_OPENED_EMAILS(state) {
    state.openedEmails = [];
  },

  UPDATE_OPENED_EMAIL(state, updatedEmail) {
    const index = state.openedEmails.findIndex(
      (email) => email.id === updatedEmail.id
    );
    if (index !== -1) {
      state.openedEmails.splice(index, 1, updatedEmail);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
