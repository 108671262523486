<template>
  <div class="weekly-review">
    <!-- Header Section -->
    <CRow class="header-section mb-5">
      <CCol>
        <h2 class="title">Ihre Social Media Beiträge</h2>
        <div class="subtitle-wrapper">
          <p class="subtitle">
            Überprüfen und genehmigen Sie geplanten Beiträge für diese Woche
          </p>
          <CBadge color="primary">{{ weeklyPosts.length }} Beiträge</CBadge>
        </div>
      </CCol>
    </CRow>

    <!-- Empty State -->
    <div v-if="!weeklyPosts.length" class="empty-state">
      <CIcon name="cil-media-play" size="xl" />
      <h3>Keine freizugebende Beiträge vorhanden</h3>
      <p>
        Wenn Sie das KI Interview abgeschlossen haben, erscheinen hier Ihre
        neuen Beiträge Freitags ab 08:00 Uhr
      </p>
      <CButton
        color="primary"
        variant="outline"
        @click="$router.push('/socialMedia/post/new')"
        >Beitrag erstellen
      </CButton>
    </div>

    <!-- Posts Grid -->
    <CRow v-else class="posts-grid">
      <CCol
        v-for="post in weeklyPosts"
        :key="post.id"
        sm="12"
        md="6"
        lg="4"
        class="mb-4"
      >
        <CCard class="post-card">
          <div class="post-status-banner">
            Entwurf
          </div>
          <div class="post-image-wrapper">
            <CImg
              v-if="post.imageUrl"
              :src="post.imageUrl"
              class="post-image"
              :alt="post.title"
            />
            <div v-else class="post-image-placeholder">
              <CIcon name="cil-image" size="xl" />
            </div>
          </div>

          <!-- Post Content -->
          <CCardBody class="post-content">
            <div class="post-header">
              <h4 class="post-title">{{ post.name }}</h4>
              <!-- Neue Elemente für Datum und Social Media -->
              <div class="post-info">
                <div class="post-date">
                  <CIcon name="cil-calendar" class="me-1"/>
                  {{ formatPublishDate(post.publishAt) }}
                </div>
                <div class="social-networks">
              <span
                v-for="conn in getConnections(post.connection)"
                :key="conn.id"
                :class="getNetworkClass(conn.socialMedia)"
                class="network-badge"
              >
                <CIcon :name="conn.icon" size="sm"/>
                {{ conn.socialMedia }}
              </span>
                </div>
              </div>
              <CTags :tags="post.tags" class="post-tags" />
            </div>

            <p class="post-text">{{ post.content }}</p>

            <!-- Action Buttons -->
            <div class="post-actions">
              <CButton
                color="success"
                class="action-button approve"
                @click="approvePost(post.id)"
              >
                <CIcon name="cil-check" />
                Genehmigen
              </CButton>
              <div class="secondary-actions">
                <CButton
                  variant="ghost"
                  @click="openEditModal(post)"
                  class="action-button edit"
                >
                  <CIcon name="cil-pencil" />
                </CButton>
                <CButton
                  variant="ghost"
                  color="danger"
                  @click="rejectPost(post.id)"
                  class="action-button reject"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Edit Modal -->
    <EditPostModal
      :show="showEditModal"
      :post="editingPost"
      @close="closeEditModal"
      @save="saveEditedPost"
    />
  </div>
</template>

<script>
import EditPostModal from "./EditPostModal.vue";
import CTags from "./CTags.vue";
import axios from "axios";
import toastPlugin from "@/toastPlugin";
import moment from 'moment';
import 'moment/locale/de';

export default {
  components: {
    CTags,
    toastPlugin,
    EditPostModal,
  },
  data() {
    return {
      weeklyPosts: [], // Hier speichern wir die nicht genehmigten Posts
      showEditModal: false,
      editingPost: null,
      socialMediaConnections: {}, // Initialisiert als leeres Objekt
    };
  },
  computed: {
    hasApprovedPosts() {
      return this.weeklyPosts.some((post) => post.approved);
    },
  },
  methods: {
    formatPublishDate(date) {
      if (!date) return 'Kein Datum';
      moment.locale('de');
      return moment(date).format('DD.MM.YYYY - HH:mm [Uhr]');
    },
    getNetworkClass(network) {
      const classes = {
        FACEBOOK: 'facebook',
        LINKEDIN: 'linkedin',
        INSTAGRAM: 'instagram',
        TWITTER: 'twitter'
      };
      return classes[network] || 'default';
    },

    getConnections(connection) {
      if (!connection) return [];
      if (Array.isArray(connection)) return connection;
      return [connection]; // Wenn es ein Objekt ist, verpacke es in ein Array
    },

    fetchConnections() {
      axios
        .get("/socialMedia/connections")
        .then((response) => {
          this.socialMediaConnections = response.data; // Verbindungen speichern
          console.log(
            "Social Media Connections fetched:",
            this.socialMediaConnections,
          );
        })
        .catch((error) => {
          console.error(
            "Fehler beim Abrufen der Social Media Verbindungen:",
            error,
          );
          this.$toaster.error(
            "Fehler beim Abrufen der Social Media Verbindungen.",
          );
        });
    },
    openEditModal(post) {
      // Stelle sicher, dass connection ein Array ist für das EditModal
      this.editingPost = {
        ...post,
        connection: this.getConnections(post.connection)
      };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false; // Modal schließen
      this.editingPost = null;
    },
    saveEditedPost(editedPost) {
      // Stelle sicher, dass connection ein Array ist beim Speichern
      const postToSave = {
        ...editedPost,
        connection: this.getConnections(editedPost.connection)
      };

      axios
        .put(`/socialMedia/posts/${postToSave.id}`, postToSave)
        .then((response) => {
          // Aktualisiere den Beitrag in der weeklyPosts-Liste
          const index = this.weeklyPosts.findIndex(
            (p) => p.id === postToSave.id,
          );
          if (index !== -1) {
            this.$set(this.weeklyPosts, index, response.data);
          }
          this.closeEditModal();
        })
        .catch((error) => {
          console.error(
            "Fehler beim Speichern des bearbeiteten Beitrags:",
            error,
          );
        });
    },
    async approvePost(id) {
      const post = this.weeklyPosts.find((p) => p.id === id);

      if (!post) {
        this.$toaster.makeToast(
          "Fehler",
          "Beitrag konnte nicht gefunden werden",
        );
        return;
      }

      // Stelle sicher, dass connection ein Array ist
      const connections = this.getConnections(post.connection);

      if (!connections.length) {
        this.$toaster.makeToast(
          "Fehler",
          "Keine Social Media Verbindung gefunden",
        );
        return;
      }

      try {
        await axios.put(`/socialMedia/posts/${id}`, {
          ...post,
          approved: true,
          connection: connections // Sende connection als Array
        });

        this.$toaster.makeToast("Erfolg", "Beitrag erfolgreich genehmigt");
        this.weeklyPosts = this.weeklyPosts.filter((p) => p.id !== id);
      } catch (error) {
        console.error("Fehler beim Genehmigen des Beitrags:", error);
        this.$toaster.makeToast(
          "Fehler",
          "Fehler beim Genehmigen des Beitrags",
        );
      }
    },
    rejectPost(id) {
      axios
        .delete(`/socialMedia/posts/${id}`)
        .then(() => {
          this.weeklyPosts = this.weeklyPosts.filter((post) => post.id !== id);
          this.$toaster.success("Beitrag erfolgreich abgelehnt und gelöscht");
          console.log(`Post deleted: ${id}`);
        })
        .catch((error) => {
          console.error("Fehler beim Löschen des Beitrags:", error);
          this.$toaster.error("Fehler beim Löschen des Beitrags");
        });
    },
    finalizeReview() {
      const approvedPosts = this.weeklyPosts.filter((post) => post.approved);

      // Beispielsweise ein API-Call oder ein Emit-Event, um die Überprüfung abzuschließen
      if (approvedPosts.length > 0) {
        // Beispiel-Logik für das Abschließen der Überprüfung
        console.log("Überprüfung abgeschlossen mit:", approvedPosts);
        this.$emit("reviewFinalized", approvedPosts);
        this.$toaster.makeToast(
          "Erfolgreich!",
          "Die Überprüfung wurde erfolgreich abgeschlossen.",
        );
      } else {
        this.$toaster.makeToast(
          "Fehler",
          "Es gibt keine genehmigten Beiträge.",
        );
      }
    },
    fetchUnapprovedPosts() {
      axios
        .get("/socialMedia/posts")
        .then((response) => {
          this.weeklyPosts = response.data.filter((post) => !post.approved);
          console.log("Unapproved posts fetched:", this.weeklyPosts);

          // Debugging-Ausgabe: Alle Beiträge auf socialMedia prüfen
          this.weeklyPosts.forEach((post) => {
            if (!post.socialMedia) {
              // Versuche, eine Default-Verbindung zu setzen, falls verfügbar
              const defaultConnection = Object.values(
                this.socialMediaConnections,
              )[0]; // Nimm die erste Verbindung
              if (defaultConnection) {
                post.socialMedia = defaultConnection.socialMedia; // Setze das socialMedia-Feld
                post.connection = defaultConnection; // Setze die Verbindung
              }
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
          this.$toast.error("Fehler beim Abrufen der Beiträge.");
        });
    },
  },
  mounted() {
    this.fetchConnections(); // Beim Laden der Seite Verbindungen abrufen
    this.fetchUnapprovedPosts();
  },
};
</script>

<style scoped>
.weekly-review {
  padding: 2rem;
  background-color: #f8f9fa;
}

.header-section {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.subtitle-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.subtitle {
  color: #6c757d;
  margin: 0;
}

.empty-state {
  text-align: center;
  padding: 4rem 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.post-card {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
}

.post-status-banner {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: #f3f4f6;
  color: #6b7280;
  z-index: 1;
}
.post-card:hover {
  transform: translateY(-4px);
}

.post-image-wrapper {
  height: 200px;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  background: #f8f9fa;
}

.post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-image-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
}

.post-content {
  padding: 1.5rem;
}

.post-header {
  margin-bottom: 1rem;
}

.post-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.post-text {
  color: #6c757d;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.post-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}

.secondary-actions {
  display: flex;
  gap: 0.5rem;
}

.approve {
  flex: 1;
  max-width: 150px;
}

.edit:hover {
  background: #e9ecef;
}

.reject:hover {
  background: #fee2e2;
}

.post-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 0.75rem 0;
}

.post-date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px 8px;
  background-color: #f3f4f6;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #4b5563;
  font-weight: 500;
}

.social-networks {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.network-badge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.network-badge:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.network-badge.facebook {
  background-color: #1877f2;
  color: white;
}

.network-badge.linkedin {
  background-color: #0a66c2;
  color: white;
}

.network-badge.instagram {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  color: white;
}

.network-badge.twitter {
  background-color: #1da1f2;
  color: white;
}

.network-badge.default {
  background-color: #e5e7eb;
  color: #4b5563;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .post-info {
    gap: 0.5rem;
  }

  .post-date {
    font-size: 0.8125rem;
  }

  .network-badge {
    padding: 3px 6px;
    font-size: 0.7rem;
  }
}
</style>
