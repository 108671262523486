<template>
  <div class="skeleton-chart">
    <div class="skeleton-chart-bar" v-for="n in 5" :key="n"></div>
  </div>
</template>

<script>
export default {
  name: "SkeletonChart",
};
</script>

<style scoped>
.skeleton-chart {
  display: flex;
  align-items: flex-end;
  height: 200px;
  background-color: #f0f0f0;
  padding: 10px;
}

.skeleton-chart-bar {
  flex-grow: 1;
  background-color: #e0e0e0;
  margin: 0 5px;
  animation: pulse 1.5s infinite;
}

.skeleton-chart-bar:nth-child(1) {
  height: 60%;
}

.skeleton-chart-bar:nth-child(2) {
  height: 80%;
}

.skeleton-chart-bar:nth-child(3) {
  height: 40%;
}

.skeleton-chart-bar:nth-child(4) {
  height: 70%;
}

.skeleton-chart-bar:nth-child(5) {
  height: 50%;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
