<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>New realm</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <RealmForm
              @submit="createNewRealm"
              :processing="processing"
              btn-text="Create now"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import RealmForm from "./base/RealmForm";

export default {
  name: "NewRealm",
  components: { RealmForm },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    createNewRealm(data) {
      this.processing = true;
      axios
          .post("/realm", data)
          .then(() => {
            this.$router.push({ name: "Realms" });
            this.$toaster.makeToast(
                "Success!",
                "<b>Instanz erfolgreich erstellt!</b>"
            );
          })
          .catch((err) => {
            let userFriendlyMessage = "Oops! Es ist ein unerwarteter Fehler aufgetreten.";

            if (err.response) {
              // Debug-Informationen für Entwickler in der Konsole
              console.error("Error response:", err.response);

              // Überprüfe, ob es sich um eine Validierungsfehlermeldung handelt
              if (
                  err.response.data &&
                  typeof err.response.data === "string" &&
                  err.response.data.includes("Validation failed")
              ) {
                userFriendlyMessage = "Einige Eingaben sind ungültig oder fehlen:<br>";

                // Extrahiere die einzelnen Fehler aus der Fehlermeldung
                const validationErrors = err.response.data.match(/Field error in object '[^']+' on field '([^']+)'.*?default message \[([^\]]+)\]/g);

                if (validationErrors) {
                  validationErrors.forEach((error) => {
                    const matches = error.match(/on field '([^']+)'.*?default message \[([^\]]+)\]/);
                    if (matches && matches.length === 3) {
                      const field = matches[1];
                      const message = matches[2];
                      userFriendlyMessage += `- <b>${field}:</b> ${message}<br>`;
                    }
                  });
                }
              } else if (err.response.status === 500) {
                userFriendlyMessage = "Es ist ein interner Fehler aufgetreten. Bitte versuche es später noch einmal oder kontaktiere den Support.";
              } else if (err.response.status === 400) {
                userFriendlyMessage = "Es scheint, als fehlen noch einige Angaben. Bitte überprüfe das Formular und versuche es erneut.";
              }
            } else {
              // Netzwerkfehler oder andere allgemeine Fehler
              userFriendlyMessage = "Es gab ein Problem bei der Verbindung. Bitte prüfe deine Internetverbindung und versuche es erneut.";
            }

            this.$toaster.makeToast("Fehler!", userFriendlyMessage);
          })
          .finally(() => (this.processing = false));
    },
  },
};
</script>
