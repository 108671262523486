<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong>Neuen Beitrag erstellen</strong>
          </CCardHeader>
          <CCardBody>
            <SocialMediaPostForm
              @submit="createSocialMediaPost"
              @fileSelected="handleFileUpload"
              @open-ai-tool="openAiContentGenerator"
              :processing="processing"
              :enable-delete="false"
              :passed-data="$route.params.passedData"
              btn-text="Speichern"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <KIPopup
      :show="showPopup"
      @close="showPopup = false"
      @apply="applyKIGeneratedContent"
    />
  </div>
</template>

<script>
import axios from "axios";
import SocialMediaPostForm from "./SocialMediaPostForm.vue";
import ImageUploadField from "@/components/ImageUploadField.vue";
import KIPopup from "@/views/base/social-media/KIPopup.vue";

export default {
  name: "SocialMediaPostNew",
  components: {
    SocialMediaPostForm,
    ImageUploadField,
    KIPopup,
  },
  data() {
    return {
      postData: {
        title: "",
        content: "",
        imageUrl: "",
      },
      processing: false,
      showPopup: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.params.saved) {
      next();
      return;
    }
    this.$modal.showModal(
      "default",
      "Editor wirklich schließen?",
      [
        `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`,
      ],
      () => {
        next();
      },
      () => {
        next(false);
      },
      {
        btnCancel: "abbrechen",
        btnConfirm: "Trotzdem schließen",
        btnConfirmColor: "danger",
      },
    );
  },
  methods: {
    handleFileUpload(formData) {
      axios
        .post("/file/upload", formData)
        .then((response) => {
          this.postData.imageUrl = response.data.fileDownloadUrl;
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    },
    createSocialMediaPost(data) {
      console.log("createSocialMediaPost Methode aufgerufen mit Daten:", data);
      this.processing = true;
      this.postData = { ...data };
      axios
        .post("/socialMedia/posts", this.postData)
        .then(() => {
          this.$router.push({
            name: "SocialMediaPosts",
            params: { saved: "1" },
          });
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Social Media Beitrag wurde erfolgreich angelegt!</b>",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Error!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    updateImageUrl(url) {
      this.postData.imageUrl = url;
    },
    updateImageTitle(title) {
      this.postData.imageTitle = title;
    },
    openAiContentGenerator() {
      this.showPopup = true;
    },
    applyKIGeneratedContent(content) {
      // Formatiere den gesamten Beitrag mit Überschrift, Haupttext, CTA und Hashtags
      const fullContent = `${content.title}\n\n${content.content}\n\n${content.postFrequencySuggestion}\n\n${content.keywords.map((keyword) => `#${keyword}`).join(" ")}`;

      // Setze den formatierten Content auf `postData.content`
      this.postData.content = fullContent.trim();
      this.showPopup = false;
    },
  },
};
</script>

<style scoped>
.upload-and-search {
  display: flex;
  gap: 20px;
  align-items: center;
}

.upload-section,
.search-section {
  flex: 1;
}

.search-bar {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-bar button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.unsplash-photos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.photo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  height: 350px;
}

.photo-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Passt das Bild innerhalb des Containers an und behält die Proportionen bei */
  height: auto; /* Verhindert Verzerrungen */
  width: auto; /* Verhindert Verzerrungen */
}

.photo-info {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #05b9ae;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  background-color: #005f5b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination span {
  display: flex;
  align-items: center;
}
</style>
