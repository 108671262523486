var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CChartPolarArea',{attrs:{"datasets":_vm.defaultDatasets,"options":_vm.defaultOptions,"labels":[
    'Eating',
    'Drinking',
    'Sleeping',
    'Designing',
    'Coding',
    'Cycling',
    'Running',
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }