var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('div',{staticClass:"prt-card-header"},[_c('div',{staticClass:"header-naming"},[_c('CIcon',{attrs:{"name":"cil-window-restore"}}),_vm._v(" Lead Funnels ")],1),_c('div',{staticClass:"header-button"},[_c('CButton',{key:"key",attrs:{"to":"leadGenerator/new","name":"backagain","size":"sm","color":"dark"}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-plus"}}),_c('span',[_vm._v("Neuen Funnel erstellen")])],1)],1)])]})],2),(!_vm.loading)?_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.tableData,"itemsPerPageSelect":{ label: 'Leadgeneratoren pro Seite' },"fields":_vm.getTableFields(),"noItemsView":{
              noResults: 'Keine Suchergebnisse verfügbar',
              noItems: 'Keine Lead-Funnels verfügbar',
            },"hover":"","striped":"","bordered":"","fixed":"","column-filter":"","table-filter":"","tableFilter":{
              label: 'Suchen',
              placeholder: 'Suchbegriff eingeben',
            },"items-per-page-select":"","sorter":"","sorterValue":{ column: 'name', asc: true },"pagination":"","loading":_vm.tableShowLoading},scopedSlots:_vm._u([{key:"pages",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(JSON.parse(item.contentJson).pages.length)+" Schritte ")])]}},{key:"active",fn:function({ item }){return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getTableBadge(item.active)}},[_vm._v(" "+_vm._s(item.active ? "Aktiv" : "Deaktiv")+" ")])],1)]}},{key:"updated",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.updated).format(_vm.$commons.DATE_TIME_FORMAT))+" ")])]}},{key:"show_details",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"to":'leadGenerator/edit/' + item.id,"color":"primary","variant":"outline","square":"","size":"sm"}},[_vm._v(" Bearbeiten ")])],1)]}}],null,false,2369803528)})],1):_vm._e()],1),_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('strong',[_vm._v("Hilfe")])]})],2),_c('CCardBody',[_c('p',[_vm._v(" Hier können Sie neue Funnels erstellen und bestehende Funnels bearbeiten. ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }