<template>
  <div>
    <LgcModule
      class="lgc-module-image"
      @click="imageEditVisible = true"
      @delete="$emit('delete', $event)"
      @add-below="$emit('add-below', $event)"
    >
      <img :src="dataValue" alt="image" />
    </LgcModule>
    <CModal
      title="Bild bearbeiten"
      size=""
      :show.sync="imageEditVisible"
      :closeOnBackdrop="true"
    >
      <div class="lgc-module-image--edit">
        <CRow>
          <CCol>
            <ImageUploadField
              v-if="imageEditVisible"
              v-model="dataValue"
              label="Bild ändern"
              @delete=""
            />
          </CCol>
        </CRow>
      </div>
      <div slot="footer">
        <CButton
          class="mr-3"
          color="success"
          variant="outline"
          @click="imageEditVisible = false"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import _ from "lodash";
import ImageUploadField from "@/components/ImageUploadField";
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";

export default {
  name: "LgcModuleImage",
  components: {
    LgcModule,
    ImageUploadField,
  },
  props: {
    value: String,
  },
  data() {
    return {
      dataValue: this.value,
      imageEditVisible: false,
    };
  },
  watch: {
    dataValue(newVal) {
      this.$emit("input", { value: newVal });
    },
  },
};
</script>

<style lang="scss" scoped>
.lgc-module-image {
  margin: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
