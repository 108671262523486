<template>
  <CRow>
    <CCol md="12">
      <WysiwygEditor v-model="notes" />
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import WysiwygEditor from "@/components/WysiwygEditor";

export default {
  components: {
    WysiwygEditor,
  },

  props: ["closeModal", "applyUpdate"],

  data: () => ({
    notes: "",
    selectedDealId: null,
    currentNoteId: null  // Neue Property zum Tracking der aktuellen Notiz-ID
  }),

  methods: {
    async submit(dealId) {
      console.log('Submit Notizen für Deal:', dealId);

      if (!this.notes.trim()) {
        console.log('Keine Notizen zum Speichern vorhanden');
        return Promise.resolve();
      }

      const data = {
        notes: this.notes,
        deal: {
          id: dealId
        }
      };

      console.log('Sende Notizen-Daten:', data);

      try {
        let response;
        if (this.currentNoteId) {
          // Update existierende Notiz
          console.log('Aktualisiere existierende Notiz:', this.currentNoteId);
          response = await axios.put(`/notes/${this.currentNoteId}`, data);
        } else {
          // Erstelle neue Notiz
          console.log('Erstelle neue Notiz');
          response = await axios.post("/notes", data);
        }

        console.log('Notizen erfolgreich gespeichert:', response.data);

        // Aktualisiere die currentNoteId mit der ID der gespeicherten Notiz
        this.currentNoteId = response.data.id;

        if (this.applyUpdate) {
          this.applyUpdate(response.data);
        }
        this.$emit('notes-updated', response.data);
        return response.data;
      } catch (error) {
        console.error('Fehler beim Speichern der Notizen:', error);
        throw error;
      }
    },

    initialize(dealId = null) {
      console.log('Initialisiere NotesWidget mit dealId:', dealId);
      this.selectedDealId = dealId;
      this.notes = "";
      this.currentNoteId = null;  // Reset der currentNoteId

      if (dealId) {
        this.loadNotesForDeal(dealId);
      }
    },

    async loadNotesForDeal(dealId) {
      try {
        console.log('Lade Notizen für Deal:', dealId);
        const response = await axios.get(`/notes/deal/${dealId}`);
        if (response.data && response.data.length > 0) {
          const latestNote = response.data[0];
          this.notes = latestNote.notes || '';
          this.currentNoteId = latestNote.id;  // Speichere die ID der geladenen Notiz
          console.log('Notizen geladen:', this.notes);
        } else {
          console.log('Keine bestehenden Notizen gefunden');
          this.notes = '';
          this.currentNoteId = null;
        }
      } catch (error) {
        console.error('Fehler beim Laden der Notizen:', error);
        this.notes = '';
        this.currentNoteId = null;
      }
    },

    initializeWithEntity(entity) {
      console.log('Initialisiere mit Entity:', entity);
      this.notes = entity.notes || '';
      this.selectedDealId = entity.deal && entity.deal.id;
      this.currentNoteId = entity.id;  // Speichere die ID der Notiz
    }
  },

  watch: {
    notes(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style>
.tox-tinymce {
  min-height: 300px !important;
}
</style>
