<template>
  <div id="hubspotForm" v-once></div>
</template>

<script>
export default {
  mounted() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "7243510",
          formId: "e7ec29ba-f839-4eb4-9ccd-9d38e46db862",
          target: "#hubspotForm",
        });
      }
    });
  },
};
</script>