<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <div>
              <strong>Abonnent bearbeiten</strong>
              <small class="text-muted ms-2">#{{ $route.params.id }}</small>
            </div>
            <div v-if="subscriber">
              <CBadge :color="subscriber.verified ? 'success' : 'warning'">
                {{ subscriber.verified ? "Verifiziert" : "Nicht verifiziert" }}
              </CBadge>
            </div>
          </CCardHeader>
          <CCardBody>
            <NewsletterSubscriberForm
                v-if="ready"
                @submit="editSubscriber"
                :enable-delete="true"
                :passedData="subscriber"
                :processing="processing"
                btn-text="Änderungen speichern"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import NewsletterSubscriberForm from "@/views/base/newsletter/NewsletterSubscriberForm";

export default {
  name: "NewsletterSubscriberEdit",
  components: { NewsletterSubscriberForm },

  data() {
    return {
      ready: false,
      subscriber: null,
      processing: false,
    };
  },

  created() {
    this.loadSubscriber();
  },

  methods: {
    async loadSubscriber() {
      try {
        const response = await axios.get(`/newsletter/subscriber/${this.$route.params.id}`);
        this.subscriber = response.data;
        this.ready = true;
      } catch (error) {
        this.$toaster.makeToast("Fehler", "Laden des Abonnenten fehlgeschlagen");
      }
    },

    async editSubscriber(data) {
      this.processing = true;
      try {
        await axios.put(`/newsletter/subscriber/${this.$route.params.id}`, data);
        this.$router.push({
          name: "NewsletterSubscribers",
          params: { saved: "1" },
        });
        this.$toaster.makeToast(
            "Erfolgreich!",
            "Änderungen wurden gespeichert"
        );
      } catch (error) {
        this.$toaster.makeToast("Fehler", "Speichern fehlgeschlagen");
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
