<template>
  <CToaster :autohide="6000">
    <template v-for="(toast, index) in toasts">
      <CToast :key="'toast' + index" :show="true" :header="toast.header">
        <div v-html="toast.content"></div>
      </CToast>
    </template>
  </CToaster>
</template>

<script>
export default {
  name: "Toaster",
  data() {
    return {
      toasts: [],
    };
  },
  methods: {
    makeToast(header, content) {
      this.toasts.push({ header, content });
    },
  },
};
</script>
