<template>
  <form ref="kensingtonLocationForm" autocomplete="off">
    <CRow>
      <CCol sm="12" xs="12">
        <CInput
          label="Name"
          name="name"
          v-model="name"
          description="Bitte geben Sie einen Namen für diesen Standort ein."
          autocomplete="off"
          required
        />
      </CCol>
      <CCol sm="12" xs="12">
        <AddableList
          :horizontal="false"
          :validate-regex="/^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/"
          invalid-message="Die eingegebene PLZ ist nicht gültig. Bitte prüfen Sie Ihre Eingabe."
          label="PLZ-Bereich des Standortes"
          description="Fügen Sie alle Postleitzahlen für diesen Standort hinzu.
              Ankommende Leads die mit einer PLZ in dieser Liste übereinstimmen werden diesem Standort zugeordnet."
          :side-by-side="true"
          :allow-comma-seperated="true"
          autocomplete="off"
          v-model="postalCodeList"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12" xs="12">
        <h5>Standortleiter / Benutzer</h5>
        <hr />
      </CCol>
      <CCol col="" xs="12">
        <CInput
          label="E-Mail"
          name="email"
          v-model="email"
          :disabled="isEdit"
          autocomplete="off"
          description="Bitte geben Sie die E-Mail des Standortleiters ein. Dieser wird zur Erstellung des Standort-Benutzers genutzt."
          required
        />
      </CCol>
      <CCol col="" xs="12">
        <CInput
          label="Vorname"
          name="firstName"
          v-model="firstName"
          autocomplete="off"
          description="Bitte geben Sie den Vornamen des Standortleiters ein. Dieser wird zur Erstellung des Standort-Benutzers genutzt."
          required
        />
      </CCol>
      <CCol col="" xs="12">
        <CInput
          label="Nachname"
          name="lastName"
          v-model="lastName"
          autocomplete="off"
          description="Bitte geben Sie den Nachnamen des Standortleiters ein. Dieser wird zur Erstellung des Standort-Benutzers genutzt."
          required
        />
      </CCol>
      <CCol v-if="!isEdit" col="" xs="12">
        <CInput
          label="Passwort"
          name="initialPassword"
          v-model="initialPassword"
          description="Bitte geben Sie ein Passwort für den Benutzer des Standortleiters ein. Dieser wird zur Erstellung des Standort-Benutzers genutzt."
          type="password"
          autocomplete="new-password"
          required
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" sm="12" xs="12">
        <h5>Stammdaten</h5>
        <hr />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Straße und Hausnummer"
          description="Geben Sie die Straße und die Hausnummer des Standortes an."
          v-model="companySettings.streetAndNumber"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="PLZ"
          description="Geben Sie die Postleitzahl des Standortes an."
          v-model="companySettings.postalCode"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Ort"
          description="Geben Sie den Ort des Standortes an."
          v-model="companySettings.city"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Telefon"
          description="Geben Sie die Telefonnummer des Standortes an."
          v-model="companySettings.phoneNumber"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Fax"
          description="Geben Sie die Faxnummer des Standortes an."
          v-model="companySettings.fax"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Webseite"
          description="Geben Sie die URL der Webseite des Standortes an."
          v-model="companySettings.website"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="E-Mail"
          description="Geben Sie die E-Mail Adresse des Standortes an."
          v-model="companySettings.email"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Kontaktperson"
          description="Geben Sie die Kontaktperson des Standortes an."
          v-model="companySettings.contactPerson"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Kontaktperson: Position"
          description="Geben Sie die Position der Kontaktperson des Standortes an. (zB. CEO)"
          v-model="companySettings.contactPersonPosition"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="number"
          label="Globaler Auf- und Abschlag (%)"
          description="Die Werte aller eingehenden Leads werden nach der Wertermittlung mit diesem prozentualen Wert angepasst"
          v-model="companySettings.globalMarkUpOrDown"
        />
      </CCol>
      <CCol col="4" xs="12">
        <CInput
          type="text"
          label="Erlaubte Domains"
          description="Bitte geben Sie die erlaubten Domains in einer durch Komma getrennten Weise ein, welche Ihren Leadgenerator einbetten dürfen."
          v-model="allowedDomains"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CButton v-if="!processing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else color="primary"> Processing... </CButton>
        <CButton
          v-if="isEdit"
          @click="deleteHandler"
          color="danger"
          style="margin-left: 4px"
        >
          Löschen
        </CButton>
      </CCol>
    </CRow>
  </form>
</template>

<script>
import axios from "axios";
import AddableList from "@/components/AddableList";

export default {
  name: "KensingtonLocationForm",
  components: { AddableList },
  props: {
    passedData: {
      type: Object,
      default: () => ({}),
    },
    btnText: {
      type: String,
      required: true,
    },
    isEdit: Boolean,
    processing: Boolean,
  },
  data() {
    return {
      id: this.passedData.id || 0,
      name: this.passedData.name || "",
      firstName: this.passedData.firstName || "",
      lastName: this.passedData.lastName || "",
      email: this.passedData.email || "",
      postalCodeList: this.passedData.postalCodeList || [],
      companySettings: this.passedData.companySettings || {},
      allowedDomains: this.passedData.allowedDomains || "*",
      initialPassword: "",
    };
  },
  methods: {
    clickHandler() {
      if (this.$refs.kensingtonLocationForm.checkValidity()) {
        this.$emit("submit", { ...this.$data });
      } else {
        this.$refs.kensingtonLocationForm.reportValidity();
      }
    },
    deleteHandler() {
      this.$modal.showModal(
        "delete",
        null,
        [`<b>Kensington Standort</b> (${this.passedData.name})`],
        async () => {
          try {
            let resp = await axios({
              method: "DELETE",
              url: `/kensington/location/${this.$route.params.id}`,
              baseURL: process.env.VUE_APP_PLUGIN_BASE_URL,
            });
            this.$toaster.makeToast(
              "Erfolgreich!",
              "Der Standort wurde erfolgreich gelöscht.",
            );
            this.$router.push({
              name: "KensingtonLocations",
              params: { saved: "1" },
            });
          } catch (e) {
            this.$toaster.makeToast(
              "Fehler!",
              "Der Standort konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
            );
          }
        },
      );
    },
  },
};
</script>
