<template>
  <div class="mb-3">
    <label v-if="label" class="form-label">{{ label }}</label>

    <div class="border rounded p-2">
      <!-- Selected Platforms -->
      <div v-if="dataValue.length > 0" class="mb-2 d-flex flex-wrap gap-2">
        <div
          v-for="item in dataValue"
          :key="item.id"
          class="badge bg-light text-primary d-inline-flex align-items-center py-2 px-2"
        >
          <CIcon
            :name="item.icon"
            class="me-2"
            size="sm"
          />
          <span>{{ item.title }}</span>
          <button
            @click="removeItem(item)"
            class="btn btn-link text-primary p-0 ms-2"
            type="button"
          >
            <CIcon name="cil-x" size="sm" />
          </button>
        </div>
      </div>

      <!-- Available Platforms -->
      <div class="platform-list">
        <button
          v-for="option in unselectedOptions"
          :key="option.id"
          @click="toggleOption(option)"
          class="platform-option d-flex align-items-center w-100 text-start"
          :disabled="isDisabled"
          type="button"
        >
          <CIcon
            :name="option.icon"
            class="me-2"
            size="sm"
          />
          <span>{{ option.title }}</span>
        </button>
      </div>

      <!-- Loading State -->
      <div v-if="loading" class="text-center py-2">
        <div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="visually-hidden">Wird geladen...</span>
        </div>
      </div>
    </div>

    <small v-if="description" class="form-text text-muted">{{ description }}</small>
  </div>
</template>


<script>
import axios from 'axios';
import { CIcon } from '@coreui/icons-vue';
import commons from '@/commons';

export default {
  name: 'SocialMediaSelector',

  components: {
    CIcon
  },

  props: {
    label: {
      type: String,
      default: 'Plattformen auswählen'
    },
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false,
      dataOptions: [],
      dataValue: [],
    };
  },

  computed: {
    unselectedOptions() {
      return this.dataOptions.filter(option =>
        !this.dataValue.some(selected => selected.id === option.id)
      );
    },

    isDisabled() {
      return this.loading || this.dataOptions.length === 0;
    }
  },

  watch: {
    options: {
      immediate: true,
      handler(newOptions) {
        if (newOptions?.length) {
          this.dataOptions = newOptions;
        }
      }
    },

    value: {
      immediate: true,
      handler(newValue) {
        if (Array.isArray(newValue)) {
          this.dataValue = newValue;
        } else {
          this.dataValue = [];
        }
      }
    }
  },

  mounted() {
    if (!this.options.length && (!this.value || !this.value.length)) {
      this.loadSocialMediaConnections();
    }
  },

  methods: {
    toggleOption(option) {
      const index = this.dataValue.findIndex(item => item.id === option.id);
      if (index === -1) {
        this.dataValue.push(option);
        this.$emit('input', this.dataValue);
      }
    },

    removeItem(item) {
      const index = this.dataValue.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.dataValue.splice(index, 1);
        this.$emit('input', this.dataValue);
      }
    },

    async loadSocialMediaConnections() {
      this.loading = true;
      try {
        const { data } = await axios.get('/socialMedia/connections');
        const options = [];

        Object.entries(data).forEach(([platform, details]) => {
          if (platform === 'FACEBOOK') {
            options.push({
              title: details.name,
              icon: 'cib-facebook-f',
              id: details.id,
              socialMedia: details.socialMedia
            });
          }
          if (platform === 'LINKEDIN') {
            options.push({
              title: details.name,
              icon: 'cib-linkedin-in',
              id: details.id,
              socialMedia: details.socialMedia
            });
          }
          if (platform === 'INSTAGRAM') {
            options.push({
              title: details.name,
              icon: 'cib-instagram',
              id: details.id,
              socialMedia: details.socialMedia
            });
          }
        });

        this.dataOptions = options;

        // Wenn keine Plattform ausgewählt ist und Optionen verfügbar sind,
        // wähle die erste Option automatisch aus
        if (this.dataValue.length === 0 && options.length > 0) {
          this.dataValue = [options[0]];
          this.$emit('input', this.dataValue);
        }

        console.log('Loaded social media connections:', options);
      } catch (error) {
        console.error('Failed to load social media connections:', error);
        this.$toaster.makeToast(
            'Fehler!',
            'Verbindungen konnten nicht geladen werden.'
        );
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.platform-list {
  margin: -0.25rem -0.5rem;
}

.platform-option {
  padding: 0.5rem;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  transition: background-color 0.2s;
}

.platform-option:hover {
  background-color: var(--cui-gray-100);
}

/* Kleinere Badge-Größe */
.badge {
  font-weight: normal;
  font-size: 0.875rem;
}

/* Konsistenter Icon-Abstand */
.me-2 {
  margin-right: 0.5rem !important;
}

/* Gap für Badges */
.gap-2 {
  gap: 0.5rem;
}

/* Entfernen-Button */
.btn-link {
  text-decoration: none;
}

.btn-link:hover {
  opacity: 0.7;
}
</style>
