<template>
  <div class="profile-setup">
    <CCard>
      <CCardHeader>
        <h1 class="mb-0">{{ hasExistingProfile ? 'Unternehmensprofil bearbeiten' : 'Unternehmensprofil erstellen' }}</h1>
      </CCardHeader>
      <CCardBody>
        <!-- Loading Spinner -->
        <div v-if="isLoading" class="text-center py-5">
          <CSpinner color="primary" />
          <div class="mt-3">Lade Profildaten...</div>
        </div>

        <template v-else>
          <!-- Progress Steps -->
          <CRow class="mb-4">
            <CCol>
              <div class="progress-steps d-flex justify-content-between">
                <div
                    v-for="(step, index) in steps"
                    :key="index"
                    :class="[
                    'progress-step',
                    { active: currentStep === index },
                    { completed: index < currentStep }
                  ]"
                >
                  <div class="step-indicator">
                    <span class="step-number">{{ index + 1 }}</span>
                    <CIcon
                        v-if="index < currentStep"
                        name="cil-check"
                        class="step-check"
                    />
                  </div>
                  <div class="step-label">{{ step.title }}</div>
                </div>
              </div>
            </CCol>
          </CRow>

          <!-- Form Steps -->
          <CRow>
            <CCol>
              <!-- Step 1: Grundlegende Unternehmensinformationen -->
              <div v-if="currentStep === 0">
                <h2 class="mb-4">Ihr Unternehmen</h2>

                <CRow>
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Unternehmensname *</label>
                      <CInput
                          v-model="formData.companyName"
                          placeholder="Name Ihres Unternehmens"
                          :class="{ 'is-invalid': showValidation && !validation.companyName }"
                          required
                      />
                      <div v-if="showValidation && !validation.companyName" class="invalid-feedback">
                        Bitte geben Sie einen Unternehmensnamen ein
                      </div>
                    </div>
                  </CCol>

                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Website</label>
                      <CInput
                          v-model="formData.website"
                          placeholder="www.ihre-website.de"
                          type="url"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Art des Immobiliengeschäfts *</label>
                      <div class="business-type-cards" :class="{ 'invalid': showValidation && !validation.businessType }">
                        <div
                            v-for="type in businessTypes"
                            :key="type.value"
                            :class="[
                            'business-card',
                            { active: formData.businessType === type.value },
                            { 'validation-error': showValidation && !validation.businessType }
                          ]"
                            @click="selectBusinessType(type.value)"
                        >
                          <div class="business-card-content">
                            <CIcon :name="type.icon" size="xl" class="icon" />
                            <h4>{{ type.label }}</h4>
                            <p>{{ type.description }}</p>
                          </div>
                        </div>
                      </div>
                      <div v-if="showValidation && !validation.businessType" class="invalid-feedback">
                        Bitte wählen Sie eine Geschäftsart aus
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Gründungsjahr</label>
                      <CInput
                          v-model="formData.foundedYear"
                          type="number"
                          placeholder="z.B. 2010"
                      />
                    </div>
                  </CCol>

                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Unternehmensgröße</label>
                      <CSelect
                          v-model="formData.employeeCount"
                          :options="employeeCountOptions"
                          placeholder="Anzahl der Mitarbeiter"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Zusätzliche Unternehmensstandorte</label>
                      <small class="text-muted d-block mb-3">
                        Ihr Hauptstandort wird später in den Kontaktinformationen erfasst.
                      </small>

                      <div class="branch-locations">
                        <!-- Liste der Standorte -->
                        <div v-for="(branch, index) in formData.branches"
                             :key="index"
                             class="branch-location-item"
                        >
                          <div class="branch-content">
                            <div class="branch-input">
                              <GoogleMapsAutocompleteInput
                                  v-model="branch.address"
                                  placeholder="Zusätzlicher Standort"
                                  :country="['de', 'at', 'ch']"
                                  types=""
                                  @place-changed="(place) => handlePlaceSelected(place, index)"
                              />
                            </div>

                            <CButton
                                color="danger"
                                variant="ghost"
                                class="branch-remove-btn"
                                @click="removeBranch(index)"
                                :title="'Standort entfernen'"
                            >
                              <CIcon name="cil-trash" />
                            </CButton>
                          </div>

                          <!-- Optionale Validierungsmeldung -->
                          <div v-if="showValidation && !branch.address" class="invalid-feedback d-block">
                            Bitte geben Sie eine gültige Adresse ein
                          </div>
                        </div>

                        <!-- Hinzufügen Button -->
                        <CButton
                            color="primary"
                            variant="ghost"
                            class="branch-add-btn"
                            @click="addBranch"
                        >
                          <CIcon name="cil-plus" class="me-2" />
                          Standort hinzufügen
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>

              <!-- Step 2: Spezialisierung & USPs -->
              <div v-if="currentStep === 1">
                <h2 class="mb-4">Ihre Spezialisierung</h2>

                <CRow>
                  <CCol sm="12">
                    <div class="form-group" :class="{ 'has-error': showValidation && formData.propertyTypes.length === 0 }">
                      <label>Immobilientypen *</label>
                      <div class="property-type-grid">
                        <div
                            v-for="type in propertyTypes"
                            :key="type.value"
                            :class="[
                            'property-type-card',
                            { active: formData.propertyTypes.includes(type.value) },
                            { 'validation-error': showValidation && formData.propertyTypes.length === 0 }
                          ]"
                            @click="togglePropertyType(type.value)"
                        >
                          <CIcon :name="type.icon" size="xl" />
                          <span>{{ type.label }}</span>
                        </div>
                      </div>
                      <div v-if="showValidation && formData.propertyTypes.length === 0" class="invalid-feedback">
                        Bitte wählen Sie mindestens einen Immobilientyp aus
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group" :class="{ 'has-error': showValidation && formData.priceSegments.length === 0 }">
                      <label>Preissegmente *</label>
                      <div class="price-range-selector">
                        <div
                            v-for="segment in priceSegments"
                            :key="segment.value"
                            :class="[
                            'price-segment',
                            { active: formData.priceSegments.includes(segment.value) },
                            { 'validation-error': showValidation && formData.priceSegments.length === 0 }
                          ]"
                            @click="togglePriceSegment(segment.value)"
                        >
                          <h4>{{ segment.label }}</h4>
                          <p>{{ segment.range }}</p>
                        </div>
                      </div>
                      <div v-if="showValidation && formData.priceSegments.length === 0" class="invalid-feedback">
                        Bitte wählen Sie mindestens ein Preissegment aus
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Tätigkeitsgebiet</label>
                      <div class="region-selector">
                        <small class="text-muted mb-2" v-if="!isMainLocationSet">
                          Der Hauptstandort wird später in den Kontaktinformationen festgelegt.
                        </small>
                        <small class="text-muted mb-2" v-else>
                          Basierend auf Ihrem Hauptstandort: {{ formData.mainLocation }}
                        </small>
                        <CSelect
                            v-model="formData.radius"
                            :options="radiusOptions"
                            placeholder="Tätigkeitsradius"
                        />
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Besondere Services & USPs</label>
                      <div class="services-grid">
                        <div
                            v-for="service in serviceOptions"
                            :key="service.value"
                            :class="[
                            'service-card',
                            { active: formData.services.includes(service.value) }
                          ]"
                            @click="toggleService(service.value)"
                        >
                          <CIcon :name="service.icon" />
                          <span>{{ service.label }}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Qualifikationen & Zertifizierungen</label>
                      <div class="certifications-grid">
                        <div
                            v-for="cert in certificationOptions"
                            :key="cert.value"
                            :class="[
                            'certification-card',
                            { active: formData.certifications.includes(cert.value) }
                          ]"
                            @click="toggleCertification(cert.value)"
                        >
                          <img :src="cert.logo" :alt="cert.label" class="cert-logo" />
                          <span>{{ cert.label }}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>

              <!-- Step 3: Marketing & Zielgruppe -->
              <div v-if="currentStep === 2">
                <h2 class="mb-4">Marketing & Zielgruppe</h2>

                <CRow>
                  <CCol sm="12">
                    <div class="form-group" :class="{ 'has-error': showValidation && !hasSelectedTargetGroups }">
                      <label>Ihre Hauptzielgruppen *</label>
                      <div class="target-groups-grid">
                        <div
                            v-for="group in targetGroupOptions"
                            :key="group.value"
                            :class="[
              'target-group-card',
              { active: formData.targetGroups.includes(group.value) },
              { 'validation-error': showValidation && !hasSelectedTargetGroups }
            ]"
                            @click="toggleTargetGroup(group.value)"
                        >
                          <div class="target-group-content">
                            <CIcon :name="group.icon" size="xl" />
                            <h4>{{ group.label }}</h4>
                            <p>{{ group.description }}</p>
                          </div>
                        </div>
                      </div>
                      <div v-if="showValidation && !hasSelectedTargetGroups" class="invalid-feedback">
                        Bitte wählen Sie mindestens eine Zielgruppe aus
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <!-- Marketing-Ziele Spalte -->
                  <CCol sm="12" md="6">
                    <div class="form-group h-100" :class="{ 'has-error': showValidation && !hasSelectedMarketingGoals }">
                      <label>Marketingziele *</label>
                      <div class="goals-list">
                        <div
                            v-for="goal in marketingGoalOptions"
                            :key="goal.value"
                            class="goal-checkbox"
                            :class="{ 'validation-error': showValidation && !hasSelectedMarketingGoals }"
                        >
                          <CInputCheckbox
                              :label="goal.label"
                              :value="goal.value"
                              :checked="formData.marketingGoals.includes(goal.value)"
                              @change="toggleMarketingGoal(goal.value)"
                          />
                          <small class="text-muted">{{ goal.description }}</small>
                        </div>
                      </div>
                      <div v-if="showValidation && !hasSelectedMarketingGoals" class="invalid-feedback">
                        Bitte wählen Sie mindestens ein Marketingziel aus
                      </div>
                    </div>
                  </CCol>

                  <!-- Herausforderungen Spalte -->
                  <CCol sm="12" md="6">
                    <div class="form-group h-100" :class="{ 'has-error': showValidation && !hasSelectedChallenges }">
                      <label>Aktuelle Herausforderungen *</label>
                      <div class="challenges-list">
                        <div
                            v-for="challenge in challengeOptions"
                            :key="challenge.value"
                            class="challenge-checkbox"
                            :class="{ 'validation-error': showValidation && !hasSelectedChallenges }"
                        >
                          <CInputCheckbox
                              :label="challenge.label"
                              :value="challenge.value"
                              :checked="formData.currentChallenges.includes(challenge.value)"
                              @change="toggleChallenge(challenge.value)"
                          />
                          <small class="text-muted">{{ challenge.description }}</small>
                        </div>
                      </div>
                      <div v-if="showValidation && !hasSelectedChallenges" class="invalid-feedback">
                        Bitte wählen Sie mindestens eine Herausforderung aus
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Aktuelle Marketing-Kanäle</label>
                      <div class="marketing-channels-grid">
                        <div
                            v-for="channel in marketingChannelOptions"
                            :key="channel.value"
                            :class="[
              'marketing-channel-card',
              { active: formData.marketingChannels.includes(channel.value) }
            ]"
                            @click="toggleMarketingChannel(channel.value)"
                        >
                          <CIcon :name="channel.icon" />
                          <span>{{ channel.label }}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Immobilienportale</label>
                      <div class="property-portals-grid">
                        <div
                            v-for="portal in propertyPortalOptions"
                            :key="portal.value"
                            :class="[
              'portal-card',
              { active: formData.propertyPortals.includes(portal.value) }
            ]"
                            @click="togglePropertyPortal(portal.value)"
                        >
                          <img :src="portal.logo" :alt="portal.label" class="portal-logo" />
                          <span>{{ portal.label }}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>

              <!-- Step 4: Kontakt & Social Media -->
              <div v-if="currentStep === 3">
                <h2 class="mb-4">Kontakt & Social Media</h2>

                <CRow>
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Hauptansprechpartner *</label>
                      <CInput
                          v-model="formData.contactInformation.mainContact"
                          placeholder="Name des Ansprechpartners"
                          :class="{ 'is-invalid': showValidation && !validation.contactInformation?.mainContact }"
                          required
                      />
                      <div v-if="showValidation && !validation.contactInformation?.mainContact" class="invalid-feedback">
                        Bitte geben Sie einen Hauptansprechpartner ein
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Position</label>
                      <CInput
                          v-model="formData.contactInformation.position"
                          placeholder="Position im Unternehmen"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-3">
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Telefonnummer *</label>
                      <CInput
                          v-model="formData.contactInformation.phoneNumber"
                          placeholder="+49 123 456789"
                          type="tel"
                          :class="{ 'is-invalid': showValidation && !validation.contactInformation?.phoneNumber }"
                          required
                      />
                      <div v-if="showValidation && !validation.contactInformation?.phoneNumber" class="invalid-feedback">
                        Bitte geben Sie eine Telefonnummer ein
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>E-Mail *</label>
                      <CInput
                          v-model="formData.contactInformation.email"
                          placeholder="kontakt@ihrefirma.de"
                          type="email"
                          :class="{ 'is-invalid': showValidation && !validation.contactInformation?.email }"
                          required
                      />
                      <div v-if="showValidation && !validation.contactInformation?.email" class="invalid-feedback">
                        Bitte geben Sie eine gültige E-Mail-Adresse ein
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-3">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Geschäftsadresse (Hauptstandort) *</label>
                      <GoogleMapsAutocompleteInput
                          v-model="formData.contactInformation.address"
                          placeholder="Adresse der Firma"
                          :country="['de', 'at', 'ch']"
                          types=""
                          horizontal
                          :class="{ 'is-invalid': showValidation && !validation.contactInformation?.address }"
                          @place-changed="handlePlaceSelected"
                      />
                      <small class="text-muted">
                        Diese Adresse wird auch als Ihr Hauptstandort verwendet.
                      </small>
                      <div v-if="showValidation && !validation.contactInformation?.address" class="invalid-feedback">
                        Bitte geben Sie eine Geschäftsadresse ein
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-3">
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Stadt</label>
                      <CInput
                          v-model="formData.contactInformation.city"
                          placeholder="Stadt"
                          readonly
                      />
                    </div>
                  </CCol>
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Postleitzahl</label>
                      <CInput
                          v-model="formData.contactInformation.postalCode"
                          placeholder="PLZ"
                          readonly
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-3">
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Bundesland</label>
                      <CInput
                          v-model="formData.contactInformation.state"
                          placeholder="Bundesland"
                          readonly
                      />
                    </div>
                  </CCol>
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Bezirk</label>
                      <CInput
                          v-model="formData.contactInformation.district"
                          placeholder="Bezirk"
                          readonly
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Social Media Plattformen</label>
                      <div class="social-media-grid">
                        <div
                            v-for="platform in socialMediaOptions"
                            :key="platform.value"
                            class="platform-card"
                            :class="{ active: formData.socialMediaPlatforms.includes(platform.value) }"
                            @click="toggleSocialPlatform(platform.value)"
                        >
                          <div class="platform-header">
                            <CIcon :name="platform.icon" size="xl" />
                            <h4>{{ platform.label }}</h4>
                          </div>

                          <template v-if="formData.socialMediaPlatforms.includes(platform.value)">
                            <div class="platform-input" @click.stop>
                              <CInput
                                  v-model="formData.socialMediaLinks[platform.value]"
                                  :placeholder="`${platform.label} Profil-URL`"
                              />
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Gewünschter Tonfall *</label>
                      <CSelect
                          v-model="formData.tone"
                          :options="toneOptions"
                          placeholder="Wählen Sie einen Tonfall"
                          :class="{ 'is-invalid': showValidation && !validation.tone }"
                          required
                      />
                      <div v-if="showValidation && !validation.tone" class="invalid-feedback">
                        Bitte wählen Sie einen Tonfall aus
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" md="6">
                    <div class="form-group">
                      <label>Posting-Häufigkeit *</label>
                      <CSelect
                          v-model="formData.postingFrequency"
                          :options="frequencyOptions"
                          placeholder="Gewünschte Häufigkeit"
                          :class="{ 'is-invalid': showValidation && !validation.postingFrequency }"
                          required
                      />
                      <div v-if="showValidation && !validation.postingFrequency" class="invalid-feedback">
                        Bitte wählen Sie eine Posting-Häufigkeit aus
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol sm="12">
                    <div class="form-group">
                      <label>Inhaltliche Präferenzen</label>
                      <div class="content-preferences-grid">
                        <div
                            v-for="pref in contentPreferenceOptions"
                            :key="pref.value"
                            :class="[
                            'preference-card',
                            { active: formData.contentPreferences.includes(pref.value) }
                          ]"
                            @click="toggleContentPreference(pref.value)"
                        >
                          <CIcon :name="pref.icon" />
                          <span>{{ pref.label }}</span>
                          <p>{{ pref.description }}</p>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>

          <!-- Navigation Buttons -->
          <CRow class="mt-4">
            <CCol class="d-flex justify-content-between">
              <CButton
                  v-if="currentStep > 0"
                  color="light"
                  @click="previousStep"
              >
                <CIcon name="cil-arrow-left" /> Zurück
              </CButton>
              <div>
                <CButton
                    v-if="currentStep < steps.length - 1"
                    color="primary"
                    @click="nextStep"
                    :disabled="!isStepValid"
                >
                  Weiter <CIcon name="cil-arrow-right" />
                </CButton>
                <CButton
                    v-else
                    color="success"
                    @click="submitForm"
                    :disabled="!isFormValid"
                >
                  {{ hasExistingProfile ? 'Profil aktualisieren' : 'Profil erstellen' }}
                  <CIcon name="cil-check" />
                </CButton>
              </div>
            </CCol>
          </CRow>
        </template>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import GoogleMapsAutocompleteInput from "@/components/GoogleMapsAutocompleteInput";
import { CIcon } from '@coreui/icons-vue'
import toastPlugin from "@/toastPlugin"
import {
  CInput,
  CSelect,
  CTextarea,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CCol,
  CInputCheckbox,
  CSpinner
} from '@coreui/vue'

export default {
  name: 'ProfileSetup',
  components: {
    GoogleMapsAutocompleteInput,
    CIcon,
    CInput,
    CSelect,
    CTextarea,
    CButton,
    CCard,
    CCardHeader,
    CCardBody,
    CRow,
    CCol,
    CInputCheckbox,
    CSpinner
  },
  data() {
    return {
      showValidation: false,
      realmId: null,
      isLoading: true,
      hasExistingProfile: false,
      currentStep: 0,
      isMainLocationSet: false,
      employeeCountOptions: [
        { value: 'single', label: 'Einzelunternehmer' },
        { value: '2-5', label: '2-5 Mitarbeiter' },
        { value: '6-10', label: '6-10 Mitarbeiter' },
        { value: '11-20', label: '11-20 Mitarbeiter' },
        { value: '21-50', label: '21-50 Mitarbeiter' },
        { value: '50+', label: 'Über 50 Mitarbeiter' }
      ],
      steps: [
        {
          title: 'Unternehmen',
          required: ['companyName', 'businessType']
        },
        {
          title: 'Spezialisierung',
          required: ['propertyTypes', 'priceSegments']
        },
        {
          title: 'Marketing',
          required: ['targetGroups', 'marketingGoals', 'currentChallenges']
        },
        {
          title: 'Kontakt',
          required: ['contactInformation.mainContact', 'contactInformation.email', 'contactInformation.phoneNumber']
        }
      ],
      radiusOptions: [
        { value: '10', label: '10 km' },
        { value: '25', label: '25 km' },
        { value: '50', label: '50 km' },
        { value: '100', label: '100 km' },
        { value: 'unlimited', label: 'Unbegrenzt' }
      ],
      certificationOptions: [
        { value: 'ImmoScout24 Gold Partner', label: 'ImmoScout24 Gold Partner', logo: '/img/certs/Gold_Badge_primary.svg' },
        { value: 'ivd', label: 'IVD Mitglied', logo: '/img/certs/Immobilienverband-IVD-Logo.svg' },
        { value: 'dekra', label: 'DEKRA zertifiziert', logo: '/img/certs/DEKRA.svg' }
      ],
      marketingGoalOptions: [
        {
          value: 'brand_awareness',
          label: 'Markenbekanntheit',
          description: 'Stärkung der Markenwahrnehmung'
        },
        {
          value: 'lead_generation',
          label: 'Lead Generierung',
          description: 'Neue Interessenten gewinnen'
        },
        {
          value: 'customer_loyalty',
          label: 'Kundenbindung',
          description: 'Bestandskunden pflegen'
        },
        {
          value: 'expert_positioning',
          label: 'Expertenpositionierung',
          description: 'Als Experte wahrgenommen werden'
        },
        {
          value: 'market_share',
          label: 'Marktanteil',
          description: 'Geschäftsbereich ausbauen'
        }
      ],
      socialMediaOptions: [
        { value: 'facebook', label: 'Facebook', icon: 'cibFacebook' },
        { value: 'instagram', label: 'Instagram', icon: 'cibInstagram' },
        { value: 'linkedin', label: 'LinkedIn', icon: 'cibLinkedin' },
        { value: 'youtube', label: 'YouTube', icon: 'cibYoutube' },
        { value: 'xing', label: 'Xing', icon: 'cibXing' }
      ],
      challengeOptions: [
        {
          value: 'competition',
          label: 'Starker Wettbewerb',
          description: 'Viele Mitbewerber im Markt'
        },
        {
          value: 'digitalization',
          label: 'Digitale Transformation',
          description: 'Anpassung an digitale Prozesse'
        },
        {
          value: 'lead_quality',
          label: 'Qualität der Anfragen',
          description: 'Verbesserung der Lead-Qualität'
        },
        {
          value: 'visibility',
          label: 'Sichtbarkeit',
          description: 'Mehr Präsenz im Markt'
        },
        {
          value: 'process_optimization',
          label: 'Prozessoptimierung',
          description: 'Interne Abläufe verbessern'
        }
      ],
      formData: {
        id: '',
        companyName: '',
        website: '',
        businessType: '',
        foundedYear: '',
        employeeCount: '',
        branches: [{ address: '' }],
        propertyTypes: [],
        priceSegments: [],
        mainLocation: '',
        radius: '',
        services: [],
        certifications: [],
        targetGroups: [],
        marketingChannels: [],
        propertyPortals: [],
        marketingGoals: [],
        currentChallenges: [],
        contactInformation: {
          mainContact: '',
          position: '',
          phoneNumber: '',
          email: '',
          address: '',
          state: '',
          district: '',
          city: '',
          postalCode: ''
        },
        socialMediaPlatforms: [],
        socialMediaLinks: {},
        tone: '',
        postingFrequency: '',
        contentPreferences: [],
        industry: '', // Added missing field
        targetAudience: '', // Added missing field
        goals: [], // Added missing field
        productsOrServices: '', // Added missing field
        specialOfferDetails: '' // Added missing field
      },
      // Options für die verschiedenen Auswahlmöglichkeiten
      businessTypes: [
        { value: 'realtor', label: 'Immobilienmakler', icon: 'cil-house', description: 'Vermittlung von Bestandsimmobilien' },
        { value: 'developer', label: 'Bauträger', icon: 'cil-building', description: 'Entwicklung und Verkauf von Neubauprojekten' },
        { value: 'prefab', label: 'Fertighausanbieter', icon: 'cil-home', description: 'Schlüsselfertige Häuser und Projektierung' }
      ],
      propertyTypes: [
        { value: 'residential', label: 'Wohnimmobilien', icon: 'cil-house' },
        { value: 'commercial', label: 'Gewerbeimmobilien', icon: 'cil-building' },
        { value: 'investment', label: 'Anlageimmobilien', icon: 'cil-bank' },
        { value: 'luxury', label: 'Luxusimmobilien', icon: 'cil-star' },
        { value: 'newbuild', label: 'Neubauprojekte', icon: 'cil-factory' }
      ],
      priceSegments: [
        { value: 'economy', label: 'Budget', range: 'bis 300.000 €' },
        { value: 'medium', label: 'Mittelklasse', range: '300.000 € - 750.000 €' },
        { value: 'premium', label: 'Premium', range: '750.000 € - 1.500.000 €' },
        { value: 'luxury', label: 'Luxus', range: 'über 1.500.000 €' }
      ],
      targetGroupOptions: [
        { value: 'private', label: 'Privatnutzer', icon: 'cil-people', description: 'Eigennutzer für Wohnimmobilien' },
        { value: 'investor', label: 'Investoren', icon: 'cil-money', description: 'Kapitalanleger und Investoren' },
        { value: 'commercial', label: 'Gewerbetreibende', icon: 'cil-briefcase', description: 'Unternehmen und Geschäftskunden' }
      ],
      serviceOptions: [
        { value: 'virtual_tour', label: '360° Rundgänge', icon: 'cil-video' },
        { value: 'staging', label: 'Home Staging', icon: 'cil-paint' },
        { value: 'financing', label: 'Finanzierungsberatung', icon: 'cil-bank' },
        { value: 'legal', label: 'Rechtsberatung', icon: 'cil-balance-scale' }
      ],
      marketingChannelOptions: [
        { value: 'online', label: 'Online Marketing', icon: 'cil-globe-alt' },
        { value: 'print', label: 'Print Medien', icon: 'cil-newspaper' },
        { value: 'radio', label: 'Radio/TV', icon: 'cil-tv' },
        { value: 'events', label: 'Events', icon: 'cil-people' }
      ],
      propertyPortalOptions: [
        { value: 'immoscout', label: 'ImmobilienScout24', logo: '/img/portals/icon_60pt@3x.png' },
        { value: 'kleinanzeigen', label: 'Kleinanzeigen', logo: '/img/portals/ka_vertical_lightgreen_rgb.png' },
        { value: 'immowelt', label: 'Immowelt', logo: '/img/portals/immowelt.jpg' }
      ],
      toneOptions: [
        { value: 'professional', label: 'Professionell' },
        { value: 'friendly', label: 'Freundlich' },
        { value: 'exclusive', label: 'Exklusiv' },
        { value: 'casual', label: 'Locker' }
      ],
      frequencyOptions: [
        { value: 'daily', label: 'Täglich' },
        { value: '3x_week', label: '3x pro Woche' },
        { value: '2x_week', label: '2x pro Woche' },
        { value: 'weekly', label: 'Wöchentlich' }
      ],
      contentPreferenceOptions: [
        { value: 'market_insights', label: 'Marktanalysen', icon: 'cil-chart', description: 'Trends und Entwicklungen' },
        { value: 'property_tips', label: 'Immobilientipps', icon: 'cil-lightbulb', description: 'Ratgeber und Tipps' },
        { value: 'success_stories', label: 'Erfolgsgeschichten', icon: 'cil-star', description: 'Referenzen und Cases' }
      ]
    }
  },
  computed: {
    validation() {
      return {
        companyName: this.formData?.companyName?.length > 0,
        businessType: this.formData?.businessType?.length > 0,
        email: this.formData?.contactInformation?.email
          ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formData.contactInformation.email)
          : false,
        phoneNumber: this.formData?.contactInformation?.phoneNumber?.length >= 6,
        industry: this.formData?.industry ? this.formData.industry !== '' : false,
        targetAudience: this.formData?.targetAudience?.length >= 10,
        address: this.formData?.contactInformation?.address?.length > 0,
        socialMediaPlatforms: Array.isArray(this.formData?.socialMediaPlatforms)
          ? this.formData.socialMediaPlatforms.length > 0
          : false,
        tone: this.formData?.tone ? this.formData.tone !== '' : false,
        goals: Array.isArray(this.formData?.goals) ? this.formData.goals.length > 0 : false,
        branches: this.formData?.branches?.every(branch => branch.address?.length > 0)
      }
    },
    isStepValid() {
      if (!this.steps) return false
      const currentStepFields = this.steps[this.currentStep]?.required
      if (!currentStepFields) return true // Wenn keine required Felder definiert sind
      return currentStepFields.every(field => this.isFieldValid(field))
    },
    hasSelectedChallenges() {
      return this.formData.currentChallenges && this.formData.currentChallenges.length > 0;
    },
    hasSelectedTargetGroups() {
      return this.formData.targetGroups && this.formData.targetGroups.length > 0;
    },

    hasSelectedMarketingGoals() {
      return this.formData.marketingGoals && this.formData.marketingGoals.length > 0;
    },
    isFormValid() {
      if (!this.steps) return false
      return this.steps.every(step => {
        if (!step.required) return true
        return step.required.every(field => this.isFieldValid(field))
      })
    }
  },
  async created() {
    await this.loadRealmId()
    if (this.realmId) {
      await this.checkAndLoadProfile()
    }
  },
  methods: {
    toggleMarketingGoal(value) {
      if (!Array.isArray(this.formData.marketingGoals)) {
        this.$set(this.formData, 'marketingGoals', [])
      }
      const index = this.formData.marketingGoals.indexOf(value)
      if (index === -1) {
        this.formData.marketingGoals.push(value)
      } else {
        this.formData.marketingGoals.splice(index, 1)
      }
    },

    toggleChallenge(value) {
      if (!Array.isArray(this.formData.currentChallenges)) {
        this.$set(this.formData, 'currentChallenges', [])
      }
      const index = this.formData.currentChallenges.indexOf(value)
      if (index === -1) {
        this.formData.currentChallenges.push(value)
      } else {
        this.formData.currentChallenges.splice(index, 1)
      }
    },

    async loadRealmId() {
      try {
        const response = await axios.get('/realm/current')
        this.realmId = response.data.id
        if (!this.realmId) {
          toastPlugin.makeToast('Fehler', 'Keine Realm ID verfügbar')
        }
      } catch (error) {
        console.error('Fehler beim Laden der Realm ID:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden der Realm ID')
      }
    },

    isFieldValid(field) {
      if (!field) return false

      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        const parentObj = this.formData?.[parent]
        if (!parentObj) return false

        const value = parentObj[child]
        if (child === 'email') {
          return value ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) : false
        }
        return value ? value.trim() !== '' : false
      }

      const value = this.formData?.[field]
      if (value === undefined || value === null) return false

      if (Array.isArray(value)) {
        return value.length > 0
      }
      return value.toString().trim() !== ''
    },

    toggleCertification(value) {
      if (!Array.isArray(this.formData.certifications)) {
        this.$set(this.formData, 'certifications', [])
      }
      const index = this.formData.certifications.indexOf(value)
      if (index === -1) {
        this.formData.certifications.push(value)
      } else {
        this.formData.certifications.splice(index, 1)
      }
    },

    handlePlaceChanged(place) {
      console.log('ProfileSetup - Received place:', place);

      // Strukturiere die Daten in das gewünschte Format um
      const formattedPlace = {
        formatted_address: place.formatted_address || '',
        locality: '',
        administrative_area_level_1: '',
        administrative_area_level_2: ''
      };

      if (place && place.address_components) {
        place.address_components.forEach(component => {
          if (component.types.includes('locality')) {
            formattedPlace.locality = component.long_name;
          }
          if (component.types.includes('administrative_area_level_1')) {
            formattedPlace.administrative_area_level_1 = component.long_name;
          }
          if (component.types.includes('administrative_area_level_2')) {
            formattedPlace.administrative_area_level_2 = component.long_name;
          }
        });
      } else if (place) {
        // Wenn place bereits die benötigten Eigenschaften enthält
        formattedPlace.locality = place.locality || '';
        formattedPlace.administrative_area_level_1 = place.administrative_area_level_1 || '';
        formattedPlace.administrative_area_level_2 = place.administrative_area_level_2 || '';
      }

      // Debug log
      console.log('Formatted place object:', formattedPlace);

      // Emit all variants
      this.$emit('placechanged', formattedPlace);
      this.$emit('place-changed', formattedPlace);
      this.$emit('place_changed', formattedPlace);
    },

    handlePlaceSelected(place, index) {
      console.log('ProfileSetup - handlePlaceSelected called with:', place);

      // Für zusätzliche Branch-Adresse
      if (index !== undefined) {
        this.$set(this.formData.branches[index], 'address', place.formatted_address || '');
        return;
      }

      // Kontaktinformationen updaten
      const newContactInfo = {
        // Behalte existierende Werte
        mainContact: this.formData.contactInformation.mainContact,
        position: this.formData.contactInformation.position,
        phoneNumber: this.formData.contactInformation.phoneNumber,
        email: this.formData.contactInformation.email,

        // Setze neue Werte
        address: place.formatted_address,
        city: place.locality,
        district: place.administrative_area_level_2,
        postalCode: place.postal_code,
        state: place.administrative_area_level_1 === 'NRW' ? 'Nordrhein-Westfalen' : place.administrative_area_level_1
      };

      // Update contactInformation
      Object.keys(newContactInfo).forEach(key => {
        this.$set(this.formData.contactInformation, key, newContactInfo[key]);
      });

      // Automatisch den Hauptstandort setzen
      if (place.locality) {
        this.formData.mainLocation = `${place.locality}, Deutschland`;
        this.isMainLocationSet = true;
      }

      console.log('Updated contact information:', this.formData.contactInformation);
    },

    addBranch() {
      if (!Array.isArray(this.formData.branches)) {
        this.$set(this.formData, 'branches', [])
      }
      this.formData.branches.push({
        address: ''
      })
    },

    formatAddress(branch) {
      if (typeof branch === 'object' && branch !== null) {
        return branch.address || ''
      }
      return branch || ''
    },

    removeBranch(index) {
      if (Array.isArray(this.formData.branches)) {
        this.formData.branches.splice(index, 1)
      }
    },
    selectBusinessType(value) {
      this.formData.businessType = value
    },
    togglePropertyType(value) {
      if (!Array.isArray(this.formData.propertyTypes)) {
        this.$set(this.formData, 'propertyTypes', [])
      }
      const index = this.formData.propertyTypes.indexOf(value)
      if (index === -1) {
        this.formData.propertyTypes.push(value)
      } else {
        this.formData.propertyTypes.splice(index, 1)
      }
    },

    togglePriceSegment(value) {
      if (!Array.isArray(this.formData.priceSegments)) {
        this.$set(this.formData, 'priceSegments', [])
      }
      const index = this.formData.priceSegments.indexOf(value)
      if (index === -1) {
        this.formData.priceSegments.push(value)
      } else {
        this.formData.priceSegments.splice(index, 1)
      }
    },

    toggleService(value) {
      if (!Array.isArray(this.formData.services)) {
        this.$set(this.formData, 'services', [])
      }
      const index = this.formData.services.indexOf(value)
      if (index === -1) {
        this.formData.services.push(value)
      } else {
        this.formData.services.splice(index, 1)
      }
    },

    toggleTargetGroup(value) {
      if (!Array.isArray(this.formData.targetGroups)) {
        this.$set(this.formData, 'targetGroups', [])
      }
      const index = this.formData.targetGroups.indexOf(value)
      if (index === -1) {
        this.formData.targetGroups.push(value)
      } else {
        this.formData.targetGroups.splice(index, 1)
      }
    },

    toggleMarketingChannel(value) {
      if (!Array.isArray(this.formData.marketingChannels)) {
        this.$set(this.formData, 'marketingChannels', [])
      }
      const index = this.formData.marketingChannels.indexOf(value)
      if (index === -1) {
        this.formData.marketingChannels.push(value)
      } else {
        this.formData.marketingChannels.splice(index, 1)
      }
    },

    toggleContentPreference(value) {
      if (!Array.isArray(this.formData.contentPreferences)) {
        this.$set(this.formData, 'contentPreferences', [])
      }
      const index = this.formData.contentPreferences.indexOf(value)
      if (index === -1) {
        this.formData.contentPreferences.push(value)
      } else {
        this.formData.contentPreferences.splice(index, 1)
      }
    },

    togglePropertyPortal(value) {
      if (!Array.isArray(this.formData.propertyPortals)) {
        this.$set(this.formData, 'propertyPortals', [])
      }
      const index = this.formData.propertyPortals.indexOf(value)
      if (index === -1) {
        this.formData.propertyPortals.push(value)
      } else {
        this.formData.propertyPortals.splice(index, 1)
      }
    },

    toggleSocialPlatform(value) {
      const index = this.formData.socialMediaPlatforms.indexOf(value)
      if (index === -1) {
        this.formData.socialMediaPlatforms.push(value)
        this.$set(this.formData.socialMediaLinks, value, '')
      } else {
        this.formData.socialMediaPlatforms.splice(index, 1)
        this.$delete(this.formData.socialMediaLinks, value)
      }
    },
    async submitForm() {
      if (this.isFormValid) {
        try {
          // Simplified branch formatting
          const formattedBranches = this.formData.branches
              .map(branch => this.formatAddress(branch))
              .filter(address => address !== '') // Remove empty addresses

          const payload = {
            id: this.hasExistingProfile ? this.formData.id : String(Date.now()),
            realm_id: String(this.realmId),
            ...this.formData,
            branches: formattedBranches // Already contains just the address strings
          }

          const response = await axios.post('/socialMedia/saveCompanyData', payload)

          if (response.data) {
            toastPlugin.makeToast(
                'Erfolg',
                'Unternehmensprofil erfolgreich gespeichert'
            )

            this.$emit('profile-saved')
            this.$router.push('/socialMedia/automation')
          }
        } catch (error) {
          console.error('Fehler beim Speichern des Profils:', error)
          toastPlugin.makeToast('Fehler', 'Fehler beim Speichern des Profils')
        }
      } else {
        toastPlugin.makeToast('Warnung', 'Bitte füllen Sie alle erforderlichen Felder aus')
      }
    },
    async checkAndLoadProfile() {
      try {
        this.isLoading = true

        if (!this.realmId) {
          console.error('Keine Realm ID verfügbar')
          toastPlugin.makeToast('Fehler', 'Keine Realm ID verfügbar')
          return
        }

        const completedResponse = await axios.get('/socialMedia/profile/completed', {
          params: { realmId: this.realmId }
        })

        this.hasExistingProfile = completedResponse.data

        if (this.hasExistingProfile) {
          const profileResponse = await axios.get('/socialMedia/profile', {
            params: { realmId: this.realmId }
          })

          const profile = profileResponse.data

          // Formular mit existierenden Daten befüllen
          this.formData = {
            id: profile.id || '',
            companyName: profile.companyName || '',
            website: profile.website || '',
            businessType: profile.businessType || '',
            foundedYear: profile.foundedYear || '',
            employeeCount: profile.employeeCount || '',
            branches: Array.isArray(profile.branches)
              ? profile.branches.map(branch => {
                if (typeof branch === 'string') {
                  return { address: branch }
                }
                if (typeof branch === 'object' && branch !== null) {
                  // Wenn es ein Objekt mit address Property ist
                  if (branch.address) {
                    return { address: branch.address }
                  }
                  // Wenn es ein Objekt ist, das direkt als String angezeigt wird
                  return { address: String(branch) }
                }
                return { address: '' }
              })
              : [{ address: '' }],
            propertyTypes: Array.isArray(profile.propertyTypes) ? profile.propertyTypes : [],
            priceSegments: Array.isArray(profile.priceSegments) ? profile.priceSegments : [],
            mainLocation: profile.mainLocation || '',
            radius: profile.radius || '',
            services: Array.isArray(profile.services) ? profile.services : [],
            certifications: Array.isArray(profile.certifications) ? profile.certifications : [],
            targetGroups: Array.isArray(profile.targetGroups) ? profile.targetGroups : [],
            marketingChannels: Array.isArray(profile.marketingChannels) ? profile.marketingChannels : [],
            propertyPortals: Array.isArray(profile.propertyPortals) ? profile.propertyPortals : [],
            marketingGoals: Array.isArray(profile.marketingGoals) ? profile.marketingGoals : [],
            currentChallenges: Array.isArray(profile.currentChallenges) ? profile.currentChallenges : [],
            contactInformation: {
              mainContact: profile.contactInformation?.mainContact || '',
              position: profile.contactInformation?.position || '',
              phoneNumber: profile.contactInformation?.phoneNumber || '',
              email: profile.contactInformation?.email || '',
              address: profile.contactInformation?.address || '',
              state: profile.contactInformation?.state || '',
              district: profile.contactInformation?.district || '',
              city: profile.contactInformation?.city || '',
              postalCode: profile.contactInformation?.postalCode || ''
            },
            socialMediaPlatforms: typeof profile.socialMediaPlatforms === 'string'
              ? [profile.socialMediaPlatforms]
              : Array.isArray(profile.socialMediaPlatforms)
                ? profile.socialMediaPlatforms
                : [],
            socialMediaLinks: profile.socialMediaLinks || {},
            tone: profile.tone || '',
            postingFrequency: profile.postingFrequency || '',
            contentPreferences: typeof profile.contentPreferences === 'string'
              ? [profile.contentPreferences]
              : Array.isArray(profile.contentPreferences)
                ? profile.contentPreferences
                : []
          }
        }
      } catch (error) {
        console.error('Fehler beim Laden des Profils:', error)
        toastPlugin.makeToast('Fehler', 'Fehler beim Laden des Profils')
      } finally {
        this.isLoading = false
      }
    },
    validateStep() {
      this.showValidation = true;
      let isValid = true;
      let invalidFields = [];

      if (this.currentStep === 2) { // Marketing & Zielgruppe Step
        if (!this.hasSelectedTargetGroups) {
          isValid = false;
          invalidFields.push('Hauptzielgruppen');
        }
        if (!this.hasSelectedMarketingGoals) {
          isValid = false;
          invalidFields.push('Marketingziele');
        }
        if (!this.hasSelectedChallenges) {
          isValid = false;
          invalidFields.push('Aktuelle Herausforderungen');
        }
      }

      if (!isValid) {
        toastPlugin.makeToast(
            'Warnung',
            `Bitte füllen Sie folgende Pflichtfelder aus: ${invalidFields.join(', ')}`
        );
      }

      return isValid;
    },
    nextStep() {
      if (this.validateStep()) {
        if (this.currentStep < this.steps.length - 1) {
          this.currentStep++
          this.showValidation = false;
        }
      }
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    resetForm() {
      this.formData = {
        companyName: '',
        industry: '',
        targetAudience: '',
        productsOrServices: '',
        goals: [],
        contactInformation: {
          phoneNumber: '',
          email: '',
          address: ''
        },
        tone: '',
        socialMediaPlatforms: [],
        contentPreferences: '',
        specialOfferDetails: ''
      }
      this.currentStep = 0
    }
  },
  watch: {
    currentStep(newStep) {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>
.profile-setup {
  max-width: 1200px;
  margin: 0 auto;
}

.progress-steps {
  position: relative;
  padding: 0 40px;
}

.progress-steps::before {
  content: '';
  position: absolute;
  top: 20px;
  left: 60px;
  right: 60px;
  height: 2px;
  background: #e9ecef;
  z-index: 1;
}

.progress-step {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 25%;
}

.property-type-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.property-type-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.property-type-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.property-type-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.property-type-card .icon {
  margin-right: 1rem;
}

/* Preissegmente */
.price-range-selector {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.price-segment {
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.price-segment:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.price-segment.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

/* Services Grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.service-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.service-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.service-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

/* Zertifizierungen Grid */
.certifications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.certification-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.certification-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.certification-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.cert-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 1rem;
}

/* Tätigkeitsgebiet */
.region-selector {
  max-width: 600px;
  margin-bottom: 2rem;
}

.region-selector .form-group {
  margin-bottom: 1rem;
}

/* Optional: Spezifische Anpassungen für das Google Autocomplete */
.region-selector :deep(.google-maps-autocomplete) {
  width: 100%;
}

/* Optional: Styling für die Vorschläge */
.region-selector :deep(.pac-container) {
  max-width: 600px;
}

.step-indicator {
  width: 40px;
  height: 40px;
  background: #fff;
  border: 2px solid #e9ecef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 12px;
  position: relative;
  z-index: 2;
}

.step-number {
  font-weight: 600;
  color: #3c4b64;
  font-size: 1rem;
}

.progress-step.active .step-indicator {
  border-color: #321fdb;
  background: #321fdb;
}

.progress-step.active .step-number {
  color: #fff;
}

.progress-step.completed .step-indicator {
  background: #2eb85c;
  border-color: #2eb85c;
}

.progress-step.completed .step-check {
  color: #fff;
}

.step-label {
  font-size: 0.875rem;
  color: #3c4b64;
  font-weight: 500;
}

.progress-step.active .step-label {
  color: #321fdb;
  font-weight: 600;
}

.goals-checkboxes,
.platforms-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group .is-valid {
  border-color: #2eb85c;
}

.form-group .is-invalid {
  border-color: #e55353;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #3c4b64;
}

@media (max-width: 768px) {
  .progress-steps {
    padding: 0 20px;
  }

  .step-label {
    font-size: 0.75rem;
  }
}

.business-type-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.business-card {
  border: 2px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.business-type-cards.invalid {
  border-color: #e55353;
  border-radius: 8px;
}

.business-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.business-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.business-card-content {
  text-align: center;
}

.business-card-content h4 {
  margin: 1rem 0;
  color: #3c4b64;
}

.business-card-content p {
  color: #768192;
  font-size: 0.875rem;
  margin: 0;
}

.business-card .icon {
  font-size: 2rem;
  color: #321fdb;
}

/* Target Groups Grid */
.target-groups-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.target-group-card {
  border: 2px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.target-group-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.target-group-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.target-group-card.validation-error {
  border-color: #e55353;
}

.target-group-content {
  text-align: center;
}

.target-group-content h4 {
  margin: 1rem 0;
  color: #3c4b64;
}

.target-group-content p {
  color: #768192;
  font-size: 0.875rem;
  margin: 0;
}

/* Marketing Channels Grid */
.marketing-channels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.marketing-channel-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.marketing-channel-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.marketing-channel-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.marketing-channel-card .icon {
  margin-right: 1rem;
  flex-shrink: 0;
  width: 24px;
}

/* Property Portals Grid */
.property-portals-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.portal-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.portal-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.portal-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.portal-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 1rem;
}

/* Social Media Grid */
.social-media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.platform-card {
  border: 2px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.platform-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.platform-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.platform-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.platform-card h4 {
  margin: 1rem 0;
  color: #3c4b64;
  font-size: 1.1rem;
}

.platform-card .icon {
  font-size: 1.5rem;
  color: #321fdb;
}

.platform-input {
  margin-top: 1rem;
}

.platform-card input {
  margin-top: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d8dbe0;
  border-radius: 4px;
  transition: border-color 0.2s;
  cursor: text;
}

.platform-card input:focus {
  border-color: #321fdb;
  outline: none;
  box-shadow: 0 0 0 2px rgba(50, 31, 219, 0.1);
}

/* Inhaltliche Präferenzen */
.content-preferences-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.preference-card {
  border: 2px solid #e9ecef;
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.preference-card:hover {
  border-color: #321fdb;
  background-color: #f8f9fa;
}

.preference-card.active {
  border-color: #2eb85c;
  background-color: #ebeeff;
}

.preference-card span {
  font-weight: 600;
  color: #3c4b64;
  font-size: 1.1rem;
}

.preference-card p {
  color: #768192;
  font-size: 0.875rem;
  margin: 0;
}

.preference-card .icon {
  font-size: 1.5rem;
  color: #321fdb;
}

/* Tonfall und Posting-Häufigkeit Selects */
.tone-frequency-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.tone-frequency-section .form-group {
  margin-bottom: 0;
}

/* Goals & Challenges Lists */
.goals-list,
.challenges-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.goal-checkbox,
.challenge-checkbox {
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.goal-checkbox.validation-error,
.challenge-checkbox.validation-error {
  border-color: #e55353;
}

.goal-checkbox small,
.challenge-checkbox small {
  display: block;
  margin-top: 0.25rem;
}

.branch-locations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 800px;
}

.branch-location-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s ease;
}

.branch-location-item:hover {
  background-color: #f1f3f5;
}

.branch-content {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.branch-input {
  flex: 1;
  min-width: 0;
}

.branch-remove-btn {
  flex-shrink: 0;
  padding: 0.5rem;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.branch-remove-btn:hover {
  background-color: #fee2e2;
  color: #dc2626;
}

.branch-add-btn {
  align-self: flex-start;
  display: inline-flex;
  align-items: center;
  margin-top: 0.5rem;
}

/* Validierung Styles */
.invalid-feedback {
  display: none;
  color: #e55353;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.has-error .invalid-feedback {
  display: block;
}

.has-error label {
  color: #e55353;
}

.has-error .target-groups-grid,
.has-error .goals-list,
.has-error .challenges-list {
  border-radius: 4px;
  padding: 4px;
}

.validation-error {
  border-color: #e55353 !important;
}

/* Form Validation */
.form-group label::after {
  content: '';
}

.form-group label:not(.checkbox-label)::after {
  content: ' *';
  color: #e55353;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .target-groups-grid,
  .marketing-channels-grid,
  .property-portals-grid,
  .social-media-grid {
    grid-template-columns: 1fr;
  }

  .branch-location-item {
    padding: 0.75rem;
  }

  .branch-content {
    flex-direction: column;
    gap: 0.5rem;
  }

  .branch-remove-btn {
    align-self: flex-end;
    margin-top: -2.5rem;
  }

  .platform-card {
    padding: 1rem;
  }
}

@media (max-width: 640px) {
  .business-type-cards,
  .target-groups-grid,
  .marketing-channels-grid,
  .property-portals-grid {
    grid-template-columns: 1fr;
  }
}
.goals-list,
.challenges-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.goal-checkbox,
.challenge-checkbox {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .goals-list,
  .challenges-list {
    margin-bottom: 1rem;
  }
}

/* Optional: Verbesserte Hover-Effekte */
.goal-checkbox:hover,
.challenge-checkbox:hover {
  background-color: #f8f9fa;
}

/* Verbesserte Validierungsanzeige */
.has-error .goals-list,
.has-error .challenges-list {
  border: 1px solid #e55353;
}

.validation-error {
  border-color: #e55353 !important;
}
</style>
