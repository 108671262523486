<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <b>Tarif</b>
        </slot>
      </CCardHeader>
      <CCardBody>
        <iframe
          v-if="typeof iframeUrl === 'string'"
          style="width: 100%; height: 70vh; border: 0"
          :src="iframeUrl"
        >
        </iframe>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";
import moment from "moment";

export default {
  name: "PlanSettings",
  data() {
    return {
      iframeUrl: null,
      config: {
        paymentMethods: ["Debit:FakePSP", "CreditCard:FakePSP"],
        publicApiKey: "5f456835bf651f30d847c76e",
        locale: "de",
        providerReturnUrl: "http://localhost:9080/#/settings/plan/success",
      },
    };
  },

  methods: {},
};
</script>

<style scoped></style>
