<!-- src/views/admin/SystemOperations.vue -->
<template>
  <CCard>
    <CCardHeader>
      <strong>System-Operationen</strong>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <h4>Pipeline-Migration</h4>
          <p class="text-muted">
            Erstellt die Standard-Immobilien-Verkaufspipeline für alle Realms, die noch keine Pipeline haben.
          </p>
          <ProcessingButton
              :processing="isProcessing"
              variant="warning"
              @click="showConfirmation"
          >
            Standard-Pipelines erstellen
          </ProcessingButton>
        </CCol>
      </CRow>
    </CCardBody>

    <!-- Bestätigungs-Dialog -->
    <CModal
        :show.sync="showConfirmDialog"
        title="Standard-Pipelines erstellen"
        color="warning"
    >
      <div class="modal-body">
        Möchten Sie für alle Realms, die noch keine Pipeline haben, die Standard-Immobilien-Verkaufspipeline erstellen?
      </div>
      <template #footer>
        <CButton
            color="secondary"
            @click="showConfirmDialog = false"
        >
          Abbrechen
        </CButton>
        <CButton
            color="warning"
            :disabled="isProcessing"
            @click="startMigration"
        >
          Erstellen
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import ProcessingButton from '@/components/ProcessingButton'
import axios from 'axios'

// Erstelle eine axios-Instanz mit der vollen Base-URL
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL.replace('/backend', '')
})

export default {
  name: 'SystemOperations',

  components: {
    ProcessingButton
  },

  data() {
    return {
      isProcessing: false,
      showConfirmDialog: false
    }
  },

  methods: {
    showConfirmation() {
      this.showConfirmDialog = true
    },

    async startMigration() {
      this.isProcessing = true
      this.showConfirmDialog = false

      try {
        // Standard axios-Instanz verwenden, die bereits konfiguriert ist
        const response = await axios({
          method: 'post',
          url: '/api/v1/migration/create-default-pipelines',
          baseURL: process.env.VUE_APP_BACKEND_BASE_URL.replace('/backend', '')
        })
        this.$root.$refs.toaster.makeToast(
            'Erfolg',
            response.data.message
        )
      } catch (error) {
        this.$root.$refs.toaster.makeToast(
            'Fehler',
            error.response?.data?.message || 'Ein Fehler ist bei der Pipeline-Migration aufgetreten'
        )
      } finally {
        this.isProcessing = false
      }
    }
  }
}
</script>
