<template>
  <div class="notifications-page">
    <CCard class="border-0 shadow-sm">
      <CCardHeader class="bg-transparent border-bottom-0 px-4 pt-4">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div class="header-title mb-3 mb-md-0">
            <h4 class="mb-0">Benachrichtigungen</h4>
            <div class="d-flex align-items-center mt-1">
              <CBadge v-if="unreadCount" color="primary-subtle" class="me-2">
                {{ unreadCount }} ungelesen
              </CBadge>
              <CBadge v-if="highPriorityCount" color="danger-subtle">
                {{ highPriorityCount }} hohe Priorität
              </CBadge>
            </div>
          </div>

          <div class="d-flex gap-2 action-buttons">
            <CButton
                v-if="unreadCount"
                color="primary"
                variant="ghost"
                size="sm"
                class="d-flex align-items-center"
                @click="markAllAsRead"
            >
              <CIcon name="cil-check" class="me-2"/>
              Alle als gelesen markieren
            </CButton>
          </div>
        </div>

        <!-- Tabs und Suche -->
        <div class="mt-4 d-flex flex-wrap justify-content-between align-items-center gap-3">
          <div class="tabs-wrapper">
            <a
                v-for="tab in tabs"
                :key="tab.value"
                href="#"
                class="tab-link"
                :class="{ active: activeTab === tab.value }"
                @click.prevent="setActiveTab(tab.value)"
            >
              {{ tab.label }}
            </a>
          </div>

          <div class="search-wrapper">
            <div class="search-icon">
              <CIcon name="cil-search"/>
            </div>
            <CFormInput
                v-model="searchQuery"
                placeholder="Benachrichtigungen durchsuchen..."
                class="search-input"
            />
          </div>
        </div>
      </CCardHeader>

      <CCardBody class="px-4">
        <!-- Loading State -->
        <div v-if="loading" class="text-center py-5">
          <CSpinner color="primary"/>
          <p class="text-muted mt-3 mb-0">Benachrichtigungen werden geladen...</p>
        </div>

        <!-- Empty State -->
        <div v-else-if="!filteredNotifications.length" class="text-center py-5">
          <div class="empty-state">
            <div class="mb-3">
              <CIcon name="cil-bell-exclamation" size="3xl" class="text-muted"/>
            </div>
            <h5 class="mb-2">Keine Benachrichtigungen</h5>
            <p class="text-muted mb-0">{{ emptyStateMessage }}</p>
          </div>
        </div>

        <!-- Notifications List -->
        <transition-group v-else name="notification-list" tag="div" class="notifications-list">
          <div
              v-for="notification in filteredNotifications"
              :key="notification.id"
              class="notification-item"
              :class="{
              'unread': !notification.read,
              'high-priority': notification.priority === 'high'
            }"
          >
            <div class="d-flex align-items-start">
              <!-- Priority Icon -->
              <div class="priority-indicator">
                <CIcon
                    :name="getPriorityIcon(notification)"
                    :class="[
                    'notification-icon',
                    getPriorityIconClass(notification)
                  ]"
                />
              </div>

              <!-- Content -->
              <div class="notification-content">
                <div class="notification-header">
                  <div class="notification-header-left">
                    <h6 class="notification-title">{{ notification.title }}</h6>
                    <p class="notification-text">{{ notification.description }}</p>
                  </div>
                  <div class="notification-header-right">
                    <span class="notification-time">{{ notification.time }}</span>
                    <CButton
                        color="danger"
                        variant="ghost"
                        size="sm"
                        class="delete-button"
                        @click="showDeleteConfirm(notification)"
                    >
                      <CIcon name="cil-trash"/>
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </CCardBody>
    </CCard>
    <!-- Modal als direktes Kind des Root-Elements -->
    <CModal
        title="Benachrichtigung löschen"
        :show.sync="showDeleteModal"
    >
      <p>Möchten Sie diese Benachrichtigung wirklich löschen?</p>

      <template #footer>
        <CButton
            color="secondary"
            variant="ghost"
            @click="showDeleteModal = false"
        >
          Abbrechen
        </CButton>
        <CButton
            color="danger"
            @click="confirmDelete"
        >
          Löschen
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import webSocketService from '@/services/WebSocketService'

export default {
  name: 'NotificationsPage',

  data() {
    return {
      loading: true,
      searchQuery: '',
      showDeleteModal: false,
      notificationToDelete: null,
      tabs: [
        {label: 'Ungelesen', value: 'unread'},
        {label: 'Alle', value: 'all'}
      ]
    }
  },

  computed: {
    ...mapState('notifications', ['notifications']),
    ...mapGetters('notifications', [
      'activeNotifications',
      'unreadNotificationsCount',
      'highPriorityNotificationsCount'
    ]),

    unreadCount() {
      return this.unreadNotificationsCount
    },

    highPriorityCount() {
      return this.highPriorityNotificationsCount
    },

    activeTab() {
      return this.$store.state.notifications.activeTab
    },

    filteredNotifications() {
      if (!this.searchQuery) return this.activeNotifications

      const query = this.searchQuery.toLowerCase()
      return this.activeNotifications.filter(notification =>
          notification.title.toLowerCase().includes(query) ||
          notification.description.toLowerCase().includes(query)
      )
    },

    emptyStateMessage() {
      if (this.searchQuery) {
        return 'Keine Benachrichtigungen entsprechen Ihrer Suche.'
      }
      return this.activeTab === 'unread'
          ? 'Sie haben keine ungelesenen Benachrichtigungen.'
          : 'Sie haben keine Benachrichtigungen.'
    }
  },

  methods: {
    ...mapActions('notifications', [
      'fetchNotifications',
      'markNotificationAsRead',
      'removeNotification',
      'setActiveTab'
    ]),

    async markAllAsRead() {
      try {
        await this.$store.dispatch('notifications/markAllAsRead') // Änderung hier
        this.$toast?.success('Alle Benachrichtigungen wurden als gelesen markiert')
      } catch (error) {
        console.error('Error marking all as read:', error)
        this.$toast?.error('Fehler beim Markieren aller Benachrichtigungen')
      }
    },

    getPriorityIcon(notification) {
      return notification.priority === 'high' ? 'cil-warning' : 'cil-bell'
    },

    getPriorityIconClass(notification) {
      return {
        'text-danger': notification.priority === 'high',
        'text-primary': notification.priority === 'normal'
      }
    },

    async markAsRead(id) {
      await this.markNotificationAsRead(id)
    },

    showDeleteConfirm(notification) {
      console.log('showDeleteConfirm called with notification:', notification); // Debug
      this.notificationToDelete = notification;
      this.showDeleteModal = true;
      console.log('Modal should be visible now, showDeleteModal:', this.showDeleteModal); // Debug
    },

    async confirmDelete() {
      console.log('confirmDelete called'); // Debug
      console.log('notificationToDelete:', this.notificationToDelete); // Debug

      if (this.notificationToDelete) {
        console.log('Attempting to delete notification with ID:', this.notificationToDelete.id); // Debug
        const success = await this.removeNotification(this.notificationToDelete.id);
        console.log('Delete operation success:', success); // Debug

        if (success) {
          this.notificationToDelete = null;
          this.showDeleteModal = false;
        }
      }
    },

    setupWebSocket() {
      webSocketService.subscribeToTopic('/topic/notifications', (message) => {
        this.$store.dispatch('notifications/handleNewNotification', message)
      })
    }
  },

  async created() {
    try {
      await this.fetchNotifications()
      this.setupWebSocket()
    } finally {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.notifications-page {
  --notification-unread-bg: rgba(79, 70, 229, 0.05);
  --notification-hover-bg: rgba(0, 0, 0, 0.02);
  --priority-high-color: var(--cui-danger, #dc3545);
  --priority-normal-color: var(--cui-primary, #321fdb);
}

.notifications-list {
  padding: 0;
}

.notification-item {
  padding: 1rem;
  margin-bottom: 0.75rem;
  background-color: white;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.notification-item:hover {
  background-color: var(--notification-hover-bg);
}

.notification-item.unread {
  background-color: var(--notification-unread-bg);
  border-left: 4px solid var(--cui-primary, #321fdb);
}

.notification-item.high-priority {
  border-left: 4px solid var(--cui-danger, #dc3545);
}

.priority-indicator {
  margin-right: 1rem;
  padding-top: 0.25rem;
}

.notification-icon {
  width: 20px;
  height: 20px;
}

.notification-content {
  flex: 1;
  min-width: 0;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-header-left {
  flex: 1;
  min-width: 0;
}

.notification-header-right {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-left: 1rem;
}

.delete-button {
  padding: 0.25rem;
  margin-top: -0.25rem;
}

.delete-button:hover {
  color: var(--cui-danger);
}

.search-wrapper {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  color: var(--cui-text-muted);
  z-index: 1;
}

.search-input {
  padding-left: 2.5rem;
  width: 100%;
  border: 1px solid var(--cui-border-color);
  border-radius: 0.25rem;
  transition: all 0.2s;
}

.search-input:focus {
  border-color: var(--cui-primary);
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.notification-text {
  margin: 0;
  color: var(--cui-text-muted, #768192);
  font-size: 0.875rem;
  line-height: 1.5;
}

.tabs-wrapper {
  display: flex;
  gap: 2rem;
  border-bottom: 1px solid var(--cui-border-color, #d8dbe0);
  margin-bottom: 1rem;
}

.tab-link {
  padding: 0.5rem 0;
  color: var(--cui-text-muted, #768192);
  text-decoration: none;
  position: relative;
  font-weight: 500;
}

.tab-link:hover {
  color: var(--cui-primary, #321fdb);
}

.tab-link.active {
  color: var(--cui-primary, #321fdb);
}

.tab-link.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--cui-primary, #321fdb);
}

.search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--cui-text-muted, #768192);
  pointer-events: none;
}

.search-box .form-control {
  padding-left: 2.5rem;
  border-radius: 0.375rem;
  border: 1px solid var(--cui-border-color, #d8dbe0);
}

.search-box .form-control:focus {
  border-color: var(--cui-primary, #321fdb);
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.notification-list-enter-active,
.notification-list-leave-active {
  transition: all 0.3s ease;
}

.notification-list-enter,
.notification-list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

@media (max-width: 768px) {
  .notification-actions {
    opacity: 1;
    margin-top: 0.5rem;
  }

  .search-box {
    max-width: 100%;
  }

  .action-buttons {
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
