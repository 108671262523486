<template>
  <CContainer class="min-vh-100 d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol md="6" sm="8">
        <CCard v-if="ready" class="mx-4 mb-0">
          <CCardBody class="p-4">
            <CForm ref="form" @keyup.enter="submit">
              <h1>Letzter Schritt!</h1>
              <p class="text-muted">
                Persönliches Passwort festlegen und Starten.
              </p>
              <CInput :value="organizationName" readonly disabled>
                <template #prepend-content>
                  <CIcon name="cil-briefcase" />
                </template>
              </CInput>
              <CInput :value="email" readonly disabled>
                <template #prepend-content>
                  <CIcon name="cil-at" />
                </template>
              </CInput>
              <CInput
                placeholder="Neues Passwort festlegen..."
                type="password"
                required
                autofocus="autofocus"
                autocomplete="new-password"
                @mouseenter="$event.target.type = 'text'"
                @mouseleave="$event.target.type = 'password'"
                v-model="password"
              >
                <template #prepend-content>
                  <CIcon name="cil-lock-locked" />
                </template>
              </CInput>
              <CButton @click="submit" color="success" block
                >Jetzt loslegen!</CButton
              >
            </CForm>
          </CCardBody>
          <CCardFooter class="p-4">
            <p class="footnote" style="margin-bottom: 0">
              Fast geschafft. Es fehlt nur noch ein Kennwort, um Ihren Account
              zu schützen!
            </p>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from "axios";

export default {
  name: "UserRegisterByToken",
  data() {
    return {
      ready: false,
      token: null,
      organizationName: null,
      email: null,
      password: null,
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    if (typeof this.token === "string" && this.token.length > 0) {
      axios
        .get("/user/checkRegisterToken?token=" + this.token)
        .then((response) => {
          this.organizationName = response.data.organization.name;
          this.email = response.data.email;
          this.ready = true;
        });
    } else {
      this.$router.push({ name: "Page404" });
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.checkValidity()) {
        axios
          .post("/user/registerByToken", {
            token: this.token,
            password: this.password,
          })
          .then((resp) => {
            this.$router.push({ name: "Login", params: { email: this.email } });
            this.$toaster.makeToast(
              "Klasse!",
              "Ihre persönliche Marketing-Cloud wurde für Sie bereitgestellt. Loggen Sie ich einfach mit Ihrer E-Mail Adresse und Ihrem Passwort ein.",
            );
          })
          .catch((err) => {
            console.log("Error while register.", err);
            this.$toaster.makeToast(
              "Sry!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          });
      } else {
        this.$refs.form.reportValidity();
      }
    },
  },
};
</script>

<style scoped></style>
