<template>
  <div>
    <CRow>
      <CCol sm="6">
        <CInput label="Vorname" v-model="firstName" required />
      </CCol>
      <CCol sm="6">
        <CInput label="Nachname" v-model="lastName" required />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput label="E-Mail" type="email" v-model="email" required />
      </CCol>
      <CCol sm="6">
        <CInput label="Benutzername" type="text" v-model="username" required />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
          label="Passwort"
          type="password"
          v-model="password"
          autocomplete="new-password"
          required
          placeholder=""
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4">
        <CSelect
          label="Organisation"
          :value="organizationId"
          @update:value="organizationId = $event"
          required
          :options="organizations"
          :disabled="!$commons.isSystemAdmin()"
        />
      </CCol>
      <CCol sm="4">
        <CSelect
          label="Rolle"
          :value="role"
          @update:value="role = $event"
          required
          :description="roleDescription"
          :options="getOptionsForRole()"
        />
      </CCol>
      <CCol sm="4">
        <CSelect
          :label="
            $commons.hasExactRealmPlan('KENSINGTON') ? 'Standort' : 'Instanz'
          "
          :value="realmId"
          @update:value="realmId = $event"
          :required="
            (realms && role === 'REALM_ADMIN') || role === 'REALM_USER'
          "
          :options="realms"
          :disabled="
            realmsDisabled ||
            !((realms && role === 'REALM_ADMIN') || role === 'REALM_USER')
          "
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <ProcessingButton
          @click="submitClickHandler"
          :processing="processing"
          :idle-text="btnText"
          idle-color="success"
          processing-text="Wird erstellt..."
        />
        <ProcessingButton
          v-if="showDelete"
          @click="deleteClickHandler"
          :processing="processingDelete"
          :idle-text="'Löschen'"
          idle-color="danger"
          processing-text="Wird gelöscht..."
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";
import ProcessingButton from "@/components/ProcessingButton";

export default {
  name: "UserForm",
  components: { ProcessingButton },
  props: {
    passedData: {
      type: Object,
      default: () => {
        return { realm: { id: -1 } };
      },
    },
    btnText: {
      type: String,
      required: true,
    },
    processing: Boolean,
    showDelete: Boolean,
    processingDelete: Boolean,
  },
  data() {
    return {
      organizations: [],
      firstName: this.passedData.firstName || "",
      lastName: this.passedData.lastName || "",
      password: this.passedData.password || "",
      email: this.passedData.email || "",
      username: this.passedData.username || "",
      organizationId:
        (this.passedData.organization || {}).id ||
        this.passedData.organizationId ||
        -1,
      role: this.passedData.role || "REALM_USER",
      realms: null,
      realmId: (this.passedData.realm && this.passedData.realm.id) || "",
      roleDescription:
        commons.ROLES_MAP[this.passedData.role || "REALM_USER"].description,
      realmsDisabled: true,
    };
  },
  mounted() {
    if (commons.isSystemAdmin()) {
      axios.get("/organization").then((response) => {
        const organizations = [{ value: -1, label: "-- Bitte auswählen --" }];
        response.data.forEach((organization) => {
          organizations.push({
            value: organization.id,
            label: `${organization.id} - ${organization.name}`,
          });
        });
        this.organizations = organizations;
      });
    } else {
      const organization = commons.getCurrentUser().organization;
      this.organizations = [
        {
          value: organization.id,
          label: `${organization.id} - ${organization.name}`,
        },
      ];
      this.organizationId = organization.id;
    }
    if (this.realmId) {
      this.loadRealmsForOrganization();
    }
  },
  methods: {
    preparePassableData() {
      const data = { ...this.$data };
      delete data.organizations;
      delete data.realms;
      return data;
    },
    submitClickHandler() {
      if (!this.email || this.email.trim() === "") {
        this.$toaster.makeToast(
          "Fehler!",
          "Bitte geben Sie eine gültige E-Mail-Adresse an.",
        );
        return;
      }
      if (!this.username || this.username.trim() === "") {
        this.$toaster.makeToast(
          "Fehler!",
          "Bitte geben Sie einen Benutzernamen an.",
        );
        return;
      }
      if (this.organizationId === -1) {
        this.$toaster.makeToast(
          "Fehler!",
          "Bitte geben Sie eine Organisation an.",
        );
        return;
      }
      this.$emit("submit", { ...this.preparePassableData() });
    },
    deleteClickHandler() {
      this.$emit("delete", { ...this.preparePassableData() });
    },
    getOptionsForRole() {
      // make a const, that will represent the roles with its priorities and then calculate the rights.
      const role = commons.getCurrentRole();
      const allLower = commons.calculateRolesLower(role, true);
      return allLower.map((r) => {
        return { label: r.title, value: r.name };
      });
    },
    loadRealmsForOrganization() {
      if (this.organizationId > 0) {
        if (commons.isSystemAdmin() || commons.isOrganizationAdmin()) {
          document.body.style.cursor = "wait";
          axios
            .get("/organization/" + this.organizationId + "/realms")
            .then((response) => {
              document.body.style.cursor = "auto";
              this.realmsDisabled = false;
              this.realms = response.data.map((realm) => {
                return {
                  value: realm.id,
                  label: `${realm.id} - ${realm.name}`,
                };
              });
              if (!this.realmId || this.realmId === -1) {
                this.realmId = this.realms && this.realms[0].value;
              }
            });
        } else {
          const realm = commons.getCurrentRealm();
          this.realmsDisabled = true;
          this.realms = [
            { value: realm.id, label: `${realm.id} - ${realm.name}` },
          ];
          if (!this.realmId || this.realmId === -1) {
            this.realmId = realm.id;
          }
        }
      } else {
        this.resetRealmSelect();
      }
    },
    resetRealmSelect() {
      this.realms = [
        { value: -1, label: "-- Keine Organisation ausgewählt --" },
      ];
      this.realmsDisabled = true;
    },
  },
  watch: {
    organizationId(newVal, oldVal) {
      this.loadRealmsForOrganization();
    },
    role(newVal, oldVal) {
      this.roleDescription = commons.ROLES_MAP[newVal].description;
    },
  },
};
</script>
