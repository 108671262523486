<template>
  <div class="deal-card" @click="$emit('click', deal.id)">
    <div class="deal-info">
      <h4 class="deal-title">{{ deal.name }}</h4>
      <p class="deal-contact" v-if="deal.realmPeople">{{ deal.realmPeople.name }}</p>
      <div class="deal-value">
        <CIcon name="cil-euro" />
        <span>{{ formatMoney(deal.value) }}</span>
      </div>
    </div>
    <div class="deal-actions">
      <CButton
        @click.stop="$emit('edit', deal)"
        color="primary"
        size="sm"
        class="action-btn"
      >
        <CIcon name="cil-pencil" />
      </CButton>
      <CButton
        @click.stop="$emit('delete', deal)"
        color="danger"
        size="sm"
        class="action-btn"
      >
        <CIcon name="cil-trash" />
      </CButton>
    </div>
  </div>
</template>

<script>
import { CIcon, CButton } from '@coreui/vue'

export default {
  name: 'DealCard',

  components: {
    CIcon,
    CButton
  },

  props: {
    deal: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatMoney(value) {
      if (!value && value !== 0) return '0 €'
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value)
    }
  }
}
</script>

<style scoped>
.deal-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.deal-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.deal-info {
  margin-bottom: 0.5rem;
}

.deal-title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  color: var(--text-primary);
}

.deal-contact {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0 0 0.5rem 0;
}

.deal-value {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--text-primary);
}

.deal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.action-btn {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.deal-card:hover .action-btn {
  opacity: 1;
}
</style>
