<template>
  <div class="newsletter-generator">
    <CCard>
      <CCardHeader>
        <h4 class="mb-0">Newsletter-Formular Generator</h4>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <!-- Configuration Panel -->
          <CCol xs="12" xl="4">
            <div class="config-section">
              <CTabs variant="pills" :active-tab="activeConfigTab">
                <CTab title="Grundeinstellungen">
                  <template #title>
                    <CIcon name="cil-settings" class="me-2"/>
                    Basis
                  </template>
                  <div class="tab-content-wrapper">
                    <CInput
                        label="Formulartitel"
                        v-model="newsletter.title"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">
                          Wird als Hauptüberschrift im Formular angezeigt
                        </small>
                      </template>
                    </CInput>

                    <NewsletterGroupSelector
                        v-model="newsletter.groups"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">
                          Empfängergruppen für gezielte Artikel
                        </small>
                      </template>
                    </NewsletterGroupSelector>
                  </div>
                </CTab>

                <CTab title="Felder">
                  <template #title>
                    <CIcon name="cil-list" class="me-2"/>
                    Felder
                  </template>
                  <div class="tab-content-wrapper">
                    <CSelect
                        label="Vorname"
                        :value="newsletter.firstName"
                        @update:value="newsletter.firstName = $event"
                        :options="selectOptions"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">
                          Bestimmen Sie die Anforderungen für das Vorname-Feld
                        </small>
                      </template>
                    </CSelect>

                    <CSelect
                        label="Nachname"
                        :value="newsletter.lastName"
                        @update:value="newsletter.lastName = $event"
                        :options="selectOptions"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">
                          Bestimmen Sie die Anforderungen für das Nachname-Feld
                        </small>
                      </template>
                    </CSelect>
                  </div>
                </CTab>

                <CTab title="Design">
                  <template #title>
                    <CIcon name="cil-paint" class="me-2"/>
                    Design
                  </template>
                  <div class="tab-content-wrapper">
                    <ColorPicker
                        label="Button-Farbe"
                        v-model="newsletter.submitColor"
                        class="mb-3"
                    />
                    <CInput
                        label="Button-Text"
                        v-model="newsletter.submitText"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">Text auf dem Absende-Button</small>
                      </template>
                    </CInput>
                    <CTextarea
                        label="Bestätigungstext"
                        v-model="newsletter.thanks"
                        rows="3"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">
                          Wird nach erfolgreicher Anmeldung angezeigt
                        </small>
                      </template>
                    </CTextarea>
                    <CTextarea
                        label="DSGVO-Text"
                        v-model="newsletter.dsgvoText"
                        rows="4"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">
                          Der Datenschutztext für die Checkbox. Verwenden Sie {companyName} für den Firmennamen und {datenschutzhinweise} für den Link zu den Datenschutzhinweisen.
                        </small>
                      </template>
                    </CTextarea>
                    <CInput
                        label="Text für Datenschutzhinweise-Link"
                        v-model="newsletter.datenschutzhinweiseText"
                        class="mb-3"
                    >
                      <template #description>
                        <small class="text-muted">
                          Der Text, der für den Link zu den Datenschutzhinweisen verwendet wird
                        </small>
                      </template>
                    </CInput>
                  </div>
                </CTab>
              </CTabs>
            </div>
          </CCol>

          <!-- Preview Panel -->
          <CCol xs="12" xl="8">
            <div class="preview-section">
              <CTabs variant="pills" :active-tab="activePreviewTab">
                <CTab title="Vorschau">
                  <template #title>
                    <CIcon name="cil-monitor" class="me-2"/>
                    Vorschau
                  </template>
                  <div class="preview-container">
                    <div class="preview-toolbar d-flex justify-content-between align-items-center p-2">
                      <div class="device-selector">
                        <CButton
                            v-for="device in devices"
                            :key="device.id"
                            :color="currentDevice === device.id ? 'primary' : 'light'"
                            variant="ghost"
                            class="me-2 device-button"
                            @click="currentDevice = device.id"
                        >
                          <CIcon
                              :name="device.icon"
                              :class="{'text-white': currentDevice === device.id, 'text-primary': currentDevice !== device.id}"
                          />
                        </CButton>
                      </div>
                      <div class="preview-actions">
                        <CButton
                            color="primary"
                            variant="ghost"
                            @click="refreshPreview"
                            class="me-2"
                        >
                          <CIcon name="cil-reload" />
                        </CButton>
                      </div>
                    </div>
                    <div class="iframe-container" :class="currentDevice">
                      <iframe
                          ref="previewIframe"
                          title="Newsletter-Vorschau"
                          class="newsletter-preview"
                      ></iframe>
                    </div>
                  </div>
                </CTab>

                <CTab title="HTML Code">
                  <template #title>
                    <CIcon name="cil-code" class="me-2"/>
                    HTML Code
                  </template>
                  <div class="code-section">
                    <div class="code-toolbar d-flex justify-content-between align-items-center p-2">
                      <div class="code-info">
                        <CBadge color="info">HTML</CBadge>
                      </div>
                      <div class="code-actions">
                        <CButton
                            color="primary"
                            variant="ghost"
                            @click="copyCode"
                            class="copy-button"
                        >
                          <CIcon :name="copyIcon" /> {{ copyButtonText }}
                        </CButton>
                      </div>
                    </div>
                    <div class="code-container">
                      <pre><code ref="codeBlock" class="html"></code></pre>
                    </div>
                  </div>
                </CTab>
              </CTabs>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import commons from "@/commons";
import ColorPicker from "@/components/ColorPicker";
import NewsletterGroupSelector from "@/components/NewsletterGroupSelector";
import hljs from 'highlight.js/lib/core';
import html from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/atom-one-dark.css';

hljs.registerLanguage('html', html);

export default {
  name: "NewsletterGenerator",
  components: {
    NewsletterGroupSelector,
    ColorPicker,
  },
  data() {
    return {
      selectOptions: [
        { value: "required", label: "Erforderlich" },
        { value: "optional", label: "Optional" },
        { value: "disabled", label: "Nicht anzeigen" },
      ],
      iframeApiBaseUrl: process.env.VUE_APP_IFRAME_URL,
      activePreviewTab: 0,
      activeConfigTab: 0,
      currentDevice: 'desktop',
      copyIcon: 'cil-copy',
      copyButtonText: 'Code kopieren',
      devices: [
        { id: 'desktop', icon: 'cilScreenDesktop' },
        { id: 'tablet', icon: 'cil-tablet' },
        { id: 'mobile', icon: 'cil-mobile' }
      ],
      templateCode: "",
      iframeKey: this.$commons.getFromStorage("currentRealm").iframeKey,
      newsletter: {
        title: "Newsletter abonnieren",
        groups: [],
        firstName: "required",
        lastName: "required",
        thanks: "Vielen Dank für Ihre Anmeldung! Wir haben Ihnen eine Bestätigungs-E-Mail gesendet.",
        dsgvoText: "Ich bin damit einverstanden, dass mich {companyName} (telefonisch oder per E-Mail) kontaktiert und mich über die weitere Marktentwicklung und attraktive Angebote informiert und dazu meine angegebenen Daten speichert. Die {datenschutzhinweise} habe ich dabei zur Kenntnis genommen.",
        datenschutzhinweiseText: "Datenschutzhinweise",
        submitText: "Jetzt anmelden",
        submitColor: "#5d9286"
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.updatePreview();
    });
  },
  watch: {
    newsletter: {
      handler() {
        this.updatePreview();
      },
      deep: true
    }
  },
  methods: {
    async copyCode() {
      try {
        await navigator.clipboard.writeText(this.templateCode);
        this.copyIcon = 'cil-check';
        this.copyButtonText = 'Kopiert!';
        this.$toaster.makeToast(
            "Erfolg",
            "Code wurde in die Zwischenablage kopiert",
            "success"
        );

        setTimeout(() => {
          this.copyIcon = 'cil-copy';
          this.copyButtonText = 'Code kopieren';
        }, 2000);
      } catch (err) {
        this.$toaster.makeToast(
            "Fehler",
            "Code konnte nicht kopiert werden",
            "error"
        );
      }
    },

    // In der updatePreview Methode
    updatePreview() {
      this.$nextTick(() => {
        if (this.$refs.previewIframe) {
          const tmpl = this.generateTemplate();
          this.templateCode = tmpl;

          const iframe = this.$refs.previewIframe;
          iframe.contentWindow.document.body.innerHTML = tmpl;

          if (this.$refs.codeBlock) {
            // Highlight-Dataset zurücksetzen
            this.$refs.codeBlock.removeAttribute('data-highlighted');
            this.$refs.codeBlock.textContent = this.templateCode;
            hljs.highlightElement(this.$refs.codeBlock);
          }
        }
      });
    },

    refreshPreview() {
      this.updatePreview();
    },

    generateTemplate() {
      let tmpl = "<!-- Beginn LeadMeUp Anmeldeformular -->";
      tmpl += `
<style>
    #cprtn {
        background: #fff;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        max-width: 600px;
        margin: 0 auto;
        padding: 2rem;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    #cprtn > form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    #cprtn h2 {
        color: #2c3e50;
        margin-bottom: 1.5rem;
        font-weight: 600;
        font-size: 1.75rem;
    }
    #cprtn label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #374151;
        width: 100%;
    }
    #cprtn input:not([type="checkbox"]) {
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 6px;
        transition: all 0.2s ease-in-out;
    }
    #cprtn input:not([type="checkbox"]):focus {
        outline: none;
        border-color: ${this.newsletter.submitColor};
        box-shadow: 0 0 0 3px ${this.newsletter.submitColor}25;
    }
    #cprtn input[type="checkbox"] {
        margin-right: 0.75rem;
        width: 1.25rem;
        height: 1.25rem;
        border: 2px solid #cbd5e0;
        border-radius: 4px;
        transition: all 0.2s ease;
    }
    .cprtn-input-group {
        margin-bottom: 0.5rem;
    }
    .cprtn-required-notice {
        text-align: right;
        font-size: 0.875rem;
        color: #6b7280;
        margin-bottom: 1.5rem;
    }
    .cprtn-submit {
        background: ${this.newsletter.submitColor};
        color: #fff;
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
        font-weight: 600;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease;
        min-width: 140px;
    }
    .cprtn-submit:hover {
        background: ${this.shadeColor(this.newsletter.submitColor, -10)};
        transform: translateY(-1px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .cprtn-submit:active {
        transform: translateY(0);
    }
    #cprtn span.required {
        color: #dc3545;
        margin-left: 0.25rem;
    }
    #submit-notice {
        color: #0f766e;
        font-weight: 500;
        padding: 1rem;
        background: #f0fdfa;
        border-radius: 6px;
        display: none;
        animation: fadeIn 0.3s ease-in-out;
    }
    #error-message {
        color: #dc2626;
        font-weight: 500;
        padding: 1rem;
        background: #fef2f2;
        border-radius: 6px;
        display: none;
        animation: fadeIn 0.3s ease-in-out;
    }
    @keyframes fadeIn {
        from { opacity: 0; transform: translateY(-10px); }
        to { opacity: 1; transform: translateY(0); }
    }
    @media (max-width: 640px) {
        #cprtn {
            padding: 1.5rem;
            border-radius: 8px;
        }
        #cprtn h2 {
            font-size: 1.5rem;
        }
    }
</style>
<div id="cprtn">
  <form id="cprtn-form" action="${this.iframeApiBaseUrl}/newsletter/subscribe/${this.iframeKey}" method="POST">
    <h2>${this.newsletter.title}</h2>
    <div class="cprtn-required-notice">
      <span class="required">*</span> erforderlich
    </div>
    <div class="cprtn-input-group">
      <label for="cprtn-email">E-Mail <span class="required">*</span></label>
      <input id="cprtn-email" name="email" type="email" required>
    </div>
    ${
          this.newsletter.firstName !== "disabled"
              ? `<div class="cprtn-input-group">
      <label for="cprtn-firstname">Vorname${this.newsletter.firstName === "required" ? '<span class="required"> *</span>' : ""}</label>
      <input id="cprtn-firstname" name="firstName" type="text" autocomplete="given-name"${this.newsletter.firstName === "required" ? " required" : ""}>
    </div>`
              : ""
      }
    ${
          this.newsletter.lastName !== "disabled"
              ? `<div class="cprtn-input-group">
      <label for="cprtn-lastname">Nachname${this.newsletter.lastName === "required" ? '<span class="required"> *</span>' : ""}</label>
      <input id="cprtn-lastname" name="lastName" type="text" autocomplete="family-name"${this.newsletter.lastName === "required" ? " required" : ""}>
    </div>`
              : ""
      }
    <div class="cprtn-input-group">
  <label style="display: flex; align-items: flex-start; cursor: pointer;">
    <input id="cprtn-dsgvo" name="dsgvo" type="checkbox" required>
    <span style="margin-top: 2px">
      ${this.newsletter.dsgvoText
          .replace('{companyName}', this.$commons.getFromStorage("currentRealm").name)
          .replace('{datenschutzhinweise}', `<a href="${this.iframeApiBaseUrl}/dsgvo-privacy/${this.iframeKey}"
           target="_blank"
           style="color: ${this.newsletter.submitColor}; text-decoration: none; border-bottom: 1px solid;">
          <u>${this.newsletter.datenschutzhinweiseText}</u>
        </a>`)}
      <span class="required">*</span>
    </span>
  </label>
</div>`;

      this.newsletter.groups.forEach((group) => {
        tmpl += `<input type="hidden" name="groups" value="${group.id}">`;
      });

      tmpl += `
    <div class="cprtn-input-group" style="text-align: center;">
      <p id="submit-notice">${this.newsletter.thanks}</p>
      <p id="error-message"></p>
      <button type="submit" class="cprtn-submit">${this.newsletter.submitText}</button>
    </div>
  </form>
</div>`;

      tmpl += `<script type="text/javascript" src="${process.env.VUE_APP_CDN_URL}/static/cprtn.min.js"><\/script>`;
      tmpl += "<!-- Ende LeadMeUp Anmeldeformular -->";
      return tmpl;
    },

    shadeColor(color, percent) {
      let R = parseInt(color.substring(1, 3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
      const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
      const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    },
  },
};
</script>

<style lang="scss" scoped>
.newsletter-generator {
  .config-section, .preview-section {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    ::v-deep .nav-pills {
      padding: 0.75rem 0.75rem 0;
      border-bottom: 1px solid #e5e7eb;
      background: #f9fafb;

      .nav-item {
        margin-right: 0.5rem;

        .nav-link {
          padding: 0.5rem 1rem;
          color: #4b5563;
          font-weight: 500;
          border-radius: 6px;
          transition: all 0.2s ease;

          &:hover {
            background: rgba(93, 146, 134, 0.08);
          }

          &.active {
            background: #5d9286;
            color: #fff;

            i {
              color: #fff !important;
            }
          }

          i {
            margin-right: 0.5rem;
            color: #5d9286;
          }
        }
      }
    }
  }

  .tab-content-wrapper {
    padding: 1.5rem;
  }

  .preview-container {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    overflow: hidden;
    margin: 1rem;
    background: #f9fafb;

    .preview-toolbar {
      border-bottom: 1px solid #e5e7eb;
      background: #fff;

      .device-button {
        padding: 0.5rem;
        margin-right: 0.5rem;
        border-radius: 6px;

        i {
          font-size: 1.1rem;
        }

        &:hover {
          background: rgba(93, 146, 134, 0.08);
        }

        &.btn-primary {
          background: #5d9286;
          border-color: #5d9286;

          i {
            color: #fff !important;
          }
        }

        &.btn-light {
          i {
            color: #5d9286;
          }
        }
      }

      .preview-actions {
        .btn {
          padding: 0.5rem;
          border-radius: 6px;

          &:hover {
            background: rgba(93, 146, 134, 0.08);
            transform: rotate(45deg);
            transition: transform 0.3s ease;
          }
        }
      }
    }

    .iframe-container {
      padding: 1rem;
      background: #fff;
      transition: all 0.3s ease;

      &.desktop {
        max-width: 100%;
      }

      &.tablet {
        max-width: 768px;
        margin: 0 auto;
      }

      &.mobile {
        max-width: 375px;
        margin: 0 auto;
      }

      .newsletter-preview {
        width: 100%;
        height: 600px;
        border: none;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .code-section {
    margin: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    overflow: hidden;

    .code-toolbar {
      background: #fff;
      border-bottom: 1px solid #e5e7eb;

      .copy-button {
        transition: all 0.2s ease;

        &:hover {
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }

    .code-container {
      background: #282c34;

      pre {
        margin: 0;
        padding: 1rem;
        max-height: 500px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        &::-webkit-scrollbar-track {
          background: #21252b;
        }

        &::-webkit-scrollbar-thumb {
          background: #4b5363;
          border-radius: 4px;

          &:hover {
            background: #5c677d;
          }
        }

        code {
          font-family: 'Fira Code', 'Monaco', 'Menlo', 'Ubuntu Mono', monospace;
          font-size: 0.875rem;
          line-height: 1.6;
          color: #abb2bf;
        }
      }
    }
  }
}

// Syntax Highlighting
::v-deep {
  .hljs {
    background: transparent;
    padding: 0;
  }

  .hljs-tag {
    color: #e06c75;
  }

  .hljs-name {
    color: #e06c75;
  }

  .hljs-attr {
    color: #d19a66;
  }

  .hljs-string {
    color: #98c379;
  }

  .hljs-comment {
    color: #7f848e;
    font-style: italic;
  }
}

// Responsive
@media (max-width: 1200px) {
  .newsletter-generator {
    .config-section {
      margin-bottom: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .newsletter-generator {
    .preview-section {
      .preview-container {
        margin: 0.5rem;

        .preview-toolbar {
          padding: 0.5rem;
        }

        .iframe-container {
          padding: 0.5rem;
        }
      }
    }

    .code-section {
      margin: 0.5rem;
    }
  }
}
</style>
