<template>
  <div class="kpi-card" :class="colorClass">
    <div class="card-icon">
      <CIcon :name="icon" size="xl" />
    </div>
    <div class="card-content">
      <h3 class="card-title">{{ title }}</h3>
      <div class="card-value">
        {{ value }}
        <TrendIndicator
          v-if="trend"
          :value="trend"
          :show-value="true"
          class="trend-indicator"
        />
      </div>
      <div class="card-description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import { CIcon } from '@coreui/vue'
import TrendIndicator from './TrendIndicator.vue'

export default {
  name: 'KpiCard',

  components: {
    CIcon,
    TrendIndicator,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'success', 'info', 'warning', 'danger'].includes(value),
    },
    trend: {
      type: Number,
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
  },

  computed: {
    colorClass() {
      return `color-${this.color}`
    },
  },
}
</script>

<style scoped>
.kpi-card {
  background: var(--card-background);
  border-radius: 0.75rem;
  padding: 1.5rem;
  display: flex;
  gap: 1rem;
  transition: all 0.2s ease;
  border: 1px solid var(--border-color);
}

.kpi-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  flex-shrink: 0;
}

.card-content {
  flex-grow: 1;
  min-width: 0;
}

.card-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
  margin: 0 0 0.5rem 0;
}

.card-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-primary);
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.card-description {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

/* Color Variants */
.color-primary .card-icon {
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}

.color-success .card-icon {
  background-color: rgba(56, 161, 105, 0.2);
  color: #38a169;
}

.color-info .card-icon {
  background-color: rgba(49, 151, 149, 0.2);
  color: #319795;
}

.color-warning .card-icon {
  background-color: rgba(214, 158, 46, 0.2);
  color: #d69e2e;
}

.color-danger .card-icon {
  background-color: rgba(229, 62, 62, 0.2);
  color: #e53e3e;
}

/* Trend Indicator Styles */
.trend-indicator {
  font-size: 0.875rem;
  font-weight: 500;
}

@media (max-width: 640px) {
  .kpi-card {
    padding: 1rem;
  }

  .card-icon {
    width: 40px;
    height: 40px;
  }

  .card-value {
    font-size: 1.25rem;
  }
}
</style>
