<template>
  <div class="d-flex justify-content-center align-items-center" style="min-height: 200px">
    <div class="text-center">
      <h2>Instagram Business Verbindung wird hergestellt...</h2>
      <div v-if="error" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'InstagramCallback',
  data() {
    return {
      error: false,
      errorMessage: ''
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (!code) {
      this.error = true;
      this.errorMessage = 'Fehlende Parameter in der Callback-URL';
      return;
    }

    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) {
      this.error = true;
      this.errorMessage = 'Nicht authentifiziert';
      return;
    }

    // Sende zum Instagram-Callback endpoint
    axios.post('/socialMedia/instagram/callback',
      {
        code,
        state // State für zusätzliche Sicherheit mitsenden
      },
      {
        headers: {
          'Authorization': `Bearer ${jwtToken}`
        }
      }
    )
      .then(response => {
        if (response.data && response.data.success) {
          this.$toaster.makeToast('Erfolg', 'Instagram Business wurde erfolgreich verbunden');
          this.$router.push('/socialMedia/connections');
        } else {
          throw new Error(response.data?.message || 'Verbindung fehlgeschlagen');
        }
      })
      .catch(error => {
        console.error('Instagram connection error:', error);
        this.error = true;
        this.errorMessage = error.response?.data?.message ||
          'Fehler bei der Verbindung mit Instagram Business';
      });
  }
}
</script>
