<template>
  <div>
    <CBreadcrumbRouter class="border-0 mb-4" />
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <h4>
              <CIcon name="cil-pencil" />
              Kampagne bearbeiten
            </h4>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateCampaign">
              <CInput
                  label="Name"
                  v-model="campaign.name"
                  description="Bearbeiten Sie den Namen der Kampagne."
                  :disabled="loading"
                  required
              />

              <CSelect
                  label="Empfängergruppen"
                  :options="groupOptions"
                  multiple
                  v-model="campaign.groupIds"
                  :disabled="loading"
              />

              <div class="mt-4">
                <CButton color="primary" type="submit" :disabled="loading">
                  <CSpinner v-if="loading" size="sm" color="white" />
                  <span v-else>Speichern</span>
                </CButton>
                <CButton color="secondary" @click="goBack" :disabled="loading">
                  Abbrechen
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import { CBreadcrumbRouter } from "@coreui/vue";

export default {
  name: "NewsletterCampaignEdit",
  components: {
    CBreadcrumbRouter,
  },
  data() {
    return {
      loading: false,
      campaign: {
        id: null,
        name: "",
        groupIds: [],
        articleIds: [],
      },
      groupOptions: [],
    };
  },
  mounted() {
    this.loadCampaign();
    this.loadGroupOptions();
  },
  methods: {
    loadCampaign() {
      const campaignId = this.$route.params.id;
      this.loading = true;

      axios
          .get(`/campaigns/${campaignId}`)
          .then((response) => {
            const { id, name, groups, articles } = response.data;
            this.campaign = {
              id,
              name,
              groupIds: groups.map((group) => group.id),
              articleIds: articles.map((article) => article.id),
            };
          })
          .catch((err) => {
            console.error("Error loading campaign:", err);
            this.$toaster.makeToast("Fehler", "Kampagne konnte nicht geladen werden.");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    loadGroupOptions() {
      axios
          .get("/newsletter/group")
          .then((response) => {
            this.groupOptions = response.data.map((group) => ({
              value: group.id,
              text: group.name,
            }));
          })
          .catch((err) => {
            console.error("Error loading groups:", err);
            this.groupOptions = [];
          });
    },
    updateCampaign() {
      this.loading = true;

      axios
          .put(`/campaigns/${this.campaign.id}`, {
            name: this.campaign.name,
            groupIds: this.campaign.groupIds,
          }, {
            headers: {
              'Content-Type': 'application/json', // JSON-Inhaltstyp angeben
            },
          })
          .then(() => {
            this.$toaster.makeToast("Erfolgreich", "Kampagne wurde erfolgreich aktualisiert.");
            this.$router.push("/newsletter/campaigns");
          })
          .catch((err) => {
            console.error("Error updating campaign:", err);
            this.$toaster.makeToast("Fehler", "Kampagne konnte nicht aktualisiert werden.");
          })
          .finally(() => {
            this.loading = false;
          });
    },
    goBack() {
      this.$router.push("/newsletter/campaigns");
    },
  },
};
</script>

<style scoped>
.card-header h4 {
  margin-bottom: 0;
}
</style>