<template>
  <div>
    <LgcModule
      class="quiz-module"
      @delete="$emit('delete', $event)"
      @add-below="$emit('add-below', $event)"
    >
      <!-- Falls keine Karten vorhanden sind, zeigen wir eine Nachricht -->
      <div v-if="!localCards.length" class="quiz-module__empty">
        <p>Keine Quiz-Karten vorhanden. Fügen Sie eine neue Karte hinzu.</p>
      </div>

      <div
        v-else
        v-for="(card, index) in localCards"
        :key="card.id"
        class="quiz-card"
        @click="handleCardEdit(index)"
      >
        <div class="quiz-card__body">
          <div class="quiz-card__image">
            <img :src="card.image || defaultImage" :alt="card.title" />
          </div>
          <div
            class="quiz-card__title"
            :contenteditable="true"
            @input="updateCardTitle($event, card)"
            @blur="validateAndUpdateTitle($event, card)"
            @keydown.enter.prevent="$event.target.blur()"
            v-text="card.title"
          />
        </div>
        <button
          type="button"
          class="quiz-card__action quiz-card__action--add"
          @click.stop="handleAddCard(card.id)"
          :aria-label="'Neue Karte nach ' + card.title + ' hinzufügen'"
        >
          <PlusIcon />
        </button>
        <button
          type="button"
          class="quiz-card__action quiz-card__action--delete"
          @click.stop="handleDeleteCard(card.id)"
          :aria-label="card.title + ' löschen'"
        >
          <DeleteIcon />
        </button>
      </div>
    </LgcModule>

    <CModal
      :show.sync="editModal.visible"
      :closeOnBackdrop="true"
      title="Quiz bearbeiten"
      @close="handleModalClose"
    >
      <div class="quiz-editor">
        <h6 class="quiz-editor__title">Vorhandene Bilder/Icons</h6>
        <div class="quiz-editor__image-grid">
          <div
            v-for="(img, index) in predefinedImages"
            :key="index"
            class="quiz-editor__image-item"
            :class="{ 'is-selected': currentEditingCard.image === img }"
            @click="selectPredefinedImage(img)"
          >
            <img :src="img" :alt="`Vorhandenes Bild ${index + 1}`" />
          </div>
        </div>
        <hr class="quiz-editor__divider" />
        <CRow>
          <CCol>
            <ImageUploadField
              v-if="editModal.visible"
              v-model="currentEditingCard.image"
              label="Eigenes Bild"
              @delete="handleImageDelete"
            />
          </CCol>
        </CRow>
      </div>
      <div slot="footer">
        <CButton
          color="success"
          variant="outline"
          class="mr-3"
          @click="saveCardChanges"
        >
          Speichern
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import ImageUploadField from "@/components/ImageUploadField";
import LgcModule from "@/views/base/leadgenerator/lgc-modules/LgcModule";

// SVG Components als separate Komponenten definiert
const PlusIcon = {
  functional: true,
  render(h) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 191 104.23',
        class: 'icon-plus'
      }
    }, [
      h('rect', {
        attrs: {
          class: 'icon-bg',
          x: '0.5',
          y: '0.5',
          width: '190',
          height: '103.23',
          rx: '8'
        }
      }),
      h('rect', {
        attrs: {
          class: 'icon-line',
          x: '92.9',
          y: '13.15',
          width: '5.2',
          height: '77.94'
        }
      }),
      h('rect', {
        attrs: {
          class: 'icon-line',
          x: '92.9',
          y: '13.15',
          width: '5.2',
          height: '77.94',
          transform: 'translate(147.61 -43.39) rotate(90)'
        }
      })
    ]);
  }
};

const DeleteIcon = {
  functional: true,
  render(h) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 191 104.2',
        class: 'icon-delete'
      }
    }, [
      h('path', {
        attrs: {
          class: 'icon-bg',
          d: 'M8.5,0.5h174c4.4,0,8,3.6,8,8v87.2c0,4.4-3.6,8-8,8H8.5c-4.4,0-8-3.6-8-8V8.5C0.5,4.1,4.1,0.5,8.5,0.5z'
        }
      }),
      h('path', {
        attrs: {
          class: 'icon-symbol',
          d: 'M84.9,50.3c-0.1-1.7,1.3-3.2,3-3.3c1.7-0.1,3.2,1.3,3.3,3c0,0.1,0,0.2,0,0.3v23.3c0.1,1.7-1.3,3.2-3,3.3 c-1.7,0.1-3.2-1.3-3.3-3c0-0.1,0-0.2,0-0.3V50.3z M127.1,37.1l-3.2,42.7c-0.2,3.5-1.7,6.8-4.2,9.4c-2.4,2.5-5.8,3.9-9.2,3.8H80.5 c-3.5,0-6.8-1.4-9.2-3.8l0,0c-2.5-2.5-4-5.8-4.2-9.4l-3.2-42.7h-5.1v-9.2c0-2.2,1.1-3,3.2-3h15.1v-6.4c0-4,3.2-7.2,7.2-7.2h22.5 c3.9,0,7.1,3.2,7.2,7.2v6.4h15.5c0.2,0,0.4,0,0.7,0c1.2,0.2,2.1,1.2,2.2,2.3c0,0.1,0,0.3,0,0.4v9.4H127.1z M120.8,37.2H70.2 l3.2,42.1c0.1,2,1,3.9,2.4,5.4l0,0c1.3,1.3,3,2,4.8,2h29.9c1.8,0,3.5-0.7,4.8-2c1.4-1.5,2.3-3.4,2.4-5.4L120.8,37.2L120.8,37.2z M105.9,24.8v-5.6H85.1v5.6H105.9z M99.9,50.3c-0.1-1.7,1.3-3.2,3-3.3c1.7-0.1,3.2,1.3,3.3,3c0,0.1,0,0.2,0,0.3v23.3 c0.1,1.7-1.3,3.2-3,3.3s-3.2-1.3-3.3-3c0-0.1,0-0.2,0-0.3C99.9,73.6,99.9,50.3,99.9,50.3z'
        }
      })
    ]);
  }
};

export default {
  name: "LgcModuleQuiz",

  components: {
    LgcModule,
    ImageUploadField,
    PlusIcon,
    DeleteIcon
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      localCards: [],
      defaultImage: 'img/image_placeholder.png',
      editModal: {
        visible: false,
        editingIndex: -1
      },
      currentEditingCard: {
        id: null,
        title: '',
        image: ''
      },
      predefinedImages: [
        "https://picsum.photos/seed/5/400/400",
        "https://picsum.photos/seed/6/400/400",
        "https://picsum.photos/seed/7/400/400",
        "https://picsum.photos/seed/8/400/400",
        "https://picsum.photos/seed/9/400/400",
        "https://picsum.photos/seed/10/400/400",
        "https://picsum.photos/seed/11/400/400",
        "https://picsum.photos/seed/12/400/400",
      ]
    };
  },

  created() {
    this.initializeCards();
  },

  methods: {
    emitUpdate(updatedCards) {
      this.$emit('input', { value: updatedCards });  // Wichtig: Wir wrappen die Karten in ein props-Objekt
    },
    initializeCards() {
      this.localCards = Array.isArray(this.value)
        ? JSON.parse(JSON.stringify(this.value))
        : [];
    },

    handleCardEdit(index) {
      this.editModal.editingIndex = index;
      this.currentEditingCard = { ...this.localCards[index] };
      this.editModal.visible = true;
    },


    updateCardTitle(e, card) {
      this.$emit('modal-open');
      const newTitle = e.target.innerText.trim();
      if (newTitle && newTitle !== card.title) {
        const index = this.localCards.findIndex(c => c.id === card.id);
        if (index !== -1) {
          const updatedCards = [...this.localCards];
          updatedCards[index] = { ...card, title: newTitle };
          this.localCards = updatedCards;
          this.emitUpdate(updatedCards);
        }
      }
      setTimeout(() => {
        this.$emit('modal-close');
      }, 100);
    },

    validateAndUpdateTitle(event, card) {
      const newTitle = event.target.innerText.trim();
      if (!newTitle) {
        event.target.innerText = card.title;
        return;
      }
      const index = this.localCards.findIndex(c => c.id === card.id);
      if (index !== -1) {
        const updatedCards = [...this.localCards];
        updatedCards[index] = { ...card, title: newTitle };
        this.localCards = updatedCards;
        this.emitUpdate(updatedCards);
      }
    },

    handleAddCard(id) {
      this.$emit('modal-open'); // Flag in Hauptkomponente setzen
      const index = this.localCards.findIndex(card => card.id === id);
      const newCard = {
        id: Date.now(),
        title: "Neu!",
        image: this.defaultImage
      };

      const updatedCards = [...this.localCards];
      updatedCards.splice(index + 1, 0, newCard);
      this.localCards = updatedCards;
      this.emitUpdate(updatedCards);

      setTimeout(() => {
        this.$emit('modal-close'); // Flag in Hauptkomponente zurücksetzen
      }, 100);
    },

    handleDeleteCard(id) {
      this.$emit('modal-open');
      const updatedCards = this.localCards.filter(card => card.id !== id);
      this.localCards = updatedCards;
      this.emitUpdate(updatedCards);
      setTimeout(() => {
        this.$emit('modal-close');
      }, 100);
    },


    selectPredefinedImage(imageUrl) {
      this.currentEditingCard.image = imageUrl;
    },

    handleImageDelete() {
      this.currentEditingCard.image = this.defaultImage;
    },

    handleModalClose() {
      this.editModal.visible = false;
      this.editModal.editingIndex = -1;
      this.currentEditingCard = {
        id: null,
        title: '',
        image: ''
      };
    },


    saveCardChanges() {
      if (this.editModal.editingIndex !== -1) {
        const updatedCards = [...this.localCards];
        updatedCards[this.editModal.editingIndex] = { ...this.currentEditingCard };
        this.localCards = updatedCards;
        this.emitUpdate(updatedCards);
      }
      this.handleModalClose();
    }
  },


  watch: {
    value: {
      handler(newVal) {
        const newValString = JSON.stringify(newVal);
        const localCardsString = JSON.stringify(this.localCards);

        if (newValString !== localCardsString) {
          this.localCards = Array.isArray(newVal) ? JSON.parse(JSON.stringify(newVal)) : [];
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss">
.quiz-module {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  width: 100%;
  justify-content: space-evenly;

  &__empty {
    width: 100%;
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px dashed #dee2e6;
  }
}

.quiz-card {
  position: relative;
  width: 46%;
  min-height: 200px;
  max-height: 200px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    outline: 2px dashed #46d7ce;
    outline-offset: 4px;

    .quiz-card__action {
      opacity: 1;
      visibility: visible;
    }
  }

  &__body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #333;
    background-color: #fff;
  }

  &__image {
    position: relative;
    height: 75%;
    cursor: pointer;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      pointer-events: none;
    }
  }

  &__title {
    outline: none;
    width: 100%;
    height: 25%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    text-align: center;
    word-break: break-word;

    &:focus {
      background-color: rgba(70, 215, 206, 0.1);
    }
  }

  &__action {
    position: absolute;
    bottom: -13px;
    width: 28px;
    height: 28px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &--add {
      left: 0;
      right: 30px;
      margin: auto;
    }

    &--delete {
      left: 30px;
      right: 0;
      margin: auto;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.quiz-editor {
  padding: 20px;

  &__title {
    text-align: left;
    margin-bottom: 15px;
  }

  &__image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }

  &__image-item {
    cursor: pointer;
    transition: transform 0.2s ease-out;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
    overflow: hidden;

    &:hover {
      transform: scale(1.02);
    }

    &.is-selected {
      border-color: #46d7ce;
      box-shadow: 0 0 0 2px #46d7ce;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__divider {
    margin: 20px 0;
    border-color: rgba(0, 0, 0, 0.1);
  }
}

// Icon styles
.icon-plus, .icon-delete {
  .icon-bg {
    transition: fill 0.2s ease;
  }
}

.icon-plus {
  .icon-bg {
    fill: #22b3ab;
    stroke: #04786c;
  }
  .icon-line {
    fill: #fff;
  }
  &:hover .icon-bg {
    fill: #16726d;
  }
}

.icon-delete {
  .icon-bg {
    fill: #dc2f2f;
    stroke: #8e1a1a;
  }
  .icon-symbol {
    fill: #fff;
  }
  &:hover .icon-bg {
    fill: #b71c1c;
  }
}
</style>
