<template>
  <div class="form-group form-row" :key="name">
    <CCol
      v-if="label"
      tag="label"
      :sm="horizontal ? 3 : 12"
      class="col-form-label"
    >
      {{ label }}
      <small
        v-if="description && !horizontal"
        class="form-text text-muted w-100"
        v-text="description"
      />
    </CCol>
    <CCol :sm="horizontal ? 9 : 12">
      <CInputCheckbox
        :label="text"
        :value="text"
        :custom="true"
        :name="name"
        :checked="dataChecked"
        @update:checked="
          dataChecked = $event;
          $emit('input', $event);
        "
        :inline="false"
        :disabled="disabled"
      />
      <small
        v-if="description && horizontal"
        class="form-text text-muted w-100"
        v-text="description"
      />
    </CCol>
  </div>
</template>

<script>
export default {
  name: "BooleanCheckbox",
  props: {
    label: String,
    name: String,
    text: String,
    description: String,
    disabled: [Boolean, String],
    horizontal: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataChecked: this.value,
    };
  },
};
</script>

<style scoped></style>
