<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <div v-if="additionalPage.isCreate">Neue Seite erstellen</div>
        <div v-else>Seite bearbeiten</div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol xs="12" xl="10">
          <CSelect
            label="Platzierung"
            :value="append"
            @update:value="append = $event"
            :options="additionalPagesBeforeAfterOptions"
            horizontal
          />
          <CInput
            type="number"
            step="1"
            min="1"
            max="99"
            label="Seitennummer"
            v-model="page"
            horizontal
          />
          <CInput type="text" label="Titel der Seite" v-model="title" horizontal />
          <WysiwygEditor
            label="Seiteninhalt"
            v-model="content"
            description="Bitte geben Sie den Inhalt der neuen Seite an. Sie können beim Inhalt nur ein paar HTML-Tags nutzen. Erlaubt sind: <b> <u> <i> <br> <img> Nutze das Medien hinzufügen Menü, um Bilder einzufügen. → Bitte geben Sie den Inhalt der Seite ein. Nur einige HTML-Tags sind erlaubt: <b>, <u>, <i>, <br>, <img>. Nutzen Sie das Menü 'Medien hinzufügen', um Bilder einzufügen."
            horizontal
          />
          <CRow>
            <CCol tag="label" sm="3" class="col-form-label" />
            <CCol sm="9">
              <CInputCheckbox
                label="Ganze Seite als Bild?"
                :value="false"
                custom
                :checked="fullpage"
                @update:checked="fullpage = !fullpage"
                :inline="false"
              />
              <small class="form-text text-muted w-100"
                >Wenn Sie dieses Kästchen aktivieren, dürfen Sie nur ein <img> HTML-Tag (Bild) im Inhalt haben. Alle anderen Tags werden ignoriert. → Wenn Sie diese Option aktivieren, darf der Inhalt nur ein Bild (<img>-Tag) enthalten. Alle anderen Tags werden ignoriert.</small
              >
            </CCol>
          </CRow>
          <CButton
            v-if="additionalPage.isCreate"
            color="primary"
            @click="submit"
            >Hinzufügen</CButton
          >
          <CButton v-else color="primary" @click="submit">Speichern</CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import WysiwygEditor from "@/components/WysiwygEditor";
import Checkbox from "@/components/Checkbox";

export default {
  name: "AdditionalPagesCard",
  components: {
    WysiwygEditor,
    Checkbox,
  },
  props: {
    additionalPage: {
      type: Object,
      default: () => {
        return {
          append: "before",
          page: 1,
          fullpage: false,
          isCreate: true,
        };
      },
    },
  },
  data() {
    return {
      append: this.additionalPage.append,
      page: this.additionalPage.page,
      content: this.additionalPage.content,
      fullpage: this.additionalPage.fullpage,
      title: this.additionalPage.title,
      additionalPagesBeforeAfterOptions: [
        { label: "Vor", value: "before" },
        { label: "Nach", value: "after" },
      ],
    };
  },
  methods: {
    submit() {
      const values = Object.assign({}, this.$data);
      delete values.additionalPagesBeforeAfterOptions;
      this.$emit("click", values);
    },
  },
  watch: {
    additionalPage(newVal) {
      this.append = newVal.append;
      this.page = newVal.page;
      this.content = newVal.content;
      this.fullpage = newVal.fullpage;
      this.title = newVal.title;
    },
  },
};
</script>

<style scoped></style>