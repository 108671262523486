<template>
  <div class="deal-pipeline">
    <div class="pipeline-stages">
      <div
        v-for="stage in pipelineStages"
        :key="stage.id"
        class="pipeline-stage"
      >
        <div class="stage-header">
          <h3 class="stage-title">{{ stage.name }}</h3>
          <span class="stage-count">{{ stage.deals.length }}</span>
        </div>
        <div class="stage-value">
          {{ formatMoney(calculateStageValue(stage.deals)) }}
        </div>
        <div class="deals-container">
          <DealCard
            v-for="deal in stage.deals"
            :key="deal.id"
            :deal="deal"
            @click="$emit('click', deal.id)"
            @edit="$emit('edit', $event, deal)"
            @delete="$emit('delete', deal)"
          />
          <div v-if="!stage.deals.length" class="empty-stage">
            <CIcon name="cil-folder-open" size="xl" />
            <p>Keine Deals in dieser Phase</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CIcon } from '@coreui/vue'
import DealCard from './DealCard.vue'

export default {
  name: 'DealPipeline',

  components: {
    CIcon,
    DealCard,
  },

  props: {
    deals: {
      type: Array,
      default: () => [],
    },
    pipelineSteps: {
      type: Array,
      default: () => [],
    }
  },

  computed: {
    // Veränderte Computed Property
    pipelineStages() {
      return this.pipelineSteps.map(step => ({
        id: step.id,
        name: step.name,
        order: step.orderNumber,
        deals: this.deals.filter(deal =>
          // Füge Debug-Logging hinzu
          deal.pipelineStepId === step.id
        ).sort((a, b) => new Date(b.updatedAt || b.closeDate || 0) - new Date(a.updatedAt || a.closeDate || 0)),
      }))
    },
  },

  methods: {
    calculateStageValue(deals) {
      return deals.reduce((sum, deal) => sum + (deal.value || 0), 0)
    },

    formatMoney(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value)
    },
  },

  // Füge mounted Hook für Debug-Zwecke hinzu
  mounted() {
    console.log('Pipeline Steps:', this.pipelineSteps)
    console.log('Deals:', this.deals)
    console.log('Pipeline Stages:', this.pipelineStages)
  }
}
</script>

<style scoped>
.deal-pipeline {
  height: 100%;
  overflow: hidden;
}

.pipeline-stages {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  height: 100%;
  overflow-x: auto;
  padding-bottom: 1rem;
}

.pipeline-stage {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  background: var(--background-color);
  border-radius: 0.5rem;
  padding: 1rem;
}

.stage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.stage-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.stage-count {
  background: var(--primary-color-light);
  color: var(--primary-color);
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.stage-value {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.deals-container {
  flex: 1;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.deals-container::-webkit-scrollbar {
  width: 4px;
}

.deals-container::-webkit-scrollbar-track {
  background: var(--background-color);
}

.deals-container::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 2px;
}

.empty-stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 2rem;
  color: var(--text-secondary);
  text-align: center;
  border: 2px dashed var(--border-color);
  border-radius: 0.5rem;
}

.empty-stage p {
  font-size: 0.875rem;
  margin: 0;
}

@media (max-width: 1400px) {
  .pipeline-stages {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .pipeline-stages {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .pipeline-stages {
    grid-template-columns: 1fr;
  }

  .pipeline-stage {
    min-width: 100%;
  }
}
</style>
