<template>
  <div class="c-tags">
    <CBadge v-for="tag in tags" :key="tag" color="info" class="mr-1 mb-1">
      #{{ tag }}
    </CBadge>
  </div>
</template>

<script>
export default {
  name: "CTags",
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.c-tags {
  display: flex;
  flex-wrap: wrap;
}
</style>
