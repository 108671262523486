<template>
  <div>
    <CRow>
      <CCol sm="12">
        <UnlayerHtmlEditor
          ref="unlayerEditor"
          label="E-Mail: Inhalt"
          :value="message"
          displayMode="web"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" class="mt-3 pb-4">
        <CButton color="success" class="mr-2" @click="saveDesign = false"
          >Save Design</CButton
        >
        <CButton color="success" @click="exportHtml">Export HTML</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UnlayerHtmlEditor from "@/components/UnlayerEditorWrapper";

export default {
  name: "PageEditor",
  components: {
    UnlayerHtmlEditor,
  },
  data() {
    return {
      message: "",
    };
  },
  mounted() {},
  methods: {
    // called when the editor is created
    editorLoaded() {
      console.log("editorLoaded");
    },
    // called when the editor has finished loading
    editorReady() {
      console.log("editorReady");
    },

    saveDesign() {
      this.$refs.unlayerEditor.editor.saveDesign((design) => {
        console.log("saveDesign", design);
      });
    },
    exportHtml() {
      this.$refs.unlayerEditor.editor.exportHtml((data) => {
        console.log("exportHtml", data);
      });
    },
  },
};
</script>

<style></style>
