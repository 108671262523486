<template>
  <div class="email-analytics">
    <!-- Sync Control Component -->
    <SyncControl v-if="isOrganizationAdmin" />
    <!-- Modern Filter Section -->
    <CCard class="filter-card mb-4">
      <CCardBody class="p-4">
        <CRow class="align-items-center">
          <CCol sm="12" md="4" class="mb-3 mb-md-0">
            <CSelectWrapper
              v-model="timeFilter"
              :options="timeFilterOptions"
              label="Zeitraum"
              class="modern-select"
              @change="updateData"
            />
          </CCol>
          <CCol sm="12" md="4" class="mb-3 mb-md-0">
            <CSelectWrapper
              v-model="selectedCampaign"
              :options="campaignOptions"
              label="Kampagne"
              class="modern-select"
              @change="updateData"
            />
          </CCol>
<!--          <CCol sm="12" md="4">-->
<!--            <div class="search-container">-->
<!--              <CInput-->
<!--                v-model="emailSubject"-->
<!--                placeholder="E-Mail Betreff"-->
<!--                class="modern-input"-->
<!--                @input="debounceSearch"-->
<!--              >-->
<!--                <template #prepend-content>-->
<!--                  <CIcon :content="$icons.cilSearch" />-->
<!--                </template>-->
<!--              </CInput>-->
<!--            </div>-->
<!--          </CCol>-->
        </CRow>

        <!-- Modern Active Filters -->
        <CRow v-if="hasActiveFilters" class="mt-4">
          <CCol>
            <div class="active-filters">
              <CBadge
                v-for="filter in activeFilters"
                :key="filter.key"
                color="primary"
                class="modern-badge mr-2"
                shape="pill"
              >
                {{ filter.label }}
                <CIcon
                  :content="$icons.cilX"
                  class="ml-2 cursor-pointer"
                  @click="removeFilter(filter.key)"
                />
              </CBadge>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <!-- Modern Statistics Cards -->
    <CRow class="mb-4">
      <CCol sm="6" lg="3">
        <CCard class="modern-stat-card">
          <CCardBody class="d-flex align-items-center">
            <div class="stat-icon bg-primary-light">
              <CIcon :content="$icons.cilSend" size="xl" class="text-primary" />
            </div>
            <div class="stat-content ml-3">
              <h6 class="stat-label mb-1">Gesendet</h6>
              <div class="stat-value">{{ statistics.sent || 0 }}</div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol sm="6" lg="3">
        <CCard class="modern-stat-card">
          <CCardBody class="d-flex align-items-center">
            <div class="stat-icon bg-success-light">
              <CIcon :content="$icons.cilEnvelopeOpen" size="xl" class="text-success" />
            </div>
            <div class="stat-content ml-3">
              <h6 class="stat-label mb-1">Geöffnet</h6>
              <div class="stat-value">{{ statistics.opened || 0 }}</div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol sm="6" lg="3">
        <CCard class="modern-stat-card">
          <CCardBody class="d-flex align-items-center">
            <div class="stat-icon bg-warning-light">
              <CIcon :content="$icons.cilCursor" size="xl" class="text-warning" />
            </div>
            <div class="stat-content ml-3">
              <h6 class="stat-label mb-1">Geklickt</h6>
              <div class="stat-value">{{ statistics.clicked || 0 }}</div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol sm="6" lg="3">
        <CCard class="modern-stat-card">
          <CCardBody class="d-flex align-items-center">
            <div class="stat-icon bg-danger-light">
              <CIcon :content="$icons.cilWarning" size="xl" class="text-danger" />
            </div>
            <div class="stat-content ml-3">
              <h6 class="stat-label mb-1">Fehler</h6>
              <div class="stat-value">{{ statistics.failed || 0 }}</div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modern Charts Section -->
    <CRow class="mb-4">
      <CCol lg="8">
        <CCard class="modern-chart-card h-100">
          <CCardHeader class="border-0 bg-transparent">
            <h5 class="mb-0">Trend Analyse</h5>
          </CCardHeader>
          <CCardBody>
            <trend-chart
              :chartData="trendData"
              :loading="loading"
              class="modern-chart"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol lg="4">
        <CCard class="modern-chart-card h-100">
          <CCardHeader class="border-0 bg-transparent">
            <h5 class="mb-0">Geräteverteilung</h5>
          </CCardHeader>
          <CCardBody>
            <device-chart
              :chartData="deviceData"
              :loading="loading"
              class="modern-chart"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modern Data Table -->
    <CCard class="modern-table-card">
      <CCardHeader class="border-0 bg-transparent d-flex justify-content-between align-items-center">
        <h5 class="mb-0">Detaillierte Übersicht</h5>
        <CButton
          color="primary"
          variant="ghost"
          size="sm"
          @click="exportCSV"
        >
          <CIcon :content="$icons.cilCloudDownload" class="mr-2" />
          Export
        </CButton>
      </CCardHeader>
      <CCardBody>
        <email-data-table
          :items="tableData"
          :loading="loading"
          class="modern-table"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "lodash/debounce";
import TrendChart from "@/components/TrendChart.vue";
import DeviceChart from "@/components/DeviceChart.vue";
  import EmailDataTable from "@/components/EmailDataTable.vue";
import CSelectWrapper from "../../components/CSelectWrapper.vue";
import * as $icons from "@coreui/icons";
import SyncControl from './SyncControl.vue';

export default {
  name: "EmailAnalytics",
  components: {
    CSelectWrapper,
    TrendChart,
    DeviceChart,
    EmailDataTable,
    SyncControl,
  },

  data() {
    return {
      loading: false,
      timeFilter: "last7days",
      selectedCampaign: "",
      emailSubject: "",
      statistics: {
        sent: 0,
        opened: 0,
        clicked: 0,
        failed: 0,
      },
      trendData: [],
      deviceData: [],
      tableData: [],
      timeFilterOptions: [
        { value: "last24h", label: "Letzte 24 Stunden" },
        { value: "last7days", label: "Letzte 7 Tage" },
        { value: "last30days", label: "Letzte 30 Tage" },
        { value: "last3months", label: "Letzte 3 Monate" },
      ],
      campaignOptions: [],
    };
  },

  computed: {
    isOrganizationAdmin() {
      return this.$commons.hasRole("ORGANIZATION_ADMIN");
    },
    $icons() {
      return $icons;
    },
    hasActiveFilters() {
      return this.timeFilter || this.selectedCampaign || this.emailSubject;
    },
    activeFilters() {
      const filters = [];
      if (this.timeFilter) {
        filters.push({
          key: "time",
          label: `Zeitraum: ${this.getTimeFilterLabel()}`,
        });
      }
      if (this.selectedCampaign) {
        filters.push({
          key: "campaign",
          label: `Kampagne: ${this.getCampaignName()}`,
        });
      }
      if (this.emailSubject) {
        filters.push({
          key: "subject",
          label: `Betreff: ${this.emailSubject}`,
        });
      }
      return filters;
    },
  },

  created() {
    this.debounceSearch = debounce(this.updateData, 500);
    this.loadInitialData();
  },


  methods: {
    async exportCSV() {
      try {
        const params = this.getQueryParams();
        const response = await axios.get("/mailgun/analytics/details", {
          params,
          responseType: "blob"
        });

        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;"
        });

        // Erstelle einen Dateinamen mit Zeitstempel
        const fileName = `email_analytics_${new Date().toISOString().split('T')[0]}.csv`;

        // Erstelle einen Download-Link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // Trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (error) {
        this.handleError(error);
      }
    },
    getCampaignName() {
      const campaign = this.campaignOptions.find((c) => c.value === this.selectedCampaign);
      return campaign ? campaign.label : "Unbekannt";
    },
    async loadInitialData() {
      try {
        this.loading = true;
        await Promise.all([this.fetchCampaigns(), this.updateData()]);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchCampaigns() {
      try {
        const response = await axios.get("/campaigns");
        this.campaignOptions = [
          { value: "all", label: "Alle E-Mails" }, // Neue eindeutige Option
          ...response.data
            .filter(campaign => campaign.name !== "Alle Abonnenten") // Filtere "Alle Abonnenten" aus
            .map((campaign) => ({
              value: campaign.id,
              label: campaign.name || `Kampagne ${campaign.id.substring(0, 8)}...`
            }))
        ].filter(option => option.value);
      } catch (error) {
        this.handleError(error);
      }
    },

    async updateData() {
      try {
        this.loading = true;
        const params = this.getQueryParams();

        // Wenn "Alle E-Mails" gewählt ist (selectedCampaign === 'all'),
        // dann keine campaign_id im Filter
        const [events, trends, devices, details] = await Promise.all([
          axios.get("/mailgun/analytics/events", {
            params: {
              timeFilter: this.timeFilter || 'last24h',
              // Nur campaignId senden wenn nicht 'all' gewählt
              ...(this.selectedCampaign && this.selectedCampaign !== 'all'
                ? { campaignId: this.selectedCampaign }
                : {}),
              subject: this.emailSubject ? this.emailSubject.toLowerCase().trim() : undefined
            }
          }),
          axios.get("/mailgun/analytics/trends"),
          axios.get("/mailgun/analytics/devices"),
          axios.get("/mailgun/analytics/details")
        ]);

        // Statistiken aus den Events
        this.statistics = {
          sent: events.data.filter(event => event.eventType === "SENT").length,
          opened: events.data.filter(event => event.eventType === "OPENED").length,
          clicked: events.data.filter(event => event.eventType === "CLICKED").length,
          failed: events.data.filter(event => event.eventType === "FAILED").length
        };

        // Trend-Daten
        this.trendData = trends.data;

        // Device-Daten
        this.deviceData = Object.entries(devices.data).map(([key, value]) => ({
          label: key,
          value: value
        }));

        // Filter die Details basierend auf dem Betreff und der ausgewählten Kampagne
        const filteredDetails = details.data.filter(item => {
          // Betreff-Filter
          if (this.emailSubject && item.details) {
            if (!item.details.toLowerCase().includes(this.emailSubject.toLowerCase())) {
              return false;
            }
          }

          // Kampagnen-Filter
          if (this.selectedCampaign && this.selectedCampaign !== 'all') {
            if (item.campaignId !== this.selectedCampaign) {
              return false;
            }
          }

          return true;
        });

        // Tabellen-Daten aus den gefilterten Details
        this.tableData = filteredDetails.map((item) => ({
          date: item.timestamp
            ? new Date(item.timestamp).toLocaleString("de-DE", { timeZone: "Europe/Berlin" })
            : "Datum nicht verfügbar",
          eventType: item.eventType || "Unbekannt",
          details: item.details || "Keine Details verfügbar",
          device: item.device || "Unbekannt",
          browser: item.browser || "Unbekannt",
          os: item.os || "Unbekannt",
          country: item.country || "Land nicht verfügbar",
          city: item.city || "Stadt nicht verfügbar",
          language: item.language || "Sprache nicht verfügbar",
          ip: item.ip || "IP nicht verfügbar",
          utm: item.utm || "UTM-Parameter nicht verfügbar",
          leadId: item.leadId || "Nicht zugeordnet"
        }));

      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    getQueryParams() {
      const params = {
        timeFilter: this.timeFilter || 'last24h'
      };

      if (this.selectedCampaign && this.selectedCampaign !== 'all') {
        params.campaignId = this.selectedCampaign;
      }

      if (this.emailSubject && this.emailSubject.trim() !== '') {
        params.subject = this.emailSubject.trim();
      }

      return params;
    },

    removeFilter(key) {
      switch (key) {
        case "time":
          this.timeFilter = "";
          break;
        case "campaign":
          this.selectedCampaign = "";
          break;
        case "subject":
          this.emailSubject = "";
          break;
      }
      this.updateData();
    },

    getTimeFilterLabel() {
      const option = this.timeFilterOptions.find((opt) => opt.value === this.timeFilter);
      return option ? option.label : "";
    },

    handleError(error) {
      console.error("Error:", error);
      const errorMessage =
        error?.response?.data?.message || "Ein unbekannter Fehler ist aufgetreten.";
      this.$toast ? this.$toast.error(errorMessage) : alert(errorMessage);
    },

  },
};
</script>

<style>
.modern-select:focus,
.modern-input:focus {
  border-color: #6366f1;
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.1);
}

/* Modern Badge Styles */
.modern-badge {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  background-color: var(--primary-light);
  color: #6366f1;
  border: none;
}

.modern-badge .c-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.modern-badge .c-icon:hover {
  opacity: 0.7;
}

/* Modern Chart Card Styles */
.modern-chart-card {
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border-radius: 12px;
}

.modern-chart-card .card-header {
  padding: 1.25rem;
}

.modern-chart-card .card-header h5 {
  color: #111827;
  font-weight: 600;
}

.modern-chart {
  padding: 1rem;
}

/* Modern Table Styles */
.modern-table-card {
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border-radius: 12px;
}

.modern-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.modern-table th {
  background-color: #f9fafb;
  color: #6b7280;
  font-weight: 500;
  text-align: left;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.modern-table td {
  padding: 1rem;
  color: #374151;
  border-bottom: 1px solid #e5e7eb;
}

.modern-table tr:last-child td {
  border-bottom: none;
}

.modern-table tr:hover {
  background-color: #f9fafb;
}

/* Loading States */
.chart-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  z-index: 10;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .stat-value {
    font-size: 1.25rem;
  }

  .modern-badge {
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
  }

  .modern-chart-card .card-header {
    padding: 1rem;
  }

  .modern-table td,
  .modern-table th {
    padding: 0.75rem;
  }
}

/* Filter Card Specific Styles */
.filter-card {
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border-radius: 12px;
  background-color: #fff;
}

.search-container {
  position: relative;
}

.search-container .c-icon {
  color: #6b7280;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

/* Active Filters Container */
.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 1rem;
}

/* Custom ScrollBar for Tables */
.modern-table-card {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #e5e7eb transparent;
}

.modern-table-card::-webkit-scrollbar {
  width: 6px;
}

.modern-table-card::-webkit-scrollbar-track {
  background: transparent;
}

.modern-table-card::-webkit-scrollbar-thumb {
  background-color: #e5e7eb;
  border-radius: 3px;
}

/* Animation for Loading States */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.loading {
  animation: pulse 1.5s infinite ease-in-out;
}

/* Export Button Styles */
.export-button {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.export-button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

/* Toast Notification Styles */
.toast-notification {
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.toast-notification.error {
  background-color: var(--danger-light);
  color: #dc2626;
}

.toast-notification.success {
  background-color: var(--success-light);
  color: #16a34a;
}
</style>
