<template>
  <form ref="funnelForm">
    <CRow>
      <CCol sm="6">
        <CInput
            label="Name"
            v-model="name"
            description="Bitte geben Sie einen Namen für diese E-Mail ein."
            required
        />
      </CCol>
      <CCol sm="6">
        <CInput
            label="E-Mail: Betreff"
            v-model="subject"
            description="Betreff der E-Mail."
            required
        />
      </CCol>
    </CRow>
    <CRow class="mb-3">
      <CCol sm="12">
        <UnlayerEmailEditor
            v-if="messageType.toUpperCase() === 'UNLAYER_JSON'"
            ref="unlayerEditor"
            label="E-Mail: Inhalt"
            :value="message"
            displayMode="email"
        />
        <WysiwygEditor
            v-else
            label="E-Mail: Inhalt"
            required
            v-model="message"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="5">
        <DurationInput
            label="Senden nach"
            description="Zu welcher Zeit nach der Wertermittlung soll diese E-Mail versendet werden?"
            :duration-time="parseInt(sendAfterDurationTime, 10)"
        @duration:time="sendAfterDurationTime = $event"
        :duration-type="sendAfterDurationType"
        @duration:type="sendAfterDurationType = $event"
        />
      </CCol>
      <CCol sm="4">
        <!-- Kampagnenauswahl -->
        <CSelectWrapper
            label="Kampagne"
            v-model="campaignId"
            :options="campaigns"
            description="Wählen Sie die Kampagne aus, der diese automatisierte E-Mail zugeordnet wird."
            placeholder="Kampagne auswählen"
            required
        />
        <CInputCheckbox
            label="Auf bestehende Kontakte anwenden?"
            v-model="applyToExistingSubscribers"
            description="Falls aktiviert, wird dieser Funnel auch auf bestehende Abonnenten angewendet."
        />
      </CCol>
      <CCol sm="3">
        <label for="active">Versand der E-Mail aktivieren?</label>
        <CInputCheckbox
            label="Aktiv?"
            id="active"
            name="active"
            value="Aktiv"
            :custom="true"
            :checked.sync="active"
            :inline="false"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CButton v-if="!localProcessing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else color="primary"> Processing... </CButton>
        <CButton
            v-if="enableDelete"
            @click="deleteHandler"
            color="danger"
            style="margin-left: 4px"
        >
          Löschen
        </CButton>
        <CButton
            v-if="enableDelete"
            @click="sendTestEmailHandler"
            color="warning"
            style="margin-left: 4px"
        >
          Test E-Mail versenden
        </CButton>
      </CCol>
    </CRow>
  </form>
</template>

<script>
import WysiwygEditor from "@/components/WysiwygEditor";
import DurationInput from "@/components/DurationInput";
import Checkbox from "@/components/Checkbox";
import axios from "axios";
import UnlayerEmailEditor from "@/components/UnlayerEditorWrapper";
import CSelectWrapper from "../../../components/CSelectWrapper.vue";

export default {
  name: "FunnelForm",
  components: {
    CSelectWrapper,
    UnlayerEmailEditor,
    DurationInput,
    WysiwygEditor,
    Checkbox,
  },
  props: {
    passedData: {
      type: Object,
      default: () => ({ id: 0 }),
    },
    campaigns: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      required: true,
    },
    enableDelete: Boolean,
    processing: Boolean,
  },
  data() {
    return {
      localProcessing: this.processing, // Lokales Datenfeld für Verarbeitung
      id: this.passedData.id || 0,
      realmId: (this.passedData.realm || {}).id || this.passedData.realmId || 1,
      name: this.passedData.name || "",
      subject: this.passedData.subject || "",
      message: this.passedData.message || "",
      messageType: this.passedData.messageType || "UNLAYER_JSON",
      campaignId: String(this.passedData.campaignId || ""),
      active:
          typeof this.passedData.active === "boolean"
              ? this.passedData.active
              : true,
      sendAfterDurationTime: Number(this.passedData.sendAfterDurationTime) || 10,
      sendAfterDurationType: this.passedData.sendAfterDurationType || "minutes",
      applyToExistingSubscribers: false, // Feld für bestehende Kontakte
    };
  },
  mounted() {
    console.log("Initialisierte Kampagne in FunnelForm:", this.campaignId);
  },
  watch: {
    processing(newVal) {
      this.localProcessing = newVal; // Synchronisiere Änderungen
    },
    passedData: {
      immediate: true,
      deep: true, // Überwacht auch verschachtelte Objekte
      handler(newData) {
        this.id = newData.id || 0;
        this.realmId = (newData.realm || {}).id || newData.realmId || 1;
        this.name = newData.name || "";
        this.subject = newData.subject || "";
        this.message = newData.message || "";
        this.messageType = newData.messageType || "UNLAYER_JSON";
        this.campaignId = newData.campaignId || null;
        console.log("Aktualisierte Kampagnen-ID in FunnelForm:", this.campaignId);
        this.active =
            typeof newData.active === "boolean" ? newData.active : true;
        this.sendAfterDurationTime = newData.sendAfterDurationTime || 10;
        this.sendAfterDurationType = newData.sendAfterDurationType || "minutes";

        if (
            this.messageType.toUpperCase() === "UNLAYER_JSON" &&
            typeof this.message === "string"
        ) {
          try {
            this.message = JSON.parse(this.message);
          } catch (error) {
            console.error("Fehler beim Parsen der Nachricht:", error);
          }
        }
      },
    },
  },
  methods: {
    validateBeforeSubmit() {
      return new Promise((resolve, reject) => {
        if (this.$refs.funnelForm.checkValidity()) {
          if (this.messageType.toUpperCase() === "UNLAYER_JSON") {
            this.$refs.unlayerEditor
                .saveDesign()
                .then((design) => {
                  resolve({
                    ...this.$data,
                    message: JSON.stringify(design),
                    sendAfterDurationType: this.sendAfterDurationType.toUpperCase(),
                    applyToExistingSubscribers: !!this.applyToExistingSubscribers,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
          } else {
            resolve({
              ...this.$data,
              sendAfterDurationType: this.sendAfterDurationType.toUpperCase(),
              applyToExistingSubscribers: !!this.applyToExistingSubscribers,
            });
          }
        } else {
          this.$refs.funnelForm.reportValidity();
          reject();
        }
      });
    },
    async clickHandler() {
      this.localProcessing = true;
      try {
        const data = await this.validateBeforeSubmit();
        if (this.applyToExistingSubscribers) {
          const response = await axios.post(
              `/funnel/rule/affectedContacts`,
              data
          );
          const affectedContacts = response.data.affectedContacts;

          const contactText =
              affectedContacts === 1
                  ? `1 bestehenden Kontakt`
                  : `${affectedContacts} bestehende Kontakte`;

          this.$modal.showModal(
              "default",
              `Dieser Funnel wird auf ${contactText} angewendet. Möchtest du fortfahren?`,
              null,
              async () => {
                await this.$emit("submit", data);
                this.localProcessing = false;
              },
              () => {
                this.localProcessing = false;
              }
          );
        } else {
          await this.$emit("submit", data);
        }
      } catch (error) {
        console.error("Fehler:", error);
        this.$toaster.makeToast(
            "Fehler!",
            "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
        );
      } finally {
        this.localProcessing = false;
      }
    },
    deleteHandler() {
      this.$modal.showModal(
          "delete",
          null,
          [`<b>FunnelRule</b> (${this.passedData.name})`],
          async () => {
            try {
              await axios.delete(`/funnel/rule/${this.id}`);
              this.$toaster.makeToast(
                  "Erfolgreich!",
                  "Die Regel wurde erfolgreich gelöscht."
              );
              await this.$router.push({ name: "Funnel" });
            } catch (e) {
              this.$toaster.makeToast(
                  "Fehler!",
                  "Die Regel konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal."
              );
            }
          }
      );
    },
    async sendTestEmailHandler() {
      const data = await this.validateBeforeSubmit();
      this.$modal.showModal(
          "default",
          "Dieses Funnel testen?",
          [
            `Hiermit wird zum Test dieser Funnel an die E-Mail des aktuellen Users (${commons.getCurrentUser().email}) gesendet.`,
            `<b>Achtung:</b> Dieser Funnel wird vorher gespeichert!`,
          ],
          () => {
            this.$emit("sendTestEmail", data);
          },
          () => {}
      );
    },
  },
};
</script>