<template>
  <CModal
    :title="title"
    :show.sync="show"
    centered
    :closeOnBackdrop="false"
    style="text-align: left"
  >
    <template v-slot:header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>
    <div style="text-align: center; width: 100%">
      <CImg src="img/loading.svg" style="width: 30%" />
      <h5 style="margin-top: 18px" v-html="content" />
    </div>
    <template v-slot:footer>
      <div />
    </template>
  </CModal>
</template>

<script>
export default {
  name: "LoadingModal",
  data() {
    return {
      show: false,
      title: null,
      entries: [],
      content: "",
      onCancel: null,
      onConfirm: null,
      options: {},
    };
  },
  mounted() {
    this.resetModal();
  },
  methods: {
    hideModal() {
      this.show = false;
    },
    resetModal() {
      this.title = "Augenblick...";
      this.content =
        "Wir verarbeiten momentan Ihre Anfrage.<br><b>Bitte warten...</b>";
      this.onCancel = () => {
        this.hideModal();
      };
      this.onConfirm = () => {
        this.hideModal();
      };
    },
    cancelEvent() {
      this.onCancel();
      this.hideModal();
    },
    confirmEvent() {
      this.onConfirm();
      this.hideModal();
    },
  },
  watch: {
    show(newVal) {
      if (newVal === false) {
        setTimeout(this.resetModal, 300);
      }
    },
  },
};
</script>

<style scoped></style>
