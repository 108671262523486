<!--suppress SpellCheckingInspection -->
<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-file" />&nbsp;
                  <strong>Konfiguration der Wohnmarktanalyse</strong>
                </div>
                <div class="header-button" style="display: flex">
                  <DoubleBounceLoader
                    v-if="loading || saving"
                    width="20px"
                    height="20px"
                    style="margin-right: 8px; display: inline-block"
                  />
                  <CButton
                    style="margin-right: 6px"
                    :href="`${pdfgenBaseUrl}/generateSamplePdf?license=${$commons.getFromStorage('license')}&timestamp=${Date.now()}`"
                    target="_blank"
                    type="button"
                    name="test_pdf"
                    size="sm"
                    key="test_pdf"
                    color="info"
                  >
                    <CIcon size="sm" name="cil-reload" />
                    <span> Standart PDF-Vorschau (mit Analysen) langsam</span>
                  </CButton>
                  <CButton
                    :href="`${pdfgenBaseUrl}/generateSamplePdf?license=${$commons.getFromStorage('license')}&fast=1&timestamp=${Date.now()}`"
                    target="_blank"
                    type="button"
                    name="test_pdf_fast"
                    size="sm"
                    key="test_pdf_fast"
                    color="info"
                  >
                    <CIcon size="sm" name="cil-reload" />
                    <span> Express PDF-Vorschau (ohne Analysen) schnell</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CTabs>
              <CTab active>
                <template slot="title"> Titel & Inhalte </template>
                <br />
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                      type="text"
                      label="Anschreiben: Anrede"
                      v-model="pdfSettings.pages.letterGreeting"
                      :description="'Hier können Sie folgende Variablen nutzen: {{ NAME }}, {{ VORNAME }}, {{ NACHNAME }}, {{ ANREDE }}, {{ GEEHRT }}'"
                      horizontal
                      required
                    />
                    <WysiwygEditor
                      label="Anschreiben: Inhalt"
                      v-model="pdfSettings.pages.letterText"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Seite 1: Titel"
                      v-model="pdfSettings.pages.p1Titel"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 2: Titel"
                      v-model="pdfSettings.pages.p2Titel"
                      horizontal
                      required
                    />
                    <WysiwygEditor
                      label="Seite 2: Inhalt"
                      v-model="pdfSettings.pages.p2Content"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Seite 3: Titel"
                      v-model="pdfSettings.pages.p3Titel"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 3: Titel 2"
                      v-model="pdfSettings.pages.p3Titel2"
                      horizontal
                      required
                    />
                    <CTextarea
                      label="Seite 3: Inhalt"
                      v-model="pdfSettings.pages.p3Content"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Seite 3: Preisentwicklung"
                      v-model="pdfSettings.pages.p3Preisentwicklung"
                      horizontal
                    />
                    <CTextarea
                      label="Seite 4: Titel"
                      v-model="pdfSettings.pages.p4Titel"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 4: Bereich 2: Titel"
                      v-model="pdfSettings.pages.p4S2Titel"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 4: Bereich 3: Titel"
                      v-model="pdfSettings.pages.p4S3Titel"
                      horizontal
                    />
                    <CTextarea
                      label="Seite 4: Bereich 3: Inhalt"
                      v-model="pdfSettings.pages.p4S3Content"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 4: Bereich 4: Titel"
                      v-model="pdfSettings.pages.p4S4Titel"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 4: Bereich 5: Titel"
                      v-model="pdfSettings.pages.p4S5Titel"
                      horizontal
                      required
                    />
                    <CTextarea
                      label="Seite 4: Bereich 5: Inhalt"
                      v-model="pdfSettings.pages.p4S5Content"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 4: Daten	"
                      v-model="pdfSettings.pages.p4Data"
                      horizontal
                      required
                    />
                    <CInput
                      type="text"
                      label="Seite 6: Titel"
                      v-model="pdfSettings.pages.p6Titel"
                      horizontal
                      required
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title"> Stammdaten </template>
                <br />
                <CRow>
                  <CCol xs="12" xl="10">
                    <!--                    <CInput type="text" label="Firma" v-model="pdfSettings.information.company" horizontal />-->
                    <!--                    <CInput type="text" label="Vollständige Anschrift" v-model="pdfSettings.information.street" horizontal />-->
                    <CInput
                      type="text"
                      label="Absender im Anschreiben (falls abweichend)"
                      v-model="pdfSettings.information.letterFrom"
                      horizontal
                    />
                    <!--                    <CInput type="text" label="Telefon" v-model="pdfSettings.information.telefon" horizontal />-->
                    <!--                    <CInput type="text" label="Fax" v-model="pdfSettings.information.fax" horizontal />-->
                    <!--                    <CInput type="text" label="Webseite" v-model="pdfSettings.information.website" horizontal />-->
                    <!--                    <CInput type="text" label="E-Mail" v-model="pdfSettings.information.email" horizontal />-->
                    <!--                    <CInput type="text" label="Kontaktperson" v-model="pdfSettings.information.owner" horizontal />-->
                    <!--                    <CInput type="text" label="Kontaktperson Position" v-model="pdfSettings.information.ownerSub" horizontal />-->
                    <CInput
                      type="text"
                      label="Öffnungszeiten: Überschrift"
                      v-model="pdfSettings.information.openingHours"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Öffnungszeiten: Tage"
                      v-model="pdfSettings.information.openingHoursDays"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Öffnungszeiten: Zeile 1"
                      v-model="pdfSettings.information.openingHoursL1"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Öffnungszeiten: Zeile 2"
                      v-model="pdfSettings.information.openingHoursL2"
                      horizontal
                    />
                    <WysiwygEditor
                      label="Haftungsausschluss"
                      v-model="pdfSettings.information.privacy"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Kontaktseite (Fußzeile): Zeile 1"
                      :description="'Folgende Platzhalter können Sie hier optional verwenden: {{ADDRESS}}, {{PHONE}}, {{FAX}}, {{WEBSITE}}, {{EMAIL}}'"
                      v-model="pdfSettings.information.contactUsL1"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Kontaktseite (Fußzeile): Zeile 2"
                      :description="'Folgende Platzhalter können Sie hier optional verwenden: {{ADDRESS}}, {{PHONE}}, {{FAX}}, {{WEBSITE}}, {{EMAIL}}'"
                      v-model="pdfSettings.information.contactUsL2"
                      horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title"> Bilder </template>
                <br />
                <CRow>
                  <CCol xs="12" xl="10">
                    <ImageUploadField
                      setting-key="pdf_media_logo"
                      v-model="pdfSettings.media.logo"
                      label="Logo"
                      @delete="deleteImageFromPdfGen('logo')"
                    />
                    <ImageUploadField
                      setting-key="pdf_media_page1main"
                      v-model="pdfSettings.media.page1main"
                      label="Seite 1: Hauptbild"
                      @delete="deleteImageFromPdfGen('page1_main')"
                    />
                    <ImageUploadField
                      setting-key="pdf_media_page2main"
                      v-model="pdfSettings.media.page2main"
                      label="Seite 2: Hauptbild"
                      @delete="deleteImageFromPdfGen('page2_main')"
                    />
                    <ImageUploadField
                      setting-key="pdf_media_profile"
                      v-model="pdfSettings.media.profile"
                      label="Bild der Kontaktperson"
                      @delete="deleteImageFromPdfGen('profile')"
                    />
                    <ImageUploadField
                      setting-key="pdf_media_signature"
                      v-model="pdfSettings.media.signature"
                      label="Unterschrift der Kontaktperson"
                      @delete="deleteImageFromPdfGen('signature')"
                    />
                    <ImageUploadField
                      setting-key="pdf_media_page6contactMap"
                      v-model="pdfSettings.media.page6contactMap"
                      label="Karte des Standorts"
                      @delete="deleteImageFromPdfGen('page6_contact-map')"
                    />
                    <ImageUploadField
                      setting-key="pdf_media_footer"
                      v-model="pdfSettings.media.footer"
                      label="Fußzeile (optional)"
                      @delete="deleteImageFromPdfGen('footer')"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title"> Farben </template>
                <br />
                <CRow>
                  <CCol xs="12" xl="10">
                    <ColorPicker
                      label="Primär"
                      v-model="pdfSettings.colors.primary"
                    />
                    <ColorPicker
                      label="Sekundär"
                      v-model="pdfSettings.colors.secondary"
                    />
                    <ColorPicker
                      label="Text Farbe"
                      v-model="pdfSettings.colors.text"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title"> Aktive Seiten </template>
                <br />
                <CRow>
                  <CCol xs="12" xl="10">
                    <div class="settings-section">
                      <h6 class="section-title">Aktive Seiten</h6>
                      <div class="active-pages-grid">
                        <CCard
                            v-for="option in pageOptions"
                            :key="option.value"
                            :class="['active-page-card', {
              'active': pdfSettings.activePages.includes(option.value)
            }]"
                            @click="toggleActivePage(option.value)"
                        >
                          <div class="d-flex align-items-center justify-content-start p-3">
                            <CIcon :name="option.icon" class="mr-2" />
                            <span>{{ option.label }}</span>
                          </div>
                        </CCard>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CTab>
              <CTab>
                <template slot="title"> Zusätzliche Seiten </template>
                <br />
                <CDataTable
                  :items="pdfSettings.additionalPages"
                  :fields="additionalFields"
                  hover
                  striped
                  bordered
                  fixed
                  pagination
                  :loading="false"
                >
                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        style="margin-right: 4px"
                        @click="openEditAddititonalPage(item, index)"
                      >
                        <CIcon name="cil-pencil" />
                      </CButton>
                      <CButton
                        color="danger"
                        variant="outline"
                        square
                        size="sm"
                        @click="pdfSettings.additionalPages.splice(index, 1)"
                      >
                        <CIcon name="cil-trash" />
                      </CButton>
                    </td>
                  </template>
                  <template #append="{ item, index }">
                    <td class="py-2">
                      {{ item.append === "before" ? "Vor" : "Nach" }}
                    </td>
                  </template>
                  <template #fullpage="{ item, index }">
                    <td class="py-2">{{ item.fullpage ? "Ja" : "Nein" }}</td>
                  </template>
                </CDataTable>
                <AdditionalPagesCard @click="addAdditionalPage" />
              </CTab>
              <CTab>
                <template slot="title"> Zusätzliche Ansprechpartner </template>
                <br />
                <CRow>
                  <CCol xs="12" xl="10">
                    <CInput
                      type="text"
                      label="Ansprechpartner (Wohnung)"
                      v-model="pdfSettings.contactPersons.wohnung.name"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Titel (Wohnung)"
                      v-model="pdfSettings.contactPersons.wohnung.title"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Email (Wohnung)"
                      v-model="pdfSettings.contactPersons.wohnung.email"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Telefon (Wohnung)"
                      v-model="pdfSettings.contactPersons.wohnung.telefon"
                      horizontal
                    />
                    <ImageUploadField
                      label="Bild des Ansprechpartners (Wohnung)"
                      setting-key="pdf_contactPersons_wohnung_profile"
                      v-model="pdfSettings.contactPersons.wohnung.profile"
                      @delete="deleteImageFromPdfGen('wohnung_profile')"
                    />
                    <ImageUploadField
                      label="Unterschrift (Wohnung)"
                      setting-key="pdf_contactPersons_wohnung_signature"
                      v-model="pdfSettings.contactPersons.wohnung.signature"
                      @delete="deleteImageFromPdfGen('wohnung_signature')"
                    />
                    <hr />
                    <CInput
                      type="text"
                      label="Ansprechpartner (Grundstück)"
                      v-model="pdfSettings.contactPersons.grundstuck.name"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Titel (Grundstück)"
                      v-model="pdfSettings.contactPersons.grundstuck.title"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Email (Grundstück)"
                      v-model="pdfSettings.contactPersons.grundstuck.email"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Telefon (Grundstück)"
                      v-model="pdfSettings.contactPersons.grundstuck.telefon"
                      horizontal
                    />
                    <ImageUploadField
                      label="Bild des Ansprechpartners (Grundstück)"
                      setting-key="pdf_contactPersons_grundstuck_profile"
                      v-model="pdfSettings.contactPersons.grundstuck.profile"
                      @delete="deleteImageFromPdfGen('grundstuck_profile')"
                    />
                    <ImageUploadField
                      label="Unterschrift (Grundstück)"
                      setting-key="pdf_contactPersons_grundstuck_signature"
                      v-model="pdfSettings.contactPersons.grundstuck.signature"
                      @delete="deleteImageFromPdfGen('grundstuck_signature')"
                    />
                    <hr />
                    <CInput
                      type="text"
                      label="Ansprechpartner (Haus)"
                      v-model="pdfSettings.contactPersons.haus.name"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Titel (Haus)"
                      v-model="pdfSettings.contactPersons.haus.title"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Email (Haus)"
                      v-model="pdfSettings.contactPersons.haus.email"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Telefon (Haus)"
                      v-model="pdfSettings.contactPersons.haus.telefon"
                      horizontal
                    />
                    <ImageUploadField
                      label="Bild des Ansprechpartners (Haus)"
                      setting-key="pdf_contactPersons_haus_profile"
                      v-model="pdfSettings.contactPersons.haus.profile"
                      @delete="deleteImageFromPdfGen('haus_profile')"
                    />
                    <ImageUploadField
                      label="Unterschrift (Haus)"
                      setting-key="pdf_contactPersons_haus_signature"
                      v-model="pdfSettings.contactPersons.haus.signature"
                      @delete="deleteImageFromPdfGen('haus_signature')"
                    />
                    <hr />
                    <CInput
                      type="text"
                      label="Ansprechpartner (Miete)"
                      v-model="pdfSettings.contactPersons.miete.name"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Titel (Miete)"
                      v-model="pdfSettings.contactPersons.miete.title"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Email (Miete)"
                      v-model="pdfSettings.contactPersons.miete.email"
                      horizontal
                    />
                    <CInput
                      type="text"
                      label="Telefon (Miete)"
                      v-model="pdfSettings.contactPersons.miete.telefon"
                      horizontal
                    />
                    <ImageUploadField
                      label="Bild des Ansprechpartners (Miete)"
                      setting-key="pdf_contactPersons_miete_profile"
                      v-model="pdfSettings.contactPersons.miete.profile"
                      @delete="deleteImageFromPdfGen('miete_profile')"
                    />
                    <ImageUploadField
                      label="Unterschrift (Miete)"
                      setting-key="pdf_contactPersons_miete_signature"
                      v-model="pdfSettings.contactPersons.miete.signature"
                      @delete="deleteImageFromPdfGen('miete_signature')"
                    />
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
            <CButton @click="submitSettings" color="success">Speichern</CButton>
            <CButton
              v-if="$commons.isOrganizationAdmin()"
              @click="submitSettings(true)"
              style="margin-left: 6px"
              v-c-tooltip="
                'Legen Sie die aktuellen Einstellungen als Standard für alle zukünftigen Standorte fest'
              "
              color="warning"
            >
              Als Standard definieren & Speichern
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Editieren der Zusatzseite"
      :show.sync="showEditAddititonalPage"
      size="lg"
    >
      <AdditionalPagesCard
        :additional-page="editAddititonalPage"
        @click="
          $set(pdfSettings.additionalPages, editAddititonalPageId, $event);
          showEditAddititonalPage = false;
        "
      />
      <template v-slot:footer>
        <div />
      </template>
    </CModal>
  </div>
</template>

<script>
import DoubleBounceLoader from "@/components/loaders/DoubleBounceLoader";
import WysiwygEditor from "@/components/WysiwygEditor";
import Checkbox from "@/components/Checkbox";
import RadioButton from "@/components/RadioButton";
import commons from "@/commons";
import axios from "axios";
import _ from "lodash";
import ColorPicker from "@/components/ColorPicker";
import ImageUploadField from "@/components/ImageUploadField";
import AdditionalPagesCard from "@/views/base/AdditionalPagesCard";

export default {
  name: "PdfSettings",
  components: {
    AdditionalPagesCard,
    ImageUploadField,
    ColorPicker,
    RadioButton,
    DoubleBounceLoader,
    WysiwygEditor,
    Checkbox,
  },
  data() {
    return {
      loading: false,
      saving: false,
      pdfgenBaseUrl: process.env.VUE_APP_PDFGEN_BASE_URL,
      showEditAddititonalPage: false,
      editAddititonalPage: {},
      editAddititonalPageId: null,
      pdfSettings: {
        activePages: [],
        pages: {
          p1Titel: "",
          p2Titel: "",
          p2Content: "",
          p3Titel: "",
          p3Titel2: "",
          p3Content: "",
          p3Preisentwicklung: "",
          p4Titel: "",
          p4S2Titel: "",
          p4S3Titel: "",
          p4S3Content: "",
          p4S4Titel: "",
          p4S5Titel: "",
          p4S5Content: "",
          p4Data: "",
          p6Titel: "",
          letterText: "",
          letterGreeting: "Sehr {{ geehrt }} {{ anrede }} {{ name }},",
        },
        information: {
          company: "",
          street: "",
          telefon: "",
          fax: "",
          website: "",
          email: "",
          owner: "",
          ownerSub: "",
          openingHours: "",
          openingHoursDays: "",
          openingHoursL1: "",
          openingHoursL2: "",
          privacy: "",
          contactUsL1:
            "{{ ADDRESS }}    |    Tel. {{ PHONE }}    |    Fax {{ FAX }}",
          contactUsL2: "{{ WEBSITE }}    |    {{ EMAIL }}",
          letterFrom: "",
        },
        media: {
          logo: "",
          page1main: "",
          page2main: "",
          signature: "",
          page6contactMap: "",
          footer: "",
          profile: "",
        },
        colors: {
          primary: "",
          secondary: "",
          text: "",
        },
        additionalPages: [],
        contactPersons: {
          wohnung: {},
          grundstuck: {},
          haus: {},
          miete: {},
        },
      },
      pageOptions: [
        { label: 'Deckblatt', value: 'cover', icon: 'cil-file' },
        { label: 'Anschreiben', value: 'letter', icon: 'cil-envelope-closed' },
        { label: 'Über uns', value: 'about_us', icon: 'cil-people' },
        { label: 'Wohnmarktanalyse', value: 'housing_market_analysis', icon: 'cil-chart-line' },
        { label: 'Umgebungspreise', value: 'extra_page_stats_1', icon: 'cil-money' },
        { label: 'Statistik 2', value: 'extra_page_stats_2', icon: 'cil-graph' },
        { label: 'Kurzanalyse', value: 'brief_analysis', icon: 'cil-notes' },
        { label: 'Energieeffizienz', value: 'energy_efficiency', icon: 'cil-input-power' },
        { label: 'Kontakt', value: 'contact', icon: 'cil-contact' },
      ],
      additionalFields: [
        {
          key: "show_details",
          label: "",
          _style: "width:10%",
          sorter: false,
          filter: false,
        },
        { key: "append", label: "Vor/Nach?", _style: "width:15%" },
        { key: "page", label: "Seite", _style: "width:15%" },
        { key: "title", label: "Titel" },
        // { key: 'content', label: 'Inhalt' },
        { key: "fullpage", label: "Ganze Seite?", _style: "width:15%" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    axios
      .get("/settings")
      .then((response) => {
        this.pdfSettings = _.merge(
          {},
          this.pdfSettings,
          commons.deepenOneLevelToNested(response.data, "_", "pdf_"),
        );
        if (typeof this.pdfSettings.activePages === "string")
          this.pdfSettings.activePages = JSON.parse(
            this.pdfSettings.activePages,
          );
        if (typeof this.pdfSettings.additionalPages === "string")
          this.pdfSettings.additionalPages = JSON.parse(
            this.pdfSettings.additionalPages,
          );
        if (typeof this.pdfSettings.contactPersons === "string")
          this.pdfSettings.contactPersons = JSON.parse(
            this.pdfSettings.contactPersons,
          );
        this.loading = false;
      })
      .catch((err) => console.error(err));
  },
  methods: {
    toggleActivePage(page) {
      const index = this.pdfSettings.activePages.indexOf(page);
      if (index > -1) {
        this.pdfSettings.activePages.splice(index, 1); // Entfernen, wenn bereits enthalten
      } else {
        this.pdfSettings.activePages.push(page); // Hinzufügen, wenn nicht enthalten
      }
    },
    submitSettings(sendAsDefault) {
      this.saving = true;
      let tmpSettings = _.merge({}, this.pdfSettings);
      if (!sendAsDefault) {
        delete tmpSettings.media;
      }
      let toSubmit = commons.oneLevelObject(tmpSettings, "_", "pdf_");
      const url = sendAsDefault === true ? "/settings/default" : "/settings";
      axios
        .post(url, toSubmit)
        .then((response) => {
          this.syncSettingsWithPdfGen();
        })
        .catch((err) => {
          this.$toaster.makeToast(
            "Fehler",
            "Ihre Einstellungen konnten nicht gespeichert werden! Mehr dazu in der Console.",
          );
          console.error(err);
        });
    },
    syncSettingsWithPdfGen() {
      axios
        .get("/pdf/syncSettings")
        .then((response) => {
          this.$toaster.makeToast(
            "Einstellungen erfolgreich gespeichert",
            "Ihre Einstellungen wurden erfolgreich gespeichert.",
          );
          this.saving = false;
        })
        .catch((err) => {
          this.$toaster.makeToast(
            "Fehler",
            "Ihre Einstellungen konnten nicht gespeichert werden! Mehr dazu in der Console.",
          );
          console.error(err);
        });
    },
    deleteImageFromPdfGen(key) {
      axios.delete(`/pdf/removeImage/${key}`).catch((err) => {
        this.$toaster.makeToast(
          "Fehler",
          "Bild konnte aus dem PDF-GEN Server nicht gelöscht werden. Mehr dazu in der Konsole.",
        );
        console.error(err);
      });
    },
    addAdditionalPage(values) {
      this.pdfSettings.additionalPages.push(values);
    },
    openEditAddititonalPage(item, index) {
      this.editAddititonalPageId = index;
      this.editAddititonalPage = item;
      this.showEditAddititonalPage = true;
    },
  },
};
</script>

<style lang="scss" scoped>

.active-pages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;  // Abstand zwischen den Karten
}
.active-page-card {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;  // Für eine ähnliche abgerundete Optik wie im zweiten Beispielbild
  transition: background-color 0.3s, box-shadow 0.3s;
  user-select: none;
  margin-bottom: 12px;

  &:hover {
    background-color: #f5f5f5; // Hellere Farbe bei Hover
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  &.active {
    background-color: #e3f2fd;  // Hebt aktive Elemente hervor (entspricht dem blauen Farbton im Beispielbild)
    border-color: #2196f3;
    color: #084298;
  }

  .d-flex {
    display: flex;
    align-items: center;
  }
}


.vars-to-use {
  font-style: italic;
  font-weight: 400;
  opacity: 0.85;
}

.additional-pages {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex: auto;

  > div.form-group {
    margin: 0 4px;
  }
}
</style>
