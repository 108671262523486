import _ from "lodash";

const ROLES_MAP = {
  ADMIN: {
    name: "ADMIN",
    permission: 100,
    title: "Cloud-Administrator",
    description: "Root für das gesamte System. VORSICHT!",
  },
  ORGANIZATION_ADMIN: {
    name: "ORGANIZATION_ADMIN",
    permission: 20,
    title: "Organisation Admin",
    description:
      "Administrator einer Organisation. Hat alle Rechte innerhalb einer Organisation. Hat auf allen Instanzen (unteraccounts) Rechte.",
  },
  ORGANIZATION_USER: {
    name: "ORGANIZATION_USER",
    permission: 15,
    title: "Organisation Nutzer",
    description:
      "Nutzer einer Organisation. Hat lesenden Zugriff innerhalb einer Organisation auf alle Instanzen (unteraccounts) & kann keine Einstellungen ändern.",
  },
  REALM_ADMIN: {
    name: "REALM_ADMIN",
    permission: 10,
    title: "Instanz Admin",
    description:
      "Admin einer Instanz. Hat alle Rechte innerhalb der ausgewählten Instanz.",
  },
  REALM_USER: {
    name: "REALM_USER",
    permission: 5,
    title: "Instanz Nutzer",
    description:
      "Nutzer einer Instanz. Hat lesenden Zugriff innerhalb der ausgewählten Instanz & kann keine Einstellungen ändern.",
  },
};

const ROLES = Object.values(ROLES_MAP);

const REALM_PLANS = {
  STARTUP: { name: "STARTUP", weight: 20, backgroundColor: "#920000" },
  PROFESSIONAL: {
    name: "PROFESSIONAL",
    weight: 21,
    backgroundColor: "#920000",
  },
  AGENTUR: { name: "AGENTUR", weight: 22, backgroundColor: "#1D6150" },
  KENSINGTON: { name: "KENSINGTON", weight: 21, backgroundColor: "#1D6150" },
};

const LEAD_TYPES = {
  APARTMENT: { name: "APARTMENT", title: "Wohnung" },
  HOUSE: { name: "HOUSE", title: "Haus" },
  LAND: { name: "LAND", title: "Grundstück" },
  RENT: { name: "RENT", title: "Miete" },
  BUSINESS: { name: "APARTMENT", title: "Gewerbe" },
  BASIC: { name: "BASIC", title: "Basic" },
};

// Create our number formatter.
const moneyFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const flatObject = (input) => {
  const getEntries = (o, prefix = "") =>
    Object.entries(o).flatMap(([k, v]) =>
      Object(v) === v
        ? getEntries(v, `${prefix}${k}.`)
        : [[`${prefix}${k}`, v]],
    );
  return Object.fromEntries(getEntries(input));
};

const getFromStorage = (key, def) => {
  try {
    let item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    } else {
      return typeof def === "function" ? def() : def;
    }
  } catch (error) {
    console.error(`Error getting ${key} from storage:`, error);
    return typeof def === "function" ? def() : def;
  }
};


const setToStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting ${key} to storage:`, error);
  }
};

const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

const logout = () => {
  try {
    console.debug('Logout called, current path:', window.location.pathname);
    localStorage.clear();
    // WICHTIG: Entfernen Sie den automatischen reload komplett
    removeFromStorage('jwtToken');
    removeFromStorage('jwtTokenParsed');
    removeFromStorage('currentUser');
    removeFromStorage('loggedIn');
  } catch (error) {
    console.error('Error during logout:', error);
  }
};

const isLoggedIn = () => {
  try {
    const loggedIn = getFromStorage("loggedIn", false);
    const token = getFromStorage("jwtToken", null);
    console.debug('isLoggedIn check:', {
      loggedIn,
      hasToken: !!token,
      currentPath: window.location.pathname
    });

    if (!loggedIn || !token) return false;
    return true;  // Vereinfachen Sie zunächst die Prüfung
  } catch (error) {
    console.error('Error checking login status:', error);
    return false;
  }
};

// convert multi-nested obj to dot notation strings
const oneLevelObject = (obj, delimiter = "_", prefix = "") => {
  let res = {};
  (function recurse(obj, current) {
    for (let key in obj) {
      let value = obj[key];
      let newKey = current ? current + delimiter + key : prefix + key; // joined key with dot
      if (value && !Array.isArray(value) && typeof value === "object") {
        recurse(value, newKey); // it's a nested object, so do it again
      } else {
        res[newKey] = value; // it's not an object, so set the property
      }
    }
  })(obj);
  return res;
};

// converts dot notation to multi-nested object
const deepenOneLevelToNested = (obj, delimiter = "_", prefix = "") => {
  let oo = {},
    t,
    parts,
    part;
  for (let k in obj) {
    let originalK = k;
    t = oo;
    if (prefix.length && k.startsWith(prefix)) {
      k = k.substring(prefix.length);
      parts = k.split(delimiter);
      const key = parts.pop();
      while (parts.length) {
        part = parts.shift();
        t = t[part] = t[part] || {};
      }
      t[key] = obj[originalK];
    }
  }
  return oo;
};

const toSnakeCase = (text) => {
  return text
    .replace(/\.?([A-Z]+)/g, function (x, y) {
      return "_" + y.toLowerCase();
    })
    .replace(/^_/, "");
};

const getJwtToken = () => {
  return getFromStorage('jwtTokenParsed', () => {
    try {
      const token = getFromStorage('jwtToken');
      if (!token) {
        console.debug('No JWT token found in storage');
        return null;
      }

      // Token von Quotes bereinigen
      const cleanToken = token.replace(/^["']|["']$/g, '');

      const base64Url = cleanToken.split('.')[1];
      if (!base64Url) {
        console.error('Invalid token format');
        return null;
      }

      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
          atob(base64)
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join('')
      );



      const parsed = JSON.parse(jsonPayload);
      console.debug('JWT token parsed successfully:', {
        sub: parsed.sub,
        roles: parsed.auth?.map(r => r.authority),
        exp: new Date(parsed.exp * 1000)
      });

      setToStorage('jwtTokenParsed', parsed);
      return parsed;
    } catch (error) {
      console.error('Error parsing JWT token:', error);
      removeFromStorage('jwtTokenParsed');
      return null;
    }
  });
};

const validateRoles = () => {
  try {
    const jwt = getJwtToken();
    if (!jwt || !jwt.auth || !Array.isArray(jwt.auth)) {
      console.error('Invalid token structure for roles');
      logout(); // Automatischer Logout bei ungültiger Token-Struktur
      return false;
    }

    // Validiere die Rollen-Struktur
    const validRoles = jwt.auth.every(role =>
        role &&
        typeof role === 'object' &&
        typeof role.authority === 'string' &&
        ROLES_MAP[role.authority]
    );

    if (!validRoles) {
      console.error('Invalid roles in token');
      logout();
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error validating roles:', error);
    return false;
  }
};

const isRole = (role) => {
  try {
    if (!validateRoles()) return false;

    const jwt = getJwtToken();
    if (jwt && jwt.auth) {
      let result = _.find(jwt.auth, { authority: role });
      return !!result;
    }
    return false;
  } catch (error) {
    console.error('Error checking role:', error);
    return false;
  }
};

const isSystemAdmin = () => {
  return isRole("ADMIN");
};

const isOrganizationAdmin = () => {
  return isRole("ORGANIZATION_ADMIN");
};

const isOrganizationUser = () => {
  return isRole("ORGANIZATION_USER");
};

const isRealmAdmin = () => {
  return isRole("REALM_ADMIN");
};

const isRealmUser = () => {
  return isRole("REALM_USER");
};

const isAnyAdmin = () => {
  return isSystemAdmin() || isOrganizationAdmin() || isRealmAdmin();
};

const getCurrentRealm = () => {
  const realm = getFromStorage("currentRealm");
  if (!realm) return null;
  // Füge eine Standardstruktur hinzu, falls plan null ist
  if (!realm.plan) {
    realm.plan = { name: "UNKNOWN" }; // Beispiel für Standardwert
  }
  return realm;
};


const getCurrentUser = () => {
  return getFromStorage("currentUser");
};

const getCurrentRole = () => {
  const roleNames = _.map(getJwtToken().auth, "authority");
  return _.maxBy(
    roleNames.map((rN) => {
      return ROLES_MAP[rN];
    }),
    "permission",
  );
};

const getCurrentPlan = () => {
  const realm = getCurrentRealm();

  // Überprüfung auf null oder undefined für realm
  if (!realm) {
    console.warn("Realm ist nicht vorhanden.");
    return null; // Zurückgeben von null, wenn kein Realm vorhanden ist
  }

  // Temporärer Plan für ADMINs, falls kein Plan vorhanden
  if (!realm.plan) {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.role === "ADMIN") {
      console.warn("Admin hat vorübergehenden Zugriff ohne aktiven Tarif.");
      // Temporären Plan zurückgeben
      return { name: "TEMP_ADMIN_PLAN", weight: 0 }; // Beispiel für einen temporären Plan
    } else {
      console.warn("Plan ist nicht vorhanden und der Benutzer ist kein ADMIN.");
      return null; // Kein Plan für andere Benutzer
    }
  }

  const planNameUpperCase = realm.plan.name.toUpperCase();
  const plan = REALM_PLANS[planNameUpperCase];

  if (!plan) {
    console.error("Realm plan konnte nicht aufgelöst werden:", realm.plan.name);
    return REALM_PLANS["PROFESSIONAL"] || null; // Fallback zu einem Standardplan
  }

  return plan;
};


const calculateRolesLower = (role, includeSelf) => {
  if (includeSelf) {
    return ROLES.filter((r) => r.permission <= role.permission);
  } else {
    return ROLES.filter((r) => r.permission < role.permission);
  }
};

const hasRole = isRole;

const hasRealmPlan = (realmPlanName) => {
  const realm = getCurrentRealm();
  if (typeof realm.plan === "undefined" || !realm.plan.name) {
    console.error("Realm does not have a plan");
    return false;
  }

  // Planname in Großbuchstaben konvertieren, um sicherzustellen, dass der Schlüssel korrekt übereinstimmt
  const planNameUpperCase = realm.plan.name.toUpperCase();
  const foundRealmPlan = REALM_PLANS[planNameUpperCase];

  if (typeof foundRealmPlan === "undefined") {
    throw Error(`Realm plan could not be resolved: ${realm.plan.name}`);
  }

  const requiredRealmPlan =
    typeof realmPlanName === "object"
      ? realmPlanName
      : REALM_PLANS[realmPlanName.toUpperCase()];

  if (typeof requiredRealmPlan === "undefined") {
    throw Error(
      `Passed realm plan name could not be resolved: ${realmPlanName}`,
    );
  }

  // Vergleich der Gewichte, um zu prüfen, ob der aktuelle Plan die erforderlichen Anforderungen erfüllt
  return foundRealmPlan.weight >= requiredRealmPlan.weight;
};

const hasExactRealmPlan = (realmPlanName) => {
  const realm = getCurrentRealm();

  // Prüfen, ob realm und realm.plan existieren
  if (!realm || typeof realm.plan === "undefined" || !realm.plan.name) {
    console.warn("Realm oder Plan nicht vorhanden. Standardwert wird zurückgegeben.");
    return false; // Ignoriert, wenn kein Plan vorhanden ist
  }

  return realm.plan.name.toUpperCase() === realmPlanName.toUpperCase();
};


const hasRealmPlanAndRole = (realmPlanName, role) => {
  return hasRealmPlan(realmPlanName) && hasRole(role);
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const formatDate = (d) => {
  const formatStr =
    [
      "" + d.getFullYear(),
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
    ].join("-") +
    " " +
    [("0" + d.getHours()).slice(-2), ("0" + d.getMinutes()).slice(-2)].join(
      ":",
    );

  return formatStr;
};

const formatStandardDate = (d) => {
  const formatStr =
    [
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
      "" + d.getFullYear(),
    ].join("/") +
    " " +
    [("0" + d.getHours()).slice(-2), ("0" + d.getMinutes()).slice(-2)].join(
      ":",
    );

  return formatStr;
};

const isJson = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  return typeof item === "object" && item !== null;
};

const DATE_TIME_FORMAT = "DD.MM.YYYY, HH:mm [Uhr]";

const formatMoney = (money) => moneyFormatter.format(money);

const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

const blobToCanvas = (theBlob) => {
  return new Promise((resolve, reject) => {
    const tmpCanvas = document.createElement("canvas");
    const ctx = tmpCanvas.getContext("2d");
    const img = new Image();
    img.onload = (event) => {
      URL.revokeObjectURL(event.target.src); // 👈 This is important. If you are not using the blob, you should release it if you don't want to reuse it. It's good for memory.
      ctx.drawImage(img, 0, 0);
      resolve(img);
    };
    img.src = URL.createObjectURL(theBlob);
  });
};

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

function isCurrentRole(role) {
  return getCurrentRole().name === role;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function removeEntitiesWithEmptyValue(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") {
      delete obj[key];
    }
  });
}

export default {
  validateRoles,
  flatObject,
  getFromStorage,
  setToStorage,
  removeFromStorage,
  logout,
  isLoggedIn,
  oneLevelObject,
  deepenOneLevelToNested,
  toSnakeCase,
  isRole,
  isSystemAdmin,
  isOrganizationAdmin,
  isOrganizationUser,
  isRealmAdmin,
  isRealmUser,
  isAnyAdmin,
  hasRole,
  calculateRolesLower,
  getJwt: getJwtToken,
  getCurrentRealm,
  getCurrentRole,
  getCurrentUser,
  getCurrentPlan,
  hasRealmPlan,
  hasExactRealmPlan,
  hasRealmPlanAndRole,
  REALM_PLANS,
  ROLES,
  ROLES_MAP,
  numberWithCommas,
  formatDate,
  formatStandardDate,
  DATE_TIME_FORMAT,
  formatMoney,
  LEAD_TYPES,
  isJson,
  blobToFile,
  blobToCanvas,
  calculateAspectRatioFit,
  isCurrentRole,
  debounce,
  removeEntitiesWithEmptyValue,
};
