<template>
  <div class="range-wrapper">
    <div class="range-slide-section">
      <div class="range-slider">
        <VueSlider
          :value="sliderValue"
          @change="updateValue"
          :min="min"
          :max="max"
          :marks="computedMarks"
          :tooltip="tooltip"
          :tooltipFormatter="tooltipFormatter"
          :tooltipStyle="computedTooltipStyle"
          :processStyle="computedProcessStyle"
        />
      </div>
      <div class="range-footer" v-if="showAlternativeInput">
        <label for="prt-range-slide-input">Alternativ eintippen</label>
        <div class="grid">
          <RangeSlideInput
            id="prt-range-slide-input"
            :min="min"
            :max="max"
            :unit="unit"
            style="height: 100%"
            :value="inputValue"
            @input="updateValue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RangeSlideInput from "@/components/RangeSlideInput";
import commons from "@/commons";

const debouncedUpdate = commons.debounce((vm, val) => {
  vm.$emit("input", val);
}, 200);

export default {
  name: "RangeSlider",
  components: { RangeSlideInput },
  props: {
    unit: String,
    value: Number,
    min: Number,
    max: Number,
    labelEvery: Number,
    showMinLabel: Boolean,
    showAlternativeInput: {
      type: Boolean,
      default: true,
    },
    plusOnMax: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
      default: "#fff",
    },
    backgroundColor: {
      type: String,
      default: "#2D78A9",
    },
    inputCenter: Boolean,
  },
  methods: {
    updateValue(e) {
      let v = e; // e is, if not event, a number, because of @change in VueSlider
      if (e instanceof Event) {
        v = e.target.value;
      }
      if (v === 0) {
        this.inputValue = "";
        this.sliderValue = this.value;
        return;
      } else {
        this.inputValue = v;
        this.sliderValue = v;
      }
      debouncedUpdate(this, v);
    },
  },
  watch: {
    value(newValue) {
      this.sharedValue = newValue;
      this.inputValue = newValue;
      this.sliderValue = newValue;
    },
  },
  computed: {
    computedTooltipStyle() {
      return {
        color: this.fontColor,
        fontSize: "18px",
        backgroundColor: this.backgroundColor,
        borderColor: this.backgroundColor,
      };
    },
    computedProcessStyle() {
      return {
        backgroundColor: this.backgroundColor,
      };
    },
    computedMarks() {
      return (val) =>
        val % this.labelEvery === 0 || (val === this.min && this.showMinLabel)
          ? {
              label: `${val}${this.unit}${val === this.max && this.plusOnMax ? "+" : ""}`,
              labelActiveStyle: {
                color: this.backgroundColor,
              },
            }
          : false;
    },
    computedRangeFooterClass() {
      return this.inputCenter
        ? "range-footer range-footer-center"
        : "range-footer";
    },
  },
  data() {
    return {
      sliderValue: this.value,
      inputValue: this.value,
      tooltip: "always",
      tooltipFormatter: (val) =>
        `${val}${this.unit}${val === this.max && this.plusOnMax ? "+" : ""}`, // eg. m²
    };
  },
};
</script>

<style lang="scss" scoped>
.range-wrapper {
  width: 100%;
  display: grid;

  .range-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .range-slider {
    padding: 52px 4px 12px 4px;
  }

  .range-footer {
    margin-top: 32px;
    margin-left: 1em;
    margin-right: 1em;

    .range-back-button {
      display: none;
      @media only screen and (max-width: 667px) {
        display: block;
      }
    }
  }

  label {
    display: block;
    width: 100%;
    text-align: left;
  }
}
</style>
