import commons from "@/commons";

export default [
  {
    _name: "CSidebarNav",
    _children: [
      // Start
      {
        _name: "CSidebarNavTitle",
        _children: ["Start"],
      },
      { _name: "CSidebarNavItem", name: "Dashboard", to: "/dashboard", icon: "cil-speedometer" },
      { _name: "CSidebarNavItem", name: "Leads", to: "/leads", icon: "cil-graph" },
      { _name: "CSidebarNavItem", name: "Deals", to: "/deals/index", icon: "cil-chart-line" },
      { _name: "CSidebarNavItem", name: "Aufgaben", to: "/tasks", icon: "cil-task" },

      // Lead-Generierung
      {
        _name: "CSidebarNavTitle",
        _children: ["Lead-Generierung"],
      },
      { _name: "CSidebarNavItem", name: "Lead Funnel", to: "/leadGenerator", icon: "cil-window-restore" },
      {
        _name: "CSidebarNavDropdown",
        name: "Immobilienbewertung",
        icon: "cil-home",
        items: [
          { name: "Wertermittlungstool", to: "/iframe", icon: "cil-browser" },
          { name: "Wohnmarktanalyse", to: "/pdf-settings", icon: "cil-file" },
          { name: "Freizugebene Leads", to: "/queuedLeads", icon: "cil-book" },
        ],
      },
      { _name: "CSidebarNavItem", name: "Landingpages", to: "/landingpage", icon: "cil-sitemap" },

      // CRM
      {
        _name: "CSidebarNavTitle",
        _children: ["CRM"],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Kontakte",
        icon: "cil-contact",
        items: [
          { name: "Personen", to: "/contacts/people", icon: "cil-user" },
          { name: "Merkmale", to: "/contacts/organization", icon: "cil-building" },
        ],
      },
      { _name: "CSidebarNavItem", name: "Propstack", to: "/contacts/propstack", icon: "cil-sync" },
      { _name: "CSidebarNavItem", name: "Import", to: "/settings/import", icon: "cil-cloud-upload"},

      // Marketing
      {
        _name: "CSidebarNavTitle",
        _children: ["Marketing"],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Social Media",
        icon: "cil-share",
        items: [
          { name: "Hilfe", to: "/socialMedia/hilfe", icon: "cil-lightbulb" },
          { name: "Setup", to: "/socialMedia/profilesetup", icon: "cil-lightbulb" },
          { name: "Dashboard", to: "/socialMedia/automation", icon: "cib-probot" },
          { name: "Social Media Kalender", to: "/socialMedia", icon: "cil-calendar" },
          { name: "Social Media Konten", to: "/socialMedia/connections", icon: "cil-swap-horizontal" },
        ],
      },

      commons.isSystemAdmin()
        ? {
          _name: "CSidebarNavItem",
          name: "Google My Business",
          to: "/google-my-business",
          icon: "cibGoogle"
        }
        : {},

      {
        _name: "CSidebarNavDropdown",
        name: "E-Mail Marketing",
        icon: "cil-envelope-open",
        items: [
          { name: "E-Mail Funnels", to: "/funnel", icon: "cil-filter" },
          { name: "E-Mail Newsletter", to: "/newsletter/article", icon: "cil-newspaper" },
          { name: "E-Mail Empfänger", to: "/emailBasic/subscriber", icon: "cil-people" },
          { name: "E-Mail Kampagnen", to: "/emailBasic/campaigns", icon: "cil-bullhorn" },
          { name: "Anmeldeformulare", to: "/emailBasic/generator", icon: "cil-clipboard" },
          { name: "Opt-In Einstellungen", to: "/emailBasic/opt-in", icon: "cil-lock-locked" },
        ],
      },

      // Statistiken
      { _name: "CSidebarNavItem", name: "Statistiken", to: "/statistics", icon: "cil-bar-chart" },
      { _name: "CSidebarNavItem", name: "Mail Analyse", to: "/emailAnalytics", icon: "cil-envelope-closed" },

      // Einstellungen
      {
        _name: "CSidebarNavDropdown",
        name: "Einstellungen",
        icon: "cil-settings",
        items: [
          { name: "Grundeinstellungen", to: "/settings/general", icon: "cil-cog" },
          { name: "Empfängergruppen", to: "/emailBasic/groups", icon: "cil-people" },
          { name: "Pipelines", to: "/deals/pipeline", icon: "cil-chart" },
          commons.isOrganizationAdmin() ? { name: "Benutzer", to: "/users", icon: "cil-user" } : {},
        ],
      },

      commons.isSystemAdmin()
          ? {
            _name: "CSidebarNavDropdown",
            name: "Administration",
            icon: "cil-settings",
            items: [
              { name: "Accounts", to: "/admin/organizations", icon: "cil-building" },
              { name: "Abonnements", to: "/admin/realms", icon: "cil-address-book" },
              { name: "Benutzer", to: "/admin/users", icon: "cil-user" },
              { name: "Benachrichtigungen", to: "/admin/notifications", icon: "cil-bell" },
              { name: "Default Realm Settings", to: "/admin/default-realm-settings", icon: "cil-settings" },
              { name: "System-Operationen", to: "/admin/system", icon: "cil-settings" },
              { name: "Chatbot", to: "/chatbot-settings", icon: "cib-probot" },
            ],
          }
          : {},

      // Standortverwaltung (nur für spezifische Benutzer sichtbar)
      commons.hasExactRealmPlan("KENSINGTON") && commons.hasRole("ORGANIZATION_ADMIN")
          ? {
            _name: "CSidebarNavDropdown",
            name: "Standortverwaltung",
            icon: "cil-location-pin",
            badge: { color: "primary", text: "NEU" },
            items: [
              { name: "Standorte", to: "/kensington-locations" },
              { name: "Importieren", to: "/kensington-locations-import" },
            ],
          }
          : {},
    ],
  },
];
