<template>
  <div class="clean-dashboard">
    <CBreadcrumbRouter :routes="routes"/>

    <!-- Main Actions -->
    <CRow class="mb-4">
      <CCol md="6">
        <CCard class="h-100 clean-card">
          <CCardBody class="d-flex flex-column">
            <div class="feature-header">
              <div class="icon-wrapper">
                <CIcon name="cil-user" size="xl" class="text-primary"/>
              </div>
              <div class="feature-text">
                <h4 class="mb-2">Unternehmensprofil erstellen</h4>
                <p class="text-muted small">Erstellen Sie Ihr individuelles Profil</p>
              </div>
            </div>
            <p class="feature-description">
              Legen Sie Ihr Unternehmensprofil an, um maßgeschneiderte Social Media Beiträge
              zu erhalten, die perfekt auf Ihre Zielgruppe abgestimmt sind.
            </p>
            <CButton
              color="primary"
              variant="outline"
              class="mt-auto"
              @click="$router.push('/socialMedia/profilesetup')"
            >
              Profil erstellen
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol md="6">
        <CCard class="h-100 clean-card">
          <CCardBody class="d-flex flex-column">
            <div class="feature-header">
              <div class="icon-wrapper success">
                <CIcon name="cil-calendar" size="xl" class="text-success"/>
              </div>
              <div class="feature-text">
                <h4 class="mb-2">Wochenplanung</h4>
                <p class="text-muted small">Verwalten Sie Ihre geplanten Beiträge</p>
              </div>
            </div>
            <p class="feature-description">
              Jeden Freitag erstellt unser System neue Beitragsvorschläge.
              Überprüfen und planen Sie diese für die kommende Woche.
            </p>
            <CButton
              color="success"
              variant="outline"
              class="mt-auto"
              @click="reviewWeeklyPosts"
            >
              Planung öffnen
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Posts Section -->
    <CCard class="mb-4 clean-card">
      <CCardHeader class="d-flex justify-content-between align-items-center bg-transparent">
        <h4 class="mb-0">Aktuelle Beiträge</h4>
        <CButton
          color="primary"
          @click="$router.push('/socialMedia/post/new')"
        >
          <CIcon name="cil-plus" class="me-2" />
          Neuer Beitrag
        </CButton>
      </CCardHeader>

      <CCardBody>
        <!-- Empty State -->
        <div v-if="!posts.length" class="text-center py-5">
          <CIcon
            name="cil-newspaper"
            size="3xl"
            class="mb-4 text-muted"
          />
          <h5 class="text-muted">Noch keine Beiträge vorhanden</h5>
          <p class="text-muted mb-4">
            Ihre generierten Beiträge werden hier angezeigt, sobald Sie welche erstellt haben.
          </p>
          <CButton
            color="primary"
            variant="outline"
            @click="$router.push('/socialMedia/profilesetup')"
          >
            Ersten Beitrag erstellen
          </CButton>
        </div>

        <!-- Posts Grid -->
        <CRow v-else>
          <CCol
            v-for="post in sortedPosts"
            :key="post.id"
            sm="12"
            md="6"
            lg="4"
            class="mb-4"
          >
            <CCard class="h-100 post-card">
              <div class="post-status-banner" :class="{ 'is-approved': post.approved }">
                {{ post.approved ? 'Geplant' : 'Entwurf' }}
              </div>
              <div class="post-image-wrapper">
                <CImg
                  v-if="post.imageUrl"
                  :src="post.imageUrl"
                  class="post-image"
                  :alt="post.name"
                />
                <div v-else class="post-image-placeholder">
                  <CIcon name="cil-image" size="xl" class="text-muted"/>
                </div>
              </div>
              <CCardBody>
                <div class="post-meta mb-3">
                  <h5 class="post-title">{{ post.name }}</h5>
                  <div class="post-info">
                    <div class="post-date">
                      <CIcon name="cil-calendar" class="me-1"/>
                      {{ formatPublishDate(post.publishAt) }}
                    </div>
                    <div class="social-networks">
                      <span
                        v-for="conn in getConnections(post.connection)"
                        :key="conn.id"
                        :class="getNetworkClass(conn.socialMedia)"
                        class="network-badge"
                      >
                        <CIcon :name="conn.icon" size="sm"/>
                        {{ conn.socialMedia }}
                      </span>
                    </div>
                  </div>
                </div>
                <p class="post-text">{{ post.content }}</p>
                <div class="post-actions">
                  <CButton
                    color="light"
                    class="action-btn edit-btn"
                    @click="openEditModal(post)"
                  >
                    <CIcon name="cil-pencil"/> Bearbeiten
                  </CButton>
                  <CButton
                    color="danger"
                    variant="ghost"
                    class="action-btn delete-btn"
                    @click="confirmDelete(post)"
                  >
                    <CIcon name="cil-trash"/> Löschen
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <!-- Weekly Review Modal -->
    <CModal
      :show.sync="showWeeklyReviewModal"
      size="xl"
      :closeOnBackdrop="false"
    >
      <WeeklyReviewComponent
        @approve="handleWeeklyPostsApprove"
      />
    </CModal>

    <!-- Edit Post Modal -->
    <EditPostModal
      v-if="showEditModal"
      :show.sync="showEditModal"
      :post="editingPost"
      :socialMediaOptions="socialMediaOptions"
      @save="handleEditedPostSave"
    />

    <!-- Delete Confirmation Modal -->
    <CModal
      :show.sync="showDeleteModal"
      size="sm"
    >
      <template #header>
        <h5>Beitrag löschen</h5>
      </template>
      <template #body>
        Möchten Sie diesen Beitrag wirklich löschen?
      </template>
      <template #footer>
        <CButton
          color="secondary"
          @click="showDeleteModal = false"
        >
          Abbrechen
        </CButton>
        <CButton
          color="danger"
          @click="deletePost"
        >
          Löschen
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { CBreadcrumbRouter } from "@coreui/vue";
import moment from 'moment';
import 'moment/locale/de';
import axios from 'axios';
import toastPlugin from '../../../toastPlugin';
import {
  CModal,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CCol,
  CIcon,
  CImg,
  CInput,
  CSpinner
} from '@coreui/vue'

export default {
  name: 'Dashboard',
  components: {
    CBreadcrumbRouter,
    WeeklyReviewComponent: () => import('./WeeklyReviewComponent.vue'),
    EditPostModal: () => import('./EditPostModal.vue'),
    CModal,
    CButton,
    CCard,
    CCardHeader,
    CCardBody,
    CRow,
    CCol,
    CIcon,
    CImg,
    CInput,
    CSpinner
  },
  props: {
    realmId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      routes: [
        {
          path: '/',
          name: 'Home'
        },
        {
          path: '/socialMedia',
          name: 'SocialMediaAutomation'
        }
      ],
      posts: [],
      socialMediaOptions: [],
      showWeeklyReviewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      editingPost: null,
      deletePostId: null,
      currentMonth: moment().format('MMMM YYYY'),
      totalReach: '0',
      scheduledPosts: '0',
      engagementRate: '0',
      showDashboard: false,
      isLoading: false
    };
  },
  computed: {
    sortedPosts() {
      return [...this.posts].sort((a, b) => {
        const dateA = new Date(a.publishAt);
        const dateB = new Date(b.publishAt);
        return dateB - dateA; // Neueste zuerst
      });
    }
  },
  async created() {
    await this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await axios.get("/socialMedia/posts");
        this.posts = response.data.map((post) => ({
          ...post,
          publishAt: post.publishAt || new Date().toISOString(),
        }));
        this.showDashboard = this.posts.length > 0;
      } catch (error) {
        console.error("Error fetching posts:", error);
        toastPlugin.makeToast(
          "Fehler",
          "Es gab einen Fehler beim Laden der Beiträge. Bitte versuchen Sie es später erneut."
        );
      }
    },

    formatPublishDate(date) {
      if (!date) return 'Kein Datum';
      moment.locale('de');
      return moment(date).format('DD.MM.YYYY - HH:mm [Uhr]');
    },

    getConnections(connection) {
      if (!connection) return [];
      if (Array.isArray(connection)) return connection;
      if (typeof connection === 'object') return [connection];
      return [];
    },

    getNetworkClass(network) {
      const classes = {
        FACEBOOK: 'facebook',
        LINKEDIN: 'linkedin',
        INSTAGRAM: 'instagram',
        TWITTER: 'twitter'
      };
      return classes[network] || 'default';
    },

    async checkInterviewCompletion() {
      try {
        const currentRealmId = this.realmId || this.$route.query.realmId;

        if (!currentRealmId) {
          console.error('Keine Realm ID verfügbar');
          toastPlugin.makeToast('Fehler', 'Keine Realm ID verfügbar');
          return;
        }

        const response = await axios.get('/socialMedia/profile/completed', {
          params: { realmId: currentRealmId }
        });

        if (!response.data) {
          this.$router.push('/socialMedia/profilesetup');
        }
      } catch (error) {
        console.error('Fehler beim Prüfen des Interviewstatus:', error);
        toastPlugin.makeToast('Fehler', 'Fehler beim Prüfen des Profil-Status');
      }
    },

    openEditModal(post) {
      const postToEdit = {
        ...post,
        connection: this.getConnections(post.connection)
      };
      this.editingPost = postToEdit;
      this.showEditModal = true;
    },

    async deletePost() {
      try {
        await axios.delete(`/socialMedia/posts/${this.deletePostId}`);
        this.posts = this.posts.filter(post => post.id !== this.deletePostId);
        toastPlugin.makeToast('Erfolg', 'Beitrag wurde gelöscht');
      } catch (error) {
        console.error('Fehler beim Löschen:', error);
        toastPlugin.makeToast('Fehler', 'Beitrag konnte nicht gelöscht werden');
      } finally {
        this.showDeleteModal = false;
        this.deletePostId = null;
      }
    },

    confirmDelete(post) {
      this.deletePostId = post.id;
      this.showDeleteModal = true;
    },

    reviewWeeklyPosts() {
      this.showWeeklyReviewModal = true;
    },

    async handleWeeklyPostsApprove(posts) {
      try {
        for (const post of posts) {
          await axios.post(`/socialMedia/approvePost/${post.id}`);
          const index = this.posts.findIndex(p => p.id === post.id);
          if (index !== -1) {
            this.$set(this.posts, index, { ...this.posts[index], approved: true });
          }
        }
        this.showWeeklyReviewModal = false;
        toastPlugin.makeToast('Erfolg', 'Beiträge wurden erfolgreich geplant');
        await this.fetchPosts();
      } catch (error) {
        console.error('Fehler beim Genehmigen der Posts:', error);
        toastPlugin.makeToast('Fehler', 'Beiträge konnten nicht geplant werden');
      }
    },

    async handleEditedPostSave(post) {
      try {
        if (!post.publishAt) {
          post.publishAt = new Date().toISOString();
        }
        const response = await axios.put(`/socialMedia/posts/${post.id}`, post);
        const index = this.posts.findIndex(p => p.id === post.id);
        if (index !== -1) {
          this.$set(this.posts, index, response.data);
        }
        this.showEditModal = false;
        toastPlugin.makeToast('Erfolg', 'Beitrag wurde gespeichert');
      } catch (error) {
        console.error('Fehler beim Speichern des Posts:', error);
        toastPlugin.makeToast('Fehler', 'Beitrag konnte nicht gespeichert werden');
      }
    }
  }
};
</script>

<style scoped>
.feature-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.icon-wrapper {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: rgba(79, 70, 229, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper.success {
  background-color: rgba(34, 197, 94, 0.1);
}

.feature-text {
  flex-grow: 1;
}

.feature-description {
  color: #6b7280;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.post-meta {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.post-date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px 8px;
  background-color: #f3f4f6;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #4b5563;
  font-weight: 500;
}

.post-date .icon {
  color: #6b7280;
}

.post-actions {
  display: flex;
  gap: 1rem;
  margin-top: auto;
  padding-top: 1rem;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.edit-btn {
  color: #4b5563;
  background-color: #f3f4f6;
  border: none;
}

.edit-btn:hover {
  background-color: #e5e7eb;
}

.delete-btn {
  color: #dc2626;
}

.delete-btn:hover {
  background-color: #fee2e2;
}

.clean-dashboard {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 1.5rem;
}

.clean-card {
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.clean-card:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.clean-icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.clean-text {
  color: #6c757d;
  line-height: 1.6;
  font-size: 0.95rem;
}

/* Post Card Styles */
.post-card {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.post-status-banner {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: #f3f4f6;
  color: #6b7280;
  z-index: 1;
}

.post-status-banner.is-approved {
  background-color: #dcfce7;
  color: #166534;
}

.post-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
}

.post-image-wrapper {
  position: relative;
  height: 200px;
  overflow: hidden;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-image-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
}

.post-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.post-date {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #6b7280;
}

.social-networks {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.network-badge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.network-badge.facebook {
  background-color: #1877f2;
  color: white;
}

.network-badge.linkedin {
  background-color: #0a66c2;
  color: white;
}

.network-badge.instagram {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  color: white;
}

.network-badge.twitter {
  background-color: #1da1f2;
  color: white;
}

.network-badge.default {
  background-color: #e5e7eb;
  color: #4b5563;
}

.post-title {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1rem;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .clean-dashboard {
    padding: 1rem;
  }

  .post-image-wrapper {
    height: 160px;
  }

  .post-title {
    font-size: 1rem;
  }

  .post-text {
    font-size: 0.875rem;
  }
}

/* Utility Classes */
.text-muted {
  color: #6c757d !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media (max-width: 768px) {
  .feature-header {
    gap: 0.75rem;
  }

  .icon-wrapper {
    width: 40px;
    height: 40px;
  }

  .post-actions {
    flex-direction: column;
    gap: 0.5rem;
  }
}
/* Responsive Anpassungen */
@media (max-width: 768px) {
  .post-meta {
    gap: 0.5rem;
  }

  .post-info {
    gap: 0.5rem;
  }

  .post-date {
    font-size: 0.8125rem;
  }

  .network-badge {
    padding: 3px 6px;
    font-size: 0.7rem;
  }
}
</style>
