<template>
  <div class="activity-widget">
    <div class="activity-widget__container">
      <div class="activity-widget__form">
        <!-- Title Input -->
        <div class="title-section">
          <CInput
            v-model="title"
            :placeholder="getPlaceholder(type)"
            :is-valid="!isDirty || requiredFields.title"
            :invalid-feedback="isDirty && !requiredFields.title ? 'Titel ist erforderlich' : ''"
            class="title-input"
          />
        </div>

        <!-- Activity Type Buttons -->
        <div class="type-buttons">
          <button
            v-for="(item, index) in activityTypes"
            :key="index"
            :class="['type-button', { active: type === item.value }]"
            @click="type = item.value"
            v-c-tooltip="item.text"
          >
            <CIcon :name="item.icon" />
          </button>
        </div>

        <!-- DateTime Section -->
        <div class="datetime-section">
          <div class="datetime-group">
            <div class="datetime-label">
              <CIcon name="cil-clock" />
              <span>Start</span>
            </div>
            <div class="datetime-inputs">
              <datepicker
                v-model="startDate"
                :language="datepickerLangGerman"
                :monday-first="true"
                format="dd/MM/yyyy"
                class="date-picker"
              />
              <CSelect
                :options="times"
                :value="startTime"
                class="time-select"
                @update:value="updateStartTime"
              />
            </div>
          </div>

          <div class="datetime-group">
            <div class="datetime-label">
              <span>Ende</span>
            </div>
            <div class="datetime-inputs">
              <datepicker
                v-model="endDate"
                :language="datepickerLangGerman"
                :monday-first="true"
                format="dd/MM/yyyy"
                class="date-picker"
              />
              <CSelect
                :options="times"
                :value="endTime"
                class="time-select"
                @update:value="updateEndTime"
              />
            </div>
          </div>
        </div>

        <!-- Additional Fields -->
        <div v-if="checkAllEmpty()" class="quick-add">
          <span>Hinzufügen:</span>
          <div class="quick-buttons">
            <button
              v-for="action in ['guests', 'location', 'description']"
              :key="action"
              @click="focusOnExtra(action)"
              class="quick-button"
            >
              {{ getActionLabel(action) }}
            </button>
          </div>
        </div>

        <!-- Expanded Sections -->
        <div class="expanded-sections">
          <div v-if="focusedExtra === 'guests' || selectedGuests.length > 0" class="section">
            <div class="guest-input">
              <CIcon name="cil-user" class="section-icon" />
              <multi-select
                :options="guests"
                :selected-options="selectedGuests"
                placeholder="Gäste hinzufügen"
                @select="onSelect"
                class="multi-select"
              />
            </div>
            <div class="new-guest">
              <input
                type="text"
                v-model="newGuest"
                placeholder="Neuer Gast"
                @keydown="onKeyDown"
                class="guest-input"
              />
              <button @click="insertAsNewOption(newGuest)" class="add-button">
                <CIcon name="cil-plus" />
              </button>
            </div>
          </div>

          <div v-if="focusedExtra === 'location' || location" class="section">
            <CIcon name="cis-location-pin" class="section-icon" />
            <CInput
              v-model="location"
              placeholder="Standort hinzufügen"
              class="location-input"
            />
          </div>

          <div v-if="focusedExtra === 'description' || description" class="section">
            <CIcon name="cil-list" class="section-icon" />
            <WysiwygEditor v-model="description" class="description-editor" />
            <div class="info-text">
              Die Beschreibung wird mit Ihrem Kalender synchronisiert
            </div>
          </div>
        </div>

        <!-- Select Fields -->
        <div class="select-sections">
          <div class="select-group">
            <CIcon name="cil-paperclip" class="section-icon" />
            <model-select
              :options="leads"
              v-model="lead"
              placeholder="Deal oder Lead"
              class="select-input"
            />
          </div>

          <div v-if="!lead.value" class="select-group">
            <CIcon name="cil-group" class="section-icon" />
            <model-select
              :options="groups"
              v-model="selectedGroup"
              placeholder="Organisation"
              class="select-input"
            />
          </div>

          <div v-if="!lead.value" class="select-group">
            <CIcon name="cil-user" class="section-icon" />
            <multi-select
              :options="peoples"
              :selected-options="selectedPeoples"
              placeholder="Kontakt auswählen"
              @select="onSelectPeople"
              class="multi-select"
            />
          </div>
        </div>

        <!-- Done Checkbox -->
        <div class="done-section">
          <CInputCheckbox
            label="Als erledigt markieren"
            :checked="done"
            @update:checked="done = !done"
            class="done-checkbox"
          />
        </div>
      </div>

      <!-- Calendar Section -->
      <div class="activity-widget__calendar">
        <div class="calendar-header">
          <button class="nav-button" @click="calendarPrevious">
            <CIcon name="cil-arrow-circle-left" />
          </button>
          <span class="current-date">
            {{ selectedDate.toLocaleDateString("de-DE") }}
          </span>
          <button class="nav-button" @click="calendarNext">
            <CIcon name="cil-arrow-circle-right" />
          </button>
        </div>

        <vue-cal
          ref="vuecal"
          locale="de"
          hide-view-selector
          :selected-date="selectedDate"
          :time-from="6 * 60"
          :time-to="22 * 60"
          :time-step="60"
          :disable-views="['years', 'year', 'month', 'week']"
          hide-weekends
          :editable-events="{
            title: false,
            drag: true,
            resize: true,
            delete: false,
            create: false,
          }"
          :min-event-width="minEventWidth"
          :events="events"
          :snap-to-time="15"
          @event-duration-change="logEvents('event-duration-change', $event)"
          @event-drop="logEvents('event-drop', $event)"
          class="calendar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WysiwygEditor from "@/components/WysiwygEditor";
import { ModelSelect, MultiSelect } from "vue-search-select";
import { activityTypes } from "@/config/constant";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import { de } from "vuejs-datepicker/dist/locale";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/drag-and-drop.es.js";
import moment from "moment";
import "moment/locale/de";

export default {
  name: "ActivityWidget",
  components: {
    ModelSelect,
    MultiSelect,
    WysiwygEditor,
    Datepicker,
    VueCal,
  },

  created() {
    this.activityTypes = activityTypes;
  },

  props: ["closeModal", "applyUpdate"],

  data() {
    // Aktuelles Datum für Initialisierung
    const now = new Date();
    const roundedMinutes = Math.ceil(now.getMinutes() / 15) * 15;
    const initialDate = new Date(now);
    initialDate.setMinutes(roundedMinutes, 0, 0);

    // Zeitoptionen generieren
    const timeOptions = [""];
    for (let i = 0; i < 24; i++) {
      for (const minutes of ['00', '15', '30', '45']) {
        const timeStr = `${String(i).padStart(2, '0')}:${minutes}`;
        timeOptions.push({
          value: timeStr,
          text: timeStr
        });
      }
    }

    return {
      datepickerLangGerman: de,
      alreadyCreatedEvents: [],
      minEventWidth: 0,
      selectedDate: initialDate,
      title: '',
      showModal: false,
      activityTypes: activityTypes,
      type: activityTypes[0].value,
      times: [],
      startDate: initialDate,
      endDate: new Date(initialDate.getTime() + 15 * 60000),
      startTime: this.formatTime(initialDate),
      endTime: this.formatTime(new Date(initialDate.getTime() + 15 * 60000)),
      busyItems: [
        { value: "FREE", label: "Frei" },
        { value: "BUSY", label: "Beschäftigt" },
      ],
      freeType: "FREE",
      description: "",
      notes: "",
      leads: [],
      lead: {
        value: null,
        text: "",
        isLead: false
      },
      location: "",
      done: false,
      guests: [],
      selectedGuests: [],
      lastSelectGuest: {},
      peoples: [],
      selectedPeoples: [],
      lastSelectPeople: {},
      groups: [],
      selectedGroup: {
        value: null,
        text: "",
      },
      focusedExtra: "",
      newGuest: "",
      errors: {},
      submitted: false,
      newGuestAddTried: false,
      selectedActivityId: null,
    };
  },

  mounted() {
    try {
      const now = new Date();
      const roundedMinutes = Math.ceil(now.getMinutes() / 15) * 15;
      now.setMinutes(roundedMinutes, 0, 0);

      this.selectedDate = now;
      this.startDate = now;
      this.endDate = new Date(now.getTime() + 15 * 60000);
      this.startTime = this.formatTime(now);
      this.endTime = this.formatTime(this.endDate);

      this.initializeTimeOptions();
      this.loadPeoples();
      this.loadGroups();
      this.loadLeads();

      console.log("Initial state after mounted:", {
        selectedDate: this.selectedDate,
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime
      });
    } catch (error) {
      console.error('Fehler beim Mounten:', error);
    }
  },

  // Für leere Initialisierung
  initializeEmpty(dealId = null) {
    console.log('=== START initializeEmpty ===');
    console.log('Called with dealId:', dealId);

    const now = new Date();
    const roundedMinutes = Math.ceil(now.getMinutes() / 15) * 15;
    now.setMinutes(roundedMinutes, 0, 0);
    initialDate.setMinutes(roundedMinutes, 0, 0);

    if (dealId) {
      this.selectedDealId = dealId;
    }

    // Force update
    this.$forceUpdate();

    console.log('Empty initialization complete:', {
      selectedDate: this.selectedDate,
      startDate: this.startDate,
      endDate: this.endDate,
      startTime: this.startTime,
      endTime: this.endTime,
      dealId: this.selectedDealId
    });
  },

  // Für Initialisierung mit existierender Task
  initializeWithTask(task) {
    console.log('=== START initializeWithTask ===');
    console.log('Called with task:', task);

    try {
      // Parse dates
      const startDate = new Date(task.startTime);
      const endDate = new Date(task.endTime);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        throw new Error('Invalid dates in task');
      }

      this.selectedDate = startDate;
      this.startDate = startDate;
      this.endDate = endDate;
      this.startTime = this.formatTime(startDate);
      this.endTime = this.formatTime(endDate);

      // Set other fields
      this.title = task.title || '';
      this.type = task.type || this.activityTypes[0].value;
      this.description = task.description || '';
      this.location = task.location || '';
      this.freeType = task.freeType || 'FREE';
      this.notes = task.notes || '';
      this.done = task.done || false;
      this.selectedActivityId = task.id;
      this.selectedDealId = task.deal?.id;

      // Force update
      this.$forceUpdate();

      console.log('Task initialization complete:', {
        title: this.title,
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        taskId: this.selectedActivityId,
        dealId: this.selectedDealId
      });
    } catch (error) {
      console.error('Error in initializeWithTask:', error);
      this.initializeEmpty(task.deal?.id);
    }
  },

  watch: {
    startDate: {
      handler(newVal) {
        if (!(newVal instanceof Date) || isNaN(newVal.getTime())) {
          const now = new Date();
          this.startDate = now;
          this.startTime = this.formatTime(now);
        }
      },
      immediate: true
    },
    endDate: {
      handler(newVal) {
        if (!(newVal instanceof Date) || isNaN(newVal.getTime())) {
          const now = new Date();
          const later = new Date(now.getTime() + 15 * 60000);
          this.endDate = later;
          this.endTime = this.formatTime(later);
        }
      },
      immediate: true
    },
    selectedDate: {
      handler(newVal) {
        if (!(newVal instanceof Date) || isNaN(newVal.getTime())) {
          const now = new Date();
          this.selectedDate = now;
        }
      },
      immediate: true
    },
    startTime(newVal) {
      if (!newVal) {
        const now = new Date();
        this.startTime = this.formatTime(now);
      }
    },
    endTime(newVal) {
      if (!newVal) {
        const now = new Date();
        const later = new Date(now.getTime() + 15 * 60000);
        this.endTime = this.formatTime(later);
      }
    }
  },

  computed: {
    events() {
      try {
        const currentEvents = [...this.alreadyCreatedEvents];

        if (this.startDate && this.endDate &&
          moment(this.startDate).isValid() &&
          moment(this.endDate).isValid()) {
          currentEvents.push({
            start: this.startDate,
            end: this.endDate,
            title: this.getPlaceholder(this.type) + " - " + this.title,
            content: "",
            class: "current",
            draggable: true,
            resizable: true
          });
        }

        return currentEvents;
      } catch (error) {
        console.error('Fehler in events computed:', error);
        return [];
      }
    }
  },

  methods: {
    logEvents(type, event) {
      if (type === 'event-duration-change' || type === 'event-drop') {
        try {
          // Event-Daten direkt verwenden
          const { start, end } = event.event;

          this.startDate = start;
          this.endDate = end;
          this.startTime = `${String(start.getHours()).padStart(2, '0')}:${String(start.getMinutes()).padStart(2, '0')}`;
          this.endTime = `${String(end.getHours()).padStart(2, '0')}:${String(end.getMinutes()).padStart(2, '0')}`;
          this.selectedDate = start;

          this.$nextTick(() => {
            if (this.$refs.vuecal) {
              this.$refs.vuecal.updateSelectedDate(start);
            }
            this.$forceUpdate();
          });
        } catch (error) {
          console.error('Error:', error);
        }
      }
    },
    updateStartTime(value) {
      this.startTime = value;
      const [hours, minutes] = value.split(':').map(Number);
      const newStartDate = new Date(this.startDate);
      newStartDate.setHours(hours, minutes, 0, 0);
      this.startDate = newStartDate;

      if (this.initialized) {
        this.validateFields();
      }
    },

    updateEndTime(value) {
      this.endTime = value;
      const [hours, minutes] = value.split(':').map(Number);
      const newEndDate = new Date(this.endDate);
      newEndDate.setHours(hours, minutes, 0, 0);
      this.endDate = newEndDate;

      if (this.initialized) {
        this.validateFields();
      }
    },
    initializeTimeOptions() {
      console.log('Initializing time options');
      this.times = [""]; // Reset times array

      // Generate time options for every 15 minutes
      for (let hour = 0; hour < 24; hour++) {
        for (let minute of ['00', '15', '30', '45']) {
          const timeStr = `${String(hour).padStart(2, '0')}:${minute}`;
          this.times.push({
            value: timeStr,
            text: timeStr
          });
        }
      }
    },
    handleInitializeEmpty(dealId = null) {
      console.log('Initializing empty widget with dealId:', dealId);

      const now = new Date();
      const roundedMinutes = Math.ceil(now.getMinutes() / 15) * 15;
      const initialDate = new Date(now);
      initialDate.setMinutes(roundedMinutes, 0, 0);

      this.selectedDate = initialDate;
      this.startDate = initialDate;
      this.endDate = new Date(initialDate.getTime() + 15 * 60000);
      this.startTime = this.formatTime(initialDate);
      this.endTime = this.formatTime(this.endDate);

      if (dealId) {
        this.selectedDealId = dealId;
      }

      // Force update
      this.$forceUpdate();

      console.log('Widget initialized with empty state:', {
        selectedDate: this.selectedDate,
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        selectedDealId: this.selectedDealId
      });
    },
    getActionLabel(action) {
      const labels = {
        guests: 'Gäste',
        location: 'Standort',
        description: 'Beschreibung'
      };
      return labels[action] || action;
    },
    handleInitializeTask(task) {
      try {
        // Konvertierung von DD/MM/YYYY zu YYYY-MM-DD für konsistentes Parsing
        const [startDay, startMonth, startYear, startTime] = task.startTimeObj.split(/[/\s]/);
        const [endDay, endMonth, endYear, endTime] = task.endTimeObj.split(/[/\s]/);

        const startDateStr = `${startYear}-${startMonth}-${startDay} ${startTime}`;
        const endDateStr = `${endYear}-${endMonth}-${endDay} ${endTime}`;

        const startDate = moment(startDateStr, "YYYY-MM-DD HH:mm").toDate();
        const endDate = moment(endDateStr, "YYYY-MM-DD HH:mm").toDate();

        // Basisfelder setzen
        this.selectedDate = startDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.startTime = moment(startDate).format('HH:mm');
        this.endTime = moment(endDate).format('HH:mm');

        // Weitere Felder
        Object.assign(this, {
          title: task.title || '',
          type: task.type || this.activityTypes[0].value,
          description: task.description || '',
          location: task.location || '',
          freeType: task.freeType || 'FREE',
          notes: task.notes || '',
          done: task.done || false,
          selectedActivityId: task.id
        });

        this.$forceUpdate();
      } catch (error) {
        console.error('Parsing error:', error);
        this.handleInitializeEmpty();
      }
    },

    makeDateTime(date, timeStr) {
      if (!(date instanceof Date) || !timeStr) {
        console.error("Ungültige Parameter in makeDateTime:", { date, timeStr });
        return new Date();
      }

      try {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const result = new Date(date);
        result.setHours(hours, minutes, 0, 0);
        return result;
      } catch (error) {
        console.error("Fehler in makeDateTime:", error);
        return new Date();
      }
    },

    initializeTimes() {
      this.times = [""];
      for (let i = 0; i < 24; i++) {
        ["00", "15", "30", "45"].forEach(minutes => {
          const timeStr = `${String(i).padStart(2, '0')}:${minutes}`;
          this.times.push({
            value: timeStr,
            text: timeStr
          });
        });
      }
    },

    async loadLeads() {
      try {
        const [leadResponse, dealResponse] = await Promise.all([
          axios.get("/lead"),
          axios.get("/deal")
        ]);

        let content = [];

        if (leadResponse.data?.content) {
          leadResponse.data.content.forEach(entity => {
            content.push({
              value: entity.id,
              text: `${entity.humanId} - ${entity.holder.fullName} (Lead)`,
              isLead: true
            });
          });
        }

        if (dealResponse.data) {
          dealResponse.data.forEach(entity => {
            content.push({
              value: entity.id,
              text: `${entity.name} (Deal)`,
              isLead: false
            });
          });
        }

        this.leads = content;
      } catch (error) {
        console.error("Fehler beim Laden der Leads und Deals:", error);
      }
    },

    loadPeoples() {
      axios.get("/realm-people")
        .then(response => {
          let content = [];
          let peopleContent = [];

          response.data?.forEach(entity => {
            peopleContent.push({
              value: entity.id,
              text: entity.name
            });

            entity.realmPeopleEmails?.forEach(emailItem => {
              content.push({
                value: emailItem.id,
                text: emailItem.email,
                flag: 1
              });
            });
          });

          this.guests = content;
          this.peoples = peopleContent;
        })
        .catch(error => {
          console.error("Fehler beim Laden der Kontakte:", error);
        });
    },

    loadGroups() {
      axios.get("/realm-group")
        .then(response => {
          this.groups = response.data?.map(entity => ({
            value: entity.id,
            text: entity.title
          })) || [];
        })
        .catch(error => {
          console.error("Fehler beim Laden der Gruppen:", error);
        });
    },

    onSelectPeople(selectedPeoples, lastSelectPeople) {
      this.selectedPeoples = selectedPeoples;
      this.lastSelectPeople = lastSelectPeople;
    },

    onSelect(selectedGuests, lastSelectGuest, eventName) {
      this.selectedGuests = selectedGuests;
      this.lastSelectGuest = lastSelectGuest;

      if (eventName === "delete" && lastSelectGuest.flag === 2) {
        const index = this.guests.findIndex(guest => guest.text === lastSelectGuest.text);
        if (index !== -1) {
          this.guests.splice(index, 1);
        }
      }
    },

    getPlaceholder(type) {
      const activity = this.activityTypes.find(item => item.value === type);
      return activity ? activity.text : "Unbekannt";
    },

    async initialize(dealId = null) {
      console.log('=== START initialize ===');

      try {
        // Basis-Zeitinitialisierung
        const now = new Date();
        const roundedMinutes = Math.ceil(now.getMinutes() / 15) * 15;
        const initialDate = new Date(now);
        initialDate.setMinutes(roundedMinutes, 0, 0);

        this.selectedDate = initialDate;
        this.startDate = initialDate;
        this.endDate = new Date(initialDate.getTime() + 15 * 60000);

        // Zeiten explizit setzen
        this.startTime = this.formatTime(initialDate);
        this.endTime = this.formatTime(this.endDate);

        if (dealId) {
          try {
            const response = await axios.get(`/task/deal/${dealId}`);
            console.log('Task Response:', response.data);

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
              const task = response.data[0];

              const startDate = new Date(task.startTime);
              const endDate = new Date(task.endTime);

              if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                this.selectedDate = startDate;
                this.startDate = startDate;
                this.endDate = endDate;
                this.startTime = this.formatTime(startDate);
                this.endTime = this.formatTime(endDate);

                this.title = task.title || '';
                this.type = task.type || this.activityTypes[0].value;
                this.description = task.description || '';
                this.location = task.location || '';
                this.freeType = task.freeType || 'FREE';
                this.notes = task.notes || '';
                this.done = task.done || false;
                this.selectedActivityId = task.id;
              }
            }
          } catch (error) {
            console.error('Fehler beim Laden der Task:', error);
          }
        }

        // Force update
        this.$forceUpdate();

        console.log('Initialize complete:', {
          selectedDate: this.selectedDate,
          startDate: this.startDate,
          endDate: this.endDate,
          startTime: this.startTime,
          endTime: this.endTime
        });

      } catch (error) {
        console.error('Fehler in initialize:', error);
      }

      console.log('=== END initialize ===');
    },

// Neue Methode zum Laden der Deal-Tasks
    async loadDealTasks(dealId) {
      try {
        console.log('Loading tasks for deal:', dealId);
        const response = await axios.get(`/task/deal/${dealId}`);
        const tasks = response.data;

        console.log('Received tasks:', tasks);

        if (tasks && tasks.length > 0) {
          // Nehmen wir den ersten Task für die Initialisierung
          const task = tasks[0];

          // Parse dates using moment
          const startMoment = moment(task.startTime); // ISO-8601 Format
          const endMoment = moment(task.endTime);   // ISO-8601 Format

          if (startMoment.isValid() && endMoment.isValid()) {
            this.selectedDate = startMoment.toDate();
            this.startDate = startMoment.toDate();
            this.endDate = endMoment.toDate();
            this.startTime = startMoment.format('HH:mm');
            this.endTime = endMoment.format('HH:mm');

            // Weitere Felder setzen
            this.title = task.title || '';
            this.type = task.type || this.activityTypes[0].value;
            this.description = task.description || '';
            this.location = task.location || '';
            this.freeType = task.freeType || 'FREE';
            this.notes = task.notes || '';
            this.done = task.done || false;
            this.selectedActivityId = task.id;
          }
        }
      } catch (error) {
        console.error('Error loading deal tasks:', error);
      }
    },

    formatTime(date) {
      try {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
          const now = new Date();
          return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
        }
        return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
      } catch (error) {
        console.error('Fehler in formatTime:', error);
        const now = new Date();
        return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
      }
    },

    validateFields() {
      this.errors = {};

      try {
        if (!this.title?.trim()) {
          this.errors.title = true;
        }

        if (!this.startTime || !this.endTime) {
          this.errors.time = true;
          return false;
        }

        const startDateTime = this.makeDateTime(this.startDate, this.startTime);
        const endDateTime = this.makeDateTime(this.endDate, this.endTime);

        if (!startDateTime || !endDateTime || startDateTime >= endDateTime) {
          this.errors.time = true;
          return false;
        }

        return Object.keys(this.errors).length === 0;
      } catch (error) {
        console.error('Fehler in validateFields:', error);
        this.errors.time = true;
        return false;
      }
    },

    submit(selectedTaskId) {
      this.submitted = true;

      if (!this.validateFields()) {
        return;
      }

      // Erstelle ISO-8601 Zeitstempel für Start- und Endzeit
      const startDateTime = moment(this.makeDateTime(this.startDate, this.startTime));
      const endDateTime = moment(this.makeDateTime(this.endDate, this.endTime));

      const data = {
        title: this.title,
        name: this.title,
        type: this.type,
        startTime: startDateTime.toISOString(), // ISO-8601 Format
        endTime: endDateTime.toISOString(),     // ISO-8601 Format
        guests: this.selectedGuests.map(item => item.text).join(','),
        location: this.location,
        description: this.description,
        freeType: this.freeType,
        notes: this.notes,
        done: this.done
      };

      if (this.lead.value) {
        if (this.lead.isLead) {
          data.lead = { id: this.lead.value };
        } else {
          data.deal = { id: this.lead.value };
        }
      } else {
        if (this.selectedPeoples.length > 0) {
          data.taskPeoples = this.selectedPeoples.map(entity => ({
            realmPeople: {
              id: entity.value,
              name: entity.name
            }
          }));
        }

        if (this.selectedGroup.value) {
          data.realmGroup = {
            id: this.selectedGroup.value
          };
        }
      }

      const url = selectedTaskId ? `/task/${selectedTaskId}` : '/task';
      const method = selectedTaskId ? 'put' : 'post';

      axios[method](url, data)
        .then(response => {
          this.initialize();
          this.closeModal();
          this.applyUpdate(response.data);
        })
        .catch(error => {
          console.error("Fehler beim Speichern der Aktivität:", error);
        });
    },

    getActivitiesOnSelectedDate() {
      if (!(this.selectedDate instanceof Date) || isNaN(this.selectedDate.getTime())) {
        console.error("Invalid selectedDate:", this.selectedDate);
        return;
      }

      const startOfDay = moment(this.selectedDate).startOf('day');
      const endOfDay = moment(this.selectedDate).endOf('day');

      const data = {
        startTime: startOfDay.toISOString(),
        endTime: endOfDay.toISOString()
      };

      console.log('Fetching activities with:', data);

      axios.post('/task/date', data)
        .then(response => {
          this.alreadyCreatedEvents = response.data
            .filter(item => item.id !== this.selectedActivityId)
            .map(item => {
              const start = moment(item.startTime, "DD. MMMM YYYY HH:mm", 'de');
              const end = moment(item.endTime, "DD. MMMM YYYY HH:mm", 'de');
              return {
                initialized: false,
                start: start.toDate(),
                end: end.toDate(),
                title: `${this.getPlaceholder(item.type)} - ${item.title}`,
                content: "",
                class: "already-created",
                draggable: false,
                resizable: false
              };
            });
        })
        .catch(error => {
          console.error('Error fetching activities:', error);
          this.alreadyCreatedEvents = [];
        });
    },

    focusOnExtra(key) {
      this.focusedExtra = key;
    },

    checkAllEmpty() {
      return (
        this.focusedExtra === "" &&
        this.selectedGuests.length === 0 &&
        !this.location &&
        !this.description
      );
    },

    calendarNext() {
      this.$refs.vuecal.next();
      this.selectedDate = moment(this.selectedDate).add(1, 'day').toDate();
    },

    calendarPrevious() {
      this.$refs.vuecal.previous();
      this.selectedDate = moment(this.selectedDate).subtract(1, 'day').toDate();
    },

    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },

    insertAsNewOption(value) {
      if (!value.trim()) return;

      this.newGuestAddTried = true;

      if (this.validateEmail(value.trim())) {
        const newGuest = {
          flag: 2,
          value: value,
          text: value
        };

        this.guests.push(newGuest);
        this.selectedGuests.push(newGuest);
        this.newGuest = "";
        this.newGuestAddTried = false;
      } else {
        this.errors.newGuest = true;
      }
    },

    onKeyDown(e) {
      if (e.keyCode === 13) {
        this.insertAsNewOption(this.newGuest);
      } else {
        const testEmail = this.newGuest + String.fromCharCode(e.keyCode);
        this.errors.newGuest = !this.validateEmail(testEmail.trim());
      }
    }
  }
};
</script>

<style lang="scss">
.activity-widget {
  --primary: #0052CC;
  --primary-hover: #0747A6;
  --surface: #FFFFFF;
  --background: #F4F5F7;
  --text: #172B4D;
  --text-secondary: #6B778C;
  --border: #DFE1E6;
  --shadow: rgba(9, 30, 66, 0.08);
  --radius: 8px;

  background: var(--background);
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;

  &__container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 24px;
    padding: 24px;
    max-width: 1400px;
    margin: 0 auto;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  &__form {
    background: var(--surface);
    border-radius: var(--radius);
    box-shadow: 0 1px 3px var(--shadow);
    padding: 24px;

    > * + * {
      margin-top: 20px;
    }
  }

  .title-input {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    font-size: 16px;
    transition: all 0.2s;

    &:focus {
      border-color: var(--primary);
      box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.2);
    }
  }

  .type-buttons {
    display: flex;
    gap: 8px;
    margin: 16px 0;

    .type-button {
      padding: 12px;
      border: 1px solid var(--border);
      border-radius: var(--radius);
      background: var(--surface);
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: var(--background);
      }

      &.active {
        background: var(--primary);
        border-color: var(--primary);
        color: white;
      }
    }
  }

  .datetime-section {
    background: var(--background);
    padding: 16px;
    border-radius: var(--radius);

    .datetime-group {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .datetime-label {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--text-secondary);
      margin-bottom: 8px;
    }

    .datetime-inputs {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 12px;
    }

    .date-picker,
    .time-select {
      border: 1px solid var(--border);
      border-radius: var(--radius);
      padding: 8px;
      background: var(--surface);
    }
  }

  .quick-add {
    margin: 20px 0;

    span {
      color: var(--text-secondary);
      margin-right: 12px;
    }

    .quick-buttons {
      display: inline-flex;
      gap: 8px;
    }

    .quick-button {
      padding: 8px 16px;
      border: 1px solid var(--border);
      border-radius: var(--radius);
      background: var(--surface);
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: var(--background);
        border-color: var(--primary);
      }
    }
  }

  .section {
    margin: 16px 0;
    padding: 16px;
    background: var(--background);
    border-radius: var(--radius);

    .section-icon {
      color: var(--text-secondary);
      margin-right: 12px;
    }
  }

  .select-sections {
    .select-group {
      margin: 16px 0;
      display: flex;
      align-items: center;

      .select-input {
        flex: 1;
      }
    }
  }

  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: var(--primary);
    color: white;
    border-radius: var(--radius) var(--radius) 0 0;

    .nav-button {
      padding: 8px;
      border-radius: 50%;
      border: none;
      background: rgba(255, 255, 255, 0.1);
      color: white;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .calendar {
    border: 1px solid var(--border);
    border-radius: 0 0 var(--radius) var(--radius);
    overflow: hidden;

    // VueCal custom styles
    :deep(.vuecal__event) {
      padding: 4px 8px;
      border-radius: 4px;

      &.current {
        background: var(--primary);
        color: white;
      }
    }
  }
}

// Ensure proper CoreUI compatibility
:deep(.c-input),
:deep(.c-select) {
  border-radius: var(--radius);
  border: 1px solid var(--border);
}

.activity-widget .calendar {
  border: 1px solid var(--border);
  border-radius: 0 0 var(--radius) var(--radius);
  overflow: hidden;
  margin-bottom: 20px; /* Add margin to prevent overlap */

  :deep(.vuecal__event) {
    padding: 4px 8px;
    border-radius: 4px;

    &.current {
      background: var(--primary);
      color: white;
    }
  }
}
</style>
