<template>
  <CModal :show="show" @close="close" size="lg" title="KI-Content Generator">
    <template #header>
      <h3>KI-Content Generator</h3>
    </template>

    <template #default>
      <div class="form-group">
        <label>Überschrift</label>
        <input
          v-model="localGeneratedContent.title"
          class="form-control"
          readonly
        />
      </div>
      <div class="form-group">
        <label>Haupttext</label>
        <textarea
          v-model="localGeneratedContent.content"
          rows="4"
          class="form-control"
          readonly
        ></textarea>
      </div>
      <div class="form-group">
        <label>Bester Tag</label>
        <input
          v-model="localGeneratedContent.bestDay"
          class="form-control"
          readonly
        />
      </div>
      <div class="form-group">
        <label>Beste Zeit</label>
        <input
          v-model="localGeneratedContent.bestTime"
          class="form-control"
          readonly
        />
      </div>
      <div class="form-group">
        <label>Call to Action</label>
        <input
          v-model="localGeneratedContent.postFrequencySuggestion"
          class="form-control"
          readonly
        />
      </div>
    </template>

    <template #footer>
      <CButton color="secondary" @click="close">Schließen</CButton>
      <CButton color="primary" @click="applyChanges">Übernehmen</CButton>
      <CButton color="warning" @click="generateAlternativePost"
        >Alternative generieren</CButton
      >
    </template>
  </CModal>
</template>

<script>
import axios from "axios";
import { CModal, CButton } from '@coreui/vue';

export default {
  name: "KIPopup",
  components: {
    CModal,
    CButton
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    generatedContent: {
      type: Object,
      default: () => ({
        title: "",
        content: "",
        bestDay: "",
        bestTime: "",
        postFrequencySuggestion: "",
      }),
    },
  },
  data() {
    return {
      localGeneratedContent: {}, // Wird in 'watch' initialisiert
    };
  },
  watch: {
    show(newVal) {
    },
    generatedContent: {
      handler(newVal) {
        console.log('KIPopup content received:', newVal);
        this.localGeneratedContent = { ...newVal };
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    applyChanges() {
      this.$emit("apply", { ...this.localGeneratedContent });
    },
    generateAlternativePost() {
      this.isLoading = true;

      // Payload für Alternative-Generierung (Variation des bestehenden Inhalts)
      const payload = {
        title: this.localGeneratedContent.title,
        content: this.localGeneratedContent.content,
        bestDay: this.localGeneratedContent.bestDay,
        bestTime: this.localGeneratedContent.bestTime,
        postFrequencySuggestion: this.localGeneratedContent.postFrequencySuggestion,
        generateAlternative: true,
        keywords: this.localGeneratedContent.keywords || []
      };

      axios
          .post("/socialMedia/generateAlternative", payload)
          .then((response) => {
            const generatedContent = response.data.generatedContents[0];

            this.localGeneratedContent = {
              title: generatedContent.title,
              content: generatedContent.content,
              bestDay: generatedContent.bestDay,
              bestTime: generatedContent.bestTime,
              postFrequencySuggestion: generatedContent.postFrequencySuggestion,
              keywords: generatedContent.keywords || [],
              synonym: generatedContent.synonym || ""
            };
          })
          .catch((error) => {
            console.error("Error generating alternative content:", error);
            // Eventuell hier noch eine Fehlerbehandlung hinzufügen
            this.$toaster?.makeToast(
                "Fehler!",
                "Bei der Generierung einer Alternative ist ein Fehler aufgetreten."
            );
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  box-sizing: border-box;
}
</style>
