<template>
  <CButton
    v-if="!processing"
    class="processing-btn"
    @click="$emit('click', $event)"
    :color="idleColor"
  >
    {{ idleText }}
  </CButton>
  <CButton
    v-else
    class="processing-btn"
    disabled
    :color="processingColor || idleColor"
  >
    {{ processingText }}
  </CButton>
</template>

<script>
export default {
  name: "ProcessingButton",
  props: {
    processing: Boolean,
    processingText: {
      type: String,
      default: "Processing...",
    },
    processingColor: String,
    idleText: {
      type: String,
      default: "Click me",
    },
    idleColor: {
      type: String,
      default: "success",
    },
  },
};
</script>

<style scoped>
.processing-btn {
  margin-right: 4px;
}
</style>
