<template>
  <div class="trend-chart">
    <div v-if="loading" class="chart-loader">
      <CSpinner />
    </div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'TrendChart',

  props: {
    chartData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      chart: null
    }
  },

  watch: {
    chartData: {
      handler: 'updateChart',
      deep: true
    }
  },

  mounted() {
    this.createChart()
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  },

  methods: {
    createChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d')
      this.chart = new Chart(ctx, {
        type: 'line',
        data: this.getChartData(),
        options: this.getChartOptions()
      })
    },

    updateChart() {
      if (this.chart) {
        this.chart.data = this.getChartData()
        this.chart.update()
      }
    },

    getChartData() {
      return {
        labels: this.chartData.map(item => item.date),
        datasets: [
          {
            label: 'Gesendet',
            data: this.chartData.map(item => item.SENT || 0),
            borderColor: '#321fdb',
            backgroundColor: 'rgba(50, 31, 219, 0.1)',
            borderWidth: 2,
            fill: true
          },
          {
            label: 'Geöffnet',
            data: this.chartData.map(item => item.OPENED || 0),
            borderColor: '#2eb85c',
            backgroundColor: 'rgba(46, 184, 92, 0.1)',
            borderWidth: 2,
            fill: true
          },
          {
            label: 'Geklickt',
            data: this.chartData.map(item => item.CLICKED || 0),
            borderColor: '#f9b115',
            backgroundColor: 'rgba(249, 177, 21, 0.1)',
            borderWidth: 2,
            fill: true
          },
          {
            label: 'Fehler',
            data: this.chartData.map(item => item.FAILED || 0),
            borderColor: '#e55353',
            backgroundColor: 'rgba(229, 83, 83, 0.1)',
            borderWidth: 2,
            fill: true
          }
        ]
      }
    },

    getChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'day',
              tooltipFormat: 'll'
            },
            scaleLabel: {
              display: true,
              labelString: 'Datum'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Interaktionen'
            }
          }]
        }
      }
    }
  }
}
</script>

<style scoped>
.trend-chart {
  position: relative;
  height: 400px;
}

.chart-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
