<template>
  <LgcModule
    v-bind="$attrs"
    class="lgc-module-button"
    @delete="$emit('delete', $event)"
    @add-below="$emit('add-below', $event)"
  >
    <div class="lgc-module-button__wrapper">
      <button
        class="lgc-module-button__button"
        :class="buttonClasses"
        @focusout="handleFocusOut"
        @click.prevent.stop
        @dblclick="openSettingsModal"
        @keydown.space.prevent="buttonInsertSpace"
        @keydown.enter.prevent="buttonInsertEnter"
        type="button"
        :style="buttonStyles"
        contenteditable="true"
        :aria-label="dataButtonText"
      >
        <!-- Icon links -->
        <span v-if="dataIcon && dataIconPosition === 'left'" class="button-icon mr-2">
          <!-- Arrow Right -->
          <svg v-if="dataIcon === 'cilArrowRight'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M8.7 1.3l6 6c.2.2.3.4.3.7s-.1.5-.3.7l-6 6c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L11.6 9H1c-.6 0-1-.4-1-1s.4-1 1-1h10.6l-4.3-4.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0z"/>
          </svg>

          <!-- Arrow Left -->
          <svg v-if="dataIcon === 'cilArrowLeft'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M15 7H4.4l4.3-4.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-6 6c-.2.2-.3.4-.3.7s.1.5.3.7l6 6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L4.4 9H15c.6 0 1-.4 1-1s-.4-1-1-1z"/>
          </svg>

          <!-- Chevron Right -->
          <svg v-if="dataIcon === 'cilChevronRight'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M4.7 1.3c-.4.4-.4 1 0 1.4L10.6 8l-5.9 5.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0l6.5-6c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-6.5-6c-.4-.4-1-.4-1.4 0z"/>
          </svg>

          <!-- Chevron Left -->
          <svg v-if="dataIcon === 'cilChevronLeft'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M11.3 1.3c.4.4.4 1 0 1.4L5.4 8l5.9 5.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-6.5-6c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7l6.5-6c.4-.4 1-.4 1.4 0z"/>
          </svg>

          <!-- Plus -->
          <svg v-if="dataIcon === 'cilPlus'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"/>
          </svg>

          <!-- Check -->
          <svg v-if="dataIcon === 'cilCheck'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M13.7 4.3c-.4-.4-1-.4-1.4 0L6 10.6 3.7 8.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3 3c.2.2.4.3.7.3s.5-.1.7-.3l7-7c.4-.4.4-1 0-1.4z"/>
          </svg>
        </span>

        <!-- Button Text -->
        <span v-text="dataButtonText"></span>

        <!-- Icon rechts -->
        <span v-if="dataIcon && dataIconPosition === 'right'" class="button-icon ml-2">
          <!-- Arrow Right -->
          <svg v-if="dataIcon === 'cilArrowRight'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M8.7 1.3l6 6c.2.2.3.4.3.7s-.1.5-.3.7l-6 6c-.4.4-1 .4-1.4 0s-.4-1 0-1.4L11.6 9H1c-.6 0-1-.4-1-1s.4-1 1-1h10.6l-4.3-4.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0z"/>
          </svg>

          <!-- Arrow Left -->
          <svg v-if="dataIcon === 'cilArrowLeft'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M15 7H4.4l4.3-4.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-6 6c-.2.2-.3.4-.3.7s.1.5.3.7l6 6c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L4.4 9H15c.6 0 1-.4 1-1s-.4-1-1-1z"/>
          </svg>

          <!-- Chevron Right -->
          <svg v-if="dataIcon === 'cilChevronRight'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M4.7 1.3c-.4.4-.4 1 0 1.4L10.6 8l-5.9 5.3c-.4.4-.4 1 0 1.4s1 .4 1.4 0l6.5-6c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-6.5-6c-.4-.4-1-.4-1.4 0z"/>
          </svg>

          <!-- Chevron Left -->
          <svg v-if="dataIcon === 'cilChevronLeft'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M11.3 1.3c.4.4.4 1 0 1.4L5.4 8l5.9 5.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-6.5-6c-.2-.2-.3-.4-.3-.7s.1-.5.3-.7l6.5-6c.4-.4 1-.4 1.4 0z"/>
          </svg>

          <!-- Plus -->
          <svg v-if="dataIcon === 'cilPlus'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"/>
          </svg>

          <!-- Check -->
          <svg v-if="dataIcon === 'cilCheck'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill="currentColor" d="M13.7 4.3c-.4-.4-1-.4-1.4 0L6 10.6 3.7 8.3c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l3 3c.2.2.4.3.7.3s.5-.1.7-.3l7-7c.4-.4.4-1 0-1.4z"/>
          </svg>
        </span>
      </button>

      <button
        class="lgc-module-button__settings-button"
        @click="openSettingsModal"
        title="Einstellungen öffnen"
        type="button"
      >
        <!-- Optimized Settings Icon with better padding and alignment -->
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" class="settings-icon">
          <path fill="currentColor" d="M15.1 9.4L14 8.3c.1-.4.1-.9.1-1.3s0-.9-.1-1.3l1.1-1.1c.3-.3.4-.7.2-1.1l-1-2c-.2-.3-.6-.5-1-.4L11.9 2c-.7-.5-1.4-.9-2.3-1.2l-.3-1.5C9.2.1 9 0 8.8 0H6.3c-.2 0-.4.1-.5.3L5.5.8C4.6 1.1 3.9 1.5 3.2 2L1.8 1.1c-.4-.1-.8 0-1 .4l-1 2c-.2.3-.1.8.2 1.1l1.1 1.1c-.1.4-.1.9-.1 1.3s0 .9.1 1.3L0 9.4c-.3.3-.4.7-.2 1.1l1 2c.2.3.6.5 1 .4l1.4-.9c.7.5 1.4.9 2.3 1.2l.3 1.5c.1.2.3.3.5.3h2.5c.2 0 .4-.1.5-.3l.3-1.5c.8-.3 1.6-.7 2.3-1.2l1.4.9c.4.1.8 0 1-.4l1-2c.2-.3.1-.8-.2-1.1zM8 10c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"/>
        </svg>
      </button>
    </div>

    <!-- Modal -->
    <CModal
      title="Button bearbeiten"
      :show="modalVisible"
      :closeOnBackdrop="false"
      @update:show="handleModalVisibility"
      size="lg"
      aria-labelledby="buttonSettingsTitle"
      class="button-settings-modal"
    >
      <div class="lgc-module-button__settings">
        <CCard>
          <CCardBody>
            <CTabs :active-tab="activeTab" @update:activeTab="handleTabChange">
              <CTab title="Grundeinstellungen" active>
                <template #title>
                  <CIcon name="cil-settings" class="mr-1" />
                  Grundeinstellungen
                </template>
                <div class="py-3">
                  <div class="form-group mb-3">
                    <label for="buttonText">Button-Text</label>
                    <input
                      type="text"
                      class="form-control"
                      id="buttonText"
                      v-model.trim="dataButtonText"
                      @input="handleTextChange"
                    />
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="buttonSize">Größe</label>
                        <select
                          class="form-control"
                          id="buttonSize"
                          v-model="dataButtonSize"
                          @change="handleSizeChange"
                        >
                          <option v-for="option in buttonSizeOptions"
                                  :key="option.value"
                                  :value="option.value">
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="buttonShape">Form</label>
                        <select
                          class="form-control"
                          id="buttonShape"
                          v-model="dataButtonShape"
                          @change="handleShapeChange"
                        >
                          <option v-for="option in buttonShapeOptions"
                                  :key="option.value"
                                  :value="option.value">
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </CTab>

              <CTab title="Farben & Design">
                <template #title>
                  <CIcon name="cil-color-palette" class="mr-1" />
                  Farben & Design
                </template>
                <div class="py-3">
                  <CRow>
                    <CCol sm="6">
                      <ColorPicker
                        label="Button-Farbe"
                        v-model="dataButtonColor"
                        class="mb-3"
                      />
                    </CCol>
                    <CCol sm="6">
                      <ColorPicker
                        label="Text-Farbe"
                        v-model="dataTextColor"
                        class="mb-3"
                      />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm="6">
                      <div class="form-group">
                        <label for="buttonStyle">Stil</label>
                        <select
                          class="form-control"
                          id="buttonStyle"
                          v-model="dataButtonStyle"
                          @change="handleStyleChange"
                        >
                          <option v-for="option in buttonStyleOptions"
                                  :key="option.value"
                                  :value="option.value">
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </CCol>
                    <CCol sm="6">
                      <div class="form-group">
                        <label>Schatten</label>
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="shadowSwitch"
                            v-model="dataHasShadow"
                          />
                          <label class="custom-control-label" for="shadowSwitch">
                            {{ dataHasShadow ? 'An' : 'Aus' }}
                          </label>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CTab>
              <CTab title="Erweitert">
                <template #title>
                  <CIcon name="cil-options" class="mr-1" />
                  Erweitert
                </template>
                <div class="py-3">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="buttonBorderWidth">Rahmenbreite (px)</label>
                        <input
                          type="number"
                          class="form-control"
                          id="buttonBorderWidth"
                          v-model.number="dataBorderWidth"
                          min="0"
                          max="10"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="buttonAnimation">Animation</label>
                        <select
                          class="form-control"
                          id="buttonAnimation"
                          v-model="dataAnimation"
                        >
                          <option v-for="option in animationOptions"
                                  :key="option.value"
                                  :value="option.value">
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col">
                      <div class="form-group">
                        <label>Icon hinzufügen</label>
                        <div class="d-flex align-items-center gap-2">
                          <select
                            class="form-control mr-2"
                            v-model="dataIcon"
                          >
                            <option v-for="option in iconOptions"
                                    :key="option.value"
                                    :value="option.value">
                              {{ option.label }}
                            </option>
                          </select>
                          <select
                            class="form-control"
                            v-model="dataIconPosition"
                            :disabled="!dataIcon"
                          >
                            <option v-for="option in iconPositionOptions"
                                    :key="option.value"
                                    :value="option.value">
                              {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </div>

      <template #footer>
        <CButton
          color="secondary"
          variant="ghost"
          @click="closeModal"
          class="mr-2"
        >
          Abbrechen
        </CButton>
        <CButton
          color="primary"
          @click="saveAndClose"
        >
          Speichern
        </CButton>
      </template>
    </CModal>
  </LgcModule>
</template>

<script>
import {
  CIcon,  // Wird noch für die Modal-Tabs benötigt
  CTab,
  CTabs,
  CCard,
  CCardBody,
  CModal,
  CButton,
  CRow,
  CCol
} from '@coreui/vue'

// Nur die Icons, die wir noch für die Modal-Tabs brauchen
import {
  cilSettings,
  cilColorPalette,
  cilOptions
} from '@coreui/icons'

import LgcModule from '@/views/base/leadgenerator/lgc-modules/LgcModule'
import ColorPicker from '@/components/ColorPicker'

export default {
  name: 'LgcModuleButton',

  components: {
    LgcModule,
    ColorPicker,
    CIcon,
    CTab,
    CTabs,
    CCard,
    CCardBody,
    CModal,
    CButton,
    CRow,
    CCol
  },

  computed: {
    buttonStyles() {
      const styles = {
        backgroundColor: this.dataButtonColor,
        color: this.dataTextColor,
        borderWidth: `${this.dataBorderWidth}px`,
      };

      // Füge Schatten hinzu, wenn aktiviert
      if (this.dataHasShadow) {
        styles.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
      }

      // Wenn der Stil 'outline' ist
      if (this.dataButtonStyle === 'outline') {
        styles.backgroundColor = 'transparent';
        styles.borderColor = this.dataButtonColor;
        styles.color = this.dataButtonColor;
      }

      // Wenn der Stil 'ghost' ist
      if (this.dataButtonStyle === 'ghost') {
        styles.backgroundColor = 'transparent';
        styles.borderColor = 'transparent';
        styles.color = this.dataButtonColor;
      }

      // Wenn der Stil 'gradient' ist
      if (this.dataButtonStyle === 'gradient') {
        styles.backgroundImage = `linear-gradient(45deg, ${this.dataButtonColor}, ${this.adjustColor(this.dataButtonColor, 20)})`;
      }

      return styles;
    },

    buttonClasses() {
      return {
        [`lgc-module-button__button--${this.dataButtonSize}`]: true,
        [`lgc-module-button__button--${this.dataButtonShape}`]: true,
        'lgc-module-button__button--with-shadow': this.dataHasShadow,
        [`lgc-module-button__button--${this.dataAnimation}`]: this.dataAnimation !== 'none',
        [this.dataButtonStyle]: true
      };
    }
},

  props: {
    value: {
      type: Object,
      default: () => ({}),
      validator: function(obj) {
        return typeof obj === 'object';
      }
    },
  },

  data() {
    return {
      activeTab: 0,
      modalVisible: false,
      dataButtonText: this.value.buttonText || 'Klicken Sie hier',
      dataButtonColor: this.value.buttonColor || '#2d78a9',
      dataTextColor: this.value.textColor || '#ffffff',
      dataButtonSize: this.value.buttonSize || 'medium',
      dataButtonShape: this.value.buttonShape || 'rounded',
      dataButtonStyle: this.value.buttonStyle || 'filled',
      dataHasShadow: this.value.hasShadow !== undefined ? this.value.hasShadow : true,
      dataBorderWidth: this.value.borderWidth || 1,
      dataAnimation: this.value.animation || 'none',
      dataIcon: this.value.icon || '',
      dataIconPosition: this.value.iconPosition || 'right',
      icons: {
        cilSettings,
        cilColorPalette,
        cilOptions
      },
      buttonSizeOptions: [
        { value: 'small', label: 'Klein' },
        { value: 'medium', label: 'Mittel' },
        { value: 'large', label: 'Groß' }
      ],
      buttonShapeOptions: [
        { value: 'rounded', label: 'Abgerundet' },
        { value: 'pill', label: 'Pill' },
        { value: 'square', label: 'Eckig' }
      ],
      buttonStyleOptions: [
        { value: 'filled', label: 'Gefüllt' },
        { value: 'outline', label: 'Outline' },
        { value: 'ghost', label: 'Ghost' },
        { value: 'gradient', label: 'Gradient' }
      ],
      animationOptions: [
        { value: 'none', label: 'Keine' },
        { value: 'scale', label: 'Skalieren' },
        { value: 'slide', label: 'Slide' },
        { value: 'pulse', label: 'Pulsieren' }
      ],
      iconOptions: [
        { value: '', label: 'Kein Icon' },
        { value: 'cilArrowRight', label: 'Pfeil rechts' },
        { value: 'cilArrowLeft', label: 'Pfeil links' },
        { value: 'cilChevronRight', label: 'Chevron rechts' },
        { value: 'cilChevronLeft', label: 'Chevron links' },
        { value: 'cilPlus', label: 'Plus' },
        { value: 'cilCheck', label: 'Häkchen' }
      ],
      iconPositionOptions: [
        { value: 'left', label: 'Links' },
        { value: 'right', label: 'Rechts' }
      ]
    }
  },

  methods: {
    handleTabChange(tabIndex) {
      this.activeTab = Number(tabIndex);
    },

    handleModalVisibility(visible) {
      if (!visible) {
        this.closeModal();
      }
    },

    openSettingsModal() {
      this.$emit('modal-open');
      this.modalVisible = true;
      this.activeTab = 0;
    },

    closeModal() {
      this.$emit('modal-close');
      this.modalVisible = false;
    },

    saveAndClose() {
      this.emitInput();
      this.closeModal();
    },

    handleTextChange() {
      this.emitInput();
    },

    handleSizeChange() {
      this.emitInput();
    },

    handleShapeChange() {
      this.emitInput();
    },

    handleStyleChange() {
      this.emitInput();
    },

    buttonInsertSpace() {
      document.execCommand('insertText', false, ' ');
    },

    buttonInsertEnter() {
      document.execCommand('insertText', false, '\n');
    },

    handleFocusOut(event) {
      this.dataButtonText = event.target.innerText;
      this.emitInput();
    },

    emitInput() {
      this.$emit('input', {
        buttonText: this.dataButtonText,
        buttonColor: this.dataButtonColor,
        textColor: this.dataTextColor,
        buttonSize: this.dataButtonSize,
        buttonShape: this.dataButtonShape,
        buttonStyle: this.dataButtonStyle,
        hasShadow: this.dataHasShadow,
        borderWidth: this.dataBorderWidth,
        animation: this.dataAnimation,
        icon: this.dataIcon,
        iconPosition: this.dataIconPosition
      });
    },
    adjustColor(color, amount) {
      return color.replace(/^#/, '').match(/.{2}/g).map(hex => {
        const val = Math.min(255, Math.max(0, parseInt(hex, 16) + amount));
        return val.toString(16).padStart(2, '0');
      }).join('');
    }
  },

  watch: {
    dataButtonColor: {
      handler() {
        this.emitInput();
      }
    },
    dataHasShadow: {
      handler() {
        this.emitInput();
      }
    },
    dataBorderWidth: {
      handler() {
        this.emitInput();
      }
    },
    dataAnimation: {
      handler() {
        this.emitInput();
      }
    },
    dataIcon: {
      handler() {
        this.emitInput();
      }
    },
    dataIconPosition: {
      handler() {
        this.emitInput();
      }
    },
    'value.buttonText'(newVal) {
      if (newVal !== this.dataButtonText) {
        this.dataButtonText = newVal;
      }
    },
    'value.buttonColor'(newVal) {
      if (newVal !== this.dataButtonColor) {
        this.dataButtonColor = newVal;
      }
    }
  }
}
</script>

<style lang="scss">
.lgc-module-button {
  --button-primary-color: #2d78a9;
  --button-hover-color: #226694;
  --button-border-color: #1c6ca1;
  --button-text-color: #ffffff;
  --button-shadow-color: rgba(0, 0, 0, 0.1);
  --button-ghost-hover-bg: rgba(45, 120, 169, 0.1);

  &__wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-style: solid;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease;
    overflow: hidden;

    // Button Größen
    &--small {
      font-size: 14px;
      padding: 6px 12px;
      gap: 6px;
    }

    &--medium {
      font-size: 16px;
      padding: 8px 20px;
      gap: 8px;
    }

    &--large {
      font-size: 18px;
      padding: 12px 28px;
      gap: 10px;
    }

    // Button Formen
    &--rounded {
      border-radius: 6px;
    }

    &--pill {
      border-radius: 50px;
    }

    &--square {
      border-radius: 0;
    }

    // Button Stile
    &.ghost {
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: var(--button-ghost-hover-bg);
      }
    }

    &.outline {
      &:hover {
        color: var(--button-text-color);
      }
    }

    // Schatten
    &--with-shadow {
      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px var(--button-shadow-color);
      }
    }

    // Animationen
    &--scale {
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.95);
      }
    }

    &--slide {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transition: left 0.3s ease;
      }

      &:hover::after {
        left: 100%;
      }
    }

    &--pulse {
      &:hover {
        animation: button-pulse 1.5s infinite;
      }
    }

    // Icon Positionierung
    .button-icon {
      &--left {
        order: -1;
      }

      &--right {
        order: 1;
      }
    }

    // Hover Effekte
    &:hover {
      background-color: var(--button-hover-color);
      border-color: var(--button-hover-color);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba(45, 120, 169, 0.3);
    }

    &:active {
      transform: translateY(1px);
    }
  }

  &__settings-button {
    padding: 8px;  // Increased padding
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;  // Added for better icon alignment
    align-items: center;
    justify-content: center;
    margin-left: 8px;  // Added spacing from main button

    .settings-icon {
      display: block;  // Prevents icon alignment issues
      color: #6c757d;  // Softer color for better visual harmony
    }

    &:hover {
      background-color: #f8f9fa;
      border-color: #cbd3d9;

      .settings-icon {
        color: #495057;  // Darker on hover
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(45, 120, 169, 0.2);
    }
  }

  // Enhanced Modal Styling
  .button-settings-modal {
    .modal-content {
      max-height: 90vh;  // Prevents modal from being too tall
      overflow-y: auto;  // Adds scrolling when needed

      .card-body {
        padding: 1.5rem;
      }

      .form-group {
        margin-bottom: 1.5rem;

        label {
          display: block;
          margin-bottom: 0.5rem;
          color: #495057;
        }

        .form-control {
          min-height: 38px;  // Ensures consistent height
          padding: 0.375rem 0.75rem;
        }

        // Fix for text truncation
        input[type="text"],
        select {
          width: 100%;
          max-width: 100%;
          height: auto;
          white-space: normal;  // Allows text to wrap
          overflow: visible;    // Prevents text cutoff
        }
      }

      // Improved tab styling
      .nav-tabs {
        padding: 1rem 1rem 0;
        margin-bottom: 0;
        border-bottom: 1px solid #dee2e6;

        .nav-link {
          padding: 0.75rem 1rem;
          margin-bottom: -1px;

          &.active {
            font-weight: 500;
          }
        }
      }

      // Better spacing for tab content
      .tab-content {
        padding: 1.5rem;

        > .tab-pane {
          margin-bottom: 1rem;
        }
      }
    }
  }


  &__settings {
    padding: 1.5rem;

    .color-picker-wrapper {
      margin-bottom: 1rem;
    }
  }

  // Animationen
  @keyframes button-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  // Responsive Design
  @media (max-width: 768px) {
    &__wrapper {
      width: 100%;
    }

    &__button {
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    &__settings {
      padding: 1rem;
    }
  }
}

// Modal Styling
.modal-lg .modal-content {
  .nav-tabs {
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #dee2e6;
  }

  .tab-content {
    > .tab-pane {
      display: none;
      &.active {
        display: block;
      }
    }
    > .active {
      display: block;
    }
  }

  .fade {
    &:not(.show) {
      opacity: 0;
    }
    &.show {
      opacity: 1;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
  }
}
</style>

