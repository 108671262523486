import { render, staticRenderFns } from "./MailgunDnsEntries.vue?vue&type=template&id=9acfe630"
import script from "./MailgunDnsEntries.vue?vue&type=script&lang=js"
export * from "./MailgunDnsEntries.vue?vue&type=script&lang=js"
import style0 from "./MailgunDnsEntries.vue?vue&type=style&index=0&id=9acfe630&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports