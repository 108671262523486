<template>
  <form ref="newsletterSubscriber">
    <!-- Anrede und E-Mail -->
    <CRow class="mb-3">
      <CCol sm="3">
        <CSelect
            label="Anrede"
            v-model="salutation"
            :options="salutationOptions"
            description="Bitte wählen Sie eine Anrede"
        />
      </CCol>
      <CCol sm="9">
        <CInput
            label="E-Mail"
            name="email"
            v-model="email"
            description="Bitte geben Sie die E-Mail Adresse des Abonnenten ein."
            required
        />
      </CCol>
    </CRow>

    <!-- Name -->
    <CRow class="mb-3">
      <CCol sm="6">
        <CInput
            label="Vorname"
            name="firstName"
            v-model="firstName"
            description="Bitte geben Sie den Vornamen des Abonnenten ein."
            required
        />
      </CCol>
      <CCol sm="6">
        <CInput
            label="Nachname"
            name="lastName"
            v-model="lastName"
            description="Bitte geben Sie den Nachnamen des Abonnenten ein."
            required
        />
      </CCol>
    </CRow>

    <!-- Firma -->
    <CRow class="mb-3">
      <CCol sm="12">
        <CInput
            label="Firma"
            name="company"
            v-model="company"
            description="Firmenname des Abonnenten (optional)"
        />
      </CCol>
    </CRow>

    <!-- Gruppen und Verifizierung -->
    <CRow class="mb-3">
      <CCol sm="8">
        <NewsletterGroupSelector
            v-model="groups"
            description="Hier können Sie die Empfängergruppe(n) auswählen, die diesem Abonnenten zugewiesen werden soll(en). Wichtig: Wenn keine Empfängergruppe ausgewählt ist, bekommen dieser Abonnent alle Newsletter/Funnels die Sie verfassen."
        />
      </CCol>
      <CCol sm="4">
        <label for="verified">Abonnent verifiziert?</label>
        <CInputCheckbox
            label="Verifiziert?"
            id="verified"
            name="verified"
            value="Ja"
            :custom="true"
            :checked.sync="verified"
            :inline="false"
        />
        <small class="form-text text-muted w-100">
          Nur verifizierte Abonnenten (E-Mail Adresse bestätigt) erhalten E-Mails.
        </small>
      </CCol>
    </CRow>

    <!-- Buttons -->
    <CRow>
      <CCol>
        <CButton v-if="!processing" @click="clickHandler" color="success">
          {{ btnText }}
        </CButton>
        <CButton v-else color="primary">Processing...</CButton>
        <CButton
            v-if="enableDelete"
            @click="deleteHandler"
            color="danger"
            style="margin-left: 4px"
        >
          Löschen
        </CButton>
      </CCol>
    </CRow>
  </form>
</template>

<script>
import NewsletterGroupSelector from "@/components/NewsletterGroupSelector";

export default {
  name: "NewsletterSubscriberForm",
  components: {
    NewsletterGroupSelector,
  },
  props: {
    passedData: {
      type: Object,
      default: () => ({ id: 0 }),
    },
    btnText: {
      type: String,
      required: true,
    },
    enableDelete: Boolean,
    processing: Boolean,
  },
  data() {
    return {
      id: this.passedData.id || 0,
      realmId: (this.passedData.realm || {}).id || this.passedData.realmId || 1,
      firstName: this.passedData.firstName || "",
      lastName: this.passedData.lastName || "",
      email: this.passedData.email || "",
      company: this.passedData.company || "",
      salutation: this.passedData.salutation || "",
      verified:
          typeof this.passedData.verified === "boolean"
              ? this.passedData.verified
              : true,
      groups: this.passedData.groups || [],
      salutationOptions: [
        { value: "", label: "Bitte wählen" },
        { value: "Herr", label: "Herr" },
        { value: "Frau", label: "Frau" },
        { value: "Divers", label: "Divers" }
      ]
    };
  },
  methods: {
    clickHandler() {
      if (this.$refs.newsletterSubscriber.checkValidity()) {
        const subscriberData = {
          ...this.$data,
          groups: this.groups.map((group) => group.id)
        };
        this.$emit("submit", subscriberData);
      } else {
        this.$refs.newsletterSubscriber.reportValidity();
      }
    },
    deleteHandler() {
      this.$modal.showModal(
          "delete",
          null,
          [`<b>NewsletterSubscriber</b> (${this.passedData.email})`],
          () => {
            this.$emit("delete");
          }
      );
    },
  },
};
</script>
