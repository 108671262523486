<template>
  <div>
    <CBreadcrumbRouter class="border-0 mb-0" />
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <div class="d-flex justify-content-between align-items-center">
              <h4>
                <CIcon name="cil-bullhorn" />
                Kampagnen
              </h4>
              <CButton @click="openCreateCampaignModal" color="primary" size="sm">
                <CIcon name="cil-plus" />
                Neue Kampagne
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <div v-if="loading" class="text-center my-3">
              <CSpinner size="sm" color="primary" />
              <p>Lade Kampagnen...</p>
            </div>
            <div v-else>
              <CDataTable
                  :items="campaigns"
                  :fields="getCampaignFields()"
                  hover
                  striped
                  bordered
                  responsive
                  pagination
                  :items-per-page="10"
              >
                <!-- Created Field -->
                <template #created="{ item }">
                  <td>{{ formatDate(item.created) }}</td>
                </template>

                <!-- Actions Field -->
                <template #actions="{ item }">
                  <td>
                    <CButtonGroup>
                      <CButton
                          @click="openEditCampaignModal(item)"
                          color="info"
                          size="sm"
                          class="mr-2"
                      >
                        <CIcon name="cil-pencil" />
                      </CButton>
                      <CButton
                          @click="deleteCampaign(item)"
                          color="danger"
                          size="sm"
                          :disabled="deleting"
                      >
                        <CSpinner v-if="deleting" size="sm" color="white" />
                        <span v-else>Löschen</span>
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>

              </CDataTable>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal für Kampagnen -->
    <CModal
        :title="isEditMode ? 'Kampagne bearbeiten' : 'Kampagne erstellen'"
        :show.sync="showCampaignModal"
        size="lg"
    >
      <CInput
          label="Name"
          v-model="editedCampaign.name"
          description="Geben Sie einen Namen für die Kampagne ein."
          :disabled="saving"
      />
      <small v-if="duplicateError" class="text-danger">
        Eine Kampagne mit diesem Namen existiert bereits.
      </small>
      <div>
        <label for="search-groups">Empfängergruppen (Gruppe der Abonnenten)</label>
        <input
            id="search-groups"
            type="text"
            class="form-control mb-2"
            v-model="groupSearch"
            placeholder="Empfängergruppen suchen..."
        />
        <div class="custom-dropdown">
          <div
              v-for="option in filteredGroupOptions"
              :key="option.value"
              class="custom-option"
              :class="{ 'highlight-assigned': option.assigned }"
              @click="toggleOption(option.value)"
              tabindex="0"
              @keyup="toggleOptionWithKeyboard($event, option.value)"
          >
            {{ option.text }}
          </div>
        </div>
        <small class="form-text text-muted w-100">
          {{ selectedGroupCountText }}
        </small>
      </div>

      <div slot="footer">
        <CButton color="secondary" @click="showCampaignModal = false" :disabled="saving">
          Abbrechen
        </CButton>
        <CButton color="primary" @click="saveCampaign" :disabled="saving">
          <CSpinner v-if="saving" size="sm" color="white" />
          <span v-else>Speichern</span>
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import { CBreadcrumbRouter, CButtonGroup } from "@coreui/vue";
import CSelectWrapper from '@/components/CSelectWrapper.vue';

export default {

  name: "Campaigns",
  components: {
    CBreadcrumbRouter,
    CButtonGroup,
    CSelectWrapper,
  },
  data() {
    return {
      duplicateError: false,
      loading: false,
      saving: false,
      deleting: false,
      campaigns: [],
      groupSearch: "", // Suchfeld-Text
      showCampaignModal: false,
      isEditMode: false,
      editedCampaign: {
        name: "",
        groupIds: [], // Sicherstellen, dass dies ein Array ist
      },
      groupOptions: [], // Standardwert setzen
    };
  },
  computed: {
    selectedGroupCountText() {
      const count = this.editedCampaign.groupIds.length;
      return count === 0
          ? "Weisen Sie Empfängergruppen Ihrer Kampagne zu."
          : `${count} Empfängergruppen ausgewählt`;
    },
    filteredGroupOptions() {
      // Filtere Optionen basierend auf dem Suchfeld
      const search = this.groupSearch.toLowerCase();
      return this.highlightedGroupOptions.filter(option =>
          option.text.toLowerCase().includes(search)
      );
    },
    processedValue: {
      get() {
        return Array.isArray(this.value) ? this.value : []; // Immer ein Array zurückgeben
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    highlightedGroupOptions() {
      if (!Array.isArray(this.editedCampaign.groupIds)) {
        console.error("groupIds ist kein Array:", this.editedCampaign.groupIds);
        return this.groupOptions;
      }

      return this.groupOptions.map((option) => {
        const isAssigned = this.editedCampaign.groupIds.includes(option.value);
        return {
          ...option,
          assigned: isAssigned, // Markiere als zugewiesen
          class: isAssigned ? "highlight-assigned" : "", // Klasse für Styling
        };
      });
    },
    },
  mounted() {
    this.loadCampaigns();
    this.loadGroupOptions();
    this.$nextTick(() => {
      const selectElement = document.getElementById("uid-wktaz2jffh"); // ID des Selects
      if (selectElement) {
        Array.from(selectElement.options).forEach((option) => {
          if (this.editedCampaign.groupIds.includes(option.value)) {
            option.style.backgroundColor = "#e6ffe6"; // Setze Hintergrundfarbe
            option.style.fontWeight = "bold"; // Fette Schrift
            option.style.color = "#28a745"; // Schriftfarbe
          }
        });
      }
    });
  },
  methods: {
    toggleOption(optionValue) {
      // Fügt die Gruppe hinzu oder entfernt sie
      const index = this.editedCampaign.groupIds.indexOf(optionValue);
      if (index === -1) {
        this.editedCampaign.groupIds.push(optionValue);
      } else {
        this.editedCampaign.groupIds.splice(index, 1);
      }
    },
    toggleOptionWithKeyboard(event, optionValue) {
      if (event.key === "Enter" || event.key === " ") {
        this.toggleOption(optionValue);
      }
    },
    onSelect(event) {
      if (this.$attrs.multiple !== undefined) {
        const selectedValues = Array.from(event.target.selectedOptions).map(option => option.value);
        this.$emit('input', selectedValues); // Sicherstellen, dass ein Array zurückgegeben wird
      } else {
        this.$emit('input', event.target.value); // Einzelwert zurückgeben
      }
    },
    handleError(err) {
      console.error("API Fehler:", err);
      this.$toaster.makeToast("Fehler", "Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.");
    },
    loadCampaigns() {
      this.loading = true;
      axios.get("/campaigns")
          .then((response) => {
            this.campaigns = response.data.map((campaign) => ({
              ...campaign,
              groupIds: Array.isArray(campaign.groups)
                  ? campaign.groups.map((group) => group.id)
                  : [], // Sicherstellen, dass es ein Array ist
              groupNames: campaign.groups.map((group) => group.name).join(", "),
            }));
          })
          .catch(this.handleError)
          .finally(() => {
            this.loading = false;
          });
    },
    loadGroupOptions() {
      axios.get("/newsletter/group")
          .then((response) => {
            const uniqueGroups = response.data.reduce((acc, group) => {
              if (!acc.some((item) => item.value === group.id)) {
                acc.push({ value: group.id, text: group.name });
              }
              return acc;
            }, []);
            this.groupOptions = uniqueGroups;
          })
          .catch(this.handleError);
    },
    openCreateCampaignModal() {
      this.isEditMode = false;
      this.editedCampaign = {
        name: "",
        groupIds: [], // Sicherstellen, dass es ein Array ist
      };
      this.showCampaignModal = true;
    },

    openEditCampaignModal(item) {
      this.isEditMode = true;
      this.editedCampaign = {
        id: item.id,
        name: item.name,
        groupIds: Array.isArray(item.groupIds) ? [...item.groupIds] : [],
      };
      this.showCampaignModal = true;
    },
    saveCampaign() {
      this.saving = true;
      this.duplicateError = false; // Setze den Fehlerzustand zurück

      const payload = {
        name: this.editedCampaign.name.trim(),
        groupIds: this.editedCampaign.groupIds,
      };

      const request = this.editedCampaign.id
          ? axios.put(`/campaigns/${this.editedCampaign.id}`, payload, {
            headers: { "Content-Type": "application/json" },
          })
          : axios.post("/campaigns", payload, {
            headers: { "Content-Type": "application/json" },
          });

      request
          .then(() => {
            this.$toaster.makeToast(
                "Erfolg",
                "Die Kampagne wurde erfolgreich gespeichert."
            );
            this.loadCampaigns(); // Aktualisiere die Kampagnen-Liste
            this.showCampaignModal = false; // Schließe das Modal
          })
          .catch((err) => {
            console.error("Error saving campaign:", err);
            this.duplicateError = false; // Fehlerzustand zurücksetzen bevor neue Fehlermeldungen behandelt werden

            if (err.response) {
              const errorMessage =
                  err.response.data.message || "Fehler beim Speichern.";

              if (
                  err.response.status === 400 &&
                  errorMessage.includes("existiert bereits")
              ) {
                this.duplicateError = true;
                this.$toaster.makeToast(
                    "Fehler",
                    "Eine Kampagne mit diesem Namen existiert bereits in diesem Realm. Bitte wählen Sie einen anderen Namen."
                );
              } else {
                this.$toaster.makeToast("Fehler", errorMessage);
              }
            } else {
              this.$toaster.makeToast(
                  "Fehler",
                  "Kampagne konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Verbindung."
              );
            }
          })
          .finally(() => {
            this.saving = false;
          });
    },
    deleteCampaign(item) {
      this.deleting = true;
      axios.delete(`/campaigns/${item.id}`)
          .then(this.loadCampaigns)
          .catch(this.handleError)
          .finally(() => {
            this.deleting = false;
          });
    },
    getCampaignFields() {
      return [
        { key: "name", label: "Name" },
        { key: "groupNames", label: "Empfängergruppen" },
        { key: "created", label: "Erstellt am" },
        { key: "actions", label: "Aktionen" },
      ];
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("de-DE");
    },
  },
};
</script>

<style>
.custom-dropdown {
  max-height: 200px; /* Begrenze die Höhe der Liste */
  overflow-y: auto; /* Scrollbar für lange Listen */
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.custom-option {
  padding: 10px;
  margin: 2px 0;
  border-radius: 4px;
  font-weight: normal;
  color: #333; /* Dunklere Schriftfarbe */
  background-color: #fff; /* Standardfarbe */
  cursor: pointer; /* Zeigt, dass es anklickbar ist */
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.custom-option:hover {
  background-color: #f1f1f1; /* Hover-Effekt für interaktive Elemente */
  color: #000; /* Deutlichere Farbe beim Hover */
}

.custom-option.highlight-assigned {
  background-color: #e6ffe6; /* Grün für ausgewählte Empfängergruppen */
  color: #28a745; /* Grüne Schriftfarbe für ausgewählte Elemente */
  font-weight: bold;
}

.highlight-assigned {
  background-color: #e6ffe6 !important;
  font-weight: bold;
  color: #28a745;
}
</style>
