<template>
  <div>
    <CTabs>
      <CTab
        title="Stock-Fotos auswählen"
        :active="activeTab === 'stock'"
        @click="activeTab = 'stock'"
      >
        <div class="search-section">
          <label for="image-search" class="search-label">{{
              searchLabel
            }}</label>
          <div class="search-bar">
            <input
              type="text"
              id="image-search"
              v-model="searchQuery"
              placeholder="Suchbegriff eingeben"
              class="search-input"
            />
            <button type="button" @click="searchImages" class="search-btn">
              Bilder suchen
            </button>
          </div>
          <div v-if="searchResults.length" class="search-results">
            <div
              v-for="(image, index) in searchResults"
              :key="index"
              :class="{ selected: selectedImage === image.urls.small }"
              class="search-result"
            >
              <div class="image-container">
                <img
                  :src="image.urls.small"
                  :alt="image.description"
                  class="result-image"
                />
                <div class="orientation-label">
                  <CIcon
                    :name="
                      image.orientation === 'landscape'
                        ? 'cilMobileLandscape'
                        : 'cilMobile'
                    "
                  />
                  <span>{{
                      image.orientation === "landscape"
                        ? "Landschaft"
                        : "Portrait"
                    }}</span>
                </div>
                <div class="photo-attribution">
                  <span>Foto von </span>
                  <a
                    :href="'https://unsplash.com/@' + image.user.username + utmParams"
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{ image.user.name }}</a>
                  <span> auf </span>
                  <a
                    :href="'https://unsplash.com/' + utmParams"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Unsplash</a>
                </div>
              </div>
              <button
                type="button"
                @click="selectImage(image)"
                :class="{ 'selected-btn': selectedImage === image.urls.small }"
                class="select-btn"
              >
                {{ selectedImage === image.urls.small ? "Ausgewählt" : "Auswählen" }}
              </button>
            </div>
          </div>
        </div>
      </CTab>
      <CTab
        title="Bild hochladen"
        :active="activeTab === 'upload'"
        @click="activeTab = 'upload'"
      >
        <div class="upload-section">
          <label for="image-upload" class="upload-label">{{
              uploadLabel
            }}</label>
          <div
            class="drag-and-drop-area"
            @dragover.prevent
            @drop.prevent="handleDrop"
          >
            <p>Drag & Drop ein Bild hier oder</p>
            <label class="custom-file-upload">
              <input
                type="file"
                id="image-upload"
                @change="handleImageUpload"
                class="upload-input"
              />
              Bild hochladen
            </label>
          </div>
          <div v-if="uploadedImage || selectedImage" class="image-preview">
            <img :src="uploadedImage || selectedImage" alt="Selected Image" />
            <div class="image-actions">
              <CButton @click="removeImage" color="danger" class="remove-btn"
              >Bild entfernen</CButton
              >
              <label class="custom-file-upload">
                <input
                  type="file"
                  id="image-upload"
                  @change="handleImageUpload"
                  class="upload-input"
                />
                <CButton color="primary" class="change-btn"
                >Bild ändern</CButton
                >
              </label>
            </div>
          </div>
          <small
            class="form-text text-muted mt-2"
            v-if="!uploadedImage && !selectedImage"
          >Bild hochladen und Vorschau anzeigen</small
          >
        </div>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import axios from "axios";
import { CTabs, CTab, CIcon } from "@coreui/vue";

export default {
  name: "ImageUploadAndSearch",
  components: {
    CTabs,
    CTab,
    CIcon,
  },
  props: {
    uploadLabel: String,
    searchLabel: String,
    firstKeyword: String,
    initialImage: String,
  },
  data() {
    return {
      activeTab: "stock",
      uploadedImage: null,
      searchQuery: this.firstKeyword || "",
      searchResults: [],
      selectedImage: null,
      currentPage: 1,
      photosPerPage: 10,
      utmParams: '?utm_source=LeadMeUp&utm_medium=referral'
    };
  },
  watch: {
    firstKeyword(newKeyword) {
      this.searchQuery = newKeyword;
      this.searchImages();
    },
    initialImage(newImage) {
      this.setImage(newImage);
    },
  },
  mounted() {
    this.setImage(this.initialImage);
  },
  methods: {
    setImage(image) {
      if (image) {
        this.uploadedImage = image;
        this.selectedImage = null;
      }
    },
    async handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await axios.post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          this.uploadedImage = response.data.fileDownloadUrl;
          this.$emit("image-upload", this.uploadedImage);
          this.selectedImage = null;
        } catch (error) {
          console.error("Error uploading image:", error);
          alert(
            "Fehler beim Hochladen des Bildes. Bitte versuchen Sie es erneut.",
          );
        }
      }
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.uploadedImage = response.data.fileDownloadUrl;
            this.$emit("image-upload", this.uploadedImage);
            this.selectedImage = null;
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            alert(
              "Fehler beim Hochladen des Bildes. Bitte versuchen Sie es erneut.",
            );
          });
      }
    },
    removeImage() {
      this.uploadedImage = null;
      this.selectedImage = null;
      this.$emit("image-remove");
    },
    async searchImages() {
      if (this.searchQuery) {
        try {
          const response = await axios.get(`/api/unsplash/photos`, {
            params: {
              query: this.searchQuery,
              page: this.currentPage,
              per_page: this.photosPerPage,
            },
          });

          this.searchResults = response.data.results.map((photo) => ({
            ...photo,
            orientation: photo.width > photo.height ? "landscape" : "portrait",
          }));
        } catch (error) {
          console.error("Error fetching images:", error);
          alert(
            "Fehler beim Abrufen der Bilder. Bitte versuchen Sie es erneut.",
          );
        }
      }
    },
    selectImage(image) {
      this.selectedImage = image.urls.small;
      this.uploadedImage = null;

      // Download tracking
      if (image.links?.download_location) {
        axios.get(`/api/unsplash/track-download?url=${encodeURIComponent(image.links.download_location)}`)
          .catch(error => console.error('Download tracking failed:', error));
      }

      // Emit direkt die URL und füge die Attribution separat hinzu
      this.$emit("image-select", image.urls.small);

      // Füge die Attribution zum Beitragstext hinzu über ein separates Event
      const attribution = `\n\n📸: ${image.user.name} auf Unsplash`;
      this.$emit("add-attribution", attribution);
    },
  },
};
</script>

<style scoped>
.image-upload-and-search {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.upload-section,
.search-section {
  display: flex;
  flex-direction: column;
}

.upload-label,
.search-label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
  color: #3c4b64;
}

.drag-and-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #007bff;
  border-radius: 4px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.drag-and-drop-area:hover {
  background-color: #e1e1e1;
}

.upload-input {
  display: none;
}

.upload-section .image-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
}

.image-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.remove-btn,
.custom-file-upload {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
  text-align: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-btn {
  background-color: #dc3545;
  color: white;
}

.remove-btn:hover {
  background-color: #c82333;
}

.custom-file-upload {
  background-color: #007bff;
  color: white;
  display: inline-block;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

.custom-file-upload input {
  display: none;
}

.search-bar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.search-btn:hover {
  background-color: #0056b3;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.search-result.selected {
  border: 2px solid #007bff;
}

.search-result:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.image-container {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}

.result-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.orientation-label {
  position: absolute;
  bottom: 30px; /* Angepasst wegen photo-attribution */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 5px;
  gap: 5px;
}

.photo-attribution {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  font-size: 12px;
  text-align: center;
}

.photo-attribution a {
  color: white;
  text-decoration: underline;
}

.photo-attribution a:hover {
  color: #ddd;
}

.orientation-label CIcon {
  margin-right: 4px;
}

.select-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.select-btn.selected-btn {
  background-color: #007bff;
}

.select-btn:hover {
  background-color: #218838;
}

.select-btn.selected-btn:hover {
  background-color: #0056b3;
}
</style>
