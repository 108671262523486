<template>
  <div class="chatbot-manager">
    <CCard>
      <CCardBody>
        <h1 class="mb-4">Chatbot Manager</h1>
        <CRow>
          <!-- Sidebar mit Chatbot-Liste -->
          <CCol md="3">
            <CCard>
              <CCardBody>
                <div
                  class="d-flex justify-content-between align-items-center mb-3"
                >
                  <h4 class="mb-0">Chatbots</h4>
                  <CButton
                    color="primary"
                    size="sm"
                    @click="openCreateBotModal"
                  >
                    <CIcon name="cil-plus" />
                    Neuer Bot
                  </CButton>
                </div>
                <CListGroup>
                  <CListGroupItem
                    v-for="bot in chatbots"
                    :key="bot.id"
                    :active="selectedBot && selectedBot.id === bot.id"
                    @click="selectBot(bot)"
                    class="d-flex justify-content-between align-items-center"
                  >
                    {{ bot.name }}
                    <CBadge :color="getBotStatusColor(bot.status)" shape="pill">
                      {{ bot.status }}
                    </CBadge>
                  </CListGroupItem>
                </CListGroup>
              </CCardBody>
            </CCard>
          </CCol>

          <!-- Hauptbereich -->
          <CCol md="9">
            <CCard v-if="selectedBot" :key="selectedBot.id">
              <CCardBody>
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <h2>{{ selectedBot.name }}</h2>
                  <CButton color="danger" size="sm" @click="confirmDeleteBot">
                    <CIcon name="cil-trash" />
                    Bot löschen
                  </CButton>
                </div>
                <CTabs>
                  <CTab title="Übersicht" active>
                    <CCard>
                      <CCardBody>
                        <h4 class="mb-3">Bot Informationen</h4>
                        <CRow>
                          <CCol sm="4">
                            <strong>Erstellt:</strong>
                            {{ formatDate(selectedBot.createdAt) }}
                          </CCol>
                          <CCol sm="4">
                            <strong>Letztes Update:</strong>
                            {{ formatDate(selectedBot.updatedAt) }}
                          </CCol>
                          <CCol sm="4">
                            <strong>Status:</strong>
                            <CBadge
                              :color="getBotStatusColor(selectedBot.status)"
                            >
                              {{ selectedBot.status }}
                            </CBadge>
                          </CCol>
                        </CRow>
                        <CRow class="mt-4">
                          <CCol sm="12">
                            <h5>Webseiten URL</h5>
                            <p>{{ selectedBot.website }}</p>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>

                  <CTab title="Training" :active="activeTab === 'Training'">
                    <CCard>
                      <CCardBody>
                        <h5 class="mb-3">Trainierte Seiten</h5>

                        <!-- Ladezustand anzeigen -->
                        <div v-if="isLoadingPages">
                          <p>Seiten werden geladen...</p>
                        </div>

                        <!-- Fortschrittsanzeige -->
                        <div
                          v-if="scrapingProgress > 0 && scrapingProgress < 100"
                        >
                          <CProgress :value="scrapingProgress" />
                          <p>{{ scrapingProgress }}% abgeschlossen</p>
                        </div>

                        <!-- Tabelle der trainierten Seiten -->
                        <div v-else>
                          <CDataTable
                            :items="selectedBot.pages"
                            :fields="[
                              { key: 'url', label: 'URL' },
                              { key: 'embeddingExists', label: 'Status' },
                            ]"
                            hover
                            striped
                            responsive
                            small
                          >
                            <template #embeddingExists="{ item }">
                              <CBadge
                                :color="
                                  item.embeddingExists ? 'success' : 'danger'
                                "
                              >
                                {{
                                  item.embeddingExists
                                    ? "Trainiert"
                                    : "Untrainiert"
                                }}
                              </CBadge>
                            </template>
                          </CDataTable>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CTab>

                  <CTab title="Einstellungen">
                    <CCard>
                      <CCardBody>
                        <h4 class="mb-3">Bot Einstellungen</h4>
                        <CForm>
                          <CInput
                            v-model="selectedBot.name"
                            label="Bot Name"
                            class="mb-3"
                          />
                          <CInput
                            v-model="selectedBot.websiteUrl"
                            label="Webseiten URL"
                            class="mb-3"
                          />
                          <CButton color="primary" @click="updateBotSettings">
                            Einstellungen speichern
                          </CButton>
                        </CForm>
                      </CCardBody>
                    </CCard>
                  </CTab>

                  <CTab title="Integrationen">
                    <CCard>
                      <CCardBody>
                        <h4 class="mb-3">Integrationsmöglichkeiten</h4>
                        <CRow>
                          <CCol md="6">
                            <h5>Iframe einbetten</h5>
                            <div v-html="decodeHtml(iframeCode)"></div>
                            <CButton
                              color="secondary"
                              size="sm"
                              class="mt-2"
                              @click="copyToClipboard(decodeHtml(iframeCode))"
                            >
                              Iframe-Code kopieren
                            </CButton>
                          </CCol>
                          <CCol md="6">
                            <h5>Widget Script</h5>
                            <div v-html="decodeHtml(widgetCode)"></div>
                            <CButton
                              color="secondary"
                              size="sm"
                              class="mt-2"
                              @click="copyToClipboard(decodeHtml(widgetCode))"
                            >
                              Widget-Code kopieren
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CTab>
                </CTabs>
              </CCardBody>
            </CCard>
            <CCard v-else>
              <CCardBody class="text-center">
                <p class="text-muted">Select a chatbot to view details</p>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CModal
      :show.sync="showDeleteConfirmModal"
      title="Löschen bestätigen"
      size="sm"
    >
      <div>Sind Sie sicher, dass Sie diesen Bot löschen möchten?</div>
      <template #footer>
        <CButton color="secondary" @click="showDeleteConfirmModal = false">
          Cancel
        </CButton>
        <CButton color="danger" @click="deleteBot"> Delete </CButton>
      </template>
    </CModal>

    <!-- Create Bot Modal -->
    <CModal
      :show.sync="showCreateBotModal"
      title="Create New Chatbot"
      size="lg"
    >
      <CForm @submit.prevent="createChatbot">
        <CInput
          v-model="newBotName"
          label="Chatbot Name"
          placeholder="Enter chatbot name"
          required
        />
        <CInput
          v-model="newWebsiteUrl"
          label="Website URL"
          placeholder="https://www.example.com"
          required
        />
        <CInput
          v-model="newSitemapUrl"
          label="Sitemap URL (optional)"
          placeholder="https://www.example.com/sitemap.xml"
        />
      </CForm>
      <template #footer>
        <CButton color="secondary" @click="closeCreateBotModal">
          Cancel
        </CButton>
        <CButton color="primary" @click="createChatbot">
          Create Chatbot
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";
import toastPlugin from "@/toastPlugin";

export default {
  data() {
    return {
      activeTab: "Overview",
      scrapingProgress: 0,
      showUrlSelectionModal: false,
      showDeleteConfirmModal: false,
      chatbots: [],
      iframeLink: "",
      widgetLink: "",
      integrationCode: "",
      search: "",
      isLoadingPages: false,
      selectedBot: {
        pages: [],
        createdAt: "",
        updatedAt: "",
        website: "",
        status: "",
      },
      createBotModal: false,
      newBotName: "",
      newWebsiteUrl: "",
      newSitemapUrl: "",
      scrapeOption: "Whole website",
      iframeUrl: process.env.VUE_APP_IFRAME_URL,
      iframeKey: commons.getFromStorage("currentRealm").iframeKey,
      widgetUrl: "https://your-chatbot-widget-url.com",
      createError: null,
      scrapedUrls: [],
      selectedUrls: [],
      showCreateBotModal: false,
    };
  },
  methods: {
    decodeHtml(html) {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    async checkScrapingProgress(botId) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/chatbots/${botId}/progress`,
        );
        this.scrapingProgress = response.data.progress;
        if (this.scrapingProgress < 100) {
          setTimeout(() => this.checkScrapingProgress(botId), 3000);
        } else {
          this.loadChatbotPages(botId);
        }
      } catch (error) {
        console.error("Failed to check scraping progress:", error);
      }
    },
    viewBotDetails(bot) {
      console.log("Bot selected: ", bot);
      axios
        .get(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/chatbots/${bot.id}`,
        )
        .then((response) => {
          console.log("Bot details loaded:", response.data);
          this.selectedBot = response.data;
          this.selectedBot.pages = this.selectedBot.pages || [];
        })
        .catch((error) => {
          console.error("Failed to load bot details:", error);
        });
    },
    async loadChatbotPages(botId) {
      this.isLoadingPages = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/chatbots/${botId}/availableUrls`,
        );
        console.log("Pages loaded:", response.data);
        if (Array.isArray(response.data)) {
          this.selectedBot.pages = response.data.map((page) => ({
            embeddingExists: page.embeddingExists,
            url: page.url,
          }));
        } else {
          this.selectedBot.pages = [];
        }
      } catch (error) {
        console.error("Failed to load chatbot pages:", error);
        this.selectedBot.pages = [];
      } finally {
        this.isLoadingPages = false;
      }
    },
    loadChatbots() {
      axios
        .get(`${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/list`)
        .then((response) => {
          this.chatbots = response.data;
        })
        .catch((error) => {
          console.error("Failed to load chatbots:", error);
        });
    },
    deletePage(page) {
      axios
        .delete(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/chatbots/${this.selectedBot.id}/pages`,
          {
            data: { url: page.url },
          },
        )
        .then(() => {
          toastPlugin.makeToast("Success", "Page deleted successfully");
          this.loadChatbotPages(this.selectedBot.id);
        })
        .catch((error) => {
          console.error("Failed to delete page:", error);
          toastPlugin.makeToast(
            "Error",
            "Failed to delete page. Please try again.",
          );
        });
    },
    getBotStatusColor(status) {
      switch (status) {
        case "Active":
          return "success";
        case "Training":
          return "warning";
        case "Inactive":
          return "danger";
        default:
          return "secondary";
      }
    },
    selectBot(bot) {
      this.selectedBot = bot;
      this.selectedBot.pages = [];
      this.loadChatbotPages(bot.id);
    },
    updateBotSettings() {
      toastPlugin.makeToast("Success", "Bot settings updated successfully");
    },
    openCreateBotModal() {
      this.showCreateBotModal = true;
    },
    closeCreateBotModal() {
      this.showCreateBotModal = false;
      this.createBotModal = false;
      this.newBotName = "";
      this.newWebsiteUrl = "";
      this.newSitemapUrl = "";
      this.createError = null;
      this.clearForm();
    },
    async createChatbot() {
      if (!this.newBotName) {
        this.createError = "Bitte gib einen Chatbot-Namen ein";
        return;
      }

      const currentRealm = commons.getFromStorage("currentRealm");
      const realmId = currentRealm.id;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/chatbots/create`,
          {
            name: this.newBotName,
            websiteUrl: this.newWebsiteUrl,
            sitemapUrl: this.newSitemapUrl,
            realmId: realmId,
          },
        );

        this.closeCreateBotModal();
        this.activeTab = "Training";
        this.checkScrapingProgress(response.data.id);
      } catch (error) {
        console.error("Failed to create chatbot:", error);
        this.createError =
          "Chatbot konnte nicht erstellt werden. Bitte versuche es erneut.";
      }
    },
    clearForm() {
      this.newBotName = "";
      this.newWebsiteUrl = "";
      this.newSitemapUrl = "";
      this.createError = null;
    },
    startTraining() {
      if (!this.selectedBot || !this.selectedBot.id) {
        toastPlugin.makeToast(
          "Error",
          "No bot selected. Please select a bot first.",
        );
        return;
      }

      if (this.newWebsiteUrl) {
        this.scrapeUrlsFromStartPage();
      } else if (this.newSitemapUrl) {
        this.scrapeUrlsFromSitemap();
      } else {
        toastPlugin.makeToast(
          "Error",
          "Please enter a website URL or a sitemap URL.",
        );
      }
    },
    scrapeUrlsFromStartPage() {
      if (!this.selectedBot || !this.selectedBot.id) {
        toastPlugin.makeToast(
          "Error",
          "No bot selected. Please select a bot first.",
        );
        return;
      }

      const payload = {
        botId: this.selectedBot.id,
        urls: [this.newWebsiteUrl],
      };

      axios
        .post(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/trainChatbot`,
          payload,
        )
        .then(() => {
          toastPlugin.makeToast("Success", "Training started successfully");
          this.newWebsiteUrl = "";
          this.newSitemapUrl = "";
        })
        .catch((error) => {
          if (error && error.response && error.response.data) {
            toastPlugin.makeToast(
              "Error",
              error.response.data.message ||
                "Failed to start training. Please try again.",
            );
          } else {
            toastPlugin.makeToast(
              "Error",
              "An unexpected error occurred. Please try again.",
            );
          }
        });
    },
    scrapeUrlsFromSitemap() {
      axios
        .get(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/extractUrlsFromSitemap?url=${this.newSitemapUrl}`,
        )
        .then((response) => {
          this.scrapedUrls = response.data.map((url) => String(url));
        })
        .catch((error) => {
          console.error("Failed to extract URLs from sitemap:", error);
        });
    },
    closeUrlSelectionModal() {
      this.showUrlSelectionModal = false;
      this.scrapedUrls = [];
      this.selectedUrls = [];
    },
    submitSelectedUrls() {
      const payload = {
        botId: this.selectedBot.id,
        urls: this.selectedUrls,
      };

      axios
        .post(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/trainChatbot`,
          payload,
        )
        .then(() => {
          toastPlugin.makeToast(
            "Success",
            "Training started successfully with selected URLs",
          );
          this.closeUrlSelectionModal();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            toastPlugin.makeToast(
              "Error",
              error.response.data.message ||
                "Failed to start training with selected URLs. Please try again.",
            );
          } else {
            toastPlugin.makeToast(
              "Error",
              "An unexpected error occurred. Please try again.",
            );
          }
        });
    },
    confirmDeleteBot() {
      this.showDeleteConfirmModal = true;
    },
    deleteBot() {
      axios
        .delete(
          `${process.env.VUE_APP_PLUGIN_BASE_URL}/webscraping/chatbot/delete/${this.selectedBot.id}`,
        )
        .then(() => {
          toastPlugin.makeToast("Success", "Chatbot deleted successfully");
          this.selectedBot = null;
          this.loadChatbots();
          this.showDeleteConfirmModal = false;
        })
        .catch((error) => {
          console.error("Failed to delete chatbot:", error);
          toastPlugin.makeToast(
            "Error",
            "Failed to delete chatbot. Please try again.",
          );
        });
    },
    formatDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toastPlugin.makeToast("Success", "Code copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toastPlugin.makeToast(
            "Error",
            "Failed to copy text. Please try again.",
          );
        });
    },
  },
  computed: {
    filteredChatbots() {
      return this.chatbots.filter((bot) =>
        bot.name.toLowerCase().includes(this.search.toLowerCase()),
      );
    },

    iframeCode() {
      const botId = this.selectedBot ? this.selectedBot.id : "defaultBotId";
      const chatbotUrl = `${this.iframeUrl}/?key=${this.iframeKey}&view=chatbot&botId=${botId}`;

      return `&lt;script&gt;(function() {
        var chatbotIframe = document.createElement('iframe');
        var params = new URLSearchParams(window.location.search);
        chatbotIframe.src = '${chatbotUrl}' + '&amp;' + params.toString();
        chatbotIframe.style.position = 'fixed';
        chatbotIframe.style.bottom = '20px';
        chatbotIframe.style.right = '20px';
        chatbotIframe.style.width = '350px';
        chatbotIframe.style.height = '500px';
        chatbotIframe.style.border = 'none';
        chatbotIframe.style.zIndex = '9999';
        document.body.appendChild(chatbotIframe);
  })();&lt;/script&gt;&lt;div id="prt-iframe-placeholder"&gt;&lt;/div&gt;`;
    },
    widgetCode() {
      const botId = this.selectedBot ? this.selectedBot.id : "defaultBotId";
      return `&lt;script src="${this.iframeUrl}/script?key=${this.iframeKey}&amp;botId=${botId}"&gt;&lt;/script&gt;`;
    },
  },
  mounted() {
    this.loadChatbots();
  },
};
</script>

<style scoped>
.chatbot-manager {
  max-width: 1200px;
  margin: 0 auto;
}

.url-selection-area {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.tab-content {
  padding-top: 1rem;
}
</style>
