<!-- LoadingState.vue -->
<template>
  <CCard>
    <CCardBody>
      <!-- Skeleton Loading -->
      <div v-if="showSkeleton">
        <div class="skeleton-loading">
          <div class="skeleton-line" style="width: 75%"></div>
          <div class="skeleton-line" style="width: 50%"></div>
        </div>
      </div>

      <!-- Progress Bar -->
      <CProgress
        :value="progress"
        :max="100"
        class="mb-2"
        variant="info"
        striped
        animated
      />

      <!-- Loading Message -->
      <div class="text-center text-muted mb-2">
        {{ currentMessage }}
      </div>

      <!-- Progress Text -->
      <div class="text-center font-weight-bold">
        {{ progress }}% geladen
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'LoadingState',
  props: {
    progress: {
      type: Number,
      default: 0
    },
    component: {
      type: String,
      default: 'metrics'
    },
    showSkeleton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentMessage: '',
      messages: {
        metrics: [
          'Lade Besucherdaten...',
          'Analysiere Trends...',
          'Bereite Metriken vor...'
        ],
        charts: [
          'Erstelle Visualisierungen...',
          'Generiere Graphen...',
          'Verarbeite Statistiken...'
        ],
        table: [
          'Lade Besucherdetails...',
          'Formatiere Tabellendaten...',
          'Optimiere Darstellung...',
          'Bereite große Datenmenge vor...'
        ]
      },
      messageInterval: null
    }
  },
  mounted() {
    this.startMessageRotation()
  },
  beforeDestroy() {
    if (this.messageInterval) {
      clearInterval(this.messageInterval)
    }
  },
  methods: {
    startMessageRotation() {
      this.updateMessage()
      this.messageInterval = setInterval(this.updateMessage, 2000)
    },
    updateMessage() {
      const messageArray = this.messages[this.component] || this.messages.metrics
      const randomIndex = Math.floor(Math.random() * messageArray.length)
      this.currentMessage = messageArray[randomIndex]
    }
  }
}
</script>

<style scoped>
.skeleton-loading {
  padding: 1rem;
}

.skeleton-line {
  height: 20px;
  background: #f0f0f0;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
