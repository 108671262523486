<template>
  <div class="funnel-wrapper">
    <!-- Typ-Auswahl -->
    <div class="type-selector mb-4">
      <CButtonGroup>
        <CButton
          v-for="type in propertyTypes"
          :key="type"
          :variant="selectedType === type ? 'primary' : 'outline-primary'"
          @click="selectedType = type"
        >
          {{ type === 'all' ? 'Alle Immobilien' : type }}
        </CButton>
      </CButtonGroup>

      <!-- Metriken für den ausgewählten Typ -->
      <div v-if="currentMetrics" class="metrics-summary mt-3 p-3 bg-light rounded">
        <div class="d-flex justify-content-around">
          <div class="text-center">
            <div class="h4 mb-0">{{ currentMetrics.totalUniqueVisitors }}</div>
            <small>Besucher gesamt</small>
          </div>
          <div class="text-center">
            <div class="h4 mb-0">{{ currentMetrics.completedVisitors }}</div>
            <small>Abgeschlossen</small>
          </div>
          <div class="text-center">
            <div class="h4 mb-0">{{ formatTime(currentMetrics.totalTimeSpent) }}</div>
            <small>Gesamtzeit</small>
          </div>
        </div>
      </div>
    </div>

    <!-- Info Box -->
    <div class="info-box mb-4">
      <i class="cil-info" />
      <div class="info-content">
        <h3>Conversion Funnel: {{ selectedType === 'all' ? 'Alle Immobilien' : selectedType }}</h3>
        <p>Verfolgen Sie den Weg Ihrer Besucher durch den Immobilien-Bewertungsprozess:</p>
        <ul>
          <li>Sehen Sie die Anzahl der Besucher in jeder Phase</li>
          <li>Analysieren Sie Conversion-Raten zwischen den Schritten</li>
          <li>Messen Sie die durchschnittliche Verweilzeit</li>
        </ul>
      </div>
    </div>

    <!-- Funnel Visualisierung -->
    <div class="funnel-container">
      <div
        v-for="(step, index) in filteredSteps"
        :key="`${step.stepName}-${index}`"
        class="funnel-step"
        :class="{ 'has-conversion-drop': hasConversionDrop(index) }"
      >
        <div class="step-metrics-left">
          <div class="step-visitors">
            <span class="metric-value">{{ formatNumber(step.uniqueVisitors) }}</span>
            <span class="metric-label">Besucher</span>
          </div>
        </div>

        <div class="step-main" :style="getStepStyle(index)">
          <div class="step-content">
            <div class="step-name">{{ formatStepName(step.stepName) }}</div>
            <div class="step-details">
              <div class="conversion-rate" :class="getConversionClass(step.conversionRate)">
                {{ formatPercentage(step.conversionRate) }}% Conversion
                <span class="conversion-trend" v-if="!isLastStep(index)">
                  {{ getStepDifference(index) }} Besucher
                </span>
              </div>
              <div v-if="step.averageTimeSpent" class="time-spent">
                <i class="cil-clock"></i> {{ formatTime(step.averageTimeSpent) }}
              </div>
            </div>
          </div>

          <!-- Konversions-Pfeil -->
          <div v-if="!isLastStep(index)" class="conversion-arrow">
            <div class="arrow-body" :style="getArrowStyle(index)">
              <span class="arrow-value">{{ getStepDifference(index) }}</span>
            </div>
          </div>
        </div>

        <div class="step-metrics-right">
          <div class="step-time">
            <div class="time-bar">
              <div class="time-progress" :style="getTimeStyle(step.averageTimeSpent)"></div>
            </div>
            <span class="time-value">{{ formatTime(step.averageTimeSpent) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CFunnel',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        steps: [],
        metricsPerType: {}
      })
    }
  },

  data() {
    return {
      selectedType: 'all'
    }
  },

  computed: {
    propertyTypes() {
      const types = ['all', ...new Set(this.data.steps.map(step => step.propertyType))];
      return types.filter(Boolean).sort(); // Sortiert die Typen alphabetisch
    },


    currentMetrics() {
      if (this.selectedType === 'all') {
        return Object.values(this.data.metricsPerType).reduce((acc, metrics) => {
          return {
            totalUniqueVisitors: (acc.totalUniqueVisitors || 0) + metrics.totalUniqueVisitors,
            completedVisitors: (acc.completedVisitors || 0) + metrics.completedVisitors,
            totalTimeSpent: (acc.totalTimeSpent || 0) + metrics.totalTimeSpent
          }
        }, {});
      }
      return this.data.metricsPerType[this.selectedType];
    },

    filteredSteps() {
      let steps = [...this.data.steps];

      if (this.selectedType !== 'all') {
        steps = steps.filter(step => {
          // Filtere nach propertyType
          if (step.propertyType !== this.selectedType) return false;

          // Prüfe, ob der Schritt wirklich zu diesem Typ gehört
          const prefix = this.selectedType.toLowerCase();
          const stepPrefix = step.stepName.toLowerCase().split(' - ')[0];

          // Spezialfall "Anfang" ist für alle erlaubt
          if (stepPrefix === 'anfang') return true;

          // Für alle anderen Schritte: Der Präfix muss zum Typ passen
          return stepPrefix === prefix;
        });
      }

      // Gruppiere nach Schrittnamen nur in der "Alle" Ansicht
      if (this.selectedType === 'all') {
        const groupedSteps = {};
        steps.forEach(step => {
          const name = step.stepName;
          if (!groupedSteps[name]) {
            groupedSteps[name] = {
              ...step,
              uniqueVisitors: 0,
              totalTime: 0,
              count: 0
            };
          }
          groupedSteps[name].uniqueVisitors += step.uniqueVisitors;
          groupedSteps[name].totalTime += (step.averageTimeSpent * step.uniqueVisitors);
          groupedSteps[name].count += step.uniqueVisitors;
        });

        steps = Object.values(groupedSteps).map(step => ({
          ...step,
          averageTimeSpent: step.count ? Math.round(step.totalTime / step.count) : 0
        }));
      }

      return this.sortStepsByJourney(steps);
    }
  },

  methods: {
    sortStepsByJourney(steps) {
      const sorted = [];
      let currentStep = steps.find(s => s.previousStep === null);

      while (currentStep && sorted.length < steps.length) {
        sorted.push(currentStep);
        // Finde den nächsten Schritt basierend auf dem aktuellen Schrittnamen
        // und dem propertyType für die korrekte Reihenfolge
        currentStep = steps.find(s =>
          s.previousStep === currentStep.stepName &&
          (this.selectedType === 'all' || s.propertyType === this.selectedType)
        );
      }

      // Füge übrige Schritte hinzu, falls die Kette unterbrochen wurde
      steps.forEach(step => {
        if (!sorted.includes(step)) {
          sorted.push(step);
        }
      });

      return sorted;
    },

    getStepStyle(index) {
      const totalSteps = this.filteredSteps.length;
      const maxWidth = 85;
      const minWidth = 45;
      const widthDiff = maxWidth - minWidth;
      const stepWidth = widthDiff / (totalSteps - 1 || 1);
      const width = maxWidth - (index * stepWidth);

      const gradients = [
        'linear-gradient(135deg, #4299E1, #2B6CB0)', // Kräftiges Blau
        'linear-gradient(135deg, #319795, #2C7A7B)', // Kräftiges Türkis
        'linear-gradient(135deg, #38A169, #2F855A)', // Kräftiges Grün
        'linear-gradient(135deg, #2C5282, #2A4365)'  // Dunkles Blau
      ];

      return {
        width: `${width}%`,
        background: gradients[index % gradients.length]
      };
    },

    getArrowStyle(index) {
      const conversion = this.getConversionRate(index);
      const color = conversion >= 0.8 ? '#38A169' :
        conversion >= 0.5 ? '#D69E2E' :
          '#E53E3E';
      return {
        borderColor: color,
        backgroundColor: `${color}22`
      };
    },

    hasConversionDrop(index) {
      if (index === 0) return false;
      const currentStep = this.filteredSteps[index];
      const previousStep = this.filteredSteps[index - 1];
      return currentStep.uniqueVisitors < previousStep.uniqueVisitors;
    },

    getStepDifference(index) {
      if (index >= this.filteredSteps.length - 1) return '';
      const current = this.filteredSteps[index].uniqueVisitors;
      const next = this.filteredSteps[index + 1].uniqueVisitors;
      const diff = next - current;
      return diff < 0 ? diff : `+${diff}`;
    },

    getConversionRate(index) {
      if (index >= this.filteredSteps.length - 1) return 1;
      const current = this.filteredSteps[index].uniqueVisitors;
      const next = this.filteredSteps[index + 1].uniqueVisitors;
      return next / current;
    },

    getTimeStyle(ms) {
      const maxTime = Math.max(...this.filteredSteps.map(s => s.averageTimeSpent || 0));
      const percentage = maxTime ? (ms / maxTime) * 100 : 0;
      return {
        width: `${percentage}%`
      };
    },

    isLastStep(index) {
      return index === this.filteredSteps.length - 1;
    },

    formatStepName(name) {
      // Entferne den Typ-Prefix, wenn er mit dem ausgewählten Typ übereinstimmt
      if (this.selectedType !== 'all') {
        const prefix = this.selectedType + ' - ';
        if (name.startsWith(prefix)) {
          return name.substring(prefix.length);
        }
      }
      return name.split(' - ').pop();
    },

    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(value);
    },

    formatPercentage(value) {
      return (value * 100).toFixed(1);
    },

    formatTime(ms) {
      if (!ms) return '0s';
      // Konvertiere zu Sekunden
      const totalSeconds = Math.floor(ms / 1000);

      // Berechne Stunden, Minuten und Sekunden
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // Durchschnittliche Zeit pro Besucher (ms durch Anzahl der Besucher)
      const avgTimePerVisitor = this.currentMetrics
        ? Math.floor(ms / this.currentMetrics.totalUniqueVisitors) / 1000
        : totalSeconds;

      // Wenn die Durchschnittszeit pro Besucher mehr als 2 Stunden beträgt,
      // zeigen wir eine benutzerfreundlichere Nachricht an
      if (avgTimePerVisitor > 7200) {
        return 'Zeitdaten möglicherweise fehlerhaft';
      }

      // Formatierung der Zeit
      let timeStr = '';
      if (hours > 0) timeStr += `${hours}h `;
      if (minutes > 0) timeStr += `${minutes}m `;
      if (seconds > 0 || timeStr === '') timeStr += `${seconds}s`;

      return timeStr.trim();
    },

    getConversionClass(rate) {
      if (rate >= 0.8) return 'high';
      if (rate >= 0.5) return 'medium';
      return 'low';
    }
  }
};
</script>

<style scoped>
.funnel-wrapper {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.type-selector {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.metrics-summary {
  border: 1px solid #e2e8f0;
}

.info-box {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 1px solid #E2E8F0;
  display: flex;
  gap: 1rem;
}

.info-box i {
  font-size: 1.5rem;
  color: #4299E1;
}

.info-content h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #2D3748;
}

.info-content p {
  color: #4A5568;
  margin-bottom: 1rem;
}

.info-content ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  color: #4A5568;
}

.funnel-container {
  background: #F7FAFC;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
}

.funnel-step {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  position: relative;
}

.step-metrics-left,
.step-metrics-right {
  width: 150px;
  padding: 0 1rem;
}

.step-main {
  min-height: 120px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.step-main:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0,0,0,0.2);
}

.step-content {
  padding: 1.5rem;
  color: white;
}

.step-name {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.step-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.conversion-rate {
  padding: 0.5rem 1rem;
  border-radius: 999px;
  font-size: 0.9rem;
  font-weight: 500;
  background: rgba(255,255,255,0.2);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.conversion-rate.high { background: rgba(56, 161, 105, 0.3); }
.conversion-rate.medium { background: rgba(214, 158, 46, 0.3); }
.conversion-rate.low { background: rgba(229, 62, 62, 0.3); }

.conversion-trend {
  font-size: 0.8rem;
  opacity: 0.9;
  padding-left: 0.5rem;
  border-left: 1px solid rgba(255,255,255,0.3);
}

.step-visitors {
  text-align: right;
}

.metric-value {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: #2D3748;
}

.metric-label {
  font-size: 0.875rem;
  color: #718096;
}

.time-spent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.time-spent i {
  opacity: 0.8;
}

.time-bar {
  height: 6px;
  background: #E2E8F0;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.time-progress {
  height: 100%;
  background: #4299E1;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.time-value {
  font-size: 0.875rem;
  color: #718096;
}

.conversion-arrow {
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 2rem;
}

.arrow-body {
  position: relative;
  height: 100%;
  width: 100%;
  border-left: 2px dashed;
}

.arrow-value {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #4A5568;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .funnel-wrapper {
    padding: 1rem;
  }

  .info-box {
    flex-direction: column;
  }

  .type-selector {
    overflow-x: auto;
  }

  .metrics-summary {
    flex-direction: column;
    gap: 1rem;
  }

  .funnel-step {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .step-main {
    width: 100% !important;
  }

  .step-metrics-left,
  .step-metrics-right {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  .step-visitors {
    text-align: center;
  }

  .step-details {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .conversion-arrow {
    display: none;
  }
}
</style>
