<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Profil bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <UserForm
              v-if="ready"
              @submit="editUser"
              @delete="deleteUser"
              :passed-data="user"
              :processing="processing"
              :show-delete="true"
              :processing-delete="processingDelete"
              btn-text="Sichern "
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import UserForm from "./UserForm";
import moment from "moment";

export default {
  name: "EditUser",
  components: { UserForm },
  data() {
    return {
      ready: false,
      user: null,
      processing: false,
      processingDelete: false,
    };
  },
  created() {
    axios
      .get("/user/" + this.$route.params.id)
      .then((response) => {
        this.user = response.data;
        this.ready = true;
      })
      .catch((err) => console.error(err));
  },
  methods: {
    editUser(data) {
      this.processing = true;
      axios
        .put("/user/" + this.$route.params.id, data)
        .then(() => {
          this.$router.push({ name: "Users" });
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Benutzer erfolgreich bearbeitet!</b>",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Error!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    deleteUser(data) {
      this.processingDelete = true;
      this.$modal.showModal(
        "delete",
        "Benutzer löschen?",
        [`${data.email} <i>(Benutzer)</i>`],
        async () => {
          try {
            let resp = await axios.delete(`/user/${this.$route.params.id}`);
            this.$toaster.makeToast(
              "Erfolgreich!",
              `Der Benutzer '${data.email}' konnte gelöscht werden.`,
            );
            this.$router.push({ name: "Users" });
          } catch (e) {
            this.$toaster.makeToast(
              "Fehler!",
              "Der Benutzer konnte nicht gelöscht werden.",
            );
            console.error(e);
          } finally {
            this.processingDelete = false;
          }
        },
      );
    },
  },
};
</script>
