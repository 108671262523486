<template>
  <CSelect
      v-bind="$attrs"
      v-on="$listeners"
      :options="processedOptions"
      v-model="processedValue"
      @change="onSelect"
  />
</template>

<script>
import CSelect from '@coreui/vue/src/components/form/CSelect';

export default {
  name: "CSelectWrapper",
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    value: {
      type: [Array, String],
      default: null,
      validator(value) {
        console.log("Übergebener Wert an value:", value); // Debug-Ausgabe
        return typeof value === "string" || Array.isArray(value);
      },
    },
  },
  computed: {
    processedValue: {
      get() {
        console.log("Processed value:", this.value); // Debugging
        return this.value ?? "";
      },
      set(newValue) {
        console.log("Neuer Wert für das Dropdown:", newValue); // Debugging
        this.$emit("input", newValue);
      },
    },
    processedOptions() {
      console.log("Verarbeitete Optionen:", this.options); // Debugging
      return this.options.map((option) => ({
        ...option,
        label: option.label || option.text || option.value,
        class: option.assigned ? "highlight-assigned" : option.class || "",
      }));
    },
  },
  methods: {
    onSelect(event) {
      if (this.$attrs.multiple !== undefined) {
        const selectedValues = Array.from(event.target.selectedOptions).map(
            (option) => option.value
        );
        this.$emit("input", selectedValues);
      } else {
        const selectedValue = event.target?.value || ""; // Extrahiere den Wert sicher
        this.$emit("input", selectedValue);
      }
    },
  },
};
</script>