<template>
  <CCard>
    <CCardHeader>
      <strong>Benachrichtigungen verwalten</strong>
    </CCardHeader>
    <CCardBody>
      <CForm @submit.prevent="submitNotification">
        <CInput
          label="Titel"
          v-model="newNotification.title"
          placeholder="Geben Sie den Titel der Benachrichtigung ein"
          required
        />
        <CTextarea
          label="Beschreibung"
          v-model="newNotification.description"
          placeholder="Geben Sie die Beschreibung der Benachrichtigung ein"
          rows="3"
          required
        />
        <CSelect
          label="Priorität"
          :options="priorityOptions"
          v-model="newNotification.priority"
        />
        <CInput
          label="Gültig bis"
          type="datetime-local"
          v-model="newNotification.expiresAt"
        />
        <CButton color="primary" type="submit"
          >Benachrichtigung erstellen</CButton
        >
      </CForm>

      <CDataTable
        :items="formattedNotifications"
        :fields="fields"
        hover
        striped
        :items-per-page="5"
        :pagination="{ doubleArrows: false, align: 'center' }"
      >
        <template #actions="{ item }">
          <CButton
            color="danger"
            size="sm"
            @click="deleteNotification(item.id)"
          >
            Löschen
          </CButton>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import WebSocketService from "@/services/WebSocketService";
import axios from "axios";
import toastPlugin from "@/toastPlugin";

export default {
  name: "NotificationManagement",
  created() {
    this.initWebSocket();
  },
  data() {
    return {
      newNotification: {
        title: "",
        description: "",
        priority: "normal",
        expiresAt: "",
      },
      priorityOptions: [
        { value: "low", label: "Niedrig" },
        { value: "normal", label: "Normal" },
        { value: "high", label: "Hoch" },
      ],
      fields: [
        { key: "title", label: "Titel" },
        { key: "description", label: "Beschreibung" },
        { key: "priority", label: "Priorität" },
        { key: "createdAt", label: "Erstellt am" },
        { key: "expiresAt", label: "Gültig bis" },
        { key: "actions", label: "Aktionen" },
      ],
    };
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.notifications,
    }),
    formattedNotifications() {
      if (Array.isArray(this.notifications)) {
        return this.notifications.map((notification) => ({
          id: notification.id,
          title: notification.title,
          description: notification.description,
          priority: notification.priority,
          createdAt: moment(notification.createdAt).format("LLL"),
          expiresAt: notification.expiresAt
              ? moment(notification.expiresAt).format("LLL")
              : "Nicht gesetzt",
          isRead: notification.isRead,
        }));
      } else {
        console.error(
            "Expected notifications to be an array, but got:",
            this.notifications,
        );
        return [];
      }
    },
  },
  methods: {
    ...mapActions('notifications', [
      'fetchNotifications',
      'addNotification',
      'markNotificationAsRead',  // statt 'markAsRead'
      'removeNotification',      // statt 'deleteNotification'
      'createNotification'
    ]),
    async submitNotification() {
      try {
        const notification = {
          ...this.newNotification,
          expiresAt: this.newNotification.expiresAt
              ? moment(this.newNotification.expiresAt).toISOString()
              : null,
        };
        await this.createNotification(notification);
        this.resetForm();
        await this.fetchNotifications();
      } catch (err) {
        console.error("Error creating notification:", err);
        toastPlugin.makeToast(
            "Fehler",
            "Fehler beim Erstellen der Benachrichtigung.",
        );
      }
    },

    resetForm() {
      this.newNotification = {
        title: "",
        description: "",
        priority: "normal",
        expiresAt: "",
      };
    },

    initWebSocket() {
      WebSocketService.connect();
      WebSocketService.subscribeToTopic("/topic/notifications", (message) => {
        try {
          this.$store.dispatch('notifications/addNotification', message);
        } catch (error) {
          console.error("Error processing notification message:", error);
        }
      });
    },

    handleNewNotification(notificationData) {
      try {
        const notification = {
          ...notificationData,
          createdAt: notificationData.createdAt || new Date().toISOString(),
        };
        this.$store.dispatch('notifications/addNotification', notification);
      } catch (error) {
        console.error("Error handling notification:", error);
      }
    },

    async markAsRead(id) {
      try {
        await this.markNotificationAsRead(id);  // statt markAsRead
      } catch (error) {
        console.error("Fehler beim Markieren als gelesen:", error);
      }
    },

    async handleDelete(id) {
      try {
        await this.removeNotification(id);  // statt deleteNotification
        await this.fetchNotifications();
        toastPlugin.makeToast("Erfolg", "Benachrichtigung gelöscht");
      } catch (error) {
        console.error("Fehler beim Löschen der Benachrichtigung:", error);
        toastPlugin.makeToast(
            "Fehler",
            "Fehler beim Löschen der Benachrichtigung"
        );
      }
    },
  },
  async mounted() {
    try {
      await this.fetchNotifications();
    } catch (error) {
      console.error("Error fetching notifications:", error);
      toastPlugin.makeToast(
          "Fehler",
          "Fehler beim Laden der Benachrichtigungen"
      );
    }
  }
};
</script>

<style scoped>
.card-body {
  padding: 1.25rem;
}

.form-group {
  margin-bottom: 1rem;
}

.btn-primary {
  margin-top: 1rem;
}

.table {
  margin-top: 2rem;
}
</style>
