<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Neuen Benutzer anlegen</strong>
              <br />
              <template v-if="!$commons.hasExactRealmPlan('KENSINGTON')">
                Das Premium-Abonnement umfasst 1 Benutzerkonto ohne
                Zusatzkosten.<br />
                Pro Nutzer können Sie gegen einen Aufpreis von 69,00 € bei
                jährlicher Laufzeit oder 89,00 € bei monatlicher Laufzeit
                weitere Benutzerkonten hinzufügen.
              </template>
            </slot>
          </CCardHeader>
          <CCardBody>
            <UserForm
              @submit="createNewUser"
              :processing="processing"
              btn-text="Jetzt anlegen"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import UserForm from "./UserForm";

export default {
  name: "NewUser",
  components: { UserForm },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    createNewUser(data) {
      this.processing = true;
      axios
        .post("/user", data)
        .then(() => {
          this.$router.push({ name: "Users" });
          this.$toaster.makeToast(
            "Fertig!",
            "Benutzer <b>'" + data.email + "'</b> wurde erfolgreich erstellet!",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Error!", err.message);
          }
        })
        .finally(() => (this.processing = false));
    },
  },
};
</script>
