<template>
  <div>
    <CBreadcrumbRouter class="border-0 mb-0" />

    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <div class="d-flex justify-content-between align-items-center">
              <h4>
                <CIcon name="cil-newspaper" />
                Empfängergruppen
              </h4>
              <CButton @click="openCreateGroupModal" color="primary" size="sm">
                <CIcon name="cil-plus" />
                Neue Empfängergruppe
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow class="mb-4">
              <CCol sm="3">
                <CWidgetSimple
                  header="Gesamtzahl Empfängergruppen"
                  :text="tableData.length.toString()"
                >
                  <CIcon name="cil-people" width="24" height="24" />
                </CWidgetSimple>
              </CCol>
              <CCol sm="3">
                <CWidgetSimple
                  header="Aktive E-Mail Funnels"
                  :text="activeFunnels.toString()"
                >
                  <CIcon name="cil-envelope-open" width="24" height="24" />
                </CWidgetSimple>
              </CCol>
              <CCol sm="3">
                <CWidgetSimple
                  header="Aktive Lead Funnels"
                  :text="activeLeadFunnels.toString()"
                >
                  <CIcon name="cil-user-follow" width="24" height="24" />
                </CWidgetSimple>
              </CCol>
              <CCol sm="3">
                <CWidgetSimple
                  header="Gesamtanzahl Abonnenten"
                  :text="totalSubscribers.toString()"
                >
                  <CIcon name="cil-user" width="24" height="24" />
                </CWidgetSimple>
              </CCol>
            </CRow>

            <CCard class="mb-4">
              <CCardBody>
                <CRow>
                  <CCol sm="10">
                    <h4 id="traffic" class="card-title mb-0">Empfängergruppen</h4>
                    <div class="small text-muted">
                      Verwalten Sie hier Ihre Empfängergruppen, um Ihre Leads besser zu segmentieren. Jede Empfängergruppe kann Abonnenten enthalten, die sich für bestimmte Themen interessieren.
                      Sie können in jedem Tool der Marketing-Cloud definieren in welche Empfängergruppen ein Lead eingetragen wird.
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
              <CDataTable
                :items="filteredTableData"
                :fields="getTableFields()"
                :items-per-page-select="{ label: 'Empfängergruppen pro Seite' }"
                :no-items-view="{
                  noResults: 'Keine Suchergebnisse verfügbar',
                  noItems: 'Es gibt noch keine Empfängergruppen',
                }"
                hover
                striped
                bordered
                responsive
                pagination
                :items-per-page="10"
              >
                <!-- Created Field -->
                <template #created="{ item }">
                  <td>{{ formatDate(item.created) }}</td>
                </template>

                <!-- Updated Field -->
                <template #updated="{ item }">
                  <td>{{ formatDate(item.updated) }}</td>
                </template>

                <!-- Actions Field -->
                <template #actions="{ item }">
                  <td>
                    <CButtonGroup>
                      <CButton
                        @click="openEditGroupModal(item)"
                        color="info"
                        size="sm"
                        class="mr-2"
                      >
                        <CIcon name="cil-pencil" />
                      </CButton>
                      <CButton
                        @click="openDeleteGroupModal(item)"
                        color="danger"
                        size="sm"
                      >
                        <CIcon name="cil-trash" />
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </CDataTable>
            </CCard>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :title="
        modalMode === 'create'
          ? 'Neue Empfängergruppe erstellen'
          : 'Empfängergruppe bearbeiten'
      "
      size="lg"
      :show.sync="visibleModal"
    >
      <CInput
        label="Name"
        v-model="editedGroupName"
        description="Geben Sie einen eindeutigen Namen ein."
      />
      <CTextarea
        label="Beschreibung"
        v-model="editedGroupDescription"
        rows="3"
        description="Fügen Sie eine optionale Beschreibung hinzu."
      />
      <div slot="footer">
        <CButton color="secondary" @click="visibleModal = false"
          >Abbrechen</CButton
        >
        <CButton color="primary" @click="saveNewsletterGroup"
          >Speichern</CButton
        >
      </div>
    </CModal>

    <CModal title="Empfängergruppen-Details" size="lg" :show.sync="showDetailsModal">
      <template v-if="selectedGroup">
        <h3>{{ selectedGroup.name }}</h3>
        <p>{{ selectedGroup.description }}</p>
        <CListGroup>
          <CListGroupItem>
            <strong>Erstellt am:</strong>
            {{ formatDate(selectedGroup.created) }}
          </CListGroupItem>
          <CListGroupItem>
            <strong>Anzahl Abonnenten:</strong>
            {{ selectedGroup.subscriberCount }}
          </CListGroupItem>
          <CListGroupItem>
            <strong>Letzte Aktualisierung:</strong>
            {{ formatDate(selectedGroup.updated) }}
          </CListGroupItem>
        </CListGroup>
      </template>
    </CModal>
  </div>
</template>

<script>
import DoubleBounceLoader from "@/components/loaders/DoubleBounceLoader";
import commons from "@/commons";
import axios from "axios";
import { CBreadcrumbRouter, CButtonGroup } from "@coreui/vue";
import moment from "moment";
import "moment/locale/de"; // Importiere deutsche Lokalisierung für moment.js

moment.locale("de"); // Setze die Lokalisierung auf Deutsch

export default {
  name: "NewsletterGroups",
  components: {
    DoubleBounceLoader,
    CBreadcrumbRouter,
    CButtonGroup,
  },
  data() {
    return {
      loading: false,
      saving: false,
      tableData: [],
      filteredTableData: [],
      tableShowLoading: true,
      visibleModal: false,
      showDetailsModal: false,
      modalMode: "create",
      editedGroupName: "",
      editedGroupDescription: "",
      selectedGroup: null,
      searchQuery: "",
      activeFunnels: 0,
      activeLeadFunnels: 0,
      totalSubscribers: 0,
    };
  },
  mounted() {
    this.loading = true;
    this.loadNewsletterGroups();
    this.loadSubscribers();
    this.loadFunnels();
    this.loadLeadFunnels();
  },
  methods: {
    addSubscriberToGroup(subscriberId, groupId) {
      axios
          .post(`/backend/newsletter/group/${groupId}/subscriber/${subscriberId}`)
          .then(() => {
            this.$toaster.makeToast(
                "Erfolgreich!",
                "Der Abonnent wurde erfolgreich zur Gruppe hinzugefügt."
            );
            this.loadNewsletterGroups(); // Aktualisiere die Gruppenliste
          })
          .catch((err) => {
            console.error("Fehler beim Hinzufügen des Abonnenten:", err);
            this.$toaster.makeToast("Fehler!", "Abonnent konnte nicht hinzugefügt werden.");
          });
    },
    removeSubscriberFromGroup(subscriberId, groupId) {
      axios
          .delete(`/backend/newsletter/group/${groupId}/subscriber/${subscriberId}`)
          .then(() => {
            this.$toaster.makeToast(
                "Erfolgreich!",
                "Der Abonnent wurde erfolgreich aus der Gruppe entfernt."
            );
            this.loadNewsletterGroups(); // Aktualisiere die Gruppenliste
          })
          .catch((err) => {
            console.error("Fehler beim Entfernen des Abonnenten:", err);
            this.$toaster.makeToast("Fehler!", "Abonnent konnte nicht entfernt werden.");
          });
    },
    openCreateGroupModal() {
      this.modalMode = "create";
      this.editedGroupName = "";
      this.editedGroupDescription = "";
      this.visibleModal = true;
    },
    openDeleteGroupModal(item) {
      this.deleteNewsletterGroup(item);
    },
    openEditGroupModal(item) {
      this.editNewsletterGroup(item);
    },
    saveNewsletterGroup() {
      const groupData = {
        name: this.editedGroupName,
        description: this.editedGroupDescription,
      };

      if (this.modalMode === "create") {
        this.createNewsletterGroup(groupData);
      } else {
        this.updateNewsletterGroup(this.selectedGroup.id, groupData);
      }
    },
    createNewsletterGroup(groupData) {
      axios
        .post("/newsletter/group", groupData)
        .then(() => {
          this.loadNewsletterGroups();
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Gruppe wurde erfolgreich angelegt!</b>",
          );
        })
        .catch(this.handleError)
        .finally(() => {
          this.visibleModal = false;
        });
    },
    updateNewsletterGroup(id, groupData) {
      axios
        .post(`/newsletter/group/${id}`, groupData)
        .then(() => {
          this.loadNewsletterGroups();
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Gruppe wurde erfolgreich aktualisiert!</b>",
          );
        })
        .catch(this.handleError)
        .finally(() => {
          this.visibleModal = false;
        });
    },
    loadNewsletterGroups() {
      axios
        .get("/newsletter/group")
        .then((response) => {
          this.tableData = response.data.map((group) => ({
            ...commons.flatObject(group),
            subscriberCount: 0, // Wird später aktualisiert
          }));
          this.filteredTableData = this.tableData;
          this.loading = false;
        })
        .catch((err) =>
          console.error("Problem while fetching newsletter groups", err),
        )
        .finally(() => (this.tableShowLoading = false));
    },
    loadFunnels() {
      axios
        .get("/funnel/rules")
        .then((response) => {
          this.activeFunnels = response.data.filter(
            (funnel) => funnel.active,
          ).length;
        })
        .catch((err) => {
          console.error("Problem while fetching funnels", err);
          this.activeFunnels = 0;
        });
    },

    loadLeadFunnels() {
      axios
        .get("/leadgenerator")
        .then((response) => {
          this.activeLeadFunnels = response.data.filter(
            (funnel) => funnel.active,
          ).length;
        })
        .catch((err) => {
          console.error("Problem while fetching lead funnels", err);
          this.activeLeadFunnels = 0;
        });
    },
    loadSubscribers() {
      axios
        .get("/newsletter/subscriber")
        .then((response) => {
          this.totalSubscribers = response.data.length;
          const groupSubscribers = {};

          // Iteriere über die Abonnenten und zähle die Abonnenten für jede Gruppe
          response.data.forEach((subscriber) => {
            subscriber.groups.forEach((group) => {
              if (!groupSubscribers[group.id]) {
                groupSubscribers[group.id] = 0;
              }
              groupSubscribers[group.id]++;
            });
          });

          // Aktualisiere die Abonnentenzahl für jede Gruppe in tableData
          this.tableData.forEach((group) => {
            group.subscriberCount = groupSubscribers[group.id] || 0;
          });

          // Aktualisiere filteredTableData
          this.filteredTableData = [...this.tableData];
        })
        .catch((err) =>
          console.error("Problem while fetching subscribers", err),
        );
    },
    deleteNewsletterGroup(item) {
      this.$modal.showModal(
        "delete",
        null,
        [`<b>Gruppe</b> (${item.name})`],
        async () => {
          try {
            console.log("Attempting to delete group:", item);
            const response = await axios.delete("/newsletter/group/" + item.id);
            console.log("Delete response:", response);

            this.loadNewsletterGroups();
          } catch (err) {
            console.error(
              "Delete error:",
              err.response ? err.response.data : err,
            );
            this.handleError(err);
          }
        },
      );
    },
    editNewsletterGroup(item) {
      this.modalMode = "edit";
      this.selectedGroup = item;
      this.editedGroupName = item.name;
      this.editedGroupDescription = item.description || "";
      this.visibleModal = true;
    },
    showGroupDetails(item) {
      this.selectedGroup = item;
      this.showDetailsModal = true;
    },
    getTableFields() {
      return [
        { key: "name", label: "Name" },
        { key: "created", label: "Erstellt am" },
        { key: "subscriberCount", label: "Anzahl Abonnenten" },
        { key: "updated", label: "Letzte Aktualisierung" },
        { key: "actions", label: "Aktionen" },
      ];
    },
    getSubscriberCountColor(count) {
      if (count > 500) return "success";
      if (count > 100) return "warning";
      return "danger";
    },
    filterTable() {
      this.filteredTableData = this.tableData.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },
    formatDate(date) {
      return moment(date).local().format("DD.MM.YYYY");
    },
    handleError(err) {
      if (err.response) {
        this.$toaster.makeToast(
          "Oops!",
          `<b>${err.response.data.code}</b><br>${err.response.data.message}`,
        );
      } else {
        this.$toaster.makeToast("Error!", err.message);
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1px;
  padding: 4px;
}

.card-header h4 {
  margin-bottom: 0;
}

.c-widget-simple {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
}

.c-widget-simple .card-header {
  background-color: transparent;
  border-bottom: none;
  font-weight: bold;
  color: #3c4b64;
}

.c-widget-simple .card-body {
  font-size: 1.5rem;
  font-weight: bold;
  color: #321fdb;
}

.input-group {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.card-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.small.text-muted {
  font-size: 0.875rem;
}
</style>
