var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LgcModule',_vm._b({staticClass:"lgc-module-conditions-privacy",attrs:{"inside-module":_vm.insideModule},on:{"delete":function($event){return _vm.$emit('delete', $event)},"add-below":function($event){return _vm.$emit('add-below', $event)}}},'LgcModule',_vm.$attrs,false),[_c('p',{ref:"pText",class:_vm.dataConditionsPrivacyActive ? '' : 'not-active',style:({ fontSize: _vm.dataConditionsPrivacyTextSize }),attrs:{"contentEditable":"true"},domProps:{"innerHTML":_vm._s(
        _vm.dataConditionsPrivacyActive
          ? _vm.computedConditionsPrivacyText
          : '[Doppelklicken für AGB & Datenschutz]'
      )},on:{"dblclick":_vm.onDoubleClick,"focusout":function($event){_vm.dataConditionsPrivacyText = _vm.dataConditionsPrivacyActive
          ? $event.target.innerText
          : _vm.dataConditionsPrivacyText;
        _vm.emitInput();}}})]),_c('CModal',{attrs:{"title":"AGB und Datenschutz","size":"","show":_vm.modalVisible,"closeOnBackdrop":true},on:{"update:show":function($event){_vm.modalVisible=$event}}},[(_vm.insideModule)?_c('Checkbox',{attrs:{"label":"","name":"conditionsPrivacyActive","options":['Rechtstext unter dem Formular einfügen?'],"value":_vm.computedConditionsPrivacyActive,"horizontal":false},on:{"input":function($event){_vm.dataConditionsPrivacyActive =
          Array.isArray($event) &&
          $event.includes('Rechtstext unter dem Formular einfügen?');
        _vm.emitInput();}}}):_vm._e(),_c('CInput',{attrs:{"disabled":Boolean(!_vm.dataConditionsPrivacyActive),"type":"number","label":"Schriftgröße","horizontal":"","value":_vm.computedConditionsPrivacyTextSize},on:{"input":function($event){_vm.dataConditionsPrivacyTextSize = $event + 'px';
        _vm.emitInput();}}}),(_vm.insideModule)?_c('hr'):_vm._e(),_c('h5',[_vm._v("Hinweis:")]),_c('p',[_vm._v(" Damit unter dem Kontaktformular der Hinweis zum Datenschutz sowie bei Bedarf der Hinweis auf Ihre AGB erscheint. müssen Sie diese einblenden. Den Inhalt des Textes können Sie flexibel an Ihr Unternehmen anpassen. Das System wandelt automatisiert die Worte "),_c('b',[_vm._v("AGB")]),_vm._v(", "),_c('b',[_vm._v("Allgemeinen Geschäftsbedingungen")]),_vm._v(" sowie "),_c('b',[_vm._v("Datenschutzerklärung")]),_vm._v(" in Links um. Die Inhalte zu den AGB sowie der Datenschutzerklärung finden sie in den "),_c('CLink',{attrs:{"href":"/settings/general","target":"_blank"}},[_vm._v("Grundeinstellungen")]),_vm._v(" unter dem Reiter \"AGB & Datenschutz\". ")],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{staticClass:"mr-3",attrs:{"color":"success","variant":"outline"},on:{"click":function($event){_vm.modalVisible = false}}},[_vm._v(" Schließen ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }