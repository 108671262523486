<template>
  <div>
    <!-- Hauptkarte mit allen Accounts -->
    <CCard>
      <CCardHeader>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="mb-0">
            <CIcon name="cil-grid" />
            Alle Accounts
          </h2>
          <CButton color="primary" @click="showAddAccountModal">
            <CIcon name="cil-plus" />
            Neuen Account hinzufügen
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <div class="mb-3">
          <CInput
            placeholder="Suche..."
            v-model="searchQuery"
            @input="onSearch"
          />
        </div>
        <CDataTable
          :items="filteredTableData"
          :fields="tableFields"
          hover
          striped
          responsive
          :items-per-page="10"
          pagination
        >
          <template #status="{ item }">
            <CBadge :color="getStatusColor(item.status)">
              {{ item.status }}
            </CBadge>
          </template>
          <template #actions="{ item }">
            <CButton
              :to="'organizations/edit/' + item.id"
              color="info"
              size="sm"
              class="mr-1"
              v-c-tooltip="'Bearbeiten'"
            >
              <CIcon name="cil-pencil" />
            </CButton>
            <CButton
              color="danger"
              size="sm"
              @click="showDeleteConfirmation(item.id)"
              v-c-tooltip="'Löschen'"
              class="mr-1"
            >
              <CIcon name="cil-trash" />
            </CButton>
            <!-- Neuer Support-Login Button -->
            <CButton
              v-if="$commons.isSystemAdmin()"
              color="success"
              size="sm"
              @click="showRealmLoginSelection(item)"
              v-c-tooltip="'Support-Login'"
            >
              <CIcon name="cil-user" />
            </CButton>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <!-- Delete Confirmation Modal -->
    <CModal
      v-if="showDeleteModal"
      :show="true"
      @update:show="showDeleteModal = false"
      color="danger"
      centered
      title="Account löschen"
    >
      <p>Sind Sie sicher, dass Sie diesen Account löschen möchten?</p>
      <template #footer>
        <CButton color="secondary" @click="showDeleteModal = false">
          Abbrechen
        </CButton>
        <CButton color="danger" @click="handleDelete">Löschen</CButton>
      </template>
    </CModal>

    <!-- Progress Modal -->
    <CModal
      v-if="showProgressModal"
      :show="true"
      @update:show="showProgressModal = false"
      color="primary"
      centered
      title="Löschvorgang läuft"
    >
      <CProgress :max="100" :value="deleteProgress" show-percentage animated />
      <p class="mt-3">{{ progressMessage }}</p>
    </CModal>

    <!-- Notification Toast -->
    <CToaster :show="showNotification" position="top-right" :autohide="5000">
      <CToast :show="showNotification" color="info">
        <template #header>
          <strong>Benachrichtigung</strong>
        </template>
        <div class="d-flex">
          <CIcon name="cil-info" class="mr-3" />
          <div>{{ notificationMessage }}</div>
        </div>
      </CToast>
    </CToaster>

    <!-- Realm Selection Modal -->
    <CModal
      v-if="showRealmSelection"
      :show="true"
      @update:show="showRealmSelection = false"
      color="primary"
      centered
      title="Realm auswählen"
    >
      <p>
        Die Organisation hat mehrere Realms. Wählen Sie die Realms aus, die Sie
        löschen möchten.
      </p>
      <CListGroup>
        <CListGroupItem
          v-for="realm in realmOptions"
          :key="realm.id"
          :active="selectedRealmIds.includes(realm.id)"
          @click="toggleRealmSelection(realm.id)"
        >
          {{ realm.name }} (ID: {{ realm.id }}, License: {{ realm.license }})
        </CListGroupItem>
      </CListGroup>

      <template #footer>
        <CButton color="secondary" @click="showRealmSelection = false">
          Abbrechen
        </CButton>
        <CButton
          color="danger"
          @click="deleteSelectedRealms"
          :disabled="!selectedRealmIds.length"
        >
          Ausgewählte Realms löschen
        </CButton>
      </template>
    </CModal>

    <!-- Neues Realm-Login Selection Modal -->
    <CModal
      v-if="showRealmLoginModal"
      :show="true"
      @update:show="showRealmLoginModal = false"
      color="primary"
      centered
      title="Support-Login - Realm auswählen"
    >
      <div v-if="selectedOrganization">
        <h5 class="mb-3">{{ selectedOrganization.name }}</h5>
        <CCard v-for="realm in realmUsers" :key="realm.id" class="mb-3">
          <CCardHeader>
            <h6 class="mb-0">{{ realm.name }}</h6>
          </CCardHeader>
          <CCardBody>
            <CListGroup>
              <!-- Wenn keine Benutzer vorhanden sind -->
              <CListGroupItem v-if="!realm.users || realm.users.length === 0" class="text-muted">
                Keine Benutzer verfügbar
              </CListGroupItem>

              <!-- Liste der Benutzer -->
              <CListGroupItem
                v-for="user in realm.users"
                :key="user.id"
                class="d-flex justify-content-between align-items-center hover-highlight"
              >
                <div>
                  <strong>{{ user.username }}</strong>
                  <div class="small text-muted">{{ user.email }}</div>
                </div>
                <CButton
                  color="success"
                  size="sm"
                  @click="performSupportLogin(user.id)"
                  class="ml-3"
                >
                  <CIcon name="cil-arrow-right" class="me-2" />
                  Login
                </CButton>
              </CListGroupItem>
            </CListGroup>
          </CCardBody>
        </CCard>
      </div>

      <template #footer>
        <CButton color="secondary" @click="showRealmLoginModal = false">
          Schließen
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";

export default {
  name: "Organizations",
  data() {
    return {
      tableData: [],
      searchQuery: "",
      showDeleteModal: false,
      showRealmSelection: false,
      showRealmLoginModal: false,
      selectedRealmIds: [],
      selectedOrganizationId: null,
      selectedOrganization: null,
      realmOptions: [],
      realmUsers: [],
      tableFields: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "contactPhone", label: "Kontakttelefon" },
        { key: "contactAddress", label: "Kontaktadresse" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Aktionen" },
      ],
      showProgressModal: false,
      deleteProgress: 0,
      progressMessage: "",
      showNotification: false,
      notificationMessage: "",
    };
  },

  computed: {
    filteredTableData() {
      if (!this.searchQuery) return this.tableData;
      return this.tableData.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      );
    },
  },

  async mounted() {
    this.fetchAccounts();
  },

  methods: {
    fetchAccounts() {
      axios
        .get("/organization")
        .then((response) => {
          this.tableData = response.data;
        })
        .catch((err) =>
          console.error("Problem while fetching organizations", err)
        );
    },

    getStatusColor(status) {
      const colors = {
        ACTIVE: "success",
        INACTIVE: "secondary",
        PENDING: "warning",
        BANNED: "danger",
      };
      return colors[status] || "primary";
    },

    showAddAccountModal() {
      console.log("Navigating to new organization form");
      this.$router.push({ name: "New organizations" });
    },

    editAccount(id) {
      this.$router.push({ name: "EditOrganization", params: { id: id } });
    },

    showDeleteConfirmation(id) {
      this.selectedOrganizationId = id;
      this.showDeleteModal = true;
    },

    async showRealmLoginSelection(organization) {
      this.selectedOrganization = organization;
      this.showRealmLoginModal = true;

      try {
        // Hole alle Realms und User der Organisation
        const response = await axios.get(`/organization/${organization.id}/realms-users`);
        this.realmUsers = response.data;
      } catch (error) {
        console.error('Fehler beim Laden der Realm-Benutzer:', error);
        this.showNotification = true;
        this.notificationMessage = 'Fehler beim Laden der Benutzer.';
      }
    },

    async performSupportLogin(userId) {
      try {
        console.log('Starting support login process');

        const currentToken = commons.getFromStorage('jwtToken');
        console.log('Current JWT token:', currentToken);

        if (!currentToken) {
          console.error('No JWT token found!');
          return;
        }

        const response = await axios.get(`/user/support-login/${userId}`, {
          headers: { Authorization: `Bearer ${currentToken}` }
        });
        const supportToken = response.data.loginUrl;

        const loginResponse = await axios.post('/user/support-login', {
          token: supportToken
        }, {
          headers: { Authorization: `Bearer ${currentToken}` }
        });

        if (loginResponse.data) {
          console.log('Login response received:', loginResponse.data);

          // Support mode info als Objekt speichern (NICHT als String)
          const supportModeInfo = {
            originalToken: currentToken,
            startTime: new Date().toISOString()
          };

          console.log('Saving support mode info:', supportModeInfo);

          // Direkt als Objekt speichern
          commons.setToStorage('supportMode', supportModeInfo);

          // Neue Benutzerdaten setzen
          commons.setToStorage('jwtToken', loginResponse.data.token);
          commons.setToStorage('currentUser', loginResponse.data.user);
          commons.setToStorage('currentRealm', loginResponse.data.realm);
          commons.setToStorage('license', loginResponse.data.realm.license);
          commons.setToStorage('unlayerSignatureHmac', loginResponse.data.unlayerSignatureHmac);

          console.log('Final support mode info:', commons.getFromStorage('supportMode'));
          console.log('Final user info:', commons.getFromStorage('currentUser'));

          window.location.href = '/dashboard';
        }
      } catch (error) {
        console.error('Fehler beim Support-Login:', error);
        this.showNotification = true;
        this.notificationMessage = 'Fehler beim Support-Login: ' +
          (error.response?.data?.message || error.message);
      }
    },

    handleDelete() {
      this.showDeleteModal = false;
      this.showProgressModal = true;
      this.deleteProgress = 0;
      this.progressMessage = "Löschvorgang gestartet...";

      this.simulateAsyncDelete(this.selectedOrganizationId);
    },

    simulateAsyncDelete(id) {
      const totalSteps = 10;
      let currentStep = 0;

      const updateProgress = () => {
        currentStep++;
        this.deleteProgress = (currentStep / totalSteps) * 100;
        this.progressMessage = `Löschvorgang läuft (${currentStep}/${totalSteps})...`;

        if (currentStep < totalSteps) {
          setTimeout(updateProgress, 1000);
        } else {
          this.finishDelete(id);
        }
      };

      updateProgress();
    },

    finishDelete(id) {
      axios
        .delete(`/organization/${id}`)
        .then(() => {
          this.tableData = this.tableData.filter((item) => item.id !== id);
          this.deleteProgress = 100;
          this.showProgressModal = false;
          this.showNotification = true;
          this.notificationMessage = "Account erfolgreich gelöscht.";
        })
        .catch((error) => {
          this.showProgressModal = false;

          if (error.response && error.response.status === 409) {
            this.realmOptions = error.response.data;
            this.showRealmSelection = true;
            this.notificationMessage =
              "Konflikt: Wählen Sie die zu löschenden Realms aus.";
          } else {
            this.showNotification = true;
            this.notificationMessage = "Fehler beim Löschen des Accounts.";
            console.error("Problem beim Löschen der Organisation", error);
          }
        });
    },

    toggleRealmSelection(id) {
      const index = this.selectedRealmIds.indexOf(id);
      if (index > -1) {
        this.selectedRealmIds.splice(index, 1);
      } else {
        this.selectedRealmIds.push(id);
      }
    },

    deleteSelectedRealms() {
      this.showRealmSelection = false;
      this.showProgressModal = true;
      this.deleteProgress = 0;
      this.progressMessage = "Löschvorgang gestartet...";

      const totalSteps = this.selectedRealmIds.length;
      let completedSteps = 0;

      const deletePromises = this.selectedRealmIds.map((realmId) =>
        axios.delete(
          `/organization/${this.selectedOrganizationId}/realm/${realmId}`
        )
      );

      deletePromises.forEach((promise) => {
        promise
          .then(() => {
            completedSteps++;
            this.deleteProgress = (completedSteps / totalSteps) * 100;
            this.progressMessage = `Löschvorgang läuft (${completedSteps}/${totalSteps})...`;

            if (completedSteps === totalSteps) {
              this.showProgressModal = false;
              this.selectedRealmIds = [];
              this.notificationMessage =
                "Ausgewählte Realms erfolgreich gelöscht.";
              this.showNotification = true;
            }
          })
          .catch((err) => {
            console.error("Problem beim Löschen der Realms", err);
            this.showProgressModal = false;
            this.notificationMessage =
              "Fehler beim Löschen der ausgewählten Realms.";
            this.showNotification = true;
          });
      });
    },

    onSearch() {
      // Die Filterung wird durch die berechnete Eigenschaft `filteredTableData` gehandhabt
    },
  },
};
</script>

<style scoped>
.hover-highlight:hover {
  background-color: #f8f9fa;
}

.modal {
  z-index: 1050 !important;
}

.modal-backdrop {
  z-index: 1040 !important;
}

.btn-success {
  min-width: 80px;
}
</style>
