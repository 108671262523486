var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"extra-steps-component"},[_c('h3',[_vm._v(" Individuelle Fragen "),_c('small',[_c('CBadge',{attrs:{"color":"success","shape":"pill"}},[_vm._v("Neu")])],1)]),_c('p',[_vm._v(" Legen Sie eigene Fragen im Leadgenerator an. Sie haben die Möglichkeit individuelle Zu & Abschläge für jede Antwortmöglichkeit zu definieren (z.B. wenn Sie den Objektzustand abfragen, -15% bei renovierungsbedürftig, 0% bei mittel, +5% bei gepflegt) ")]),_c('div',{staticClass:"extra-steps-wrapper"},[_vm._l((_vm.extraSteps),function(extraStep){return _c('div',{staticClass:"extra-step",on:{"click":function($event){return _vm.editExtraStep(extraStep)}}},[_c('h4',[_vm._v(_vm._s(extraStep.title))]),_c('hr'),_c('p',{staticStyle:{"font-weight":"bold","margin-bottom":"6px"}},[_vm._v(" "+_vm._s((extraStep.answers || []).length)+" "+_vm._s((extraStep.answers || []).length === 1 ? "Antwort" : "Antworten")+" ")]),_c('p',{staticStyle:{"font-size":"13px","font-style":"italic"}},[_vm._v(" "+_vm._s(extraStep.activeTypes .map((type) => _vm.$commons.LEAD_TYPES[type.toUpperCase()].title) .join(", "))+" ")])])}),_c('div',{staticClass:"add-extra-step",on:{"click":function($event){_vm.modalIsAdd = true;
        _vm.showExtraStepModal = true;}}},[_c('CIcon',{attrs:{"name":"cil-plus"}})],1)],2),_c('CModal',{staticStyle:{"text-align":"left"},attrs:{"title":_vm.modalIsAdd ? 'Extra Schritt erstellen' : 'Extra Schritt bearbeiten',"show":_vm.showExtraStepModal},on:{"update:show":function($event){_vm.showExtraStepModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.cancelModal}},[_vm._v(" Abbrechen ")]),(!_vm.modalIsAdd)?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":_vm.deleteExtraStep}},[_vm._v(" Löschen ")]):_vm._e(),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.confirmModal}},[_vm._v(" Speichern! ")])]},proxy:true}])},[_c('CInput',{attrs:{"label":"Titel","placeholder":"...zB. Ist Ihre Immobilie renovierungsbedürftig?"},model:{value:(_vm.modalContent.title),callback:function ($$v) {_vm.$set(_vm.modalContent, "title", $$v)},expression:"modalContent.title"}}),_c('ExtraStepsAddStepList',{attrs:{"label":"Auswahlmöglichkeiten","horizontal":false,"description":"Hinterlegen Sie individuelle Fragen zur Vorqualifizierung ihrer potenziellen Kunden"},model:{value:(_vm.modalContent.answers),callback:function ($$v) {_vm.$set(_vm.modalContent, "answers", $$v)},expression:"modalContent.answers"}}),_c('Checkbox',{attrs:{"label":"Immobilientypen","description":"Zu welchen Immobilientypen soll dieser Extra Schritt angezeigt werden?","name":"activeTypes","horizontal":false,"options":[
        { label: 'Wohnung', value: 'apartment' },
        { label: 'Haus', value: 'house' },
        { label: 'Grundstück (keine Wertermittlung)', value: 'land' },
        { label: 'Miete', value: 'rent' },
        { label: 'Gewerbe (keine Wertermittlung)', value: 'business' },
      ]},model:{value:(_vm.modalContent.activeTypes),callback:function ($$v) {_vm.$set(_vm.modalContent, "activeTypes", $$v)},expression:"modalContent.activeTypes"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }