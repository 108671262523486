<template>
  <CModal :title="title" :show.sync="show" style="text-align: left">
    <div v-for="item in entities" :key="item">
      <p v-html="item" />
    </div>
    <template v-slot:footer>
      <button
        type="button"
        :class="'btn btn-' + options.btnCancelColor"
        @click="cancelEvent"
      >
        {{ options.btnCancel }}
      </button>
      <button
        type="button"
        :class="'btn btn-' + options.btnConfirmColor"
        @click="confirmEvent"
      >
        {{ options.btnConfirm }}
      </button>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "DefaultModal",
  data() {
    return {
      show: false,
      title: null,
      entities: [],
      options: {
        btnCancel: "Abbrechen",
        btnConfirm: "Zustimmen!",
        btnCancelColor: "secondary",
        btnConfirmColor: "success",
        isChoice: false
      },
      onCancel: null,
      onConfirm: null,
    };
  },
  mounted() {
    this.resetModal();
  },
  methods: {
    hideModal() {
      this.show = false;
    },
    resetModal() {
      this.title = "Sind Sie sicher?";
      this.entities = [];
      this.onCancel = () => {
        this.hideModal();
      };
      this.onConfirm = () => {
        this.hideModal();
      };
      this.options = {
        btnCancel: "Abbrechen",
        btnConfirm: "Zustimmen!",
        btnCancelColor: "secondary",
        btnConfirmColor: "success",
        isChoice: false
      };
    },
    cancelEvent() {
      if (this.options.isChoice && this.onConfirm) {
        // Business wurde gewählt (Cancel Button)
        this.onConfirm(false);
      } else if (this.onCancel) {
        this.onCancel();
      }
      this.hideModal();
    },
    confirmEvent() {
      if (this.options.isChoice) {
        // Basic wurde gewählt (Confirm Button)
        this.onConfirm(true);
      } else if (this.onConfirm) {
        this.onConfirm();
      }
      this.hideModal();
    }
  },
  watch: {
    show(newVal) {
      if (newVal === false) {
        setTimeout(this.resetModal, 300);
      }
    },
  },
};
</script>
