import DefaultModal from "@/modals/DefaultModal";
import DeleteModal from "@/modals/DeleteModal";
import LoadingModal from "@/modals/LoadingModal";

const modals = {
  default: null,
  delete: null,
  loading: null,
  choice: null
};

function resetModalData(modal) {
  if (!modal) return;

  modal.$data.title = '';
  modal.$data.entities = [];
  modal.$data.onConfirm = null;
  modal.$data.onCancel = null;
  modal.$data.options = {};
  modal.$data.show = false;
}

function showModal(modal, title, entities, onConfirm, onCancel, options) {
  // Zuerst alle Modals zurücksetzen
  Object.values(modals).forEach(modalInstance => {
    if (modalInstance) {
      resetModalData(modalInstance);
    }
  });

  // Wählen Sie das richtige Modal aus
  let chosen = null;
  if (modal === 'choice') {
    chosen = modals.default;
    // Spezielle Behandlung für Choice Modal
    if (!options) options = {};
    options.isChoice = true;  // Flag hinzufügen um zu kennzeichnen, dass es ein Choice Modal ist
  } else {
    chosen = modals[modal];
  }

  if (!chosen) {
    console.error('Modal type not found:', modal);
    return;
  }

  // Setzen Sie die neuen Werte
  if (title) chosen.$data.title = title;
  if (entities) chosen.$data.entities = entities;
  if (onConfirm) chosen.$data.onConfirm = onConfirm;
  if (onCancel) chosen.$data.onCancel = onCancel;
  if (options) {
    chosen.$data.options = Object.assign({}, chosen.$data.options, options);
  }
  chosen.$data.show = true;
}

function showModalOnly(modal) {
  resetModalData(modals[modal]);
  let chosen = modals[modal];
  chosen.$data.show = true;
}

function hideModal(modal) {
  let chosen = modals[modal];
  resetModalData(chosen);
}

export default {
  install(Vue, options) {
    let DefaultModalComp = Vue.extend(DefaultModal);
    modals.default = new DefaultModalComp();
    modals.default.$mount();
    document.body.appendChild(modals.default.$el);

    let DeleteModalComp = Vue.extend(DeleteModal);
    modals.delete = new DeleteModalComp();
    modals.delete.$mount();
    document.body.appendChild(modals.delete.$el);

    let LoadingModalComp = Vue.extend(LoadingModal);
    modals.loading = new LoadingModalComp();
    modals.loading.$mount();
    document.body.appendChild(modals.loading.$el);

    Vue.prototype.$modal = {
      showModal,
      showModalOnly,
      hideModal,
    };
  },
};
