<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="mb-0">
                <CIcon name="cil-chart" class="mr-2" />
                Statistiken
              </h4>
              <CDropdown :toggler-text="selectedPeriod" color="primary">
                <CDropdownItem
                  v-for="period in periods"
                  :key="period"
                  @click="setPeriod(period)"
                  :active="selectedPeriod === period"
                >
                  {{ period }}
                </CDropdownItem>
              </CDropdown>
            </div>
          </CCardHeader>
          <CCardBody>
            <!-- MetricCards Section -->
            <div v-if="loadingStates.metrics.loading">
              <LoadingState
                component="metrics"
                :progress="loadingStates.metrics.progress"
              />
            </div>
            <CRow v-else>
              <CCol sm="6">
                <MetricCard
                  title="Gesamte Besucher"
                  :value="totalVisitors"
                  description="Seit Beginn"
                  icon="cil-people"
                  color="info"
                  :extraInfo="visitorsChange"
                />
              </CCol>
              <CCol sm="6">
                <MetricCard
                  title="Besucher im Zeitraum"
                  :value="periodVisitors"
                  :description="selectedPeriod"
                  icon="cil-user-follow"
                  color="success"
                  :extraInfo="topSource"
                />
              </CCol>
            </CRow>

            <!-- Funnel Section -->
            <div v-if="loadingStates.charts.loading">
              <LoadingState
                component="charts"
                :progress="loadingStates.charts.progress"
              />
            </div>
            <CRow v-else class="mt-4">
              <CCol sm="12">
                <CCard>
                  <CCardHeader>
                    <strong>Conversion Funnel</strong>
                  </CCardHeader>
                  <CCardBody>
                    <CFunnel v-if="funnelData" :data="funnelData" />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>

            <!-- Charts Section -->
            <div v-if="loadingStates.charts.loading">
              <LoadingState
                component="charts"
                :progress="loadingStates.charts.progress"
              />
            </div>
            <CRow v-else class="mt-4">
              <CCol sm="6">
                <CCard>
                  <CCardHeader>
                    <strong>Besucher-Trend</strong>
                  </CCardHeader>
                  <CCardBody>
                    <LineChart
                      v-if="visitorTrendData.datasets.length > 0"
                      :chart-data="visitorTrendData"
                      :options="lineChartOptions"
                    />
                    <div v-else class="text-center p-4">
                      <p>Keine Daten für den ausgewählten Zeitraum verfügbar.</p>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
              <CCol sm="6">
                <CCard>
                  <CCardHeader>
                    <strong>Gerätetypen</strong>
                  </CCardHeader>
                  <CCardBody>
                    <PieChart
                      :chart-data="deviceTypeData"
                      :options="pieChartOptions"
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>

            <!-- Table Section -->
            <div v-if="loadingStates.table.loading">
              <LoadingState
                component="table"
                :progress="loadingStates.table.progress"
              />
            </div>
            <div v-else class="mt-4">
              <CRow>
                <CCol sm="12">
                  <CCard>
                    <CCardHeader>
                      <strong>Besucherdetails</strong>
                    </CCardHeader>
                    <CCardBody>
                      <div
                        v-if="!tableShowLoading && tableData.length === 0"
                        class="text-center p-4"
                      >
                        <p>Es sind derzeit keine Besucherdaten verfügbar.</p>
                        <p>
                          Bitte überprüfen Sie die Filtereinstellungen oder
                          versuchen Sie es später erneut.
                        </p>
                      </div>
                      <CDataTable
                        v-else
                        :items="tableData"
                        :fields="getTableFields()"
                        :items-per-page="itemsPerPage"
                        :loading="loadingStates.table.loading"
                        hover
                        striped
                        bordered
                        responsive
                        :sorter="false"
                      >
                        <template #lastStatistic.date="{ item }">
                          {{ item['lastStatistic.date'] }}
                        </template>
                      </CDataTable>
                      <CPagination
                        v-if="pagination && pagination.totalPages > 1"
                        :activePage="pagination.activePage"
                        :pages="pagination.totalPages"
                        @update:activePage="activePageChanged"
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import MetricCard from "@/components/MetricCard.vue";
import LineChart from "@/components/LineChart.vue";
import PieChart from "@/components/PieChart.vue";
import CFunnel from "@/components/CFunnel.vue";
import LoadingState from "@/components/loaders/LoadingState.vue";
import TimeFormatterMixin from "@/mixins/TimeFormatterMixin";
import moment from "moment";
import axios from "axios";

export default {
  name: "Statistics",
  components: {
    MetricCard,
    PieChart,
    LineChart,
    CFunnel,
    LoadingState
  },
  mixins: [TimeFormatterMixin],

  data() {
    return {
      selectedPeriod: "Letzte 7 Tage",
      periods: [
        "Letzte 24 Stunden",
        "Letzte 7 Tage",
        "Letzte 30 Tage",
        "Letzte 3 Monate",
      ],
      loadingStates: {
        metrics: { loading: true, progress: 0 },
        charts: { loading: true, progress: 0 },
        table: { loading: true, progress: 0 }
      },
      tableData: [],
      tableShowLoading: true,
      itemsPerPage: 10,
      pagination: {
        activePage: 1,
        totalPages: 1,
        totalElements: 0,
      },
      totalVisitors: 0,
      periodVisitors: 0,
      visitorsChange: "N/A",
      topSource: "N/A",
      visitorTrend: [],
      deviceTypeDistribution: {},
      funnelData: null,
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                },
              },
            },
          ],
        },
      },
      pieChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  computed: {
    visitorTrendData() {
      const labels = this.visitorTrend.map((item) =>
        moment(item.date).format("DD.MM.YYYY")
      );
      const data = this.visitorTrend.map((item) => item.count);

      return {
        labels,
        datasets: [
          {
            label: "Besucher",
            data,
            borderColor: "#42A5F5",
            fill: false,
          },
        ],
      };
    },

    deviceTypeData() {
      const translations = {
        Desktop: "Desktop",
        Mobile: "Mobil",
        Tablet: "Tablet",
        "Smart TV": "Smart TV",
        Console: "Spielkonsole",
        Unknown: "Unbekannt",
      };

      const translatedData = {};
      Object.entries(this.deviceTypeDistribution).forEach(([key, value]) => {
        translatedData[translations[key] || key] = value;
      });

      return {
        labels: Object.keys(translatedData),
        datasets: [
          {
            data: Object.values(translatedData),
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
            ],
          },
        ],
      };
    },
  },

  methods: {
    translateDevice(device) {
      const translations = {
        Desktop: "Desktop",
        Mobile: "Mobil",
        Tablet: "Tablet",
        "Smart TV": "Smart TV",
        Console: "Spielkonsole",
        Unknown: "Unbekannt",
      };
      return translations[device] || device;
    },

    translateDeviceClass(deviceClass) {
      const translations = {
        Desktop: "Desktop",
        Mobile: "Mobil",
        Tablet: "Tablet",
        TV: "Fernseher",
        Console: "Spielkonsole",
        Unknown: "Unbekannt",
      };
      return translations[deviceClass] || deviceClass;
    },

    getCountryName(countryCode) {
      const countries = {
        DE: "Deutschland",
        AT: "Österreich",
        CH: "Schweiz",
        GB: "Großbritannien",
        US: "USA",
      };
      return countries[countryCode] || countryCode;
    },

    getLanguageName(langCode) {
      const languages = {
        de: "Deutsch",
        en: "Englisch",
        fr: "Französisch",
        es: "Spanisch",
        it: "Italienisch",
      };
      return languages[langCode] || langCode;
    },

    getStepName(step) {
      if (!step || step === "-") return "-";
      return step
        .replace("Haus - ", "")
        .replace("Wohnung - ", "")
        .replace("Grundstück - ", "")
        .replace("Gewerbe - ", "");
    },

    async fetchData() {
      // Setze alle Loading-States zurück
      this.loadingStates.metrics.loading = true;
      this.loadingStates.charts.loading = true;
      this.loadingStates.table.loading = true;
      this.loadingStates.metrics.progress = 0;
      this.loadingStates.charts.progress = 0;
      this.loadingStates.table.progress = 0;

      try {
        const dateParams = this.getDateParams("iso");

        // Dashboard Request mit Progress
        const dashboardResponse = await axios.get("/statistic/dashboard", {
          params: dateParams,
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.loadingStates.metrics.progress = progress;
            }
          }
        });

        // Funnel Request mit Progress
        const funnelResponse = await axios.get("/statistic/funnel", {
          params: dateParams,
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.loadingStates.charts.progress = progress;
            }
          }
        });

        // Table Request mit Progress
        const tableResponse = await this.fetchTableData();

        this.processDashboardData(dashboardResponse.data);
        this.funnelData = funnelResponse.data;
        this.processTableData(tableResponse);

      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
        this.$toast.error("Fehler beim Laden der Daten");
      } finally {
        // Setze Loading-States zurück
        this.loadingStates.metrics.loading = false;
        this.loadingStates.charts.loading = false;
        this.loadingStates.table.loading = false;
      }
    },

    async fetchTableData() {
      try {
        const dateParams = this.getDateParams("iso");

        const response = await axios.get("/statistic", {
          params: {
            startDate: dateParams.startDate,
            endDate: dateParams.endDate,
            page: this.pagination.activePage - 1,
            size: this.itemsPerPage,
          },
          onDownloadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              this.loadingStates.table.progress = progress;
            }
          }
        });

        return response;
      } catch (error) {
        console.error("Fehler beim Abrufen der Tabellendaten:", error);
        this.$toast?.error("Fehler beim Laden der Tabellendaten");
        return null;
      }
    },

    getDateParams(type = "iso") {
      const periodMap = {
        "Letzte 24 Stunden": [1, "days"],
        "Letzte 7 Tage": [7, "days"],
        "Letzte 30 Tage": [30, "days"],
        "Letzte 3 Monate": [3, "months"],
      };

      const [amount, unit] = periodMap[this.selectedPeriod];
      const end = moment().endOf("day");
      const start = moment().subtract(amount, unit).startOf("day");

      if (type === "unix") {
        return {
          startDate: start.unix(),
          endDate: end.unix(),
        };
      }

      return {
        startDate: start.format(),
        endDate: end.format(),
      };
    },
    processDashboardData(data) {
      this.totalVisitors = data.totalVisitors || 0;
      this.periodVisitors = data.periodVisitors || 0;
      this.deviceTypeDistribution = data.deviceTypeDistribution || {};
      this.visitorTrend = data.visitorTrend || [];
      this.visitorsChange = data.visitorsChange || "N/A";
      this.topSource = data.topSource || "N/A";
    },

    processTableData(response) {
      if (!response?.data) {
        this.tableData = [];
        this.pagination.totalPages = 1;
        return;
      }

      const { content, totalPages, totalElements } = response.data;

      if (content && Array.isArray(content)) {
        this.tableData = content.map((item) => ({
          id: item.id,
          "lastStatistic.date": item.lastStatistic
            ? moment(item.lastStatistic.date).format("DD.MM.YYYY HH:mm")
            : "-",
          "lastStatistic.step": this.getStepName(
            item.lastStatistic?.step || "-"
          ),
          "lastStatistic.utmSource": item.lastStatistic?.utmSource || "-",
          "lastStatistic.utmMedium": item.lastStatistic?.utmMedium || "-",
          "lastStatistic.utmCampaign": item.lastStatistic?.utmCampaign || "-",
          "lastStatistic.utmTerm": item.lastStatistic?.utmTerm || "-",
          "lastStatistic.utmContent": item.lastStatistic?.utmContent || "-",
          browser: item.browser || "-",
          operatingSystem: item.operatingSystem || "-",
          device: this.translateDevice(item.device) || "-",
          deviceClass: this.translateDeviceClass(item.deviceClass) || "-",
          country: this.getCountryName(item.country) || "-",
          city: item.city || "-",
          language: this.getLanguageName(item.language) || "-",
          ip: item.ip || "-",
          rawDate: item.lastStatistic?.date || "", // Für Sortierung
        }));

        this.pagination = {
          ...this.pagination,
          totalPages: totalPages || 1,
          totalElements: totalElements || 0,
        };
      } else {
        this.tableData = [];
        this.pagination.totalPages = 1;
        this.pagination.totalElements = 0;
      }
    },

    getTableFields() {
      return [
        {
          key: "lastStatistic.date",
          label: "Datum",
          _classes: 'font-weight-bold'
        },
        { key: "browser", label: "Browser" },
        { key: "operatingSystem", label: "Betriebssystem" },
        { key: "device", label: "Gerät" },
        { key: "deviceClass", label: "Gerätetyp" },
        { key: "country", label: "Land" },
        { key: "city", label: "Stadt" },
        { key: "language", label: "Sprache" },
        { key: "lastStatistic.step", label: "Schritt" },
        { key: "lastStatistic.utmSource", label: "UTM Quelle" },
        { key: "lastStatistic.utmMedium", label: "UTM Medium" },
        { key: "lastStatistic.utmCampaign", label: "UTM Kampagne" },
        { key: "lastStatistic.utmTerm", label: "UTM Begriff" },
        { key: "lastStatistic.utmContent", label: "UTM Inhalt" },
        { key: "ip", label: "IP-Adresse" }
      ];
    },

    setPeriod(period) {
      this.selectedPeriod = period;
      this.pagination.activePage = 1; // Zurück zur ersten Seite
      this.fetchData();
    },

    async activePageChanged(newPage) {
      this.pagination.activePage = newPage;
      const response = await this.fetchTableData();
      this.processTableData(response);
    },
  },

  mounted() {
    this.fetchData();
  },

  beforeDestroy() {
    // Optional: Cleanup oder Reset der Loading States
    this.loadingStates = {
      metrics: { loading: false, progress: 0 },
      charts: { loading: false, progress: 0 },
      table: { loading: false, progress: 0 }
    };
  }
};
</script>

<style scoped>
.statistics-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Optional: Animate transitions */
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
