<template>
  <div class="settings-container">
    <CRow>
      <CCol lg="12">
        <!-- Installation Card -->
        <CCard class="mb-4">
          <CCardHeader class="d-flex align-items-center">
            <div>
              <h4 class="mb-1">Wertermittlungsformular-Einstellungen</h4>
              <p class="text-muted mb-0">
                Konfigurieren Sie das Aussehen und die Inhalte Ihres Leadgenerators
              </p>
            </div>
          </CCardHeader>
          <CCardBody v-if="isNotKensingtonOrIsButOrgaAdmin">
            <div class="installation-section">
              <h5 class="mb-3">
                <CIcon name="cil-code" class="mr-2" />
                Installation des Leadgenerators
              </h5>
              <p class="text-muted mb-3">
                Fügen Sie den folgenden Code in Ihre Webseite ein, wo das Formular erscheinen soll:
              </p>
              <div class="code-container">
                <pre class="integration-code">
                  <code>
&lt;script type="text/javascript" src="{{ iframeUrl }}/script?key={{ iframeKey }}"&gt;&lt;/script&gt;
&lt;div id="prt-iframe-placeholder"&gt;&lt;/div&gt;
                  </code>
                </pre>
                <CButton
                    color="primary"
                    size="sm"
                    class="copy-button"
                    @click="copyToClipboard"
                >
                  <CIcon name="cil-copy" /> Kopieren
                </CButton>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <!-- Settings Card -->
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Formular-Einstellungen</h4>
            <DoubleBounceLoader v-if="loading || saving" width="20px" height="20px" />
          </CCardHeader>

          <CCardBody v-if="!loading">
            <!-- Modern Tab Navigation -->
            <CTabs @change="handleTabChange" variant="pills" class="settings-tabs">
              <!-- Basic Settings Tab -->
              <CTab>
                <template slot="title">
                  <CIcon name="cil-settings" class="mr-2" />
                  Einstellungen
                </template>

                <div class="tab-content-wrapper">
                  <CRow>
                    <CCol xs="12" xl="10">
                      <!-- Google Maps API Section -->
                      <div class="settings-section" v-if="isNotKensingtonOrIsButOrgaAdmin">
                        <h6 class="section-title">Google Maps Konfiguration</h6>

                        <CInput
                            type="text"
                            label="API-Schlüssel"
                            v-model="iframeSettings.googleMapsApiKey"
                            placeholder="Geben Sie Ihren Google Maps API-Schlüssel ein"
                        >
                          <template v-slot:append>
                            <CButton color="primary" @click="warningModal = true" size="sm">
                              <CIcon name="cil-lightbulb" /> Anleitung
                            </CButton>
                          </template>
                        </CInput>

                        <!-- Google Maps API Modal -->
                        <CModal
                          title="Google Maps API einrichten"
                          :show.sync="warningModal"
                          class="maps-setup-modal"
                        >
                          <div class="setup-content">
                            <div class="step">
                              <h4>1. Google Cloud Console öffnen</h4>
                              <p>Besuchen Sie die <a href="https://console.cloud.google.com" target="_blank">Google Cloud Console</a></p>
                            </div>

                            <div class="step">
                              <h4>2. Neues Projekt anlegen</h4>
                              <ul>
                                <li>Wählen Sie "Neues Projekt"</li>
                                <li>Vergeben Sie einen Namen und Organisation (optional)</li>
                              </ul>
                            </div>

                            <div class="step">
                              <h4>3. Benötigte APIs aktivieren</h4>
                              <p>Unter "APIs & Dienste" > "API-Bibliothek" aktivieren Sie:</p>
                              <div class="api-list">
                                <span class="api-item">Maps JavaScript API</span>
                                <span class="api-item">Places API</span>
                                <span class="api-item">Geocoding API</span>
                              </div>
                            </div>

                            <div class="step">
                              <h4>4. API-Schlüssel generieren</h4>
                              <ul>
                                <li>"APIs & Dienste" > "Anmeldedaten"</li>
                                <li>"+ Anmeldedaten erstellen" > "API-Schlüssel"</li>
                              </ul>
                            </div>

                            <div class="step">
                              <h4>5. Sicherheitseinstellungen</h4>
                              <ul>
                                <li>API-Schlüssel anklicken</li>
                                <li>Bei "Anwendungsbeschränkungen": HTTP-Referrer wählen</li>
                                <li>Ihre Domain(s) eintragen</li>
                                <li>"API-Beschränkungen" auf aktivierte Maps-APIs setzen</li>
                              </ul>
                            </div>

                            <div class="step">
                              <h4>6. Abrechnung einrichten</h4>
                              <div class="billing-info">
                                <p>Sie erhalten monatlich ca. 180€ kostenloses Guthaben. Ein Zahlungsmittel ist für die Aktivierung erforderlich.</p>
                                <div class="info-box">
                                  <i class="cil-info-circle"></i>
                                  Die kostenlose Nutzung reicht für die meisten Anwendungsfälle aus.
                                </div>
                              </div>
                            </div>
                          </div>
                        </CModal>

                      </div>

                      <!-- Benefits Section -->
                      <div class="settings-section">
                        <h6 class="section-title">Vorteile & Alleinstellungsmerkmale</h6>
                        <WysiwygEditor
                            v-model="iframeSettings.benefits"
                            :config="{
                            placeholder: 'Fügen Sie hier Ihre Vorteile ein...',
                            toolbar: ['bold', 'italic', 'list']
                          }"
                        />
                      </div>

                      <!-- Active Property Types Section -->
                      <div class="settings-section">
                        <h6 class="section-title">Aktive Immobilientypen</h6>
                        <div class="property-types-grid">
                          <CCard
                              v-for="option in propertyTypeOptions"
                              :key="option.value"
                              :class="['property-type-card', {
                              'active': iframeSettings.activeSteps.includes(option.value)
                            }]"
                              @click="togglePropertyType(option.value)"
                          >
                            <div class="d-flex align-items-center">
                              <CIcon :name="option.icon" class="mr-2" />
                              <span>{{ option.label }}</span>
                            </div>
                          </CCard>
                        </div>
                      </div>

                      <!-- Additional Settings -->
                      <div class="settings-section">
                        <h6 class="section-title">Weitere Einstellungen</h6>
                        <div class="property-types-grid">
                          <CCard
                              v-for="option in switchOptions"
                              :key="option.key"
                              :class="['property-type-card', { 'active': iframeSettings[option.key] }]"
                              @click="toggleSwitch(option.key)"
                          >
                            <div class="d-flex align-items-center">
                              <CIcon :name="option.icon" class="mr-2" />
                              <span>{{ option.label }}</span>
                            </div>
                          </CCard>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CTab>

              <!-- Fonts Settings Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-text" class="mr-2" />
                  Schriften
                </template>
                <div class="tab-content-wrapper">
                  <CRow>
                    <CCol xs="12" xl="10">
                      <div class="font-settings-grid">
                        <!-- Font Family Selection with Preview -->
                        <div class="font-preview-section">
                          <PrtFontPicker label="Schriftart" v-model="iframeSettings.fontFamily" />

                          <div class="font-preview" :style="{
                              fontFamily: iframeSettings.fontFamily,
                              textTransform: iframeSettings.textTransformations, fontWeight: iframeSettings.fontWeight,
                              color: iframeSettings.fontColor, fontSize: iframeSettings.fontSize + 'px'
                            }">
                            Beispieltext in gewählter Schriftart
                          </div>

                        </div>

                        <!-- Font Size with Visual Slider -->
                        <div class="font-size-section">
                          <label>Schriftgröße</label>
                          <div class="size-slider-container">
                            <input
                                type="range"
                                v-model="iframeSettings.fontSize"
                                min="12"
                                max="24"
                                step="1"
                                class="modern-slider"
                            />
                            <span class="size-value">{{iframeSettings.fontSize}}</span>
                          </div>
                        </div>

                        <!-- Color Selection with Preview -->
                        <div class="color-section">
                          <ColorPicker
                              label="Schriftfarbe"
                              v-model="iframeSettings.fontColor"
                          />
                        </div>

                        <!-- Modern Radio Buttons for Text Transform -->
                        <div class="text-transform-section">
                          <label>Schrifttransformation</label>
                          <div class="transform-options">
                            <button
                                v-for="option in transformOptions"
                                :key="option.value"
                                :class="['transform-btn', { active: iframeSettings.textTransformations === option.value }]"
                                @click="iframeSettings.textTransformations = option.value"
                            >
                              {{option.label}}
                            </button>
                          </div>
                        </div>

                        <div class="text-transform-section">
                          <label>Schriftstärke</label>
                          <div class="transform-options">
                            <button
                                v-for="option in fontWeightOptions"
                                :key="option.value"
                                :class="['transform-btn', { active: iframeSettings.fontWeight === option.value }]"
                                @click="iframeSettings.fontWeight = option.value"
                            >
                              {{option.label}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CTab>

              <!-- Headers Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-bold" class="mr-2" />
                  Überschriften
                </template>
                <div class="tab-content-wrapper">
                  <CRow>
                    <CCol xs="12" xl="10">
                      <div class="headers-grid">
                        <!-- H1 Settings -->
                        <div class="header-preview-card">
                          <h3>Hauptüberschrift (H1)</h3>
                          <div class="header-controls">
                            <div class="size-control">
                              <label>Größe</label>
                              <input
                                  type="range"
                                  v-model="iframeSettings.h1Size"
                                  min="24"
                                  max="48"
                                  step="1"
                                  class="modern-slider"
                              />
                              <span>{{ iframeSettings.h1Size }}px</span>
                            </div>
                            <div class="color-control">
                              <label>Farbe</label>
                              <ColorPicker
                                  v-model="iframeSettings.h1Color"
                                  class="header-color-picker"
                              />
                            </div>
                          </div>
                          <div
                              class="preview-text"
                              :style="{
                fontSize: iframeSettings.h1Size + 'px',
                color: iframeSettings.h1Color
              }"
                          >
                            Beispiel Hauptüberschrift
                          </div>
                        </div>

                        <!-- H2 Settings -->
                        <div class="header-preview-card">
                          <h3>Zwischenüberschrift (H2)</h3>
                          <div class="header-controls">
                            <div class="size-control">
                              <label>Größe</label>
                              <input
                                  type="range"
                                  v-model="iframeSettings.h2Size"
                                  min="18"
                                  max="36"
                                  step="1"
                                  class="modern-slider"
                              />
                              <span>{{ iframeSettings.h2Size }}px</span>
                            </div>
                            <div class="color-control">
                              <label>Farbe</label>
                              <ColorPicker
                                  v-model="iframeSettings.h2Color"
                                  class="header-color-picker"
                              />
                            </div>
                          </div>
                          <div
                              class="preview-text"
                              :style="{
                fontSize: iframeSettings.h2Size + 'px',
                color: iframeSettings.h2Color
              }"
                          >
                            Beispiel Zwischenüberschrift
                          </div>
                        </div>

                        <!-- Weitere Überschriften wie H3, H4, etc. können ähnlich hinzugefügt werden -->
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CTab>

              <!-- Color Settings Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-color-palette" class="mr-2" />
                  Individualisieren
                </template>
                <div class="settings-section">
                  <h6 class="section-title">Individuelle Anpassungen</h6>

                  <GoogleMapsAutocompleteInput
                      label="Google Maps: Startpunkt"
                      description="Bitte geben Sie die Startadresse für Google Maps ein. Hier können Sie auch eine genau Adresse eingeben mit Straße und Hausnummer."
                      v-model="iframeSettings.mapCenter.address"
                      @placechanged="saveMapsCenter"
                      placeholder="Bitte geben Sie die Startadresse für Google Maps ein..."
                      :country="['de', 'at', 'ch']"
                      types=""
                      horizontal
                  />

                  <AddableList
                      label="Fragen zur Qualifizierung Ihrer Leads"
                      description="Hinterlegen Sie individuelle Fragen zur Vorqualifizierung ihrer potenziellen Kunden"
                      v-model="iframeSettings.reasonsWhenBuying"
                      v-if="isNotKensingtonOrIsButOrgaAdmin"
                  />
                  <AddableList
                      label="Fragen zur Qualifizierung Ihrer Leads (Miete)"
                      description="Hinterlegen Sie individuelle Fragen zur Vorqualifizierung ihrer potenziellen Kunden"
                      v-model="iframeSettings.reasonsWhenRenting"
                      v-if="isNotKensingtonOrIsButOrgaAdmin"
                  />
                  <CInput
                      type="number"
                      label="Globaler Auf- und Abschlag (%)"
                      description="Die Werte aller eingehenden Leads werden nach der Wertermittlung mit diesem prozentualen Wert angepasst"
                      v-model="iframeSettings.globalMarkUpOrDown"
                      horizontal
                  />
                  <CInput
                      type="number"
                      label="Verkauf: Auf- und Abschlag (%)"
                      description='Die Werte aller eingehenden Leads werden nach der Wertermittlung mit diesem prozentualen Wert angepasst, wenn der Typ des Leads "Verkauf" ist. Dieser Wert überschreibt den globalen Auf- und Abschlag.'
                      v-model="iframeSettings.markUpOrDownForBuy"
                      horizontal
                  />
                  <CInput
                      type="number"
                      label="Miete: Auf- und Abschlag (%)"
                      description='Die Werte aller eingehenden Leads werden nach der Wertermittlung mit diesem prozentualen Wert angepasst, wenn der Typ des Leads "Miete" ist. Dieser Wert überschreibt den globalen Auf- und Abschlag.'
                      v-model="iframeSettings.markUpOrDownForRent"
                      horizontal
                  />

                  <CInput
                      type="text"
                      label="Extra Schritt: URL"
                      description="Wenn Sie einen Extra Schritt eingerichtet haben, können Sie auch die URL dazu ändern. Wenn die Kund:innen auf dem Extra Schritt landen wird die URL dann in zB. https://www.ihre-url.de/dieser-wert geändert"
                      v-model="iframeSettings.extraSlideRoute"
                      horizontal
                  />

                  <CInput
                      type="text"
                      label="Weiterleitungs URL"
                      description="Sollen die Kund:innen nach Absenden des Leadgenerators auf eine andere Seite weitergeleitet werden? Wenn Ja, geben Sie die URL der Seite an."
                      v-model="iframeSettings.redirectOnSuccessUrl"
                      v-if="isNotKensingtonOrIsButOrgaAdmin"
                      horizontal
                  />

                  <CodeEditor
                      label="Eigenes CSS"
                      v-model="iframeSettings.customCss"
                      language="css"
                  />

                  <WysiwygEditor
                      label="Extra Schritt"
                      v-model="iframeSettings.extraSlide"
                  />
                </div>


              </CTab>

              <!-- Color Settings Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-color-palette" class="mr-2" />
                  Farben
                </template>

                <div class="button-colors-section">
                  <h3>Farben</h3>

                  <div class="button-preview-grid">

                    <div class="button-preview">
                      <ColorPicker
                          label="Primäre Farbe"
                          v-model="iframeSettings.primaryColor"
                      />
                    </div>

                    <div class="button-preview">
                      <ColorPicker
                          label="Sekundäre Farbe"
                          v-model="iframeSettings.secondaryColor"
                      />
                    </div>
                  </div>

                  <h3>Button-Farben</h3>
                  <div class="button-preview-grid">
                    <!-- Weiter Button -->
                    <div class="button-preview">
                      <ColorPicker
                          label="Weiter-Button"
                          v-model="iframeSettings.nextButtonColor"
                      />
                      <button class="preview-btn next-btn" :style="{ backgroundColor: iframeSettings.nextButtonColor }">
                        <CIcon name="cil-arrow-right" class="mr-1" />
                        Weiter
                      </button>
                    </div>

                    <!-- Zurück Button -->
                    <div class="button-preview">
                      <ColorPicker
                          label="Zurück-Button"
                          v-model="iframeSettings.previousButtonColor"
                      />
                      <button class="preview-btn prev-btn" :style="{ backgroundColor: iframeSettings.previousButtonColor }">
                        <CIcon name="cil-arrow-left" class="mr-1" />
                        Zurück
                      </button>
                    </div>

                    <!-- Absenden Button -->
                    <div class="button-preview">
                      <ColorPicker
                          label="Absenden-Button"
                          v-model="iframeSettings.submitButtonColor"
                      />
                      <button class="preview-btn submit-btn" :style="{ backgroundColor: iframeSettings.submitButtonColor }">
                        <CIcon name="cil-check" class="mr-1" />
                        Absenden
                      </button>
                    </div>
                  </div>

                  <!-- Button States Preview -->
                  <div class="button-states-preview">
                    <h4>Button-Zustände</h4>
                    <div class="states-grid">
                      <!-- Normal State -->
                      <div class="state-preview">
                        <span class="state-label">Normal</span>
                        <button
                            class="preview-btn"
                            :style="{ backgroundColor: iframeSettings.nextButtonColor }"
                        >
                          Button
                        </button>
                      </div>

                      <!-- Hover State -->
                      <div class="state-preview">
                        <span class="state-label">Hover</span>
                        <button
                            class="preview-btn hover"
                            :style="{
            backgroundColor: adjustColor(iframeSettings.nextButtonColor, -10)
          }"
                        >
                          Button
                        </button>
                      </div>

                      <!-- Active State -->
                      <div class="state-preview">
                        <span class="state-label">Active</span>
                        <button
                            class="preview-btn active"
                            :style="{
            backgroundColor: adjustColor(iframeSettings.nextButtonColor, -20)
          }"
                        >
                          Button
                        </button>
                      </div>

                      <!-- Disabled State -->
                      <div class="state-preview">
                        <span class="state-label">Disabled</span>
                        <button
                            class="preview-btn disabled"
                            :style="{
            backgroundColor: adjustColor(iframeSettings.nextButtonColor, 40),
            opacity: 0.6
          }"
                            disabled
                        >
                          Button
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </CTab>

              <!-- Text Customization Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-pencil" class="mr-2" />
                  Texte
                </template>
                <div class="tab-content-wrapper">
                  <CRow>
                    <CCol xs="12" xl="10">
                      <CInput
                          label="Start"
                          description="Ändern Sie den Text auf der Startseite"
                          v-model="iframeSettings.text.start"
                          horizontal
                      />
                      <CInput
                          label="Start: Slogan 1"
                          description="Ändern Sie den Slogan auf der Startseite"
                          v-model="iframeSettings.text.startSlogan1"
                          horizontal
                      />
                      <CInput
                          label="Start: Slogan 2"
                          description="Ändern Sie den Slogan auf der Startseite"
                          v-model="iframeSettings.text.startSlogan2"
                          horizontal
                      />
                      <CInput
                          label="Start: Button"
                          description="Ändern Sie den Text des Buttons auf der Startseite"
                          v-model="iframeSettings.text.startButton"
                          horizontal
                      />
                      <CInput
                          label="Region"
                          description="Ändern Sie den Text für den Standort der Immobilie"
                          v-model="iframeSettings.text.region"
                          horizontal
                      />
                      <CInput
                          label="Formular"
                          description="Ändern Sie den Text über dem Kontaktformular."
                          v-model="iframeSettings.text.personalDataForm"
                          horizontal
                      />
                      <CInput
                          label="Schlusssatz"
                          description="Ändern Sie den Dankessatz bei der Wertermittlung"
                          v-model="iframeSettings.text.thanks"
                          horizontal
                      />
                      <CInput
                          label="Deaktivierte Wertermittlung Zeile 1"
                          description="Ändern Sie den Text der 1 Zeile bei deaktivierter Wertermittlung"
                          v-model="iframeSettings.text.deactivatedRate1"
                          horizontal
                      />
                      <CInput
                          label="Deaktivierte Wertermittlung Zeile 2"
                          description="Ändern Sie den Text der 2 Zeile bei deaktivierter Wertermittlung"
                          v-model="iframeSettings.text.deactivatedRate2"
                          horizontal
                      />
                      <CInput
                          label="Fehlgeschlagene Wertermittlung Zeile 1"
                          description="Ändern Sie den Text der 1 Zeile bei fehlgeschlagener Wertermittlung"
                          v-model="iframeSettings.text.notFound1"
                          horizontal
                      />
                      <CInput
                          label="Fehlgeschlagene Wertermittlung Zeile 2"
                          description="Ändern Sie den Text der 2 Zeile bei fehlgeschlagener Wertermittlung"
                          v-model="iframeSettings.text.notFound2"
                          horizontal
                      />
                      <CInput
                          label="Zusatztext"
                          description="Dieser Text wird bei einer erfolgreichen Wertermittlung angezeigt"
                          v-model="iframeSettings.text.extraMessage"
                          horizontal
                      />
                      <CInput
                          label="Grund der Bewertung"
                          description="Dieser Text wird vor allen Schritten angezeigt"
                          v-model="iframeSettings.text.reason"
                          horizontal
                      />
                      <CInput
                          label="Verkaufszeitwunsch"
                          :description="`Dieser Text wird nach dem &quot;Grund der Bewertung&quot; angezeigt, wenn &quot;Verkaufen&quot; ausgewählt wird.`"
                          v-model="iframeSettings.text.reasonWhen"
                          horizontal
                      />
                      <CInput
                          label="Der Wert Ihrer Immobilie wird ermittelt"
                          description="Dieser Text wird im Formular für die Kontaktdaten des Eigentümers angezeigt"
                          v-model="iframeSettings.text.propertyIsRating"
                          horizontal
                      />
                      <CInput
                          label="Der Wert Ihrer Immobilie wurde ermittelt"
                          description="Dieser Text wird im Formular für die Kontaktdaten des Eigentümers angezeigt"
                          v-model="iframeSettings.text.propertyRated"
                          horizontal
                      />
                      <CInput
                          label="Der Wert Ihrer Immobilie beträgt"
                          :description="'Dieser Text wird bei einer erfolgreichen Wertermittlung angezeigt. Hier müssen die Variable {{ WERT }} angeben'"
                          v-model="iframeSettings.text.propertyValueIs"
                          horizontal
                      />
                      <CInput
                          label="E-Mail: Titel"
                          description="Ändern Sie den Titel für den E-Mail Schritt."
                          v-model="iframeSettings.text.emailTitle"
                          horizontal
                      />
                      <CInput
                          label="E-Mail: Inhalt"
                          description="Ändern Sie den Text für den E-Mail Schritt."
                          v-model="iframeSettings.text.emailBody"
                          horizontal
                      />
                      <hr />
                      <CInput
                          label="Wohnung: Wohnfläche"
                          description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Wohnung"
                          v-model="iframeSettings.text.apartment.livingArea"
                          horizontal
                      />
                      <CInput
                          label="Wohnung: Etage"
                          description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Wohnung"
                          v-model="iframeSettings.text.apartment.floor"
                          horizontal
                      />
                      <CInput
                          label="Wohnung: Zimmer"
                          description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Wohnung"
                          v-model="iframeSettings.text.apartment.roomCount"
                          horizontal
                      />
                      <CInput
                          label="Wohnung: Baujahr"
                          description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Wohnung"
                          v-model="iframeSettings.text.apartment.constructionYear"
                          horizontal
                      />
                      <hr />
                      <CInput
                          label="Haus: Grundfläche"
                          description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Haus"
                          v-model="iframeSettings.text.house.siteArea"
                          horizontal
                      />
                      <CInput
                          label="Haus: Art"
                          description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Haus"
                          v-model="iframeSettings.text.house.houseType"
                          horizontal
                      />
                      <CInput
                          label="Haus: Wohnfläche"
                          description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Haus"
                          v-model="iframeSettings.text.house.livingArea"
                          horizontal
                      />
                      <CInput
                          label="Haus: Etage"
                          description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Haus"
                          v-model="iframeSettings.text.house.floor"
                          horizontal
                      />
                      <CInput
                          label="Haus: Zimmer"
                          description="Ändern Sie den Text für Schritt 5 bei dem Immobilientyp Haus"
                          v-model="iframeSettings.text.house.roomCount"
                          horizontal
                      />
                      <CInput
                          label="Haus: Baujahr"
                          description="Ändern Sie den Text für Schritt 6 bei dem Immobilientyp Haus"
                          v-model="iframeSettings.text.house.constructionYear"
                          horizontal
                      />
                      <hr />
                      <CInput
                          label="Grundstück: Grundfläche"
                          description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Grundstück"
                          v-model="iframeSettings.text.land.siteArea"
                          horizontal
                      />
                      <CInput
                          label="Grundstück: Erschlossen"
                          description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Grundstück"
                          v-model="iframeSettings.text.land.developedProperty"
                          horizontal
                      />
                      <CInput
                          label="Grundstück: Bebauungsmöglichkeiten"
                          description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Grundstück"
                          v-model="
                        iframeSettings.text.land.constructionPossibilities
                      "
                          horizontal
                      />
                      <CInput
                          label="Grundstück: Grundstückszuschnitt"
                          description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Grundstück"
                          v-model="iframeSettings.text.land.siteLayout"
                          horizontal
                      />
                      <hr />
                      <CInput
                          label="Miete: Wohnfläche"
                          description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Miete"
                          v-model="iframeSettings.text.rent.livingArea"
                          horizontal
                      />
                      <CInput
                          label="Miete: Typ"
                          description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Miete"
                          v-model="iframeSettings.text.rent.rentType"
                          horizontal
                      />
                      <CInput
                          label="Miete: Kategorie"
                          description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Miete"
                          v-model="iframeSettings.text.rent.rentCategory"
                          horizontal
                      />
                      <CInput
                          label="Miete: Baujahr"
                          description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Miete"
                          v-model="iframeSettings.text.rent.constructionYear"
                          horizontal
                      />
                      <hr />
                      <CInput
                          label="Gewerbe: Art"
                          description="Ändern Sie den Text für Schritt 1 bei dem Immobilientyp Gewerbe"
                          v-model="iframeSettings.text.business.businessType"
                          horizontal
                      />
                      <CInput
                          label="Gewerbe: Typ"
                          description="Ändern Sie den Text für Schritt 2 bei dem Immobilientyp Gewerbe"
                          v-model="iframeSettings.text.business.livingArea"
                          horizontal
                      />
                      <CInput
                          label="Gewerbe: Kategorie"
                          description="Ändern Sie den Text für Schritt 3 bei dem Immobilientyp Gewerbe"
                          v-model="iframeSettings.text.business.siteArea"
                          horizontal
                      />
                      <CInput
                          label="Gewerbe: Baujahr"
                          description="Ändern Sie den Text für Schritt 4 bei dem Immobilientyp Gewerbe"
                          v-model="iframeSettings.text.business.isRent"
                          horizontal
                      />
                      <CInput
                          label="Gewerbe: Baujahr"
                          description="Ändern Sie den Text für Schritt 5 bei dem Immobilientyp Gewerbe"
                          v-model="iframeSettings.text.business.constructionYear"
                          horizontal
                      />
                    </CCol>
                  </CRow>
                </div>
              </CTab>

              <!-- Tracking Settings Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-chart-line" class="mr-2" />
                  Tracking
                </template>
                <div class="tab-content-wrapper">
                  <CRow>
                    <CCol xs="12" xl="10">
                      <CButton
                          @click="trackingYoutubeCollapse = !trackingYoutubeCollapse"
                          color="primary"
                          class="mb-2"
                      >
                        Anleitung für das Tracking
                      </CButton>
                      <CCollapse :show="trackingYoutubeCollapse">
                        <CCard body-wrapper>
                          <CEmbed
                              tag="p"
                              aspect="16by9"
                              src="https://www.youtube.com/embed/8Vr_iSaaMnI"
                          />
                        </CCard>
                      </CCollapse>
                      <hr class="mt-2 mb-4" />

                      <!-- Tracking via Karten -->
                      <div class="settings-section">
                        <h6 class="section-title">Tracking via</h6>
                        <div class="tracking-options-container">
                          <CCard
                              v-for="option in trackingOptions"
                              :key="option.value"
                              :class="['tracking-option-card', {
        'active': iframeSettings.trackingVia.includes(option.value)
      }]"
                              @click="toggleTrackingOption(option.value)"
                          >
                            <div class="d-flex align-items-center">
                              <CIcon :name="option.icon" class="mr-2" />
                              <span>{{ option.label }}</span>
                            </div>
                          </CCard>
                        </div>
                      </div>

                      <CInput
                          label="Facebook Pixel: ID"
                          v-model="iframeSettings.facebookPixelId"
                      />
                      <CInput
                          label="Google Tag Manager: ID"
                          v-model="iframeSettings.googleTagManagerId"
                      />
                      <CodeEditor
                          label="Header Code"
                          v-model="iframeSettings.customHeader"
                          language="markup"
                      />
                    </CCol>
                  </CRow>
                </div>
              </CTab>


              <!-- DSGVO Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-shield-alt" class="mr-2" />
                  DSGVO
                </template>
                <div class="tab-content-wrapper">

                  <!-- Privacy Policy -->
                  <div class="settings-section">
                    <h6 class="section-title">Datenschutzerklärung</h6>
                    <WysiwygEditor
                        v-model="iframeSettings.privacyPolicy"
                        :config="{
          placeholder: 'Fügen Sie Ihre vollständige Datenschutzerklärung ein...',
          toolbar: ['bold', 'italic', 'link']
        }"
                    />
                  </div>

                  <CRow>
                    <CCol xs="12" xl="10">
                      <!-- Einwilligung zum Datenschutz aktivieren -->
                      <div class="settings-section">
                        <h6 class="section-title">Datenschutz-Einwilligung</h6>
                        <CCard
                            :class="['property-type-card', { 'active': iframeSettings.dsgvo.active }]"
                            @click="toggleDsgvoConsent"
                        >
                          <div class="d-flex align-items-center">
                            <CIcon name="cil-shield-alt" class="mr-2" />
                            <span>Einwilligung zum Datenschutz aktivieren?</span>
                          </div>
                        </CCard>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CTab>

              <!-- Preview Tab -->
              <CTab v-if="isNotKensingtonOrIsButOrgaAdmin">
                <template slot="title">
                  <CIcon name="cil-magnifying-glass" class="mr-2" />
                  Vorschau
                </template>
                <div class="tab-content-wrapper">
                  <CRow>
                    <CCol xs="12" xl="10">
                      <div class="mb-4">
                        <CButton @click="refreshPreviewIframe" color="warning">
                          Neu laden
                        </CButton>
                      </div>
                      <iframe
                          ref="previewLeadGeneratorIframe"
                          src="about:blank"
                          frameborder="0"
                          style="border: 0;width: 100%;height: 700px"
                      />
                    </CCol>
                  </CRow>
                </div>
              </CTab>
            </CTabs>

            <!-- Action Buttons -->
            <div class="d-flex mt-4">
              <CButton @click="submitSettings" color="success" size="lg">
                <CIcon name="cil-save" /> Speichern
              </CButton>

              <CButton
                  v-if="$commons.isOrganizationAdmin()"
                  @click="submitSettings(true)"
                  color="warning"
                  size="lg"
                  class="ml-2"
              >
                <CIcon name="cil-star" /> Als Standard speichern
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import WysiwygEditor from "@/components/WysiwygEditor";
import CodeEditor from "@/components/CodeEditor";
import Checkbox from "@/components/Checkbox";
import ColorPicker from "@/components/ColorPicker";
import RadioButton from "@/components/RadioButton";
import commons from "@/commons";
import axios from "axios";
import _ from "lodash";
import DoubleBounceLoader from "@/components/loaders/DoubleBounceLoader";
import PrtFontPicker from "@/components/PrtFontPicker";
import GoogleMapsAutocompleteInput from "@/components/GoogleMapsAutocompleteInput";
import AddableList from "@/components/AddableList";
import ExtraStepsComponent from "@/components/ExtraStepsComponent";
import { CSwitch } from '@coreui/vue';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CEmbed,
  CIcon,
  CInput,
  CModal,
  CRow,
  CTabs,
  CTab
} from "@coreui/vue";

export default {
  name: "IframeSettings",
  components: {
    ExtraStepsComponent,
    AddableList,
    GoogleMapsAutocompleteInput,
    PrtFontPicker,
    DoubleBounceLoader,
    RadioButton,
    ColorPicker,
    Checkbox,
    WysiwygEditor,
    CodeEditor,
    CIcon,
    CSwitch,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CCollapse,
    CEmbed,
    CInput,
    CModal,
    CRow,
    CTabs,
    CTab,
  },
  data() {
    return {
      warningModal: false,
      loading: false,
      saving: false,
      iframeUrl: process.env.VUE_APP_IFRAME_URL,
      iframeKey: commons.getFromStorage("currentRealm").iframeKey,
      trackingYoutubeCollapse: false,
      isNotKensingtonOrIsButOrgaAdmin:
          !commons.hasExactRealmPlan("KENSINGTON") ||
          commons.hasRole("ORGANIZATION_ADMIN"),
      iframeSettings: {
        googleMapsApiKey: "",
        benefits: "",
        activeSteps: [],
        rateProperty: true,
        sendPdfCustomer: true,
        queueActive: false,
        privacyPolicy: "",
        customCss: "",
        extraSlide: "",
        extraSlideRoute: "",
        fontFamily: "Arial",
        fontSize: "14px",
        fontColor: "#000",
        textTransformations: "none",
        fontWeight: "400",
        h1Size: "24px",
        h1Color: "#000",
        h2Size: "18px",
        h2Color: "#000",
        primaryColor: "#002335",
        secondaryColor: "#17b5a0",
        previousButtonColor: "#fc5c65",
        nextButtonColor: "#20bf6b",
        submitButtonColor: "#20bf6b",
        askReason: true,
        reasonsWhenBuying: ["Verkauf", "Kauf"],
        reasonsWhenRenting: ["Vermieten", "Mieten"],
        mapCenter: {
          address: "Berlin",
          lat: 52.520008,
          lng: 13.404954,
        },
        globalMarkUpOrDown: 0,
        markUpOrDownForBuy: 0,
        markUpOrDownForRent: 0,
        text: {
          start: "Welche Immobilie möchten Sie <b>verkaufen?</b>",
          startSlogan1:
              '100% <span class="prt-color-secondary">kostenlos</span> bewerten lassen',
          startSlogan2: "Welche Immobilie möchten Sie bewerten?",
          startButton: "Jetzt Bewertung anfordern!",
          region: "In welcher <b>Region</b> befindet sich die Immobilie?",
          personalDataForm:
              "Fordern Sie jetzt Ihr Angebot von makler-anfragen.immo an.",
          thanks: "Wir melden uns bei Ihnen, so schnell wie möglich!",
          deactivatedRate1: "Danke für Ihr Vertrauen!",
          deactivatedRate2: "Wir melden uns so bald wie möglich!",
          notFound1:
              "Leider konnten wir anhand Ihrer Daten keine Bewertung generieren.!",
          notFound2: "Wir melden uns so bald wie möglich",
          extraMessage: "",
          reason: "Wozu benötigen Sie die Immobilienbewertung?",
          reasonWhen: "Wann möchten Sie voraussichtlich verkaufen?",
          propertyIsRating: "Der Wert Ihrer Immobilie wird ermittelt...",
          propertyRated: "Der Wert Ihrer Immobilie wurde ermittelt!",
          propertyValueIs: "Ihr Immobilienwert beträgt {{ WERT }}",
          emailTitle: "An wen dürfen wir die <b>Bewertung</b> versenden?",
          emailBody:
              "Bitte achten Sie auf die korrekte Schreibweise Ihrer E-Mail-Adresse.<br><br><b>Wichtig:</b> Überprüfen Sie auch Ihren Spam-Ordner.",

          apartment: {
            livingArea: "Wie ist die <b>Wohnfläche</b> der Wohnung?",
            floor: "In welcher <b>Etage</b> befindet sich die Wohnung?",
            roomCount: "Wie viele <b>Zimmer</b> hat die Wohnung?",
            constructionYear: "Wann wurde das Wohnhaus <b>gebaut?</b>",
          },

          house: {
            siteArea: "Welche Fläche hat das <b>Grundstück</b> des Hauses?",
            houseType: "Bitte wählen Sie die Art des Hauses aus.",
            livingArea: "Wie ist die gesamte <b>Wohnfläche</b> des Hauses?",
            floor: "Wie viele <b>Etagen</b> hat das Haus?",
            roomCount: "Wie viele <b>Zimmer</b> hat das Haus?",
            constructionYear: "Wann wurde das <b>Haus</b> gebaut?",
          },

          land: {
            siteArea: "Welche Fläche hat das <b>Grundstück</b>?",
            developedProperty: "Ist das Grundstück <b>erschlossen</b>?",
            constructionPossibilities:
                "Wie sind die <b>Bebauungsmöglichkeiten</b>?",
            siteLayout: "Wie ist der <b>Grundstückszuschnitt</b>?",
          },

          rent: {
            livingArea: "Wie ist die <b>Wohnfläche</b> der Wohnung?",
            rentType: "Um was <b>handelt</b> es sich hierbei?",
            rentCategory: "Welcher <b>Typ</b> passt zur Ihrer Immobilie?",
            constructionYear: "Wann wurde das Wohnhaus <b>gebaut?</b>",
          },

          business: {
            businessType: "Um welche Gebäudeart handelt es sich?",
            livingArea: "Wie groß ist die Gewerbefläche der Immobilie?",
            siteArea: "Welche Fläche hat das Grundstück des Gewerbes?",
            isRent: "Ist die Immobilie vermietet?",
            constructionYear: "Wann wurde das Haus erbaut?",
          },
        },
        dsgvo: {
          active: false,
          agentName: "",
          address: "",
          plz: "",
          place: "",
          phone: "",
        },
        trackingVia: [],
        facebookPixelId: "",
        googleTagManagerId: "",
        customHeader: "",
      },
      propertyTypeOptions: [
        { label: "Wohnung", value: "apartment", icon: "cil-home" },
        { label: "Haus", value: "house", icon: "cil-building" },
        { label: "Grundstück", value: "land", icon: "cil-map" },
        { label: "Miete", value: "rent", icon: "cil-dollar" },
        { label: "Gewerbe", value: "business", icon: "cil-industry" },
      ],
      transformOptions: [
        { label: 'Normal', value: 'none' },
        { label: 'Großbuchstaben', value: 'uppercase' },
        { label: 'Kleinbuchstaben', value: 'lowercase' },
        { label: 'Erster Buchstabe groß', value: 'capitalize' },
      ],
      fontWeightOptions: [
        { label: 'Light', value: '300' },
        { label: 'Normal', value: '400' },
        { label: 'Fett', value: '700' },
      ],
      switchOptions: [
        { key: 'rateProperty', label: 'Immobilie bewerten', icon: 'cil-home' },
        { key: 'sendPdfCustomer', label: 'PDF an Kunden senden', icon: 'cil-file' },
        { key: 'queueActive', label: 'Leadfreigabe aktivieren', icon: 'cil-check' },
        { key: 'askReason', label: 'Grund der Bewertung', icon: 'cil-info' },
        { key: 'smsVerifyActive', label: 'Telefonverifizierung', icon: 'cil-phone' }
      ],
      trackingOptions: [
        { label: 'Google Tag Manager', value: 'google_tag_manager', icon: 'cib-google' },
        { label: 'Facebook Pixel', value: 'facebook_pixel', icon: 'cib-facebook' }
      ],
    };
  },
  watch: {
    'iframeSettings.rateProperty': debounce(function(newValue) {
      this.$forceUpdate(); // Aktualisiert die Switch-Komponente
      this.submitSettings(false); // Debounce-Funktion ruft die Methode verzögert auf
    }, 500),

    'iframeSettings.sendPdfCustomer': debounce(function(newValue) {
      this.submitSettings(false); // Debounce-Funktion ruft die Methode verzögert auf
    }, 500),
  },
  methods: {
    handleTabChange(selectedTab) {
      if (selectedTab === 'Vorschau') {
        this.refreshPreviewIframe();
      }
    },

    toggleTrackingOption(value) {
      if (!Array.isArray(this.iframeSettings.trackingVia)) {
        // Stelle sicher, dass trackingVia immer ein Array ist
        this.iframeSettings.trackingVia = [];
      }

      const index = this.iframeSettings.trackingVia.indexOf(value);
      if (index === -1) {
        // Wert ist noch nicht enthalten, also hinzufügen
        this.iframeSettings.trackingVia.push(value);
      } else {
        // Wert ist bereits enthalten, also entfernen
        this.iframeSettings.trackingVia.splice(index, 1);
      }

      // Optional: API-Aufruf oder Speichern der Änderungen nach dem Update
      this.submitSettings(false);
    },
    toggleDsgvoConsent() {
      this.iframeSettings.dsgvo.active = !this.iframeSettings.dsgvo.active;
      this.submitSettings(false); // Speichern, um den neuen Zustand zu reflektieren
    },
    toggleSwitch(key) {
      this.iframeSettings[key] = !this.iframeSettings[key];
      this.submitSettings(false); // Speichern, um den neuen Zustand zu reflektieren
    },
    // Beispiel für die aktualisierte Methode zur Verarbeitung von Switches
    onSwitchChange(newValue, event) {
      const targetName = event.target.name.replace(/^iframe_iframe_/, 'iframe_');
      _.set(this.iframeSettings, targetName, newValue);
      this.submitSettings(false); // Speichern ohne Standardsetzung
  },
    adjustColor(color, amount) {
      // Konvertiert Hex zu RGB
      const hex = color.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      // Passt die Helligkeit an
      const adjustedR = Math.max(0, Math.min(255, r + amount));
      const adjustedG = Math.max(0, Math.min(255, g + amount));
      const adjustedB = Math.max(0, Math.min(255, b + amount));
      // Konvertiert zurück zu Hex
      return '#' +
          ((1 << 24) + (adjustedR << 16) + (adjustedG << 8) + adjustedB)
              .toString(16)
              .slice(1);
    },
    copyToClipboard() {
      let code = '<script type="text/javascript" src="' + this.iframeUrl + '/script?key=' + this.iframeKey + '"><\\/script>' +
          '<div id="prt-iframe-placeholder"></div>';
      // Entferne den Backslash für die tatsächliche Kopie
      code = code.replace(/\\\//g, '/');
      const textArea = document.createElement("textarea");
      textArea.value = code;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        this.$toaster.makeToast("Code kopiert!", "Der Integrationscode wurde kopiert.");
      } catch (err) {
        console.error("Fehler beim Kopieren des Codes:", err);
      }
      document.body.removeChild(textArea);
    },
    submitSettings: debounce(function(sendAsDefault) {
      this.saving = true;
      let toSubmit = commons.oneLevelObject(this.iframeSettings, "_", "iframe_");

      axios.post(sendAsDefault ? "/settings/default" : "/settings", toSubmit)
          .then(() => {
            this.$toaster.makeToast("Erfolgreich gespeichert", "Einstellungen wurden in der Cloud gespeichert.");
            this.saving = false;
          })
          .catch((err) => {
            console.error("Fehler beim Speichern:", err.response ? err.response.data : err);
            this.saving = false;
          });
    }, 500), // Debounce um den mehrfachen Aufruf zu verhindern
    togglePropertyType(type) {
      if (this.iframeSettings.activeSteps.includes(type)) {
        this.iframeSettings.activeSteps = this.iframeSettings.activeSteps.filter(
            (step) => step !== type
        );
      } else {
        this.iframeSettings.activeSteps.push(type);
      }
    },
    saveMapsCenter(changedPlace) {
      this.iframeSettings.mapCenter.lat = changedPlace.latitude;
      this.iframeSettings.mapCenter.lng = changedPlace.longitude;
    },
    refreshPreviewIframe() {
      let iframe = this.$refs.previewLeadGeneratorIframe;
      if (iframe) {
        iframe.src = `${this.iframeUrl}?key=${this.iframeKey}`;
      } else {
        console.error('iFrame-Referenz nicht gefunden');
      }
    },
  },
  mounted() {
    if (this.iframeUrl && this.iframeKey) {
      setTimeout(() => {
        this.refreshPreviewIframe();
      }, 500); // Timeout anpassen, wenn nötig
    } else {
      console.warn('Iframe URL oder Key nicht verfügbar beim Mounten.');
    }

    this.loading = true;
    axios.get("/settings").then((response) => {

      const cleanedData = commons.deepenOneLevelToNested(response.data, "_", "iframe_");

      // Konvertiere die Zeichenfolgen, die Arrays sein sollten, in echte Arrays
      try {
        cleanedData.reasonsWhenBuying = JSON.parse(cleanedData.reasonsWhenBuying);
      } catch (e) {
        cleanedData.reasonsWhenBuying = []; // Fallback auf ein leeres Array, wenn das Parsen fehlschlägt
      }

      try {
        cleanedData.reasonsWhenRenting = JSON.parse(cleanedData.reasonsWhenRenting);
      } catch (e) {
        cleanedData.reasonsWhenRenting = [];
      }

      try {
        cleanedData.activeSteps = JSON.parse(cleanedData.activeSteps);
      } catch (e) {
        cleanedData.activeSteps = []; // Fallback auf ein leeres Array, wenn das Parsen fehlschlägt
      }

      // Füge hier die Konvertierung für trackingVia hinzu
      try {
        cleanedData.trackingVia = JSON.parse(cleanedData.trackingVia);
      } catch (e) {
        cleanedData.trackingVia = []; // Fallback auf ein leeres Array, wenn das Parsen fehlschlägt
      }

      // Setze die iframeSettings
      this.iframeSettings = _.merge({}, this.iframeSettings, cleanedData);
      this.loading = false;
    })
        .catch((err) => {
          console.error('Fehler beim Abrufen der Einstellungen:', err);
          this.loading = false;
        });
  },
  cleanApiResponse(response) {
    const cleanedResponse = {};
    Object.keys(response).forEach((key) => {
      const normalizedKey = key.replace(/^iframe_iframe_/, 'iframe_');
      if (!cleanedResponse[normalizedKey]) {
        cleanedResponse[normalizedKey] = response[key];
      }
    });
    return cleanedResponse;
  },
};
</script>


<style scoped>
.settings-container {
  padding: 20px;
}

.code-container {
  position: relative;
  background: #2d2d2d; /* Dunklerer Hintergrund für Code-Editor-Optik */
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #444; /* Rahmen */
}

.integration-code {
  margin: 0;
  font-family: 'Courier New', Courier, monospace; /* Monospace-Schrift für Code-Stil */
  color: #f8f8f2; /* Helle Schriftfarbe */
  white-space: pre-wrap;
  line-height: 1.5; /* Bessere Lesbarkeit */
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #444; /* Knopf im Code-Editor-Stil */
  color: #f8f8f2; /* Helle Schriftfarbe */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s;
}

.copy-button:hover {
  background: #666; /* Hover-Effekt */
}

.settings-tabs {
  margin-bottom: 30px;
}

.settings-section {
  margin-bottom: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.section-title {
  margin-bottom: 20px;
  color: #2c3e50;
  font-weight: 600;
}

.switch-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}


.property-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.property-type-card {
  cursor: pointer;
  padding: 15px;
  transition: all 0.3s ease;
}

.property-type-card.active {
  background: #e3f2fd;
  border-color: #2196f3;
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.preview-iframe {
  border: 0;
  width: 100%;
}

/* Modern Styling */
.font-settings-grid,
.headers-grid,
.color-scheme-grid {
  display: grid;
  gap: 2rem;
  padding: 1.5rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Font Preview */
.font-preview {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: #f8f9fa;
  font-size: 1.2rem;
}

/* Modern Slider */
.modern-slider {
  width: 100%;
  height: 6px;
  -webkit-appearance: none;
  background: #e9ecef;
  border-radius: 3px;
  outline: none;
}

.modern-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
  transition: all 0.2s ease;
}

/* Color Preview */
.color-preview {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  margin-top: 0.5rem;
  transition: all 0.3s ease;
}

/* Transform Buttons */
.transform-options {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.transform-btn {
  padding: 0.5rem 1rem;
  border: 2px solid #dee2e6;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.transform-btn.active {
  background: #007bff;
  color: white;
  border-color: #0056b3;
}

/* Header Preview Cards */
.header-preview-card {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Button Preview */
.button-preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.button-preview {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.prev-btn {
  background-image: linear-gradient(to right, currentColor, darken(currentColor, 10%));
}

.preview-btn.active {
  cursor: default;
  transform: translateY(1px);
}

.preview-btn.hover {
  cursor: default;
}

.preview-btn.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.states-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.state-preview {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.state-label {
  font-size: 0.875rem;
  color: #6c757d;
  font-weight: 500;
}


.button-states-preview {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.preview-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.next-btn, .submit-btn {
  background-image: linear-gradient(to right, currentColor, darken(currentColor, 10%));
}

.button-preview:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.preview-btn {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}


/* Responsive Design */
@media (max-width: 768px) {
  .font-settings-grid,
  .headers-grid,
  .color-scheme-grid {
    grid-template-columns: 1fr;
  }

  .button-preview-grid {
    grid-template-columns: 1fr;
  }

  .states-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.tracking-options-container {
  display: flex;
  gap: 20px; /* Abstand zwischen den Karten */
  flex-wrap: wrap; /* Wrap, damit die Karten in kleinen Ansichten umgebrochen werden */
}

.tracking-option-card {
  cursor: pointer;
  padding: 15px;
  flex: 1 1 calc(50% - 20px); /* Jede Karte nimmt 50% der Breite ein, minus dem Abstand */
  transition: all 0.3s ease;
  text-align: center; /* Karteninhalt zentrieren */
  border-radius: 8px;
  background-color: #f8f9fa; /* Hintergrundfarbe */
}

.tracking-option-card.active {
  background-color: #e3f2fd; /* Aktive Karten hervorheben */
  border: 2px solid #007bff; /* Umrandung für aktive Karten */
}

.tracking-options-container .tracking-option-card:hover {
  transform: translateY(-2px); /* Hebt die Karte leicht an, wenn mit der Maus darübergefahren wird */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Schatten für den Hover-Effekt */
}



/* Responsive Adjustments */
@media (max-width: 768px) {
  .settings-container {
    padding: 10px;
  }

  .property-types-grid {
    grid-template-columns: 1fr;
  }
}

.maps-setup-modal {
  max-width: 600px;
  margin: 30px auto;
}

.setup-content {
  padding: 20px;
}

.step {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.step:last-child {
  border: none;
}

.step h4 {
  color: #2c3e50;
  margin-bottom: 15px;
  font-size: 18px;
}

.api-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.api-item {
  background: #f8f9fa;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.billing-info {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
}

.info-box {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  background: #e3f2fd;
  border-radius: 4px;
  font-size: 14px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #007bff;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.nav-pills .nav-link{
  list-style: none;
  padding: 0;
}


.nav-pills li::before {
  display: none;
}
</style>
