<template>
  <div>
    <!-- Header mit Button -->
    <div class="header d-flex justify-content-between align-items-center mb-4 p-3 bg-light rounded shadow-sm">
      <h2 class="font-weight-bold">Funnel-Übersicht</h2>
      <div class="header-button">
        <CButton
            to="/funnel/new"
            name="createFunnel"
            size="sm"
            color="dark"
        >
          <CIcon size="sm" name="cil-plus" />
          <span> Neue E-Mail hinzufügen</span>
        </CButton>
      </div>
    </div>

    <!-- Filterleiste -->
    <div class="marketing-dashboard">
      <div class="filter-bar d-flex align-items-center mb-4 p-3 bg-light rounded shadow-sm">
        <span class="font-weight-bold mr-3">Filtern nach:</span>

        <!-- Kampagnen-Dropdown -->
        <CDropdown class="mr-3" :toggler-text="selectedCampaign ? `Kampagne: ${selectedCampaign}` : 'Jede Kampagne'">
          <CDropdownItem @click="selectCampaign(null)">
            Alle Kampagnen
          </CDropdownItem>
          <CDropdownItem
              v-for="campaign in campaignOptions"
              :key="campaign.value"
              @click="selectCampaign(campaign.value)"
              :active="selectedCampaign === campaign.value"
          >
            {{ campaign.label }} ({{ campaign.count }})
          </CDropdownItem>
        </CDropdown>

        <!-- Status-Dropdown -->
        <CDropdown :toggler-text="selectedStatus ? `Status: ${selectedStatus === 'active' ? 'Aktiv' : 'Inaktiv'}` : 'Jeder Status'">
          <CDropdownItem @click="selectStatus(null)">
            Alle Status
          </CDropdownItem>
          <CDropdownItem
              @click="selectStatus('active')"
              :active="selectedStatus === 'active'"
          >
            Aktiv
          </CDropdownItem>
          <CDropdownItem
              @click="selectStatus('inactive')"
              :active="selectedStatus === 'inactive'"
          >
            Inaktiv
          </CDropdownItem>
        </CDropdown>
      </div>

      <!-- Kampagnenliste -->
      <CRow>
        <CCol md="6" v-for="(campaign, index) in filteredCampaigns" :key="index">
          <CCard class="campaign-card shadow-sm mb-4">
            <CCardBody>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="font-weight-bold">{{ campaign.name }}</h5>
                <CBadge :color="campaign.active ? 'success' : 'danger'" class="status-badge">
                  {{ campaign.active ? 'Aktiv' : 'Inaktiv' }}
                </CBadge>
              </div>
              <p class="text-muted mb-1">Betreff: {{ campaign.subject }}</p>
              <p class="text-muted mb-3">
                Geplant: {{ formatDuration(campaign.sendAfterDurationTime, campaign.sendAfterDurationType) }}
              </p>
              <CButton
                  :to="`/funnel/edit/${campaign.uuid}`"
                  color="info"
                  variant="outline"
                  size="sm"
                  class="action-button"
              >
                Bearbeiten
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  CRow, CCol, CCard, CCardBody, CButton, CIcon, CBadge,
  CDropdown, CDropdownItem,
} from "@coreui/vue";

export default {
  name: "MarketingDashboard",
  components: {
    CRow, CCol, CCard, CCardBody, CButton, CIcon, CBadge,
    CDropdown, CDropdownItem,
  },
  data() {
    return {
      campaigns: [],
      filteredCampaigns: [],
      campaignOptions: [],
      selectedCampaign: null,
      selectedStatus: null,
    };
  },
  mounted() {
    this.fetchFunnelData();
  },
  methods: {
    fetchFunnelData() {
      axios.get("/funnel/rules").then((response) => {
        this.campaigns = response.data;
        this.populateCampaignOptions();
        this.applyFilters(); // Initialize filtered campaigns
      }).catch((error) => {
        console.error("Fehler beim Abrufen der Funnels:", error);
      });
    },
    populateCampaignOptions() {
      // Group campaigns by name and count occurrences
      const campaignCounts = this.campaigns.reduce((acc, campaign) => {
        const campaignName = campaign.campaignName || "Keiner Kampagne zugeordnet";
        acc[campaignName] = (acc[campaignName] || 0) + 1;
        return acc;
      }, {});

      // Convert to array of options
      this.campaignOptions = Object.entries(campaignCounts).map(([name, count]) => ({
        label: name,
        value: name,
        count: count
      }));
    },
    selectCampaign(campaign) {
      this.selectedCampaign = campaign;
      this.applyFilters();
    },
    selectStatus(status) {
      this.selectedStatus = status;
      this.applyFilters();
    },
    applyFilters() {
      this.filteredCampaigns = this.campaigns.filter(campaign => {
        const campaignMatch = !this.selectedCampaign ||
            (this.selectedCampaign === "Keiner Kampagne zugeordnet" ?
                !campaign.campaignName :
                campaign.campaignName === this.selectedCampaign);

        const statusMatch = !this.selectedStatus ||
            (this.selectedStatus === "active" ? campaign.active : !campaign.active);

        return campaignMatch && statusMatch;
      });
    },
    formatDuration(time, type) {
      return time > 0 ? this.$moment.duration(time, type).humanize() : "Sofort";
    },
  },
};
</script>

<style scoped>
.filter-bar {
  background-color: #f9fafb;
  border-radius: 8px;
}

.dropdown-toggle-custom {
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: #495057;
  display: flex;
  align-items: center;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.dropdown-toggle-custom:hover {
  background-color: #e2e6ea;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.campaign-card {
  border-radius: 12px;
  transition: transform 0.2s;
}

.campaign-card:hover {
  transform: translateY(-5px);
}

.status-badge {
  font-size: 0.75rem;
  padding: 0.25em 0.5em;
}

.action-button {
  font-weight: bold;
  transition: background-color 0.2s;
}

.action-button:hover {
  background-color: #e9ecef;
}
</style>
