<template>
  <div class="prt-email-editor">
    <div class="prt-email-editor-bar">
      <div class="bar-title">
        Inhalt
        <CAlert
          v-if="
            !$commons.getFromStorage('unlayerEditorNoticeAlertDismiss', false)
          "
          color="info"
          closeButton
          @update:show="closeNoticeAlert"
          ><small class="form-text text-muted w-100"
            >Verfassen Sie hier mit dem Drag & Drop Editor Ihre E-Mail Vorlage.
            Nutzen Sie gerne die von uns zur Verfügung gestellten Designvorlagen
            oder erstellen & verwalten Sie eigene Vorlagen.</small
          ></CAlert
        >
      </div>
      <div class="bar-buttons">
        <CButton color="unlayer" @click="openTemplatePicker">
          <CIcon name="cil-folder-open" />
          Vorlage verwenden...
        </CButton>
        <CButton color="unlayer" @click="openTemplateCreator">
          <CIcon name="cil-description" />
          In eine Vorlage umwandeln
        </CButton>
      </div>
    </div>
    <hr style="width: 100%" />
    <UnlayerEditor
      ref="emailEditor"
      @load="editorLoaded"
      locale="de-DE"
      :options="options"
      :projectId="33569"
      minHeight="700px"
      :displayMode="displayMode"
    />
    <CModal
      title="Vorlage auswählen"
      size="xl"
      :show.sync="templatePickerModalVisible"
      :closeOnBackdrop="true"
    >
      <CTabs>
        <CTab title="E-Mail Vorlagen" :active="displayMode === 'email'">
          <div class="unlayer-template-wrapper">
            <div
              v-for="template in predefinedEmailTemplates"
              @click="loadDesign(template.designJson)"
              class="unlayer-template"
            >
              <CImg :src="template.thumbnailUrl" />
              <label>{{ template.name }}</label>
            </div>
          </div>
        </CTab>
        <CTab title="Landingpage Vorlagen" :active="displayMode === 'web'">
          <div class="unlayer-template-wrapper">
            <div
              v-for="template in predefinedLandingpageTemplates"
              @click="loadDesign(template.designJson)"
              class="unlayer-template"
            >
              <CImg :src="template.thumbnailUrl" />
              <label>{{ template.name }}</label>
            </div>
          </div>
        </CTab>
        <CTab title="Eigene" :active="ownTemplates.length > 0">
          <div class="unlayer-template-wrapper">
            <div
              v-for="template in ownTemplates"
              @click="loadDesign(template.designJson)"
              class="unlayer-template"
            >
              <CImg :src="template.thumbnailUrl" />
              <label>{{ template.name }}</label>
              <CButton
                @click.stop.prevent="deleteTemplate(template)"
                size="sm"
                color="danger"
                class="deletable"
              >
                <CIcon size="sm" name="cil-trash"></CIcon>
              </CButton>
            </div>
          </div>
        </CTab>
      </CTabs>
      <template #footer>
        <CButton color="secondary" @click="templatePickerModalVisible = false"
          >Schließen</CButton
        >
      </template>
    </CModal>
    <CModal
      title="Vorlage erstellen"
      size="sm"
      :show.sync="templateCreateModalVisible"
      :closeOnBackdrop="true"
    >
      <CForm>
        <CInput
          v-model="templateCreateName"
          required
          label="Name der Vorlage"
        />
      </CForm>
      <template #footer>
        <CButton color="secondary" @click="templateCreateModalVisible = false"
          >Abbrechen</CButton
        >
        <CButton
          v-if="!createTemplateProcessing"
          color="success"
          @click="createTemplateFromCurrentDesign"
          >Erstellen
        </CButton>
        <CButton v-else color="warning" disabled>Bitte warten...</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import UnlayerEditor from "./UnlayerEditor";
import axios from "axios";
import commons from "@/commons";

export default {
  name: "UnlayerEditorWrapper",
  components: { UnlayerEditor },
  props: {
    value: [String, Object],
    displayMode: {
      type: String,
      default: "web",
      validator: function (value) {
        // The value must match one of these strings
        return ["email", "web"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      options: {
        displayMode: this.displayMode,
        userUploads: true,
        user: {
          id: this.$commons.getCurrentUser().id,
          signature: this.$commons.getFromStorage("unlayerSignatureHmac"),
        },
        mergeTags: {
          vorname: {
            name: "Vorname",
            value: "{{VORNAME}}",
            sample: "Max",
          },
          nachname: {
            name: "Nachname",
            value: "{{NACHNAME}}",
            sample: "Mustermann",
          },
          firma: {
            name: "Firma",
            value: "{{FIRMA}}",
            sample: "Musterfirma GmbH",
          }
        },
        customJS: [
          `unlayer.registerTool({
  name: 'prt_iframe',
  label: 'Leadgenerator',
  icon: 'fa-analytics',
  supportedDisplayModes: ['web'],
  usageLimit: 1,
  options: {},
  values: {},
  renderer: {
    Viewer: unlayer.createViewer({
      render(values) {
        return '<iframe style="border:0;height:600px;width:1px;min-width:100%" id="cloud-prt-iframe" src="${process.env.VUE_APP_IFRAME_URL}?key=${commons.getFromStorage("currentRealm").iframeKey}">'
      }
    }),
    exporters: {
      web: function(values) {
        return '<'+'script type="text/javascript" src="${process.env.VUE_APP_IFRAME_URL}/script?key=${commons.getFromStorage("currentRealm").iframeKey}"><'+'/script><div id="prt-iframe-placeholder"></div>'
      }
    },
    head: {
      css: function(values) {},
      js: function(values) {}
    }
  },
  validator(data) {
    return [];
  },
});
unlayer.registerTool({
  name: 'prt_google_tag_manager',
  label: 'Tag Manager',
  icon: 'fa-tag',
  supportedDisplayModes: ['web'],
  options: {
    location: {
      title: "Google Tag Manager",
      position: 1,
      options: {
        "google_tag_manager_id": {
          "label": "ID",
          "widget": "text",
          "defaultValue": "GTM-XXXXXX"
        }
      }
    }
  },
  values: {},
  renderer: {
    Viewer: unlayer.createViewer({
      render(values) {
        return '<code>[ Google Tag Manager - Dieser Text wird nur im Editor angezeigt. ]</code>'
      }
    }),
    exporters: {
      web: function(values) {
        return '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id='+values.google_tag_manager_id+'" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>'
      }
    },
    head: {
      css: function(values) {},
      js: function(values) {
        return "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','"+values.google_tag_manager_id+"');"
      }
    }
  },
  validator(data) {
    return [];
  },
});`,
        ],
      },
      templatePickerModalVisible: false,
      templateCreateModalVisible: false,
      templateCreateName: "",
      createTemplateProcessing: false,
      predefinedTemplates: [],
      ownTemplates: [],
    };
  },
  created() {
    this.loadAllTemplates();
  },
  watch: {
    value(newVal) {
      this.editorLoaded(newVal);
    },
  },
  computed: {
    predefinedLandingpageTemplates() {
      return _.filter(this.predefinedTemplates, ["displayMode", "WEB"]);
    },
    predefinedEmailTemplates() {
      return _.filter(this.predefinedTemplates, ["displayMode", "EMAIL"]);
    },
  },
  methods: {
    editorLoaded(value) {
      value = value || this.value;
      if (
        typeof value === "string" ||
        typeof this.$refs.emailEditor.editor === "undefined"
      ) {
        return;
      }
      this.$refs.emailEditor.editor.loadDesign(value);
    },
    openTemplatePicker() {
      this.templatePickerModalVisible = true;
    },
    openTemplateCreator() {
      this.templateCreateModalVisible = true;
    },
    loadAllTemplates() {
      axios
        .get("/unlayer/predefinedTemplates")
        .then((response) => {
          this.predefinedTemplates = response.data;
        })
        .catch((err) =>
          console.error(
            "Problem while fetching predefined unlayer templates",
            err,
          ),
        );
      axios
        .get("/unlayer/templates")
        .then((response) => {
          this.ownTemplates = response.data;
        })
        .catch((err) =>
          console.error(
            "Problem while fetching predefined unlayer templates",
            err,
          ),
        );
    },
    loadDesign(design) {
      this.$refs.emailEditor.editor.loadDesign(design);
      this.templatePickerModalVisible = false;
    },
    saveDesign() {
      return new Promise((resolve, reject) => {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          resolve(design);
        });
      });
    },
    exportHtml() {
      return new Promise((resolve, reject) => {
        this.$refs.emailEditor.editor.exportHtml((html) => {
          resolve(html);
        });
      });
    },
    async createTemplateFromCurrentDesign() {
      if (!this.templateCreateName) {
        this.$toaster.makeToast("Fehler", "Bitte geben Sie einen Namen ein.");
        return;
      }
      try {
        this.createTemplateProcessing = true;
        const design = await this.saveDesign();
        await axios.post("/unlayer/templates", {
          name: this.templateCreateName,
          design: design,
          displayMode: this.displayMode,
        });
        this.$toaster.makeToast(
          "Erfolgreich!",
          "Aus Ihrem aktuellen Design wurde erfolgreich die Vorlage <b>" +
            this.templateCreateName +
            "</b> erstellt.",
        );
        this.loadAllTemplates();
      } catch (e) {
        this.$toaster.makeToast(
          "Fehler",
          "Es konnte keine Vorlage erstellt werden. Bitte probieren Sie es später noch einmal.",
        );
        console.log(e);
      } finally {
        this.createTemplateProcessing = false;
        this.templateCreateName = "";
        this.templateCreateModalVisible = false;
      }
    },
    async deleteTemplate(template) {
      this.$modal.showModal(
        "delete",
        null,
        [`<b>Vorlage</b> (${template.name})`],
        async () => {
          try {
            await axios.delete("/unlayer/templates/" + template.id);
            this.$toaster.makeToast(
              "Erfolgreich!",
              "Ihre Vorlage <b>" +
                template.name +
                "</b> wurde soeben gelöscht.",
            );
            this.loadAllTemplates();
          } catch (e) {
            this.$toaster.makeToast(
              "Fehler",
              "Es konnte keine Vorlage gelöscht werden. Bitte probieren Sie es später noch einmal.",
            );
            console.log(e);
          }
        },
      );
    },
    closeNoticeAlert(event) {
      if (event === false) {
        commons.setToStorage("unlayerEditorNoticeAlertDismiss", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prt-email-editor {
  display: flex;
  position: relative;
  height: 810px;
  min-height: 700px;
  flex-direction: column;
  margin-bottom: 50px;
}

.prt-email-editor-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 4px;
  //background-color: rgb(249, 249, 249);
  button {
    margin: 4px 6px 4px 4px;
  }

  .bar-title {
    max-width: 500px;
  }
}

.unlayer-template-wrapper {
  position: relative;
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */
  margin: 4px 0;
  padding: 18px;
  width: 100%;
  height: 70vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 22px 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.unlayer-template {
  min-width: 0; /* NEW; needed for Firefox */
  position: relative;
  border: 2px solid #2b3438;
  border-radius: 4px;
  width: 100%;
  height: 240px;
  min-height: 240px;
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    user-select: none;
  }

  label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    background-color: #2b3438;
    color: #fff;
    opacity: 0.8;
    font-size: 13px;
    padding: 2px 4px;
  }

  .deletable {
    position: absolute;
    top: 4px;
    right: 4px;
    margin: 0;
    opacity: 0.8;
    transition: all 0.2s;
    z-index: 299;
  }

  .deletable:hover {
    opacity: 1;
    color: #fff;
  }
}
</style>
