<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Abonnement bearbeiten</strong> ID:
              {{ $route.params.id }} - Plan: {{ realm.plan ? realm.plan.name : 'Kein Plan' }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <RealmForm
                v-if="ready"
                :name="realm.name"
                :wordpress-url="realm.wordpressUrl"
                :license="realm.license"
                :status="realm.status"
                :allowed-domains="realm.allowedDomains"
                :organization-id="realm.organization.id"
                :plan="realm.plan || { name: 'Professional' }"
            :stripe-subscription-id="realm.stripeSubscriptionId"
            @submit="editRealm"
            :processing="processing"
            btn-text="Save realm"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import RealmForm from "./base/RealmForm";

export default {
  name: "EditRealm",
  components: { RealmForm },
  data() {
    return {
      ready: false,
      realm: null,
      processing: false,
    };
  },
  created() {
    axios
      .get("/realm/" + this.$route.params.id)
      .then((response) => {
        this.realm = response.data;
        this.ready = true;
      })
      .catch((err) => console.error(err));
  },
  methods: {
    editRealm(data) {
      this.processing = true;
      axios
        .put("/realm/" + this.$route.params.id, data)
        .then(() => {
          this.$router.push({ name: "Realms" });
          this.$toaster.makeToast(
            "Success!",
            "<b>Abonnement erfolgreich bearbeitet</b>",
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toaster.makeToast(
              "Oops!",
              "<b>" +
                err.response.data.code +
                "</b><br>" +
                err.response.data.message,
            );
          } else {
            this.$toaster.makeToast("Error!", err.message);
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>