var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CFormGroup',_vm._b({scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label",function(){return [(_vm.label)?_c('label',{class:_vm.labelClasses,attrs:{"for":_vm.safeId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]})]},proxy:true},{key:"input",fn:function(){return [_c('vue-google-autocomplete',_vm._b({ref:"googleAuto",attrs:{"id":_vm.safeId,"classname":(_vm.inputClasses || []).join(' '),"readonly":_vm.readonly || _vm.plaintext,"value":_vm.state},on:{"placechanged":_vm.handlePlaceChanged,"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"error":function($event){return _vm.$emit('error', $event)},"change":function($event){return _vm.$emit('input', $event)}}},'vue-google-autocomplete',_vm.$attrs,false))]},proxy:true},_vm._l((_vm.$options.slots),function(slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}})],null,true)},'CFormGroup',{
    append: _vm.append,
    prepend: _vm.prepend,
    validFeedback: _vm.validFeedback,
    invalidFeedback: _vm.invalidFeedback,
    tooltipFeedback: _vm.tooltipFeedback,
    description: _vm.description,
    wrapperClasses: _vm.wrapperClasses,
    class: _vm.computedClasses,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }