<template>
  <CRow class="deal-modal">
    <CCol :md="mode === 'Add' ? 12 : 7">
      <CRow>
        <CCol md="12" class="text-right" v-show="mode === 'Edit'">
          <CIcon
            size="xl"
            name="cil-check-circle"
            class="text-success status-icon"
            v-show="status === 'WON'"
          />
          <CIcon
            size="xl"
            name="cil-x-circle"
            class="text-danger status-icon"
            v-show="status === 'LOST'"
          />
          <CButton
            class="btn-success mb-3"
            v-show="status === 'PROCESSING'"
            @click="changeDealStatus('WON')"
          >
            Gewonnen
          </CButton>

          <CButton
            class="btn-primary ml-3 mb-3"
            v-show="status !== 'PROCESSING'"
            @click="changeDealStatus('PROCESSING')"
          >
            Erneut öffnen
          </CButton>

          <CButton
            class="btn-danger mb-3 ml-3"
            v-show="status === 'PROCESSING'"
            @click="changeDealStatus('LOST')"
          >
            Verloren
          </CButton>
        </CCol>

        <CCol md="12" style="position: relative">
          <model-select
            v-model="selectedPeople"
            :options="peoples"
            :placeholder="
              !selectedPeople.value && !leadPeopleName ? 'Kontaktperson' : ''
            "
            :class="showPeopleError && submitted ? 'mb-1' : 'mb-3'"
            class="model-select"
          />
          <span v-if="!selectedPeople.value" class="new-people">
            {{ leadPeopleName }}
          </span>
          <CBadge
            v-if="!selectedPeople.value && leadPeopleName"
            class="new-people-badge"
            color="primary"
            >Als neuen Kontakt anlegen
          </CBadge>
          <label class="text-danger ml-1" v-show="showPeopleError && submitted"
            >Bitte wählen Sie eine Kontaktperson aus</label
          >
        </CCol>

        <CCol
            md="12"
            class="mb-3 d-flex justify-content-between"
            style="margin-top: -10px"
            v-if="hasPossibleDuplicates"
        >
          <div>
            <CIcon name="cil-warning" class="text-warning"></CIcon>
            &nbsp;&nbsp;
            <span>Möglicherweise existiert dieser Kontakt bereits!</span>
          </div>

          <div @click="showSimilarPeople = true" class="cursor-pointer">
            <span>Prüfen</span>
          </div>
        </CCol>

        <CCol
            md="12"
            class="mb-3"
            v-if="showSimilarPeople && possibleDuplicates.length > 0"
        >
          <div class="similar-people-container">
            <div
                v-for="duplicate in possibleDuplicates"
                :key="duplicate.realmPeople.id"
                class="people-box"
                @click="setAsSimilarPeople(duplicate)"
            >
              <div class="people-box-content">
                <div class="people-box-header">
                  <span class="people-name">{{ duplicate.realmPeople.name }}</span>
                  <CBadge :color="getMatchColor(duplicate.matchScore)">
                    {{ Math.round(duplicate.matchScore * 100) }}% Übereinstimmung
                  </CBadge>
                </div>
                <div class="people-box-details">
                  <div v-if="duplicate.realmPeople.realmPeopleEmails?.length">
                    <CIcon name="cil-envelope-closed"/>
                    {{ duplicate.realmPeople.realmPeopleEmails[0].email }}
                  </div>
                  <div v-if="duplicate.realmPeople.realmPeoplePhones?.length">
                    <CIcon name="cil-phone"/>
                    {{ duplicate.realmPeople.realmPeoplePhones[0].phone }}
                  </div>
                </div>
              </div>
            </div>

            <div class="people-box" @click="addAsNewPeople">
              <span>Als neuen Kontakt anlegen</span>
            </div>
          </div>
        </CCol>

        <!-- Loading-Indikator -->
        <CCol md="12" v-if="isSearching">
          <CSpinner size="sm"/> Suche nach ähnlichen Kontakten...
        </CCol>

        <CCol md="12">
          <model-select
            v-model="selectedGroup"
            :options="groups"
            placeholder="Organisation"
            class="model-select mb-3"
          />
        </CCol>

        <CCol md="12">
          <CInput v-model="name" placeholder="Titel" required />
        </CCol>

        <CCol md="6">
          <CInput type="number" placeholder="Wert" v-model="value" required />
        </CCol>

        <CCol md="6">
          <CSelect value="1" :options="[{ value: 2, label: 'EUR' }]" />
        </CCol>

        <CCol md="12">
          <CSelect
            :value="localSelectedPipeline"
            @input="updatePipeline"
            :options="pipelines"
            required
          />

          <span
            v-if="!pipelines || (pipelines && pipelines.length === 0)"
            style="margin-top: -10px; display: block"
          >Es wurde noch keine Pipeline angelegt -
        <router-link to="/deals/pipeline">Pipeline</router-link>
        erstellen</span
          >
        </CCol>

        <CCol v-if="steps.length > 0" md="12">
          <CRow style="height: 30px" class="px-3 mb-3">
            <CCol
              :key="index"
              v-for="(step, index) of steps"
              class="pipeline-step"
              :class="
                currentStep && currentStep.orderNumber >= step.orderNumber
                  ? status === 'LOST'
                    ? 'passed-lost'
                    : 'passed'
                  : null
              "
              @click="currentStep = step"
              v-c-tooltip="step.name"
            >
            </CCol>
          </CRow>
        </CCol>

        <CCol md="12" style="position: relative">
          <datepicker
            ref="datepicker"
            v-model="closeDate"
            :language="datepickerLangGerman"
            :monday-first="true"
            format="dd/MM/yyyy"
          />
        </CCol>
      </CRow>
    </CCol>

    <CCol md="5" v-if="mode === 'Edit'">
      <CRow>
        <CCol md="12" class="text-right">
          <CButton
            color="success"
            v-c-tooltip="'Erstellen Sie Notizen'"
            @click="showCreateNotesModal"
          >
            Notiz hinzufügen
          </CButton>
          &nbsp;&nbsp;&nbsp;
          <CButton
            color="success"
            v-c-tooltip="'Eine Aktivität erstellen'"
            @click="showCreateActivityModal"
          >
            Aktivität hinzufügen
          </CButton>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="12">
          <div class="deal-activity-filter">
            <span
              class="font-weight-bold cursor-pointer tab"
              :class="selectedCategory === 'all' && 'text-primary'"
              @click="selectedCategory = 'all'"
            >
              Alle
            </span>
            &nbsp;&nbsp;
            <span
              class="font-weight-bold cursor-pointer tab"
              :class="selectedCategory === 'notes' && 'text-primary'"
              @click="selectedCategory = 'notes'"
            >
              Notizen
            </span>
            &nbsp;&nbsp;
            <span
              class="font-weight-bold cursor-pointer tab"
              :class="selectedCategory === 'activities' && 'text-primary'"
              @click="selectedCategory = 'activities'"
            >
              Aktivitäten
            </span>
          </div>
        </CCol>

        <NotesWidget ref="notesWidget" v-show="selectedCategory === 'notes'" />

        <CCol
          md="12"
          v-for="(attach, index) of attaches"
          :key="`attach-${index}`"
        >
          <div v-if="attach.category === 'notes'" class="deal-activity-wrapper">
            <div class="deal-activity">
              <div>
                <CButton
                  size="sm"
                  color="dark"
                  variant="outline"
                  class="rounded-circle"
                >
                  <CIcon name="cil-notes" />
                </CButton>
              </div>

              <div class="deal-activity-information">
                <div class="deal-notes">
                  &nbsp;&nbsp;&nbsp;
                  <span v-html="attach.notes"></span>
                </div>

                <div class="deal-activity-start-time">
                  <span>
                    {{ formatDate(attach.created) }}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <CDropdown
                toggler-text="..."
                class="m-2 activity-action"
                placement="bottom-start"
              >
                <CDropdownItem v-on:click="showUpdateNotesModal(attach)">
                  Bearbeiten
                </CDropdownItem>
                <CDropdownItem v-on:click="deleteNotes(attach)">
                  Löschen
                </CDropdownItem>
              </CDropdown>
            </div>
          </div>

          <div class="deal-activity-wrapper" v-else>
            <div class="deal-activity">
              <div>
                <CButton
                  size="sm"
                  color="dark"
                  variant="outline"
                  class="rounded-circle"
                  v-c-tooltip="getTooltip(attach.type)"
                >
                  <CIcon :name="getIcon(attach.type)" />
                </CButton>
              </div>

              <div class="deal-activity-information">
                <div class="deal-activity-title">
                  <CIcon
                    class="text-success"
                    name="cil-check-circle"
                    v-c-tooltip="'Done'"
                    v-if="attach.done === true"
                  />
                  <span v-on:click="showUpdateActivityModal(attach)"
                    >&nbsp;&nbsp;&nbsp;{{ attach.title }}</span
                  >
                </div>

                <div class="deal-activity-start-time">
                  <span>
                    {{ attach.startTime }}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <CDropdown
                toggler-text="..."
                class="m-2 activity-action"
                placement="bottom-start"
              >
                <CDropdownItem v-on:click="showUpdateActivityModal(attach)">
                  Bearbeiten
                </CDropdownItem>
                <CDropdownItem v-on:click="deleteActivity(attach)">
                  Löschen
                </CDropdownItem>
              </CDropdown>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCol>
    <DefaultModal ref="defaultModal" />
  </CRow>
</template>

<script>
import { nextTick } from "vue";
import axios from "axios";
import { ModelSelect } from "vue-search-select";
import Datepicker from "vuejs-datepicker";
import { de } from "vuejs-datepicker/dist/locale";
import { activityTypes } from "../../../config/constant";
import moment from "moment";
import NotesWidget from '@/components/shared/widgets/NotesWidget.vue'
import ActivityWidget from '@/components/shared/widgets/ActivityWidget.vue'
import DefaultModal from '@/modals/DefaultModal.vue'

export default {
  props: {
    pipelines: Array,
    originalPipelines: Array,
    applyAdd: Function,
    applyUpdate: Function,
    mode: String,
    selectedPipeline: [String, Number], // Behält den Prop-Namen bei
  },
  components: {
    ModelSelect,
    Datepicker,
    NotesWidget,
    nextTick,
    ActivityWidget,
    DefaultModal,
  },
  mounted() {
    if (this.steps.length > 0) {
      this.currentStep = this.steps[0]; // Setzt den ersten Schritt als Standardwert
    }
    this.loadPeoples();
    this.loadGroups();
  },
  data: () => ({
    localSelectedPipeline: null,
    visibleNotesModal: false,
    datepickerLangGerman: de,
    peoples: [],
    selectedPeople: {
      value: "",
      text: "",
      realmGroup: null,
    },
    groups: [],
    selectedGroup: {
      value: "",
      text: "",
    },
    name: "",
    value: "",
    currentStep: null,
    closeDate: new Date(new Date().setDate(new Date().getDate() + 10)),
    showPeopleError: false,
    submitted: false,
    selectedDealId: null,
    status: null,
    activities: [],
    notesList: [],
    leadPeopleName: "",
    leadPeopleEmail: "",
    leadPeoplePhone: "",
    showSimilarPeople: false,
    stopWatchSelectedPeople: false,
    submitting: false,
    selectedCategory: "all",
    showNameError: false,
    possibleDuplicates: [],
    searchTimeout: null,
    isSearching: false,
  }),
  methods: {
    async searchSimilarPeople(searchData = {}) {
      if (!searchData.name && !searchData.email && !searchData.phone) {
        this.possibleDuplicates = [];
        return;
      }

      try {
        this.isSearching = true;
        const response = await axios.post('/realm-people/search', searchData);
        this.possibleDuplicates = response.data;

        // Wenn Duplikate gefunden wurden, zeige die Warnung
        if (this.possibleDuplicates.length > 0) {
          this.showSimilarPeople = true;
        }
      } catch (error) {
        console.error('Fehler bei der Duplikatsuche:', error);
      } finally {
        this.isSearching = false;
      }
    },

    // Debounced Suchfunktion
    debouncedSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        const searchData = {
          name: this.leadPeopleName,
          email: this.leadPeopleEmail,
          phone: this.leadPeoplePhone
        };
        this.searchSimilarPeople(searchData);
      }, 300); // 300ms Verzögerung
    },
    updatePipeline(value) {
      this.localSelectedPipeline = value;
      this.$emit('update:selectedPipeline', value);
    },
    showCreateActivityModal() {
      this.visibleActivityModal = true;
      this.selectedTaskId = null;

      nextTick(() => {
        if (
            this.$refs.activityWidget &&
            typeof this.$refs.activityWidget.initialize === "function"
        ) {
          this.$refs.activityWidget.initialize(this.selectedDeal?.id);
        } else {
          console.error(
              "activityWidget ist nicht definiert oder hat keine Methode 'initialize'",
          );
        }
      });
    },
    getMatchColor(score) {
      if (score >= 0.8) return 'success';
      if (score >= 0.5) return 'warning';
      return 'danger';
    },
    showUpdateActivityModal(item) {
      this.$refs.activityWidget.initializeWithEntity(item);
      this.selectedTaskId = item.id;
      this.visibleActivityModal = true;
    },
    showCreateNotesModal() {
      this.visibleNotesModal = true;
      if (
        this.$refs.notesWidget &&
        typeof this.$refs.notesWidget.initialize === "function"
      ) {
        this.$refs.notesWidget.initialize(this.selectedDeal?.id);
      } else {
        console.error(
          "notesWidget ist nicht definiert oder hat keine Methode 'initialize'",
        );
      }
    },
    closeNotesModal() {
      this.visibleNotesModal = false;
    },
    showUpdateNotesModal(item) {
      if (
        this.$refs.notesWidget &&
        typeof this.$refs.notesWidget.initializeWithEntity === "function"
      ) {
        this.$refs.notesWidget.initializeWithEntity(item);
        this.selectedNotesId = item.id;
        this.visibleNotesModal = true;
      } else {
        console.error(
          "notesWidget ist nicht definiert oder hat keine Methode 'initializeWithEntity'",
        );
      }
    },
    formatDate(value) {
      if (!value) {
        return "Datum nicht verfügbar";
      }
      return moment(value).isValid()
        ? moment(value).format("DD/MM/YYYY HH:mm")
        : "Ungültiges Datum";
    },
    async addPeople(data) {
      try {
        this.submitting = true;
        this.stopWatchSelectedPeople = true;
        const response = await axios.post("/realm-people", data);
        this.peoples.push({
          value: response.data.id,
          text: response.data.name,
          realmGroup: response.data.realmGroup,
          realmPeopleEmails: response.data.realmPeopleEmails,
          realmPeoplePhones: response.data.realmPeoplePhones,
        });

        this.selectedPeople = {
          value: response.data.id,
          text: response.data.name,
          realmGroup: response.data.realmGroup,
        };

        this.initializeLeadPeople();
      } catch (e) {
        console.error(e);
      } finally {
        this.submitting = false;
      }
    },
    loadPeoples() {
      axios
        .get("/realm-people")
        .then((response) => {
          this.peoples = response.data.map((entity) => ({
            value: entity.id,
            text: entity.name,
            realmGroup: entity.realmGroup,
            realmPeopleEmails: entity.realmPeopleEmails,
            realmPeoplePhones: entity.realmPeoplePhones,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadGroups() {
      axios
        .get("/realm-group")
        .then((response) => {
          this.groups = response.data.map((entity) => ({
            value: entity.id,
            text: entity.title,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async submit() {
      if (!this.localSelectedPipeline) {
        this.$toaster.makeToast(
          "Fehler!",
          "Bitte wählen Sie eine Pipeline.",
          "danger",
        );
        return;
      }

      if (!this.name || !this.value) {
        this.$toaster.makeToast(
          "Fehler!",
          "Bitte füllen Sie alle erforderlichen Felder aus.",
          "danger",
        );
        return;
      }

      try {
        // Finde die vollständige Pipeline-Information
        const selectedPipeline = this.originalPipelines.find(
          p => p.id === this.localSelectedPipeline
        );

        const dealData = {
          name: this.name,
          value: this.value,
          pipeline: {
            id: selectedPipeline.id,
            name: selectedPipeline.name,
            description: selectedPipeline.description,
            pipelineSteps: selectedPipeline.pipelineSteps || []
          },
          pipelineStep: this.currentStep,
          closeDate: this.closeDate,
          selectedPeople: {
            value: this.selectedPeople.value || null,
            text: this.selectedPeople.text || null,
            realmGroup: this.selectedPeople.realmGroup || null,
          },
          selectedGroup: {
            value: this.selectedGroup.value || null,
            text: this.selectedGroup.text || null,
          },
          status: this.status || "PROCESSING",
          realmPeople: this.selectedPeople.value ? {
            id: this.selectedPeople.value,
            name: this.selectedPeople.text,
            realmGroup: this.selectedPeople.realmGroup
          } : null,
          realmGroup: this.selectedGroup.value ? {
            id: this.selectedGroup.value,
            name: this.selectedGroup.text
          } : null
        };

        const response = await axios.post("/deal", dealData);

        this.$toaster.makeToast(
          "Erfolg!",
          "Deal erfolgreich gespeichert!",
          "success",
        );

        // Emit the new deal
        this.$emit("dealAdded", response.data);

        // Modal schließen
        this.$parent.$data.visibleModal = false;

        // Falls applyAdd existiert, aufrufen
        if (typeof this.applyAdd === 'function') {
          await this.applyAdd(response.data);
        }

      } catch (error) {
        console.error('Fehler beim Speichern:', error);
        this.$toaster.makeToast(
          "Fehler!",
          "Es gab ein Problem beim Speichern des Deals.",
          "danger",
        );
      }
    },
    getIcon(type) {
      const item = activityTypes.find((entity) => entity.value === type);
      return item ? item.icon : "";
    },
    getTooltip(type) {
      const item = activityTypes.find((entity) => entity.value === type);
      return item ? item.text : "";
    },
    setAsSimilarPeople(duplicate) {
      this.selectedPeople = {
        value: duplicate.realmPeople.id,
        text: duplicate.realmPeople.name,
        realmGroup: duplicate.realmPeople.realmGroup,
      };
      this.showSimilarPeople = false;
      this.possibleDuplicates = [];
    },
    getMatchMessage(matchScore) {
      const score = Math.round(matchScore * 100);

      if (score >= 90) {
        return "Dieser Kontakt existiert höchstwahrscheinlich bereits!";
      } else if (score >= 70) {
        return "Ein sehr ähnlicher Kontakt wurde gefunden.";
      } else if (score >= 50) {
        return "Es wurde ein möglicherweise ähnlicher Kontakt gefunden.";
      } else {
        return "Es wurde ein Kontakt mit gewissen Ähnlichkeiten gefunden.";
      }
    },
    async addAsNewPeople() {
      if (this.possibleDuplicates.length > 0) {
        const bestMatch = this.possibleDuplicates[0];
        const matchMessage = this.getMatchMessage(bestMatch.matchScore);

        const modalRef = this.$refs.defaultModal;
        modalRef.title = "Möglicher Dubletten-Kontakt";
        modalRef.entities = [
          matchMessage,
          `Gefundener Kontakt: ${bestMatch.realmPeople.name}`,
          bestMatch.realmPeople.realmPeopleEmails?.[0]?.email
              ? `E-Mail: ${bestMatch.realmPeople.realmPeopleEmails[0].email}`
              : null,
          bestMatch.realmPeople.realmPeoplePhones?.[0]?.phone
              ? `Telefon: ${bestMatch.realmPeople.realmPeoplePhones[0].phone}`
              : null,
          "",  // Leerzeile für bessere Lesbarkeit
          "Möchten Sie trotzdem einen neuen Kontakt anlegen?"
        ].filter(Boolean);  // Entfernt null-Einträge

        modalRef.options = {
          btnCancel: "Bestehenden Kontakt verwenden",
          btnConfirm: "Neuen Kontakt anlegen",
          btnCancelColor: "primary",
          btnConfirmColor: "warning"
        };

        try {
          await new Promise((resolve, reject) => {
            modalRef.onConfirm = resolve;
            modalRef.onCancel = () => {
              this.setAsSimilarPeople(bestMatch);  // Wählt den bestehenden Kontakt aus
              reject();
            };
            modalRef.show = true;
          });
        } catch {
          return;
        }
      }

      const data = {
        name: this.leadPeopleName,
        realmGroup: { id: null },
        realmPeoplePhones: [{ phone: this.leadPeoplePhone }],
        realmPeopleEmails: [{ email: this.leadPeopleEmail }],
      };

      await this.addPeople(data);
    },
    initializeLeadPeople() {
      this.leadPeopleName = "";
      this.leadPeopleEmail = "";
      this.leadPeoplePhone = "";
    },
  },
  watch: {
    leadPeopleName() {
      this.debouncedSearch();
    },
    leadPeopleEmail() {
      this.debouncedSearch();
    },
    leadPeoplePhone() {
      this.debouncedSearch();
    },
    selectedPipeline: {
      immediate: true,
      handler(newVal) {
        this.localSelectedPipeline = newVal;
      }
    },
    selectedPeople: {
      deep: true,
      handler(newVal) {
        if (this.submitted) {
          this.showPeopleError = !newVal.value;
        }

        if (this.stopWatchSelectedPeople) {
          this.stopWatchSelectedPeople = false;
        } else if (newVal && newVal.text) {
          this.name = newVal.text + " - Deal";
        }

        const group = newVal && newVal.realmGroup;

        this.selectedGroup = {
          value: group?.id,
          text: group?.name,
        };
      },
    },
  },
  computed: {
    steps() {
      const pipeline = this.originalPipelines.find(
        (entity) => entity.id === this.localSelectedPipeline
      );
      return (
        pipeline?.pipelineSteps?.sort(
          (a, b) => a.orderNumber - b.orderNumber,
        ) || []
      );
    },
    similarPeople() {
      if (this.peoples.length > 0) {
        for (const item of this.peoples) {
          const nameCheck = item.text === this.leadPeopleName;
          if (!nameCheck) continue;

          const emailCheck = item.realmPeopleEmails.some(
            (email) => email === this.leadPeopleEmail,
          );
          if (!emailCheck) continue;

          const phoneCheck = item.realmPeoplePhones.some(
            (phone) => phone === this.leadPeoplePhone,
          );
          if (phoneCheck) return item;
        }
      }
      return null;
    },
    attaches() {
      const all = this.notesList.concat(this.activities);
      return this.selectedCategory === "all"
        ? all.sort((a, b) => a.date - b.date)
        : this.selectedCategory === "notes"
          ? this.notesList
          : this.activities;
    },
  },
};
</script>

<style>
.pipeline-step {
  cursor: pointer;
  border: 0.1rem solid #d8dbe0;
}

.passed {
  background-color: #05b9ae;
}

.passed-lost {
  background-color: #f94839;
}

.vdp-datepicker input {
  height: 33px;
  width: 100%;
  border: 1px #d8dbe0 solid;
  padding-left: 13px;
}

.vdp-datepicker input:focus {
  border: 1px #ccc7f6 solid;
}

.model-select {
  color: #768192 !important;
}

.search.selection.dropdown.disabled {
  opacity: 1;
}

.status-icon {
  margin-top: -15px;
}

.deal-activity-wrapper {
  display: flex;
  justify-content: space-between;
  border: solid 1px #e5e5e5;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  width: 95%;
}

.deal-activity {
  display: flex;
  align-items: center;
}

.deal-activity-information {
  margin-left: 30px;
}

.deal-activity-title {
  font-size: 16px;
  font-weight: bold;
}

.deal-activity-title > span {
  cursor: pointer;
}

.deal-activity-title > span:hover {
  color: blue;
}

.deal-activity-start-time {
  margin-top: 10px;
}

.deal-notes img {
  max-width: 300px;
}

.deal-activity-filter {
  margin: 30px 0px;
}

.deal-activity-filter .text-primary {
  color: #26292c !important;
  border-bottom: 2px solid #721ea9;
}

.deal-activity-filter .tab {
  font:
    400 13px/16px Source Sans Pro,
    sans-serif;
  padding: 5px 12px;
}

.activity-action {
  background-color: transparent;
  color: gray;
}

.activity-action .dropdown-menu {
  margin-top: 10px !important;
}

.deal-modal {
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.new-people {
  position: absolute;
  top: 10px;
  left: 30px;
}

.new-people-badge {
  position: absolute;
  top: 7px;
  right: 50px;
  padding: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.vdp-datepicker {
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #d8dbe0;
}

.people-box {
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-shadow: 1px 1px 5px gray;
  height: 40px;
}

.people-box:hover {
  background: gray;
  color: white;
}

.tab {
  font-size: 18px;
}

.similar-people-container {
  max-height: 400px;
  overflow-y: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 8px;
}

.people-box {
  background: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.people-box:last-child {
  margin-bottom: 0;
}

.people-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #321fdb;
}

.people-box-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.people-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.people-name {
  font-weight: 600;
  font-size: 1.1em;
  color: #2c3e50;
}

.people-box-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #64748b;
  font-size: 0.95em;
}

.people-box-details div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.people-box-details .c-icon {
  color: #321fdb;
  opacity: 0.8;
}

/* Styling für den "Als neuen Kontakt anlegen" Button */
.people-box:last-child {
  background: #f8fafc;
  border: 2px dashed #e4e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  font-weight: 500;
  color: #64748b;
}

.people-box:last-child:hover {
  background: #f1f5f9;
  border-color: #321fdb;
  color: #321fdb;
}
</style>
