<template>
  <CRow>
    <CCol sm="6" lg="3" v-if="this.$commons.isSystemAdmin()">
      <CWidgetDropdown
        color="primary"
        :header="realmsRegistered"
        text="Instanzen"
      >
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height: 70px"
            :data-points="[0, 0, 0, 0, 0, 0, 0]"
            point-hover-background-color="primary"
            label="Realms"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="info" :header="leadsRegistered" text="Leads">
        <template #footer>
          <CChartLineSimple
            class="mt-3 mx-3"
            style="height: 70px"
            :data-points="[0, 0, 0, 0, 0, 0, 0]"
            point-hover-background-color="info"
            :options="{ elements: { line: { tension: 0.00001 } } }"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import { CChartLineSimple } from "../charts/index.js";

export default {
  name: "WidgetsDropdown",
  components: { CChartLineSimple },
  data() {
    return {
      realmsRegistered: "0",
      leadsRegistered: "0",
    };
  },
  mounted() {
    if (this.$commons.isSystemAdmin()) {
      axios
        .get("/dashboard/realmsRegistered")
        .then((response) => {
          this.realmsRegistered = String(response.data.count);
        })
        .catch((err) => console.error(err));
    }
    axios
      .get("/dashboard/leadsRegistered")
      .then((response) => {
        this.leadsRegistered = String(response.data.count);
      })
      .catch((err) => console.error(err));
  },
};
</script>
