
<template>
  <div class="pipeline-creator">
    <!-- Header Section -->
    <CCard class="mb-4">
      <CCardBody>
        <CRow>
          <CCol sm="8">
            <h1 class="display-4 mb-0">Pipeline erstellen</h1>
            <p class="text-muted lead">
              Gestalten Sie Ihren Verkaufsprozess in wenigen Schritten
            </p>
          </CCol>
          <CCol sm="4" class="d-flex align-items-center justify-content-end">
            <CButton
              color="light"
              class="mr-2"
              @click="$router.push('/pipelines')"
            >
              <CIcon name="cil-arrow-left" /> Zurück
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CRow class="mb-4">
      <CCol>
        <CCard>
          <CCardHeader class="bg-light">
            <h3 class="h5 mb-0">Ihre bestehenden Pipelines</h3>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :items="existingPipelines"
              :fields="[
    { key: 'name', label: 'Name' },
    { key: 'description', label: 'Beschreibung' },
    {
      key: 'pipelineSteps',
      label: 'Anzahl Phasen',
      _style: 'text-align: left; width: 150px;'
    },
    {
      key: 'actions',
      label: 'Aktionen',
      _style: 'text-align: right; width: 250px;'
    }
  ]"
              :loading="isLoading"
              hover
              striped
              class="mb-0"
            >
              <template #pipelineSteps="{item}">
                <td class="text-right">
                  {{ item.pipelineSteps.length }} Phasen
                </td>
              </template>

              <template #actions="{item}">
                <td>
                  <div class="d-flex justify-content-end">
                    <CButton
                      color="info"
                      variant="ghost"
                      size="sm"
                      class="mx-1"
                      @click="editExistingPipeline(item)"
                    >
                      <CIcon name="cil-pencil" /> Bearbeiten
                    </CButton>
                    <CButton
                      color="danger"
                      variant="ghost"
                      size="sm"
                      @click="deletePipeline(item.id)"
                    >
                      <CIcon name="cil-trash" /> Löschen
                    </CButton>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Main Content -->
    <CRow>
      <!-- Left Side - Pipeline Configuration -->
      <CCol md="5">
        <CCard class="mb-4">
          <CCardHeader class="bg-primary text-white">
            <h2 class="h5 mb-0">1. Pipeline Details</h2>
          </CCardHeader>
          <CCardBody>
            <CInput
              v-model="pipelineData.name"
              label="Pipeline Name"
              placeholder="z.B. B2B Vertriebsprozess"
              :invalid="errors.name"
              invalid-feedback="Bitte geben Sie einen Namen ein"
              class="mb-4"
            />
            <CTextarea
              v-model="pipelineData.description"
              label="Beschreibung"
              placeholder="Beschreiben Sie den Zweck dieser Pipeline..."
              rows="3"
              class="mb-4"
            />
          </CCardBody>
        </CCard>

        <CCard class="mb-4">
          <CCardHeader class="bg-primary text-white">
            <h2 class="h5 mb-0">2. Phasen hinzufügen</h2>
          </CCardHeader>
          <CCardBody>
            <div class="pipeline-steps-creator">
              <draggable
                v-model="pipelineData.steps"
                class="pipeline-steps-list"
                handle=".drag-handle"
              >
                <transition-group name="list" tag="div">
                  <CCard
                    v-for="(step, index) in pipelineData.steps"
                    :key="step.id || index"
                    class="step-card mb-3"
                  >
                    <CCardBody class="p-3">
                      <div class="d-flex align-items-center">
                        <div class="drag-handle mr-2">
                          <CIcon name="cil-menu" />
                        </div>
                        <div class="step-number mr-3">
                          {{ index + 1 }}
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-1">{{ step.name }}</h6>
                          <small class="text-muted">
                            Erfolgswahrscheinlichkeit: {{ step.probability }}%
                          </small>
                        </div>
                        <div class="step-actions">
                          <CButton
                            color="info"
                            variant="ghost"
                            size="sm"
                            class="mr-1"
                            @click="editStep(index)"
                          >
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton
                            color="danger"
                            variant="ghost"
                            size="sm"
                            @click="removeStep(index)"
                          >
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                </transition-group>
              </draggable>

              <CButton
                color="success"
                variant="outline"
                block
                @click="openStepModal()"
              >
                <CIcon name="cil-plus" /> Phase hinzufügen
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <!-- Right Side - Preview -->
      <CCol md="7">
        <CCard class="pipeline-preview">
          <CCardHeader class="bg-light">
            <h2 class="h5 mb-0">Pipeline Vorschau</h2>
          </CCardHeader>
          <CCardBody class="p-4">
            <div class="pipeline-visualization">
              <!-- Pipeline Preview Visualization -->
              <div class="pipeline-flow">
                <div
                  v-for="(step, index) in pipelineData.steps"
                  :key="index"
                  class="pipeline-stage"
                  :class="{'active': previewActiveStep === index}"
                >
                  <div class="stage-content">
                    <div class="stage-number">{{ index + 1 }}</div>
                    <div class="stage-info">
                      <h6>{{ step.name }}</h6>
                      <div class="probability-bar">
                        <div
                          class="probability-fill"
                          :style="{width: `${step.probability}%`}"
                        ></div>
                      </div>
                      <small>{{ step.probability }}% Erfolgswahrscheinlichkeit</small>
                    </div>
                  </div>
                  <div class="stage-arrow" v-if="index < pipelineData.steps.length - 1">
                    <CIcon name="cil-arrow-right" />
                  </div>
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <!-- Action Buttons -->
        <CCard class="mt-4">
          <CCardBody class="d-flex justify-content-between">
            <CButton
              color="secondary"
              variant="outline"
              @click="$router.push('/pipelines')"
            >
              Abbrechen
            </CButton>
            <div>
              <CButton
                color="success"
                class="ml-2"
                :disabled="!isValid || isSaving"
                @click="savePipeline"
              >
                <CIcon name="cil-check" />
                Pipeline speichern
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Step Modal -->
    <CModal
      :show.sync="showStepModal"
      title="Pipeline-Phase konfigurieren"
      size="lg"
    >
      <div class="p-4">
        <!-- Name der Phase -->
        <CInput
          v-model="currentStep.name"
          label="Name der Phase"
          placeholder="z.B. Erstgespräch, Angebot erstellt, Verhandlung"
          :invalid="stepErrors.name"
          invalid-feedback="Bitte geben Sie einen Namen ein"
          class="mb-4"
        />

        <!-- Beschreibung -->
        <CTextarea
          v-model="currentStep.description"
          label="Beschreibung der Phase"
          placeholder="Beschreiben Sie, was in dieser Phase passiert..."
          rows="3"
          class="mb-4"
        />

        <!-- Erfolgswahrscheinlichkeit -->
        <div class="form-group mb-4">
          <label>Erfolgswahrscheinlichkeit</label>
          <div class="d-flex align-items-center probability-selector">
            <input
              v-model.number="currentStep.probability"
              type="range"
              class="form-control-range flex-grow-1 mr-3"
              min="0"
              max="100"
              step="5"
            />
            <div class="probability-badge">
              {{ currentStep.probability ?? 50 }}%
            </div>
          </div>
          <small class="text-muted">
            Wie hoch ist die Chance, dass ein Deal in dieser Phase erfolgreich abgeschlossen wird?
          </small>
        </div>
      </div>

      <template #footer>
        <CButton
          color="secondary"
          @click="closeStepModal"
        >
          Abbrechen
        </CButton>
        <CButton
          color="primary"
          @click="saveStep"
        >
          Speichern
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import toastPlugin from "@/toastPlugin";
import { CFormGroup, CInput, CTextarea, } from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import axios from 'axios'
import draggable from 'vuedraggable'


export default {
  name: 'PipelineCreator',

  components: {
    CFormGroup,
    CInput,
    CTextarea,
    CIcon,
    toastPlugin,
    draggable
  },

  data() {
    return {
      pipelineData: {
        name: '',
        description: '',
        steps: [],
        isLoading: false,
      },

      currentStep: {
        name: '',
        description: '',
        probability: 50,
        duration: 1,
        durationUnit: 'days',
        activities: [],
        index: null
      },

      existingPipelines: [],
      isLoading: false,

      showStepModal: false,
      isSaving: false,
      previewActiveStep: 0,

      errors: {
        name: false
      },

      stepErrors: {
        name: false,
        probability: false,
        duration: false
      }
    }
  },

  async created() {
    this.loadPipelines()
  },

  computed: {
    isValid() {
      return this.pipelineData.name.trim() &&
        this.pipelineData.steps.length > 0
    }
  },

  methods: {
    async loadPipelines() {
      this.isLoading = true
      try {
        const response = await axios.get('/pipeline')
        this.existingPipelines = response.data
      } catch (error) {
        this.$toast.error('Fehler beim Laden der Pipelines')
        console.error('Error loading pipelines:', error)
      } finally {
        this.isLoading = false
      }
    },

    openStepModal(index = null) {
      if (index !== null) {
        const step = this.pipelineData.steps[index]
        this.$set(this, 'currentStep', {
          name: step.name,
          description: step.description || '',
          probability: step.probability ?? 50,
          duration: step.duration || 1,
          durationUnit: step.durationUnit || 'days',
          activities: [...(step.activities || [])],
          index
        })
      } else {
        this.$set(this, 'currentStep', {
          name: '',
          description: '',
          probability: 50,
          duration: 1,
          durationUnit: 'days',
          activities: [],
          index: null
        })
      }

      this.showStepModal = true
    },

    addActivity() {
      this.currentStep.activities.push('')
    },

    removeActivity(index) {
      this.currentStep.activities.splice(index, 1)
    },


    editStep(index) {
      const step = this.pipelineData.steps[index]
      this.currentStep = {
        name: step.name,
        description: step.description || '',
        probability: step.probability,
        duration: step.duration || 1,
        durationUnit: step.durationUnit || 'days',
        activities: [...(step.activities || [])], // Ensure activities is an array
        index
      }
      this.showStepModal = true
    },

    closeStepModal() {
      this.showStepModal = false
      this.stepErrors.name = false
    },

    saveStep() {
      if (!this.validateStep()) return

      const stepData = {
        name: this.currentStep.name,
        description: this.currentStep.description,
        probability: this.currentStep.probability,
        duration: this.currentStep.duration,
        durationUnit: this.currentStep.durationUnit,
        activities: this.currentStep.activities.filter(a => a.trim())
      }

      if (this.currentStep.index !== null) {
        this.$set(this.pipelineData.steps, this.currentStep.index, stepData)
      } else {
        this.pipelineData.steps.push(stepData)
      }

      this.closeStepModal()
    },

    validateStep() {
      let isValid = true
      this.stepErrors = {
        name: false,
        probability: false,
        duration: false
      }

      if (!this.currentStep.name.trim()) {
        this.stepErrors.name = true
        isValid = false
      }

      if (this.currentStep.probability < 0 || this.currentStep.probability > 100) {
        this.stepErrors.probability = true
        isValid = false
      }

      if (this.currentStep.duration < 1) {
        this.stepErrors.duration = true
        isValid = false
      }

      return isValid
    },

    removeStep(index) {
      this.pipelineData.steps.splice(index, 1)
    },

    editExistingPipeline(pipeline) {
      this.pipelineData = {
        id: pipeline.id,
        name: pipeline.name,
        description: pipeline.description || '', // Beschreibung laden
        steps: pipeline.pipelineSteps?.map(step => ({
          name: step.name,
          description: step.description || '', // Step-Beschreibung laden
          probability: step.probability,
          orderNumber: step.orderNumber
        })) || []
      }
      // Scroll to editor
      this.$nextTick(() => {
        const editorElement = document.querySelector('.pipeline-creator')
        editorElement?.scrollIntoView({ behavior: 'smooth' })
      })
    },

    async deletePipeline(pipelineId) {
      if (!confirm('Möchten Sie diese Pipeline wirklich löschen?')) return

      try {
        await axios.delete(`/pipeline/${pipelineId}`)
        toastPlugin.makeToast('Erfolg', 'Pipeline erfolgreich gelöscht');
        await this.loadPipelines()
      } catch (error) {
        toastPlugin.makeToast('Fehler', 'Fehler beim Löschen der Pipeline', 'error');
        console.error('Error deleting pipeline:', error)
      }
    },

    async savePipeline() {
      if (!this.isValid) return;

      this.isSaving = true;
      try {
        const payload = {
          name: this.pipelineData.name,
          description: this.pipelineData.description,
          pipelineSteps: this.pipelineData.steps.map((step, index) => ({
            name: step.name,
            description: step.description,
            probability: step.probability,
            orderNumber: index + 1
          }))
        };

        if (this.pipelineData.id) {
          // Update existing pipeline
          await axios.put(`/pipeline/${this.pipelineData.id}`, payload);
          toastPlugin.makeToast("Erfolg", "Pipeline wurde erfolgreich aktualisiert");
        } else {
          // Create new pipeline
          await axios.post('/pipeline', payload);
          toastPlugin.makeToast("Erfolg", "Neue Pipeline wurde erfolgreich erstellt");
        }

        await this.loadPipelines();
        this.resetForm();
      } catch (error) {
        console.error('Fehler beim Speichern der Pipeline:', error);

        let errorMessage = 'Fehler beim Speichern der Pipeline';

        if (error?.response?.data?.message) {
          errorMessage = error.response.data.message;
        } else if (error?.message) {
          errorMessage = error.message;
        }

        toastPlugin.makeToast("Fehler", errorMessage);
      } finally {
        this.isSaving = false;
      }
    },

    // Stellen Sie sicher, dass die resetForm Methode auch die Beschreibung zurücksetzt
    resetForm() {
      this.pipelineData = {
        id: null,
        name: '',
        description: '', // Beschreibung zurücksetzen
        steps: []
      }
    }
  }
}
</script>

<style scoped>
.table td {
  vertical-align: middle !important;
}

.pipeline-creator {
  min-height: 100vh;
  background-color: #f8f9fa;
}

.step-card {
  background: white;
  border: 1px solid #e4e7ea;
  transition: all 0.3s ease;
}

.step-card:hover {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.step-number {
  width: 24px;
  height: 24px;
  background: #321fdb;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.drag-handle {
  cursor: move;
  color: #8a93a2;
}

.pipeline-visualization {
  padding: 20px;
  overflow-x: auto;
}

.pipeline-flow {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pipeline-stage {
  display: flex;
  align-items: center;
  padding: 15px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e4e7ea;
  transition: all 0.3s ease;
}

.pipeline-stage.active {
  border-color: #321fdb;
  box-shadow: 0 0 0 1px #321fdb;
}

.stage-content {
  display: flex;
  align-items: center;
  flex: 1;
}

.stage-info {
  margin-left: 15px;
  flex: 1;
}

.probability-bar {
  height: 6px;
  background: #e4e7ea;
  border-radius: 3px;
  margin: 8px 0;
  overflow: hidden;
}

.probability-fill {
  height: 100%;
  background: #321fdb;
  transition: width 0.3s ease;
}

.stage-arrow {
  margin: 0 20px;
  color: #8a93a2;
}

.probability-display {
  min-width: 60px;
  text-align: right;
  font-weight: bold;
}

.list-move {
  transition: transform 0.5s;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.probability-selector {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
}

.probability-badge {
  min-width: 70px;
  padding: 6px 12px;
  background: #4318FF;
  color: white;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 2px 4px rgba(67, 24, 255, 0.1);
}


input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #e9ecef;
  border-radius: 4px;
  margin: 10px 0;
}

/* Firefox-spezifische Styles */
input[type="range"]::-moz-range-thumb {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #4318FF;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
}

/* Aktiver Zustand */
input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 2px 8px rgba(67, 24, 255, 0.25);
  transform: scale(1.1);
}

/* Hover-Effekt */
input[type="range"]:hover::-webkit-slider-thumb {
  background: #3a14e0;
}

/* Verbessern des Slider-Thumbs (der bewegliche Punkt) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #4318FF; /* CoreUI primary blue */
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  margin-top: -8px; /* Zentriert den Thumb vertikal */
}


.form-control-range {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: #dee2e6;
  outline: none;
}

.form-control-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #321fdb;
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
</style>
