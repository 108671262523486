<template>
  <CCard class="task-widget">
    <CCardHeader>
      <h4 class="mb-0">Aktivitäten</h4>
      <CButton
        color="primary"
        size="sm"
        class="float-right"
        @click="showCreateModal"
      >
        <CIcon name="cil-plus" />
        Neue Aktivität
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CTabs :active-tab="activeTab" @update:activeTab="hideModalOnCalendar">
        <CTab title="Liste" active>
          <template #title>
            <CIcon name="cil-list" class="mr-1" />
            Liste
          </template>
          <CInput
            placeholder="Aktivität suchen"
            v-model="search"
            class="mb-3"
          />
          <CDataTable
            :items="tableData"
            :fields="tableFields"
            :items-per-page="5"
            :hover="true"
            :sorter="true"
            :pagination="true"
            :search="search"
            :loading="tableShowLoading"
          >
            <template #actions="{ item }">
              <td>
                <CButton
                  color="primary"
                  size="sm"
                  class="mr-1"
                  @click="showUpdateModal(item)"
                >
                  <CIcon name="cil-pencil" />
                </CButton>
                <CButton color="danger" size="sm" @click="confirmDelete(item)">
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CTab>
        <CTab title="Kalender">
          <template #title>
            <CIcon name="cil-calendar" class="mr-1" />
            Kalender
          </template>
          <TaskCalendar
            ref="calendar"
            :show-create-modal-with-data="showCreateModalWithData"
            :add-brief-activity="addBriefActivity"
            :show-update-modal="showUpdateModal"
            :delete-activity="deleteTask"
            :apply-parent-update="applyUpdate"
          />
        </CTab>
      </CTabs>
    </CCardBody>

    <CModal :show.sync="visibleActivityModal" title="Aktivität" size="lg">
      <ActivityWidget
        ref="activityWidget"
        :applyUpdate="applyUpdate"
        :closeModal="closeModal"
      />
      <template #footer>
        <CButton color="secondary" @click="closeModal">Abbrechen</CButton>
        <CButton color="primary" @click="submit">Speichern</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="deleteConfirmDialog"
      title="Aktivität löschen"
      color="danger"
    >
      <p>
        Möchten Sie diese Aktivität wirklich löschen? Diese Aktion kann nicht
        rückgängig gemacht werden.
      </p>
      <template #footer>
        <CButton color="secondary" @click="deleteConfirmDialog = false"
          >Abbrechen</CButton
        >
        <CButton color="danger" @click="confirmDeleteAction">Löschen</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import ActivityWidget from '@/components/shared/widgets/ActivityWidget.vue'
import axios from "axios";
import commons from "../../commons";
import { activityTypes } from "../../config/constant";
import moment from "moment";
import TaskCalendar from "./TaskCalendar";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CIcon,
  CInput,
  CModal,
  CTabs,
  CTab,
} from "@coreui/vue";

export default {
  name: "TaskWidget",
  components: {
    TaskCalendar,
    ActivityWidget,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CDataTable,
    CIcon,
    CInput,
    CModal,
    CTabs,
    CTab,
  },
  mounted() {
    this.loadTasks();
  },
  data: () => ({
    visibleActivityModal: false,
    tableShowLoading: false,
    tableData: [],
    selectedTaskId: null,
    activeTab: 0,
    search: "",
    deleteConfirmDialog: false,
    itemToDelete: null,
    tableFields: [
      { key: "title", label: "Betreff", _classes: "font-weight-bold" },
      { key: "typeText", label: "Kontaktart" },
      { key: "startTime", label: "Von" },
      { key: "endTime", label: "Bis" },
      { key: "doneStr", label: "Erledigt" },
      { key: "actions", label: "Aktionen", sorter: false, filter: false },
    ],
  }),
  methods: {
    submit() {
      this.$refs.activityWidget.submit(this.selectedTaskId);
    },
    closeModal() {
      this.$refs.activityWidget.initialize();
      this.visibleActivityModal = false;
    },
    getTableFields() {
      return [
        {
          key: "title",
          label: "Betreff",
        },
        {
          key: "typeText",
          label: "Kontaktart",
        },
        {
          key: "startTime",
          label: "Fälligkeitstermin von",
        },
        {
          key: "endTime",
          label: "Fälligkeitstermin bis",
        },
        {
          key: "doneStr",
          label: "Als erledigt markiert?",
        },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
        {
          key: "delete_item",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ];
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteConfirmDialog = true;
    },

    confirmDeleteAction() {
      if (this.itemToDelete) {
        this.deleteTask(this.itemToDelete);
        this.deleteConfirmDialog = false;
        this.itemToDelete = null;
      }
    },

    loadTasks() {
      const leadId = this.$route.params.id;
      let endpoint = ``;
      if (leadId) {
        // if this is in lead detail view
        endpoint = `task/lead/${leadId}`;
      } else {
        // this is in dashboard tasks page
        endpoint = `task/realm`;
      }

      this.tableShowLoading = true;
      axios
        .get(endpoint)
        .then((response) => {
          let content = [];
          response.data.forEach((value) => {
            const selectedActivity = activityTypes.find(
              (item) => item.value === value["type"],
            );
            value["typeText"] = selectedActivity.text;
            value["startTimeObj"] = commons.formatStandardDate(
              new Date(value["startTime"]),
            );
            value["endTimeObj"] = commons.formatStandardDate(
              new Date(value["endTime"]),
            );
            moment.locale("de");
            value["startTime"] = moment(value["startTime"]).format("LLL");
            value["endTime"] = moment(value["endTime"]).format("LLL");
            value["doneStr"] = value["done"] ? "Ja" : "Nein";
            content.push(commons.flatObject(value));
          });
          this.tableData = content;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableShowLoading = false;
        });
    },
    // after success the create or update activity, also update the table data
    applyUpdate(data) {
      const leadId = this.$route.params.id;
      const index = this.tableData.findIndex((entity) => entity.id === data.id);

      data["startTimeObj"] = commons.formatStandardDate(
        new Date(data["startTime"]),
      );
      data["endTimeObj"] = commons.formatStandardDate(
        new Date(data["endTime"]),
      );
      moment.locale("de");
      data["startTime"] = moment(data["startTime"]).format("LLL");
      data["endTime"] = moment(data["endTime"]).format("LLL");
      data["doneStr"] = data["done"] ? "Yes" : "No";
      const selectedActivity = activityTypes.find(
        (item) => item.value === data["type"],
      );
      data["typeText"] = selectedActivity.text;

      if (leadId) {
        // if this is in lead detail
        if (data["lead"] && parseInt(data["lead"].id) === parseInt(leadId)) {
          // when task belongs to this lead
          // if created
          if (index === -1) {
            if (this.tableData.length === 0) {
              this.tableData = [commons.flatObject(data)];
            } else {
              this.tableData.push(commons.flatObject(data));
            }
          } else {
            this.tableData.splice(index, 1, commons.flatObject(data));
          }
        } else {
          this.tableData.splice(index, 1);
        }
      } else {
        if (index === -1) {
          // if task created
          if (this.tableData.length === 0) {
            this.tableData = [commons.flatObject(data)];
          } else {
            this.tableData.push(commons.flatObject(data));
          }
        } else {
          this.tableData.splice(index, 1, commons.flatObject(data));
        }
      }

      this.$refs.calendar.applyUpdate(data);
    },
    showCreateModal() {
      this.selectedTaskId = null;
      this.visibleActivityModal = true;
      this.$refs.activityWidget.initialize();
    },
    /**
     * @description show the create modal when click the detail on calendar view creation modal
     */
    showCreateModalWithData(newEvent) {
      this.selectedTaskId = null;
      this.visibleActivityModal = true;
      this.$refs.activityWidget.initializeOnClickDetail(newEvent);
    },
    /**
     * @description when click the add button on calendar view creation modal
     * @param newEvent
     */
    addBriefActivity(newEvent) {
      this.selectedTaskId = null;
      this.$refs.activityWidget.initializeOnClickDetail(newEvent);
      this.$refs.activityWidget.submit(this.selectedTaskId);
    },
    showUpdateModal(item) {
      const task = {
        id: item.id || null,
        title: item.title || '',
        type: item.type || 'CALL',
        endTime: item.endTime,
        endTimeObj: item.endTimeObj,
        startTime: item.startTime || item.endTime,  // Fallback auf endTime
        startTimeObj: item.startTimeObj || (item.endTimeObj ? moment(item.endTimeObj, "DD/MM/YYYY HH:mm").subtract(15, 'minutes').format("DD/MM/YYYY HH:mm") : null),
        description: item.description || '',
        location: item.location || '',
        freeType: item.freeType || 'FREE',
        notes: item.notes || '',
        done: item.done || false
      };

      this.$refs.activityWidget.handleInitializeTask(task);
      this.visibleActivityModal = true;
      this.selectedTaskId = item.id;
    },
    deleteTask(item) {
      this.tableShowLoading = true;
      axios
        .delete(`task/${item.id}`)
        .then(() => {
          this.tableData =
            this.tableData &&
            this.tableData.filter((entity) => entity.id !== item.id);
          this.$refs.calendar.events =
            this.$refs.calendar.events &&
            this.$refs.calendar.events.filter(
              (entity) => entity.id !== item.id,
            );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableShowLoading = false;
        });
    },
    hideModalOnCalendar() {
      this.$refs.calendar.hideModal("activity-modal");
      this.$refs.calendar.hideModal("detail-modal");
    },
  },
  watch: {
    // when modal closed, initialize the activity modal fields
    visibleActivityModal: function (val) {
      if (!val) {
        this.$refs.activityWidget.initialize();
      }
    },
    activeTab(val) {
      console.log(val);
      this.hideModalOnCalendar();
    },
  },
};
</script>

<style scoped>
.task-calendar {
  height: 600px;
  width: 100%;
}

#calendar {
  height: 100%;
  width: 100%;
}
.task-widget {
  margin-bottom: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.add-activity-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}
.activity-widget .calendar {
  border: 1px solid var(--border);
  border-radius: 0 0 var(--radius) var(--radius);
  overflow: hidden;
  margin-bottom: 20px; /* Add margin to prevent overlap */

  :deep(.vuecal__event) {
    padding: 4px 8px;
    border-radius: 4px;

    &.current {
      background: var(--primary);
      color: white;
    }
  }
}
</style>
