<template>
  <LgcModule
    class="lgc-module-choices"
    @delete="$emit('delete', $event)"
    @add-below="$emit('add-below', $event)"
  >
    <div
      v-for="(choice, index) in choices"
      :key="choice.id"
      class="lgc-module-choices__item"
      @click="editChoice(index)"
    >
      <div class="lgc-module-choices__content">
        <div
          class="lgc-module-choices__title"
          :class="{ 'lgc-module-choices__title--empty': !choice.title }"
          contenteditable="true"
          @click.stop.prevent
          @focusout="handleTitleUpdate(choice, $event)"
          @keydown.enter.prevent
          v-text="choice.title"
          role="textbox"
          :aria-label="`Choice ${index + 1} title`"
        ></div>
      </div>

      <div class="lgc-module-choices__actions">
        <button
          type="button"
          class="lgc-module-choices__action lgc-module-choices__action--add"
          @click.stop="addNewChoice(choice.id)"
          :aria-label="`Add new choice after ${choice.title || 'untitled choice'}`"
        >
          <span v-html="svgPlusIcon" aria-hidden="true"></span>
        </button>

        <button
          type="button"
          class="lgc-module-choices__action lgc-module-choices__action--delete"
          @click.stop="confirmDeleteChoice(choice)"
          :aria-label="`Delete choice ${choice.title || 'untitled choice'}`"
        >
          <span v-html="svgDeleteIcon" aria-hidden="true"></span>
        </button>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <CModal
      :show="modalVisible"
      :close-on-backdrop="false"
      title="Bestätigung"
      aria-labelledby="deleteConfirmTitle"
    >
      <p>Möchten Sie diese Auswahl wirklich löschen?</p>
      <template #footer>
        <CButton
          color="danger"
          variant="outline"
          type="button"
          @click="executeDelete"
        >
          Löschen
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          type="button"
          @click="closeModal"
        >
          Abbrechen
        </CButton>
      </template>
    </CModal>
  </LgcModule>
</template>

<script>
import _ from 'lodash';
import LgcModule from '@/views/base/leadgenerator/lgc-modules/LgcModule';

// SVG Icons direkt in der Komponente definiert
const svgPlusIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 191 104.23"><defs><style>.cls-1{fill:#22b3ab;stroke:#04786c;stroke-miterlimit:10;}.cls-2{fill:#fff;}</style></defs><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect class="cls-1" x="0.5" y="0.5" width="190" height="103.23" rx="8"/><rect class="cls-2" x="92.9" y="13.15" width="5.2" height="77.94"/><rect class="cls-2" x="92.9" y="13.15" width="5.2" height="77.94" transform="translate(147.61 -43.39) rotate(90)"/></g></g></svg>';

const svgDeleteIcon = '<svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewbox="0 0 191 104.2" style="enable-background:new 0 0 191 104.2;" xml:space="preserve"><style type="text/css">.st0{fill:#DC2F2F;stroke:#8E1A1A;stroke-miterlimit:10;}.st1{fill:#FFFFFF;}</style><g id="Ebene_2_1_"><g id="Ebene_1-2"><path class="st0" d="M8.5,0.5h174c4.4,0,8,3.6,8,8v87.2c0,4.4-3.6,8-8,8H8.5c-4.4,0-8-3.6-8-8V8.5C0.5,4.1,4.1,0.5,8.5,0.5z"/></g></g><path class="st1" d="M84.9,50.3c-0.1-1.7,1.3-3.2,3-3.3c1.7-0.1,3.2,1.3,3.3,3c0,0.1,0,0.2,0,0.3v23.3c0.1,1.7-1.3,3.2-3,3.3c-1.7,0.1-3.2-1.3-3.3-3c0-0.1,0-0.2,0-0.3V50.3z M127.1,37.1l-3.2,42.7c-0.2,3.5-1.7,6.8-4.2,9.4c-2.4,2.5-5.8,3.9-9.2,3.8H80.5c-3.5,0-6.8-1.4-9.2-3.8l0,0c-2.5-2.5-4-5.8-4.2-9.4l-3.2-42.7h-5.1v-9.2c0-2.2,1.1-3,3.2-3h15.1v-6.4c0-4,3.2-7.2,7.2-7.2h22.5c3.9,0,7.1,3.2,7.2,7.2v6.4h15.5c0.2,0,0.4,0,0.7,0c1.2,0.2,2.1,1.2,2.2,2.3c0,0.1,0,0.3,0,0.4v9.4H127.1z M120.8,37.2H70.2l3.2,42.1c0.1,2,1,3.9,2.4,5.4l0,0c1.3,1.3,3,2,4.8,2h29.9c1.8,0,3.5-0.7,4.8-2c1.4-1.5,2.3-3.4,2.4-5.4L120.8,37.2L120.8,37.2z M105.9,24.8v-5.6H85.1v5.6H105.9z M99.9,50.3c-0.1-1.7,1.3-3.2,3-3.3c1.7-0.1,3.2,1.3,3.3,3c0,0.1,0,0.2,0,0.3v23.3c0.1,1.7-1.3,3.2-3,3.3s-3.2-1.3-3.3-3c0-0.1,0-0.2,0-0.3C99.9,73.6,99.9,50.3,99.9,50.3z"/></svg>';

export default {
  name: 'LgcModuleChoices',

  components: {
    LgcModule
  },

  props: {
    value: {
      type: Array,
      default: () => [],
      validator: function(value) {
        return Array.isArray(value);
      }
    }
  },

  data() {
    return {
      choices: [],
      modalVisible: false,
      choiceToDelete: null,
      svgPlusIcon,
      svgDeleteIcon
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.choices = _.cloneDeep(newValue);
      }
    }
  },

  methods: {
    handleTitleUpdate(choice, event) {
      const newTitle = _.trim(event.target.innerText);
      if (choice.title !== newTitle) {
        this.$set(choice, 'title', newTitle);
        this.emitChoices();
      }
    },

    addNewChoice(id) {
      const choiceIndex = _.findIndex(this.choices, { id });
      this.choices.splice(choiceIndex + 1, 0, {
        id: this.generateNewId(),
        title: 'Neue Auswahl'
      });
      this.emitChoices();
    },

    confirmDeleteChoice(choice) {
      this.choiceToDelete = choice;
      this.modalVisible = true;
      this.$emit('modal-open');
    },

    executeDelete() {
      if (this.choiceToDelete) {
        this.choices = this.choices.filter(c => c.id !== this.choiceToDelete.id);
        this.emitChoices();
      }
      this.closeModal();
    },

    closeModal() {
      this.modalVisible = false;
      this.choiceToDelete = null;
      this.$emit('modal-close');
    },

    generateNewId() {
      return Math.max(0, ...this.choices.map(c => c.id)) + 1;
    },

    emitChoices() {
      // Reorder and update IDs
      const updatedChoices = this.choices.map((choice, index) => ({
        ...choice,
        id: index + 1
      }));

      this.$emit('input', {
        value: updatedChoices
      });
    },

    editChoice(index) {
      // Reserved for future edit functionality
      console.log(`Editing choice at index ${index}`);
    }
  }
};
</script>

<style lang="scss">
.lgc-module-choices {
  --primary-color: #22b3ab;
  --danger-color: #dc3545;
  --border-color: #dee2e6;
  --hover-outline-color: #46d7ce;

  &__item {
    position: relative;
    width: 100%;
    margin: 0.75rem 0;
    border-radius: 0.5rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      outline: 2px dashed var(--hover-outline-color);
      outline-offset: 0.25rem;
    }

    &:hover .lgc-module-choices__actions {
      opacity: 1;
      visibility: visible;
    }
  }

  &__content {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: white;
  }

  &__title {
    outline: none;
    width: 100%;
    min-height: 2.5rem;
    font-size: 1.125rem;
    line-height: 1.5;
    padding: 0.25rem;

    &:focus {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 0.25rem;
    }

    &--empty {
      &:before {
        content: 'Titel eingeben...';
        color: #6c757d;
      }
    }
  }

  &__actions {
    position: absolute;
    display: flex;
    gap: 1rem;
    justify-content: center;
    width: 100%;
    bottom: -1rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }

  &__action {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &--add {
      .cls-1 {
        transition: fill 0.2s ease-in-out;
      }

      &:hover .cls-1 {
        fill: darken(#22b3ab, 10%);
      }
    }

    &--delete {
      .st0 {
        transition: fill 0.2s ease-in-out;
      }

      &:hover .st0 {
        fill: darken(#dc3545, 10%);
      }
    }
  }

  @media (max-width: 768px) {
    &__actions {
      position: static;
      opacity: 1;
      visibility: visible;
      margin-top: 0.5rem;
    }

    &__item:hover {
      outline-offset: 0.125rem;
    }
  }
}
</style>
