<template>
  <CModal
    :show.sync="localShow"
    title="Beitrag bearbeiten"
    size="lg"
    @close="handleClose"
  >
    <template v-if="post">
      <CCard>
        <CCardBody>
          <SocialMediaSelector
            v-model="editedPost.connection"
            :options="socialMediaOptions"
            label="Social-Media-Plattform"
            class="mb-3"
          />

          <CInput
            v-model="editedPost.name"
            label="Titel"
            class="mb-3"
            :counter="true"
            :max-length="100"
          />

          <CTextarea
            v-model="editedPost.content"
            label="Beitragsinhalt"
            rows="5"
            class="mb-3"
            :counter="true"
            :max-length="280"
          />

          <DateTimePicker
            id="publish-at-picker"
            class="prt-social-media-date-time-picker mb-3"
            label="Veröffentlichungszeitpunkt"
            description="Wählen Sie Datum und Uhrzeit für die Veröffentlichung Ihres Beitrags."
            :horizontal="false"
            v-model="editedPost.publishAt"
            v-c-tooltip="
              'Bestimmen Sie einen spezifischen Zeitpunkt für die Veröffentlichung Ihres Beitrags.'
            "
          />

          <ImageUploadAndSearch
            :uploadLabel="'Bild hochladen'"
            :searchLabel="'Stock-Fotos auswählen'"
            :initialImage="editedPost.imageUrl"
            :firstKeyword="synonymForImageSearch"
            @image-upload="handleImageUpload"
            @image-select="handleImageSelect"
            @image-remove="handleImageRemove"
          />

          <div class="image-preview mb-3" v-if="editedPost.imageUrl">
            <img
              :src="editedPost.imageUrl"
              alt="Vorschau"
              class="img-fluid rounded"
            />
          </div>

          <CTags
            v-model="editedPost.tags"
            label="Tags"
            placeholder="Fügen Sie relevante Tags hinzu"
          />
        </CCardBody>
      </CCard>
    </template>

    <template #footer>
      <CButton
        color="warning"
        @click="generateAlternative"
        :disabled="isGenerating"
      >
        <CSpinner v-if="isGenerating" size="sm" />
        Alternative generieren
      </CButton>
      <CButton color="primary" @click="savePost" :disabled="!isFormValid"
        >Speichern & Planen</CButton
      >
    </template>
  </CModal>
</template>

<script>
import DateTimePicker from "@/components/DateTimePicker.vue";
import SocialMediaSelector from "@/components/SocialMediaSelector.vue";
import CTags from "@/components/CTags.vue";
import ImageUploadAndSearch from "@/components/ImageUploadAndSearch.vue";
import axios from "axios";

export default {
  components: {
    DateTimePicker,
    SocialMediaSelector,
    CTags,
    ImageUploadAndSearch,
  },
  props: {
    show: Boolean,
    post: Object,
    socialMediaOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localShow: this.show,
      editedPost: {},
      originalPost: {},
      isGenerating: false,
      synonymForImageSearch: "", // Speichert das Synonym für die Bildersuche
    };
  },
  watch: {
    show(newValue) {
      this.localShow = newValue;
    },
    localShow(newValue) {
      if (!newValue) {
        this.$emit("update:show", false);
        this.editedPost = {}; // Rücksetzen von editedPost beim Schließen des Modals
      }
    },
    post: {
      handler(newPost) {
        if (newPost) {
          this.editedPost = { ...newPost };
          this.originalPost = { ...newPost };
        }
      },
      immediate: true,
    },
  },
  computed: {
    isFormValid() {
      return (
        this.editedPost.name &&
        this.editedPost.content &&
        this.editedPost.content.length <= 1000 &&
        this.editedPost.connection &&
        this.editedPost.publishAt
      );
    },
  },
  methods: {
    savePost() {
      if (this.isFormValid) {
        const editedPost = {
          ...this.editedPost,
          connectionId: this.editedPost.connection.id,
          approved: true
        };
        this.$emit("save", editedPost);
        this.localShow = false; // Close the modal after saving
      }
    },
    handleClose() {
      console.log("handleClose triggered");
      if (
        JSON.stringify(this.editedPost) !== JSON.stringify(this.originalPost)
      ) {
        if (
          confirm(
            "Sie haben ungespeicherte Änderungen. Möchten Sie wirklich schließen?",
          )
        ) {
          this.localShow = false; // Schließen des Modals
        }
      } else {
        this.localShow = false; // Schließen des Modals
      }
    },
    generateAlternative() {
      this.isGenerating = true;
      const requestData = {
        content: this.editedPost.content,
        connectionId: this.editedPost.connection.id,
        generateAlternative: true,
      };
      axios
        .post("/socialMedia/generateAlternative", requestData)
        .then((response) => {
          if (
            response.data &&
            response.data.generatedContents &&
            response.data.generatedContents.length > 0
          ) {
            const generatedContent = response.data.generatedContents[0];
            this.editedPost.content = generatedContent.content;
            this.synonymForImageSearch = generatedContent.synonym || "";
            this.$toaster.makeToast(
              "Erfolgreich!",
              "Alternative erfolgreich generiert.",
            );
          } else {
            this.$toaster.makeToast("Fehler", "Keine Alternative generiert.");
          }
        })
        .catch((err) => {
          console.error("Fehler beim Generieren der Alternative:", err);
          this.$toaster.makeToast(
            "Fehler",
            "Fehler beim Generieren der Alternative.",
          );
        })
        .finally(() => {
          this.isGenerating = false;
        });
    },
    handleImageUpload(imageUrl) {
      this.editedPost.imageUrl = imageUrl;
    },
    handleImageSelect(imageUrl) {
      this.editedPost.imageUrl = imageUrl;
    },
    handleImageRemove() {
      this.editedPost.imageUrl = null;
    },
  },
};
</script>

<style scoped>
.prt-social-media-date-time-picker {
  width: 100%;
}

.image-preview {
  max-width: 300px;
  margin: 0 auto;
}

.image-preview img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
</style>
