<!-- src/components/google-my-business/LocationsList.vue -->
<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol sm="6">
          <h4>Standorte</h4>
        </CCol>
        <CCol sm="6" class="d-flex justify-content-end">
          <CButton
              color="primary"
              @click="showAddModal"
              :disabled="loading"
          >
            <CIcon name="cil-plus" /> Standort hinzufügen
          </CButton>
        </CCol>
      </CRow>
    </CCardHeader>

    <CCardBody>
      <!-- Suchleiste -->
      <CRow class="mb-3">
        <CCol sm="12" md="6">
          <CInput
              placeholder="Suchen..."
              v-model="searchQuery"
              @input="handleSearch"
          >
            <template #prepend-content>
              <CIcon name="cil-search" />
            </template>
          </CInput>
        </CCol>
      </CRow>

      <!-- Locations Tabelle -->
      <CDataTable
          :items="locations"
          :fields="fields"
          :loading="loading"
          hover
          striped
          pagination
          :items-per-page="10"
          :sorter="{ external: false, resetable: true }"
      >
        <!-- Status Column -->
        <template #business-status="{item}">
          <CBadge :color="getStatusColor(item.businessStatus)">
            {{ item.businessStatus }}
          </CBadge>
        </template>

        <!-- Actions Column -->
        <template #actions="{item}">
          <td class="py-2">
            <CButton
                color="info"
                size="sm"
                class="mr-1"
                @click="editLocation(item)"
            >
              <CIcon name="cil-pencil" />
            </CButton>
            <CButton
                color="danger"
                size="sm"
                @click="confirmDelete(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>

    <!-- Add/Edit Modal -->
    <CModal
        :show.sync="showModal"
        :title="modalMode === 'add' ? 'Standort hinzufügen' : 'Standort bearbeiten'"
        size="lg"
    >
      <CForm @submit.prevent="submitLocation">
        <CInput
            label="Standortname"
            v-model="locationForm.locationName"
            required
        />
        <CInput
            label="Hauptkategorie"
            v-model="locationForm.primaryCategory"
            required
        />
        <CInput
            label="Adresse"
            v-model="locationForm.address"
        />
        <CInput
            label="Telefon"
            v-model="locationForm.primaryPhone"
        />
        <CInput
            label="Store Code"
            v-model="locationForm.storeCode"
        />
        <CInput
            label="Website URL"
            v-model="locationForm.websiteUrl"
            type="url"
        />
      </CForm>

      <template #footer>
        <CButton
            color="secondary"
            @click="showModal = false"
        >
          Abbrechen
        </CButton>
        <CButton
            color="primary"
            @click="submitLocation"
            :disabled="!isFormValid"
        >
          {{ modalMode === 'add' ? 'Hinzufügen' : 'Speichern' }}
        </CButton>
      </template>
    </CModal>

    <!-- Delete Confirmation Modal -->
    <CModal
        :show.sync="showDeleteModal"
        title="Standort löschen"
        color="danger"
    >
      <p>Möchten Sie diesen Standort wirklich löschen?</p>

      <template #footer>
        <CButton
            color="secondary"
            @click="showDeleteModal = false"
        >
          Abbrechen
        </CButton>
        <CButton
            color="danger"
            @click="deleteLocation"
        >
          Löschen
        </CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LocationsList',

  data() {
    return {
      loading: false,
      showModal: false,
      showDeleteModal: false,
      modalMode: 'add',
      searchQuery: '',
      selectedLocation: null,
      locationForm: {
        locationName: '',
        primaryCategory: '',
        address: '',
        primaryPhone: '',
        storeCode: '',
        websiteUrl: ''
      },
      fields: [
        { key: 'locationName', label: 'Name', sortable: true },
        { key: 'address', label: 'Adresse', sortable: true },
        { key: 'primaryPhone', label: 'Telefon' },
        { key: 'primaryCategory', label: 'Kategorie' },
        { key: 'businessStatus', label: 'Status' },
        { key: 'actions', label: 'Aktionen', sorter: false }
      ]
    }
  },

  computed: {
    ...mapState({
      locations: state => state.googleMyBusiness.locations
    }),

    isFormValid() {
      return this.locationForm.locationName && this.locationForm.primaryCategory
    }
  },

  methods: {
    ...mapActions({
      fetchLocations: 'googleMyBusiness/fetchLocations'
    }),

    getStatusColor(status) {
      const colors = {
        'OPERATIONAL': 'success',
        'CLOSED_TEMPORARILY': 'warning',
        'CLOSED_PERMANENTLY': 'danger',
        'default': 'secondary'
      }
      return colors[status] || colors.default
    },

    showAddModal() {
      this.modalMode = 'add'
      this.locationForm = {
        locationName: '',
        primaryCategory: '',
        address: '',
        primaryPhone: '',
        storeCode: '',
        websiteUrl: ''
      }
      this.showModal = true
    },

    editLocation(location) {
      this.modalMode = 'edit'
      this.selectedLocation = location
      this.locationForm = { ...location }
      this.showModal = true
    },

    async submitLocation() {
      if (!this.isFormValid) return

      this.loading = true
      try {
        if (this.modalMode === 'add') {
          await this.$axios.post('/api/v1/google-my-business/locations', this.locationForm)
          this.$toast.success('Standort erfolgreich hinzugefügt')
        } else {
          await this.$axios.put(`/api/v1/google-my-business/locations/${this.selectedLocation.id}`, this.locationForm)
          this.$toast.success('Standort erfolgreich aktualisiert')
        }

        await this.fetchLocations()
        this.showModal = false
      } catch (error) {
        this.$toast.error('Fehler beim Speichern des Standorts')
      } finally {
        this.loading = false
      }
    },

    confirmDelete(location) {
      this.selectedLocation = location
      this.showDeleteModal = true
    },

    async deleteLocation() {
      this.loading = true
      try {
        await this.$axios.delete(`/api/v1/google-my-business/locations/${this.selectedLocation.id}`)
        await this.fetchLocations()
        this.$toast.success('Standort erfolgreich gelöscht')
        this.showDeleteModal = false
      } catch (error) {
        this.$toast.error('Fehler beim Löschen des Standorts')
      } finally {
        this.loading = false
      }
    },

    handleSearch(value) {
      // Implementieren Sie hier die Suchlogik
      // Möglicherweise mit debounce für bessere Performance
    }
  },

  created() {
    this.fetchLocations()
  }
}
</script>
