<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Neue Funnel E-Mail erstellen</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <FunnelForm
                @submit="createFunnel"
                :processing="processing"
                :enable-delete="false"
                btn-text="Speichern"
                :campaigns="campaigns"
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Platzhalter für E-Mails</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <PlaceholderManager />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import FunnelForm from "./base/FunnelForm";
import PlaceholderManager from "@/components/newsletter/PlaceholderManager";


export default {
  name: "EditFunnel",
  components: {
    FunnelForm,
    PlaceholderManager
  },
  data() {
    return {
      funnelRule: null,
      processing: false,
      campaigns: [], // Kampagnen-Daten hinzufügen
    };
  },
  mounted() {
    this.loadCampaigns(); // Kampagnen beim Laden der Komponente laden
  },
  methods: {
    async loadCampaigns() {
      try {
        const response = await axios.get("/campaigns");
        this.campaigns = response.data.map((campaign) => ({
          value: campaign.id,
          text: campaign.name,
        }));
        console.log("Geladene Kampagnen:", this.campaigns);
      } catch (error) {
        console.error("Fehler beim Laden der Kampagnen:", error);
      }
    },
    createFunnel(data) {
      this.processing = true;
      const applyToExistingSubscribers = data.applyToExistingSubscribers === true;
      axios
          .post(`/funnel/rule?applyToExistingSubscribers=${applyToExistingSubscribers}`, data)
          .then(() => {
            this.$router.push({ name: "Funnel", params: { saved: "1" } }); // Zur Funnel-Seite navigieren
            this.$toaster.makeToast(
                "Success!",
                "<b>Funnel erfolgreich erstellt!</b>"
            );
          })
          .catch((err) => {
            if (err.response) {
              this.$toaster.makeToast(
                  "Oops!",
                  `<b>${err.response.data.code}</b><br>${err.response.data.message}`
              );
            } else {
              this.$toaster.makeToast("Error!", err.message);
            }
          })
          .finally(() => {
            this.processing = false;
          });
    },
  },
};
</script>
