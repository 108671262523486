<template>
  <div class="sync-control">
    <CCard class="mb-4">
      <CCardHeader class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0">E-Mail Statistik Aktualisierung</h5>
        <div class="button-container">
          <CButton
            color="primary"
            variant="outline"
            size="sm"
            @click="refreshStatus"
            :disabled="loading"
          >
            <CIcon :content="$icons.cilSync" class="me-2" />
            Status prüfen
          </CButton>
          <CButton
            color="primary"
            size="sm"
            @click="showHistoricalSyncModal"
            :disabled="loading"
          >
            <CIcon :content="$icons.cilCloudDownload" class="me-2" />
            Ältere Daten laden
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <!-- Sync Status -->
        <div v-if="syncStatus" class="sync-status">
          <div class="row">
            <div class="col-md-6">
              <h6>Letzte Aktualisierung</h6>
              <p>{{ formatDate(syncStatus.lastSync) }}</p>
            </div>
            <div class="col-md-6">
              <h6>Zusammenfassung</h6>
              <div class="event-stats">
                <div v-for="(count, type) in getFormattedEventCounts(syncStatus.eventCounts)" :key="type" class="stat-item">
                  <span class="stat-label">{{ type }}:</span>
                  <span class="stat-value">{{ count }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import axios from 'axios';

export default {
  name: 'SyncControl',

  data() {
    return {
      loading: false,
      syncing: false,
      syncStatus: null,
      today: new Date().toISOString().split('T')[0]
    }
  },

  methods: {
    formatDate(date) {
      try {
        if (!date) return 'Keine Daten verfügbar';

        // Prüfe ob es ein gültiges Datum ist
        const dateObj = new Date(date);
        if (isNaN(dateObj.getTime())) {
          return 'Ungültiges Datum';
        }

        return format(dateObj, 'dd.MM.yyyy HH:mm:ss', { locale: de });
      } catch (error) {
        console.error('Fehler bei der Datumsformatierung:', error);
        return 'Fehler bei der Datumsanzeige';
      }
    },

    getFormattedEventCounts(eventCounts) {
      const translations = {
        CLICKED: 'Geklickt',
        OPENED: 'Geöffnet',
        FAILED: 'Fehlgeschlagen',
        SENT: 'Gesendet',
        clicked: 'Geklickt',
        accepted: 'Akzeptiert',
        delivered: 'Zugestellt',
        failed: 'Fehlgeschlagen',
        sent: 'Gesendet',
        opened: 'Geöffnet'
      };

      const formatted = {};
      for (const [key, value] of Object.entries(eventCounts)) {
        formatted[translations[key] || key] = value;
      }
      return formatted;
    },

    async refreshStatus() {
      try {
        this.loading = true;
        const response = await axios.get('/mailgun/analytics/sync/status');
        this.syncStatus = response.data;
      } catch (error) {
        console.error('Fehler beim Laden des Status:', error);
      } finally {
        this.loading = false;
      }
    },

    showHistoricalSyncModal() {
      const entities = [
        'Ab welchem Datum möchten Sie die E-Mail-Statistiken laden?',
        'Wenn Sie kein Datum auswählen, werden automatisch die letzten 30 Tage geladen.',
        `<input type="date" class="form-control mt-3" max="${this.today}" id="syncStartDate">`,
        '<small class="text-muted mt-2">Hinweis: Der Ladevorgang kann einige Minuten dauern.</small>'
      ];

      this.$modal.showModal('default', 'Ältere E-Mail-Statistiken laden', entities,
        async () => {
          try {
            this.syncing = true;
            const startDate = document.getElementById('syncStartDate').value;
            const params = startDate ? { startDateStr: startDate } : {};

            await axios.post('/mailgun/analytics/sync/historical', null, {
              params
            });

            this.$toaster.makeToast('Erfolg', 'Der Ladevorgang wurde gestartet. Die Daten werden im Hintergrund aktualisiert.');

            setTimeout(() => {
              this.refreshStatus();
            }, 2000);

          } catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
            this.$toaster.makeToast(
              'Fehler',
              'Die Daten konnten nicht geladen werden: ' + (error.response?.data?.message || error.message)
            );
          } finally {
            this.syncing = false;
          }
        },
        null,
        {
          btnCancel: 'Abbrechen',
          btnConfirm: 'Laden starten',
          btnCancelColor: 'secondary',
          btnConfirmColor: 'primary',
        }
      );
    },
  },

  mounted() {
    this.refreshStatus();
  }
};
</script>

<style scoped>
.sync-control {
  margin-bottom: 2rem;
}

.event-stats {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.stat-label {
  font-weight: 500;
}

.stat-value {
  color: #0d6efd;
}

.button-container {
  display: flex;
  gap: 16px;
}
</style>
