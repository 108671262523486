<template>
  <div class="lgc-personal-info">
    <LgcModule
      class="lgc-personal-info__module"
      @delete="$emit('delete', $event)"
      @add-below="$emit('add-below', $event)"
    >
      <!-- Header mit Edit Icon -->
      <div class="lgc-personal-info__header-wrapper">
        <div class="lgc-personal-info__header-content">
          <p
            ref="headerInput"
            class="lgc-personal-info__header"
            contenteditable="true"
            @focusout="inputEvent('dataHeader', $event)"
            @keydown.enter.prevent
            role="textbox"
            aria-label="Überschrift bearbeiten"
            v-text="dataHeader"
          />
          <button
            class="lgc-personal-info__edit-btn"
            @click="focusHeader"
            title="Überschrift bearbeiten"
          >
            <i class="cil-pencil"></i>
          </button>
        </div>
      </div>

      <form
        class="lgc-personal-info__form"
        novalidate
      >
        <!-- Formular Header mit Settings Button -->
        <div class="lgc-personal-info__form-header">
          <div class="lgc-personal-info__form-title">
            <h4>Persönliche Informationen</h4>
            <span class="lgc-personal-info__form-hint">* Pflichtfeld</span>
          </div>
          <button
            class="lgc-personal-info__configure-btn"
            @click.prevent.stop="openSettingsModal"
            title="Formularfelder konfigurieren"
          >
           <i class="cil-options"></i>
           <span>Felder anpassen</span>
          </button>
        </div>

        <div
          v-for="field in formFields"
          :key="field.name"
          class="lgc-personal-info__form-group"
        >
          <label :for="field.name" class="lgc-personal-info__label">
            {{ field.placeholder }}
          </label>
          <input
            :id="field.name"
            :type="field.type"
            :name="field.name"
            :placeholder="field.placeholder"
            :required="field.required"
            :aria-required="field.required"
            :aria-label="field.placeholder"
            :class="{
              'lgc-personal-info__input': true,
              'is-invalid': fieldErrors[field.name],
              'is-valid': formData[field.name] && !fieldErrors[field.name]
            }"
            v-model="formData[field.name]"
            @blur="validateField(field.name)"
          />
          <span
            v-if="fieldErrors[field.name]"
            class="lgc-personal-info__error"
            role="alert"
          >
            {{ fieldErrors[field.name] }}
          </span>
        </div>
      </form>

      <LgcModuleConditionsPrivacy
        inside-module
        :value="conditionsPrivacyModuleProps.value"
        @input="updateConditionsPrivacy"
      />

      <LgcModuleButton
        inside-module
        :value="buttonModuleProps.value"
        @input="updateButton"
      />
    </LgcModule>

    <CModal
      v-if="modalVisible"
      title="Formular-Einstellungen"
      :show="modalVisible"
      :closeOnBackdrop="false"
      aria-labelledby="formSettingsTitle"
    >
      <div class="lgc-personal-info__settings">
      <div class="lgc-personal-info__settings-section">
        <h5>Feldkonfiguration</h5>
        <div class="field-settings-grid">
          <div class="field-settings-header">
            <div class="field-name">Feldname</div>
            <div class="field-controls">
              <div class="control-label">Aktiv</div>
              <div class="control-label">Pflichtfeld</div>
            </div>
          </div>
          <div
            v-for="field in fieldSettings"
            :key="field.name"
            class="field-settings-row"
          >
            <div class="field-name">{{ field.label }}</div>
            <div class="field-controls">
              <Checkbox
                :name="`visible-${field.name}`"
                :options="['Aktiv']"
                @input="(value) => updateFieldVisibility(field.name, value)"
                :value="tempFieldVisibility[field.name] ? ['Aktiv'] : []"
                :horizontal="false"
              />
              <Checkbox
                :name="`required-${field.name}`"
                :options="['Pflichtfeld']"
                @input="(value) => updateFieldRequired(field.name, value)"
                :value="tempFieldRequired[field.name] ? ['Pflichtfeld'] : []"
                :horizontal="false"
                :disabled="!tempFieldVisibility[field.name]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
      <template #footer>
        <CButton
          color="secondary"
          variant="outline"
          type="button"
          class="mr-2"
          @click="cancelModal"
        >
          Abbrechen
        </CButton>
        <CButton
          color="success"
          variant="outline"
          type="button"
          @click="closeModal"
        >
          Einstellungen speichern
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import _ from 'lodash';
import LgcModule from '@/views/base/leadgenerator/lgc-modules/LgcModule';
import LgcModuleConditionsPrivacy from '@/views/base/leadgenerator/lgc-modules/LgcModuleConditionsPrivacy';
import LgcModuleButton from '@/views/base/leadgenerator/lgc-modules/LgcModuleButton';
import Checkbox from '@/components/Checkbox';

const FORM_FIELDS = {
  SALUTATION: {
    name: 'salutation',
    type: 'text',
    placeholder: 'Anrede*',
    required: true,
    visible: true
  },
  FIRST_NAME: {
    name: 'firstName',
    type: 'text',
    placeholder: 'Vorname*',
    required: true,
    visible: true
  },
  LAST_NAME: {
    name: 'lastName',
    type: 'text',
    placeholder: 'Nachname*',
    required: true,
    visible: true
  },
  EMAIL: {
    name: 'email',
    type: 'email',
    placeholder: 'E-Mail*',
    required: true,
    visible: true
  },
  PHONE: {
    name: 'phoneNumber',
    type: 'tel',
    placeholder: 'Telefonnummer',
    required: false,
    visible: true
  }
};

const ERROR_MESSAGES = {
  REQUIRED: (field) => `${field} ist erforderlich`,
  INVALID_EMAIL: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  INVALID_PHONE: 'Bitte geben Sie eine gültige Telefonnummer ein'
};

export default {
  name: 'LgcModulePersonalInfo',

  components: {
    LgcModuleButton,
    LgcModuleConditionsPrivacy,
    LgcModule,
    Checkbox,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
      validator: function(obj) {
        return typeof obj === 'object';
      }
    },
  },

  data() {
    const initializeFieldVisibility = () => {
      const visibility = {};
      Object.values(FORM_FIELDS).forEach(field => {
        visibility[field.name] = this.value?.fieldVisibility?.[field.name] ?? true;
      });
      return visibility;
    };

    const initializeFieldRequired = () => {
      const required = {};
      Object.values(FORM_FIELDS).forEach(field => {
        required[field.name] = this.value?.fieldRequired?.[field.name] ?? field.required;
      });
      return required;
    };

    // Erstelle die initialisierten Objekte
    const fieldVisibility = initializeFieldVisibility();
    const fieldRequired = initializeFieldRequired();

    return {
      modalVisible: false,
      dataHeader: this.value?.header || 'Neue Überschrift',
      formData: {
        salutation: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      },
      fieldErrors: {},
      buttonModuleProps: {
        value: {
          buttonText: this.value?.buttonText || 'Weiter',
          buttonColor: this.value?.buttonColor || '#2d78a9',
        },
      },
      conditionsPrivacyModuleProps: {
        value: {
          conditionsPrivacyText: this.value?.conditionsPrivacyText || '',
          conditionsPrivacyActive: this.value?.conditionsPrivacyActive || false,
          conditionsPrivacyTextSize: this.value?.conditionsPrivacyTextSize,
        },
      },
      dataPhoneNumberRequired: this.value?.phoneNumberRequired ?? true,
      fieldVisibility, // Hier das initialisierte Objekt verwenden
      tempFieldVisibility: JSON.parse(JSON.stringify(fieldVisibility)), // Kopie für temporäre Änderungen
      fieldRequired, // Hier das initialisierte Objekt verwenden
      tempFieldRequired: JSON.parse(JSON.stringify(fieldRequired)), // Kopie für temporäre Änderungen
      tempPhoneNumberRequired: false,
      phoneRequiredLabel: 'Telefonnummer erforderlich?'
    };
  },

  computed: {
    formFields() {
      return Object.values(FORM_FIELDS).filter(field => {
        return this.fieldVisibility[field.name] === true;
      }).map(field => {
        const basePlaceholder = field.placeholder.replace('*', ''); // Entferne vorhandene Sternchen
        return {
          ...field,
          placeholder: this.fieldRequired[field.name]
            ? `${basePlaceholder}*`  // Füge Sternchen hinzu wenn required
            : basePlaceholder
        };
      });
    },

    fieldSettings() {
      return Object.values(FORM_FIELDS).map(field => ({
        name: field.name,
        label: field.placeholder.replace('*', ''),
        visible: this.modalVisible ? this.tempFieldVisibility[field.name] : this.fieldVisibility[field.name]
      }));
    },

    computedPhoneNumberRequired() {
      return this.dataPhoneNumberRequired ? [this.phoneRequiredLabel] : [];
    },

    isFormValid() {
      return Object.keys(this.fieldErrors).length === 0;
    }
  },

  watch: {
    modalVisible(newVal) {
      if (newVal) {
        this.$emit('modal-open');
      } else {
        this.$emit('modal-close');
      }
    },
    value: {
      handler(newValue) {
        if (newValue.header !== undefined) {
          this.dataHeader = newValue.header;
        }
        if (newValue.buttonText !== undefined) {
          this.buttonModuleProps.value.buttonText = newValue.buttonText;
        }
        if (newValue.buttonColor !== undefined) {
          this.buttonModuleProps.value.buttonColor = newValue.buttonColor;
        }
        if (newValue.fieldVisibility !== undefined) {
          Object.keys(newValue.fieldVisibility).forEach(fieldName => {
            this.$set(this.fieldVisibility, fieldName, newValue.fieldVisibility[fieldName]);
          });
        }
      },
      deep: true
    },

    formData: {
      handler() {
        this.emitInput();
      },
      deep: true
    }
  },

  methods: {
    handleModalShow() {
      this.$emit('modal-open');
    },

    handleModalHide() {
      this.$emit('modal-close');
    },

    openSettingsModal(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.tempFieldVisibility = JSON.parse(JSON.stringify(this.fieldVisibility));
      this.tempFieldRequired = JSON.parse(JSON.stringify(this.fieldRequired));
      this.tempPhoneNumberRequired = this.dataPhoneNumberRequired;
      this.modalVisible = true;
    },

    updateFieldRequired(fieldName, value) {
      this.$set(this.tempFieldRequired, fieldName, value.length > 0);
    },

    closeModal() {
      this.fieldVisibility = JSON.parse(JSON.stringify(this.tempFieldVisibility));
      this.fieldRequired = JSON.parse(JSON.stringify(this.tempFieldRequired));
      this.dataPhoneNumberRequired = this.tempPhoneNumberRequired;

      this.$emit('input', {
        value: {
          ...this.value,
          phoneNumberRequired: this.dataPhoneNumberRequired,
          fieldVisibility: this.fieldVisibility,
          fieldRequired: this.fieldRequired
        }
      });

      this.modalVisible = false;
      this.$emit('modal-close');
    },

  cancelModal() {
      // Verwerfe temporäre Änderungen
      this.modalVisible = false;
      this.$emit('modal-close');
    },

    focusHeader() {
      const headerEl = this.$refs.headerInput;
      if (headerEl) {
        headerEl.focus();
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(headerEl);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },

    validateField(fieldName) {
      const field = this.formFields.find(f => f.name === fieldName);
      const value = this.formData[fieldName];

      if (this.fieldRequired[fieldName] && !value) {
        this.$set(this.fieldErrors, fieldName, ERROR_MESSAGES.REQUIRED(field.placeholder.replace('*', '')));
        return false;
      }

      if (fieldName === 'email' && value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          this.$set(this.fieldErrors, fieldName, ERROR_MESSAGES.INVALID_EMAIL);
          return false;
        }
      }

      if (fieldName === 'phoneNumber' && value && this.dataPhoneNumberRequired) {
        const phoneRegex = /^[+\d\s-()]{5,}$/;
        if (!phoneRegex.test(value)) {
          this.$set(this.fieldErrors, fieldName, ERROR_MESSAGES.INVALID_PHONE);
          return false;
        }
      }

      this.$delete(this.fieldErrors, fieldName);
      return true;
    },

    validateForm() {
      let isValid = true;
      this.formFields.forEach(field => {
        if (!this.validateField(field.name)) {
          isValid = false;
        }
      });
      return isValid;
    },

    updateFieldVisibility(fieldName, value) {
      // Aktualisiere nur die temporären Werte während das Modal offen ist
      this.$set(this.tempFieldVisibility, fieldName, value.length > 0);
    },


    inputEvent(key, e) {
      this[key] = e.target.innerText;
      this.emitInput();
    },

    updateConditionsPrivacy(event) {
      this.conditionsPrivacyModuleProps = event;
      this.emitInput();
    },

    updateButton(event) {
      this.buttonModuleProps = event;
      this.emitInput();
    },

    updatePhoneRequired(event) {
      // Nur temporäre Werte aktualisieren
      this.tempPhoneNumberRequired = Array.isArray(event) &&
        event.includes(this.phoneRequiredLabel);
    },

    emitInput() {
      this.$emit('input', {
        value: {
          header: _.trim(this.dataHeader),
          buttonText: this.buttonModuleProps.value.buttonText,
          buttonColor: this.buttonModuleProps.value.buttonColor,
          conditionsPrivacyText: this.conditionsPrivacyModuleProps.value.conditionsPrivacyText,
          conditionsPrivacyActive: this.conditionsPrivacyModuleProps.value.conditionsPrivacyActive,
          conditionsPrivacyTextSize: this.conditionsPrivacyModuleProps.value.conditionsPrivacyTextSize,
          phoneNumberRequired: this.dataPhoneNumberRequired,
          fieldVisibility: this.fieldVisibility,
          formData: this.formData,
          isValid: this.isFormValid
        },
      });
    },
  },
};
</script>
<style lang="scss">
.field-settings-grid {
  background: white;
  border-radius: 8px;
  border: 1px solid var(--border-color);

  .field-settings-header {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 1rem;
    background: #f8f9fa;
    border-bottom: 1px solid var(--border-color);
    font-weight: 500;

    .field-controls {
      display: grid;
      grid-template-columns: repeat(2, 100px);
      gap: 1rem;
      text-align: center;
    }
  }

  .field-settings-row {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: #f8f9fa;
    }

    .field-name {
      font-weight: 500;
    }

    .field-controls {
      display: grid;
      grid-template-columns: repeat(2, 100px);
      gap: 1rem;

      .checkbox-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.lgc-personal-info {
  --primary-color: #2d78a9;
  --error-color: #dc3545;
  --success-color: #28a745;
  --border-color: #dee2e6;
  --text-color: #495057;
  --bg-color: #ffffff;
  --border-radius: 6px;
  --input-padding: 12px 14px;
  --transition-duration: 0.3s;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &__module {
    background: var(--bg-color);
    border-radius: var(--border-radius);
    padding: 1.5rem;
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
  }

  &__header-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-grow: 1;
  }

  &__header {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary-color);
    transition: all var(--transition-duration);
    padding: 0.5rem;
    border-radius: var(--border-radius);
    min-width: 200px;

    &:hover {
      background-color: rgba(45, 120, 169, 0.02);
    }

    &:focus {
      outline: none;
      background-color: rgba(45, 120, 169, 0.05);
      box-shadow: 0 0 0 2px rgba(45, 120, 169, 0.1);
    }
  }
  &__edit-btn {
    background: transparent;
    border: none;
    color: var(--primary-color);
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all var(--transition-duration);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(45, 120, 169, 0.1);
    }

    i {
      font-size: 1rem;
    }
  }

  &__header-content:hover &__edit-btn {
    opacity: 1;
  }

  &__settings-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: all var(--transition-duration);

    &:hover {
      background-color: var(--primary-color);
      color: white;
    }

    i {
      font-size: 1.25rem;
    }
  }

  &__settings-text {
    font-size: 0.9rem;
    font-weight: 500;
  }

  &__form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__form-title {
    h4 {
      margin: 0;
      color: var(--text-color);
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  &__configure-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: transparent;
    border: none;
    color: var(--primary-color);
    padding: 0.5rem 0.75rem;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: all var(--transition-duration);
    font-size: 0.9rem;

    &:hover {
      background-color: rgba(45, 120, 169, 0.1);
    }

    i {
      font-size: 1rem;
    }
  }

  &__settings-btn {
    background: transparent;
    border: none;
    color: var(--primary-color);
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all var(--transition-duration);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    &:hover {
      background-color: rgba(45, 120, 169, 0.1);
      transform: rotate(15deg);
    }

    i {
      font-size: 1.25rem;
    }
  }

  &__form {
    background: var(--bg-color);
    border-radius: var(--border-radius);
    margin-bottom: 2rem;
  }

  &__form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h4 {
      margin: 0;
      color: var(--text-color);
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  &__form-hint {
    font-size: 0.875rem;
    color: var(--error-color);
  }

  &__form-group {
    position: relative;
    margin-bottom: 1.75rem;
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: var(--text-color);
    font-weight: 500;
  }

  &__input {
    width: 100%;
    padding: var(--input-padding);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: 1rem;
    color: var(--text-color);
    transition: all var(--transition-duration);
    background-color: var(--bg-color);

    &:focus {
      outline: none;
      border-color: var(--primary-color);
      box-shadow: 0 0 0 3px rgba(45, 120, 169, 0.1);
    }

    &.is-invalid {
      border-color: var(--error-color);
      padding-right: 2.5rem;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1rem;

      &:focus {
        box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.1);
      }
    }

    &.is-valid {
      border-color: var(--success-color);
      padding-right: 2.5rem;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1rem;
    }

    &::placeholder {
      color: #adb5bd;
    }
  }

  &__error {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 0.75rem;
    color: var(--error-color);
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &::before {
      content: "⚠";
      font-size: 0.875rem;
    }
  }

  &__settings {
    padding: 1rem;

    &-section {
      h5 {
        margin-bottom: 1rem;
        color: var(--text-color);
        font-size: 1rem;
      }
    }
  }

  .lgc-module-inside.lgc-module-conditions-privacy {
    margin-top: 2rem;
    padding: 1rem;
    background-color: rgba(45, 120, 169, 0.03);
    border-radius: var(--border-radius);

    p {
      margin: 0.5rem 0;
      font-size: 0.875rem;
      line-height: 1.6;
      color: var(--text-color);
    }
  }

  // Responsive Design
  @media (max-width: 768px) {
    &__module {
      padding: 1rem;
    }

    &__header {
      font-size: 1.25rem;
    }

    &__input {
      font-size: 0.875rem;
      padding: 10px 12px;
    }
  }

  &__settings {
    padding: 1rem;

    &-section {
      & + & {
        margin-top: 2rem;
      }

      h5 {
        margin-bottom: 1rem;
        color: var(--text-color);
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  &__field-setting {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: var(--border-radius);
    background-color: rgba(0, 0, 0, 0.02);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
}
}
</style>
