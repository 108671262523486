<template>
  <div class="page-manager">
    <draggable
        v-model="localList"
        :animation="200"
        @end="handleDragEnd"
        class="list-group"
    >
      <div
          v-for="(element, index) in computedList"
          :key="element.order"
          class="list-group-item"
          :class="{ 'is-dragging': isDragging }"
      >
        <div class="order-badge">
          {{ element.order }}
        </div>
        <div
            :class="['page-name', { 'is-active': activePage === index }]"
            contenteditable="true"
            @input="sanitizeInput($event)"
            @keydown.enter.prevent
            @focusout="updatePageName(index, $event)"
            @click="$emit('pageClick', index)"
        >
          {{ element.name }}
        </div>
        <div class="item-actions">
          <button
              class="action-button delete"
              @click="$emit('deleteCurrentPage', index)"
              title="Löschen"
          >
            <CIcon name="cil-trash" />
          </button>
        </div>
      </div>
    </draggable>
    <div class="actions-panel">
      <CButton
          @click="$emit('newPage')"
          class="primary-button"
          color="primary"
      >
        <CIcon name="cil-plus" class="button-icon" />
        Neuer Schritt
      </CButton>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'LeadgeneratorOrderDraggable',

  components: {
    draggable
  },

  props: {
    list: {
      type: Array,
      required: true
    },
    activePage: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      localList: [...this.list],
      isDragging: false
    }
  },

  computed: {
    computedList() {
      return this.localList.map((name, index) => ({
        name,
        order: index + 1
      }))
    }
  },

  watch: {
    list: {
      handler(newList) {
        this.localList = [...newList]
      },
      deep: true
    }
  },

  methods: {
    sanitizeInput(event) {
      const target = event.target
      const text = target.innerText.replace(/\n/g, '')
      const sanitizedText = text.replace(/<\/?[^>]+(>|$)/g, '')

      const selection = window.getSelection()
      const range = document.createRange()
      const cursorPosition = selection.getRangeAt(0).startOffset

      target.innerText = sanitizedText

      if (target.childNodes.length > 0) {
        range.setStart(target.childNodes[0], Math.min(cursorPosition, sanitizedText.length))
        range.setEnd(target.childNodes[0], Math.min(cursorPosition, sanitizedText.length))
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },

    updatePageName(index, event) {
      const newName = event.target.innerText.trim()
      this.localList[index] = newName
      this.$emit('changePageName', index, newName)
    },

    handleDragEnd(event) {
      if (event.moved) {
        this.$emit('pageDragMouseUp', this.localList)
      }
      this.isDragging = false
    }
  }
}
</script>

<style scoped>
.page-manager {
  --primary-color: #05b9ae;
  --hover-color: #048f86;
  --background-color: #f8f9fa;
  --item-background: #ffffff;
  --text-color: #2c3e50;
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.05);
  --shadow-md: 0 4px 6px rgba(0,0,0,0.1);
  --border-radius: 8px;

  padding: 1rem;
}

.drag-container {
  background: var(--background-color);
  border-radius: var(--border-radius);
  padding: 1rem;
  min-height: 100px;
}

.list-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.list-group-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: var(--item-background);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  transition: all 0.2s ease;
  gap: 1rem; /* Abstand zwischen den Elementen */
}

.list-group-item:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.order-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem; /* Größe reduzieren, um nicht zu dominant zu wirken */
  height: 1.5rem;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  font-weight: 600;
  cursor: grab;
  margin-right: 0.5rem; /* Moderater Abstand zum Text */
  flex-shrink: 0; /* Badge wird nicht kleiner */
  font-size: 1rem; /* Schriftgröße anpassen */
}


.page-name {
  flex: 4; /* Der Textbereich soll mehr Platz einnehmen */
  margin: 0;
  border-radius: 4px;
  transition: all 0.2s ease;
  min-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis; /* Bei zu langem Text werden Ellipsen angezeigt */
  white-space: nowrap;
  color: #2c3e50;
  font-size: 1rem; /* Schriftgröße für Lesbarkeit */
}

.page-name:focus {
  outline: 2px solid var(--primary-color);
  background: rgba(5, 185, 174, 0.05);
}

.is-active {
  color: var(--primary-color);
  font-weight: 600;
}

.item-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Abstand zwischen den Symbolen */
  flex-shrink: 0; /* Verhindert, dass die Buttons kleiner werden */
}

.action-button {
  padding: 0.25rem; /* Weniger Padding, um Platz zu sparen */
  border: none;
  background: transparent;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem; /* Kleinere Schriftgröße für Icons */
}

.action-button:hover {
  color: var(--primary-color);
  background: rgba(5, 185, 174, 0.1);
}

.actions-panel {
  margin-top: 1rem;
  display: flex;
  gap: 0.75rem;
}

.primary-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  font-weight: 600;
}

.button-icon {
  margin-right: 0.5rem;
}

/* Animations */
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-active {
  position: absolute;
}

@media (max-width: 640px) {
  .list-group-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-badge {
    margin-bottom: 0.5rem;
  }

  .item-actions {
    margin-top: 0.5rem;
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
