<template>
  <CModal
    title="Facebook / Instagram mit Leadmeup verbinden"
    :show="showModal"
    @update:show="$emit('update:showModal', $event)"
    style="text-align: left"
  >
    <strong>Schritt 1: Einloggen</strong>
    <v-facebook-login
      style="margin-top: 4px; cursor: pointer"
      :app-id="facebookAppId"
      version="v18.0"
      sdk-locale="en_US"
      :login-options="loginOptions"
      @sdk-init="onSdkInit"
      @login="onLogin"
      @logout="onLogout"
    >
      <template #login> Mit Facebook verbinden </template>
    </v-facebook-login>

    <br />
    <strong>Schritt 2: Seite auswählen</strong>
    <p>Hier müssen Sie eine Facebook-Business Seite auswählen</p>
    <div class="alert alert-info" role="alert">
      <strong>Hinweis:</strong> Um Instagram Business zu nutzen, muss Ihre Facebook-Seite mit einem Instagram Business Account verknüpft sein.
    </div>
    <CSelect
      v-if="userIsLoggedIn"
      label=""
      :value="selectedFacebookPageId"
      @update:value="onPageSelect($event)"
      :disabled="!userIsLoggedIn || pageOptions === null"
      :options="pageOptions || [{ label: '-- Bitte erst einloggen --', value: 0 }]"
      description="Sie benötigen Admin-Rechte, damit Sie Ihre Facebook-Seite mit unserem LeadMeUp verbinden können"
    />
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="cancelModal">
        Abbrechen
      </button>
      <button
        v-if="showDisconnectButton"
        type="button"
        class="btn btn-danger"
        @click="deleteExtraStep"
      >
        Entkoppeln
      </button>
      <button
        type="button"
        class="btn btn-success"
        @click="confirmModal"
        :disabled="isLoading || !userIsLoggedIn || !selectedFacebookPageId"
      >
        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2"></span>
        {{ isLoading ? 'Verbinde...' : 'Mit Facebook verbinden!' }}
      </button>
    </template>
  </CModal>
</template>

<script>
import VFacebookLogin from "vue-facebook-login-component"
import axios from "axios"
import _ from "lodash"

export default {
  name: "FacebookModal",

  components: {
    VFacebookLogin
  },

  props: {
    showModal: Boolean
  },

  data() {
    return {
      isLoading: false,
      loginOptions: {
        scope: "pages_show_list,pages_read_engagement,pages_manage_posts,public_profile,email,instagram_basic,instagram_content_publish",
        config_id: process.env.VUE_APP_FACEBOOK_CONFIG_ID,
      },
      fbSdk: null,
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
      showDisconnectButton: false,
      userIsLoggedIn: false,
      facebookAccounts: null,
      pageOptions: null,
      selectedFacebookPageId: null
    }
  },

  methods: {
    cancelModal() {
      this.$emit('update:showModal', false)
    },

    async confirmModal() {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const found = _.find(this.pageOptions, {
          value: this.selectedFacebookPageId
        });

        if (!found) {
          throw new Error("Keine Facebook-Seite ausgewählt");
        }

        // Facebook verbinden
        await axios.post("/socialMedia/connect", {
          socialMedia: "FACEBOOK",
          accessToken: found.access_token,
          name: found.label,
          pageId: found.value
        });

        // Wenn Instagram Business Account vorhanden ist, diesen auch verbinden
        if (found.instagram_business_account) {
          await axios.post("/socialMedia/connect", {
            socialMedia: "INSTAGRAM",
            accessToken: found.access_token,
            name: found.instagram_business_account.username,
            pageId: found.instagram_business_account.id
          });
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Facebook und Instagram Business wurden erfolgreich verbunden!</b>"
          );
        } else {
          this.$toaster.makeToast(
            "Erfolgreich!",
            "<b>Facebook wurde erfolgreich verbunden! Hinweis: Kein Instagram Business Account gefunden.</b>"
          );
        }

        this.$emit("update:showModal", false);
        this.$router.go();
      } catch (err) {
        console.error("Fehler beim Verbinden:", err);
        this.$toaster.makeToast(
          "Fehler!",
          err.response?.data?.message || err.message
        );
      } finally {
        this.isLoading = false;
      }
    },

    onSdkInit(sdk) {
      this.fbSdk = sdk
      this.$emit("sdk-init", sdk)
      sdk.FB.getLoginStatus((response) => {
        console.log("fb login status:", response)
        if (response.status === "connected") {
          this.userIsLoggedIn = true
        }
      })
    },

    onLogin() {
      this.userIsLoggedIn = true;
      const FB = this.fbSdk.FB;

      new Promise((resolve, reject) => {
        let cachedAccounts = this.getCachedPageOptions();
        if (cachedAccounts === null) {
          const access_token = FB.getAuthResponse()["accessToken"];
          console.log("Access Token = " + access_token);

          FB.api(
            "/me/accounts",
            { fields: "access_token,name,id,instagram_business_account" },
            (response) => {
              console.log("Accounts:", response);
              if (!response || response.error) {
                reject(response?.error || new Error('Facebook API Fehler'));
                return;
              }

              cachedAccounts = response.data.map((p) => ({
                label: p.name + (p.instagram_business_account ? ' (mit Instagram)' : ''),
                value: p.id,
                access_token: p.access_token,
                has_instagram: !!p.instagram_business_account,
                instagram_business_account: p.instagram_business_account
              }));

              this.setCachedPageOptions(cachedAccounts);
              resolve(cachedAccounts);
            }
          );
        } else {
          console.log("facebook pageOptions loaded from cache:", cachedAccounts);
          resolve(cachedAccounts);
        }
      })
        .then((cachedAccounts) => {
          this.pageOptions = cachedAccounts;
          if (cachedAccounts?.length) {
            this.selectedFacebookPageId = cachedAccounts[0].value;
          }
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Facebook-Seiten:", error);
          this.$toaster.makeToast(
            "Fehler",
            "Facebook-Seiten konnten nicht geladen werden: " + error.message
          );
        });
    },

    onLogout() {
      this.userIsLoggedIn = false
      this.pageOptions = null
      this.removeCachedPageOptions()
      this.$emit("logout")
    },

    onPageSelect(page) {
      this.selectedFacebookPageId = page
    },

    getCachedPageOptions() {
      const pageOptions = localStorage.getItem("social_connections_facebook_pageOptions")
      if (pageOptions) {
        const timeWhenSet = localStorage.getItem("social_connections_facebook_pageOptions__time")
        const maxDiffMillis = 1000 * 60 * 90 // 90 min
        const expireAt = parseInt(timeWhenSet) + maxDiffMillis
        const expired = expireAt < Date.now()
        return expired ? null : JSON.parse(pageOptions)
      }
      return null
    },

    setCachedPageOptions(pageOptions) {
      localStorage.setItem(
        "social_connections_facebook_pageOptions",
        JSON.stringify(pageOptions)
      )
      localStorage.setItem(
        "social_connections_facebook_pageOptions__time",
        Date.now().toString()
      )
    },

    removeCachedPageOptions() {
      localStorage.removeItem("social_connections_facebook_pageOptions")
      localStorage.removeItem("social_connections_facebook_pageOptions__time")
    }
  }
}
</script>

<style scoped>
.spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.15em;
}
</style>
