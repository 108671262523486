<template>
  <div class="email-data-table">
    <div v-if="loading" class="table-loader">
      <CSpinner />
    </div>
    <CDataTable :items="items" :fields="fields" hover striped responsive />
  </div>
</template>

<script>
import { CDataTable, CSpinner } from '@coreui/vue'

export default {
  components: {
    CDataTable,
    CSpinner
  },
  name: 'EmailDataTable',

  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      fields: [
        { key: 'date', label: 'Datum', sortable: true },
        { key: 'browser', label: 'Browser', sortable: true },
        { key: 'os', label: 'Betriebssystem', sortable: true },
        { key: 'device', label: 'Gerät', sortable: true },
        { key: 'country', label: 'Land', sortable: true },
        { key: 'city', label: 'Stadt', sortable: true },
        { key: 'language', label: 'Sprache', sortable: true },
        { key: 'ip', label: 'IP', sortable: true },
        { key: 'utm', label: 'UTM-Parameter', sortable: true }
      ]
    }
  }
}
</script>

<style scoped>
.email-data-table {
  position: relative;
}
.table-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>