<template>
  <div class="social-media-automation">
    <CCard>
      <CCardHeader>
        <h1 class="mb-0">Social Media Automatisierung</h1>
      </CCardHeader>
      <CCardBody>
        <CProgress v-if="isLoading" :value="progress" class="mb-3" />
        <transition name="fade" mode="out-in">
          <!-- ProfileSetup anzeigen, wenn Interview unvollständig -->
          <ProfileSetup
            v-if="!isInterviewComplete && !showDashboard"
            @profile-saved="handleProfileSaved"
          />
          <!-- Dashboard anzeigen, wenn Interview abgeschlossen oder Posts generiert wurden -->
          <Dashboard
            v-else
            :posts="generatedPosts"
            @approve-post="approvePost"
            @reject-post="rejectPost"
            @generate-alternative="generateAlternative"
            @save-post="saveEditedPost"
            @restart-form="restartForm"
          />
        </transition>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Dashboard from "./Dashboard.vue";
import ProfileSetup from './ProfileSetup.vue'
import axios from "axios";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CProgress,
  CIcon
} from '@coreui/vue'

export default {
  components: {
    ProfileSetup,
    Dashboard,
    CCard,
    CCardHeader,
    CCardBody,
    CProgress,
    CIcon
  },
  data() {
    return {
      showDashboard: false,
      generatedPosts: [],
      isLoading: false,
      progress: 0,
      isInterviewComplete: false,
      realmId: null,
      initialData: {
        companyData: {
          name: "",
          industry: "",
          size: "",
          targetAudience: "",
          description: "",
        },
        contentPreferences: {
          tone: "",
          topics: "",
          keywords: [],
          avoidTopics: "",
          useEmojis: false,
        },
        schedule: {
          days: [],
          frequency: "",
        },
      },
      isGenerating: false,
    };
  },
  methods: {
    async getBestTimeToPost(content) {
      try {
        const response = await axios.post("/socialMedia/bestTimeToPost", {
          content,
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching best time to post:", error);
        return null;
      }
    },
    async getSuggestedImage(content) {
      try {
        const response = await axios.post("/socialMedia/suggestImage", {
          content,
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching suggested image:", error);
        return null;
      }
    },
    async checkInterviewCompletion() {
      try {
        const response = await axios.get(`/socialMedia/profile/completed`, {
          params: { realmId: this.realmId },
        });
        this.isInterviewComplete = response.data;
        this.showDashboard = this.isInterviewComplete;
      } catch (error) {
        console.error("Fehler beim Prüfen des Interviewstatus:", error);
      }
    },
    // Neue Methode für das Handling des gespeicherten Profils
    async handleProfileSaved() {
      this.isInterviewComplete = true;
      this.showDashboard = true;
      await this.fetchPosts();
    },
    async approvePost(id) {
      try {
        const post = this.generatedPosts.find((p) => p.id === id);
        if (post && !post.publishAt) {
          post.publishAt = new Date().toISOString();
        }
        await axios.post(`/socialMedia/approvePost/${id}`);
        post.approved = true;
        this.$toast.success("Beitrag erfolgreich genehmigt");
      } catch (error) {
        console.error("Error approving post:", error);
        this.$toast.error(
          "Es gab einen Fehler bei der Genehmigung des Posts. Bitte versuchen Sie es später erneut.",
        );
      }
    },
    async rejectPost(id) {
      try {
        await axios.delete(`/socialMedia/posts/${id}`);
        this.generatedPosts = this.generatedPosts.filter(
          (post) => post.id !== id,
        );
        this.$toast.success("Beitrag erfolgreich abgelehnt");
      } catch (error) {
        console.error("Error rejecting post:", error);
        this.$toast.error(
          "Es gab einen Fehler beim Ablehnen des Posts. Bitte versuchen Sie es später erneut.",
        );
      }
    },
    async generateAlternative(post) {
      try {
        this.isGenerating = true;
        const requestData = {
          ...this.initialData.contentPreferences,
          mainContent: post.content,
          generateAlternative: true,
          keywords: this.initialData.contentPreferences.keywords,
        };
        if (requestData.keywords.length < 2) {
          throw new Error("Not enough keywords provided. Min 2 keywords");
        }
        const response = await axios.post(
          "/socialMedia/generateByAi",
          requestData,
        );
        const newPost = {
          ...post,
          id: Date.now(),
          content: response.data.result,
        };
        this.generatedPosts.push(newPost);
        this.$toast.success("Alternative Version generiert");
      } catch (error) {
        console.error("Error generating alternative:", error);
        this.$toast.error(
          "Es gab einen Fehler bei der Generierung der Alternative. Bitte versuchen Sie es später erneut.",
        );
      } finally {
        this.isGenerating = false;
      }
    },
    async saveEditedPost(post) {
      try {
        if (!post.publishAt) {
          post.publishAt = new Date().toISOString();
        }
        const response = await axios.put(`/socialMedia/posts/${post.id}`, post);
        const index = this.generatedPosts.findIndex((p) => p.id === post.id);
        if (index !== -1) {
          this.generatedPosts.splice(index, 1, response.data);
        }
        this.$toast.success("Beitrag erfolgreich gespeichert");
      } catch (error) {
        console.error("Error saving post:", error);
        this.$toast.error(
          "Es gab einen Fehler beim Speichern des Posts. Bitte versuchen Sie es später erneut.",
        );
      }
    },
    restartForm() {
      this.showDashboard = false;
      this.initialData = {
        companyData: {
          name: "",
          industry: "",
          size: "",
          targetAudience: "",
          description: "",
        },
        contentPreferences: {
          tone: "",
          topics: "",
          keywords: [],
          avoidTopics: "",
          useEmojis: false,
        },
        schedule: {
          days: [],
          frequency: "",
        },
      };
    },
    async fetchPosts() {
      try {
        const response = await axios.get("/socialMedia/posts");
        this.generatedPosts = response.data.map((post) => ({
          ...post,
          publishAt: post.publishAt || new Date().toISOString(),
        }));
        this.showDashboard = this.generatedPosts.length > 0;
      } catch (error) {
        console.error("Error fetching posts:", error);
        this.$toast.error(
          "Es gab einen Fehler beim Laden der Beiträge. Bitte versuchen Sie es später erneut.",
        );
      }
    },
  },
  async mounted() {
    try {
      const realmResponse = await axios.get("/realm/current");
      const realmId = realmResponse.data.id;

      if (realmId) {
        this.realmId = realmId;
        console.log("Erhaltene realmId:", this.realmId);
        await this.checkInterviewCompletion();
      }

      await this.fetchPosts();
    } catch (error) {
      console.error("Fehler beim Laden der aktuellen Realm-ID:", error);
    }
  },
};
</script>
