<template>
  <div>
    <h2>
      Willkommen in der Marketing Cloud
      {{ this.$commons.getFromStorage("currentUser").firstName }}
      {{ this.$commons.getFromStorage("currentUser").lastName }}!
    </h2>
    <hr />

    <CRow>
      <CCol lg="5" class="py-3" id="dash-tab">
        <CTabs variant="pills" :active-tab="0">
          <CTab title="Ihr persönlicher Erklärfilm">
            <br />
            <div style="text-align: justify">
              Eigentümer müssen die wichtigsten
              <strong>Fakten und Informationen</strong> zu deinem Unternehmen
              bereits in der ersten Minute erfahren. Mit einem
              <strong>Erklärfilm</strong>, der ohne Umwege direkt zur Sache
              kommt und dafür sorgt, dass Immobilieneigentümer verstehen, wie
              der Ablauf der Zusammenarbeit ist! Wir machen die Botschaft
              verständlich.
            </div>
          </CTab>
          <CTab title="Beispiel">
            <br />
            <CEmbed
              tag="p"
              aspect="16by9"
              src="https://www.youtube.com/embed/5DZ61ChXl6Y"
              frameborder="0"
              allow="accelerometer;autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </CTab>
          <CTab title="Bestellen">
            <br />
            <div style="text-align: justify">
              Bestelle ein auf dein Maklerbüro
              <strong>angepassten</strong> Erklärfilm. Die Lieferung erfolgt
              innerhalb von <strong>24 Stunden</strong>
            </div>
            <br />
            <div style="text-align: center">
              <CButton
                color="primary"
                href="https://selfservice.billwerk.com/portal/signup.html#/608bc135436322e8bf5a3fe7"
                target="_blank"
                >Jetzt Bestellen
              </CButton>
            </div>
          </CTab>
        </CTabs>
      </CCol>

      <CCol lg="5" class="py-3">
        <CTabs variant="pills" :active-tab="0">
          <CTab title="Landingpageüberprüfung">
            <br />
            <div style="text-align: justify">
              <strong>Gute Nachrichten!</strong> Jetzt kannst du deine
              <strong>Landingpage</strong> von einem Marketingexperten
              überprüfen lassen. Du erhältst von unseren Experten
              <strong>Empfehlungen</strong>, was du besser machen kannst. Unsere
              Experten haben unzählige Landingpages erstellt und optimiert, wir
              geben dir praktische Ratschläge, nicht nur Theorie.
            </div>
          </CTab>
          <CTab title="Umfang">
            <br />

            <ul class="feature">
              <li class="item">
                Du erhältst ein Video, das deine
                <strong>Landingpage</strong> analysiert.
              </li>
              <li class="item">
                Du erhältst <strong>spezifische</strong> Ratschläge!
              </li>
              <li class="item">
                Das Video kannst du <strong>herunterladen</strong> und mit
                deinen Kollegen und Geschäftspartnern teilen.
              </li>
            </ul>
          </CTab>
          <CTab title="Bestellen">
            <br />
            <div style="text-align: justify">
              Bestelle jetzt eine
              <strong>individuelle Überprüfung</strong> deiner Landingpage. Die
              Überprüfung wird innerhalb von 2-3 Werktagen geliefert.
            </div>
            <br />
            <div style="text-align: center">
              <CButton
                color="primary"
                href="https://selfservice.billwerk.com/portal/signup.html#/608d26b48f7bc431a24106d0"
                target="_blank"
                >Jetzt Bestellen
              </CButton>
            </div>
          </CTab>
        </CTabs>
      </CCol>
    </CRow>
    <hr />
    <div data-canny />
    <!--<CRow>
  <CCol lg="10" class="py-3">
    <CJumbotron>
    <h1 class="display-10">Austausch zu Marketing-Strategien</h1>
    <p class="lead">Willst du lernen, was nötig ist, um mehr Leads zu generieren?
    <br>
    Dann bist du in unserer offiziellen Facebook Gruppe "Immobilienakquise - Austausch zu Marketing-Strategien" am RICHTIGEN ORT!</p>
    <CButton color="primary" href="https://www.facebook.com/groups/734865423644373" target="_blank">Jetzt der Gruppe beitreten</CButton>
  </CJumbotron>
      </CCol>
    </CRow>-->
    <hr />
    <WidgetsDropdown />
  </div>
</template>

<script>
import WidgetsDropdown from "./widgets/WidgetsDropdown";

export default {
  name: "Dashboard",
  components: {
    WidgetsDropdown,
  },
  mounted() {
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          (e.type = "text/javascript"),
            (e.async = !0),
            (e.src = "https://canny.io/sdk.js"),
            f.parentNode.insertBefore(e, f);
        }
      }

      if ("function" != typeof w.Canny) {
        var c = function () {
          c.q.push(arguments);
        };
        (c.q = []),
          (w.Canny = c),
          "complete" === d.readyState
            ? l()
            : w.attachEvent
              ? w.attachEvent("onload", l)
              : w.addEventListener("load", l, !1);
      }
    })(window, document, "canny-jssdk", "script");
    Canny("render", {
      boardToken: "f49b0f96-d41d-bab2-e0ef-f4152f12dcdc",
      basePath: null,
      ssoToken: null,
    });
  },
  methods: {
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },
};
</script>

<style>
div#dash-tab {
  border-right: 1px solid rgba(0, 0, 21, 0.2);
}
</style>
