<template>
  <div
    v-bind:id="id"
    class="unlayer-editor"
    v-bind:style="{ minHeight: minHeight, height: height }"
  ></div>
</template>

<script>
import { unlayerEditorLoadScript } from "./unlayerEditorLoadScript";

let lastEditorId = 0;

export default {
  name: "UnlayerEditor",
  props: {
    editorId: String,
    options: Object,
    projectId: Number,
    tools: Object,
    appearance: Object,
    locale: String,
    minHeight: {
      type: String,
      default: "500px",
    },
    height: {
      type: String,
      default: "500px",
    },
    displayMode: {
      type: String,
      default: "web",
    },
    value: [String, Object],
  },
  computed: {
    id() {
      return this.editorId || `editor-${++lastEditorId}`;
    },
  },
  mounted() {
    unlayerEditorLoadScript(this.loadEditor.bind(this));
  },
  methods: {
    loadEditor() {
      const options = this.options || {};

      if (this.projectId) {
        options.projectId = this.projectId;
      }

      if (this.tools) {
        options.tools = this.tools;
      }

      if (this.appearance) {
        options.appearance = this.appearance;
      }

      if (this.locale) {
        options.locale = this.locale;
      }

      /* global unlayer */
      this.editor = unlayer.createEditor({
        ...options,
        id: this.id,
        displayMode: this.displayMode,
        source: {
          name: "vue-email-editor",
          version: "0.8.0",
        },
      });

      this.$emit("load");

      this.editor.addEventListener("editor:ready", () => {
        this.$emit("ready");
      });
    },
    loadDesign(design) {
      this.editor.loadDesign(design);
    },
    saveDesign(callback) {
      this.editor.saveDesign(callback);
    },
    exportHtml(callback) {
      this.editor.exportHtml(callback);
    },
  },
};
</script>

<style scoped>
.unlayer-editor {
  flex: 1;
  display: flex;
}
</style>
