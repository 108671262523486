<template>
  <div>
    <CInputFile
      :label="label"
      :placeholder="placeholder"
      @change="uploadFile"
      :multiple="false"
      class="file-input-field"
      accept="*/*"
      horizontal
      custom
    />
    <CRow v-if="showControls">
      <CCol sm="3" />
      <CCol sm="9">
        <div class="controls-wrapper">
          <CButton type="button" color="danger" @click="deleteFile"
            >Delete</CButton
          >
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FileUploadField",
  props: {
    label: String,
    settingKey: String,
    value: String,
    maxFileSizeInMB: {
      type: Number,
      default: 4,
    },
  },
  mounted() {
    if (this.value) {
      this.fileUrl = this.value;
      this.placeholder = this.value.split("/").pop();
      this.showControls = true;
    }
  },
  data() {
    return {
      placeholder: "Datei auswählen...",
      fileUrl: "",
      showControls: false,
      selectedFile: null,
    };
  },
  methods: {
    uploadFile($event) {
      if ($event[0]) {
        const file = $event[0];
        this.selectedFile = $event[0];

        if (file.size > this.maxFileSizeInMB * 1024 * 1024) {
          // Mega-Byte calc
          this.$toaster.makeToast(
            "Fehler!",
            `Die Dateigröße darf ${this.maxFileSizeInMB}MB nicht übersteigen.`,
          );
          return;
        }

        const data = new FormData();
        data.append("file", file, file.name);
        data.append("saveAsSetting", this.settingKey); // saves this file's public url as a realm setting with this key
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        };
        axios
          .post("/file/upload", data, options)
          .then((response) => {
            this.$toaster.makeToast(
              "Erfolgreich!",
              "Datei wurde erfolgreich hochgeladen!",
            );
            this.fileUrl = response.data.fileDownloadUrl;
            this.$emit("input", response.data.fileDownloadUrl);
            this.placeholder = this.fileUrl.split("/").pop();
            this.showControls = true;
          })
          .catch((err) => {
            this.$toaster.makeToast(
              "Fehler!",
              "Das Datei konnte nicht hochgeladen werden: " + err.toString(),
            );
          });
      }
    },
    deleteFile() {
      const fileUrl = this.fileUrl;
      const split = fileUrl.split("/");
      const fileName = split.pop();
      const cloudFileId = split.pop();
      const realmUniqueHash = split.pop();
      axios
        .delete(
          `/file/delete/${realmUniqueHash}/${cloudFileId}/${fileName}?deleteSetting=${this.settingKey}`,
        )
        .then((response) => {
          this.$toaster.makeToast("Erfolgreich!", "Datei wurde gelöscht!");
          this.fileUrl = "";
          this.placeholder = "Datei auswählen...";
          this.showControls = false;
          this.$emit("input", "");
        })
        .catch((err) => {
          this.$toaster.makeToast(
            "Fehler!",
            "Datei konnte nicht gelöscht werden: " + err.toString(),
          );
        });
      this.$emit("delete", { settingKey: this.settingKey, fileUrl });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-input-field {
  margin: 0 0 8px 0;
}

.controls-wrapper {
  display: inline-block;

  button {
    margin-left: -8px;
  }
}
</style>
