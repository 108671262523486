<template>
  <CChartPie
    :datasets="chartData.datasets"
    :labels="chartData.labels"
    :options="options"
  />
</template>

<script>
import { CChartPie } from '@coreui/vue-chartjs'

export default {
  name: 'PieChart',
  components: {
    CChartPie
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
