<template>
  <CCard class="contact-import">
    <CCardHeader>
      <slot name="header">
        <b>Kontakte Importieren</b>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CCol md="12" v-if="!showTable" class="pl-0 pr-2">
        <vue-csv-import v-model="csv" :map-fields="mapFields">
          <template slot="hasHeaders" slot-scope="{ headers, toggle }">
            <label style="margin-left: -20px">
              <input
                type="checkbox"
                id="hasHeaders"
                :value="headers"
                @change="toggle"
              />
              Die Datei hat eine Kopfzeile
            </label>
          </template>

          <template slot="error"> Der Dateityp ist ungültig </template>

          <template slot="thead">
            <tr>
              <th>Spaltenbeschriftungen den Kontaktinformationen zuordnen</th>
              <th>Spalten in deiner Importdatei</th>
            </tr>
          </template>

          <template slot="next" slot-scope="{ load }">
            <CButton color="success" class="mt-5" @click.prevent="load"
              >Datei hochladen</CButton
            >
          </template>
        </vue-csv-import>
      </CCol>

      <CCol md="12" v-if="showTable">
        <CDataTable
          :items="csv"
          :fields="getTableFields()"
          hover
          striped
          bordered
          fixed
          column-filter
          table-filter
          items-per-page-select
          sorter
          pagination
          :loading="loading"
        >
          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click.prevent="showEditModal(index, item)"
              >
                Editieren
              </CButton>
            </td>
          </template>
          <template #delete_item="{ item, index }">
            <td class="py-2">
              <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                @click.prevent="deleteRecord(index)"
              >
                Löschen
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCol>

      <CCol
        md="12"
        v-if="visibleActionButton && !showTable"
        class="text-center"
      >
        <CButton color="primary" @click.prevent="showTable = true">
          Weiter
        </CButton>
      </CCol>

      <CCol md="12" v-if="visibleActionButton && showTable" class="text-center">
        <CButton color="primary" @click.prevent="back()"> Zurück </CButton>
        &nbsp;&nbsp;&nbsp;
        <CButton color="primary" @click.prevent="submit()">
          Import abschließen
        </CButton>
      </CCol>

      <CCol md="12">
        <CForm @submit.prevent="update">
          <CModal
            title="Kontakt hinzufügen"
            size="sm"
            :show.sync="visibleModal"
            :closeOnBackdrop="false"
          >
            <CRow>
              <CCol sm="12">
                <label for="name">Name</label>
                <CInput
                  id="name"
                  required="true"
                  v-model="selectedItem && selectedItem['Name']"
                />
              </CCol>

              <CCol sm="12">
                <label>E-Mail Adresse</label>
                <CInput
                  type="email"
                  required
                  v-model="selectedItem && selectedItem['E-Mail']"
                />
              </CCol>

              <CCol sm="12">
                <label>Telefon</label>
                <CInput
                  id="phone"
                  required
                  v-model="selectedItem && selectedItem['Telefon']"
                />
              </CCol>

              <CCol sm="12">
                <label for="organization"
                  >Organisation (z.b. bei Firmen, Erbengemeinschaften
                  o.ä)</label
                >
                <CInput
                  id="organization"
                  v-model="selectedItem && selectedItem['Organisation']"
                />
              </CCol>

              <CCol sm="12">
                <label for="organizationAddress">Organisation-Adresse</label>
                <CInput
                  id="organizationAddress"
                  v-model="selectedItem && selectedItem['Organisation-Adresse']"
                />
              </CCol>
            </CRow>

            <div slot="footer">
              <CButton
                class="mr-3"
                color="dark"
                variant="outline"
                @click="visibleModal = false"
              >
                Abbrechen
              </CButton>
              <CButton color="success" type="submit"> Speichern </CButton>
            </div>
          </CModal>
        </CForm>
      </CCol>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import { VueCsvImport } from "vue-csv-import";

export default {
  name: "ContactImport",
  components: {
    VueCsvImport,
  },
  mounted() {
    this.loadPeoples();
    this.loadOrganizations();
    this.customizeFileInput();
  },
  data() {
    return {
      mapFields: [
        "Name",
        "E-Mail",
        "Telefon",
        "Organisation",
        "Organisation-Adresse",
      ],
      csv: null,
      tableData: [],
      showTable: false,
      peoples: [],
      organizations: [],
      visibleActionButton: false,
      loading: false,
      visibleModal: false,
      selectedIndex: null,
      selectedItem: null,
    };
  },
  watch: {
    csv(val) {
      if (val) {
        this.visibleActionButton = true;
      }
    },
  },
  methods: {
    customizeFileInput() {
      document.querySelectorAll(
        ".contact-import .csv-import-file",
      )[0].style.display = "none";

      const divWrapper = document.createElement("div");
      divWrapper.setAttribute("class", "row");

      const divOffset = document.createElement("div");
      divOffset.setAttribute("class", "col-sm-3 mb-3");
      divWrapper.appendChild(divOffset);

      const div = document.createElement("div");
      div.setAttribute("class", "form-group col-sm-9 mb-3");
      const label = document.createElement("label");
      label.setAttribute("for", "csv-import-file");
      label.setAttribute("class", "custom-file-label");
      label.style.display = "block";
      label.innerHTML = "Datei auswählen...";
      div.appendChild(label);

      divWrapper.appendChild(div);

      const csvInput = document.querySelectorAll(
        ".contact-import .form-control-file",
      )[0];
      csvInput.addEventListener("change", function (e) {
        label.innerHTML = e.target.files[0].name;
      });

      div.addEventListener("click", function () {
        csvInput.click();
      });
      const partOne = document.querySelectorAll(
        ".contact-import .vue-csv-uploader-part-one",
      )[0];
      partOne.insertBefore(divWrapper, partOne.children[2]);
    },
    loadPeoples() {
      axios
        .get("/realm-people")
        .then((response) => {
          this.peoples = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    loadOrganizations() {
      axios
        .get("/realm-group")
        .then((response) => {
          this.organizations = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    getTableFields() {
      return [
        {
          key: "Name",
          label: "Name",
        },
        {
          key: "E-Mail",
          label: "E-Mail",
        },
        {
          key: "Telefon",
          label: "Telefon",
        },
        {
          key: "Organisation",
          label: "Organisation",
        },
        {
          key: "Organisation-Adresse",
          label: "Organisation-Adresse",
        },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
        {
          key: "delete_item",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ];
    },
    back() {
      this.showTable = false;
      setTimeout(() => {
        this.customizeFileInput();
      }, 100);
    },
    async submit() {
      this.loading = true;
      const peoples = this.filterDuplicates();
      const organizationsToAdd = this.filterOrganizations(peoples);
      this.loading = false;
      if (organizationsToAdd && organizationsToAdd.length > 0) {
        await this.addOrganizations(organizationsToAdd);
      }

      if (peoples && peoples.length > 0) {
        this.loading = true;
        const formattedPeoples = this.formatPeoplesData(peoples);
        this.loading = false;

        await this.addPeoples(formattedPeoples);
      } else if (!this.csv || (this.csv && this.csv.length === 0)) {
        alert("Nichts hinzuzufügen");
      } else {
        alert("Bereits importierte Kontakte ");
      }
    },
    filterDuplicates() {
      let entities = [];
      this.csv &&
        this.csv.map((record) => {
          // check csv file has duplicated contacts
          const duplicated =
            entities &&
            entities.findIndex((entity) => {
              return (
                entity["Name"] === record["Name"] &&
                entity["E-Mail"] === record["E-Mail"]
              );
            });

          if (duplicated === -1) {
            // check if db has already same contact
            const alreadyExist =
              this.peoples &&
              this.peoples.findIndex((people) => {
                if (people.name === record["Name"]) {
                  const index =
                    people.realmPeopleEmails &&
                    people.realmPeopleEmails.findIndex(
                      (email) => email["email"] === record["E-Mail"],
                    );
                  return index !== -1;
                }
              });
            // if db doesn't have same contact
            if (alreadyExist === -1) {
              entities.push(record);
            }
          }
        });

      return entities;
    },
    filterOrganizations(peoples) {
      let organizations = [];
      peoples &&
        peoples.map((people) => {
          // check csv file has the duplicated organizations
          const index =
            organizations &&
            organizations.findIndex((organization) => {
              return (
                organization["title"] === people["Organisation"] &&
                organization["address"] === people["Organisation-Adresse"]
              );
            });

          if (index === -1) {
            // check if db has already same organization
            const alreadyExist =
              this.organizations &&
              this.organizations.findIndex(
                (organization) => organization.title === people["Organisation"],
              );
            // if db doesn't have same organization
            if (alreadyExist === -1) {
              organizations.push({
                title: people["Organisation"],
                address: people["Organisation-Adresse"],
              });
            }
          }
        });

      return organizations;
    },
    formatPeoplesData(peoples) {
      let formattedPeoples = [];
      peoples &&
        peoples.map((people) => {
          const organization =
            this.organizations &&
            this.organizations.find(
              (organization) => organization.title === people["Organisation"],
            );

          const entity = {
            name: people["Name"],
            realmGroup: {
              id: (organization && organization.id) || null,
            },
            realmPeopleEmails: [
              {
                email: people["E-Mail"],
              },
            ],
            realmPeoplePhones: [
              {
                phone: people["Telefon"],
              },
            ],
          };

          formattedPeoples.push(entity);
        });

      return formattedPeoples;
    },
    async addOrganizations(organizations) {
      try {
        this.loading = true;
        const response = await axios.post(
          "/realm-group/multiple",
          organizations,
        );
        if (response.data) {
          this.organizations.push(...response.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async addPeoples(peoples) {
      try {
        this.loading = true;
        await axios.post("/realm-people/multiple", peoples);
        this.$toaster.makeToast("Erfolgreich!", "Erfolgreich importiert");
        this.$router.push({ name: "People" });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    showEditModal(index, item) {
      this.selectedIndex = index;
      this.selectedItem = item;
      this.visibleModal = true;
    },
    update() {
      if (this.selectedIndex !== null) {
        this.csv && this.csv.splice(this.selectedIndex, 1, this.selectedItem);
        this.visibleModal = false;
      }
    },
    deleteRecord(index) {
      this.csv && this.csv.splice(index, 1);
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
