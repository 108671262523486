<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Funnel E-Mail bearbeiten</strong>
            </slot>
          </CCardHeader>
          <CCardBody v-if="funnelRule">
          <FunnelForm
              :passed-data="funnelRule"
              @submit="createFunnel"
              :processing="processing"
              :enable-delete="false"
              btn-text="Speichern"
              :campaigns="campaigns"
          />
        </CCardBody>
          <CSpinner v-else />
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Platzhalter für E-Mails</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <PlaceholderManager />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import FunnelForm from "./base/FunnelForm";
import PlaceholderManager from "@/components/newsletter/PlaceholderManager";


export default {
  name: "EditFunnel",
  components: {
    FunnelForm,
    PlaceholderManager
  },
  data() {
    return {
      funnelRule: null,
      processing: false,
      campaigns: [], // Kampagnen-Daten
    };
  },
  mounted() {
    this.loadCampaigns()
        .then(() => {
          const funnelId = this.$route.params.id;
          if (funnelId) {
            this.loadFunnelRule(funnelId);
          } else {
            console.error("Keine Funnel-ID gefunden. Weiterleitung zur Funnel-Übersicht.");
            this.$router.push({ name: "Funnel" });
          }
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Kampagnen:", error);
        });
  },
  methods: {
    async loadFunnelRule(id) {
      try {
        const response = await axios.get(`/funnel/rule/${id}`);
        const funnel = response.data;

        if (funnel.messageType === "UNLAYER_JSON" && funnel.message) {
          funnel.message = JSON.parse(funnel.message);
        }

        this.funnelRule = funnel;
        console.log("Geladene Funnelregel:", funnel);
      } catch (error) {
        console.error("Fehler beim Laden des Funnels:", error);
        this.$router.push({ name: "Funnel" });
      }
    },
    createFunnel(data) {
      this.processing = true;
      const request = data.id
          ? axios.put(`/funnel/rule/${data.id}`, data) // Update
          : axios.post("/funnel/rule/", data);        // Create

      request
          .then(() => {
            this.$router.push({ name: "Funnel", params: { saved: "1" } });
            this.$toaster.makeToast("Success!", "<b>Funnel erfolgreich gespeichert!</b>");
          })
          .catch((err) => {
            if (err.response) {
              this.$toaster.makeToast(
                  "Oops!",
                  "<b>" +
                  err.response.data.code +
                  "</b><br>" +
                  err.response.data.message
              );
            } else {
              this.$toaster.makeToast("Error!", err.message);
            }
          })
          .finally(() => {
            this.processing = false;
          });
    },
    async loadCampaigns() {
      try {
        const response = await axios.get("/campaigns");

        // Kampagnendaten korrekt mappen
        this.campaigns = response.data.map((campaign) => ({
          value: campaign.id, // Kampagnen-ID (UUID oder String)
          text: campaign.name, // Kampagnenname
        }));

        console.log("Geladene Kampagnen:", this.campaigns);
      } catch (error) {
        console.error("Fehler beim Laden der Kampagnen:", error);
      }
    },
  },
};
</script>
