import axios from 'axios';
import router from '@/router';
import commons from "@/commons";
import toastPlugin from "@/toastPlugin";

class AuthService {
  isRefreshing = false;
  tokenRenewalTimeout = null;

  async handleLogin(response) {
    try {
      const { token, user } = response.data;

      // Speichern Sie zuerst den Token
      commons.setToStorage('jwtToken', token);

      // Parsen und speichern Sie die Token-Informationen
      const parsed = commons.getJwtToken();
      if (!parsed) {
        throw new Error('Failed to parse token');
      }

      // Speichern Sie den User und Login-Status
      commons.setToStorage('currentUser', user);
      commons.setToStorage('loggedIn', true);

      // Setup Token Renewal
      this.setupTokenRenewal(token);

      return true;
    } catch (error) {
      console.error('Login processing error:', error);
      return false;
    }
  }

  setupTokenRenewal(token) {
    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
    }

    const expirationTime = this.getTokenExpirationTime(token);
    const currentTime = Date.now();
    const timeUntilRenewal = expirationTime - currentTime - this.TOKEN_RENEWAL_THRESHOLD;

    if (timeUntilRenewal > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, timeUntilRenewal);
    }
  }

  async renewToken() {
    if (this.isRefreshing) {
      return commons.getFromStorage('jwtToken');
    }

    this.isRefreshing = true;
    try {
      const currentUser = commons.getFromStorage('currentUser');
      if (!currentUser?.email) {
        throw new Error('No user credentials available');
      }

      const response = await axios.post('/user/login', {
        email: currentUser.email,
        password: commons.getFromStorage('userPassword')
      });

      await this.handleLogin(response);

      this.isRefreshing = false;
      return commons.getFromStorage('jwtToken');
    } catch (error) {
      this.isRefreshing = false;
      this.logout();
      throw error;
    }
  }

  logout() {
    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
    }

    commons.removeFromStorage('jwtToken');
    commons.removeFromStorage('jwtTokenParsed');
    commons.removeFromStorage('currentUser');
    commons.removeFromStorage('loggedIn');
    commons.removeFromStorage('userPassword');

    setTimeout(() => {
      toastPlugin.makeToast(
          "Sitzung abgelaufen",
          "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an."
      );
      router.push({ name: "Login" });
    }, 100);
  }
}

export default new AuthService();
