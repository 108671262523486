<template>
  <div v-if="isInSupportMode" class="support-mode-banner">
    <div class="d-flex align-items-center justify-content-center w-100">
      <CIcon name="cil-user" class="mr-2"/>
      <span class="mr-3">Support-Modus aktiv als: {{ currentUserEmail }}</span>
      <CButton
        color="light"
        variant="outline"
        size="sm"
        @click="exitSupportMode"
        class="ml-3"
      >
        <CIcon name="cil-account-logout" class="mr-1"/>
        Zurück zum Admin-Account
      </CButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import commons from "@/commons";

export default {
  name: 'SupportModeBanner',
  data() {
    return {
      isInSupportMode: false,
      currentUserEmail: '',
      originalToken: null,
      storageListener: null
    }
  },
  mounted() {
    this.checkSupportMode();

    // Listener als Property speichern
    this.storageListener = () => this.checkSupportMode();
    window.addEventListener('storage', this.storageListener);
  },
  beforeDestroy() {
    if (this.storageListener) {
      window.removeEventListener('storage', this.storageListener);
      this.storageListener = null;
    }
  },
  methods: {
    checkSupportMode() {
      const supportInfo = commons.getFromStorage('supportMode');

      if (supportInfo) {
        try {
          const supportData = typeof supportInfo === 'string' ? JSON.parse(supportInfo) : supportInfo;

          this.isInSupportMode = true;
          this.originalToken = supportData.originalToken;

          const userInfo = commons.getFromStorage('currentUser');
          if (userInfo) {
            const user = typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo;
            this.currentUserEmail = user.email;
          }

        } catch (error) {
          console.error('Error processing support info:', error);
          this.resetSupportMode();
        }
      } else {
        this.resetSupportMode();
      }
    },

    resetSupportMode() {
      this.isInSupportMode = false;
      this.originalToken = null;
      this.currentUserEmail = '';
      commons.removeFromStorage('supportMode');
    },

    async exitSupportMode() {
      try {

        const supportInfo = commons.getFromStorage('supportMode');

        if (!supportInfo || !supportInfo.originalToken) {
          console.error('No valid support mode info found');
          return;
        }

        const originalToken = supportInfo.originalToken;

        const response = await axios.post('/user/exit-support-mode', {
          originalToken: originalToken
        });

        if (response.data) {
          // Alle relevanten Storage-Einträge einzeln entfernen
          commons.removeFromStorage('jwtToken');
          commons.removeFromStorage('currentUser');
          commons.removeFromStorage('currentRealm');
          commons.removeFromStorage('license');
          commons.removeFromStorage('unlayerSignatureHmac');
          commons.removeFromStorage('supportMode');
          commons.removeFromStorage('loggedIn');

          // Neue Admin-Daten setzen
          commons.setToStorage('jwtToken', response.data.token);
          commons.setToStorage('currentUser', response.data.user);
          commons.setToStorage('currentRealm', response.data.realm);
          commons.setToStorage('license', response.data.realm.license);
          commons.setToStorage('unlayerSignatureHmac', response.data.unlayerSignatureHmac);
          commons.setToStorage('loggedIn', true);

          // Seite neu laden
          window.location.href = '/dashboard';
        }
      } catch (error) {
        console.error('Fehler beim Verlassen des Support-Modus:', error);
        if (this.$toast) {
          this.$toast.error('Fehler beim Verlassen des Support-Modus');
        }
      }
    }
  }
}
</script>

<style scoped>
.support-mode-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #321fdb;
  color: white;
  padding: 8px;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.support-mode-banner .btn-light {
  color: #321fdb;
  background-color: white;
  border-color: white;
}

.support-mode-banner .btn-light:hover {
  color: #2819ae;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}
</style>
