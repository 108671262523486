<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <!-- Header mit Loading Indicator -->
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Grundeinstellungen</h4>
            <DoubleBounceLoader v-if="loading || saving" width="20px" height="20px"/>
          </CCardHeader>

          <CCardBody v-if="!loading">
            <!-- Modernere Tab Navigation -->
            <CTabs variant="pills" vertical>
              <!-- Unternehmen Tab -->
              <CTab title="Unternehmen" active>
                <CCard class="border-0">
                  <CCardBody>
                    <h5 class="mb-4">Unternehmensdaten</h5>

                    <!-- Gruppierte Formularfelder -->
                    <CRow>
                      <CCol md="6">
                        <CInput
                            label="Unternehmensname"
                            v-model="currentRealm.name"
                            placeholder="Ihr Unternehmensname"
                        />
                      </CCol>
                      <CCol md="6">
                        <CInput
                            label="Kontaktperson"
                            v-model="companySettings.contactPerson"
                            placeholder="Name der Kontaktperson"
                        />
                      </CCol>
                    </CRow>

                    <!-- Adressgruppe -->
                    <div class="address-group mt-4">
                      <h6 class="mb-3">Adresse</h6>
                      <CRow>
                        <CCol md="6">
                          <CInput
                              label="Straße & Hausnummer"
                              v-model="companySettings.streetAndNumber"
                          />
                        </CCol>
                        <CCol md="3">
                          <CInput
                              label="PLZ"
                              v-model="companySettings.postalCode"
                          />
                        </CCol>
                        <CCol md="3">
                          <CInput
                              label="Ort"
                              v-model="companySettings.city"
                          />
                        </CCol>
                      </CRow>
                    </div>

                    <!-- Kontaktdaten -->
                    <div class="contact-group mt-4">
                      <h6 class="mb-3">Kontaktdaten</h6>
                      <CRow>
                        <CCol md="6">
                          <CInput
                              label="Telefon"
                              v-model="companySettings.phoneNumber"
                              prepend-icon="cil-phone"
                          />
                        </CCol>
                        <CCol md="6">
                          <CInput
                              label="E-Mail"
                              v-model="companySettings.email"
                              prepend-icon="cil-envelope-closed"
                          />
                        </CCol>
                      </CRow>
                      <CRow class="mt-3">
                        <CCol md="6">
                          <CInput
                              label="Website"
                              v-model="companySettings.website"
                              prepend-icon="cil-globe-alt"
                          />
                        </CCol>
                        <CCol md="6">
                          <CInput
                              label="Fax"
                              v-model="companySettings.fax"
                              prepend-icon="cil-print"
                          />
                        </CCol>
                      </CRow>
                    </div>

                    <!-- API & Provision -->
                    <div class="api-group mt-4">
                      <h6 class="mb-3">API & Provision</h6>
                      <CRow>
                        <CCol md="6">
                          <CInput
                              label="API-Schlüssel"
                              :value="currentRealm.apiKey || currentRealm.license"
                              readonly
                              class="api-key-input"
                          />
                        </CCol>
                        <CCol md="6">
                          <CInput
                              label="Provision/Gewinn (%)"
                              v-model="currentRealm.commission"
                              type="number"
                          />
                        </CCol>
                      </CRow>
                    </div>
                  </CCardBody>
                </CCard>
              </CTab>

              <!-- E-Mail-Konfiguration Tab -->
              <CTab title="E-Mail-Konfiguration">
                <CCard class="border-0">
                  <CCardBody>
                    <h5 class="mb-4">E-Mail-Einstellungen</h5>

                    <!-- Domain Konfiguration -->
                    <div class="domain-group mb-4" v-if="isNotKensingtonOrIsButOrgaAdmin">
                      <h6 class="mb-3">Domain-Konfiguration</h6>
                      <CInput
                          label="Domain"
                          v-model="currentRealm.mailgunDomain"
                          :isValid="validFields.mailgunDomain.valid"
                          :invalidFeedback="validFields.mailgunDomain.invalidFeedback"
                          :disabled="currentRealm.mailgunDomainResponseJson != null"
                      >
                        <template v-slot:description>
                          <div class="text-muted small mb-2">
                            Standardmäßig werden Ihre E-Mails über die Domain von LeadMeUp gesendet.
                            Sie können optional eine eigene Domain konfigurieren.
                          </div>

                          <!-- DNS Einträge Controls -->
                          <div v-if="currentRealm.mailgunDomainResponseJson" class="mt-2">
                            <CButton
                                color="primary"
                                size="sm"
                                @click="collapseMailgunDns = !collapseMailgunDns"
                            >
                              DNS-Einträge {{ collapseMailgunDns ? 'ausblenden' : 'anzeigen' }}
                            </CButton>
                            <CButton
                                color="info"
                                size="sm"
                                class="ml-2"
                                @click="verifyAndRefreshMailgunDomain"
                            >
                              DNS-Einträge prüfen
                            </CButton>
                            <CButton
                                color="danger"
                                size="sm"
                                class="ml-2"
                                @click="deleteMailgunDomain"
                            >
                              Domain löschen
                            </CButton>
                          </div>

                          <!-- DNS Einträge -->
                          <CCollapse
                              v-if="currentRealm.mailgunDomainResponseJson"
                              :show="collapseMailgunDns"
                          >
                            <MailgunDnsEntries
                                class="mt-3"
                                :mailgun-response-json="currentRealm.mailgunDomainResponseJson"
                            />
                          </CCollapse>
                        </template>
                      </CInput>
                    </div>

                    <!-- E-Mail Benachrichtigungen -->
                    <div class="notifications-group mb-4">
                      <h6 class="mb-3">Benachrichtigungen</h6>
                      <RadioButton
                          label="Aktivitäten via E-Mail erhalten?"
                          name="email_activities"
                          :options="[
                          { label: 'Ja', value: true },
                          { label: 'Nein', value: false }
                        ]"
                          v-model="emailSettings.activities"
                          description="Möchten Sie über die Öffnung der Immobilienbewertung durch den Eigentümer, zusätzlich per E-Mail benachrichtig werden?"
                      />
                    </div>

                    <!-- E-Mail Templates -->
                    <div class="templates-group">
                      <h6 class="mb-3">E-Mail Templates</h6>

                      <!-- Admin E-Mail -->
                      <CInput
                          v-if="isNotKensingtonOrIsButOrgaAdmin"
                          type="text"
                          label="Admin E-Mail"
                          description="An diese E-Mail werden die Benachrichtigungen über neue Leads gesendet. Ausserdem wird diese E-Mail-Adresse als Standard Absender für alle E-Mails verwendet. Format: Ihr Name <info@mustermakler.de> - Beispiel: Mustermakler GmbH <info@mustermakler.de>"
                          v-model="emailSettings.adminEmail"
                          placeholder="Ihr Name <ihre@email.de>"
                      />

                      <!-- Kunden E-Mail -->
                      <div class="mt-3">
                        <CInput
                            label="Betreff (Kunde)"
                            v-model="emailSettings.customerSubject"
                            description="Betreff der Wertermittlungs E-Mail an den Eigentümer. Die Verwendung der unten angegeben Platzhalter ist möglich."
                        />
                        <WysiwygEditor
                            class="mt-3"
                            label="Inhalt (Kunde)"
                            description="Inhalt der Wertermittlungs E-Mail an den Eigentümer. Die Verwendung der unten angegeben Platzhalter ist möglich."
                            v-model="emailSettings.customerContent"
                        />
                      </div>

                      <!-- Kunden E-Mail (keine Ergebnisse) -->
                      <div class="mt-3">
                        <WysiwygEditor
                            label="Inhalt (Kunde) - Keine Ergebnisse"
                            v-model="emailSettings.customerContentNoResults"
                            description="Inhalt der E-Mail an den Eigentümer wenn keine Ergebnisse vorliegen oder die Wertermittlung deaktiviert ist. Die Verwendung der unten angegeben Platzhalter ist möglich."
                        />
                      </div>

                      <!-- Dateianhang -->
                      <div class="mt-3">
                        <FileUploadField
                            label="Dateianhang (Kunde)"
                            v-model="emailSettings.customerAttachment"
                            settingKey="email_customerAttachment"
                            description="Fügen Sie einen Dateianhang hinzu, dieser wird den Mails an Ihre Kunden angehangen. (Kommt bald)"

                        />
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CTab>

              <!-- OpenImmo Tab -->
              <CTab title="OpenImmo" v-if="shouldShowImmoTab">
                <CCard class="border-0">
                  <CCardBody>
                    <h5 class="mb-4">OpenImmo Konfiguration</h5>
                    <CInput
                        type="text"
                        label="OpenImmo Objekt Nr."
                        v-model="openImmoSettings.objectId"
                        description="Zur Zuordnung des Leads beim Import der OpenImmo XML"
                    />
                  </CCardBody>
                </CCard>
              </CTab>

              <!-- Opt-in Tab -->
              <CTab title="Opt-in" v-if="isNotKensingtonOrIsButOrgaAdmin">
                <CCard class="border-0">
                  <CCardBody>
                    <h5 class="mb-4">Opt-in Konfiguration</h5>

                    <!-- Standard Opt-In -->
                    <div class="email-template mb-4">
                      <h6 class="mb-3">Standard Opt-In</h6>
                      <CInput
                        label="Betreff"
                        v-model="optInSettings.emailSubject"
                        description="Betreff der E-Mail-Bestätigungs-Mail. Die Platzhalter (unten) sind erlaubt."
                      />
                      <WysiwygEditor
                        class="mt-3"
                        label="E-Mail Inhalt"
                        v-model="optInSettings.emailContent"
                        description="Inhalt der E-Mail-Bestätigungs-Mail. Die Platzhalter (unten) sind erlaubt."
                      />
                    </div>

                    <!-- Re-Opt-In (Neu) -->
                    <div class="email-template mb-4">
                      <h6 class="mb-3">Re-Opt-In (Erneute Anmeldung)</h6>
                      <CInput
                        label="Betreff"
                        v-model="optInSettings.reOptInEmailSubject"
                        description="Betreff der Bestätigungs-E-Mail für erneute Anmeldungen."
                      />
                      <WysiwygEditor
                        class="mt-3"
                        label="E-Mail Inhalt"
                        v-model="optInSettings.reOptInEmailContent"
                        description="Inhalt der Bestätigungs-E-Mail für erneute Newsletter-Anmeldungen."
                      />
                      <WysiwygEditor
                        class="mt-3"
                        label="Anmeldeformular"
                        v-model="optInSettings.reOptInFormHtml"
                        description="Das Formular für Nutzer, die sich erneut zum Newsletter anmelden möchten."
                      />
                      <WysiwygEditor
                        class="mt-3"
                        label="Bestätigungsseite"
                        v-model="optInSettings.reOptInConfirmationHtml"
                        description="Die Bestätigungsseite nach erfolgreicher erneuter Anmeldung."
                      />
                    </div>

                    <!-- Bestätigungsseiten -->
                    <div class="confirmation-pages">
                      <h6 class="mb-3">Bestätigungsseiten</h6>
                      <WysiwygEditor
                        label="Opt-In bestätigt"
                        description="Inhalt der Bestätigungsseite nach Klick auf den 'Verifizieren' Button. HTML ist erlaubt."
                        v-model="optInSettings.acceptedHtml"
                      />
                      <WysiwygEditor
                        class="mt-3"
                        label="Opt-In abgelehnt"
                        description="Inhalt der Seite bei Abmeldung aus dem E-Mail-Verteiler. HTML ist erlaubt. Tipp: Fügen Sie einen Link zur erneuten Anmeldung hinzu."
                        v-model="optInSettings.cancelledHtml"
                      />
                    </div>
                  </CCardBody>
                </CCard>
              </CTab>

              <!-- CRM Integration Tab -->
              <CTab title="CRM Integration">
                <CCard class="border-0">
                  <CCardBody>
                    <h5 class="mb-4">CRM Einstellungen</h5>

                    <!-- PropStack -->
                    <div class="propstack-group mb-4">
                      <h6 class="mb-3">Propstack</h6>
                      <CRow>
                        <CCol md="8">
                          <CInput
                            type="password"
                            label="API-Schlüssel"
                            v-model="propStackSettings.apiKey"
                            placeholder="Propstack API-Schlüssel eingeben"
                          />
                        </CCol>
                        <CCol md="4" class="d-flex align-items-end">
                          <CButton
                            color="info"
                            :disabled="!propStackSettings.apiKey || !propStackSettings.enabled || propStackTestStatus.loading"
                            @click="testPropstackConnection"
                          >
                            <CIcon name="cil-link" v-if="!propStackTestStatus.loading"/>
                            <CSpinner size="sm" v-else/>
                            Verbindung testen
                          </CButton>
                        </CCol>
                      </CRow>

                      <div class="mt-3">
                        <RadioButton
                          label="Integration aktivieren?"
                          name="propstackEnabled"
                          :options="[
        { label: 'Ja', value: true },
        { label: 'Nein', value: false }
      ]"
                          v-model="propStackSettings.enabled"
                          @change="handlePropstackEnabledChange"
                        />
                      </div>

                      <!-- Status Messages -->
                      <div v-if="propStackTestStatus.success" class="mt-3">
                        <CAlert color="success">
                          <CIcon name="cil-check-circle"/> Verbindung erfolgreich hergestellt
                        </CAlert>
                      </div>
                      <div v-if="propStackTestStatus.error" class="mt-3">
                        <CAlert color="danger">
                          <CIcon name="cil-x-circle"/> {{ propStackTestStatus.error }}
                        </CAlert>
                      </div>
                    </div>

                    <!-- FlowFact -->
                    <div class="flowfact-group mb-4">
                      <h6 class="mb-3">FlowFact</h6>
                      <CInput
                          type="text"
                          label="API-Schlüssel"
                          v-model="flowFactSettings.apiKey"
                      />
                    </div>

                    <!-- OnOffice -->
                    <div class="onoffice-group">
                      <h6 class="mb-3">OnOffice</h6>
                      <CRow>
                        <CCol md="6">
                          <CInput
                              type="text"
                              label="API-Schlüssel"
                              v-model="onOfficeSettings.apiKey"
                          />
                        </CCol>
                        <CCol md="6">
                          <CInput
                              type="password"
                              label="API-Secret"
                              v-model="onOfficeSettings.apiSecret"
                          />
                        </CCol>
                      </CRow>
                      <RadioButton
                          class="mt-3"
                          label="Newsletter-Service aktivieren?"
                          name="newsletterServiceActive"
                          :options="[
                          { label: 'Ja', value: true },
                          { label: 'Nein', value: false }
                        ]"
                          v-model="onOfficeSettings.newsletterServiceActive"
                      />
                    </div>
                  </CCardBody>
                </CCard>
              </CTab>

              <!-- AGB & Datenschutz Tab -->
              <CTab title="AGB & Datenschutz" v-if="isNotKensingtonOrIsButOrgaAdmin">
                <CCard class="border-0">
                  <CCardBody>
                    <h5 class="mb-4">Rechtliche Dokumente</h5>

                    <WysiwygEditor
                        label="AGB"
                        v-model="companySettings.conditions"
                        description="Erstellen Sie die Allgemeinen Geschäftsbedigungen für dieses Unternehmen."
                    />

                    <WysiwygEditor
                        class="mt-4"
                        label="Datenschutz"
                        v-model="companySettings.privacy"
                        description="Erstellen Sie die Datenschutzerklärung für dieses Unternehmen."
                    />
                  </CCardBody>
                </CCard>
              </CTab>
            </CTabs>

            <!-- Action Buttons -->
            <div class="d-flex mt-4">
              <CButton
                  @click="submitSettings"
                  color="success"
                  size="lg"
              >
                <CIcon name="cil-save"/> Speichern
              </CButton>

              <CButton
                  v-if="currentRealm.plan === 'KENSINGTON' && $commons.isOrganizationAdmin()"
                  @click="submitSettings(true)"
                  color="warning"
                  size="lg"
                  class="ml-2"
                  v-c-tooltip="'Als Standard für neue Standorte festlegen'"
              >
                <CIcon name="cil-star"/> Als Standard speichern
              </CButton>

            </div>
          </CCardBody>
        </CCard>

        <!-- Platzhalter Card -->
        <CCard class="mt-4 placeholder-card">
          <CCardHeader>
            <h5 class="mb-0 d-flex align-items-center">
              <CIcon name="cil-code" class="mr-2"/>
              Platzhalter für E-Mails
            </h5>
          </CCardHeader>
          <CCardBody>
            <div class="table-responsive">
              <table class="table table-hover table-striped vars-to-use">
                <thead>
                <tr>
                  <th width="30%">Platzhalter</th>
                  <th>Beschreibung</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><code>{{ "{MAKLER}" }}</code></td>
                  <td>Ihr Maklername</td>
                </tr>
                <tr>
                  <td><code>{{ "{IMMOBILIEN_TYP}" }}</code></td>
                  <td>Z.b. Haus, Eigentumswohnung etc.</td>
                </tr>
                <tr>
                  <td><code>{{ "{ANREDE}" }}</code></td>
                  <td>Anrede</td>
                </tr>
                <tr>
                  <td><code>{{ "{NACHNAME}" }}</code></td>
                  <td>Nachname des Eigentümers</td>
                </tr>
                <tr>
                  <td><code>{{ "{VORNAME}" }}</code></td>
                  <td>Vorname des Eigentümers</td>
                </tr>
                <tr>
                  <td><code>{{ "{TELEFON}" }}</code></td>
                  <td>Telefonnummer des Eigentümers</td>
                </tr>
                <tr>
                  <td><code>{{ "{EMAIL}" }}</code></td>
                  <td>E-Mail Adresse des Eigentümers</td>
                </tr>
                <tr>
                  <td><code>{{ "{ADRESSE}" }}</code></td>
                  <td>Vollständige Anschrift der Immobilie</td>
                </tr>
                <tr>
                  <td><code>{{ "{WOHNFLACHE}" }}</code></td>
                  <td>Wohnfläche der Immobilie</td>
                </tr>
                <tr>
                  <td><code>{{ "{ZIMMER}" }}</code></td>
                  <td>Anzahl der Zimmer der Immobilie</td>
                </tr>
                <tr>
                  <td><code>{{ "{BAUJAHR}" }}</code></td>
                  <td>Baujahr der Immobilie</td>
                </tr>
                <tr>
                  <td><code>{{ "{GRUNDFLACHE}" }}</code></td>
                  <td>Grundstücksfläche der Immobilie</td>
                </tr>
                <tr>
                  <td><code>{{ "{ETAGE}" }}</code></td>
                  <td>Anzahl der Etagen der Immobilie</td>
                </tr>
                <tr>
                  <td><code>{{ "{ERSCHLOSSEN}" }}</code></td>
                  <td>Bei Grundstücken, Angabe ob erschlossen, teilerscshlossen oder nicht erschlossen</td>
                </tr>
                <tr>
                  <td><code>{{ "{BEBAUUNG}" }}</code></td>
                  <td>Bebaungsmöglichkeiten von Grundstücken</td>
                </tr>
                <tr>
                  <td><code>{{ "{ZUSCHNITT}" }}</code></td>
                  <td>Angaben zum Grundstückszuschnitt</td>
                </tr>
                <tr>
                  <td><code>{{ "{GRUND}" }}</code></td>
                  <td>Grund der Immobilienbewertung Kauf oder Verkauf</td>
                </tr>
                <tr>
                  <td><code>{{ "{GRUND_WANN}" }}</code></td>
                  <td>Verkaufszeitwunsch, wenn "Verkauf" als Grund ausgewählt</td>
                </tr>
                <tr>
                  <td><code>{{ "{GESAMT_ERGEBNIS}" }}</code></td>
                  <td>Ermittelter durchschnittlicher Marktwert</td>
                </tr>
                <tr>
                  <td><code>{{ "{MIN_GESAMT_ERGEBNIS}" }}</code></td>
                  <td>Resultierende Wertspanne Minimum</td>
                </tr>
                <tr>
                  <td><code>{{ "{MAX_GESAMT_ERGEBNIS}" }}</code></td>
                  <td>Resultierende Wertspanne Maximum</td>
                </tr>
                <tr>
                  <td><code>{{ "{ERGEBNIS_PRO_QM}" }}</code></td>
                  <td>Durchschnittlicher Wert pro m² Wohnfläche</td>
                </tr>
                <tr>
                  <td><code>{{ "{MIN_ERGEBNIS_PRO_QM}" }}</code></td>
                  <td>Ermittelte Wertspanne pro m² Minimum</td>
                </tr>
                <tr>
                  <td><code>{{ "{MAX_ERGEBNIS_PRO_QM}" }}</code></td>
                  <td>Ermittelte Wertspanne pro m² Maximum</td>
                </tr>
                <tr>
                  <td><code>{{ "{OPT_IN_CONFIRM_URL}" }}</code></td>
                  <td>Die URL zum Bestätigen des Opt-Ins</td>
                </tr>
                <!-- Neue Re-Opt-In Variablen -->
                <tr>
                  <td><code>{{ "{CONFIRM_URL}" }}</code></td>
                  <td>
                    URL zur Bestätigung der erneuten Anmeldung
                    <span class="badge badge-info">Nur in Re-Opt-In E-Mails</span>
                  </td>
                </tr>
                <tr>
                  <td><code>{{ "{BASE_URL}" }}</code></td>
                  <td>Basis-URL der Anwendung</td>
                </tr>
                <tr>
                  <td><code>{{ "{REALM_ID}" }}</code></td>
                  <td>ID des aktuellen Realms</td>
                </tr>
                <tr>
                  <td><code>{{ "{REALM_NAME}" }}</code></td>
                  <td>Name des Realms</td>
                </tr>
                <tr>
                  <td><code>{{ "{PRIVACY_URL}" }}</code></td>
                  <td>Link zur Datenschutzerklärung (falls konfiguriert)</td>
                </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import DoubleBounceLoader from "@/components/loaders/DoubleBounceLoader";
import WysiwygEditor from "@/components/WysiwygEditor";
import Checkbox from "@/components/Checkbox";
import RadioButton from "@/components/RadioButton";
import commons from "@/commons";
import axios from "axios";
import _ from "lodash";
import MailgunDnsEntries from "@/components/MailgunDnsEntries";
import validations from "@/validations";
import FileUploadField from "@/components/FileUploadField";
import TheToaster from "../../containers/TheToaster.vue";
import { DEFAULT_TEMPLATES } from '@/constants/defaultTemplates';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL.replace('/backend', '')
})

export default {
  name: "RealmSettings",
  components: {
    FileUploadField,
    MailgunDnsEntries,
    RadioButton,
    DoubleBounceLoader,
    WysiwygEditor,
    Checkbox,
  },
  data() {
    return {
      loading: false,
      saving: false,
      currentRealm: this.$commons.getFromStorage("currentRealm"),
      collapseMailgunDns: false,
      /* v-if explained: if realmPlain is NOT KENSINGTON then show, else: check if hasRole minimum ORGANIZATION_ADMIN */
      isNotKensingtonOrIsButOrgaAdmin:
        !commons.hasExactRealmPlan("KENSINGTON") ||
        commons.hasRole("ORGANIZATION_ADMIN"),
      onOfficeSettings: {
        apiKey: "",
        newsletterServiceActive: false,
      },
      propStackSettings: {
        apiKey: "",
        enabled: true, // default to true as per requirements
      },
      propStackTestStatus: {
        loading: false,
        error: null,
        success: false
      },
      flowFactSettings: {
        apiKey: "",
      },
      companySettings: {
        streetAndNumber: "",
        postalCode: "",
        city: "",
        phoneNumber: "",
        fax: "",
        website: "",
        email: "",
        contactPerson: "",
        contactPersonPosition: "",
        conditions: "",
        privacy: "",
      },
      emailSettings: {
        activities: false,
        adminEmail: "",
        customerSubject: "",
        customerContent: "",
        customerContentNoResults: "",
        customerAttachment: "",
        feedbackEmailActive: false,
        feedbackEmailSubject: "",
        feedbackEmailContent: "",
        feedbackSendAfter: 10,
        adminSubject: "",
        adminContent: "",
      },
      openImmoSettings: {
        objectId: "",
      },
      optInSettings: {
        emailSubject: "",
        emailContent: "",
        acceptedHtml: "",
        cancelledHtml: "",
        reOptInEmailSubject: "",
        reOptInEmailContent: "",
        reOptInFormHtml: "",
        reOptInConfirmationHtml: ""
      },
      validFields: {
        mailgunDomain: {
          valid: null,
          invalidFeedback: "",
        },
      },
      debounced: {
        validateMailgunDomain: _.debounce(this.validateMailgunDomain, 500),
      },
    };
  },
  mounted() {
    this.loading = true;
    axios
      .get("/settings")
      .then((response) => {
        this.emailSettings = _.merge(
          {},
          this.emailSettings,
          commons.deepenOneLevelToNested(response.data, "_", "email_"),
        );
        this.openImmoSettings = _.merge(
          {},
          this.openImmoSettings,
          commons.deepenOneLevelToNested(response.data, "_", "openimmo_"),
        );
        const optInData = commons.deepenOneLevelToNested(response.data, "_", "optIn_");
        this.optInSettings = _.merge({}, this.optInSettings, optInData);

        if (!this.optInSettings.reOptInEmailContent) {
          this.optInSettings.reOptInEmailContent = DEFAULT_TEMPLATES.reOptInEmail;
        }
        if (!this.optInSettings.reOptInFormHtml) {
          this.optInSettings.reOptInFormHtml = DEFAULT_TEMPLATES.reOptInForm;
        }
        if (!this.optInSettings.reOptInConfirmationHtml) {
          this.optInSettings.reOptInConfirmationHtml = DEFAULT_TEMPLATES.reOptInConfirmation;
        }
        this.optInSettings = _.merge(
          {},
          this.optInSettings,
          commons.deepenOneLevelToNested(response.data, "_", "optIn_"),
        );
        this.companySettings = _.merge(
          {},
          this.companySettings,
          commons.deepenOneLevelToNested(response.data, "_", "company_"),
        );
        this.propStackSettings.apiKey = response.data.propstack_apiKey || "";
        this.propStackSettings.enabled = response.data.propstack_enabled !== false;
        this.onOfficeSettings.apiKey = response.data.onOffice_apiKey;
        this.onOfficeSettings.apiSecret = response.data.onOffice_apiSecret;
        this.onOfficeSettings.newsletterServiceActive =
          response.data.onOffice_newsletterServiceActive === true;
        this.loading = false;
      })
      .catch((err) => console.error(err));
  },

  computed: {
    shouldShowImmoTab() {
      const currentPlanName = this.currentRealm.plan;
      const isOrgAdmin = commons.isOrganizationAdmin();
      const isKensington = currentPlanName === "KENSINGTON";
      return isKensington && isOrgAdmin;
    },

    computedZapierDescription() {
      return this.$commons.hasRealmPlan("STARTUP")
        ? "Wenn Sie Zapier verwenden, ist diese Checkbox aktiv."
        : 'Um diese Funktion nutzen zu können, benötigen Sie den "STARTUP" Tarif.';
    },
  },
  methods: {
    submitSettings(sendAsDefault) {
      this.saving = true;
      const toSubmit = commons.oneLevelObject(
          {
            email: this.emailSettings,
            openimmo: this.openImmoSettings,
            optIn: this.optInSettings,
            propstack: {
              apiKey: this.propStackSettings.apiKey,
              enabled: this.propStackSettings.enabled
            },
            onOffice: {
              apiKey: this.onOfficeSettings.apiKey,
              apiSecret: this.onOfficeSettings.apiSecret,
              newsletterServiceActive: this.onOfficeSettings.newsletterServiceActive === true,
            },
            company: this.companySettings,
          },
          "_"
      );

      const url = sendAsDefault ? "/settings/default" : "/settings";
      this.$modal.showModalOnly("loading");

      // Erster API-Aufruf
      axios.post(url, toSubmit)
          .then(() => {
            console.log("Erster API-Call erfolgreich");
          })
          .catch((err) => {
            this.saving = false;
            this.$modal.hideModal("loading");
            let errorMessage = "Ihre Realm Einstellungen konnten nicht gespeichert werden! Mehr dazu in der Console.";

            // Extrahiere die spezifische Fehlermeldung aus err.response.data, falls vorhanden
            if (err.response && err.response.data) {
              // Falls die Antwort direkt die Nachricht im 'data'-Feld enthält
              if (typeof err.response.data === 'string') {
                errorMessage = err.response.data;
              } else if (err.response.data.message) {
                errorMessage = err.response.data.message;
              }
            }

            // Zeige die spezifische Fehlermeldung im Toast an
            this.$toaster.makeToast("Fehler", errorMessage);
            console.error(err, err.response.data);
          });

      // Zweiter API-Aufruf
      axios.post("/realm/current", {
        ...this.currentRealm,
        organizationId: this.currentRealm.organization.id,
      })
          .then((response) => {
            this.saving = false;
            this.$modal.hideModal("loading");
            this.$toaster.makeToast(
                "Erfolgreich",
                "Ihre Einstellungen wurden erfolgreich gespeichert."
            );

            const data = { ...response.data };
            commons.setToStorage("currentRealm", data);
            this.currentRealm = data;
          })
          .catch((err) => {
            this.saving = false;
            this.$modal.hideModal("loading");
            let errorMessage = "Ihre Realm Einstellungen konnten nicht gespeichert werden! Mehr dazu in der Console.";

            // Überprüfe, ob err.response.data eine String-Nachricht enthält oder ein Objekt ist
            if (err.response && err.response.data) {
              if (typeof err.response.data === 'string') {
                errorMessage = err.response.data; // Verwende die Nachricht, wenn es ein String ist
              } else if (err.response.data.message) {
                errorMessage = err.response.data.message; // Verwende die Nachricht aus dem 'message'-Feld, wenn vorhanden
              } else if (err.response.data.error) {
                errorMessage = err.response.data.error; // Prüfe, ob es ein 'error'-Feld gibt
              }
            }

            this.$toaster.makeToast("Fehler", errorMessage);
            console.error(err, err.response.data);
          });

      this.syncSettingsWithPdfGen();
    },
    validateMailgunDomain(val) {
      if (validations._allowEmptyString(val)) {
        this.validFields.mailgunDomain.valid = null;
        return;
      }
      if (!validations.domain(val)) {
        this.validFields.mailgunDomain.valid = false;
        this.validFields.mailgunDomain.invalidFeedback =
            "Die angegebene Domain ist ungültig.";
        return;
      }
      axios
          .get(`/realm/validateMailgunDomain/${val}`)
          .then((response) => {
            const valid = response.data.valid;
            if (valid) {
              this.validFields.mailgunDomain.valid = true;
            } else {
              this.validFields.mailgunDomain.valid = false;
              this.validFields.mailgunDomain.invalidFeedback =
                  "Die Domain wurde bereits registriert.";
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 400 && err.response.data.message.includes('domain already exists')) {
              this.validFields.mailgunDomain.valid = false;
              this.validFields.mailgunDomain.invalidFeedback =
                  "Die Domain ist bereits in einem anderen Account registriert. Bitte verwenden Sie eine andere Domain oder kontaktieren Sie den Support.";
            } else {
              this.validFields.mailgunDomain.valid = false;
              this.validFields.mailgunDomain.invalidFeedback =
                  "Fehler bei der Überprüfung.";
            }
            console.error(err);
          });
    },
    verifyAndRefreshMailgunDomain() {
      this.$modal.showModalOnly("loading");
      axios
        .get("/realm/current/verifyAndRefreshMailgunDomain")
        .then((response) => {
          this.currentRealm.mailgunDomainResponseJson = { ...response.data };
          commons.setToStorage("currentRealm", { ...this.currentRealm });
          this.$modal.hideModal("loading");
          this.$toaster.makeToast(
            "Aktualisiert",
            "Die DNS-Einträge wurden aktualisiert. Bitte prüfen.",
          );
        })
        .catch((err) => {
          this.$toaster.makeToast("Fehler!", err.message);
        });
    },
    deleteMailgunDomain() {
      this.$modal.showModal(
        "default",
        "E-Mail Domain löschen?",
        [`Möchten Sie Ihre E-Mail Domain aus unseren Systemen löschen?`],
        async () => {
          try {
            let resp = await axios.delete(`/realm/current/deleteMailgunDomain`);
            this.currentRealm.mailgunDomain = null;
            this.currentRealm.mailgunDomainResponseJson = null;
            commons.setToStorage("currentRealm", { ...this.currentRealm });
            this.$toaster.makeToast(
              "Erfolgreich!",
              "Die E-Mail Domain wurde soeben gelöscht.",
            );
          } catch (e) {
            this.$toaster.makeToast(
              "Fehler!",
              "Die E-Mail Domain konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
            );
            console.error(e);
          }
        },
      );
    },
    syncSettingsWithPdfGen() {
      axios.get("/pdf/syncSettings?includeMedia=false").catch((err) => {
        this.$toaster.makeToast(
          "Fehler",
          "Ihre Einstellungen konnten nicht mit dem PDF Server synchronisiert werden! Mehr dazu in der Console.",
        );
        console.error(err);
      });
    },
    async testPropstackConnection() {
      if (!this.propStackSettings.apiKey || !this.propStackSettings.enabled) {
        return;
      }

      this.propStackTestStatus.loading = true;
      this.propStackTestStatus.error = null;
      this.propStackTestStatus.success = false;

      try {
        // Erst die Propstack-Einstellungen speichern
        await axios({
          method: 'post',
          url: `/api/v1/propstack/settings/${this.currentRealm.id}`,
          baseURL: process.env.VUE_APP_BACKEND_BASE_URL.replace('/backend', ''),
          params: {  // Verwende params statt data für Request-Parameter
            apiKey: this.propStackSettings.apiKey,
            enabled: this.propStackSettings.enabled
          }
        });

        // Dann den Verbindungstest durchführen
        await axios({
          method: 'get',
          url: `/api/v1/propstack/test-connection/${this.currentRealm.id}`,
          baseURL: process.env.VUE_APP_BACKEND_BASE_URL.replace('/backend', '')
        });

        this.propStackTestStatus.success = true;
        this.$toaster.makeToast(
            "Erfolgreich",
            "Die Verbindung zu Propstack wurde erfolgreich getestet."
        );
      } catch (err) {
        this.propStackTestStatus.error = err.response?.data?.message || "Verbindung fehlgeschlagen";
        this.$toaster.makeToast(
            "Fehler",
            `Propstack-Verbindungstest fehlgeschlagen: ${this.propStackTestStatus.error}`
        );
      } finally {
        this.propStackTestStatus.loading = false;
      }
    },

    handlePropstackEnabledChange(enabled) {
      this.propStackSettings.enabled = enabled;
      // Auto-save when enabled status changes
      this.submitSettings();
    },
  },
  watch: {
    "currentRealm.mailgunDomain": function (newVal, oldVal) {
      this.debounced.validateMailgunDomain(newVal);
    },
  },
};
</script>

<style scoped>
.placeholder-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.vars-to-use {
  font-size: 0.9rem;
}

.vars-to-use code {
  background-color: #f8f9fa;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  color: #e83e8c;
  font-family: monospace;
}

.table-hover tbody tr:hover {
  background-color: rgba(0,0,0,.02);
}

.badge {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
}

.badge-info {
  background-color: #17a2b8;
}

.badge-warning {
  background-color: #ffc107;
  color: #000;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .table-responsive {
    margin: 0 -1rem;
  }

  .vars-to-use {
    font-size: 0.8rem;
  }

  .vars-to-use td {
    padding: 0.5rem;
  }
}
</style>

