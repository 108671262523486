<template>
  <div class="progress-ring" :style="containerStyle">
    <svg :width="size" :height="size">
      <circle
        class="progress-ring__circle-bg"
        :cx="center"
        :cy="center"
        :r="radius"
        :stroke-width="strokeWidth"
      />
      <circle
        class="progress-ring__circle"
        :cx="center"
        :cy="center"
        :r="radius"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="strokeDashoffset"
      />
    </svg>
    <div class="progress-ring__text" v-if="showPercentage">
      {{ formattedProgress }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressRing',

  props: {
    progress: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 48,
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    center() {
      return this.size / 2
    },

    radius() {
      return (this.size - this.strokeWidth) / 2
    },

    circumference() {
      return 2 * Math.PI * this.radius
    },

    strokeDashoffset() {
      const progress = this.progress > 100 ? 100 : this.progress < 0 ? 0 : this.progress
      return this.circumference - (progress / 100) * this.circumference
    },

    containerStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    },

    formattedProgress() {
      return `${Math.round(this.progress)}%`
    },
  },
}
</script>

<style scoped>
.progress-ring {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.progress-ring__circle-bg {
  fill: none;
  stroke: var(--border-color);
}

.progress-ring__circle {
  fill: none;
  stroke: var(--primary-color);
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.3s ease;
}

.progress-ring__text {
  position: absolute;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
}
</style>
