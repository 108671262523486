<template>
  <div class="range-slide-input-wrapper">
    <div class="range-slide-input">
      <input
        :id="id"
        type="number"
        :step="step"
        :value="value"
        @input="update"
        class="range-show-value"
        required="required"
      />
      <span class="unit">{{ unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RangeSlideInput",
  props: {
    step: [String, Number],
    value: [String, Number],
    unit: String,
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    update(e) {
      this.$emit("input", Number(e.target.value));
    },
  },
};
</script>

<style lang="scss" scoped>
.range-slide-input-wrapper {
  position: relative;
  text-align: left;
  display: block;

  * {
    height: 100%;
  }

  .range-slide-input {
    position: relative;

    &:hover,
    &:focus,
    &:active {
      border-color: #bfbfbf;

      .unit {
        right: 25px;
      }
    }

    input {
      display: block;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #bfbfbf;
      padding: 8px;
      font-size: 16px;
      background: #fff;
      outline: none;
    }

    .unit {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      display: flex;
      align-items: center;
      transition: all 0.2s;
    }
  }
}
</style>
