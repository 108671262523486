// AE means "Allow empty". If the given value is null or empty, the function will
// return null, so the input field does not show invalid or valid classes.

function _allowEmptyString(str) {
  return str === null || str === "";
}

const domain = (str) => {
  return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(
    str,
  );
};

const domainAE = (str) => {
  return _allowEmptyString(str) ? null : domain(str);
};

export default {
  _allowEmptyString,
  domain,
  domainAE,
};
