import Vue from "vue";
import App from "./App";
import router from "./router";
import { NotesWidget } from '@/components/shared/widgets'
import store from "./store";
import importCoreUI from "./importCoreUI";
import { iconsSet as icons } from "./assets/icons/icons.js";
import moment from "moment";
import "moment/locale/de.js";
import axios from "axios";
import commons from "./commons";
import toastPlugin from "./toastPlugin";
import realmSwitcherModalPlugin from "@/realmSwitcherModalPlugin";
import modalPlugin from "@/modalPlugin";
import "vue-search-select/dist/VueSearchSelect.css";
import validations from "@/validations";
import VCalendar from "v-calendar";
import VTooltip from 'v-tooltip'
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import JsonCSV from "vue-json-csv";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSlider from "vue-slider-component";
import Chart from 'chart.js'
import 'chartjs-adapter-moment'
import { CChartLine, CChartPie } from '@coreui/vue-chartjs'
import CPagination from "@coreui/vue/src/components/pagination/CPagination";
import { Stomp } from "@stomp/stompjs";
import WebSocketService from "@/services/WebSocketService";
import CBreadcrumb from "@coreui/vue";
import CInputGroup from "./components/CInputGroup.vue";
import CInputGroupText from "./components/CInputGroupText.vue";
import authService from "@/services/auth.service";

Chart.defaults.global.defaultFontFamily = 'sans-serif'
Chart.defaults.global.defaultFontSize = 12

Vue.config.productionTip = false
Vue.config.devtools = false
Vue.config.silent = true  //

moment.locale("de");

Vue.config.performance = true;

importCoreUI.installComponents();
Vue.use(toastPlugin);
Vue.use(realmSwitcherModalPlugin);
Vue.use(modalPlugin);
Vue.use(VTooltip)
Vue.prototype.$stomp = Stomp;
Vue.prototype.$icons = icons;

Vue.use(VCalendar, {});
Vue.component("VueSlider", VueSlider);
Vue.component("CPagination", CPagination);
Vue.component("CInputGroup", CInputGroup);
Vue.component("CInputGroupText", CInputGroupText);
Vue.component('NotesWidget', NotesWidget)
Vue.component('CChartLine', CChartLine)
Vue.component('CChartPie', CChartPie)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBdNZqtEDIsQbckRbf88R-z7fJpTyvyfnk',
    libraries: ['places', 'visualization']
  },
  installComponents: true
});

Vue.component("multiselect", Multiselect);
Vue.prototype.$moment = moment;
Vue.prototype.$commons = commons;
Vue.prototype.$validations = validations;
Vue.component("downloadCsv", JsonCSV);

WebSocketService.connect();
WebSocketService.subscribeToTopic("/topic/notifications", (message) => {
  const notificationData = JSON.parse(message.body);
  WebSocketService.handleNewNotification(notificationData);
});

axios.interceptors.request.use(async function (config) {
  try {
    let jwtToken = commons.getFromStorage("jwtToken");

    if (jwtToken) {
      // Prüfen Sie, ob der Token bereits das "Bearer" Prefix hat
      if (!jwtToken.startsWith('Bearer ')) {
        jwtToken = 'Bearer ' + jwtToken;
      }

      config.headers["Authorization"] = jwtToken;
    }

    if (!config.baseURL) {
      config.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;
    }

    return config;
  } catch (error) {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response) {
        // Token ist abgelaufen oder ungültig
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            // Versuchen Sie zuerst den Token zu erneuern
            const newToken = await authService.renewToken();
            if (newToken) {
              originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
              return axios(originalRequest);
            }
          } catch (renewError) {
            console.error('Token renewal failed:', renewError);
            // Bei Fehler während der Token-Erneuerung: Logout und zur Login-Seite
            commons.logout();
            router.push('/pages/login');
            return Promise.reject(renewError);
          }
        }

        if (error.response.status === 403) {
          toastPlugin.makeToast(
              "Hinweis",
              "Sie haben keine Berechtigung, diese Aktion durchzuführen."
          );
        }
      }

      return Promise.reject(error);
    }
);

// Token-Prüfungs-Funktion verbessern
function isTokenExpired(token) {
  try {
    if (!token) return true;

    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiry = payload.exp;
    const now = Math.floor(Date.now() / 1000);
    // 5 Minuten Puffer
    return now > (expiry - 300);
  } catch (error) {
    console.error('Token expiration check error:', error);
    return true;
  }
}

// WebSocket-Verbindung nur herstellen, wenn der Benutzer eingeloggt ist
if (commons.isLoggedIn()) {
  WebSocketService.connect();
  WebSocketService.subscribeToTopic("/topic/notifications", (message) => {
    try {
      const notificationData = JSON.parse(message.body);
      WebSocketService.handleNewNotification(notificationData);
    } catch (error) {
      console.error('Error handling notification:', error);
    }
  });

  // Aktuelle Realm-Daten laden
  axios
      .get("/realm/current")
      .then((response) => {
        commons.setToStorage("currentRealm", response.data);
      })
      .catch((err) => {
        console.error("Can't retrieve fresh state of current realm", err);
      });
}

// Vue-Instanz erstellen
new Vue({
  el: "#app",
  router,
  store,
  icons,
  render: (h) => h(App),
});
