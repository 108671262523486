<template>
  <CDropdown
      inNav
      class="user-dropdown"
      placement="bottom-end"
      :add-menu-classes="['user-dropdown-menu', 'p-0', 'mt-1']"
  >
    <template #toggler>
      <CHeaderNavLink class="user-dropdown-toggle py-0">
        <div class="user-avatar-wrapper">
          <div class="user-avatar" :class="{ 'has-notification': hasUnreadNotifications }">
            <template v-if="!avatarError">
              <img
                  src="/img/avatars/boss.png"
                  class="user-avatar-image"
                  alt="Benutzer Avatar"
                  @error="handleAvatarError"
              />
            </template>
            <div v-else class="user-avatar-fallback">
              {{ userInitials }}
            </div>

            <!-- Online Status Indicator -->
            <div class="user-status-indicator" :class="userStatusClass"></div>
          </div>
        </div>
      </CHeaderNavLink>
    </template>

    <!-- User Info Header -->
    <div class="user-info-header">
      <div class="user-info-content">
        <div class="user-avatar user-avatar-lg">
          <template v-if="!avatarError">
            <img
                src="/img/avatars/boss.png"
                class="user-avatar-image"
                alt="Benutzer Avatar"
                @error="handleAvatarError"
            />
          </template>
          <div v-else class="user-avatar-fallback">
            {{ userInitials }}
          </div>
        </div>
        <div class="user-details">
          <div class="user-name">{{ userName }}</div>
          <div class="user-email">{{ currentUserEmail }}</div>
          <div class="user-role">Administrator</div>
        </div>
      </div>
    </div>

    <!-- Menu Items -->
    <div class="user-menu-items">
      <CDropdownItem
          v-for="item in menuItems"
          :key="item.route"
          :to="item.route"
          class="user-menu-item"
      >
        <div class="menu-item-content">
          <div class="menu-item-left">
            <CIcon :name="item.icon" class="menu-icon" />
            {{ item.label }}
          </div>
          <CBadge
              v-if="item.badge"
              class="menu-badge"
              :color="item.badge.color"
          >
            {{ item.badge.text }}
          </CBadge>
        </div>
      </CDropdownItem>
    </div>

    <!-- Footer Actions -->
    <div class="user-menu-footer">
      <CButton
          color="danger"
          variant="ghost"
          class="w-100"
          @click="handleLogout"
      >
        <CIcon name="cil-lock-locked" class="me-2" />
        Abmelden
      </CButton>
    </div>
  </CDropdown>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/de'

moment.locale('de')

export default {
  name: 'TheHeaderDropdownAccnt',

  data() {
    return {
      avatarError: false,
      userStatus: 'online',
      notifications: [],
      unreadNotifications: [],
      currentRealmData: null,
      loading: {
        realm: false,
        notifications: false
      },
      menuItems: [
        {
          icon: 'cil-user',
          label: 'Mein Profil',
          route: '/users/edit/1'
        },
        {
          icon: 'cil-settings',
          label: 'Tarifeinstellungen',
          route: '/subscription'
        },
        {
          icon: 'cil-user',
          label: 'Benutzer',
          route: '/users'
        },
        {
          icon: 'cil-bell',
          label: 'Benachrichtigungen',
          route: '/notifications',
          badge: {
            color: 'danger',
            text: '0'
          }
        }
      ]
    }
  },

  computed: {
    userName() {
      return this.currentRealmData?.organization?.name || ''
    },

    currentUserEmail() {
      return this.currentRealmData?.organization?.email || ''
    },

    organizationLogoUrl() {
      return this.currentRealmData?.organization?.logoUrl || null
    },

    planName() {
      return this.currentRealmData?.plan?.name || ''
    },

    userInitials() {
      return (this.userName || '')
          .split(' ')
          .map(n => n[0])
          .join('')
          .toUpperCase()
          .substring(0, 2)
    },

    hasUnreadNotifications() {
      return this.unreadNotifications.length > 0
    },

    unreadNotificationsCount() {
      return this.unreadNotifications.length
    },

    userStatusClass() {
      return {
        'status-online': this.userStatus === 'online',
        'status-away': this.userStatus === 'away',
        'status-offline': this.userStatus === 'offline'
      }
    }
  },

  created() {
    this.initializeData()
  },

  mounted() {
    this.startNotificationPolling()
  },

  beforeDestroy() {
    this.stopNotificationPolling()
  },

  methods: {
    async initializeData() {
      await Promise.all([
        this.fetchRealmData(),
        this.fetchNotifications()
      ])
    },

    handleLogout() {
      this.$router.push({ name: 'Logout' })
    },

    async fetchRealmData() {
      this.loading.realm = true
      try {
        const response = await axios.get('/realm/current')
        this.currentRealmData = response.data
        this.updateMenuItems()
      } catch (error) {
        console.error('Error fetching realm data:', error)
        this.$toast?.error('Fehler beim Laden der Benutzerdaten')
      } finally {
        this.loading.realm = false
      }
    },

    async fetchNotifications() {
      this.loading.notifications = true
      try {
        const [allResponse, unreadResponse] = await Promise.all([
          axios.get('/api/notifications'),
          axios.get('/api/notifications/unread')
        ])

        if (allResponse.data) {
          this.notifications = allResponse.data.map(notification => ({
            ...notification,
            time: moment(notification.createdAt).format('LLL')
          }))
        }

        if (unreadResponse.data) {
          this.unreadNotifications = unreadResponse.data.map(notification => ({
            ...notification,
            time: moment(notification.createdAt).format('LLL')
          }))
        }

        this.updateNotificationBadge()
      } catch (error) {
        console.error('Error fetching notifications:', error)
        this.$toast?.error('Fehler beim Laden der Benachrichtigungen')
      } finally {
        this.loading.notifications = false
      }
    },

    async markNotificationAsRead(notificationId) {
      try {
        await axios.post(`/api/notifications/${notificationId}/read`)
        await this.fetchNotifications()
      } catch (error) {
        console.error('Error marking notification as read:', error)
        this.$toast?.error('Fehler beim Markieren der Benachrichtigung')
      }
    },

    updateMenuItems() {
      if (this.currentRealmData?.organization?.id) {
        const profileMenuItem = this.menuItems.find(item => item.label === 'Mein Profil')
        if (profileMenuItem) {
          profileMenuItem.route = `/users/edit/${this.currentRealmData.organization.id}`
        }
      }

      if (this.currentRealmData?.plan) {
        const subscriptionItem = this.menuItems.find(item => item.label === 'Tarifeinstellungen')
        if (subscriptionItem) {
          subscriptionItem.badge = {
            color: 'info',
            text: this.currentRealmData.plan.name
          }
        }
      }

      this.updateNotificationBadge()
    },

    updateNotificationBadge() {
      const notificationMenuItem = this.menuItems.find(item => item.label === 'Benachrichtigungen')
      if (notificationMenuItem) {
        if (this.unreadNotificationsCount > 0) {
          notificationMenuItem.badge = {
            color: 'danger',
            text: this.unreadNotificationsCount.toString()
          }
        } else {
          delete notificationMenuItem.badge
        }
      }
    },

    handleAvatarError() {
      this.avatarError = true
    },

    startNotificationPolling() {
      this.notificationInterval = setInterval(() => {
        this.fetchNotifications()
      }, 30000)
    },

    stopNotificationPolling() {
      if (this.notificationInterval) {
        clearInterval(this.notificationInterval)
      }
    }
  }
}
</script>

<style scoped>
.user-dropdown {
  --avatar-size: 40px;
  --avatar-size-lg: 64px;
  --status-size: 10px;
  --primary-color: var(--primary, #321fdb);
  --success-color: var(--success, #2eb85c);
  --warning-color: var(--warning, #f9b115);
  --danger-color: var(--danger, #e55353);
}

/* Avatar Styles */
.user-avatar-wrapper {
  position: relative;
  padding: 2px;
}

.user-avatar {
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 50%;
  background-color: var(--light);
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
  transition: all 0.2s ease;
}

.user-avatar-lg {
  width: var(--avatar-size-lg);
  height: var(--avatar-size-lg);
}

.user-avatar:hover {
  border-color: var(--primary-color);
  transform: scale(1.05);
}

.user-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-avatar-fallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
  font-size: 1.2em;
}

.user-avatar-lg .user-avatar-fallback {
  font-size: 1.8em;
}

/* Status Indicator */
.user-status-indicator {
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: var(--status-size);
  height: var(--status-size);
  border-radius: 50%;
  border: 2px solid var(--white);
  background-color: var(--success-color);
}

.status-away {
  background-color: var(--warning-color);
}

.status-offline {
  background-color: var(--danger-color);
}

/* Dropdown Menu Styles */
.user-dropdown-menu {
  width: 280px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.user-info-header {
  padding: 1.5rem;
  background-color: var(--primary-color);
  color: white;
}

.user-info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.user-details {
  margin-top: 1rem;
}

.user-name {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.user-email {
  font-size: 0.9rem;
  opacity: 0.9;
  margin-bottom: 0.25rem;
}

.user-role {
  font-size: 0.8rem;
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Menu Items */
.user-menu-items {
  padding: 0.5rem 0;
}

.user-menu-item {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.menu-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.menu-item-left {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.user-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menu-icon {
  width: 1.2rem;
  height: 1.2rem;
  opacity: 0.8;
}

/* Badge Styling */
.menu-badge {
  margin-left: 1rem;
  padding: 0.25em 0.6em;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 0.25rem;
}

/* Hover Effects */
.user-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.user-menu-item:hover .menu-icon {
  opacity: 1;
}

/* Optional: Animation for badges */
.menu-badge {
  transition: all 0.2s ease;
}

.user-menu-item:hover .menu-badge {
  transform: scale(1.05);
}


/* Footer */
.user-menu-footer {
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Notification Indicator */
.has-notification::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 8px;
  background-color: var(--danger-color);
  border-radius: 50%;
  border: 2px solid var(--white);
}

/* Badge Styles */
:deep(.badge) {
  font-size: 0.75rem;
  padding: 0.25em 0.5em;
}
</style>
