var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CFormGroup',_vm._b({scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label",function(){return [(_vm.label)?_c('label',{class:_vm.labelClasses,attrs:{"for":_vm.safeId}},[_vm._v(" "+_vm._s(_vm.laEbel)+" ")]):_vm._e()]})]},proxy:true},{key:"input",fn:function(){return [_c('TinyMCEEditor',{attrs:{"api-key":"klq3a6ik85ypjus3hskhkhj6n0juagsvd6i7rnynhdvomx98","init":_vm.editorOptions},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})]},proxy:true},_vm._l((_vm.$options.slots),function(slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}})],null,true)},'CFormGroup',{
    append: _vm.append,
    prepend: _vm.prepend,
    validFeedback: _vm.validFeedback,
    invalidFeedback: _vm.invalidFeedback,
    tooltipFeedback: _vm.tooltipFeedback,
    description: _vm.description,
    wrapperClasses: _vm.wrapperClasses,
    class: _vm.computedClasses,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }