<template>
  <div>
    <contact-import />
    <NewsletterSubscriberImport />
  </div>
</template>

<script>
import axios from "axios";
import ContactImport from "./ContactImport";
import NewsletterSubscriberImport from "@/views/base/NewsletterSubscriberImport";

export default {
  name: "ImportExport",
  data() {
    return {
      placeholder: "Datei auswählen...",
      file: null,
    };
  },
  components: {
    NewsletterSubscriberImport,
    ContactImport,
  },
  methods: {
    onChange($event) {
      this.placeholder = $event[0].name;
      this.file = $event[0];
    },
  },
};
</script>

<style scoped></style>
