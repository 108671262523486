var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('div',{staticClass:"prt-card-header"},[_c('div',{staticClass:"header-naming"},[_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" Alle Merkmale ")],1),_c('div',{staticClass:"header-button"},[_c('CButton',{key:"key",attrs:{"name":"backagain","size":"sm","color":"dark"},on:{"click":_vm.showAddModal}},[_c('CIcon',{attrs:{"size":"sm","name":"cil-plus"}}),_c('span',[_vm._v(" Neues Merkmal hinzufügen")])],1)],1)])]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.tableData,"itemsPerPageSelect":{ label: 'Merkmale pro Seite' },"fields":_vm.getTableFields(),"noItemsView":{
            noResults: 'Keine Suchergebnisse verfügbar',
            noItems: 'Noch keine Merkmale erstellt',
          },"hover":"","striped":"","bordered":"","fixed":"","column-filter":"","table-filter":"","tableFilter":{
            label: 'Merkmale suchen',
            placeholder: 'Merkmal eingeben',
          },"items-per-page-select":"","sorter":"","pagination":"","loading":_vm.tableShowLoading},scopedSlots:_vm._u([{key:"show_details",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.showUpdateModal(item)}}},[_vm._v(" Bearbeiten ")])],1)]}},{key:"delete_item",fn:function({ item, index }){return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"danger","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" Löschen ")])],1)]}}])})],1)],1)],1),_c('CCol',{attrs:{"md":"12"}},[_c('CForm',{on:{"submit":_vm.submit}},[_c('CModal',{attrs:{"title":"Merkmale hinzufügen","size":"sm","show":_vm.visibleAddModal,"closeOnBackdrop":false},on:{"update:show":function($event){_vm.visibleAddModal=$event}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('label',{attrs:{"for":"title"}},[_vm._v("Name")]),_c('CInput',{attrs:{"id":"title","required":"true","placeholder":"Name des Merkmals"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{staticClass:"mr-3",attrs:{"color":"dark","variant":"outline"},on:{"click":function($event){_vm.visibleAddModal = false}}},[_vm._v(" Abbrechen ")]),_c('CButton',{attrs:{"color":"success","type":"submit"}},[_vm._v(" Speichern ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }