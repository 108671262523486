<template>
  <CRow>
    <CCol md="12" class="mb-3">
      <CCard>
        <CCardHeader>
          <slot name="header">
            <div class="prt-card-header">
              <div class="header-naming">
                <CIcon name="cil-grid" />
                Alle Kontakte
              </div>
              <div class="header-button">
                <CButton
                  name="backagain"
                  size="sm"
                  key="key"
                  color="dark"
                  @click="showAddModal"
                >
                  <CIcon size="sm" name="cil-plus" />
                  <span> Kontakt hinzufügen</span>
                </CButton>
              </div>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="tableData"
            :itemsPerPageSelect="{ label: 'Kontakte pro Seite' }"
            :fields="getTableFields()"
            :noItemsView="{
              noResults: 'Keine Suchergebnisse verfügbar',
              noItems: 'Keine Kontakte erstellt',
            }"
            hover
            striped
            bordered
            fixed
            column-filter
            table-filter
            :tableFilter="{
              label: 'Kontakte suchen',
              placeholder: 'Suchbegriff eingeben',
            }"
            items-per-page-select
            sorter
            pagination
            :loading="tableShowLoading"
          >
            <template #show_details="{ item, index }">
              <td class="py-2">
                <CButton
                  @click="showUpdateModal(item)"
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                >
                  Bearbeiten
                </CButton>
              </td>
            </template>
            <template #delete_item="{ item, index }">
              <td class="py-2">
                <CButton
                  @click="deleteItem(item.id)"
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                >
                  Löschen
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol md="12">
      <CForm @submit="submit">
        <CModal
          title="Kontakt hinzufügen"
          size="sm"
          :show.sync="visibleAddModal"
          :closeOnBackdrop="false"
        >
          <CRow>
            <CCol sm="12">
              <label for="name">Name</label>
              <CInput id="name" required="true" v-model="name" />
            </CCol>

            <CCol sm="12">
              <label for="organization"
                >Merkmale</label
              >
              <CSelect
                :value="realmGroup.id"
                @update:value="realmGroup.id = $event"
                :options="organizations"
                id="organization"
              />
            </CCol>

            <CCol sm="12">
              <label>Telefon</label>
              <CRow
                :key="'phones-parent' + index"
                v-for="(item, index) in realmPeoplePhones"
              >
                <CCol :key="'first' + index" md="10">
                  <CInput v-model="item.phone" class="mb-1" required />
                </CCol>
                <CCol
                  :key="'second' + index"
                  md="2"
                  v-if="realmPeoplePhones.length > 1"
                >
                  <label @click="removeItem(realmPeoplePhones, index)">
                    <CIcon style="cursor: pointer" name="cil-trash" />
                  </label>
                </CCol>
              </CRow>
              <label
                class="text-primary"
                style="cursor: pointer"
                @click="addOneMore(realmPeoplePhones, 'phone')"
                >+ Weitere Rufnummer hinterlegen</label
              >
            </CCol>

            <CCol sm="12">
              <label>E-Mail Adresse</label>
              <CRow
                :key="'emails-parent' + index"
                v-for="(item, index) in realmPeopleEmails"
              >
                <CCol :key="'third' + index" md="10">
                  <CInput
                    type="email"
                    v-model="item.email"
                    required
                    class="mb-1"
                  />
                </CCol>
                <CCol
                  :key="'fourth' + index"
                  md="2"
                  v-if="realmPeopleEmails.length > 1"
                >
                  <label @click="removeItem(realmPeopleEmails, index)">
                    <CIcon style="cursor: pointer" name="cil-trash" />
                  </label>
                </CCol>
              </CRow>
              <label
                class="text-primary"
                style="cursor: pointer"
                @click="addOneMore(realmPeopleEmails, 'email')"
                >+ Weitere E-Mail Adresse hinterlegen</label
              >
            </CCol>
          </CRow>
          <div slot="footer">
            <CButton
              class="mr-3"
              color="dark"
              variant="outline"
              @click="visibleAddModal = false"
            >
              Abbrechen
            </CButton>
            <CButton color="success" type="submit"> Speichern </CButton>
          </div>
        </CModal>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import commons from "../../commons";

export default {
  mounted() {
    this.loadPeoples();
  },
  data: () => ({
    visibleAddModal: false,
    name: "",
    realmGroup: {
      id: null,
    },
    realmPeoplePhones: [
      {
        phone: "",
      },
    ],
    realmPeopleEmails: [
      {
        email: "",
      },
    ],
    tableData: [], // this is used for display
    originData: [], // this is used for saving origin data from api
    organizations: [],
    tableShowLoading: false,
    actionFlag: "none",
    selectedItem: null,
    csv: null,
  }),
  methods: {
    loadPeoples() {
      axios
        .get("/realm-people")
        .then((response) => {
          this.originData = JSON.parse(JSON.stringify(response.data));
          let content = [];
          response.data.forEach((value) => {
            value.realmGroup =
              (value.realmGroup && value.realmGroup.title) || "";
            value.realmPeopleEmails = value.realmPeopleEmails
              .map((item) => item.email)
              .toString();
            value.realmPeoplePhones = value.realmPeoplePhones
              .map((item) => item.phone)
              .toString();
            content.push(commons.flatObject(value));
          });
          this.tableData = content;
          this.loadOrganizations();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableShowLoading = false;
        });
    },
    loadOrganizations() {
      this.tableShowLoading = true;

      axios
        .get("/realm-group")
        .then((response) => {
          this.organizations =
            response.data &&
            response.data.map((item) => {
              return {
                value: item.id,
                label: item.title,
              };
            });

          this.organizations.splice(0, 0, {
            value: null,
            label: "",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableShowLoading = false;
        });
    },
    addOneMore(data, key) {
      data.push({
        [key]: "",
      });
    },
    removeItem(data, index) {
      data.splice(index, 1);
    },
    submit(e) {
      this.tableShowLoading = true;
      e.preventDefault();
      const data = {
        name: this.name,
        realmGroup: this.realmGroup,
        realmPeoplePhones: this.realmPeoplePhones,
        realmPeopleEmails: this.realmPeopleEmails,
      };

      if (this.actionFlag === "add") {
        axios
          .post("/realm-people", data)
          .then((response) => {
            this.originData.push(JSON.parse(JSON.stringify(response.data)));

            response.data.realmGroup =
              (response.data.realmGroup && response.data.realmGroup.title) ||
              "";
            response.data.realmPeopleEmails = response.data.realmPeopleEmails
              .map((item) => item.email)
              .toString();
            response.data.realmPeoplePhones = response.data.realmPeoplePhones
              .map((item) => item.phone)
              .toString();
            if (this.tableData.length === 0) {
              this.tableData = [commons.flatObject(response.data)];
            } else {
              this.tableData.push(commons.flatObject(response.data));
            }

            this.visibleAddModal = false;
            this.initialize();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.tableShowLoading = false;
          });
      } else if (this.actionFlag === "update") {
        axios
          .put(`/realm-people/${this.selectedItem.id}`, data)
          .then((response) => {
            const index = this.tableData.findIndex(
              (item) => item.id === this.selectedItem.id,
            );
            this.originData.splice(
              index,
              1,
              JSON.parse(JSON.stringify(response.data)),
            );

            response.data.realmGroup =
              (response.data.realmGroup && response.data.realmGroup.title) ||
              "";
            response.data.realmPeopleEmails = response.data.realmPeopleEmails
              .map((item) => item.email)
              .toString();
            response.data.realmPeoplePhones = response.data.realmPeoplePhones
              .map((item) => item.phone)
              .toString();

            this.tableData.splice(index, 1, response.data);

            this.visibleAddModal = false;
            this.initialize();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.tableShowLoading = false;
          });
      }
    },
    initialize() {
      this.name = "";
      this.realmGroup = { id: null };
      this.realmPeoplePhones = [{ phone: "" }];
      this.realmPeopleEmails = [{ email: "" }];
    },
    getTableFields() {
      return [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "realmGroup",
          label: "Merkmale",
        },
        {
          key: "realmPeopleEmails",
          label: "E-Mail",
        },
        {
          key: "realmPeoplePhones",
          label: "Telefon",
        },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
        {
          key: "delete_item",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ];
    },
    showUpdateModal(item) {
      const selectedItemIndex = this.originData.findIndex(
        (entity) => entity.id === item.id,
      );
      if (selectedItemIndex !== -1) {
        this.selectedItem = JSON.parse(
          JSON.stringify(this.originData[selectedItemIndex]),
        );

        this.name = this.selectedItem.name;
        this.realmPeoplePhones = this.selectedItem.realmPeoplePhones;
        this.realmPeopleEmails = this.selectedItem.realmPeopleEmails;
        this.realmGroup = this.selectedItem.realmGroup || {
          id: null,
        };

        this.actionFlag = "update";
        this.visibleAddModal = true;
      }
    },
    showAddModal() {
      this.actionFlag = "add";
      this.visibleAddModal = true;
    },
    deleteItem(id) {
      this.tableShowLoading = true;
      axios
        .delete(`realm-people/${id}`)
        .then(() => {
          this.tableData = this.tableData.filter((item) => item.id !== id);
          this.originData = this.originData.filter((item) => item.id !== id);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.tableShowLoading = false;
        });
    },
  },
};
</script>
