<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong>Newsletter Abonnenten</strong>
            <CButton
              v-if="selected.length > 0"
              @click="deleteSelectedSubscribers"
              color="danger"
              variant="outline"
              class="float-right mr-2"
              size="sm"
            >
              Ausgewählte löschen ({{ selected.length }})
            </CButton>
            <DoubleBounceLoader
              v-if="loading || saving"
              width="20px"
              height="20px"
              style="float: right"
            />
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CDataTable
              :items="tableData"
              :itemsPerPageSelect="{ label: 'Abonnenten pro Seite' }"
              :fields="getTableFields()"
              :noItemsView="{
                noResults: 'Keine Suchergebnisse verfügbar',
                noItems: 'Es gibt noch keine Abonnenten',
              }"
              hover
              striped
              bordered
              fixed
              column-filter
              table-filter
              :tableFilter="{
                label: 'Abonnenten suchen',
                placeholder: 'Suchbegriff eingeben',
              }"
              items-per-page-select
              sorter
              :sorterValue="{ column: 'created', asc: false }"
              pagination
              :loading="tableShowLoading"
            >
              <template #salutation="{ item }">
                <td>{{ item.salutation || '-' }}</td>
              </template>

              <template #company="{ item }">
                <td>{{ item.company || '-' }}</td>
              </template>
              <template #checkbox-header>
                <th>
                  <CInputCheckbox
                    :checked="isAllSelected"
                    :indeterminate="isIndeterminate"
                    @update:checked="toggleSelectAll"
                  />
                </th>
              </template>
              <template #checkbox="{item}">
                <td>
                  <CInputCheckbox
                    :checked="isSelected(item)"
                    @update:checked="toggleSelection(item)"
                  />
                </td>
              </template>
              <!-- Template für die Checkbox-Spalte -->
              <template #checkbox="{item}">
                <td>
                  <CInputCheckbox
                    :checked="isSelected(item)"
                    @update:checked="toggleSelection(item)"
                  />
                </td>
              </template>

              <template #verified="{ item }">
                <td>
                  <CBadge :color="item.verified ? 'success' : 'danger'">
                    {{ item.verified ? "Verifiziert" : "Nicht verifiziert" }}
                  </CBadge>
                </td>
              </template>
              <template #groups="{ item }">
                <td>
                  <CBadge
                    v-if="item.groups && item.groups.length > 0"
                    v-for="groupName in item.groups.map((g) => g.name)"
                    :key="groupName"
                    color="info"
                    shape="pill"
                    style="margin-right: 4px"
                  >
                    {{ groupName }}
                  </CBadge>
                  <span v-else>Keine Gruppe</span>
                </td>
              </template>

              <template #show_details="{ item, index }">
                <td class="py-2">
                  <CButton
                    :to="'subscriber/edit/' + item.id"
                    color="primary"
                    variant="outline"
                    class="action-button"
                    square
                    size="sm"
                  >
                    Bearbeiten
                  </CButton>
                  <CButton
                    @click="deleteNewsletterSubscriber(item, index)"
                    color="danger"
                    variant="outline"
                    class="action-button"
                    square
                    size="sm"
                  >
                    <CIcon name="cil-trash" />
                  </CButton>
                </td>
              </template>
              <template #created="{ item }">
                <td>
                  {{ $moment(item.created).format($commons.DATE_TIME_FORMAT) }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DoubleBounceLoader from "@/components/loaders/DoubleBounceLoader";
import commons from "@/commons";
import axios from "axios";

export default {
  name: "NewsletterSubscriber",
  components: {
    DoubleBounceLoader,
  },
  computed: {
    // Prüft, ob alle Items ausgewählt sind
    isAllSelected() {
      return this.tableData.length > 0 && this.selected.length === this.tableData.length;
    },
    // Prüft, ob einige aber nicht alle Items ausgewählt sind
    isIndeterminate() {
      return this.selected.length > 0 && !this.isAllSelected;
    }
  },
  data() {
    return {
      loading: false,
      saving: false,
      tableData: [],
      tableShowLoading: true,
      selected: [], // Speichert die ausgewählten Items
    };
  },
  mounted() {
    this.loading = true;
    this.loadNewsletterSubscriber();
  },
  methods: {
    toggleSelectAll(checked) {
      if (checked) {
        // Alle auswählen
        this.selected = [...this.tableData];
      } else {
        // Alle abwählen
        this.selected = [];
      }
    },
    loadNewsletterSubscriber() {
      axios
        .get("/newsletter/subscriber")
        .then((response) => {
          console.log(response.data);
          let content = [];
          response.data.forEach((value) => {
            let toAdd = commons.flatObject(value);
            toAdd.groups = value.groups;
            content.push(toAdd);
          });
          this.tableData = content;
          this.loading = false;
        })
        .catch((err) =>
          console.error("Problem while fetching newsletter subscribers", err),
        )
        .finally(() => (this.tableShowLoading = false));
    },
    getTableFields() {
      return [
        {
          key: 'checkbox',
          label: '',
          _style: 'width:5%',
          sorter: false,
          filter: false,
          _classes: 'text-center' // Zentriert die Checkboxen
        },
        { key: "salutation", label: "Anrede" },
        { key: "firstName", label: "Vorname" },
        { key: "lastName", label: "Nachname" },
        { key: "company", label: "Firma" },
        { key: "email", label: "E-Mail" },
        { key: "groups", label: "Empfängergruppe" },
        { key: "verified", label: "Verifiziert?" },
        { key: "created", label: "Datum" },
        {
          key: "show_details",
          label: "",
          _style: "width:10%",
          sorter: false,
          filter: false,
        },
      ];
    },
    // Neue Methoden für die Checkbox-Funktionalität
    isSelected(item) {
      return this.selected.some(selected => selected.id === item.id);
    },
    toggleSelection(item) {
      const index = this.selected.findIndex(selected => selected.id === item.id);
      if (index === -1) {
        this.selected.push(item);
      } else {
        this.selected.splice(index, 1);
      }
    },
    deleteNewsletterSubscriber(item, index) {
      this.$modal.showModal(
        "delete",
        null,
        [`<b>Abonnent</b> (${item["email"]})`],
        async () => {
          try {
            await axios.delete("/newsletter/subscriber/" + item.id);
            this.$toaster.makeToast(
              "Abonnent gelöscht",
              `Ihr Newsletter Abonnent "${item.email}" wurde erfolgreich gelöscht!`,
            );
            this.loadNewsletterSubscriber();
          } catch (err) {
            this.$toaster.makeToast(
              "Fehler",
              `Beim Löschen des Newsletter-Abonnenten "${item.email}" ist ein Fehler aufgetreten. Bitte versuchen Sie es in einem späteren Zeitpunkt erneut!`,
            );
            console.log(err);
          }
        },
      );
    },
    deleteSelectedSubscribers() {
      if (this.selected.length === 0) return;

      const emailList = this.selected.map(item => item.email).join(', ');

      this.$modal.showModal(
        "delete",
        null,
        [`<b>${this.selected.length} Abonnenten</b> (${emailList})`],
        async () => {
          try {
            const deletePromises = this.selected.map(item =>
              axios.delete("/newsletter/subscriber/" + item.id)
            );

            await Promise.all(deletePromises);

            this.$toaster.makeToast(
              "Abonnenten gelöscht",
              `${this.selected.length} Newsletter Abonnenten wurden erfolgreich gelöscht!`
            );

            this.selected = []; // Liste leeren
            this.loadNewsletterSubscriber(); // Tabelle neu laden
          } catch (err) {
            this.$toaster.makeToast(
              "Fehler",
              `Beim Löschen der Newsletter-Abonnenten ist ein Fehler aufgetreten. Bitte versuchen Sie es in einem späteren Zeitpunkt erneut!`
            );
            console.error(err);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.action-button {
  margin-right: 5px;
}
</style>
